import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ApiService } from "../../shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { UrlService } from "../../shared/services/url.service";
import { ReCaptchaV3Service } from "ng-recaptcha";

@Component({
  selector: "app-partner",
  templateUrl: "./partner.component.html",
  styleUrls: ["./partner.component.scss"],
})
export class PartnerComponent implements OnInit {
  partnerForm: FormGroup;

  pendingPartner = false;
  pendingPartnerDetailForm: FormGroup;
  colorClassesNames = ["color-dark-blue", "color-white-gray"];

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    const panels = document.getElementsByClassName("panel");
    const scroll = window.scrollY + window.innerHeight / 1.5;

    for (let i = 0; i < panels.length; i++) {
      const panel = panels[i] as HTMLElement;

      if (
        panel.offsetTop <= scroll &&
        panel.offsetTop + panel.offsetHeight > scroll
      ) {
        for (let j = 0; j < panels.length; j++) {
          this.colorClassesNames.map((className) =>
            panels[j].classList.remove(className)
          );
          panels[j].classList.add(this.colorClassesNames[i]);
        }
      }
    }
  }

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    public urlService: UrlService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit(): void {
    if (!this.urlService.checkUrlCo()) {
    }
    this.initPartnerForm();
    this.initPendingPartnerDetailForm();
  }

  learnMoreAsPartner(
    emailAddress: string,
    firstname: string,
    lastName: string,
    role: string,
    state: string,
    companyName: string,
    phone: string
  ) {
    //make restfull call to send message
    /* this.writeApi.sendMessage({
     messageType: "LearnMoreAsPartner",
     message: {
       "firstname": firstname,
       "lastname": lastName,
       "email": emailAddress,
       "companyName": companyName,
       "phone": phone,
       "role": role,
       "state": state
     }
   });
   //.then(_=> /*show its been sent );*/
  }

  private initPartnerForm() {
    this.partnerForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      businessName: ["", [Validators.required]],
      state: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      role: ["", [Validators.required]],
    });
  }

  private initPendingPartnerDetailForm() {
    this.pendingPartnerDetailForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      company: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      customertype: ["", [Validators.required]]
    });
  }

  recaptcha: any;
  submit() {
    this.pendingPartner = true;
    if (this.partnerForm.valid) {
      this.recaptcha = this.recaptchaV3Service.execute('partner')
        .subscribe(
          (token) => {
            this.apiService
              .sendMessage("LearnMoreAsPartner", this.partnerForm.value)
              .pipe(finalize(() => (this.pendingPartner = false)))
              .subscribe(
                (data) => {
                  this.toastr.success("Your details has been sent");
                },
                (error) => {
                  this.toastr.error("Server Error. Try again later");
                }
              );
          },
          (error) => {
            this.toastr.error("Recaptcha Error");
          }
        );

    } else {
      this.toastr.error("Form is invalid. Enter correct information");
      this.pendingPartner = false;
    }
  }

  submitPendingPartnerDetail() {
    this.pendingPartner = true;
    if (this.pendingPartnerDetailForm.valid) {
      this.apiService
        .sendMessage("LearnMoreAsPartner", this.pendingPartnerDetailForm.value)
        .pipe(finalize(() => (this.pendingPartner = false)))
        .subscribe(
          (data) => {
            this.pendingPartnerDetailForm.reset();
            this.toastr.success("Your details has been sent");
          },
          (error) => {
            this.toastr.error("Server Error. Try again later");
          }
        );
    } else {
      if (this.pendingPartnerDetailForm.controls["firstname"].invalid) {
        this.toastr.error("First name is required");
      }
      if (this.pendingPartnerDetailForm.controls["lastname"].invalid) {
        this.toastr.error("Last name is required");
      }
      if (this.pendingPartnerDetailForm.controls["phone"].invalid) {
        this.toastr.error("Phone is invalid");
      }
      if (this.pendingPartnerDetailForm.controls["company"].invalid) {
        this.toastr.error("Company is required");
      }
      if (this.pendingPartnerDetailForm.controls["email"].invalid) {
        this.toastr.error("Email address is invalid");
      }
      if (this.pendingPartnerDetailForm.controls["customertype"].invalid) {
        this.toastr.error("Customer type is invalid");
      }
      this.pendingPartner = false;
    }
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }


  ngOnDestroy() {
    if (this.recaptcha) {
      this.recaptcha.unsubscribe();
    }
  }
}
