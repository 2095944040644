import { DOCUMENT } from "@angular/common";
import { Component, Inject, HostListener } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { LoggingService } from "./shared/services/logging.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  scale = 0.8;
  constructor(
    private monitoringService: LoggingService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: window["appConfig"]["instrumentationKey"],
      },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    let defaultContentWidth = window["appConfig"]["defaultContentWidth"];
    let scale = (window.innerWidth * 1.0) / defaultContentWidth;

    if (scale < 0.5) {
      scale = 0.5;
    }

    this.document.documentElement.style.setProperty(
      "--global-scale",
      scale.toString()
    );
  }

  ngOnInit() {}

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    let defaultContentWidth = window["appConfig"]["defaultContentWidth"];
    let scale = (window.innerWidth * 1.0) / defaultContentWidth;

    if (scale < 0.5) {
      scale = 0.5;
    }

    this.document.documentElement.style.setProperty(
      "--global-scale",
      scale.toString()
    );
  }
}
