/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as Parameters from "./models/parameters";
import { OriginationWriteApiContext } from "./originationWriteApiContext";

class OriginationWriteApi extends OriginationWriteApiContext {
  /**
   * Initializes a new instance of the OriginationWriteApi class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.OriginationWriteApiOptions) {
    super(credentials, options);
  }

  /**
   * @param applicationId
   * @param applicant
   * @param [options] The optional parameters
   * @returns Promise<Models.AddPrimaryApplicantDetailsResponse>
   */
  addPrimaryApplicantDetails(applicationId: string, applicant: Models.PrimaryApplicantAndSecondaryApplicantRequested, options?: Models.OriginationWriteApiAddPrimaryApplicantDetailsOptionalParams): Promise<Models.AddPrimaryApplicantDetailsResponse>;
  /**
   * @param applicationId
   * @param applicant
   * @param callback The callback
   */
  addPrimaryApplicantDetails(applicationId: string, applicant: Models.PrimaryApplicantAndSecondaryApplicantRequested, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param applicant
   * @param options The optional parameters
   * @param callback The callback
   */
  addPrimaryApplicantDetails(applicationId: string, applicant: Models.PrimaryApplicantAndSecondaryApplicantRequested, options: Models.OriginationWriteApiAddPrimaryApplicantDetailsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  addPrimaryApplicantDetails(applicationId: string, applicant: Models.PrimaryApplicantAndSecondaryApplicantRequested, options?: Models.OriginationWriteApiAddPrimaryApplicantDetailsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AddPrimaryApplicantDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        applicant,
        options
      },
      addPrimaryApplicantDetailsOperationSpec,
      callback) as Promise<Models.AddPrimaryApplicantDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param applicant
   * @param [options] The optional parameters
   * @returns Promise<Models.AddSecondaryApplicantDetailsResponse>
   */
  addSecondaryApplicantDetails(applicationId: string, applicant: Models.SecondaryApplicant, options?: Models.OriginationWriteApiAddSecondaryApplicantDetailsOptionalParams): Promise<Models.AddSecondaryApplicantDetailsResponse>;
  /**
   * @param applicationId
   * @param applicant
   * @param callback The callback
   */
  addSecondaryApplicantDetails(applicationId: string, applicant: Models.SecondaryApplicant, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param applicant
   * @param options The optional parameters
   * @param callback The callback
   */
  addSecondaryApplicantDetails(applicationId: string, applicant: Models.SecondaryApplicant, options: Models.OriginationWriteApiAddSecondaryApplicantDetailsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  addSecondaryApplicantDetails(applicationId: string, applicant: Models.SecondaryApplicant, options?: Models.OriginationWriteApiAddSecondaryApplicantDetailsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AddSecondaryApplicantDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        applicant,
        options
      },
      addSecondaryApplicantDetailsOperationSpec,
      callback) as Promise<Models.AddSecondaryApplicantDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendSecondaryApplicantNotice(applicationId: string, options?: Models.OriginationWriteApiSendSecondaryApplicantNoticeOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendSecondaryApplicantNotice(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendSecondaryApplicantNotice(applicationId: string, options: Models.OriginationWriteApiSendSecondaryApplicantNoticeOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendSecondaryApplicantNotice(applicationId: string, options?: Models.OriginationWriteApiSendSecondaryApplicantNoticeOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendSecondaryApplicantNoticeOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicantDetails(applicationId: string, options?: Models.OriginationWriteApiUpdateApplicantDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicantDetails(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicantDetails(applicationId: string, options: Models.OriginationWriteApiUpdateApplicantDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateApplicantDetails(applicationId: string, options?: Models.OriginationWriteApiUpdateApplicantDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicantDetailsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerList(options?: Models.OriginationWriteApiUpdatePartnerListOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updatePartnerList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerList(options: Models.OriginationWriteApiUpdatePartnerListOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updatePartnerList(options?: Models.OriginationWriteApiUpdatePartnerListOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartnerListOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPrimaryAccount(applicationId: string, options?: Models.OriginationWriteApiSetPrimaryAccountOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  setPrimaryAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  setPrimaryAccount(applicationId: string, options: Models.OriginationWriteApiSetPrimaryAccountOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setPrimaryAccount(applicationId: string, options?: Models.OriginationWriteApiSetPrimaryAccountOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      setPrimaryAccountOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendMessage(options?: Models.OriginationWriteApiSendMessageOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendMessage(options: Models.OriginationWriteApiSendMessageOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendMessage(options?: Models.OriginationWriteApiSendMessageOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendMessageOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendSimpleMessage(options?: Models.OriginationWriteApiSendSimpleMessageOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendSimpleMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendSimpleMessage(options: Models.OriginationWriteApiSendSimpleMessageOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendSimpleMessage(options?: Models.OriginationWriteApiSendSimpleMessageOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendSimpleMessageOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendApplicationReady(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendApplicationReady(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendApplicationReady(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  sendApplicationReady(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendApplicationReadyOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CompleteApplicationResponse>
   */
  completeApplication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.CompleteApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  completeApplication(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  completeApplication(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  completeApplication(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.CompleteApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      completeApplicationOperationSpec,
      callback) as Promise<Models.CompleteApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resetLogin(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  resetLogin(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  resetLogin(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  resetLogin(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      resetLoginOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateApplicationResponse>
   */
  createApplication(options?: Models.OriginationWriteApiCreateApplicationOptionalParams): Promise<Models.CreateApplicationResponse>;
  /**
   * @param callback The callback
   */
  createApplication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApplication(options: Models.OriginationWriteApiCreateApplicationOptionalParams, callback: msRest.ServiceCallback<string>): void;
  createApplication(options?: Models.OriginationWriteApiCreateApplicationOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.CreateApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createApplicationOperationSpec,
      callback) as Promise<Models.CreateApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateApplicationAndCustomerResponse>
   */
  createApplicationAndCustomer(options?: Models.OriginationWriteApiCreateApplicationAndCustomerOptionalParams): Promise<Models.CreateApplicationAndCustomerResponse>;
  /**
   * @param callback The callback
   */
  createApplicationAndCustomer(callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createApplicationAndCustomer(options: Models.OriginationWriteApiCreateApplicationAndCustomerOptionalParams, callback: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>): void;
  createApplicationAndCustomer(options?: Models.OriginationWriteApiCreateApplicationAndCustomerOptionalParams | msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>, callback?: msRest.ServiceCallback<Models.ApplicationAndCustomerResponse>): Promise<Models.CreateApplicationAndCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createApplicationAndCustomerOperationSpec,
      callback) as Promise<Models.CreateApplicationAndCustomerResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateCustomerResponse>
   */
  updateCustomer(options?: Models.OriginationWriteApiUpdateCustomerOptionalParams): Promise<Models.UpdateCustomerResponse>;
  /**
   * @param callback The callback
   */
  updateCustomer(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomer(options: Models.OriginationWriteApiUpdateCustomerOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateCustomer(options?: Models.OriginationWriteApiUpdateCustomerOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateCustomerOperationSpec,
      callback) as Promise<Models.UpdateCustomerResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ChangeStatusResponse>
   */
  changeStatus(options?: Models.OriginationWriteApiChangeStatusOptionalParams): Promise<Models.ChangeStatusResponse>;
  /**
   * @param callback The callback
   */
  changeStatus(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  changeStatus(options: Models.OriginationWriteApiChangeStatusOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  changeStatus(options?: Models.OriginationWriteApiChangeStatusOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.ChangeStatusResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      changeStatusOperationSpec,
      callback) as Promise<Models.ChangeStatusResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdatePartnerResponse>
   */
  updatePartner(options?: Models.OriginationWriteApiUpdatePartnerOptionalParams): Promise<Models.UpdatePartnerResponse>;
  /**
   * @param callback The callback
   */
  updatePartner(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartner(options: Models.OriginationWriteApiUpdatePartnerOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updatePartner(options?: Models.OriginationWriteApiUpdatePartnerOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdatePartnerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartnerOperationSpec,
      callback) as Promise<Models.UpdatePartnerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBusinessNameResponse>
   */
  updateBusinessName(applicationId: string, options?: Models.OriginationWriteApiUpdateBusinessNameOptionalParams): Promise<Models.UpdateBusinessNameResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateBusinessName(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBusinessName(applicationId: string, options: Models.OriginationWriteApiUpdateBusinessNameOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateBusinessName(applicationId: string, options?: Models.OriginationWriteApiUpdateBusinessNameOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateBusinessNameResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateBusinessNameOperationSpec,
      callback) as Promise<Models.UpdateBusinessNameResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBusinessDetailsResponse>
   */
  updateBusinessDetails(applicationId: string, options?: Models.OriginationWriteApiUpdateBusinessDetailsOptionalParams): Promise<Models.UpdateBusinessDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateBusinessDetails(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBusinessDetails(applicationId: string, options: Models.OriginationWriteApiUpdateBusinessDetailsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateBusinessDetails(applicationId: string, options?: Models.OriginationWriteApiUpdateBusinessDetailsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateBusinessDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateBusinessDetailsOperationSpec,
      callback) as Promise<Models.UpdateBusinessDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateApplicationResponse>
   */
  updateApplication(options?: Models.OriginationWriteApiUpdateApplicationOptionalParams): Promise<Models.UpdateApplicationResponse>;
  /**
   * @param callback The callback
   */
  updateApplication(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplication(options: Models.OriginationWriteApiUpdateApplicationOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateApplication(options?: Models.OriginationWriteApiUpdateApplicationOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateApplicationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateApplicationOperationSpec,
      callback) as Promise<Models.UpdateApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateApplicationStatusResponse>
   */
  updateApplicationStatus(options?: Models.OriginationWriteApiUpdateApplicationStatusOptionalParams): Promise<Models.UpdateApplicationStatusResponse>;
  /**
   * @param callback The callback
   */
  updateApplicationStatus(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicationStatus(options: Models.OriginationWriteApiUpdateApplicationStatusOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  updateApplicationStatus(options?: Models.OriginationWriteApiUpdateApplicationStatusOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.UpdateApplicationStatusResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateApplicationStatusOperationSpec,
      callback) as Promise<Models.UpdateApplicationStatusResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateOfferResponse>
   */
  updateOffer(options?: Models.OriginationWriteApiUpdateOfferOptionalParams): Promise<Models.UpdateOfferResponse>;
  /**
   * @param callback The callback
   */
  updateOffer(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateOffer(options: Models.OriginationWriteApiUpdateOfferOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateOffer(options?: Models.OriginationWriteApiUpdateOfferOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateOfferResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateOfferOperationSpec,
      callback) as Promise<Models.UpdateOfferResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateLoginStatusResponse>
   */
  updateLoginStatus(options?: Models.OriginationWriteApiUpdateLoginStatusOptionalParams): Promise<Models.UpdateLoginStatusResponse>;
  /**
   * @param callback The callback
   */
  updateLoginStatus(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoginStatus(options: Models.OriginationWriteApiUpdateLoginStatusOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateLoginStatus(options?: Models.OriginationWriteApiUpdateLoginStatusOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateLoginStatusResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateLoginStatusOperationSpec,
      callback) as Promise<Models.UpdateLoginStatusResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendConnectionEmail(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendConnectionEmail(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendConnectionEmail(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  sendConnectionEmail(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendConnectionEmailOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterCommunicationResponse>
   */
  registerCommunication(applicationId: string, options?: Models.OriginationWriteApiRegisterCommunicationOptionalParams): Promise<Models.RegisterCommunicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  registerCommunication(applicationId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerCommunication(applicationId: string, options: Models.OriginationWriteApiRegisterCommunicationOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  registerCommunication(applicationId: string, options?: Models.OriginationWriteApiRegisterCommunicationOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.RegisterCommunicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      registerCommunicationOperationSpec,
      callback) as Promise<Models.RegisterCommunicationResponse>;
  }

  /**
   * @param applicationId
   * @param communicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unregisterCommunication(applicationId: string, communicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param communicationId
   * @param callback The callback
   */
  unregisterCommunication(applicationId: string, communicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param communicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterCommunication(applicationId: string, communicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  unregisterCommunication(applicationId: string, communicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        communicationId,
        options
      },
      unregisterCommunicationOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  communicationAdded(options?: Models.OriginationWriteApiCommunicationAddedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  communicationAdded(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  communicationAdded(options: Models.OriginationWriteApiCommunicationAddedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  communicationAdded(options?: Models.OriginationWriteApiCommunicationAddedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      communicationAddedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendConnectionLink(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  sendConnectionLink(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendConnectionLink(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  sendConnectionLink(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      sendConnectionLinkOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  cancelApplication(applicationId: string, options?: Models.OriginationWriteApiCancelApplicationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  cancelApplication(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  cancelApplication(applicationId: string, options: Models.OriginationWriteApiCancelApplicationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  cancelApplication(applicationId: string, options?: Models.OriginationWriteApiCancelApplicationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      cancelApplicationOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  submittedApplication(applicationId: string, options?: Models.OriginationWriteApiSubmittedApplicationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  submittedApplication(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  submittedApplication(applicationId: string, options: Models.OriginationWriteApiSubmittedApplicationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  submittedApplication(applicationId: string, options?: Models.OriginationWriteApiSubmittedApplicationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      submittedApplicationOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  linkBankingData(applicationId: string, options?: Models.OriginationWriteApiLinkBankingDataOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  linkBankingData(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  linkBankingData(applicationId: string, options: Models.OriginationWriteApiLinkBankingDataOptionalParams, callback: msRest.ServiceCallback<void>): void;
  linkBankingData(applicationId: string, options?: Models.OriginationWriteApiLinkBankingDataOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      linkBankingDataOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  uploadBankStatementsDocumentId(applicationId: string, options?: Models.OriginationWriteApiUploadBankStatementsDocumentIdOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  uploadBankStatementsDocumentId(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  uploadBankStatementsDocumentId(applicationId: string, options: Models.OriginationWriteApiUploadBankStatementsDocumentIdOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadBankStatementsDocumentId(applicationId: string, options?: Models.OriginationWriteApiUploadBankStatementsDocumentIdOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      uploadBankStatementsDocumentIdOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  linkAccoutingData(applicationId: string, options?: Models.OriginationWriteApiLinkAccoutingDataOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  linkAccoutingData(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  linkAccoutingData(applicationId: string, options: Models.OriginationWriteApiLinkAccoutingDataOptionalParams, callback: msRest.ServiceCallback<void>): void;
  linkAccoutingData(applicationId: string, options?: Models.OriginationWriteApiLinkAccoutingDataOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      linkAccoutingDataOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  completed(applicationId: string, options?: Models.OriginationWriteApiCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  completed(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  completed(applicationId: string, options: Models.OriginationWriteApiCompletedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  completed(applicationId: string, options?: Models.OriginationWriteApiCompletedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      completedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refer(applicationId: string, options?: Models.OriginationWriteApiReferOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  refer(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  refer(applicationId: string, options: Models.OriginationWriteApiReferOptionalParams, callback: msRest.ServiceCallback<void>): void;
  refer(applicationId: string, options?: Models.OriginationWriteApiReferOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      referOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankAccounts(applicationId: string, options?: Models.OriginationWriteApiConfirmBankAccountsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankAccounts(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankAccounts(applicationId: string, options: Models.OriginationWriteApiConfirmBankAccountsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  confirmBankAccounts(applicationId: string, options?: Models.OriginationWriteApiConfirmBankAccountsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankAccountsOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectBankfeedAccount(applicationId: string, options?: Models.OriginationWriteApiConnectBankfeedAccountOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  connectBankfeedAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  connectBankfeedAccount(applicationId: string, options: Models.OriginationWriteApiConnectBankfeedAccountOptionalParams, callback: msRest.ServiceCallback<void>): void;
  connectBankfeedAccount(applicationId: string, options?: Models.OriginationWriteApiConnectBankfeedAccountOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      connectBankfeedAccountOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param status
   * @param message
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectYodleeBankfeedAccount(applicationId: string, status: boolean, message: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param status
   * @param message
   * @param callback The callback
   */
  connectYodleeBankfeedAccount(applicationId: string, status: boolean, message: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param status
   * @param message
   * @param options The optional parameters
   * @param callback The callback
   */
  connectYodleeBankfeedAccount(applicationId: string, status: boolean, message: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  connectYodleeBankfeedAccount(applicationId: string, status: boolean, message: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        status,
        message,
        options
      },
      connectYodleeBankfeedAccountOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param status
   * @param message
   * @param publicToken
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectPlaidBankfeedAccount(applicationId: string, status: boolean, message: string, publicToken: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param status
   * @param message
   * @param publicToken
   * @param callback The callback
   */
  connectPlaidBankfeedAccount(applicationId: string, status: boolean, message: string, publicToken: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param status
   * @param message
   * @param publicToken
   * @param options The optional parameters
   * @param callback The callback
   */
  connectPlaidBankfeedAccount(applicationId: string, status: boolean, message: string, publicToken: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  connectPlaidBankfeedAccount(applicationId: string, status: boolean, message: string, publicToken: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        status,
        message,
        publicToken,
        options
      },
      connectPlaidBankfeedAccountOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedAccount(applicationId: string, options?: Models.OriginationWriteApiConfirmBankfeedAccountOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedAccount(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedAccount(applicationId: string, options: Models.OriginationWriteApiConfirmBankfeedAccountOptionalParams, callback: msRest.ServiceCallback<void>): void;
  confirmBankfeedAccount(applicationId: string, options?: Models.OriginationWriteApiConfirmBankfeedAccountOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedAccountOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedAccounts(applicationId: string, options?: Models.OriginationWriteApiConfirmBankfeedAccountsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedAccounts(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedAccounts(applicationId: string, options: Models.OriginationWriteApiConfirmBankfeedAccountsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  confirmBankfeedAccounts(applicationId: string, options?: Models.OriginationWriteApiConfirmBankfeedAccountsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedAccountsOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedYodlee(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedYodlee(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedYodlee(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  confirmBankfeedYodlee(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedYodleeOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedPlaid(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedPlaid(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedPlaid(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  confirmBankfeedPlaid(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedPlaidOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SendApplicationOtpResponse>
   */
  sendApplicationOtp(options?: Models.OriginationWriteApiSendApplicationOtpOptionalParams): Promise<Models.SendApplicationOtpResponse>;
  /**
   * @param callback The callback
   */
  sendApplicationOtp(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendApplicationOtp(options: Models.OriginationWriteApiSendApplicationOtpOptionalParams, callback: msRest.ServiceCallback<string>): void;
  sendApplicationOtp(options?: Models.OriginationWriteApiSendApplicationOtpOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.SendApplicationOtpResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendApplicationOtpOperationSpec,
      callback) as Promise<Models.SendApplicationOtpResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendLoginOtp(options?: Models.OriginationWriteApiSendLoginOtpOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  sendLoginOtp(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  sendLoginOtp(options: Models.OriginationWriteApiSendLoginOtpOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendLoginOtp(options?: Models.OriginationWriteApiSendLoginOtpOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      sendLoginOtpOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  confirmBankfeedMFADetails(applicationId: string, options?: Models.OriginationWriteApiConfirmBankfeedMFADetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  confirmBankfeedMFADetails(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  confirmBankfeedMFADetails(applicationId: string, options: Models.OriginationWriteApiConfirmBankfeedMFADetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  confirmBankfeedMFADetails(applicationId: string, options?: Models.OriginationWriteApiConfirmBankfeedMFADetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      confirmBankfeedMFADetailsOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addTrustDetails(applicationId: string, options?: Models.OriginationWriteApiAddTrustDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addTrustDetails(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addTrustDetails(applicationId: string, options: Models.OriginationWriteApiAddTrustDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addTrustDetails(applicationId: string, options?: Models.OriginationWriteApiAddTrustDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addTrustDetailsOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  contractComplete(applicationId: string, options?: Models.OriginationWriteApiContractCompleteOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  contractComplete(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  contractComplete(applicationId: string, options: Models.OriginationWriteApiContractCompleteOptionalParams, callback: msRest.ServiceCallback<void>): void;
  contractComplete(applicationId: string, options?: Models.OriginationWriteApiContractCompleteOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      contractCompleteOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatedContractCompleted(applicationId: string, options?: Models.OriginationWriteApiUpdatedContractCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updatedContractCompleted(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatedContractCompleted(applicationId: string, options: Models.OriginationWriteApiUpdatedContractCompletedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updatedContractCompleted(applicationId: string, options?: Models.OriginationWriteApiUpdatedContractCompletedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updatedContractCompletedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param offerId
   * @param secured
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  acceptOffer(applicationId: string, offerId: string, secured: number, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param offerId
   * @param secured
   * @param callback The callback
   */
  acceptOffer(applicationId: string, offerId: string, secured: number, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param offerId
   * @param secured
   * @param options The optional parameters
   * @param callback The callback
   */
  acceptOffer(applicationId: string, offerId: string, secured: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  acceptOffer(applicationId: string, offerId: string, secured: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        offerId,
        secured,
        options
      },
      acceptOfferOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GenerateOfferResponse>
   */
  generateOffer(applicationId: string, options?: Models.OriginationWriteApiGenerateOfferOptionalParams): Promise<Models.GenerateOfferResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  generateOffer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  generateOffer(applicationId: string, options: Models.OriginationWriteApiGenerateOfferOptionalParams, callback: msRest.ServiceCallback<string>): void;
  generateOffer(applicationId: string, options?: Models.OriginationWriteApiGenerateOfferOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GenerateOfferResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      generateOfferOperationSpec,
      callback) as Promise<Models.GenerateOfferResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  commissionMethod(applicationId: string, options?: Models.OriginationWriteApiCommissionMethodOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  commissionMethod(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  commissionMethod(applicationId: string, options: Models.OriginationWriteApiCommissionMethodOptionalParams, callback: msRest.ServiceCallback<void>): void;
  commissionMethod(applicationId: string, options?: Models.OriginationWriteApiCommissionMethodOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      commissionMethodOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectAccountingCompany(applicationId: string, options?: Models.OriginationWriteApiConnectAccountingCompanyOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  connectAccountingCompany(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  connectAccountingCompany(applicationId: string, options: Models.OriginationWriteApiConnectAccountingCompanyOptionalParams, callback: msRest.ServiceCallback<void>): void;
  connectAccountingCompany(applicationId: string, options?: Models.OriginationWriteApiConnectAccountingCompanyOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      connectAccountingCompanyOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connectAccountingCompanyWithDetails(applicationId: string, options?: Models.OriginationWriteApiConnectAccountingCompanyWithDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  connectAccountingCompanyWithDetails(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  connectAccountingCompanyWithDetails(applicationId: string, options: Models.OriginationWriteApiConnectAccountingCompanyWithDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  connectAccountingCompanyWithDetails(applicationId: string, options?: Models.OriginationWriteApiConnectAccountingCompanyWithDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      connectAccountingCompanyWithDetailsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  offerCompleted(options?: Models.OriginationWriteApiOfferCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  offerCompleted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  offerCompleted(options: Models.OriginationWriteApiOfferCompletedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  offerCompleted(options?: Models.OriginationWriteApiOfferCompletedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      offerCompletedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  offerFailed(options?: Models.OriginationWriteApiOfferFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  offerFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  offerFailed(options: Models.OriginationWriteApiOfferFailedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  offerFailed(options?: Models.OriginationWriteApiOfferFailedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      offerFailedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankingSystemConnected(options?: Models.OriginationWriteApiBankingSystemConnectedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankingSystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankingSystemConnected(options: Models.OriginationWriteApiBankingSystemConnectedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  bankingSystemConnected(options?: Models.OriginationWriteApiBankingSystemConnectedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankingSystemConnectedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemConnected(options?: Models.OriginationWriteApiBankfeedSystemConnectedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemConnected(options: Models.OriginationWriteApiBankfeedSystemConnectedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  bankfeedSystemConnected(options?: Models.OriginationWriteApiBankfeedSystemConnectedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemConnectedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankingSystemFailed(options?: Models.OriginationWriteApiBankingSystemFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankingSystemFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankingSystemFailed(options: Models.OriginationWriteApiBankingSystemFailedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  bankingSystemFailed(options?: Models.OriginationWriteApiBankingSystemFailedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankingSystemFailedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemFailed(options?: Models.OriginationWriteApiBankfeedSystemFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemFailed(options: Models.OriginationWriteApiBankfeedSystemFailedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  bankfeedSystemFailed(options?: Models.OriginationWriteApiBankfeedSystemFailedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemFailedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingSystemConnected(options?: Models.OriginationWriteApiAccountingSystemConnectedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingSystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingSystemConnected(options: Models.OriginationWriteApiAccountingSystemConnectedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingSystemConnected(options?: Models.OriginationWriteApiAccountingSystemConnectedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingSystemConnectedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingCompanySystemConnected(options?: Models.OriginationWriteApiAccountingCompanySystemConnectedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingCompanySystemConnected(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingCompanySystemConnected(options: Models.OriginationWriteApiAccountingCompanySystemConnectedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingCompanySystemConnected(options?: Models.OriginationWriteApiAccountingCompanySystemConnectedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingCompanySystemConnectedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingCompanySystemConnectedWithDetails(options?: Models.OriginationWriteApiAccountingCompanySystemConnectedWithDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingCompanySystemConnectedWithDetails(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingCompanySystemConnectedWithDetails(options: Models.OriginationWriteApiAccountingCompanySystemConnectedWithDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingCompanySystemConnectedWithDetails(options?: Models.OriginationWriteApiAccountingCompanySystemConnectedWithDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingCompanySystemConnectedWithDetailsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingSystemFailed(options?: Models.OriginationWriteApiAccountingSystemFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingSystemFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingSystemFailed(options: Models.OriginationWriteApiAccountingSystemFailedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingSystemFailed(options?: Models.OriginationWriteApiAccountingSystemFailedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingSystemFailedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankingSystemAccountsReady(options?: Models.OriginationWriteApiBankingSystemAccountsReadyOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankingSystemAccountsReady(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankingSystemAccountsReady(options: Models.OriginationWriteApiBankingSystemAccountsReadyOptionalParams, callback: msRest.ServiceCallback<void>): void;
  bankingSystemAccountsReady(options?: Models.OriginationWriteApiBankingSystemAccountsReadyOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankingSystemAccountsReadyOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemAccountsReady(options?: Models.OriginationWriteApiBankfeedSystemAccountsReadyOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemAccountsReady(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemAccountsReady(options: Models.OriginationWriteApiBankfeedSystemAccountsReadyOptionalParams, callback: msRest.ServiceCallback<void>): void;
  bankfeedSystemAccountsReady(options?: Models.OriginationWriteApiBankfeedSystemAccountsReadyOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemAccountsReadyOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  bankfeedSystemMFAReady(options?: Models.OriginationWriteApiBankfeedSystemMFAReadyOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  bankfeedSystemMFAReady(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  bankfeedSystemMFAReady(options: Models.OriginationWriteApiBankfeedSystemMFAReadyOptionalParams, callback: msRest.ServiceCallback<void>): void;
  bankfeedSystemMFAReady(options?: Models.OriginationWriteApiBankfeedSystemMFAReadyOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      bankfeedSystemMFAReadyOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  organisationCreated(options?: Models.OriginationWriteApiOrganisationCreatedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  organisationCreated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  organisationCreated(options: Models.OriginationWriteApiOrganisationCreatedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  organisationCreated(options?: Models.OriginationWriteApiOrganisationCreatedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      organisationCreatedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  organisationFailed(options?: Models.OriginationWriteApiOrganisationFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  organisationFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  organisationFailed(options: Models.OriginationWriteApiOrganisationFailedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  organisationFailed(options?: Models.OriginationWriteApiOrganisationFailedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      organisationFailedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationCanBeCompleted(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationCanBeCompleted(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationCanBeCompleted(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  applicationCanBeCompleted(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationCanBeCompletedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationCanNotBeCompleted(applicationId: string, options?: Models.OriginationWriteApiApplicationCanNotBeCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationCanNotBeCompleted(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationCanNotBeCompleted(applicationId: string, options: Models.OriginationWriteApiApplicationCanNotBeCompletedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  applicationCanNotBeCompleted(applicationId: string, options?: Models.OriginationWriteApiApplicationCanNotBeCompletedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationCanNotBeCompletedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loanCreated(applicationId: string, options?: Models.OriginationWriteApiLoanCreatedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  loanCreated(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  loanCreated(applicationId: string, options: Models.OriginationWriteApiLoanCreatedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  loanCreated(applicationId: string, options?: Models.OriginationWriteApiLoanCreatedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      loanCreatedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationOfferUpdated(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationOfferUpdated(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationOfferUpdated(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  applicationOfferUpdated(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationOfferUpdatedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingProcessed(options?: Models.OriginationWriteApiAccountingProcessedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingProcessed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingProcessed(options: Models.OriginationWriteApiAccountingProcessedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingProcessed(options?: Models.OriginationWriteApiAccountingProcessedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingProcessedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingProcessedError(options?: Models.OriginationWriteApiAccountingProcessedErrorOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingProcessedError(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingProcessedError(options: Models.OriginationWriteApiAccountingProcessedErrorOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingProcessedError(options?: Models.OriginationWriteApiAccountingProcessedErrorOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingProcessedErrorOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingQueueStarted(options?: Models.OriginationWriteApiAccountingQueueStartedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingQueueStarted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingQueueStarted(options: Models.OriginationWriteApiAccountingQueueStartedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingQueueStarted(options?: Models.OriginationWriteApiAccountingQueueStartedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingQueueStartedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  accountingQueueWait(options?: Models.OriginationWriteApiAccountingQueueWaitOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  accountingQueueWait(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  accountingQueueWait(options: Models.OriginationWriteApiAccountingQueueWaitOptionalParams, callback: msRest.ServiceCallback<void>): void;
  accountingQueueWait(options?: Models.OriginationWriteApiAccountingQueueWaitOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      accountingQueueWaitOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  statementProcessed(options?: Models.OriginationWriteApiStatementProcessedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  statementProcessed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  statementProcessed(options: Models.OriginationWriteApiStatementProcessedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  statementProcessed(options?: Models.OriginationWriteApiStatementProcessedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      statementProcessedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicationStatusChanged(options?: Models.OriginationWriteApiApplicationStatusChangedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  applicationStatusChanged(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationStatusChanged(options: Models.OriginationWriteApiApplicationStatusChangedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  applicationStatusChanged(options?: Models.OriginationWriteApiApplicationStatusChangedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicationStatusChangedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  contractSigned(options?: Models.OriginationWriteApiContractSignedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  contractSigned(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  contractSigned(options: Models.OriginationWriteApiContractSignedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  contractSigned(options?: Models.OriginationWriteApiContractSignedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      contractSignedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshEquifax(options?: Models.OriginationWriteApiRefreshEquifaxOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshEquifax(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshEquifax(options: Models.OriginationWriteApiRefreshEquifaxOptionalParams, callback: msRest.ServiceCallback<void>): void;
  refreshEquifax(options?: Models.OriginationWriteApiRefreshEquifaxOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshEquifaxOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshEquifaxFailed(options?: Models.OriginationWriteApiRefreshEquifaxFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshEquifaxFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshEquifaxFailed(options: Models.OriginationWriteApiRefreshEquifaxFailedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  refreshEquifaxFailed(options?: Models.OriginationWriteApiRefreshEquifaxFailedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshEquifaxFailedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshIllion(options?: Models.OriginationWriteApiRefreshIllionOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshIllion(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshIllion(options: Models.OriginationWriteApiRefreshIllionOptionalParams, callback: msRest.ServiceCallback<void>): void;
  refreshIllion(options?: Models.OriginationWriteApiRefreshIllionOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshIllionOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  refreshIllionFailed(options?: Models.OriginationWriteApiRefreshIllionFailedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  refreshIllionFailed(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshIllionFailed(options: Models.OriginationWriteApiRefreshIllionFailedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  refreshIllionFailed(options?: Models.OriginationWriteApiRefreshIllionFailedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshIllionFailedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  applicantIsReadyForEvaluation(options?: Models.OriginationWriteApiApplicantIsReadyForEvaluationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  applicantIsReadyForEvaluation(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicantIsReadyForEvaluation(options: Models.OriginationWriteApiApplicantIsReadyForEvaluationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  applicantIsReadyForEvaluation(options?: Models.OriginationWriteApiApplicantIsReadyForEvaluationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicantIsReadyForEvaluationOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicationBusiness(options?: Models.OriginationWriteApiUpdateApplicationBusinessOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateApplicationBusiness(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicationBusiness(options: Models.OriginationWriteApiUpdateApplicationBusinessOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateApplicationBusiness(options?: Models.OriginationWriteApiUpdateApplicationBusinessOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateApplicationBusinessOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateIncomeOnReconnect(options?: Models.OriginationWriteApiUpdateIncomeOnReconnectOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateIncomeOnReconnect(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateIncomeOnReconnect(options: Models.OriginationWriteApiUpdateIncomeOnReconnectOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateIncomeOnReconnect(options?: Models.OriginationWriteApiUpdateIncomeOnReconnectOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateIncomeOnReconnectOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.AddLoanCustomerResponse>
   */
  addLoanCustomer(applicationId: string, options?: Models.OriginationWriteApiAddLoanCustomerOptionalParams): Promise<Models.AddLoanCustomerResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addLoanCustomer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addLoanCustomer(applicationId: string, options: Models.OriginationWriteApiAddLoanCustomerOptionalParams, callback: msRest.ServiceCallback<string>): void;
  addLoanCustomer(applicationId: string, options?: Models.OriginationWriteApiAddLoanCustomerOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AddLoanCustomerResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addLoanCustomerOperationSpec,
      callback) as Promise<Models.AddLoanCustomerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateLoanCustomerResponse>
   */
  updateLoanCustomer(applicationId: string, options?: Models.OriginationWriteApiUpdateLoanCustomerOptionalParams): Promise<Models.UpdateLoanCustomerResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateLoanCustomer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanCustomer(applicationId: string, options: Models.OriginationWriteApiUpdateLoanCustomerOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateLoanCustomer(applicationId: string, options?: Models.OriginationWriteApiUpdateLoanCustomerOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateLoanCustomerResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateLoanCustomerOperationSpec,
      callback) as Promise<Models.UpdateLoanCustomerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.RemoveLoanCustomerResponse>
   */
  removeLoanCustomer(applicationId: string, options?: Models.OriginationWriteApiRemoveLoanCustomerOptionalParams): Promise<Models.RemoveLoanCustomerResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  removeLoanCustomer(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeLoanCustomer(applicationId: string, options: Models.OriginationWriteApiRemoveLoanCustomerOptionalParams, callback: msRest.ServiceCallback<string>): void;
  removeLoanCustomer(applicationId: string, options?: Models.OriginationWriteApiRemoveLoanCustomerOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.RemoveLoanCustomerResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      removeLoanCustomerOperationSpec,
      callback) as Promise<Models.RemoveLoanCustomerResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerExternalLink(applicationId: string, options?: Models.OriginationWriteApiRemovePartnerExternalLinkOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  removePartnerExternalLink(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerExternalLink(applicationId: string, options: Models.OriginationWriteApiRemovePartnerExternalLinkOptionalParams, callback: msRest.ServiceCallback<void>): void;
  removePartnerExternalLink(applicationId: string, options?: Models.OriginationWriteApiRemovePartnerExternalLinkOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      removePartnerExternalLinkOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateAuditDetails(loanId: string, options?: Models.OriginationWriteApiUpdateAuditDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateAuditDetails(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAuditDetails(loanId: string, options: Models.OriginationWriteApiUpdateAuditDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateAuditDetails(loanId: string, options?: Models.OriginationWriteApiUpdateAuditDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateAuditDetailsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadLoanDocument(loanId: string, options?: Models.OriginationWriteApiUploadLoanDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  uploadLoanDocument(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadLoanDocument(loanId: string, options: Models.OriginationWriteApiUploadLoanDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadLoanDocument(loanId: string, options?: Models.OriginationWriteApiUploadLoanDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      uploadLoanDocumentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadLoanDocumentsAsByteArray(loanId: string, options?: Models.OriginationWriteApiUploadLoanDocumentsAsByteArrayOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  uploadLoanDocumentsAsByteArray(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadLoanDocumentsAsByteArray(loanId: string, options: Models.OriginationWriteApiUploadLoanDocumentsAsByteArrayOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadLoanDocumentsAsByteArray(loanId: string, options?: Models.OriginationWriteApiUploadLoanDocumentsAsByteArrayOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      uploadLoanDocumentsAsByteArrayOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  auditComplete(loanId: string, options?: Models.OriginationWriteApiAuditCompleteOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  auditComplete(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  auditComplete(loanId: string, options: Models.OriginationWriteApiAuditCompleteOptionalParams, callback: msRest.ServiceCallback<void>): void;
  auditComplete(loanId: string, options?: Models.OriginationWriteApiAuditCompleteOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      auditCompleteOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  runAudit(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  runAudit(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  runAudit(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  runAudit(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      runAuditOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resetAuditDate(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  resetAuditDate(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  resetAuditDate(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  resetAuditDate(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      resetAuditDateOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  onOffAudit(loanId: string, options?: Models.OriginationWriteApiOnOffAuditOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  onOffAudit(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  onOffAudit(loanId: string, options: Models.OriginationWriteApiOnOffAuditOptionalParams, callback: msRest.ServiceCallback<void>): void;
  onOffAudit(loanId: string, options?: Models.OriginationWriteApiOnOffAuditOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      onOffAuditOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateAuditSettings(options?: Models.OriginationWriteApiUpdateAuditSettingsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateAuditSettings(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAuditSettings(options: Models.OriginationWriteApiUpdateAuditSettingsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateAuditSettings(options?: Models.OriginationWriteApiUpdateAuditSettingsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateAuditSettingsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadDocumentAuditComplete(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  uploadDocumentAuditComplete(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadDocumentAuditComplete(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  uploadDocumentAuditComplete(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      uploadDocumentAuditCompleteOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.AuditDocumentResponse>
   */
  auditDocument(loanId: string, options?: Models.OriginationWriteApiAuditDocumentOptionalParams): Promise<Models.AuditDocumentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  auditDocument(loanId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  auditDocument(loanId: string, options: Models.OriginationWriteApiAuditDocumentOptionalParams, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  auditDocument(loanId: string, options?: Models.OriginationWriteApiAuditDocumentOptionalParams | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.AuditDocumentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      auditDocumentOperationSpec,
      callback) as Promise<Models.AuditDocumentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCorpratePassword(options?: Models.OriginationWriteApiSetCorpratePasswordOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setCorpratePassword(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setCorpratePassword(options: Models.OriginationWriteApiSetCorpratePasswordOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setCorpratePassword(options?: Models.OriginationWriteApiSetCorpratePasswordOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setCorpratePasswordOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableCardService(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  enableCardService(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableCardService(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  enableCardService(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      enableCardServiceOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disableCardService(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  disableCardService(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  disableCardService(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  disableCardService(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      disableCardServiceOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCardConfiguration(loanId: string, options?: Models.OriginationWriteApiSetCardConfigurationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  setCardConfiguration(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  setCardConfiguration(loanId: string, options: Models.OriginationWriteApiSetCardConfigurationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setCardConfiguration(loanId: string, options?: Models.OriginationWriteApiSetCardConfigurationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      setCardConfigurationOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addCard(loanId: string, options?: Models.OriginationWriteApiAddCardOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  addCard(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  addCard(loanId: string, options: Models.OriginationWriteApiAddCardOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addCard(loanId: string, options?: Models.OriginationWriteApiAddCardOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      addCardOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCard(loanId: string, options?: Models.OriginationWriteApiUpdateCardOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateCard(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCard(loanId: string, options: Models.OriginationWriteApiUpdateCardOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateCard(loanId: string, options?: Models.OriginationWriteApiUpdateCardOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateCardOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeCard(loanId: string, options?: Models.OriginationWriteApiRemoveCardOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  removeCard(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeCard(loanId: string, options: Models.OriginationWriteApiRemoveCardOptionalParams, callback: msRest.ServiceCallback<void>): void;
  removeCard(loanId: string, options?: Models.OriginationWriteApiRemoveCardOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      removeCardOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  suspendCard(loanId: string, options?: Models.OriginationWriteApiSuspendCardOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  suspendCard(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  suspendCard(loanId: string, options: Models.OriginationWriteApiSuspendCardOptionalParams, callback: msRest.ServiceCallback<void>): void;
  suspendCard(loanId: string, options?: Models.OriginationWriteApiSuspendCardOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      suspendCardOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unSuspendCard(loanId: string, options?: Models.OriginationWriteApiUnSuspendCardOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unSuspendCard(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unSuspendCard(loanId: string, options: Models.OriginationWriteApiUnSuspendCardOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unSuspendCard(loanId: string, options?: Models.OriginationWriteApiUnSuspendCardOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unSuspendCardOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCardConfiguration(options?: Models.OriginationWriteApiUpdateCardConfigurationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateCardConfiguration(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCardConfiguration(options: Models.OriginationWriteApiUpdateCardConfigurationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateCardConfiguration(options?: Models.OriginationWriteApiUpdateCardConfigurationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateCardConfigurationOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  createCorprate(loanId: string, options?: Models.OriginationWriteApiCreateCorprateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  createCorprate(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  createCorprate(loanId: string, options: Models.OriginationWriteApiCreateCorprateOptionalParams, callback: msRest.ServiceCallback<void>): void;
  createCorprate(loanId: string, options?: Models.OriginationWriteApiCreateCorprateOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      createCorprateOperationSpec,
      callback);
  }

  /**
   * @param customerId
   * @param connectionId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setCustomerConnection(customerId: string, connectionId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param connectionId
   * @param callback The callback
   */
  setCustomerConnection(customerId: string, connectionId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param connectionId
   * @param options The optional parameters
   * @param callback The callback
   */
  setCustomerConnection(customerId: string, connectionId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  setCustomerConnection(customerId: string, connectionId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        connectionId,
        options
      },
      setCustomerConnectionOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.TestCustomerConnectionResponse>
   */
  testCustomerConnection(options?: Models.OriginationWriteApiTestCustomerConnectionOptionalParams): Promise<Models.TestCustomerConnectionResponse>;
  /**
   * @param callback The callback
   */
  testCustomerConnection(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  testCustomerConnection(options: Models.OriginationWriteApiTestCustomerConnectionOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  testCustomerConnection(options?: Models.OriginationWriteApiTestCustomerConnectionOptionalParams | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.TestCustomerConnectionResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      testCustomerConnectionOperationSpec,
      callback) as Promise<Models.TestCustomerConnectionResponse>;
  }

  /**
   * @param connectionId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resetCustomerConnection(connectionId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param connectionId
   * @param callback The callback
   */
  resetCustomerConnection(connectionId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param connectionId
   * @param options The optional parameters
   * @param callback The callback
   */
  resetCustomerConnection(connectionId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  resetCustomerConnection(connectionId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        connectionId,
        options
      },
      resetCustomerConnectionOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AddCustomersResponse>
   */
  addCustomers(options?: Models.OriginationWriteApiAddCustomersOptionalParams): Promise<Models.AddCustomersResponse>;
  /**
   * @param callback The callback
   */
  addCustomers(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addCustomers(options: Models.OriginationWriteApiAddCustomersOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  addCustomers(options?: Models.OriginationWriteApiAddCustomersOptionalParams | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.AddCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addCustomersOperationSpec,
      callback) as Promise<Models.AddCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.AddCustomerResponse>
   */
  addCustomer(options?: Models.OriginationWriteApiAddCustomerOptionalParams): Promise<Models.AddCustomerResponse>;
  /**
   * @param callback The callback
   */
  addCustomer(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addCustomer(options: Models.OriginationWriteApiAddCustomerOptionalParams, callback: msRest.ServiceCallback<string>): void;
  addCustomer(options?: Models.OriginationWriteApiAddCustomerOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.AddCustomerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addCustomerOperationSpec,
      callback) as Promise<Models.AddCustomerResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.SendOtpResponse>
   */
  sendOtp(customerId: string, options?: msRest.RequestOptionsBase): Promise<Models.SendOtpResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  sendOtp(customerId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendOtp(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  sendOtp(customerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.SendOtpResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      sendOtpOperationSpec,
      callback) as Promise<Models.SendOtpResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resetPasssword(options?: Models.OriginationWriteApiResetPassswordOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  resetPasssword(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  resetPasssword(options: Models.OriginationWriteApiResetPassswordOptionalParams, callback: msRest.ServiceCallback<void>): void;
  resetPasssword(options?: Models.OriginationWriteApiResetPassswordOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      resetPassswordOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  notifyPasswordResetCompleted(options?: Models.OriginationWriteApiNotifyPasswordResetCompletedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  notifyPasswordResetCompleted(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  notifyPasswordResetCompleted(options: Models.OriginationWriteApiNotifyPasswordResetCompletedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  notifyPasswordResetCompleted(options?: Models.OriginationWriteApiNotifyPasswordResetCompletedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      notifyPasswordResetCompletedOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCustomer1(id: string, options?: Models.OriginationWriteApiUpdateCustomer1OptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  updateCustomer1(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomer1(id: string, options: Models.OriginationWriteApiUpdateCustomer1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateCustomer1(id: string, options?: Models.OriginationWriteApiUpdateCustomer1OptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      updateCustomer1OperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disableCustomer(options?: Models.OriginationWriteApiDisableCustomerOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  disableCustomer(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disableCustomer(options: Models.OriginationWriteApiDisableCustomerOptionalParams, callback: msRest.ServiceCallback<void>): void;
  disableCustomer(options?: Models.OriginationWriteApiDisableCustomerOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disableCustomerOperationSpec,
      callback);
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  customerCreated(customerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  customerCreated(customerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  customerCreated(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  customerCreated(customerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      customerCreatedOperationSpec,
      callback);
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setUserRoles(customerId: string, options?: Models.OriginationWriteApiSetUserRolesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  setUserRoles(customerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setUserRoles(customerId: string, options: Models.OriginationWriteApiSetUserRolesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setUserRoles(customerId: string, options?: Models.OriginationWriteApiSetUserRolesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      setUserRolesOperationSpec,
      callback);
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeUserRoles(customerId: string, options?: Models.OriginationWriteApiRemoveUserRolesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  removeUserRoles(customerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeUserRoles(customerId: string, options: Models.OriginationWriteApiRemoveUserRolesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  removeUserRoles(customerId: string, options?: Models.OriginationWriteApiRemoveUserRolesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      removeUserRolesOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  customerRolesUpdated(options?: Models.OriginationWriteApiCustomerRolesUpdatedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  customerRolesUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  customerRolesUpdated(options: Models.OriginationWriteApiCustomerRolesUpdatedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  customerRolesUpdated(options?: Models.OriginationWriteApiCustomerRolesUpdatedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      customerRolesUpdatedOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadDocument(applicationId: string, options?: Models.OriginationWriteApiUploadDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  uploadDocument(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadDocument(applicationId: string, options: Models.OriginationWriteApiUploadDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadDocument(applicationId: string, options?: Models.OriginationWriteApiUploadDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      uploadDocumentOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadDocuments(applicationId: string, options?: Models.OriginationWriteApiUploadDocumentsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  uploadDocuments(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadDocuments(applicationId: string, options: Models.OriginationWriteApiUploadDocumentsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadDocuments(applicationId: string, options?: Models.OriginationWriteApiUploadDocumentsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      uploadDocumentsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setEmployeePassword(options?: Models.OriginationWriteApiSetEmployeePasswordOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setEmployeePassword(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setEmployeePassword(options: Models.OriginationWriteApiSetEmployeePasswordOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setEmployeePassword(options?: Models.OriginationWriteApiSetEmployeePasswordOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setEmployeePasswordOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableEmployeeEarlyAccess(loanId: string, options?: Models.OriginationWriteApiEnableEmployeeEarlyAccessOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  enableEmployeeEarlyAccess(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableEmployeeEarlyAccess(loanId: string, options: Models.OriginationWriteApiEnableEmployeeEarlyAccessOptionalParams, callback: msRest.ServiceCallback<void>): void;
  enableEmployeeEarlyAccess(loanId: string, options?: Models.OriginationWriteApiEnableEmployeeEarlyAccessOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      enableEmployeeEarlyAccessOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disableEmployeeEarlyAccess(loanId: string, options?: Models.OriginationWriteApiDisableEmployeeEarlyAccessOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  disableEmployeeEarlyAccess(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  disableEmployeeEarlyAccess(loanId: string, options: Models.OriginationWriteApiDisableEmployeeEarlyAccessOptionalParams, callback: msRest.ServiceCallback<void>): void;
  disableEmployeeEarlyAccess(loanId: string, options?: Models.OriginationWriteApiDisableEmployeeEarlyAccessOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      disableEmployeeEarlyAccessOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  connect(loanId: string, options?: Models.OriginationWriteApiConnectOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  connect(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  connect(loanId: string, options: Models.OriginationWriteApiConnectOptionalParams, callback: msRest.ServiceCallback<void>): void;
  connect(loanId: string, options?: Models.OriginationWriteApiConnectOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      connectOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disconnected(loanId: string, options?: Models.OriginationWriteApiDisconnectedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  disconnected(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  disconnected(loanId: string, options: Models.OriginationWriteApiDisconnectedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  disconnected(loanId: string, options?: Models.OriginationWriteApiDisconnectedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      disconnectedOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setEmployeeWithdrawDetails(loanId: string, options?: Models.OriginationWriteApiSetEmployeeWithdrawDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  setEmployeeWithdrawDetails(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  setEmployeeWithdrawDetails(loanId: string, options: Models.OriginationWriteApiSetEmployeeWithdrawDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setEmployeeWithdrawDetails(loanId: string, options?: Models.OriginationWriteApiSetEmployeeWithdrawDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      setEmployeeWithdrawDetailsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  makeWithdrawRequest(loanId: string, options?: Models.OriginationWriteApiMakeWithdrawRequestOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  makeWithdrawRequest(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  makeWithdrawRequest(loanId: string, options: Models.OriginationWriteApiMakeWithdrawRequestOptionalParams, callback: msRest.ServiceCallback<void>): void;
  makeWithdrawRequest(loanId: string, options?: Models.OriginationWriteApiMakeWithdrawRequestOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      makeWithdrawRequestOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  earlyAccessConnectionUpdated(options?: Models.OriginationWriteApiEarlyAccessConnectionUpdatedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  earlyAccessConnectionUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  earlyAccessConnectionUpdated(options: Models.OriginationWriteApiEarlyAccessConnectionUpdatedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  earlyAccessConnectionUpdated(options?: Models.OriginationWriteApiEarlyAccessConnectionUpdatedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      earlyAccessConnectionUpdatedOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  employeeStatusUpdated(options?: Models.OriginationWriteApiEmployeeStatusUpdatedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  employeeStatusUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  employeeStatusUpdated(options: Models.OriginationWriteApiEmployeeStatusUpdatedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  employeeStatusUpdated(options?: Models.OriginationWriteApiEmployeeStatusUpdatedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      employeeStatusUpdatedOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  processFinch(loanId: string, options?: Models.OriginationWriteApiProcessFinchOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  processFinch(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  processFinch(loanId: string, options: Models.OriginationWriteApiProcessFinchOptionalParams, callback: msRest.ServiceCallback<void>): void;
  processFinch(loanId: string, options?: Models.OriginationWriteApiProcessFinchOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      processFinchOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  inviteAllEmployees(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  inviteAllEmployees(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  inviteAllEmployees(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  inviteAllEmployees(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      inviteAllEmployeesOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateEmployeeDateOfBirth(options?: Models.OriginationWriteApiUpdateEmployeeDateOfBirthOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateEmployeeDateOfBirth(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateEmployeeDateOfBirth(options: Models.OriginationWriteApiUpdateEmployeeDateOfBirthOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateEmployeeDateOfBirth(options?: Models.OriginationWriteApiUpdateEmployeeDateOfBirthOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateEmployeeDateOfBirthOperationSpec,
      callback);
  }

  /**
   * @param employeeId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setEmployeeRoles(employeeId: string, options?: Models.OriginationWriteApiSetEmployeeRolesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param employeeId
   * @param callback The callback
   */
  setEmployeeRoles(employeeId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param employeeId
   * @param options The optional parameters
   * @param callback The callback
   */
  setEmployeeRoles(employeeId: string, options: Models.OriginationWriteApiSetEmployeeRolesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setEmployeeRoles(employeeId: string, options?: Models.OriginationWriteApiSetEmployeeRolesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        employeeId,
        options
      },
      setEmployeeRolesOperationSpec,
      callback);
  }

  /**
   * @param employeeId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeEmployeeRoles(employeeId: string, options?: Models.OriginationWriteApiRemoveEmployeeRolesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param employeeId
   * @param callback The callback
   */
  removeEmployeeRoles(employeeId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param employeeId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeEmployeeRoles(employeeId: string, options: Models.OriginationWriteApiRemoveEmployeeRolesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  removeEmployeeRoles(employeeId: string, options?: Models.OriginationWriteApiRemoveEmployeeRolesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        employeeId,
        options
      },
      removeEmployeeRolesOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBaseSupportDocument(options?: Models.OriginationWriteApiUploadBaseSupportDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadBaseSupportDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBaseSupportDocument(options: Models.OriginationWriteApiUploadBaseSupportDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadBaseSupportDocument(options?: Models.OriginationWriteApiUploadBaseSupportDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadBaseSupportDocumentOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateBaseSupportDocumentForApplication(options?: Models.OriginationWriteApiUpdateBaseSupportDocumentForApplicationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateBaseSupportDocumentForApplication(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBaseSupportDocumentForApplication(options: Models.OriginationWriteApiUpdateBaseSupportDocumentForApplicationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateBaseSupportDocumentForApplication(options?: Models.OriginationWriteApiUpdateBaseSupportDocumentForApplicationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateBaseSupportDocumentForApplicationOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  overrideContract(options?: Models.OriginationWriteApiOverrideContractOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  overrideContract(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  overrideContract(options: Models.OriginationWriteApiOverrideContractOptionalParams, callback: msRest.ServiceCallback<void>): void;
  overrideContract(options?: Models.OriginationWriteApiOverrideContractOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      overrideContractOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadW9SampleDocument(options?: Models.OriginationWriteApiUploadW9SampleDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  uploadW9SampleDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadW9SampleDocument(options: Models.OriginationWriteApiUploadW9SampleDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadW9SampleDocument(options?: Models.OriginationWriteApiUploadW9SampleDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      uploadW9SampleDocumentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  turnOnChangeOfFacility(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  turnOnChangeOfFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  turnOnChangeOfFacility(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  turnOnChangeOfFacility(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      turnOnChangeOfFacilityOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTransactionDateResponse>
   */
  updateTransactionDate(loanId: string, options?: Models.OriginationWriteApiUpdateTransactionDateOptionalParams): Promise<Models.UpdateTransactionDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateTransactionDate(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTransactionDate(loanId: string, options: Models.OriginationWriteApiUpdateTransactionDateOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateTransactionDate(loanId: string, options?: Models.OriginationWriteApiUpdateTransactionDateOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateTransactionDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateTransactionDateOperationSpec,
      callback) as Promise<Models.UpdateTransactionDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateTransactionDebtorResponse>
   */
  updateTransactionDebtor(loanId: string, options?: Models.OriginationWriteApiUpdateTransactionDebtorOptionalParams): Promise<Models.UpdateTransactionDebtorResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateTransactionDebtor(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateTransactionDebtor(loanId: string, options: Models.OriginationWriteApiUpdateTransactionDebtorOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateTransactionDebtor(loanId: string, options?: Models.OriginationWriteApiUpdateTransactionDebtorOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateTransactionDebtorResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateTransactionDebtorOperationSpec,
      callback) as Promise<Models.UpdateTransactionDebtorResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterTransactionsSummaryResponse>
   */
  registerTransactionsSummary(loanId: string, options?: Models.OriginationWriteApiRegisterTransactionsSummaryOptionalParams): Promise<Models.RegisterTransactionsSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerTransactionsSummary(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseListGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerTransactionsSummary(loanId: string, options: Models.OriginationWriteApiRegisterTransactionsSummaryOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseListGuid>): void;
  registerTransactionsSummary(loanId: string, options?: Models.OriginationWriteApiRegisterTransactionsSummaryOptionalParams | msRest.ServiceCallback<Models.ApiResponseListGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseListGuid>): Promise<Models.RegisterTransactionsSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerTransactionsSummaryOperationSpec,
      callback) as Promise<Models.RegisterTransactionsSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterTransactionSummaryResponse>
   */
  unregisterTransactionSummary(loanId: string, options?: Models.OriginationWriteApiUnregisterTransactionSummaryOptionalParams): Promise<Models.UnregisterTransactionSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterTransactionSummary(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterTransactionSummary(loanId: string, options: Models.OriginationWriteApiUnregisterTransactionSummaryOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  unregisterTransactionSummary(loanId: string, options?: Models.OriginationWriteApiUnregisterTransactionSummaryOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UnregisterTransactionSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterTransactionSummaryOperationSpec,
      callback) as Promise<Models.UnregisterTransactionSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterSyntheticPaymentSummaryResponse>
   */
  registerSyntheticPaymentSummary(loanId: string, options?: Models.OriginationWriteApiRegisterSyntheticPaymentSummaryOptionalParams): Promise<Models.RegisterSyntheticPaymentSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerSyntheticPaymentSummary(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerSyntheticPaymentSummary(loanId: string, options: Models.OriginationWriteApiRegisterSyntheticPaymentSummaryOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  registerSyntheticPaymentSummary(loanId: string, options?: Models.OriginationWriteApiRegisterSyntheticPaymentSummaryOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.RegisterSyntheticPaymentSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerSyntheticPaymentSummaryOperationSpec,
      callback) as Promise<Models.RegisterSyntheticPaymentSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterSyntheticPaymentFullResponse>
   */
  registerSyntheticPaymentFull(loanId: string, options?: Models.OriginationWriteApiRegisterSyntheticPaymentFullOptionalParams): Promise<Models.RegisterSyntheticPaymentFullResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerSyntheticPaymentFull(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerSyntheticPaymentFull(loanId: string, options: Models.OriginationWriteApiRegisterSyntheticPaymentFullOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  registerSyntheticPaymentFull(loanId: string, options?: Models.OriginationWriteApiRegisterSyntheticPaymentFullOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.RegisterSyntheticPaymentFullResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerSyntheticPaymentFullOperationSpec,
      callback) as Promise<Models.RegisterSyntheticPaymentFullResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.ReconcileTransactionResponse>
   */
  reconcileTransaction(loanId: string, options?: Models.OriginationWriteApiReconcileTransactionOptionalParams): Promise<Models.ReconcileTransactionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconcileTransaction(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseString>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconcileTransaction(loanId: string, options: Models.OriginationWriteApiReconcileTransactionOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseString>): void;
  reconcileTransaction(loanId: string, options?: Models.OriginationWriteApiReconcileTransactionOptionalParams | msRest.ServiceCallback<Models.ApiResponseString>, callback?: msRest.ServiceCallback<Models.ApiResponseString>): Promise<Models.ReconcileTransactionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconcileTransactionOperationSpec,
      callback) as Promise<Models.ReconcileTransactionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterSyntheticPaymentResponse>
   */
  unregisterSyntheticPayment(loanId: string, options?: Models.OriginationWriteApiUnregisterSyntheticPaymentOptionalParams): Promise<Models.UnregisterSyntheticPaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterSyntheticPayment(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterSyntheticPayment(loanId: string, options: Models.OriginationWriteApiUnregisterSyntheticPaymentOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  unregisterSyntheticPayment(loanId: string, options?: Models.OriginationWriteApiUnregisterSyntheticPaymentOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UnregisterSyntheticPaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterSyntheticPaymentOperationSpec,
      callback) as Promise<Models.UnregisterSyntheticPaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteSyntheticPaymentResponse>
   */
  deleteSyntheticPayment(loanId: string, options?: Models.OriginationWriteApiDeleteSyntheticPaymentOptionalParams): Promise<Models.DeleteSyntheticPaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  deleteSyntheticPayment(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteSyntheticPayment(loanId: string, options: Models.OriginationWriteApiDeleteSyntheticPaymentOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  deleteSyntheticPayment(loanId: string, options?: Models.OriginationWriteApiDeleteSyntheticPaymentOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.DeleteSyntheticPaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      deleteSyntheticPaymentOperationSpec,
      callback) as Promise<Models.DeleteSyntheticPaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterAdvancesSummaryResponse>
   */
  registerAdvancesSummary(loanId: string, options?: Models.OriginationWriteApiRegisterAdvancesSummaryOptionalParams): Promise<Models.RegisterAdvancesSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerAdvancesSummary(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseListAdvanceProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerAdvancesSummary(loanId: string, options: Models.OriginationWriteApiRegisterAdvancesSummaryOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseListAdvanceProjection>): void;
  registerAdvancesSummary(loanId: string, options?: Models.OriginationWriteApiRegisterAdvancesSummaryOptionalParams | msRest.ServiceCallback<Models.ApiResponseListAdvanceProjection>, callback?: msRest.ServiceCallback<Models.ApiResponseListAdvanceProjection>): Promise<Models.RegisterAdvancesSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerAdvancesSummaryOperationSpec,
      callback) as Promise<Models.RegisterAdvancesSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterAdvancesSummaryResponse>
   */
  unregisterAdvancesSummary(loanId: string, options?: Models.OriginationWriteApiUnregisterAdvancesSummaryOptionalParams): Promise<Models.UnregisterAdvancesSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterAdvancesSummary(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterAdvancesSummary(loanId: string, options: Models.OriginationWriteApiUnregisterAdvancesSummaryOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  unregisterAdvancesSummary(loanId: string, options?: Models.OriginationWriteApiUnregisterAdvancesSummaryOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UnregisterAdvancesSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterAdvancesSummaryOperationSpec,
      callback) as Promise<Models.UnregisterAdvancesSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawStartDateResponse>
   */
  updateWithdrawStartDate(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawStartDateOptionalParams): Promise<Models.UpdateWithdrawStartDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawStartDate(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawStartDate(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawStartDateOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawStartDate(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawStartDateOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawStartDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawStartDateOperationSpec,
      callback) as Promise<Models.UpdateWithdrawStartDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAdvanceDateResponse>
   */
  updateAdvanceDate(loanId: string, options?: Models.OriginationWriteApiUpdateAdvanceDateOptionalParams): Promise<Models.UpdateAdvanceDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateAdvanceDate(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAdvanceDate(loanId: string, options: Models.OriginationWriteApiUpdateAdvanceDateOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateAdvanceDate(loanId: string, options?: Models.OriginationWriteApiUpdateAdvanceDateOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateAdvanceDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateAdvanceDateOperationSpec,
      callback) as Promise<Models.UpdateAdvanceDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawWeeklyRepaymentResponse>
   */
  updateWithdrawWeeklyRepayment(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawWeeklyRepaymentOptionalParams): Promise<Models.UpdateWithdrawWeeklyRepaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawWeeklyRepayment(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawWeeklyRepayment(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawWeeklyRepaymentOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawWeeklyRepayment(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawWeeklyRepaymentOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawWeeklyRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawWeeklyRepaymentOperationSpec,
      callback) as Promise<Models.UpdateWithdrawWeeklyRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawTrailingCommissionResponse>
   */
  updateWithdrawTrailingCommission(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawTrailingCommissionOptionalParams): Promise<Models.UpdateWithdrawTrailingCommissionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawTrailingCommission(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawTrailingCommission(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawTrailingCommissionOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawTrailingCommission(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawTrailingCommissionOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawTrailingCommissionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawTrailingCommissionOperationSpec,
      callback) as Promise<Models.UpdateWithdrawTrailingCommissionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawLegacyResponse>
   */
  updateWithdrawLegacy(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawLegacyOptionalParams): Promise<Models.UpdateWithdrawLegacyResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawLegacy(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawLegacy(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawLegacyOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawLegacy(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawLegacyOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawLegacyResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawLegacyOperationSpec,
      callback) as Promise<Models.UpdateWithdrawLegacyResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse>
   */
  updateWithdrawUpfrontFeeCommission(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawUpfrontFeeCommissionOptionalParams): Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawUpfrontFeeCommission(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawUpfrontFeeCommission(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawUpfrontFeeCommissionOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawUpfrontFeeCommission(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawUpfrontFeeCommissionOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawUpfrontFeeCommissionOperationSpec,
      callback) as Promise<Models.UpdateWithdrawUpfrontFeeCommissionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateAdvanceLastRepaymentDateResponse>
   */
  updateAdvanceLastRepaymentDate(loanId: string, options?: Models.OriginationWriteApiUpdateAdvanceLastRepaymentDateOptionalParams): Promise<Models.UpdateAdvanceLastRepaymentDateResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateAdvanceLastRepaymentDate(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateAdvanceLastRepaymentDate(loanId: string, options: Models.OriginationWriteApiUpdateAdvanceLastRepaymentDateOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateAdvanceLastRepaymentDate(loanId: string, options?: Models.OriginationWriteApiUpdateAdvanceLastRepaymentDateOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateAdvanceLastRepaymentDateResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateAdvanceLastRepaymentDateOperationSpec,
      callback) as Promise<Models.UpdateAdvanceLastRepaymentDateResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsPercentageResponse>
   */
  withdrawAsPercentage(loanId: string, options?: Models.OriginationWriteApiWithdrawAsPercentageOptionalParams): Promise<Models.WithdrawAsPercentageResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsPercentage(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsPercentage(loanId: string, options: Models.OriginationWriteApiWithdrawAsPercentageOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  withdrawAsPercentage(loanId: string, options?: Models.OriginationWriteApiWithdrawAsPercentageOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.WithdrawAsPercentageResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsPercentageOperationSpec,
      callback) as Promise<Models.WithdrawAsPercentageResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsPercentageOverrideResponse>
   */
  withdrawAsPercentageOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawAsPercentageOverrideOptionalParams): Promise<Models.WithdrawAsPercentageOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsPercentageOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsPercentageOverride(loanId: string, options: Models.OriginationWriteApiWithdrawAsPercentageOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  withdrawAsPercentageOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawAsPercentageOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>, callback?: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): Promise<Models.WithdrawAsPercentageOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsPercentageOverrideOperationSpec,
      callback) as Promise<Models.WithdrawAsPercentageOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawAsPercentageOverrideResponse>
   */
  updateWithdrawAsPercentageOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawAsPercentageOverrideOptionalParams): Promise<Models.UpdateWithdrawAsPercentageOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawAsPercentageOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawAsPercentageOverride(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawAsPercentageOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawAsPercentageOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawAsPercentageOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawAsPercentageOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawAsPercentageOverrideOperationSpec,
      callback) as Promise<Models.UpdateWithdrawAsPercentageOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsInvoicePaymentResponse>
   */
  withdrawAsInvoicePayment(loanId: string, options?: Models.OriginationWriteApiWithdrawAsInvoicePaymentOptionalParams): Promise<Models.WithdrawAsInvoicePaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsInvoicePayment(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsInvoicePayment(loanId: string, options: Models.OriginationWriteApiWithdrawAsInvoicePaymentOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  withdrawAsInvoicePayment(loanId: string, options?: Models.OriginationWriteApiWithdrawAsInvoicePaymentOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.WithdrawAsInvoicePaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsInvoicePaymentOperationSpec,
      callback) as Promise<Models.WithdrawAsInvoicePaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawAsInvoicePaymentOverrideResponse>
   */
  withdrawAsInvoicePaymentOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawAsInvoicePaymentOverrideOptionalParams): Promise<Models.WithdrawAsInvoicePaymentOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawAsInvoicePaymentOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawAsInvoicePaymentOverride(loanId: string, options: Models.OriginationWriteApiWithdrawAsInvoicePaymentOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  withdrawAsInvoicePaymentOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawAsInvoicePaymentOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>, callback?: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): Promise<Models.WithdrawAsInvoicePaymentOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawAsInvoicePaymentOverrideOperationSpec,
      callback) as Promise<Models.WithdrawAsInvoicePaymentOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse>
   */
  updateWithdrawAsInvoicePaymentOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawAsInvoicePaymentOverrideOptionalParams): Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawAsInvoicePaymentOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawAsInvoicePaymentOverride(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawAsInvoicePaymentOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawAsInvoicePaymentOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawAsInvoicePaymentOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawAsInvoicePaymentOverrideOperationSpec,
      callback) as Promise<Models.UpdateWithdrawAsInvoicePaymentOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawFixedResponse>
   */
  withdrawFixed(loanId: string, options?: Models.OriginationWriteApiWithdrawFixedOptionalParams): Promise<Models.WithdrawFixedResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawFixed(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawFixed(loanId: string, options: Models.OriginationWriteApiWithdrawFixedOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  withdrawFixed(loanId: string, options?: Models.OriginationWriteApiWithdrawFixedOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.WithdrawFixedResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawFixedOperationSpec,
      callback) as Promise<Models.WithdrawFixedResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawFixedOverrideResponse>
   */
  withdrawFixedOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawFixedOverrideOptionalParams): Promise<Models.WithdrawFixedOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawFixedOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawFixedOverride(loanId: string, options: Models.OriginationWriteApiWithdrawFixedOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  withdrawFixedOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawFixedOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>, callback?: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): Promise<Models.WithdrawFixedOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawFixedOverrideOperationSpec,
      callback) as Promise<Models.WithdrawFixedOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawFixedOverrideResponse>
   */
  updateWithdrawFixedOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawFixedOverrideOptionalParams): Promise<Models.UpdateWithdrawFixedOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawFixedOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawFixedOverride(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawFixedOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawFixedOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawFixedOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawFixedOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawFixedOverrideOperationSpec,
      callback) as Promise<Models.UpdateWithdrawFixedOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawLineOfCreditResponse>
   */
  withdrawLineOfCredit(loanId: string, options?: Models.OriginationWriteApiWithdrawLineOfCreditOptionalParams): Promise<Models.WithdrawLineOfCreditResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawLineOfCredit(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawLineOfCredit(loanId: string, options: Models.OriginationWriteApiWithdrawLineOfCreditOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  withdrawLineOfCredit(loanId: string, options?: Models.OriginationWriteApiWithdrawLineOfCreditOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.WithdrawLineOfCreditResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawLineOfCreditOperationSpec,
      callback) as Promise<Models.WithdrawLineOfCreditResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawLineOfCreditOverrideResponse>
   */
  withdrawLineOfCreditOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawLineOfCreditOverrideOptionalParams): Promise<Models.WithdrawLineOfCreditOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawLineOfCreditOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawLineOfCreditOverride(loanId: string, options: Models.OriginationWriteApiWithdrawLineOfCreditOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  withdrawLineOfCreditOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawLineOfCreditOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>, callback?: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): Promise<Models.WithdrawLineOfCreditOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawLineOfCreditOverrideOperationSpec,
      callback) as Promise<Models.WithdrawLineOfCreditOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse>
   */
  updateWithdrawLineOfCreditOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawLineOfCreditOverrideOptionalParams): Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawLineOfCreditOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawLineOfCreditOverride(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawLineOfCreditOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawLineOfCreditOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawLineOfCreditOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawLineOfCreditOverrideOperationSpec,
      callback) as Promise<Models.UpdateWithdrawLineOfCreditOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawCreditCardResponse>
   */
  withdrawCreditCard(loanId: string, options?: Models.OriginationWriteApiWithdrawCreditCardOptionalParams): Promise<Models.WithdrawCreditCardResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawCreditCard(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawCreditCard(loanId: string, options: Models.OriginationWriteApiWithdrawCreditCardOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  withdrawCreditCard(loanId: string, options?: Models.OriginationWriteApiWithdrawCreditCardOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.WithdrawCreditCardResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawCreditCardOperationSpec,
      callback) as Promise<Models.WithdrawCreditCardResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.WithdrawCreditCardOverrideResponse>
   */
  withdrawCreditCardOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawCreditCardOverrideOptionalParams): Promise<Models.WithdrawCreditCardOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  withdrawCreditCardOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  withdrawCreditCardOverride(loanId: string, options: Models.OriginationWriteApiWithdrawCreditCardOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): void;
  withdrawCreditCardOverride(loanId: string, options?: Models.OriginationWriteApiWithdrawCreditCardOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>, callback?: msRest.ServiceCallback<Models.ApiResponseAdvanceProjection>): Promise<Models.WithdrawCreditCardOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      withdrawCreditCardOverrideOperationSpec,
      callback) as Promise<Models.WithdrawCreditCardOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateWithdrawCreditCardOverrideResponse>
   */
  updateWithdrawCreditCardOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawCreditCardOverrideOptionalParams): Promise<Models.UpdateWithdrawCreditCardOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateWithdrawCreditCardOverride(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWithdrawCreditCardOverride(loanId: string, options: Models.OriginationWriteApiUpdateWithdrawCreditCardOverrideOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  updateWithdrawCreditCardOverride(loanId: string, options?: Models.OriginationWriteApiUpdateWithdrawCreditCardOverrideOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UpdateWithdrawCreditCardOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateWithdrawCreditCardOverrideOperationSpec,
      callback) as Promise<Models.UpdateWithdrawCreditCardOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterPaymentArrangementResponse>
   */
  registerPaymentArrangement(loanId: string, options?: Models.OriginationWriteApiRegisterPaymentArrangementOptionalParams): Promise<Models.RegisterPaymentArrangementResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerPaymentArrangement(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseString>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerPaymentArrangement(loanId: string, options: Models.OriginationWriteApiRegisterPaymentArrangementOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseString>): void;
  registerPaymentArrangement(loanId: string, options?: Models.OriginationWriteApiRegisterPaymentArrangementOptionalParams | msRest.ServiceCallback<Models.ApiResponseString>, callback?: msRest.ServiceCallback<Models.ApiResponseString>): Promise<Models.RegisterPaymentArrangementResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerPaymentArrangementOperationSpec,
      callback) as Promise<Models.RegisterPaymentArrangementResponse>;
  }

  /**
   * @param loanId
   * @param paymentArrangementId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterPaymentArrangementResponse>
   */
  unregisterPaymentArrangement(loanId: string, paymentArrangementId: string, options?: msRest.RequestOptionsBase): Promise<Models.UnregisterPaymentArrangementResponse>;
  /**
   * @param loanId
   * @param paymentArrangementId
   * @param callback The callback
   */
  unregisterPaymentArrangement(loanId: string, paymentArrangementId: string, callback: msRest.ServiceCallback<Models.ApiResponseString>): void;
  /**
   * @param loanId
   * @param paymentArrangementId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterPaymentArrangement(loanId: string, paymentArrangementId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ApiResponseString>): void;
  unregisterPaymentArrangement(loanId: string, paymentArrangementId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApiResponseString>, callback?: msRest.ServiceCallback<Models.ApiResponseString>): Promise<Models.UnregisterPaymentArrangementResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        paymentArrangementId,
        options
      },
      unregisterPaymentArrangementOperationSpec,
      callback) as Promise<Models.UnregisterPaymentArrangementResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.EarlyRepayAdvanceResponse>
   */
  earlyRepayAdvance(loanId: string, options?: Models.OriginationWriteApiEarlyRepayAdvanceOptionalParams): Promise<Models.EarlyRepayAdvanceResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  earlyRepayAdvance(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  earlyRepayAdvance(loanId: string, options: Models.OriginationWriteApiEarlyRepayAdvanceOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  earlyRepayAdvance(loanId: string, options?: Models.OriginationWriteApiEarlyRepayAdvanceOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.EarlyRepayAdvanceResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      earlyRepayAdvanceOperationSpec,
      callback) as Promise<Models.EarlyRepayAdvanceResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterRepaymentDetailsResponse>
   */
  registerRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiRegisterRepaymentDetailsOptionalParams): Promise<Models.RegisterRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerRepaymentDetails(loanId: string, options: Models.OriginationWriteApiRegisterRepaymentDetailsOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  registerRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiRegisterRepaymentDetailsOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.RegisterRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerRepaymentDetailsOperationSpec,
      callback) as Promise<Models.RegisterRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterEarlyRepaymentDetailsResponse>
   */
  registerEarlyRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiRegisterEarlyRepaymentDetailsOptionalParams): Promise<Models.RegisterEarlyRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerEarlyRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerEarlyRepaymentDetails(loanId: string, options: Models.OriginationWriteApiRegisterEarlyRepaymentDetailsOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  registerEarlyRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiRegisterEarlyRepaymentDetailsOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.RegisterEarlyRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerEarlyRepaymentDetailsOperationSpec,
      callback) as Promise<Models.RegisterEarlyRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterSkipRepaymentDetailsResponse>
   */
  registerSkipRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiRegisterSkipRepaymentDetailsOptionalParams): Promise<Models.RegisterSkipRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerSkipRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerSkipRepaymentDetails(loanId: string, options: Models.OriginationWriteApiRegisterSkipRepaymentDetailsOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  registerSkipRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiRegisterSkipRepaymentDetailsOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.RegisterSkipRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerSkipRepaymentDetailsOperationSpec,
      callback) as Promise<Models.RegisterSkipRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UnregisterRepaymentDetailsResponse>
   */
  unregisterRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiUnregisterRepaymentDetailsOptionalParams): Promise<Models.UnregisterRepaymentDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unregisterRepaymentDetails(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unregisterRepaymentDetails(loanId: string, options: Models.OriginationWriteApiUnregisterRepaymentDetailsOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  unregisterRepaymentDetails(loanId: string, options?: Models.OriginationWriteApiUnregisterRepaymentDetailsOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.UnregisterRepaymentDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unregisterRepaymentDetailsOperationSpec,
      callback) as Promise<Models.UnregisterRepaymentDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addCommissionToAdvance(loanId: string, options?: Models.OriginationWriteApiAddCommissionToAdvanceOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  addCommissionToAdvance(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  addCommissionToAdvance(loanId: string, options: Models.OriginationWriteApiAddCommissionToAdvanceOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addCommissionToAdvance(loanId: string, options?: Models.OriginationWriteApiAddCommissionToAdvanceOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      addCommissionToAdvanceOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param advanceId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  payCommission(loanId: string, advanceId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param advanceId
   * @param callback The callback
   */
  payCommission(loanId: string, advanceId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param advanceId
   * @param options The optional parameters
   * @param callback The callback
   */
  payCommission(loanId: string, advanceId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  payCommission(loanId: string, advanceId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        advanceId,
        options
      },
      payCommissionOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  commissionMethod1(loanId: string, options?: Models.OriginationWriteApiCommissionMethod1OptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  commissionMethod1(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  commissionMethod1(loanId: string, options: Models.OriginationWriteApiCommissionMethod1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  commissionMethod1(loanId: string, options?: Models.OriginationWriteApiCommissionMethod1OptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      commissionMethod1OperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param debtorId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableDebtor(loanId: string, debtorId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param debtorId
   * @param callback The callback
   */
  enableDebtor(loanId: string, debtorId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param debtorId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableDebtor(loanId: string, debtorId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  enableDebtor(loanId: string, debtorId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        debtorId,
        options
      },
      enableDebtorOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param enabled
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableDebtorService(loanId: string, enabled: boolean, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param enabled
   * @param callback The callback
   */
  enableDebtorService(loanId: string, enabled: boolean, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param enabled
   * @param options The optional parameters
   * @param callback The callback
   */
  enableDebtorService(loanId: string, enabled: boolean, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  enableDebtorService(loanId: string, enabled: boolean, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        enabled,
        options
      },
      enableDebtorServiceOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param enabled
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableShowAllDebtor(loanId: string, enabled: boolean, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param enabled
   * @param callback The callback
   */
  enableShowAllDebtor(loanId: string, enabled: boolean, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param enabled
   * @param options The optional parameters
   * @param callback The callback
   */
  enableShowAllDebtor(loanId: string, enabled: boolean, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  enableShowAllDebtor(loanId: string, enabled: boolean, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        enabled,
        options
      },
      enableShowAllDebtorOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loginProcess(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  loginProcess(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  loginProcess(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  loginProcess(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      loginProcessOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param bankId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateBankId(loanId: string, bankId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param bankId
   * @param callback The callback
   */
  updateBankId(loanId: string, bankId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param bankId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBankId(loanId: string, bankId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateBankId(loanId: string, bankId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        bankId,
        options
      },
      updateBankIdOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param funder
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateFunder(loanId: string, funder: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param funder
   * @param callback The callback
   */
  updateFunder(loanId: string, funder: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param funder
   * @param options The optional parameters
   * @param callback The callback
   */
  updateFunder(loanId: string, funder: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateFunder(loanId: string, funder: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        funder,
        options
      },
      updateFunderOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enable(loanId: string, options?: Models.OriginationWriteApiEnableOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  enable(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  enable(loanId: string, options: Models.OriginationWriteApiEnableOptionalParams, callback: msRest.ServiceCallback<void>): void;
  enable(loanId: string, options?: Models.OriginationWriteApiEnableOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      enableOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanCustomerDetails(loanId: string, options?: Models.OriginationWriteApiUpdateLoanCustomerDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanCustomerDetails(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanCustomerDetails(loanId: string, options: Models.OriginationWriteApiUpdateLoanCustomerDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateLoanCustomerDetails(loanId: string, options?: Models.OriginationWriteApiUpdateLoanCustomerDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanCustomerDetailsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBusinessNameDetailsResponse>
   */
  updateBusinessNameDetails(loanId: string, options?: Models.OriginationWriteApiUpdateBusinessNameDetailsOptionalParams): Promise<Models.UpdateBusinessNameDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateBusinessNameDetails(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBusinessNameDetails(loanId: string, options: Models.OriginationWriteApiUpdateBusinessNameDetailsOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateBusinessNameDetails(loanId: string, options?: Models.OriginationWriteApiUpdateBusinessNameDetailsOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateBusinessNameDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateBusinessNameDetailsOperationSpec,
      callback) as Promise<Models.UpdateBusinessNameDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.UpdateBalanceOverrideResponse>
   */
  updateBalanceOverride(loanId: string, options?: Models.OriginationWriteApiUpdateBalanceOverrideOptionalParams): Promise<Models.UpdateBalanceOverrideResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateBalanceOverride(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBalanceOverride(loanId: string, options: Models.OriginationWriteApiUpdateBalanceOverrideOptionalParams, callback: msRest.ServiceCallback<string>): void;
  updateBalanceOverride(loanId: string, options?: Models.OriginationWriteApiUpdateBalanceOverrideOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.UpdateBalanceOverrideResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateBalanceOverrideOperationSpec,
      callback) as Promise<Models.UpdateBalanceOverrideResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanStatus(loanId: string, options?: Models.OriginationWriteApiUpdateLoanStatusOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanStatus(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanStatus(loanId: string, options: Models.OriginationWriteApiUpdateLoanStatusOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateLoanStatus(loanId: string, options?: Models.OriginationWriteApiUpdateLoanStatusOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanStatusOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconnectAccountingData(options?: Models.OriginationWriteApiReconnectAccountingDataOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  reconnectAccountingData(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reconnectAccountingData(options: Models.OriginationWriteApiReconnectAccountingDataOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reconnectAccountingData(options?: Models.OriginationWriteApiReconnectAccountingDataOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reconnectAccountingDataOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reprocessAccountingDataCommand(options?: Models.OriginationWriteApiReprocessAccountingDataCommandOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  reprocessAccountingDataCommand(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  reprocessAccountingDataCommand(options: Models.OriginationWriteApiReprocessAccountingDataCommandOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reprocessAccountingDataCommand(options?: Models.OriginationWriteApiReprocessAccountingDataCommandOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      reprocessAccountingDataCommandOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disconnectAccountingSystem(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  disconnectAccountingSystem(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  disconnectAccountingSystem(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  disconnectAccountingSystem(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      disconnectAccountingSystemOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  processPayments(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  processPayments(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  processPayments(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  processPayments(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      processPaymentsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  close(loanId: string, options?: Models.OriginationWriteApiCloseOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  close(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  close(loanId: string, options: Models.OriginationWriteApiCloseOptionalParams, callback: msRest.ServiceCallback<void>): void;
  close(loanId: string, options?: Models.OriginationWriteApiCloseOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      closeOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  switchLoanType(loanId: string, options?: Models.OriginationWriteApiSwitchLoanTypeOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  switchLoanType(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  switchLoanType(loanId: string, options: Models.OriginationWriteApiSwitchLoanTypeOptionalParams, callback: msRest.ServiceCallback<void>): void;
  switchLoanType(loanId: string, options?: Models.OriginationWriteApiSwitchLoanTypeOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      switchLoanTypeOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  open(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  open(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  open(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  open(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      openOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  archived(loanId: string, options?: Models.OriginationWriteApiArchivedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  archived(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  archived(loanId: string, options: Models.OriginationWriteApiArchivedOptionalParams, callback: msRest.ServiceCallback<void>): void;
  archived(loanId: string, options?: Models.OriginationWriteApiArchivedOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      archivedOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanPartnerDetails(loanId: string, options?: Models.OriginationWriteApiUpdateLoanPartnerDetailsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanPartnerDetails(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanPartnerDetails(loanId: string, options: Models.OriginationWriteApiUpdateLoanPartnerDetailsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateLoanPartnerDetails(loanId: string, options?: Models.OriginationWriteApiUpdateLoanPartnerDetailsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanPartnerDetailsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  addChangeToFacility(loanId: string, options?: Models.OriginationWriteApiAddChangeToFacilityOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  addChangeToFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  addChangeToFacility(loanId: string, options: Models.OriginationWriteApiAddChangeToFacilityOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addChangeToFacility(loanId: string, options?: Models.OriginationWriteApiAddChangeToFacilityOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      addChangeToFacilityOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  removeChangeToFacility(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  removeChangeToFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  removeChangeToFacility(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  removeChangeToFacility(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      removeChangeToFacilityOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateLoanOnApplicationIdResponse>
   */
  createLoanOnApplicationId(options?: Models.OriginationWriteApiCreateLoanOnApplicationIdOptionalParams): Promise<Models.CreateLoanOnApplicationIdResponse>;
  /**
   * @param callback The callback
   */
  createLoanOnApplicationId(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createLoanOnApplicationId(options: Models.OriginationWriteApiCreateLoanOnApplicationIdOptionalParams, callback: msRest.ServiceCallback<string>): void;
  createLoanOnApplicationId(options?: Models.OriginationWriteApiCreateLoanOnApplicationIdOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.CreateLoanOnApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createLoanOnApplicationIdOperationSpec,
      callback) as Promise<Models.CreateLoanOnApplicationIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  registerBankAccount(loanId: string, options?: Models.OriginationWriteApiRegisterBankAccountOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerBankAccount(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerBankAccount(loanId: string, options: Models.OriginationWriteApiRegisterBankAccountOptionalParams, callback: msRest.ServiceCallback<void>): void;
  registerBankAccount(loanId: string, options?: Models.OriginationWriteApiRegisterBankAccountOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerBankAccountOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateRevolvingFacility(loanId: string, options?: Models.OriginationWriteApiUpdateRevolvingFacilityOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateRevolvingFacility(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateRevolvingFacility(loanId: string, options: Models.OriginationWriteApiUpdateRevolvingFacilityOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateRevolvingFacility(loanId: string, options?: Models.OriginationWriteApiUpdateRevolvingFacilityOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateRevolvingFacilityOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.SendOtp1Response>
   */
  sendOtp1(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.SendOtp1Response>;
  /**
   * @param loanId
   * @param callback The callback
   */
  sendOtp1(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendOtp1(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  sendOtp1(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.SendOtp1Response> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      sendOtp1OperationSpec,
      callback) as Promise<Models.SendOtp1Response>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.RegisterLoanNotificationResponse>
   */
  registerLoanNotification(loanId: string, options?: Models.OriginationWriteApiRegisterLoanNotificationOptionalParams): Promise<Models.RegisterLoanNotificationResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  registerLoanNotification(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  registerLoanNotification(loanId: string, options: Models.OriginationWriteApiRegisterLoanNotificationOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  registerLoanNotification(loanId: string, options?: Models.OriginationWriteApiRegisterLoanNotificationOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.RegisterLoanNotificationResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      registerLoanNotificationOperationSpec,
      callback) as Promise<Models.RegisterLoanNotificationResponse>;
  }

  /**
   * @param loanId
   * @param notificationId
   * @param [options] The optional parameters
   * @returns Promise<Models.ReadLoanNotificationResponse>
   */
  readLoanNotification(loanId: string, notificationId: string, options?: msRest.RequestOptionsBase): Promise<Models.ReadLoanNotificationResponse>;
  /**
   * @param loanId
   * @param notificationId
   * @param callback The callback
   */
  readLoanNotification(loanId: string, notificationId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param notificationId
   * @param options The optional parameters
   * @param callback The callback
   */
  readLoanNotification(loanId: string, notificationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  readLoanNotification(loanId: string, notificationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.ReadLoanNotificationResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        notificationId,
        options
      },
      readLoanNotificationOperationSpec,
      callback) as Promise<Models.ReadLoanNotificationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCustomerList(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateCustomerList(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCustomerList(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateCustomerList(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateCustomerListOperationSpec,
      callback);
  }

  /**
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanBalanceAndSetting(offerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param offerId
   * @param callback The callback
   */
  updateLoanBalanceAndSetting(offerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanBalanceAndSetting(offerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateLoanBalanceAndSetting(offerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        offerId,
        options
      },
      updateLoanBalanceAndSettingOperationSpec,
      callback);
  }

  /**
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanSetting(offerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param offerId
   * @param callback The callback
   */
  updateLoanSetting(offerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanSetting(offerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateLoanSetting(offerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        offerId,
        options
      },
      updateLoanSettingOperationSpec,
      callback);
  }

  /**
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanBalance(offerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param offerId
   * @param callback The callback
   */
  updateLoanBalance(offerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanBalance(offerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateLoanBalance(offerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        offerId,
        options
      },
      updateLoanBalanceOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateNewTransactions(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateNewTransactions(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateNewTransactions(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateNewTransactions(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateNewTransactionsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reprocessComplete(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reprocessComplete(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reprocessComplete(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  reprocessComplete(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reprocessCompleteOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateDebtorLists(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateDebtorLists(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateDebtorLists(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateDebtorLists(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateDebtorListsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableLoanSignal(loanId: string, options?: Models.OriginationWriteApiEnableLoanSignalOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  enableLoanSignal(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  enableLoanSignal(loanId: string, options: Models.OriginationWriteApiEnableLoanSignalOptionalParams, callback: msRest.ServiceCallback<void>): void;
  enableLoanSignal(loanId: string, options?: Models.OriginationWriteApiEnableLoanSignalOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      enableLoanSignalOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateNewAdvances(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateNewAdvances(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateNewAdvances(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateNewAdvances(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateNewAdvancesOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  auditInvoice(options?: Models.OriginationWriteApiAuditInvoiceOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  auditInvoice(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  auditInvoice(options: Models.OriginationWriteApiAuditInvoiceOptionalParams, callback: msRest.ServiceCallback<void>): void;
  auditInvoice(options?: Models.OriginationWriteApiAuditInvoiceOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      auditInvoiceOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  dailyUpdate(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  dailyUpdate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  dailyUpdate(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  dailyUpdate(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      dailyUpdateOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  dailyUpdateOnLoan(options?: Models.OriginationWriteApiDailyUpdateOnLoanOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  dailyUpdateOnLoan(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  dailyUpdateOnLoan(options: Models.OriginationWriteApiDailyUpdateOnLoanOptionalParams, callback: msRest.ServiceCallback<void>): void;
  dailyUpdateOnLoan(options?: Models.OriginationWriteApiDailyUpdateOnLoanOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      dailyUpdateOnLoanOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  autoMatchingProcess(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  autoMatchingProcess(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  autoMatchingProcess(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  autoMatchingProcess(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      autoMatchingProcessOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationAllPayments(loanId: string, options?: Models.OriginationWriteApiReconciliationAllPaymentsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationAllPayments(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationAllPayments(loanId: string, options: Models.OriginationWriteApiReconciliationAllPaymentsOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reconciliationAllPayments(loanId: string, options?: Models.OriginationWriteApiReconciliationAllPaymentsOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationAllPaymentsOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationPayment(loanId: string, options?: Models.OriginationWriteApiReconciliationPaymentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationPayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationPayment(loanId: string, options: Models.OriginationWriteApiReconciliationPaymentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reconciliationPayment(loanId: string, options?: Models.OriginationWriteApiReconciliationPaymentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationPaymentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationRepayment(loanId: string, options?: Models.OriginationWriteApiReconciliationRepaymentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationRepayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationRepayment(loanId: string, options: Models.OriginationWriteApiReconciliationRepaymentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reconciliationRepayment(loanId: string, options?: Models.OriginationWriteApiReconciliationRepaymentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationRepaymentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationTransfers(loanId: string, options?: Models.OriginationWriteApiReconciliationTransfersOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationTransfers(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationTransfers(loanId: string, options: Models.OriginationWriteApiReconciliationTransfersOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reconciliationTransfers(loanId: string, options?: Models.OriginationWriteApiReconciliationTransfersOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationTransfersOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationExternalCredit(loanId: string, options?: Models.OriginationWriteApiReconciliationExternalCreditOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationExternalCredit(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationExternalCredit(loanId: string, options: Models.OriginationWriteApiReconciliationExternalCreditOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reconciliationExternalCredit(loanId: string, options?: Models.OriginationWriteApiReconciliationExternalCreditOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationExternalCreditOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reconciliationInvoicePayment(loanId: string, options?: Models.OriginationWriteApiReconciliationInvoicePaymentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  reconciliationInvoicePayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  reconciliationInvoicePayment(loanId: string, options: Models.OriginationWriteApiReconciliationInvoicePaymentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  reconciliationInvoicePayment(loanId: string, options?: Models.OriginationWriteApiReconciliationInvoicePaymentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      reconciliationInvoicePaymentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationAllPayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationAllPaymentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationAllPayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationAllPayment(loanId: string, options: Models.OriginationWriteApiUnreconciliationAllPaymentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unreconciliationAllPayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationAllPaymentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationAllPaymentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationPayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationPaymentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationPayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationPayment(loanId: string, options: Models.OriginationWriteApiUnreconciliationPaymentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unreconciliationPayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationPaymentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationPaymentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationRepayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationRepaymentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationRepayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationRepayment(loanId: string, options: Models.OriginationWriteApiUnreconciliationRepaymentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unreconciliationRepayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationRepaymentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationRepaymentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationInvoicePayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationInvoicePaymentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationInvoicePayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationInvoicePayment(loanId: string, options: Models.OriginationWriteApiUnreconciliationInvoicePaymentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unreconciliationInvoicePayment(loanId: string, options?: Models.OriginationWriteApiUnreconciliationInvoicePaymentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationInvoicePaymentOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationTransfers(loanId: string, options?: Models.OriginationWriteApiUnreconciliationTransfersOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationTransfers(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationTransfers(loanId: string, options: Models.OriginationWriteApiUnreconciliationTransfersOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unreconciliationTransfers(loanId: string, options?: Models.OriginationWriteApiUnreconciliationTransfersOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationTransfersOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  unreconciliationExternalCredit(loanId: string, options?: Models.OriginationWriteApiUnreconciliationExternalCreditOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  unreconciliationExternalCredit(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  unreconciliationExternalCredit(loanId: string, options: Models.OriginationWriteApiUnreconciliationExternalCreditOptionalParams, callback: msRest.ServiceCallback<void>): void;
  unreconciliationExternalCredit(loanId: string, options?: Models.OriginationWriteApiUnreconciliationExternalCreditOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      unreconciliationExternalCreditOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateReconcilationRequestResponse>
   */
  createReconcilationRequest(loanId: string, options?: Models.OriginationWriteApiCreateReconcilationRequestOptionalParams): Promise<Models.CreateReconcilationRequestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  createReconcilationRequest(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  createReconcilationRequest(loanId: string, options: Models.OriginationWriteApiCreateReconcilationRequestOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  createReconcilationRequest(loanId: string, options?: Models.OriginationWriteApiCreateReconcilationRequestOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.CreateReconcilationRequestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      createReconcilationRequestOperationSpec,
      callback) as Promise<Models.CreateReconcilationRequestResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateReconcilationMisdirectionResponse>
   */
  createReconcilationMisdirection(loanId: string, options?: Models.OriginationWriteApiCreateReconcilationMisdirectionOptionalParams): Promise<Models.CreateReconcilationMisdirectionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  createReconcilationMisdirection(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  createReconcilationMisdirection(loanId: string, options: Models.OriginationWriteApiCreateReconcilationMisdirectionOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  createReconcilationMisdirection(loanId: string, options?: Models.OriginationWriteApiCreateReconcilationMisdirectionOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.CreateReconcilationMisdirectionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      createReconcilationMisdirectionOperationSpec,
      callback) as Promise<Models.CreateReconcilationMisdirectionResponse>;
  }

  /**
   * @param loanId
   * @param paymentQueryId
   * @param [options] The optional parameters
   * @returns Promise<Models.PaymentQueryStatusChangeResponse>
   */
  paymentQueryStatusChange(loanId: string, paymentQueryId: string, options?: Models.OriginationWriteApiPaymentQueryStatusChangeOptionalParams): Promise<Models.PaymentQueryStatusChangeResponse>;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param callback The callback
   */
  paymentQueryStatusChange(loanId: string, paymentQueryId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param options The optional parameters
   * @param callback The callback
   */
  paymentQueryStatusChange(loanId: string, paymentQueryId: string, options: Models.OriginationWriteApiPaymentQueryStatusChangeOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  paymentQueryStatusChange(loanId: string, paymentQueryId: string, options?: Models.OriginationWriteApiPaymentQueryStatusChangeOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.PaymentQueryStatusChangeResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        paymentQueryId,
        options
      },
      paymentQueryStatusChangeOperationSpec,
      callback) as Promise<Models.PaymentQueryStatusChangeResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.AssignMisdirectionResponse>
   */
  assignMisdirection(loanId: string, options?: Models.OriginationWriteApiAssignMisdirectionOptionalParams): Promise<Models.AssignMisdirectionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  assignMisdirection(loanId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  assignMisdirection(loanId: string, options: Models.OriginationWriteApiAssignMisdirectionOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  assignMisdirection(loanId: string, options?: Models.OriginationWriteApiAssignMisdirectionOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.AssignMisdirectionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      assignMisdirectionOperationSpec,
      callback) as Promise<Models.AssignMisdirectionResponse>;
  }

  /**
   * @param loanId
   * @param paymentQueryId
   * @param [options] The optional parameters
   * @returns Promise<Models.AssignRequestResponse>
   */
  assignRequest(loanId: string, paymentQueryId: string, options?: Models.OriginationWriteApiAssignRequestOptionalParams): Promise<Models.AssignRequestResponse>;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param callback The callback
   */
  assignRequest(loanId: string, paymentQueryId: string, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  /**
   * @param loanId
   * @param paymentQueryId
   * @param options The optional parameters
   * @param callback The callback
   */
  assignRequest(loanId: string, paymentQueryId: string, options: Models.OriginationWriteApiAssignRequestOptionalParams, callback: msRest.ServiceCallback<Models.ApiResponseGuid>): void;
  assignRequest(loanId: string, paymentQueryId: string, options?: Models.OriginationWriteApiAssignRequestOptionalParams | msRest.ServiceCallback<Models.ApiResponseGuid>, callback?: msRest.ServiceCallback<Models.ApiResponseGuid>): Promise<Models.AssignRequestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        paymentQueryId,
        options
      },
      assignRequestOperationSpec,
      callback) as Promise<Models.AssignRequestResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartners(options?: Models.OriginationWriteApiUpdatePartnersOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updatePartners(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartners(options: Models.OriginationWriteApiUpdatePartnersOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updatePartners(options?: Models.OriginationWriteApiUpdatePartnersOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartnersOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addPartners(options?: Models.OriginationWriteApiAddPartnersOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addPartners(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartners(options: Models.OriginationWriteApiAddPartnersOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addPartners(options?: Models.OriginationWriteApiAddPartnersOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addPartnersOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartner1(options?: Models.OriginationWriteApiUpdatePartner1OptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updatePartner1(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartner1(options: Models.OriginationWriteApiUpdatePartner1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  updatePartner1(options?: Models.OriginationWriteApiUpdatePartner1OptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartner1OperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerCommission(partnerId: string, options?: Models.OriginationWriteApiUpdatePartnerCommissionOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  updatePartnerCommission(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerCommission(partnerId: string, options: Models.OriginationWriteApiUpdatePartnerCommissionOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updatePartnerCommission(partnerId: string, options?: Models.OriginationWriteApiUpdatePartnerCommissionOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      updatePartnerCommissionOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resetPassword(options?: Models.OriginationWriteApiResetPasswordOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  resetPassword(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  resetPassword(options: Models.OriginationWriteApiResetPasswordOptionalParams, callback: msRest.ServiceCallback<void>): void;
  resetPassword(options?: Models.OriginationWriteApiResetPasswordOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      resetPasswordOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendLink(partnerId: string, options?: Models.OriginationWriteApiSendLinkOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  sendLink(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendLink(partnerId: string, options: Models.OriginationWriteApiSendLinkOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendLink(partnerId: string, options?: Models.OriginationWriteApiSendLinkOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      sendLinkOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  resendSendLink(partnerId: string, options?: Models.OriginationWriteApiResendSendLinkOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  resendSendLink(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  resendSendLink(partnerId: string, options: Models.OriginationWriteApiResendSendLinkOptionalParams, callback: msRest.ServiceCallback<void>): void;
  resendSendLink(partnerId: string, options?: Models.OriginationWriteApiResendSendLinkOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      resendSendLinkOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  sendLinkForExisting(partnerId: string, options?: Models.OriginationWriteApiSendLinkForExistingOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  sendLinkForExisting(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  sendLinkForExisting(partnerId: string, options: Models.OriginationWriteApiSendLinkForExistingOptionalParams, callback: msRest.ServiceCallback<void>): void;
  sendLinkForExisting(partnerId: string, options?: Models.OriginationWriteApiSendLinkForExistingOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      sendLinkForExistingOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disablePartner(options?: Models.OriginationWriteApiDisablePartnerOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  disablePartner(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disablePartner(options: Models.OriginationWriteApiDisablePartnerOptionalParams, callback: msRest.ServiceCallback<void>): void;
  disablePartner(options?: Models.OriginationWriteApiDisablePartnerOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disablePartnerOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  notifyPasswordResetCompleted1(options?: Models.OriginationWriteApiNotifyPasswordResetCompleted1OptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  notifyPasswordResetCompleted1(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  notifyPasswordResetCompleted1(options: Models.OriginationWriteApiNotifyPasswordResetCompleted1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  notifyPasswordResetCompleted1(options?: Models.OriginationWriteApiNotifyPasswordResetCompleted1OptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      notifyPasswordResetCompleted1OperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addAccreditation(id: string, options?: Models.OriginationWriteApiAddAccreditationOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  addAccreditation(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  addAccreditation(id: string, options: Models.OriginationWriteApiAddAccreditationOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addAccreditation(id: string, options?: Models.OriginationWriteApiAddAccreditationOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      addAccreditationOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadW9Form(id: string, options?: Models.OriginationWriteApiUploadW9FormOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  uploadW9Form(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadW9Form(id: string, options: Models.OriginationWriteApiUploadW9FormOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadW9Form(id: string, options?: Models.OriginationWriteApiUploadW9FormOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      uploadW9FormOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBankStatement(id: string, options?: Models.OriginationWriteApiUploadBankStatementOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  uploadBankStatement(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBankStatement(id: string, options: Models.OriginationWriteApiUploadBankStatementOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadBankStatement(id: string, options?: Models.OriginationWriteApiUploadBankStatementOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      uploadBankStatementOperationSpec,
      callback);
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  uploadBlankInvoice(id: string, options?: Models.OriginationWriteApiUploadBlankInvoiceOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  uploadBlankInvoice(id: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  uploadBlankInvoice(id: string, options: Models.OriginationWriteApiUploadBlankInvoiceOptionalParams, callback: msRest.ServiceCallback<void>): void;
  uploadBlankInvoice(id: string, options?: Models.OriginationWriteApiUploadBlankInvoiceOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      uploadBlankInvoiceOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setPartnerRoles(partnerId: string, options?: Models.OriginationWriteApiSetPartnerRolesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  setPartnerRoles(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  setPartnerRoles(partnerId: string, options: Models.OriginationWriteApiSetPartnerRolesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setPartnerRoles(partnerId: string, options?: Models.OriginationWriteApiSetPartnerRolesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      setPartnerRolesOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerRoles(partnerId: string, options?: Models.OriginationWriteApiRemovePartnerRolesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  removePartnerRoles(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerRoles(partnerId: string, options: Models.OriginationWriteApiRemovePartnerRolesOptionalParams, callback: msRest.ServiceCallback<void>): void;
  removePartnerRoles(partnerId: string, options?: Models.OriginationWriteApiRemovePartnerRolesOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      removePartnerRolesOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addPartnerDailyReport(partnerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  addPartnerDailyReport(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  addPartnerDailyReport(partnerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  addPartnerDailyReport(partnerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      addPartnerDailyReportOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerDailyReportEmail(partnerId: string, options?: Models.OriginationWriteApiUpdatePartnerDailyReportEmailOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  updatePartnerDailyReportEmail(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerDailyReportEmail(partnerId: string, options: Models.OriginationWriteApiUpdatePartnerDailyReportEmailOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updatePartnerDailyReportEmail(partnerId: string, options?: Models.OriginationWriteApiUpdatePartnerDailyReportEmailOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      updatePartnerDailyReportEmailOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removePartnerDailyReport(partnerId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  removePartnerDailyReport(partnerId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  removePartnerDailyReport(partnerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  removePartnerDailyReport(partnerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      removePartnerDailyReportOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updatePartnerList1(options?: Models.OriginationWriteApiUpdatePartnerList1OptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updatePartnerList1(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updatePartnerList1(options: Models.OriginationWriteApiUpdatePartnerList1OptionalParams, callback: msRest.ServiceCallback<void>): void;
  updatePartnerList1(options?: Models.OriginationWriteApiUpdatePartnerList1OptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updatePartnerList1OperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addSupportingDocument(applicationId: string, options?: Models.OriginationWriteApiAddSupportingDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addSupportingDocument(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addSupportingDocument(applicationId: string, options: Models.OriginationWriteApiAddSupportingDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addSupportingDocument(applicationId: string, options?: Models.OriginationWriteApiAddSupportingDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addSupportingDocumentOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addSupportingDocumentDirectly(applicationId: string, options?: Models.OriginationWriteApiAddSupportingDocumentDirectlyOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addSupportingDocumentDirectly(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addSupportingDocumentDirectly(applicationId: string, options: Models.OriginationWriteApiAddSupportingDocumentDirectlyOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addSupportingDocumentDirectly(applicationId: string, options?: Models.OriginationWriteApiAddSupportingDocumentDirectlyOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addSupportingDocumentDirectlyOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addSupportingDocumentDirect(applicationId: string, options?: Models.OriginationWriteApiAddSupportingDocumentDirectOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  addSupportingDocumentDirect(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  addSupportingDocumentDirect(applicationId: string, options: Models.OriginationWriteApiAddSupportingDocumentDirectOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addSupportingDocumentDirect(applicationId: string, options?: Models.OriginationWriteApiAddSupportingDocumentDirectOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      addSupportingDocumentDirectOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @deprecated This operation is deprecated. Please do not use it any longer.
   * @returns Promise<msRest.RestResponse>
   */
  generateDocuments(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  generateDocuments(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   * @deprecated This operation is deprecated. Please do not use it any longer.
   */
  generateDocuments(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  generateDocuments(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      generateDocumentsOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addRequestSupportingDocument(options?: Models.OriginationWriteApiAddRequestSupportingDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addRequestSupportingDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addRequestSupportingDocument(options: Models.OriginationWriteApiAddRequestSupportingDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  addRequestSupportingDocument(options?: Models.OriginationWriteApiAddRequestSupportingDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addRequestSupportingDocumentOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeRequestSupportingDocument(options?: Models.OriginationWriteApiRemoveRequestSupportingDocumentOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  removeRequestSupportingDocument(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  removeRequestSupportingDocument(options: Models.OriginationWriteApiRemoveRequestSupportingDocumentOptionalParams, callback: msRest.ServiceCallback<void>): void;
  removeRequestSupportingDocument(options?: Models.OriginationWriteApiRemoveRequestSupportingDocumentOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      removeRequestSupportingDocumentOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setSite(options?: Models.OriginationWriteApiSetSiteOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  setSite(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  setSite(options: Models.OriginationWriteApiSetSiteOptionalParams, callback: msRest.ServiceCallback<void>): void;
  setSite(options?: Models.OriginationWriteApiSetSiteOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      setSiteOperationSpec,
      callback);
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const addPrimaryApplicantDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Applicant/{applicationId}/add-primary-application-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.doCheck
  ],
  requestBody: {
    parameterPath: "applicant",
    mapper: {
      ...Mappers.PrimaryApplicantAndSecondaryApplicantRequested,
      required: true
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const addSecondaryApplicantDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Applicant/{applicationId}/add-secondary-application-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.doCheck
  ],
  requestBody: {
    parameterPath: "applicant",
    mapper: {
      ...Mappers.SecondaryApplicant,
      required: true
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendSecondaryApplicantNoticeOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Applicant/{applicationId}/send-secondary-application-notice",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.email0,
    Parameters.phone
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicantDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Applicant/{applicationId}/update-applicant-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.applicantId
  ],
  requestBody: {
    parameterPath: [
      "options",
      "applicants"
    ],
    mapper: Mappers.Applicant
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerListOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-partner-list",
  queryParameters: [
    Parameters.partnerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPrimaryAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/primary-bank-accounts",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "account"
    ],
    mapper: Mappers.PlexeOrganisationServiceBankFeedAccount
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/send-message",
  queryParameters: [
    Parameters.messageType
  ],
  requestBody: {
    parameterPath: [
      "options",
      "message"
    ],
    mapper: {
      serializedName: "message",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendSimpleMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/send-simple-message",
  queryParameters: [
    Parameters.messageType,
    Parameters.message0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendApplicationReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/send-application-ready",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const completeApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/complete-application",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resetLoginOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/reset-login",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/create-application",
  requestBody: {
    parameterPath: [
      "options",
      "startApplicationRequest"
    ],
    mapper: Mappers.StartApplicationRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const createApplicationAndCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/create-application-and-customer",
  requestBody: {
    parameterPath: [
      "options",
      "applicationAndCustomer"
    ],
    mapper: Mappers.CreateApplicationAndCustomerRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationAndCustomerResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const updateCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-customer",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.customerId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const changeStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/change-status",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.status0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-partner",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.partnerId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateBusinessNameOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/update-business-name",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.businessName
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateBusinessDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/update-business-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: {
      serializedName: "details",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-application",
  requestBody: {
    parameterPath: [
      "options",
      "updateApplicationRequest"
    ],
    mapper: Mappers.UpdateApplicationRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateApplicationStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-application-status",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.status0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateOfferOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-offer",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.offerId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoginStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-login-status",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.status1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendConnectionEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/send-connecting-email",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const registerCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/register-communication",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "notification"
    ],
    mapper: Mappers.Communication
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/unregister-communication/{communicationId}",
  urlParameters: [
    Parameters.applicationId0,
    Parameters.communicationId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const communicationAddedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/communication-added",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.notificationId0,
    Parameters.message0,
    Parameters.user,
    Parameters.createdOnUtc
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendConnectionLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/send-connecting-link",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const cancelApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/cancel",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.reason
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const submittedApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/submitted",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.sms,
    Parameters.email0,
    Parameters.comment
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const linkBankingDataOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/link-banking-data",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "bankingData"
    ],
    mapper: Mappers.BankingData
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBankStatementsDocumentIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/upload-bank-statements-document-id",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.documentId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const linkAccoutingDataOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/link-accounting-data",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.host
  ],
  requestBody: {
    parameterPath: [
      "options",
      "accountingData"
    ],
    mapper: Mappers.AccountingData
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const completedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/completed",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.sms,
    Parameters.email0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const referOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/refer",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.sms,
    Parameters.email0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/confirm-banking-accounts",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "bankAccountIds"
    ],
    mapper: {
      serializedName: "bankAccountIds",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Number"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/connect-bankfeed-account",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "bankingData"
    ],
    mapper: Mappers.BankingData
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectYodleeBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/connect-yodlee-bankfeed-account",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.status2,
    Parameters.message1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectPlaidBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/connect-plaid-bankfeed-account",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.status2,
    Parameters.message1,
    Parameters.publicToken
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/confirm-bankfeed-account",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "bankAccount"
    ],
    mapper: Mappers.PlexeOrganisationServiceModelsBankAccount
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/confirm-bankfeed-accounts",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "bankAccount"
    ],
    mapper: {
      serializedName: "bankAccount",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "PlexeOrganisationServiceModelsBankAccount"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedYodleeOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/confirm-bankfeed-yodlee",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedPlaidOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/confirm-bankfeed-plaid",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendApplicationOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/send-application-otp",
  queryParameters: [
    Parameters.number
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendLoginOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/send-login-otp",
  queryParameters: [
    Parameters.email1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const confirmBankfeedMFADetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/confirm-bankfeed-mfa",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.uri,
    Parameters.yodlee
  ],
  requestBody: {
    parameterPath: [
      "options",
      "fields"
    ],
    mapper: {
      serializedName: "fields",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addTrustDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/add-trust-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "trust"
    ],
    mapper: Mappers.Trust
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const contractCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/contract-complete",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.CompleteContractRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatedContractCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/updated-contract-completed",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.CompleteContractRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const acceptOfferOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/accept-offer/{offerId}",
  urlParameters: [
    Parameters.applicationId0,
    Parameters.offerId1
  ],
  queryParameters: [
    Parameters.secured0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const generateOfferOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/generate-offer",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.accounting,
    Parameters.banking
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const commissionMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/commission",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.secured1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "commission"
    ],
    mapper: Mappers.Commission
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectAccountingCompanyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/connect-company",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.companyUri
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectAccountingCompanyWithDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/connect-company-with-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "reponseDetails"
    ],
    mapper: {
      serializedName: "reponseDetails",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const offerCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/offer-completed",
  queryParameters: [
    Parameters.offerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const offerFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/offer-failed",
  queryParameters: [
    Parameters.offerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankingSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/banking-system-connected",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankfeedSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/bankfeed-system-connected",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankingSystemFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/banking-system-failed",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankfeedSystemFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/bankfeed-system-failed",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.message0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-system-connected",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingCompanySystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-system-company-connected",
  queryParameters: [
    Parameters.applicationId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "companies"
    ],
    mapper: {
      serializedName: "companies",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingCompanySystemConnectedWithDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-system-company-connected-with-details",
  queryParameters: [
    Parameters.applicationId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "response"
    ],
    mapper: {
      serializedName: "response",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingSystemFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-system-failed",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankingSystemAccountsReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/banking-system-accounts-ready",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankfeedSystemAccountsReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/bankfeed-system-accounts-ready",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const bankfeedSystemMFAReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/bankfeed-system-mfa-ready",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const organisationCreatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/organisation-created",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const organisationFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/organisation-failed",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const applicationCanBeCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/application-can-be-completed",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const applicationCanNotBeCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/application-can-not-be-completed",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.message0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const loanCreatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/loan-created",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const applicationOfferUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/application-offer-updated",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingProcessedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-processed",
  queryParameters: [
    Parameters.organisationId,
    Parameters.message0,
    Parameters.count
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingProcessedErrorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-processed-error",
  queryParameters: [
    Parameters.organisationId,
    Parameters.message0,
    Parameters.url,
    Parameters.provider
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingQueueStartedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-process-started",
  queryParameters: [
    Parameters.organisationId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const accountingQueueWaitOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/accounting-process-queued",
  queryParameters: [
    Parameters.organisationId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const statementProcessedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/statement-processed",
  queryParameters: [
    Parameters.organisationId,
    Parameters.message0,
    Parameters.count
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const applicationStatusChangedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/application-status-change",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.customerId0,
    Parameters.partnerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const contractSignedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/contract-signed",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const refreshEquifaxOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/refresh-equifax",
  queryParameters: [
    Parameters.applicantId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const refreshEquifaxFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/refresh-equifax-failed",
  queryParameters: [
    Parameters.applicantId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const refreshIllionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/refresh-illion",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const refreshIllionFailedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/refresh-illion-failed",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const applicantIsReadyForEvaluationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/applicant-is-ready-for-evaluation",
  queryParameters: [
    Parameters.applicantId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateApplicationBusinessOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-application-business",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.message0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateIncomeOnReconnectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-income-on-reconnect",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addLoanCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/add-loan-customer",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoanCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/update-loan-customer",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.oldCustomerId,
    Parameters.newCustomerId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const removeLoanCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/remove-loan-customer",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const removePartnerExternalLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/remove-partner-external-link",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.partnerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateAuditDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/update-audit-details",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.auditType
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadLoanDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/upload-audit-document",
  urlParameters: [
    Parameters.loanId1
  ],
  formDataParameters: [
    Parameters.uploadedFile
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadLoanDocumentsAsByteArrayOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/upload-audit-document-as-byte-array",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.filename
  ],
  requestBody: {
    parameterPath: [
      "options",
      "uploadedFile"
    ],
    mapper: {
      serializedName: "uploadedFile",
      type: {
        name: "ByteArray"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const auditCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/audit-complete",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.notes
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const runAuditOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/run-audit",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const resetAuditDateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/reset-audit-date",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const onOffAuditOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/on-off-audit",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.onOffAuditParameter
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateAuditSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/update-audit-settings",
  queryParameters: [
    Parameters.lowDay,
    Parameters.highDay
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadDocumentAuditCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/upload-document-audit-complete",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const auditDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Audit/{loanId}/audit-document",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.location
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const setCorpratePasswordOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Card/set-corprate-password",
  queryParameters: [
    Parameters.username,
    Parameters.password,
    Parameters.dateOfBirth
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableCardServiceOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/enable-card-service",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disableCardServiceOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/disable-card-service",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setCardConfigurationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Card/{loanId}/set-card-configuration",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "configuration"
    ],
    mapper: Mappers.CardConfiguration
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Card/{loanId}/add-card",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: Mappers.CardHolderDetails
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Card/{loanId}/update-card",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: Mappers.CardHolderDetails
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/remove-card",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.cardId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const suspendCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/suspend-card",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.cardId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unSuspendCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/unsuspend-card",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.cardId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateCardConfigurationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Card/update-card-configuration-signalR",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createCorprateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Card/{loanId}/create-corprate",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: Mappers.CorprateCardHolder
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setCustomerConnectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Config/{customerId}/{connectionId}/set-customer-connection",
  urlParameters: [
    Parameters.customerId1,
    Parameters.connectionId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const testCustomerConnectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Config/test-on-loan-id",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resetCustomerConnectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Config/{connectionId}/reset-customer-connection",
  urlParameters: [
    Parameters.connectionId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/customers",
  requestBody: {
    parameterPath: [
      "options",
      "customers"
    ],
    mapper: {
      serializedName: "customers",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "RegisterCustomerRequest"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const addCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/customer",
  requestBody: {
    parameterPath: [
      "options",
      "customers"
    ],
    mapper: Mappers.RegisterCustomerRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const sendOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/customer/{customerId}/sendotp",
  urlParameters: [
    Parameters.customerId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const resetPassswordOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Customer/reset-password",
  requestBody: {
    parameterPath: [
      "options",
      "emailAddress"
    ],
    mapper: {
      serializedName: "emailAddress",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const notifyPasswordResetCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Customer/notify-password-reset",
  requestBody: {
    parameterPath: [
      "options",
      "brokerEmail"
    ],
    mapper: {
      serializedName: "brokerEmail",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateCustomer1OperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Customer/update-customer",
  queryParameters: [
    Parameters.id0,
    Parameters.firstName,
    Parameters.lastName,
    Parameters.mobile,
    Parameters.email1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disableCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Customer/disable",
  queryParameters: [
    Parameters.id1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const customerCreatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/customer/{customerId}/created",
  urlParameters: [
    Parameters.customerId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setUserRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Customer/{customerId}/set-user-roles",
  urlParameters: [
    Parameters.customerId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "roles"
    ],
    mapper: {
      serializedName: "roles",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeUserRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Customer/{customerId}/remove-user-roles",
  urlParameters: [
    Parameters.customerId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "roles"
    ],
    mapper: {
      serializedName: "roles",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const customerRolesUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Customer/customer-roles-updated",
  queryParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/upload-document",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.filename
  ],
  formDataParameters: [
    Parameters.uploadedFile
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/{applicationId}/upload-documents",
  urlParameters: [
    Parameters.applicationId0
  ],
  formDataParameters: [
    Parameters.uploadedFiles
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setEmployeePasswordOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/set-employee-password",
  queryParameters: [
    Parameters.username,
    Parameters.password,
    Parameters.dateOfBirth
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableEmployeeEarlyAccessOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/enable-employee-early-access",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.employeeId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disableEmployeeEarlyAccessOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/disable-employee-early-access",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.employeeId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const connectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/connect",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.providerType
  ],
  requestBody: {
    parameterPath: [
      "options",
      "connectionData"
    ],
    mapper: {
      serializedName: "connectionData",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disconnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/disconnected",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.providerType
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setEmployeeWithdrawDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/set-employee-withdraw-details",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.employeeId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: {
      serializedName: "details",
      type: {
        name: "Dictionary",
        value: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const makeWithdrawRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/make-withdraw-request",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.employeeId0,
    Parameters.amount
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const earlyAccessConnectionUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/early-access-connection-updated",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const employeeStatusUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/employee-status-updated",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const processFinchOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/process-finch",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.code
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const inviteAllEmployeesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{loanId}/invite-all-employees",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateEmployeeDateOfBirthOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/update-employee-dateofbirth",
  queryParameters: [
    Parameters.employeeId0,
    Parameters.dateOfBirth
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setEmployeeRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{employeeId}/set-employee-roles",
  urlParameters: [
    Parameters.employeeId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "roles"
    ],
    mapper: {
      serializedName: "roles",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeEmployeeRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/{employeeId}/remove-employee-roles",
  urlParameters: [
    Parameters.employeeId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "roles"
    ],
    mapper: {
      serializedName: "roles",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBaseSupportDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/upload-base-support-document",
  queryParameters: [
    Parameters.name,
    Parameters.filename
  ],
  formDataParameters: [
    Parameters.uploadedFile
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateBaseSupportDocumentForApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/update-base-support-document-for-application",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const overrideContractOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/override-contract",
  queryParameters: [
    Parameters.applicationId1
  ],
  formDataParameters: [
    Parameters.uploadedFile
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadW9SampleDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Application/upload-w9-sample-document",
  queryParameters: [
    Parameters.name
  ],
  formDataParameters: [
    Parameters.uploadedFile
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const turnOnChangeOfFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/turn-on-change-of-facility",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateTransactionDateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-transaction-date",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId,
    Parameters.newdate
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateTransactionDebtorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-transaction-debtor",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId,
    Parameters.debtor
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerTransactionsSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-transaction-summary",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: {
      serializedName: "request",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "TransactionProjection"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseListGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterTransactionSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unregister-transaction-summary",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerSyntheticPaymentSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-synthetic-payment-summary",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerSyntheticPaymentFullOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-synthetic-payment-full",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId,
    Parameters.debtor,
    Parameters.details,
    Parameters.amount,
    Parameters.dateParameter
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const reconcileTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reconcile-transaction",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId,
    Parameters.reconciled
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseString
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterSyntheticPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unregister-synthetic-transaction-summary",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId,
    Parameters.debtor,
    Parameters.details,
    Parameters.amount,
    Parameters.dateParameter
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const deleteSyntheticPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/delete-synthetic-transaction",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.sythenticTransactionId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerAdvancesSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-advance-summary",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: {
      serializedName: "request",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "AdvanceProjection"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseListAdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterAdvancesSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unregister-advance-summary",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawStartDateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-startdate",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.startDate
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateAdvanceDateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-advance-date",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.startDate
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawWeeklyRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-weeklyrepayment",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.weeklyrepayment
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawTrailingCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-trailing-commission",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.trailing
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawLegacyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-legacy",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.legacy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawUpfrontFeeCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-upfront-fee-commission",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.upfrontFee
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateAdvanceLastRepaymentDateOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-advance-last-repayment-date",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.lastRepayment
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsPercentageOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-percentage",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawPercentageRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsPercentageOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-percentage-override",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawPercentageRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseAdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawAsPercentageOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-as-percentage-override",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawPercentageRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-invoice-payment",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawInvoiceRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawAsInvoicePaymentOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-invoice-payment-override",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawInvoiceRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseAdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawAsInvoicePaymentOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-as-invoice-payment-override",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawInvoiceRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawFixedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-fixed",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawFixedRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawFixedOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-fixed-override",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawFixedRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseAdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawFixedOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-as-fixed-override",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawFixedRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawLineOfCreditOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-line-of-credit",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawLineOfCreditRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawLineOfCreditOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-line-of-credit-override",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawLineOfCreditRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseAdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawLineOfCreditOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-as-line-of-credit-override",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawLineOfCreditRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawCreditCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-credit-card",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawCreditCardRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const withdrawCreditCardOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/withdraw-as-credit-card-override",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawCreditCardRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseAdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const updateWithdrawCreditCardOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-withdraw-as-credit-card-override",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: Mappers.WithdrawCreditCardRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerPaymentArrangementOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/payment-arrangement",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.startDate,
    Parameters.endDate,
    Parameters.amount
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseString
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterPaymentArrangementOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unregister-payment-arrangement",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentArrangementId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseString
    },
    500: {},
    default: {}
  },
  serializer
};

const earlyRepayAdvanceOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/early-repay-advance",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-repayment-details",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "request"
    ],
    mapper: {
      serializedName: "request",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "RepaymentAdjustmentProjection"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerEarlyRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-early-repayment-details",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const registerSkipRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-skip-repayment-details",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const unregisterRepaymentDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unregister-repayment-details",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.repaymentId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const addCommissionToAdvanceOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/add-commission-to-advance",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.advanceId0,
    Parameters.amount
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const payCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/pay-commission/{advanceId}",
  urlParameters: [
    Parameters.loanId1,
    Parameters.advanceId2
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const commissionMethod1OperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/commission",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "commissionSet"
    ],
    mapper: Mappers.CommissionSet
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableDebtorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/enable-debtor",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.debtorId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableDebtorServiceOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/enable-debtor-service",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.enabled0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableShowAllDebtorOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/enable-show-all-debtor",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.enabled0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const loginProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/login-process",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateBankIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-bank-id",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.bankId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateFunderOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-funder",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.funder0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/enable",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.enabled1,
    Parameters.reason
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanCustomerDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-customer",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateBusinessNameDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-business-name",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.businessName
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateBalanceOverrideOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-balance-override",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.balanceOverride
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoanStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-health",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.health
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconnectAccountingDataOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/reconnect-accounting-data",
  queryParameters: [
    Parameters.host,
    Parameters.loanId2
  ],
  requestBody: {
    parameterPath: [
      "options",
      "accountingData"
    ],
    mapper: Mappers.AccountingData
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reprocessAccountingDataCommandOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/reprocess-loan",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disconnectAccountingSystemOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/disconnect-accounting-system",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const processPaymentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/process-payments",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const closeOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/close",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.defaulted
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const switchLoanTypeOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/switch-loan-type",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.loanType
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const openOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/open",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const archivedOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/archived",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.archive
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanPartnerDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-partner",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.partnerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addChangeToFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/add-change-to-facility",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.newAmount
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeChangeToFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/remove-change-to-facility",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createLoanOnApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/create-on-application-id",
  queryParameters: [
    Parameters.applicationId1,
    Parameters.funder1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const registerBankAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-bank-account",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "bankDetails"
    ],
    mapper: Mappers.BankDetails
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateRevolvingFacilityOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/revolving-facility",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.revolving,
    Parameters.local
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendOtp1OperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/sendotp",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const registerLoanNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/register-notification",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "notification"
    ],
    mapper: Mappers.Notification
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const readLoanNotificationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/{notificationId}/read-notification",
  urlParameters: [
    Parameters.loanId1,
    Parameters.notificationId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updateCustomerListOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/update-customer-list",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanBalanceAndSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{offerId}/update-balance-and-setting",
  urlParameters: [
    Parameters.offerId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanSettingOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{offerId}/update-loan-setting",
  urlParameters: [
    Parameters.offerId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanBalanceOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{offerId}/update-loan-balance",
  urlParameters: [
    Parameters.offerId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateNewTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-transactions",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reprocessCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reprocess-complete",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateDebtorListsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/debtor-updated",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const enableLoanSignalOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/enable-loan-signal",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.enable,
    Parameters.reason
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateNewAdvancesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/update-advances",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const auditInvoiceOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/audit-invoice",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const dailyUpdateOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/daily-reprocess",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const dailyUpdateOnLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/daily-reprocess-for-loan",
  queryParameters: [
    Parameters.loanId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const autoMatchingProcessOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/auto-payment-processing",
  urlParameters: [
    Parameters.loanId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationAllPaymentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reconciliation-all-payment",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentDetails"
    ],
    mapper: {
      serializedName: "paymentDetails",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "PaymentDetails"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reconciliation-payment",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reconciliation-repayment",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount,
    Parameters.transactionId
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationTransfersOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reconciliation-transfers",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationExternalCreditOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reconciliation-external-credit",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const reconciliationInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/reconciliation-invoice-payment",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount,
    Parameters.transactionId
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationAllPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unreconciliation-all-payment",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "payments"
    ],
    mapper: {
      serializedName: "payments",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "PaymentDetails"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationPaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unreconciliation-payment",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unreconciliation-repayment",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unreconciliation-invoice-payment",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentId,
    Parameters.syntheticPaymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationTransfersOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unreconciliation-transfers",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const unreconciliationExternalCreditOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/unreconciliation-external-credit",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.paymentId,
    Parameters.paymentQueryId0,
    Parameters.amount,
    Parameters.debtor,
    Parameters.paymentDate,
    Parameters.paymentAmount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentReference"
    ],
    mapper: {
      serializedName: "paymentReference",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const createReconcilationRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/create-reconciliation-request",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: Mappers.ReconciliationDetails
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const createReconcilationMisdirectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/create-reconciliation-misdirection",
  urlParameters: [
    Parameters.loanId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: Mappers.ReconciliationDetails
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const paymentQueryStatusChangeOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/payment-query-status-change/{paymentQueryId}",
  urlParameters: [
    Parameters.loanId1,
    Parameters.paymentQueryId1
  ],
  queryParameters: [
    Parameters.status0,
    Parameters.notes,
    Parameters.updateReconcilationStatus
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const assignMisdirectionOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/assign-misdirection",
  urlParameters: [
    Parameters.loanId1
  ],
  queryParameters: [
    Parameters.transactionId,
    Parameters.dateParameter,
    Parameters.details,
    Parameters.debtors,
    Parameters.amount
  ],
  requestBody: {
    parameterPath: [
      "options",
      "paymentQueryIds"
    ],
    mapper: {
      serializedName: "paymentQueryIds",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Uuid"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const assignRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/assign-request/{paymentQueryId}",
  urlParameters: [
    Parameters.loanId1,
    Parameters.paymentQueryId1
  ],
  queryParameters: [
    Parameters.transactionId,
    Parameters.dateParameter,
    Parameters.details,
    Parameters.debtors,
    Parameters.amount
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApiResponseGuid
    },
    500: {},
    default: {}
  },
  serializer
};

const updatePartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "api/partners",
  requestBody: {
    parameterPath: [
      "options",
      "partners"
    ],
    mapper: {
      serializedName: "partners",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "RegisterPartnerRequest"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addPartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/partners",
  requestBody: {
    parameterPath: [
      "options",
      "partners"
    ],
    mapper: {
      serializedName: "partners",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Composite",
            className: "RegisterPartnerRequest"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartner1OperationSpec: msRest.OperationSpec = {
  httpMethod: "PUT",
  path: "api/partner",
  queryParameters: [
    Parameters.partnerId1
  ],
  requestBody: {
    parameterPath: [
      "options",
      "partner"
    ],
    mapper: Mappers.RegisterPartnerRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerCommissionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/{partnerId}/update-partner-commission",
  urlParameters: [
    Parameters.partnerId2
  ],
  queryParameters: [
    Parameters.locTrailing,
    Parameters.unsecuredTrailing,
    Parameters.securedTrailing,
    Parameters.upfront
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const resetPasswordOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/reset-password",
  requestBody: {
    parameterPath: [
      "options",
      "emailAddress"
    ],
    mapper: {
      serializedName: "emailAddress",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/send-link",
  urlParameters: [
    Parameters.partnerId2
  ],
  requestBody: {
    parameterPath: [
      "options",
      "partnerDetail"
    ],
    mapper: Mappers.PartnerSendLinkRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const resendSendLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/resend-send-link",
  urlParameters: [
    Parameters.partnerId2
  ],
  queryParameters: [
    Parameters.requestId
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const sendLinkForExistingOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/send-link-for-existing",
  urlParameters: [
    Parameters.partnerId2
  ],
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const disablePartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/disable",
  queryParameters: [
    Parameters.id1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const notifyPasswordResetCompleted1OperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/notify-password-reset",
  requestBody: {
    parameterPath: [
      "options",
      "brokerEmail"
    ],
    mapper: {
      serializedName: "brokerEmail",
      type: {
        name: "String"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addAccreditationOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{Id}/add-accreditation",
  urlParameters: [
    Parameters.id0
  ],
  requestBody: {
    parameterPath: [
      "options",
      "partnerAccreditationDetail"
    ],
    mapper: Mappers.PartnerAccreditationRequest
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadW9FormOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{Id}/upload-w9-form",
  urlParameters: [
    Parameters.id0
  ],
  formDataParameters: [
    Parameters.uploadedFile
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBankStatementOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{Id}/upload-bank-statement",
  urlParameters: [
    Parameters.id0
  ],
  formDataParameters: [
    Parameters.uploadedFiles
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const uploadBlankInvoiceOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{Id}/upload-blank-invoice",
  urlParameters: [
    Parameters.id0
  ],
  formDataParameters: [
    Parameters.uploadedFiles
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setPartnerRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/set-partner-roles",
  urlParameters: [
    Parameters.partnerId2
  ],
  requestBody: {
    parameterPath: [
      "options",
      "roles"
    ],
    mapper: {
      serializedName: "roles",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removePartnerRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/remove-user-roles",
  urlParameters: [
    Parameters.partnerId2
  ],
  requestBody: {
    parameterPath: [
      "options",
      "roles"
    ],
    mapper: {
      serializedName: "roles",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addPartnerDailyReportOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/add-partner-daily-report",
  urlParameters: [
    Parameters.partnerId2
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerDailyReportEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/update-partner-daily-report-email",
  urlParameters: [
    Parameters.partnerId2
  ],
  queryParameters: [
    Parameters.email1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removePartnerDailyReportOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/{partnerId}/remove-partner-daily-report",
  urlParameters: [
    Parameters.partnerId2
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updatePartnerList1OperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Partner/update-partner-list",
  queryParameters: [
    Parameters.partnerId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/SupportingDocument/{applicationId}/add-supporting-document",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.supportingDocumentId,
    Parameters.fileType,
    Parameters.fileName
  ],
  requestBody: {
    parameterPath: [
      "options",
      "fileData"
    ],
    mapper: {
      serializedName: "fileData",
      type: {
        name: "ByteArray"
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addSupportingDocumentDirectlyOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/SupportingDocument/{applicationId}/add-supporting-document-directly",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.fileType,
    Parameters.fileName,
    Parameters.details
  ],
  formDataParameters: [
    Parameters.fileData
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addSupportingDocumentDirectOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/SupportingDocument/{applicationId}/add-supporting-document-direct",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.supportingDocumentId
  ],
  formDataParameters: [
    Parameters.uploadedFile
  ],
  contentType: "multipart/form-data",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const generateDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/SupportingDocument/{applicationId}/generate-document",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const addRequestSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/SupportingDocument/add-request-supporting-document",
  queryParameters: [
    Parameters.title
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const removeRequestSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/SupportingDocument/remove-request-supporting-document",
  queryParameters: [
    Parameters.title
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const setSiteOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/WhiteLabel/set-site",
  requestBody: {
    parameterPath: [
      "options",
      "details"
    ],
    mapper: Mappers.SiteDetails
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

export {
  OriginationWriteApi,
  OriginationWriteApiContext,
  Models as OriginationWriteApiModels,
  Mappers as OriginationWriteApiMappers
};
