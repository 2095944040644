/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";
import * as msRest from "@azure/ms-rest-js";

/**
 * An interface representing Name.
 */
export interface Name {
  first?: string;
  last?: string;
}

/**
 * An interface representing DrivingLicense.
 */
export interface DrivingLicense {
  issuingState?: string;
  cardNumber?: string;
  expiryDate?: Date;
  name?: string;
  address?: string;
  dateOfBirth?: Date;
  image?: Uint8Array;
}

/**
 * An interface representing Applicant.
 */
export interface Applicant {
  name?: string;
  driversLicense?: DrivingLicense;
  isPrimary?: boolean;
  email?: string;
  mobile?: string;
  applicationId?: string;
  miscellaneousData?: { [propertyName: string]: string };
  id: string;
}

/**
 * An interface representing PrimaryApplicant.
 */
export interface PrimaryApplicant {
  isManuallyAdded?: boolean;
  name?: string;
  driversLicense?: DrivingLicense;
  isPrimary?: boolean;
  email?: string;
  mobile?: string;
  applicationId?: string;
  miscellaneousData?: { [propertyName: string]: string };
  id: string;
}

/**
 * An interface representing SecondaryApplicant.
 */
export interface SecondaryApplicant {
  isManuallyAdded?: boolean;
  name?: string;
  driversLicense?: DrivingLicense;
  isPrimary?: boolean;
  email?: string;
  mobile?: string;
  applicationId?: string;
  miscellaneousData?: { [propertyName: string]: string };
  id: string;
}

/**
 * An interface representing ApplicationsOverview.
 */
export interface ApplicationsOverview {
  partnerId?: string;
  totalAmountApproved?: number;
  totalAplicationsApproved?: number;
  totalAmountPending?: number;
  totalApplicationsPending?: number;
  totalCommissionsPaid?: number;
  totalCommissionsDeals?: number;
}

/**
 * An interface representing BoolWrapper.
 */
export interface BoolWrapper {
  value?: boolean;
}

/**
 * An interface representing TransactionDataFile.
 */
export interface TransactionDataFile {
  data?: Uint8Array;
  fileName?: string;
  fileType?: string;
}

/**
 * An interface representing Commission.
 */
export interface Commission {
  upfrontFee?: number;
  trailing?: number;
}

/**
 * An interface representing Loan.
 */
export interface Loan {
  applicationId?: string;
  offerId?: string;
  loanType?: number;
  enabled?: boolean;
  closed?: boolean;
  archived?: boolean;
  revolving?: boolean;
  enabledDebtors?: boolean;
  showAllDebtors?: boolean;
  bankId?: string;
  funder?: string;
  health?: string;
  createdDate?: Date;
  customerId?: string;
  partnerId?: string;
  organisationId?: string;
  customerFullName?: string;
  businessName?: string;
  securedCommission?: Commission;
  lineOfCommission?: Commission;
  commission?: Commission;
  salesPerson?: string;
  lastUpdated?: Date;
  secured?: boolean;
  loanTypeName?: string;
  tradingName?: string;
  fullBusinessName?: string;
  nextAuditDate?: Date;
  auditHighRange?: number;
  auditLowRange?: number;
  auditType?: string;
  auditTypeList?: string[];
  closedDate?: Date;
  balanceOverride?: boolean;
  defaulted?: boolean;
  defaultStatus?: string;
  customerIds?: string[];
  customerIdStr?: string;
  id: string;
}

/**
 * An interface representing Application.
 */
export interface Application {
  partnerId?: string;
  customerId?: string;
  abn?: string;
  businessName?: string;
  shortCode?: string;
  statusName?: string;
  securedCommission?: Commission;
  lineOfCreditCommission?: Commission;
  commission?: Commission;
  organisationId?: string;
  offerId?: string;
  isUnitTrust?: boolean;
  secured?: boolean;
  tradingName?: string;
  fullBusinessName?: string;
  offerAccepted?: boolean;
  loanName?: number;
  acn?: string;
  acnType?: string;
  contactSms?: boolean;
  contactEmail?: boolean;
  hasLoggedIn?: boolean;
  contractIPAddress?: string;
  contractSignatureLocation?: string;
  contractSignature2Location?: string;
  contractIPAddressSecondary?: string;
  contractSignatureLocationSecondary?: string;
  contractSignature2LocationSecondary?: string;
  cancelledReason?: string;
  secondaryApplicantRequested?: boolean;
  primaryApplicantAdded?: boolean;
  secondaryApplicantAdded?: boolean;
  contractSignatureDate?: string;
  contractSignature2Date?: string;
  contractSignatureSecondaryDate?: string;
  contractSignature2SecondaryDate?: string;
  createdDate?: Date;
  id: string;
}

/**
 * An interface representing Customer.
 */
export interface Customer {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  businessName?: string;
  tradingName?: string;
  password?: string;
  type?: string;
  parentId?: string;
  dateOfBirth?: Date;
  hasLoggedIn?: boolean;
  id: string;
}

/**
 * An interface representing PartnerCommission.
 */
export interface PartnerCommission {
  unsecuredTrail?: number;
  securedTrail?: number;
  locTrail?: number;
  upFrontFee?: number;
}

/**
 * An interface representing Partner.
 */
export interface Partner {
  email: string;
  firstName: string;
  lastName: string;
  businessName?: string;
  mobilePhoneNumber?: string;
  commission?: PartnerCommission;
  urlReferral?: string;
  id: string;
}

/**
 * An interface representing PlexeOrganisationServiceModelsOrganisationAddress.
 */
export interface PlexeOrganisationServiceModelsOrganisationAddress {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  region?: string;
  postalCode?: string;
  country?: string;
}

/**
 * An interface representing PlexeOrganisationServiceModelsOrganisationPhone.
 */
export interface PlexeOrganisationServiceModelsOrganisationPhone {
  phoneType?: string;
  phoneNumber?: string;
  phoneAreaCode?: string;
  phoneCountryCode?: string;
}

/**
 * An interface representing PlexeOrganisationServiceModelsApplicant.
 */
export interface PlexeOrganisationServiceModelsApplicant {
  firstName?: string;
  surname?: string;
  mobile?: string;
  email?: string;
  middleName?: string;
  dateOfBirth?: string;
  title?: string;
  ownershipType?: string;
  citizenshipStaus?: string;
  unitNo?: string;
  streetNo?: string;
  street?: string;
  city?: string;
  state?: string;
  postcode?: string;
  timeAtAddress?: string;
  driverLicenceState?: string;
  driverLicenceExpiryDate?: string;
  medicareNumber?: string;
  medicareExpiryDate?: string;
  medicarePosition?: string;
  medicareNameOnCard?: string;
  driverLicenceNumber?: string;
  id?: string;
}

/**
 * An interface representing PlexeOrganisationServiceModelsNews.
 */
export interface PlexeOrganisationServiceModelsNews {
  datetime?: Date;
  source?: string;
  details?: string;
}

/**
 * An interface representing PlexeOrganisationServiceModelsNote.
 */
export interface PlexeOrganisationServiceModelsNote {
  datetime?: Date;
  author?: string;
  details?: string;
}

/**
 * An interface representing PlexeLookupServiceProxyModelsOrganisationDetails.
 */
export interface PlexeLookupServiceProxyModelsOrganisationDetails {
  entityType?: string;
  entityTypeCode?: string;
  fromDate?: Date;
  toDate?: Date;
  postcode?: string;
  state?: string;
  familyName?: string;
  firstName?: string;
  otherName?: string;
  mainName?: string;
  status?: string;
  name?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsPaymentTerm.
 */
export interface PlexeAccountingProxyModelsPaymentTerm {
  day?: number;
  termType?: number;
}

/**
 * An interface representing PlexeAccountingProxyModelsAddress.
 */
export interface PlexeAccountingProxyModelsAddress {
  addressType?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  city?: string;
  state?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  attentionTo?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsPhone.
 */
export interface PlexeAccountingProxyModelsPhone {
  phoneType?: number;
  phoneNumber?: string;
  phoneAreaCode?: string;
  phoneCountryCode?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsOrganisation.
 */
export interface PlexeAccountingProxyModelsOrganisation {
  name?: string;
  abn?: string;
  acn?: string;
  legalName?: string;
  shortCode?: string;
  paysTax?: boolean;
  version?: number;
  organizationType?: number;
  isDemoCompany?: boolean;
  apiKey?: string;
  countryCode?: string;
  taxNumber?: string;
  financialYearEndDay?: number;
  financialYearEndMonth?: number;
  periodLockDate?: Date;
  endOfYearLockDate?: Date;
  createdDateUtc?: Date;
  timezone?: string;
  lineOfBusiness?: string;
  registrationNumber?: string;
  billsPaymentTerm?: PlexeAccountingProxyModelsPaymentTerm;
  salesPaymentTerm?: PlexeAccountingProxyModelsPaymentTerm;
  salesTaxBasisType?: number;
  salesTaxPeriodType?: number;
  baseCurrency?: string;
  addresses?: PlexeAccountingProxyModelsAddress[];
  phones?: PlexeAccountingProxyModelsPhone[];
}

/**
 * An interface representing PlexeOrganisationServiceModelsOrganisation.
 */
export interface PlexeOrganisationServiceModelsOrganisation {
  name?: string;
  abn?: string;
  id?: string;
  applicationId?: string;
  bankStatement?: boolean;
  bankFeed?: boolean;
  accounting?: boolean;
  archive?: boolean;
  address?: PlexeOrganisationServiceModelsOrganisationAddress;
  numbers?: PlexeOrganisationServiceModelsOrganisationPhone[];
  applicants?: PlexeOrganisationServiceModelsApplicant[];
  news?: PlexeOrganisationServiceModelsNews[];
  notes?: PlexeOrganisationServiceModelsNote[];
  asicDetails?: PlexeLookupServiceProxyModelsOrganisationDetails;
  accountingSystem?: string;
  accountingSystemDetails?: PlexeAccountingProxyModelsOrganisation;
  createdDate?: Date;
  bankStatementType?: string;
  bankFeedConnectionType?: string;
  plexeBsb?: string;
  plexeAccountNumber?: string;
  bankingProviderCustomerId?: string;
  automaticDirectDebit?: boolean;
  invoiceExclusion?: boolean;
  accountingWriteBack?: boolean;
  automaticAccountingImport?: boolean;
}

/**
 * An interface representing Summary.
 */
export interface Summary {
  loan?: Loan;
  application?: Application;
  quickUrl?: string;
  customer?: Customer;
  customers?: Customer[];
  partner?: Partner;
  organisation?: PlexeOrganisationServiceModelsOrganisation;
  applicants?: Applicant[];
}

/**
 * An interface representing CommissionDetails.
 */
export interface CommissionDetails {
  advanceRate?: number;
  upFrontBrokerEstablishmentFee?: number;
  firstAdvanceCommissionFee?: number;
  ongoingAdvanceCommissionFee?: number;
  total?: number;
}

/**
 * An interface representing Amount.
 */
export interface Amount {
  value?: number;
  currencyCode?: string;
}

/**
 * An interface representing ApplicationListProjection.
 */
export interface ApplicationListProjection {
  id?: string;
  partnerId?: string;
  customerId?: string;
  customerFullName?: string;
  businessName?: string;
  shortCode?: string;
  status?: string;
  cancelledReason?: string;
  totalAmount?: Amount;
  availableCredit?: Amount;
  securedCommission?: Commission;
  commission?: Commission;
  lineOfCreditCommission?: Commission;
  creditType?: string;
  salesPerson?: string;
  abn?: string;
  lastUpdated?: Date;
  createdDate?: Date;
  isUnitTrust?: boolean;
  organisationId?: string;
  offerId?: string;
  tradingName?: string;
  hasLoggedIn?: boolean;
  fullBusinessName?: string;
  secured?: boolean;
  offerAccepted?: boolean;
  loanType?: string;
  acn?: string;
  acnType?: string;
  accountingConnected?: boolean;
  accountingSkipped?: boolean;
  bankingConnected?: boolean;
  bankingSkipped?: boolean;
  contactEmail?: boolean;
  contactSms?: boolean;
  contractIPAddress?: string;
  contractSignatureLocation?: string;
  contractSignature2Location?: string;
  contractSignatureLocationSecondary?: string;
  contractSignature2LocationSecondary?: string;
  contractSignatureDate?: string;
  contractSignature2Date?: string;
  contractSignatureSecondaryDate?: string;
  contractSignature2SecondaryDate?: string;
  contractIPAddressSecondary?: string;
  secondaryApplicantRequested?: boolean;
  primaryApplicantAdded?: boolean;
  secondaryApplicantAdded?: boolean;
  customerIds?: string;
}

/**
 * An interface representing PagedListApplicationListProjection.
 */
export interface PagedListApplicationListProjection {
  data?: ApplicationListProjection[];
  totalCount?: number;
}

/**
 * An interface representing PlexeOrganisationServiceModelsBankAccount.
 */
export interface PlexeOrganisationServiceModelsBankAccount {
  name?: string;
  accountNumber?: string;
  id?: string;
  bsb?: string;
  balance?: string;
  available?: string;
  accountHolder?: string;
  accountType?: string;
}

/**
 * An interface representing PlexeBankStatementsProxyModelsField.
 */
export interface PlexeBankStatementsProxyModelsField {
  type?: string;
  text?: string;
  value?: string;
  values?: { [propertyName: string]: string };
  fieldID?: string;
}

/**
 * An interface representing PlexeBankStatementsProxyModelsMFADetails.
 */
export interface PlexeBankStatementsProxyModelsMFADetails {
  title?: string;
  fields?: PlexeBankStatementsProxyModelsField[];
  submitTo?: string;
}

/**
 * An interface representing PlexeBankStatementsProxyModelsToken.
 */
export interface PlexeBankStatementsProxyModelsToken {
  accessToken?: string;
  issuedAt?: Date;
  expiresIn?: number;
  isValid?: boolean;
}

/**
 * An interface representing PlexeBankStatementsProxyModelsFastLinkToken.
 */
export interface PlexeBankStatementsProxyModelsFastLinkToken {
  fastLinkUri?: string;
  token?: PlexeBankStatementsProxyModelsToken;
}

/**
 * An interface representing OfferType.
 */
export interface OfferType {
  creditLimit?: number;
  facilityLimit?: number;
  advanceRate?: number;
  establishmentFee?: number;
  weeks?: number;
  decision?: number;
}

/**
 * An interface representing OfferAmount.
 */
export interface OfferAmount {
  id?: string;
  unsecured?: OfferType;
  secured?: OfferType;
  lineOfCredit?: OfferType;
  creditCard?: OfferType;
}

/**
 * An interface representing ApplicationSettings.
 */
export interface ApplicationSettings {
  fixedRepayment?: boolean;
  percentOfIncome?: boolean;
  invoicePayment?: boolean;
  lineOfCreditPayment?: boolean;
  creditCardPayment?: boolean;
}

/**
 * An interface representing CommunicationProjection.
 */
export interface CommunicationProjection {
  notificationId?: string;
  applicationId?: string;
  createdDateUtc?: Date;
  message?: string;
  user?: string;
}

/**
 * An interface representing DebtorDetails.
 */
export interface DebtorDetails {
  name?: string;
  invoiceTypes?: { [propertyName: string]: number };
}

/**
 * An interface representing LoanDebtorProjection.
 */
export interface LoanDebtorProjection {
  name?: string;
  id?: string;
  details?: DebtorDetails;
  approved?: number;
  fundingRate?: number;
  available?: number;
  selected?: boolean;
  requested?: boolean;
  total?: number;
  adminSelected?: boolean;
}

/**
 * An interface representing PagedListLoanDebtorProjection.
 */
export interface PagedListLoanDebtorProjection {
  data?: LoanDebtorProjection[];
  totalCount?: number;
}

/**
 * An interface representing FixedRepaymentCalculation.
 */
export interface FixedRepaymentCalculation {
  terms?: number;
  weeklyRepayment?: number;
  id?: string;
  loanId?: string;
  applicationId?: string;
  withdrawAmount?: number;
  advanceRate?: number;
  totalRepayment?: number;
  firstRepayment?: Date;
}

/**
 * An interface representing LineOfCreditRepaymentCalculation.
 */
export interface LineOfCreditRepaymentCalculation {
  percentageOfIncome?: number;
  weeklyRepayment?: number;
  weeks?: number;
  weeklyForecastIncome?: number;
  fixed?: boolean;
  isFirstAdavnce?: boolean;
  id?: string;
  loanId?: string;
  applicationId?: string;
  withdrawAmount?: number;
  advanceRate?: number;
  totalRepayment?: number;
  firstRepayment?: Date;
}

/**
 * An interface representing PercentOfIncomeCalculation.
 */
export interface PercentOfIncomeCalculation {
  rate?: number;
  weeks?: number;
  id?: string;
  loanId?: string;
  applicationId?: string;
  withdrawAmount?: number;
  advanceRate?: number;
  totalRepayment?: number;
  firstRepayment?: Date;
}

/**
 * An interface representing InvoicePaymentCalculation.
 */
export interface InvoicePaymentCalculation {
  id?: string;
  loanId?: string;
  applicationId?: string;
  withdrawAmount?: number;
  advanceRate?: number;
  totalRepayment?: number;
  firstRepayment?: Date;
}

/**
 * An interface representing IncomeSummary.
 */
export interface IncomeSummary {
  income?: number;
  repayment?: number;
}

/**
 * An interface representing IncomeDetails.
 */
export interface IncomeDetails {
  currentMonthIncomeChangePercent?: number;
  currentMonthIncomeChangeUp?: boolean;
  currentWeek?: IncomeSummary;
  estimatedWeek?: IncomeSummary;
  historicalWeeks?: { [propertyName: string]: IncomeSummary };
  nextRepaymentDate?: Date;
}

/**
 * An interface representing OfferDetails.
 */
export interface OfferDetails {
  offerId?: string;
  facilityLimit?: number;
  creditLimit?: number;
  commission?: Commission;
  secured?: boolean;
  decision?: number;
  loanType?: number;
  cashFlowLimit?: number;
  receivableLimit?: number;
}

/**
 * An interface representing AuditDetails.
 */
export interface AuditDetails {
  type?: string;
  nextDate?: Date;
  low?: number;
  high?: number;
  loanId?: string;
  organisationId?: string;
  onOffAudit?: boolean;
}

/**
 * An interface representing PlexeAccountingProxyModelsLineItem.
 */
export interface PlexeAccountingProxyModelsLineItem {
  id?: string;
  description?: string;
  discountRate?: number;
  itemCode?: string;
  lineAmount?: number;
  quantity?: number;
  taxAmount?: number;
  unitAmount?: number;
}

/**
 * An interface representing PlexeAccountingProxyModelsPaymentInvoice.
 */
export interface PlexeAccountingProxyModelsPaymentInvoice {
  amountApplied?: number;
  id?: string;
  number?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsPayment.
 */
export interface PlexeAccountingProxyModelsPayment {
  type?: number;
  status?: number;
  currencyRate?: number;
  bankAmount?: number;
  amount?: number;
  reference?: string;
  isReconciled?: boolean;
  date?: Date;
  account?: string;
  updatedDate?: Date;
  retrievedDate?: Date;
  invoices?: PlexeAccountingProxyModelsPaymentInvoice[];
  debtor?: string;
  id?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsBalance.
 */
export interface PlexeAccountingProxyModelsBalance {
  outstanding?: number;
  overdue?: number;
}

/**
 * An interface representing PlexeAccountingProxyModelsBalances.
 */
export interface PlexeAccountingProxyModelsBalances {
  accountsReceivable?: PlexeAccountingProxyModelsBalance;
  accountsPayable?: PlexeAccountingProxyModelsBalance;
}

/**
 * An interface representing PlexeAccountingProxyModelsContact.
 */
export interface PlexeAccountingProxyModelsContact {
  id?: string;
  firstName?: string;
  lastName?: string;
  abn?: string;
  acn?: string;
  companyName?: string;
  notes?: string;
  balance?: PlexeAccountingProxyModelsBalances;
  addresses?: PlexeAccountingProxyModelsAddress[];
  phones?: PlexeAccountingProxyModelsPhone[];
  fullName?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsCreditNote.
 */
export interface PlexeAccountingProxyModelsCreditNote {
  id?: string;
  date?: Date;
  dueDate?: Date;
  status?: number;
  total?: number;
  creditNoteNumber?: string;
  lineItems?: PlexeAccountingProxyModelsLineItem[];
  payments?: PlexeAccountingProxyModelsPayment[];
  contact?: PlexeAccountingProxyModelsContact;
}

/**
 * An interface representing PlexeAccountingProxyModelsInvoice.
 */
export interface PlexeAccountingProxyModelsInvoice {
  id?: string;
  date?: Date;
  dueDate?: Date;
  status?: number;
  total?: number;
  amountDue?: number;
  amountPaid?: number;
  paymentDate?: Date;
  invoiceNumber?: string;
  lineItems?: PlexeAccountingProxyModelsLineItem[];
  payments?: PlexeAccountingProxyModelsPayment[];
  contact?: PlexeAccountingProxyModelsContact;
  currencyRate?: number;
  currencyCode?: string;
  creditNotes?: PlexeAccountingProxyModelsCreditNote[];
  updatedDateUtc?: Date;
}

/**
 * An interface representing AuditDocument.
 */
export interface AuditDocument {
  dateTime?: Date;
  name?: string;
  location?: string;
}

/**
 * An interface representing AuditInvoiceDetails.
 */
export interface AuditInvoiceDetails {
  status?: number;
  loanId?: string;
  remainingDays?: number;
  invoice?: PlexeAccountingProxyModelsInvoice;
  auditType?: string;
  notes?: string;
  documents?: AuditDocument[];
  completedDate?: Date;
}

/**
 * An interface representing PagedListAuditDocument.
 */
export interface PagedListAuditDocument {
  data?: AuditDocument[];
  totalCount?: number;
}

/**
 * An interface representing AuditSettings.
 */
export interface AuditSettings {
  high?: number;
  low?: number;
  nextDate?: Date;
  auditTypes?: string[];
}

/**
 * An interface representing CardConfiguration.
 */
export interface CardConfiguration {
  limit?: number;
}

/**
 * An interface representing CardHolderDetails.
 */
export interface CardHolderDetails {
  id?: string;
  loanId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dateOfBirth?: Date;
  phoneNumber?: string;
  income?: number;
  isActive?: boolean;
  cardName?: string;
  expiryDate?: Date;
  cardType?: string;
  creditLimit?: number;
  limit?: number;
  suspended?: boolean;
}

/**
 * An interface representing PagedListCardHolderDetails.
 */
export interface PagedListCardHolderDetails {
  data?: CardHolderDetails[];
  totalCount?: number;
}

/**
 * An interface representing CardTransaction.
 */
export interface CardTransaction {
  id?: string;
  loanId?: string;
  details?: string;
  amount?: number;
  transactionType?: string;
  cardHolderId?: string;
  dateUTC?: Date;
}

/**
 * An interface representing PagedListCardTransaction.
 */
export interface PagedListCardTransaction {
  data?: CardTransaction[];
  totalCount?: number;
}

/**
 * An interface representing HydrogenAppTokenResponse.
 */
export interface HydrogenAppTokenResponse {
  id?: string;
  tenantName?: string;
  username?: any;
  isValid?: boolean;
  createDate?: Date;
  updateDate?: Date;
  appName?: string;
  appToken?: string;
  publicKey?: string;
}

/**
 * An interface representing CustomerProjection.
 */
export interface CustomerProjection {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  mobileNumber?: string;
  businessName?: string;
  tradingName?: string;
  type?: string;
  parentId?: string;
  dateOfBirth?: Date;
  hasLoggedIn?: boolean;
  role?: string;
}

/**
 * An interface representing PagedListCustomerProjection.
 */
export interface PagedListCustomerProjection {
  data?: CustomerProjection[];
  totalCount?: number;
}

/**
 * An interface representing PagedListTransactionDataFile.
 */
export interface PagedListTransactionDataFile {
  data?: TransactionDataFile[];
  totalCount?: number;
}

/**
 * An interface representing ProviderDetails.
 */
export interface ProviderDetails {
  connected?: boolean;
  loanId?: string;
  type?: number;
  details?: { [propertyName: string]: string };
}

/**
 * An interface representing PaySlip.
 */
export interface PaySlip {
  start?: Date;
  end?: Date;
  amount?: number;
}

/**
 * An interface representing EmployeeDetails.
 */
export interface EmployeeDetails {
  provider?: string;
  employmentType?: string;
  employmentStartDate?: Date;
  annual?: number;
  yearly?: number;
  employeeId?: string;
  status?: boolean;
  paySlips?: PaySlip[];
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  isActive?: boolean;
  isEnable?: boolean;
  dateOfBirth?: Date;
}

/**
 * An interface representing PagedListEmployeeDetails.
 */
export interface PagedListEmployeeDetails {
  data?: EmployeeDetails[];
  totalCount?: number;
}

/**
 * An interface representing WithdrawRequest.
 */
export interface WithdrawRequest {
  dateTime?: Date;
  requestId?: string;
  loanId?: string;
  employeeId?: string;
  requestAmount?: number;
  responseAmount?: number;
  status?: string;
  reclaimDate?: Date;
}

/**
 * An interface representing ContractDocument.
 */
export interface ContractDocument {
  displayName?: string;
  createdDate?: Date;
  documentId?: string;
  data?: Uint8Array;
  status?: number;
}

/**
 * An interface representing PagedListContractDocument.
 */
export interface PagedListContractDocument {
  data?: ContractDocument[];
  totalCount?: number;
}

/**
 * An interface representing CustomerLookupData.
 */
export interface CustomerLookupData {
  fullName?: string;
  type?: number;
  abn?: string;
  status?: string;
  applicationId?: string;
  loanId?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly display?: string;
}

/**
 * An interface representing CustomerLoanProjection.
 */
export interface CustomerLoanProjection {
  customerId?: string;
  loanId?: string;
  applicationId?: string;
  partnerId?: string;
  customerFullName?: string;
  businessName?: string;
  health?: string;
  shortCode?: string;
  totalAmount?: Amount;
  availableCredit?: Amount;
  securedCommission?: Commission;
  lineOfCreditCommission?: Commission;
  commission?: Commission;
  salesPerson?: string;
  lastUpdated?: Date;
  organisationId?: string;
  offerId?: string;
  closed?: boolean;
  archived?: boolean;
  enabledDebtors?: boolean;
  showAllDebtors?: boolean;
  bankId?: string;
  funder?: string;
  enabled?: string;
  tradingName?: string;
  fullBusinessName?: string;
  revolving?: boolean;
  secured?: boolean;
  createdDate?: Date;
  nextAuditDate?: Date;
  auditLowRange?: number;
  auditHighRange?: number;
  auditType?: string;
  loanType?: string;
  closedDate?: Date;
  onOffAudit?: boolean;
  auditId?: string;
  balanceOverride?: boolean;
  defaulted?: boolean;
  defaultStatus?: string;
  associatedCustomerIdStr?: string;
}

/**
 * An interface representing PagedListCustomerLoanProjection.
 */
export interface PagedListCustomerLoanProjection {
  data?: CustomerLoanProjection[];
  totalCount?: number;
}

/**
 * An interface representing PaymentQueryNote.
 */
export interface PaymentQueryNote {
  date?: Date;
  note?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly output?: string;
}

/**
 * An interface representing PaymentQuery.
 */
export interface PaymentQuery {
  status?: string;
  paymentQueryType?: string;
  reconciled?: boolean;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly time?: number;
  notes?: PaymentQueryNote[];
  paymentQueryId?: string;
  loanId?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly output?: string;
  amount?: number;
  transactionId?: string;
  date?: Date;
  debtor?: string;
  details?: string;
  account?: string;
  type?: string;
}

/**
 * An interface representing LoanReadyApplication.
 */
export interface LoanReadyApplication {
  businessName?: string;
  availableCredit?: Amount;
  shortCode?: string;
  totalAmount?: Amount;
  id?: string;
  status?: string;
}

/**
 * An interface representing PagedListLoanReadyApplication.
 */
export interface PagedListLoanReadyApplication {
  data?: LoanReadyApplication[];
  totalCount?: number;
}

/**
 * An interface representing PaymentArrangement.
 */
export interface PaymentArrangement {
  paymentArrangementId?: string;
  amount?: number;
  advanceId?: string;
  startDate?: Date;
  endDate?: Date;
  createdDate?: Date;
  advanceRate?: number;
}

/**
 * An interface representing LoanNotificationProjection.
 */
export interface LoanNotificationProjection {
  notificationId?: string;
  loanId?: string;
  createdOnUtc?: Date;
  message?: string;
  read?: boolean;
  readOnUtc?: string;
}

/**
 * An interface representing PagedListLoanNotificationProjection.
 */
export interface PagedListLoanNotificationProjection {
  data?: LoanNotificationProjection[];
  totalCount?: number;
}

/**
 * An interface representing LoanData.
 */
export interface LoanData {
  loanId?: string;
  applicationId?: string;
  organisationId?: string;
  customerId?: string;
  partnerId?: string;
  offerId?: string;
  businessName?: string;
  tradingName?: string;
  fullBusinessName?: string;
  closed?: boolean;
  revolving?: boolean;
  createdDate?: Date;
  archived?: boolean;
  loanType?: number;
  enabledDebtors?: boolean;
  bankId?: string;
  funder?: string;
  showAllDebtors?: boolean;
  health?: string;
  enabled?: boolean;
  customerFullName?: string;
  securedCommission?: Commission;
  commission?: Commission;
  locCommission?: Commission;
  salesPerson?: string;
  lastUpdated?: Date;
  secured?: boolean;
  nextAuditDate?: Date;
  auditHighRange?: number;
  auditLowRange?: number;
  auditType?: string;
  auditTypeList?: string[];
  loanTypeName?: string;
  defaulted?: boolean;
  defaultStatus?: string;
}

/**
 * An interface representing LoanBalances.
 */
export interface LoanBalances {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly availableFunds?: number;
  systemPricing?: number;
  balance?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalLimit?: number;
  limits?: { [propertyName: string]: number };
  rawTotal?: number;
  adjustment?: number;
  totalDebtors?: number;
  totalFundingRate?: number;
}

/**
 * An interface representing AllLoanBalances.
 */
export interface AllLoanBalances {
  all?: LoanBalances;
  fixed?: LoanBalances;
  invoice?: LoanBalances;
  percentage?: LoanBalances;
  lineOfCredit?: LoanBalances;
  creditCard?: LoanBalances;
}

/**
 * An interface representing LoanSettings.
 */
export interface LoanSettings {
  fixedRepayment?: boolean;
  percentOfIncome?: boolean;
  invoicePayment?: boolean;
  lineOfCreditPayment?: boolean;
  creditCardPayment?: boolean;
  revolving?: boolean;
  enabledDebtors?: boolean;
  showAllDebtors?: boolean;
}

/**
 * An interface representing PaidCommission.
 */
export interface PaidCommission {
  createdOnUtc?: Date;
  paid?: boolean;
  partnerId?: string;
  advanceId?: string;
  amount?: number;
  paidOn?: Date;
  id: string;
}

/**
 * An interface representing PagedListPaidCommission.
 */
export interface PagedListPaidCommission {
  data?: PaidCommission[];
  totalCount?: number;
}

/**
 * An interface representing TransactionProjection.
 */
export interface TransactionProjection {
  id?: string;
  overrideId?: string;
  loanId?: string;
  advanceId?: string;
  advanceName?: string;
  dateUTC?: Date;
  type?: string;
  details?: string;
  amount?: Amount;
  balance?: Amount;
  reconciled?: boolean;
  debtor?: string;
  isCredit?: boolean;
  bankReceipt?: string;
  force?: boolean;
}

/**
 * An interface representing PagedListTransactionProjection.
 */
export interface PagedListTransactionProjection {
  data?: TransactionProjection[];
  totalCount?: number;
}

/**
 * An interface representing AdvanceProjection.
 */
export interface AdvanceProjection {
  id?: string;
  loanId?: string;
  dateUTC?: Date;
  type?: string;
  details?: string;
  amount?: Amount;
  remaining?: Amount;
  nextRepaymentValue?: Amount;
  nextRepaymentDateUTC?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly actualNextRepaymentDateUTC?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly actualNextRepaymentDay?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly nextRepaymentDay?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRepaymentDay?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRepaymentDateUTC?: Date;
  totalRepaymentAmount?: number;
  weeklyPayment?: number;
  advanceRate?: number;
  terms?: number;
  priority?: boolean;
  percentage?: number;
  weeks?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly name?: string;
  earlyRepaid?: boolean;
  earlyRepaidDate?: Date;
  overrideLastRepaymentDateUTC?: Date;
  overrideLastRepayment?: boolean;
  legacy?: boolean;
  monthlyFee?: boolean;
  commission?: Commission;
  fixed?: boolean;
  fixedRepayment?: number;
  fixedRepaymentFee?: number;
}

/**
 * An interface representing PagedListAdvanceProjection.
 */
export interface PagedListAdvanceProjection {
  data?: AdvanceProjection[];
  totalCount?: number;
}

/**
 * An interface representing CashflowDetails.
 */
export interface CashflowDetails {
  lastQuarterProfits?: number;
  agedPayables?: number;
  invoices?: number;
  bills?: number;
  cashFlow?: { [propertyName: string]: number };
}

/**
 * An interface representing EarlyRepaymentCalculation.
 */
export interface EarlyRepaymentCalculation {
  saving?: number;
  repayment?: number;
  scheduleTransaction?: Date;
  weeklyRepayment?: number;
}

/**
 * An interface representing SkipSchedule.
 */
export interface SkipSchedule {
  repaymentDate?: Date;
  nextRepaymentDate?: Date;
  repaymentAmount?: number;
  nextRepaymentAmount?: number;
  lastDate?: Date;
  skipFee?: number;
}

/**
 * An interface representing SkipRepaymentCalculation.
 */
export interface SkipRepaymentCalculation {
  current?: SkipSchedule;
  next?: SkipSchedule;
}

/**
 * An interface representing BankDetails.
 */
export interface BankDetails {
  name?: string;
  bsb?: string;
  number?: string;
}

/**
 * An interface representing TransactionFile.
 */
export interface TransactionFile {
  data?: string;
  fileName?: string;
  fileType?: string;
}

/**
 * An interface representing PlexeOrganisationServiceAccountingModelsProcessedPayment.
 */
export interface PlexeOrganisationServiceAccountingModelsProcessedPayment {
  manualExclude?: boolean;
  paymentKey?: string;
  type?: number;
  status?: number;
  currencyRate?: number;
  bankAmount?: number;
  amount?: number;
  reference?: string;
  isReconciled?: boolean;
  date?: Date;
  account?: string;
  updatedDate?: Date;
  retrievedDate?: Date;
  invoices?: PlexeAccountingProxyModelsPaymentInvoice[];
  debtor?: string;
  id?: string;
}

/**
 * An interface representing SyntheticPayment.
 */
export interface SyntheticPayment {
  amount?: number;
  date?: Date;
  debtor?: string;
  details?: string;
  transactionId?: string;
}

/**
 * An interface representing ReconciledPayment.
 */
export interface ReconciledPayment {
  id?: string;
  amount?: number;
  details?: string;
  debtor?: string;
  paymentQueryId?: string;
  type?: string;
  transactionId?: string;
  date?: Date;
  payment?: PlexeOrganisationServiceAccountingModelsProcessedPayment;
  syntheticPayment?: SyntheticPayment;
  status?: string;
  autoMatch?: boolean;
}

/**
 * An interface representing PagedListReconciledPayment.
 */
export interface PagedListReconciledPayment {
  data?: ReconciledPayment[];
  totalCount?: number;
}

/**
 * An interface representing ReconciliationTransaction.
 */
export interface ReconciliationTransaction {
  source?: string;
  reconciled?: boolean;
  request?: boolean;
  requestList?: { [propertyName: string]: string };
  misdirectionList?: { [propertyName: string]: string };
  enabledDebtor?: boolean;
  systemReconciled?: boolean;
  updatedDate?: Date;
  retrievedDate?: Date;
  potentialMisdirection?: boolean;
  potentialMisdirectionDetails?: PaymentQuery;
  misdirect?: boolean;
  id?: string;
  date?: Date;
  details?: string;
  metaDescription?: string;
  amount?: number;
  account?: string;
  type?: string;
  debtor?: string;
}

/**
 * An interface representing PaymentAndTransactions.
 */
export interface PaymentAndTransactions {
  date?: Date;
  list?: ReconciliationTransaction[];
}

/**
 * An interface representing PlexeBankStatementsProxyModelsCredential.
 */
export interface PlexeBankStatementsProxyModelsCredential {
  name?: string;
  fieldID?: string;
  type?: string;
  description?: string;
  values?: string;
  keyboardType?: string;
}

/**
 * An interface representing PlexeBankStatementsProxyModelsInstitution.
 */
export interface PlexeBankStatementsProxyModelsInstitution {
  slug?: string;
  name?: string;
  credentials?: PlexeBankStatementsProxyModelsCredential[];
  status?: string;
  searchable?: string;
  display?: string;
  searchVal?: string;
  region?: string;
  exportWithPassword?: number;
  estatementsSupported?: number;
  transactionListingsSupported?: string;
  requiresPreload?: string;
  requiresMfa?: string;
  updatedAt?: string;
  maxDays?: string;
  continues?: string;
}

/**
 * An interface representing OrganisationDetails.
 */
export interface OrganisationDetails {
  entityType?: string;
  entityTypeCode?: string;
  fromDate?: Date;
  toDate?: Date;
  postcode?: string;
  state?: string;
  familyName?: string;
  firstName?: string;
  otherName?: string;
  mainName?: string;
  status?: string;
  name?: string;
}

/**
 * An interface representing RequestDetail.
 */
export interface RequestDetail {
  type?: string;
  name?: string;
  id?: string;
}

/**
 * An interface representing LookupResult.
 */
export interface LookupResult {
  search?: string;
  elapsed?: string;
  list?: RequestDetail[];
}

/**
 * An interface representing PartnerSendLinkRequest.
 */
export interface PartnerSendLinkRequest {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  ein?: string;
  mobile?: string;
  createdDate?: Date;
}

/**
 * An interface representing PartnerProjection.
 */
export interface PartnerProjection {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  mobilePhoneNumber?: string;
  unsecuredTrail?: number;
  securedTrail?: number;
  locTrail?: number;
  upFrontFee?: number;
  role?: string;
  urlReferral?: string;
}

/**
 * An interface representing PagedListPartnerProjection.
 */
export interface PagedListPartnerProjection {
  data?: PartnerProjection[];
  totalCount?: number;
}

/**
 * An interface representing PartnerOwnerDetails.
 */
export interface PartnerOwnerDetails {
  title?: string;
  firstName?: string;
  preferredName?: string;
  lastname?: string;
  cellNumber?: string;
  officeNumber?: string;
  email?: string;
  driversLicenceNumber?: string;
  stateIssued?: string;
  ssn?: string;
  dob?: Date;
}

/**
 * An interface representing PartnerBusinessDetails.
 */
export interface PartnerBusinessDetails {
  companyName?: string;
  companyAddress?: string;
  officeNumber?: string;
  webAddress?: string;
  accountPayableEmail?: string;
  optionFirst?: boolean;
  optionSecond?: boolean;
  optionThird?: boolean;
  optionFourth?: boolean;
}

/**
 * An interface representing PartnerAchDetail.
 */
export interface PartnerAchDetail {
  bankName?: string;
  accountType?: string;
  routingNumber?: string;
  accountNumber?: string;
}

/**
 * An interface representing PartnerCompanyDetail.
 */
export interface PartnerCompanyDetail {
  companyName?: string;
  name?: string;
  title?: string;
  date?: string;
  signature?: string;
}

/**
 * An interface representing PartnerAccreditationRequest.
 */
export interface PartnerAccreditationRequest {
  ownerDetails?: PartnerOwnerDetails;
  businessDetails?: PartnerBusinessDetails;
  achDetail?: PartnerAchDetail;
  companyDetail?: PartnerCompanyDetail;
}

/**
 * An interface representing FileDetails.
 */
export interface FileDetails {
  location?: string;
  fileType?: string;
  fileName?: string;
  internalLocation?: string;
}

/**
 * An interface representing SupportDocumentDetails.
 */
export interface SupportDocumentDetails {
  label?: string;
  details?: string;
  submittedDate?: Date;
  submitted?: boolean;
  locations?: FileDetails[];
  id?: string;
}

/**
 * An interface representing PagedListSupportDocumentDetails.
 */
export interface PagedListSupportDocumentDetails {
  data?: SupportDocumentDetails[];
  totalCount?: number;
}

/**
 * An interface representing SiteDetails.
 */
export interface SiteDetails {
  items?: { [propertyName: string]: string };
  name?: string;
  urls?: string[];
  partnerId?: string;
}

/**
 * An interface representing OriginationReadApiOptions.
 */
export interface OriginationReadApiOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetOrganisationExtraDetailsOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiOverviewOptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDownloadContractFileOptionalParams extends msRest.RequestOptionsBase {
  fileType?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetCommissionDetailsOptionalParams extends msRest.RequestOptionsBase {
  secured?: number;
  establishmentFeeRate?: number;
  commissionRate?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiApplicationListOptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
  orderBy?: string;
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  status?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetBankfeedAccountsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  excludeCreditCards?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetBankfeedMFADetailsOptionalParams extends msRest.RequestOptionsBase {
  yodlee?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCommissionMethodOptionalParams extends msRest.RequestOptionsBase {
  loanType?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDebtorSummaryOnApplicationIdOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  /**
   * Default value: ''.
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateIndicativeFixedRepaymentOptionalParams extends msRest.RequestOptionsBase {
  amount?: number;
  terms?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateIndicativePercentOfIncomeOptionalParams extends msRest.RequestOptionsBase {
  amount?: number;
  percent?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateIndicativeInvoicePaymentOptionalParams extends msRest.RequestOptionsBase {
  amount?: number;
  /**
   * Default value: false.
   */
  overrideCalculation?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetOfferDetailsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  force?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiUpdateApplicationCacheOptionalParams extends msRest.RequestOptionsBase {
  shortCode?: string;
  customerId?: string;
  organisationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiIsApprovedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiIsApplicantApprovedOptionalParams extends msRest.RequestOptionsBase {
  applicantId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiIsApplicantEvaluatedOptionalParams extends msRest.RequestOptionsBase {
  applicantId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetByPartnerOptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetCurrentAuditReportOptionalParams extends msRest.RequestOptionsBase {
  status?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetCurrentAuditDocumentsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetRecentAuditDocumentsOptionalParams extends msRest.RequestOptionsBase {
  invoiceId?: string;
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiIsCorprateOptionalParams extends msRest.RequestOptionsBase {
  username?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiHasCorprateLoggedInOptionalParams extends msRest.RequestOptionsBase {
  username?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAllCardHolderCardsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetCardOptionalParams extends msRest.RequestOptionsBase {
  providerType?: number;
  cardId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetTransactionsPerCardOptionalParams extends msRest.RequestOptionsBase {
  cardId?: string;
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetTransactionsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetCorpratesOptionalParams extends msRest.RequestOptionsBase {
  orderBy?: string;
  skip?: number;
  take?: number;
  /**
   * Default value: false.
   */
  includeDisabled?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetCustomersOptionalParams extends msRest.RequestOptionsBase {
  searchText?: string;
  skip?: number;
  take?: number;
  /**
   * Default value: false.
   */
  includeDisabled?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiSearchCustomersOptionalParams extends msRest.RequestOptionsBase {
  charList?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAuthorizationTokenOptionalParams extends msRest.RequestOptionsBase {
  customerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetDocumentsPagedOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDownloadDocumentOptionalParams extends msRest.RequestOptionsBase {
  fileName?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiIsEmployeeOptionalParams extends msRest.RequestOptionsBase {
  username?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiHasLoggedIn1OptionalParams extends msRest.RequestOptionsBase {
  username?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiIsPayrollSystemConnectedOptionalParams extends msRest.RequestOptionsBase {
  providerType?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetEmployeePageDetailsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetEmployeeDetailOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetEmployeeWithdrawDetailsOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateWithdrawRequestOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
  amount?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAllWithdrawRequestsOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetWithdrawRequestOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
  requestId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetEmployeesOptionalParams extends msRest.RequestOptionsBase {
  orderBy?: string;
  skip?: number;
  take?: number;
  /**
   * Default value: false.
   */
  includeDisabled?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetFilterEmployeesOptionalParams extends msRest.RequestOptionsBase {
  search?: string;
  skip?: number;
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAllContractDocumentsPagedOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDeletePastContractOptionalParams extends msRest.RequestOptionsBase {
  documentId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDownloadContractFileOnDocumentIdOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCustomerLookupOptionalParams extends msRest.RequestOptionsBase {
  search?: string;
  /**
   * Default value: false.
   */
  includeCancelled?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetLoansOnCustomerIdOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  /**
   * Default value: 'BusinessName'.
   */
  orderBy?: string;
  /**
   * Default value: false.
   */
  includedClosed?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetLoanReadyApplicationsOnCustomerIdOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  /**
   * Default value: 'BusinessName'.
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetCommissionOnLoanOptionalParams extends msRest.RequestOptionsBase {
  secured?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetPaymentArrangementsOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetLoanNotificationsOptionalParams extends msRest.RequestOptionsBase {
  onlyUnread?: boolean;
  skip?: number;
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetLoanBalancesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  force?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAllLoanBalancesOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  force?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetLoanSettingsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  force?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetLoansOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  /**
   * Default value: 'BusinessName'.
   */
  orderBy?: string;
  /**
   * Default value: false.
   */
  includedClosed?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetPaidCommissionsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  /**
   * Default value: ''.
   */
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDebtorSummaryOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiTransactionSummaryOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  orderBy?: string;
  /**
   * Default value: true.
   */
  orderbyDescending?: boolean;
  filterColumn?: string;
  filterValue?: string;
  /**
   * Default value: 'All'.
   */
  viewType?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAllTransactionsOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
  types?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiTransactionSummaryOnIdsOptionalParams extends msRest.RequestOptionsBase {
  transactionids?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiAdvanceSummaryOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateFixedRepaymentOptionalParams extends msRest.RequestOptionsBase {
  amount?: number;
  terms?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculatePercentOfIncomeOptionalParams extends msRest.RequestOptionsBase {
  amount?: number;
  percent?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateInvoicePaymentOptionalParams extends msRest.RequestOptionsBase {
  amount?: number;
  /**
   * Default value: false.
   */
  overrideCalculation?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateEarlyRepaymentOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiCalculateSkipRepaymentOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDownloadTransactionFileOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
  fileType?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiDownloadTransactionStatementOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiLookupDebtorsOptionalParams extends msRest.RequestOptionsBase {
  debtor?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiUpdateLoanCacheOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiUpdateLoanBalanceCacheOptionalParams extends msRest.RequestOptionsBase {
  offerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiUpdateLoanSettingCacheOptionalParams extends msRest.RequestOptionsBase {
  offerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetReconciledPaymentsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetPaymentReconciliationTransactionOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
  /**
   * Default value: true.
   */
  showAll?: boolean;
  /**
   * Default value: true.
   */
  onlyKnownDebtors?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetSyntheticTransactionsOptionalParams extends msRest.RequestOptionsBase {
  debtor?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetSyntheticTransactionsTotalOptionalParams extends msRest.RequestOptionsBase {
  debtor?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetBankListOptionalParams extends msRest.RequestOptionsBase {
  reset?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetBankListDetailsOptionalParams extends msRest.RequestOptionsBase {
  reset?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetBusinessNameOnAcnOptionalParams extends msRest.RequestOptionsBase {
  acn?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetBusinessNameDetailsOnAcnOptionalParams extends msRest.RequestOptionsBase {
  acn?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetDetailsOptionalParams extends msRest.RequestOptionsBase {
  searchString?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAccountingAuthorizeUrlOptionalParams extends msRest.RequestOptionsBase {
  host?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetReconnectAccountingAuthorizeUrlOptionalParams extends msRest.RequestOptionsBase {
  host?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetPartnerSentLinkRequestOptionalParams extends msRest.RequestOptionsBase {
  requestId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetPartnersOptionalParams extends msRest.RequestOptionsBase {
  searchText?: string;
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  /**
   * Default value: false.
   */
  includeDisabled?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiSearchPartnersOptionalParams extends msRest.RequestOptionsBase {
  charList?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAuthorizationToken1OptionalParams extends msRest.RequestOptionsBase {
  userName?: string;
  password?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetAccreditationOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetPartnerDailyReportsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetPartnerIdByUrlReferralOptionalParams extends msRest.RequestOptionsBase {
  urlReferral?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationReadApiGetSupportingDocumentsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
}

/**
 * Contains response data for the getApplicantNames operation.
 */
export type GetApplicantNamesResponse = Array<Name> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Name[];
    };
};

/**
 * Contains response data for the getApplicants operation.
 */
export type GetApplicantsResponse = Array<Applicant> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Applicant[];
    };
};

/**
 * Contains response data for the getPrimaryApplicant operation.
 */
export type GetPrimaryApplicantResponse = PrimaryApplicant & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PrimaryApplicant;
    };
};

/**
 * Contains response data for the getApplicantDriverLicenceUrl operation.
 */
export type GetApplicantDriverLicenceUrlResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getApplicant operation.
 */
export type GetApplicantResponse = Applicant & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Applicant;
    };
};

/**
 * Contains response data for the getApplicantDriverLicenceImage operation.
 */
export type GetApplicantDriverLicenceImageResponse = {
  /**
   * The parsed response body.
   */
  body: Uint8Array;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Uint8Array;
    };
};

/**
 * Contains response data for the getSecondaryApplicant operation.
 */
export type GetSecondaryApplicantResponse = SecondaryApplicant & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SecondaryApplicant;
    };
};

/**
 * Contains response data for the getOrganisationExtraDetails operation.
 */
export type GetOrganisationExtraDetailsResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: string;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: string };
    };
};

/**
 * Contains response data for the overview operation.
 */
export type OverviewResponse = ApplicationsOverview & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApplicationsOverview;
    };
};

/**
 * Contains response data for the validateOtp operation.
 */
export type ValidateOtpResponse = BoolWrapper & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: BoolWrapper;
    };
};

/**
 * Contains response data for the applicationStatus operation.
 */
export type ApplicationStatusResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: boolean;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: boolean };
    };
};

/**
 * Contains response data for the applicationConnectionStatus operation.
 */
export type ApplicationConnectionStatusResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: boolean;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: boolean };
    };
};

/**
 * Contains response data for the hasOrganisationNameBeenFinalised operation.
 */
export type HasOrganisationNameBeenFinalisedResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the isOfferReady operation.
 */
export type IsOfferReadyResponse = BoolWrapper & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: BoolWrapper;
    };
};

/**
 * Contains response data for the hasLoggedIn operation.
 */
export type HasLoggedInResponse = BoolWrapper & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: BoolWrapper;
    };
};

/**
 * Contains response data for the downloadContractFile operation.
 */
export type DownloadContractFileResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the getData operation.
 */
export type GetDataResponse = Summary & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Summary;
    };
};

/**
 * Contains response data for the getCommissionDetails operation.
 */
export type GetCommissionDetailsResponse = CommissionDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CommissionDetails;
    };
};

/**
 * Contains response data for the getApplicationIdOnShortCode operation.
 */
export type GetApplicationIdOnShortCodeResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getToken operation.
 */
export type GetTokenResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getApplicationOnShortCode operation.
 */
export type GetApplicationOnShortCodeResponse = Application & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Application;
    };
};

/**
 * Contains response data for the getShortCodeOnApplicationId operation.
 */
export type GetShortCodeOnApplicationIdResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getQuickUrl operation.
 */
export type GetQuickUrlResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getSecondApplicantUrl operation.
 */
export type GetSecondApplicantUrlResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getBaseUrl operation.
 */
export type GetBaseUrlResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the applicationList operation.
 */
export type ApplicationListResponse = PagedListApplicationListProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListApplicationListProjection;
    };
};

/**
 * Contains response data for the getBusinessNameFromApplication operation.
 */
export type GetBusinessNameFromApplicationResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getBankAccounts operation.
 */
export type GetBankAccountsResponse = Array<PlexeOrganisationServiceModelsBankAccount> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PlexeOrganisationServiceModelsBankAccount[];
    };
};

/**
 * Contains response data for the getBankfeedAccounts operation.
 */
export type GetBankfeedAccountsResponse = Array<PlexeOrganisationServiceModelsBankAccount> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PlexeOrganisationServiceModelsBankAccount[];
    };
};

/**
 * Contains response data for the getBankfeedMFADetails operation.
 */
export type GetBankfeedMFADetailsResponse = PlexeBankStatementsProxyModelsMFADetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PlexeBankStatementsProxyModelsMFADetails;
    };
};

/**
 * Contains response data for the getBankfeedFaskLink operation.
 */
export type GetBankfeedFaskLinkResponse = PlexeBankStatementsProxyModelsFastLinkToken & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PlexeBankStatementsProxyModelsFastLinkToken;
    };
};

/**
 * Contains response data for the getBankfeedPlaidLink operation.
 */
export type GetBankfeedPlaidLinkResponse = PlexeBankStatementsProxyModelsFastLinkToken & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PlexeBankStatementsProxyModelsFastLinkToken;
    };
};

/**
 * Contains response data for the isProvisoApplication operation.
 */
export type IsProvisoApplicationResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the offer operation.
 */
export type OfferResponse = OfferAmount & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OfferAmount;
    };
};

/**
 * Contains response data for the getApplicationSettings operation.
 */
export type GetApplicationSettingsResponse = ApplicationSettings & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApplicationSettings;
    };
};

/**
 * Contains response data for the getCommunication operation.
 */
export type GetCommunicationResponse = Array<CommunicationProjection> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CommunicationProjection[];
    };
};

/**
 * Contains response data for the commissionMethod operation.
 */
export type CommissionMethodResponse = Commission & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Commission;
    };
};

/**
 * Contains response data for the getApplication operation.
 */
export type GetApplicationResponse = Application & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Application;
    };
};

/**
 * Contains response data for the debtorSummaryOnApplicationId operation.
 */
export type DebtorSummaryOnApplicationIdResponse = PagedListLoanDebtorProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListLoanDebtorProjection;
    };
};

/**
 * Contains response data for the calculateIndicativeFixedRepayment operation.
 */
export type CalculateIndicativeFixedRepaymentResponse = FixedRepaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FixedRepaymentCalculation;
    };
};

/**
 * Contains response data for the calculateIndicativeLineOfCreditSlider operation.
 */
export type CalculateIndicativeLineOfCreditSliderResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: number;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: number };
    };
};

/**
 * Contains response data for the calculateIndicativeLineOfCreditRepayment operation.
 */
export type CalculateIndicativeLineOfCreditRepaymentResponse = LineOfCreditRepaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LineOfCreditRepaymentCalculation;
    };
};

/**
 * Contains response data for the calculateIndicativePercentOfIncome operation.
 */
export type CalculateIndicativePercentOfIncomeResponse = PercentOfIncomeCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PercentOfIncomeCalculation;
    };
};

/**
 * Contains response data for the calculateIndicativeInvoicePayment operation.
 */
export type CalculateIndicativeInvoicePaymentResponse = InvoicePaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InvoicePaymentCalculation;
    };
};

/**
 * Contains response data for the incomeDetailsForApplication operation.
 */
export type IncomeDetailsForApplicationResponse = IncomeDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: IncomeDetails;
    };
};

/**
 * Contains response data for the isApplicationComplete operation.
 */
export type IsApplicationCompleteResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getOfferDetails operation.
 */
export type GetOfferDetailsResponse = OfferDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OfferDetails;
    };
};

/**
 * Contains response data for the getOrganisationId operation.
 */
export type GetOrganisationIdResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getOfferId operation.
 */
export type GetOfferIdResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getApplicationOnCustomerId operation.
 */
export type GetApplicationOnCustomerIdResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getApplicationDetailsOnCustomerId operation.
 */
export type GetApplicationDetailsOnCustomerIdResponse = Application & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Application;
    };
};

/**
 * Contains response data for the getApplicationOnOrganisationId operation.
 */
export type GetApplicationOnOrganisationIdResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getApplicationDetailOnOrganisationId operation.
 */
export type GetApplicationDetailOnOrganisationIdResponse = Application & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Application;
    };
};

/**
 * Contains response data for the getApplicationOnOrganisationName operation.
 */
export type GetApplicationOnOrganisationNameResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the isApproved operation.
 */
export type IsApprovedResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the isApplicantApproved operation.
 */
export type IsApplicantApprovedResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the isApplicantEvaluated operation.
 */
export type IsApplicantEvaluatedResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getSingleApplication operation.
 */
export type GetSingleApplicationResponse = ApplicationListProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApplicationListProjection;
    };
};

/**
 * Contains response data for the getByPartner operation.
 */
export type GetByPartnerResponse = Array<ApplicationListProjection> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApplicationListProjection[];
    };
};

/**
 * Contains response data for the isAuditRequired operation.
 */
export type IsAuditRequiredResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getAuditDetails operation.
 */
export type GetAuditDetailsResponse = AuditDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AuditDetails;
    };
};

/**
 * Contains response data for the getCurrentAuditReport operation.
 */
export type GetCurrentAuditReportResponse = AuditInvoiceDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AuditInvoiceDetails;
    };
};

/**
 * Contains response data for the getRecentAuditReports operation.
 */
export type GetRecentAuditReportsResponse = Array<AuditInvoiceDetails> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AuditInvoiceDetails[];
    };
};

/**
 * Contains response data for the getCurrentAuditDocuments operation.
 */
export type GetCurrentAuditDocumentsResponse = PagedListAuditDocument & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListAuditDocument;
    };
};

/**
 * Contains response data for the getRecentAuditDocuments operation.
 */
export type GetRecentAuditDocumentsResponse = PagedListAuditDocument & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListAuditDocument;
    };
};

/**
 * Contains response data for the getAuditSettings operation.
 */
export type GetAuditSettingsResponse = AuditSettings & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AuditSettings;
    };
};

/**
 * Contains response data for the isCorprate operation.
 */
export type IsCorprateResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the hasCorprateLoggedIn operation.
 */
export type HasCorprateLoggedInResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the isCardServiceEnabled operation.
 */
export type IsCardServiceEnabledResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getCardConfiguration operation.
 */
export type GetCardConfigurationResponse = CardConfiguration & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CardConfiguration;
    };
};

/**
 * Contains response data for the getAllCardHolderCards operation.
 */
export type GetAllCardHolderCardsResponse = PagedListCardHolderDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCardHolderDetails;
    };
};

/**
 * Contains response data for the getCard operation.
 */
export type GetCardResponse = CardHolderDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CardHolderDetails;
    };
};

/**
 * Contains response data for the getTransactionsPerCard operation.
 */
export type GetTransactionsPerCardResponse = PagedListCardTransaction & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCardTransaction;
    };
};

/**
 * Contains response data for the getTransactions operation.
 */
export type GetTransactionsResponse = PagedListCardTransaction & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCardTransaction;
    };
};

/**
 * Contains response data for the getWebComponentToken operation.
 */
export type GetWebComponentTokenResponse = HydrogenAppTokenResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: HydrogenAppTokenResponse;
    };
};

/**
 * Contains response data for the getCorprates operation.
 */
export type GetCorpratesResponse = PagedListCustomerProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerProjection;
    };
};

/**
 * Contains response data for the getCustomer operation.
 */
export type GetCustomerResponse = Customer & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Customer;
    };
};

/**
 * Contains response data for the getCustomerOnEmail operation.
 */
export type GetCustomerOnEmailResponse = Customer & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Customer;
    };
};

/**
 * Contains response data for the getCustomers operation.
 */
export type GetCustomersResponse = PagedListCustomerProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerProjection;
    };
};

/**
 * Contains response data for the searchCustomers operation.
 */
export type SearchCustomersResponse = Array<CustomerProjection> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CustomerProjection[];
    };
};

/**
 * Contains response data for the getAuthorizationToken operation.
 */
export type GetAuthorizationTokenResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getDocumentsPaged operation.
 */
export type GetDocumentsPagedResponse = PagedListTransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListTransactionDataFile;
    };
};

/**
 * Contains response data for the downloadDocument operation.
 */
export type DownloadDocumentResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the isEmployee operation.
 */
export type IsEmployeeResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the hasLoggedIn1 operation.
 */
export type HasLoggedIn1Response = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the isPayrollSystemConnected operation.
 */
export type IsPayrollSystemConnectedResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getPayrollDetails operation.
 */
export type GetPayrollDetailsResponse = ProviderDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ProviderDetails;
    };
};

/**
 * Contains response data for the getEmployeePageDetails operation.
 */
export type GetEmployeePageDetailsResponse = PagedListEmployeeDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListEmployeeDetails;
    };
};

/**
 * Contains response data for the getEmployeeDetail operation.
 */
export type GetEmployeeDetailResponse = EmployeeDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: EmployeeDetails;
    };
};

/**
 * Contains response data for the getEmployeeWithdrawDetails operation.
 */
export type GetEmployeeWithdrawDetailsResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: string;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: string };
    };
};

/**
 * Contains response data for the calculateWithdrawRequest operation.
 */
export type CalculateWithdrawRequestResponse = WithdrawRequest & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WithdrawRequest;
    };
};

/**
 * Contains response data for the getAllWithdrawRequests operation.
 */
export type GetAllWithdrawRequestsResponse = Array<WithdrawRequest> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WithdrawRequest[];
    };
};

/**
 * Contains response data for the getWithdrawRequest operation.
 */
export type GetWithdrawRequestResponse = WithdrawRequest & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: WithdrawRequest;
    };
};

/**
 * Contains response data for the getFinchRedirectUrl operation.
 */
export type GetFinchRedirectUrlResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getEmployees operation.
 */
export type GetEmployeesResponse = PagedListCustomerProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerProjection;
    };
};

/**
 * Contains response data for the getFilterEmployees operation.
 */
export type GetFilterEmployeesResponse = PagedListCustomerProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerProjection;
    };
};

/**
 * Contains response data for the getAllContractDocuments operation.
 */
export type GetAllContractDocumentsResponse = Array<ContractDocument> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ContractDocument[];
    };
};

/**
 * Contains response data for the getAllContractDocumentsPaged operation.
 */
export type GetAllContractDocumentsPagedResponse = PagedListContractDocument & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListContractDocument;
    };
};

/**
 * Contains response data for the deletePastContract operation.
 */
export type DeletePastContractResponse = PagedListContractDocument & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListContractDocument;
    };
};

/**
 * Contains response data for the getBaseSupportDocument operation.
 */
export type GetBaseSupportDocumentResponse = Array<ContractDocument> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ContractDocument[];
    };
};

/**
 * Contains response data for the downloadContractFileOnDocumentId operation.
 */
export type DownloadContractFileOnDocumentIdResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the getW9FormSampleDocument operation.
 */
export type GetW9FormSampleDocumentResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the customerLookupOffline operation.
 */
export type CustomerLookupOfflineResponse = Array<CustomerLookupData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CustomerLookupData[];
    };
};

/**
 * Contains response data for the customerLookup operation.
 */
export type CustomerLookupResponse = Array<CustomerLookupData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CustomerLookupData[];
    };
};

/**
 * Contains response data for the getLoansOnCustomerId operation.
 */
export type GetLoansOnCustomerIdResponse = PagedListCustomerLoanProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerLoanProjection;
    };
};

/**
 * Contains response data for the getPaymentQueries operation.
 */
export type GetPaymentQueriesResponse = Array<PaymentQuery> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PaymentQuery[];
    };
};

/**
 * Contains response data for the getLoanReadyApplicationsOnCustomerId operation.
 */
export type GetLoanReadyApplicationsOnCustomerIdResponse = PagedListLoanReadyApplication & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListLoanReadyApplication;
    };
};

/**
 * Contains response data for the getCommissionOnLoan operation.
 */
export type GetCommissionOnLoanResponse = Commission & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Commission;
    };
};

/**
 * Contains response data for the getPaymentArrangements operation.
 */
export type GetPaymentArrangementsResponse = Array<PaymentArrangement> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PaymentArrangement[];
    };
};

/**
 * Contains response data for the getLoanNotifications operation.
 */
export type GetLoanNotificationsResponse = PagedListLoanNotificationProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListLoanNotificationProjection;
    };
};

/**
 * Contains response data for the getLoanByOrganisationId operation.
 */
export type GetLoanByOrganisationIdResponse = Loan & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Loan;
    };
};

/**
 * Contains response data for the getLoan operation.
 */
export type GetLoanResponse = Loan & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Loan;
    };
};

/**
 * Contains response data for the getLoanDataByOrganistionId operation.
 */
export type GetLoanDataByOrganistionIdResponse = LoanData & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LoanData;
    };
};

/**
 * Contains response data for the getLoanDataByLoanId operation.
 */
export type GetLoanDataByLoanIdResponse = LoanData & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LoanData;
    };
};

/**
 * Contains response data for the getLoanBalances operation.
 */
export type GetLoanBalancesResponse = LoanBalances & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LoanBalances;
    };
};

/**
 * Contains response data for the getAllLoanBalances operation.
 */
export type GetAllLoanBalancesResponse = AllLoanBalances & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AllLoanBalances;
    };
};

/**
 * Contains response data for the getLoanSettings operation.
 */
export type GetLoanSettingsResponse = LoanSettings & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LoanSettings;
    };
};

/**
 * Contains response data for the getAllLoanData operation.
 */
export type GetAllLoanDataResponse = Array<LoanData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LoanData[];
    };
};

/**
 * Contains response data for the getLoans operation.
 */
export type GetLoansResponse = PagedListCustomerLoanProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerLoanProjection;
    };
};

/**
 * Contains response data for the getClosedLoans operation.
 */
export type GetClosedLoansResponse = PagedListCustomerLoanProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerLoanProjection;
    };
};

/**
 * Contains response data for the getLoansByType operation.
 */
export type GetLoansByTypeResponse = PagedListCustomerLoanProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerLoanProjection;
    };
};

/**
 * Contains response data for the getLoansByHealth operation.
 */
export type GetLoansByHealthResponse = PagedListCustomerLoanProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListCustomerLoanProjection;
    };
};

/**
 * Contains response data for the getPaidCommissions operation.
 */
export type GetPaidCommissionsResponse = PagedListPaidCommission & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListPaidCommission;
    };
};

/**
 * Contains response data for the debtorSummary operation.
 */
export type DebtorSummaryResponse = PagedListLoanDebtorProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListLoanDebtorProjection;
    };
};

/**
 * Contains response data for the transactionSummary operation.
 */
export type TransactionSummaryResponse = PagedListTransactionProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListTransactionProjection;
    };
};

/**
 * Contains response data for the getAllTransactions operation.
 */
export type GetAllTransactionsResponse = Array<TransactionProjection> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionProjection[];
    };
};

/**
 * Contains response data for the transactionSummaryOnIds operation.
 */
export type TransactionSummaryOnIdsResponse = Array<TransactionProjection> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionProjection[];
    };
};

/**
 * Contains response data for the advanceSummary operation.
 */
export type AdvanceSummaryResponse = PagedListAdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListAdvanceProjection;
    };
};

/**
 * Contains response data for the advanceHistoryDetails operation.
 */
export type AdvanceHistoryDetailsResponse = AdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: AdvanceProjection;
    };
};

/**
 * Contains response data for the cashflowDetailsMethod operation.
 */
export type CashflowDetailsMethodResponse = CashflowDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: CashflowDetails;
    };
};

/**
 * Contains response data for the incomeDetailsMethod operation.
 */
export type IncomeDetailsMethodResponse = IncomeDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: IncomeDetails;
    };
};

/**
 * Contains response data for the calculateFixedRepayment operation.
 */
export type CalculateFixedRepaymentResponse = FixedRepaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: FixedRepaymentCalculation;
    };
};

/**
 * Contains response data for the calculateLineOfCreditSlider operation.
 */
export type CalculateLineOfCreditSliderResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: number;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: number };
    };
};

/**
 * Contains response data for the calculateLineOfCreditRepayment operation.
 */
export type CalculateLineOfCreditRepaymentResponse = LineOfCreditRepaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LineOfCreditRepaymentCalculation;
    };
};

/**
 * Contains response data for the calculatePercentOfIncome operation.
 */
export type CalculatePercentOfIncomeResponse = PercentOfIncomeCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PercentOfIncomeCalculation;
    };
};

/**
 * Contains response data for the calculateInvoicePayment operation.
 */
export type CalculateInvoicePaymentResponse = InvoicePaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: InvoicePaymentCalculation;
    };
};

/**
 * Contains response data for the calculateEarlyRepayment operation.
 */
export type CalculateEarlyRepaymentResponse = EarlyRepaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: EarlyRepaymentCalculation;
    };
};

/**
 * Contains response data for the calculateSkipRepayment operation.
 */
export type CalculateSkipRepaymentResponse = SkipRepaymentCalculation & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SkipRepaymentCalculation;
    };
};

/**
 * Contains response data for the getBankDetails operation.
 */
export type GetBankDetailsResponse = BankDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: BankDetails;
    };
};

/**
 * Contains response data for the isBankSetup operation.
 */
export type IsBankSetupResponse = BoolWrapper & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: BoolWrapper;
    };
};

/**
 * Contains response data for the isChangeOfFacilityRequired operation.
 */
export type IsChangeOfFacilityRequiredResponse = BoolWrapper & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: BoolWrapper;
    };
};

/**
 * Contains response data for the getNewFacilityLimit operation.
 */
export type GetNewFacilityLimitResponse = {
  /**
   * The parsed response body.
   */
  body: number;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: number;
    };
};

/**
 * Contains response data for the getHighestFacilityLimit operation.
 */
export type GetHighestFacilityLimitResponse = {
  /**
   * The parsed response body.
   */
  body: number;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: number;
    };
};

/**
 * Contains response data for the getLoanApplication operation.
 */
export type GetLoanApplicationResponse = Application & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Application;
    };
};

/**
 * Contains response data for the isConnected operation.
 */
export type IsConnectedResponse = BoolWrapper & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: BoolWrapper;
    };
};

/**
 * Contains response data for the isAccountingSystemConnected operation.
 */
export type IsAccountingSystemConnectedResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the downloadTransactionFile operation.
 */
export type DownloadTransactionFileResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionFile;
    };
};

/**
 * Contains response data for the downloadTransactionStatement operation.
 */
export type DownloadTransactionStatementResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the lookupDebtors operation.
 */
export type LookupDebtorsResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the getPaymentQueries1 operation.
 */
export type GetPaymentQueries1Response = Array<PaymentQuery> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PaymentQuery[];
    };
};

/**
 * Contains response data for the getReconciledPayments operation.
 */
export type GetReconciledPaymentsResponse = PagedListReconciledPayment & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListReconciledPayment;
    };
};

/**
 * Contains response data for the getPaymentReconciliationTransaction operation.
 */
export type GetPaymentReconciliationTransactionResponse = Array<PaymentAndTransactions> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PaymentAndTransactions[];
    };
};

/**
 * Contains response data for the getSyntheticTransactions operation.
 */
export type GetSyntheticTransactionsResponse = Array<SyntheticPayment> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SyntheticPayment[];
    };
};

/**
 * Contains response data for the getSyntheticTransactionsTotal operation.
 */
export type GetSyntheticTransactionsTotalResponse = {
  /**
   * The parsed response body.
   */
  body: number;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: number;
    };
};

/**
 * Contains response data for the getBankList operation.
 */
export type GetBankListResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the getBankListDetails operation.
 */
export type GetBankListDetailsResponse = Array<PlexeBankStatementsProxyModelsInstitution> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PlexeBankStatementsProxyModelsInstitution[];
    };
};

/**
 * Contains response data for the getSupportedAccountingSoftware operation.
 */
export type GetSupportedAccountingSoftwareResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the getBusinessName operation.
 */
export type GetBusinessNameResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the isZipCodeValid operation.
 */
export type IsZipCodeValidResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getBusinessNameOnAcn operation.
 */
export type GetBusinessNameOnAcnResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getBusinessNameDetails operation.
 */
export type GetBusinessNameDetailsResponse = OrganisationDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrganisationDetails;
    };
};

/**
 * Contains response data for the getBusinessNameDetailsOnAcn operation.
 */
export type GetBusinessNameDetailsOnAcnResponse = OrganisationDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: OrganisationDetails;
    };
};

/**
 * Contains response data for the getDetails operation.
 */
export type GetDetailsResponse = LookupResult & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: LookupResult;
    };
};

/**
 * Contains response data for the getAccountingAuthorizeUrl operation.
 */
export type GetAccountingAuthorizeUrlResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getReconnectAccountingAuthorizeUrl operation.
 */
export type GetReconnectAccountingAuthorizeUrlResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getPartner operation.
 */
export type GetPartnerResponse = Partner & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Partner;
    };
};

/**
 * Contains response data for the getPartnerSentLinkRequest operation.
 */
export type GetPartnerSentLinkRequestResponse = PartnerSendLinkRequest & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PartnerSendLinkRequest;
    };
};

/**
 * Contains response data for the getPartnerOnEmail operation.
 */
export type GetPartnerOnEmailResponse = Partner & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: Partner;
    };
};

/**
 * Contains response data for the getPartners operation.
 */
export type GetPartnersResponse = PagedListPartnerProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListPartnerProjection;
    };
};

/**
 * Contains response data for the searchPartners operation.
 */
export type SearchPartnersResponse = Array<PartnerProjection> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PartnerProjection[];
    };
};

/**
 * Contains response data for the getAuthorizationToken1 operation.
 */
export type GetAuthorizationToken1Response = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getAccreditation operation.
 */
export type GetAccreditationResponse = PartnerAccreditationRequest & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PartnerAccreditationRequest;
    };
};

/**
 * Contains response data for the downloadBankStatements operation.
 */
export type DownloadBankStatementsResponse = Array<TransactionDataFile> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile[];
    };
};

/**
 * Contains response data for the downloadBlankInvoices operation.
 */
export type DownloadBlankInvoicesResponse = Array<TransactionDataFile> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile[];
    };
};

/**
 * Contains response data for the downloadW9Form operation.
 */
export type DownloadW9FormResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the getPartnerDailyReports operation.
 */
export type GetPartnerDailyReportsResponse = PagedListPartnerProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListPartnerProjection;
    };
};

/**
 * Contains response data for the getPartnerIdByUrlReferral operation.
 */
export type GetPartnerIdByUrlReferralResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the getSupportingDocumentRequest operation.
 */
export type GetSupportingDocumentRequestResponse = Array<SupportDocumentDetails> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SupportDocumentDetails[];
    };
};

/**
 * Contains response data for the getSupportingDocument operation.
 */
export type GetSupportingDocumentResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the getSupportingDocuments operation.
 */
export type GetSupportingDocumentsResponse = PagedListSupportDocumentDetails & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: PagedListSupportDocumentDetails;
    };
};

/**
 * Contains response data for the isSupportedDocumentCompleted operation.
 */
export type IsSupportedDocumentCompletedResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the isSupportedEnabled operation.
 */
export type IsSupportedEnabledResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the getRequestSupportingDocuments operation.
 */
export type GetRequestSupportingDocumentsResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the getDesignElementsOnSite operation.
 */
export type GetDesignElementsOnSiteResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: string;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: { [propertyName: string]: string };
    };
};

/**
 * Contains response data for the getAllSites operation.
 */
export type GetAllSitesResponse = Array<SiteDetails> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: SiteDetails[];
    };
};

/**
 * Contains response data for the getDesignElementList operation.
 */
export type GetDesignElementListResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};
