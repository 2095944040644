import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/shared/services/api.service";
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: "app-how-it-works",
  templateUrl: "./how-it-works.component.html",
  styleUrls: ["./how-it-works.component.scss"],
})
export class HowItWorksComponent implements OnInit {
  pendingPartner = false;
  pendingPartnerDetailForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private apiService: ApiService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  private initPendingPartnerDetailForm() {
    this.pendingPartnerDetailForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      phone: ["", [Validators.required]],
      company: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
      customertype: ["", [Validators.required]]
    });
  }

  recaptcha: any;
  submitPendingPartnerDetail() {
    this.pendingPartner = true;
    if (this.pendingPartnerDetailForm.valid) {
      this.recaptcha = this.recaptchaV3Service.execute('howitworks')
        .subscribe(
          (token) => {
            this.apiService
              .sendMessage("LearnMoreAsPartner", this.pendingPartnerDetailForm.value)
              .pipe(finalize(() => (this.pendingPartner = false)))
              .subscribe(
                (data) => {
                  this.pendingPartnerDetailForm.reset();
                  this.toastr.success("Your details has been sent");
                },
                (error) => {
                  this.toastr.error("Server Error. Try again later");
                }
              );
          },
          (error) => {
            this.toastr.error("Recaptcha Error");
          }
        );

    } else {
      if (this.pendingPartnerDetailForm.controls["firstname"].invalid) {
        this.toastr.error("First name is required");
      }
      if (this.pendingPartnerDetailForm.controls["lastname"].invalid) {
        this.toastr.error("Last name is required");
      }
      if (this.pendingPartnerDetailForm.controls["phone"].invalid) {
        this.toastr.error("Phone is invalid");
      }
      if (this.pendingPartnerDetailForm.controls["company"].invalid) {
        this.toastr.error("Company is required");
      }
      if (this.pendingPartnerDetailForm.controls["email"].invalid) {
        this.toastr.error("Email address is invalid");
      }
      if (this.pendingPartnerDetailForm.controls["customertype"].invalid) {
        this.toastr.error("Customer type is invalid");
      }
      this.pendingPartner = false;
    }
  }

  scrollToElement($element): void {
    $element.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }

  ngOnInit(): void {
    this.initPendingPartnerDetailForm();
  }

  ngOnDestroy() {
    if (this.recaptcha) {
      this.recaptcha.unsubscribe();
    }
  }
}
