<section *ngIf="urlService.checkUrlCom()">
  <div class="container">
    <h1>Terms of Use and Conditions</h1>

    <h3>Please read these Terms of Use carefully and completely.</h3>
    <p class="policy-text">The following terms and conditions govern the use of our website which is managed by Plexe
      Pty Ltd and its related entities (collectively referred to as "PLEXE") and is intended for use by individual
      business owners and legal entities organized and authorized to perform business in Australia only
    </p>
    <h3>Accepting the Terms.</h3>

    <p class="policy-text">By using the information, tools, features and functionality located at plexe.com.au or
      subdomains or by submitting an application for credit to PLEXE, you signify that you have read, understand and
      agree to be bound by these terms and conditions (the “Agreement”) for any and all applications for credit to
      PLEXE. You further agree all information being provided by your company as the borrower and or by you (either as a
      guarantor or as an authorized representative of your company) as part of the application process is true and
      complete.
      <br />  <br/>
      You may not use the Service and you may not accept this Agreement if you are under 18 years of age. You represent
      that you have the capacity to bind the company or entity applying for credit, on behalf of such company or entity.
      You understand that (a) we will require you to sign a personal guarantee for the credit obtained by your company
      from PLEXE, and (b) we will take a general lien on your business’s assets.
      <br />  <br/>
      In addition to this Agreement you and your company may enter into other agreements that will govern the terms of
      your company’s loan or line of credit and use of the Service. If there is a conflict between this Agreement and
      your Platform Agreement and Terms and Conditions, the other agreement shall govern with respect to those specific
      aspects
    </p>
    <h3>Credit and Background Check Authorisation</h3>

    <p class="policy-text">You understand and agree that PLEXE, agents and assignees are authorised to contact third
      parties to conduct background checks and other investigative reports, or obtain any other document or data that we
      reasonably believe may be necessary to determine your eligibility for credit, including but not limited make
      credit report inquiries (including requesting personal credit bureau reports about you and any other owner(s) of
      the business who will be providing a personal guarantee, and business credit bureaus about your company, in either
      case, from credit reporting agencies and other sources) or for any other lawful purpose. PLEXE has such authority
      with any extension of credit to the company or any other time during which credit has been obtained by your
      company from PLEXE on whose behalf you are applying, conducting loan file updates, conducting credit report
      inquiry updates, ongoing loan reviews, renewal of financing, or referral of your business to third party lenders.
      You understand and agree that PLEXE is making no commitment or guarantee that any product, evaluation, or guidance
      provided by PLEXE will result in an approval of credit.

    <h3>Primary uses:</h3>

    <p class="policy-text">The Site is only intended for those who access it from within Australia. Because of this we
      cannot guarantee that the Site or the information thereon complies with law or regulation of other countries, or
      is appropriate for use, in other places.
      <br />  <br/>
      You are wholly responsible for use of the Site by any person using your computer and you must ensure that any such
      person complies with these Terms.
      <br />  <br/>
      The information provided on this Site is not intended for distribution to, or use by, any person in any
      jurisdiction where such distribution or use would be contrary to law or regulation. This Site should not be
      considered as communicating any invitation or inducement to engage in banking or investment activity or any offer
      to buy or sell any securities or other instruments outside of Australia.
    </p>
    <h3>Copyright and Other Intellectual Property:</h3>

    <p class="policy-text">PLEXE, maintains this website and certain other websites that are linked to this site, and is
      the owner or the authorized user of all text, images, graphics, photos, animation, music, sounds, and other
      materials contained within these websites. The materials contained within these websites, including, without
      limitation, any copyrights, trademarks, service marks, and all other proprietary materials, are protected by
      international copyright laws and treaty provisions, trademarks laws, and other proprietary rights laws. PLEXE
      and/or PLEXE members also own(s) a copyright in the selection, coordination and arrangement of the material
      contained within these websites.
      <br />  <br/>
      The material contained within these websites is provided by PLEXE and/or PLEXE members only for lawful uses by
      customers, PLEXE employees, and members of the general public. the material may not be copied, republished,
      incorporated into another website, or reproduced (whether by linking, framing, or any other method), transmitted,
      distributed, uploaded, posted, used to create a derivative work, or exploited in any other way without the express
      written consent of PLEXE.
    </p>
    <h3>Limited Liability:</h3>

    <p class="policy-text">Neither PLEXE and/or PLEXE members nor any other party involved in the creation, production
      or delivery of the information at this site, nor the officers, directors, employees or representatives of any of
      the foregoing, are liable in any way for any indirect, special, punitive, consequential, or indirect damages
      (including without limitation lost profits, cost of procuring substitute service or lost opportunity) arising out
      of or in connection with this site or the use of this site or a linked site or with the delay or inability to use
      this site or a linked site, whether or not PLEXE is made aware of the possibility of such damages. This limitation
      includes, but is not limited to, the transmission of any viruses, data or harmful code that may affect a user's
      equipment, any incompatibility between this site's files and the user's browser or other site accessing program or
      any failure of any electronic or telephone equipment, communication or connection lines, unauthorized access,
      theft, operator errors, or any force majeure. PLEXE does not guarantee continuous, uninterrupted, or secure access
      to this site or a linked site. The content, accuracy, opinions expressed, and other links provided by the linked
      sites are not necessarily investigated, verified, monitored, or endorsed by PLEXE. The information, software,
      products, and description of services published on the site, or a linked site may include inaccuracies or
      typographical errors, and PLEXE and/or PLEXE members specifically disclaim any liability for such inaccuracies or
      errors. Changes are periodically made to the information on the site and linked sites. PLEXE may make improvements
      or changes to the site at any time.
    </p>
    <h3>NO WARRANTIES:</h3>

    <p class="policy-text">All products, services and content on this site are provided "as is" without warranty of any
      kind, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a
      particular purpose, title, non-infringement, security, or accuracy. Neither PLEXE nor any PLEXE members endorses,
      and none are responsible for the accuracy or reliability of any information on this site. It is the responsibility
      of the user to evaluate the accuracy, reliability, timeliness, and completeness of any information available on
      this site. PLEXE specifically disclaims any duty to update the information on the site.
      <br /> <br />
      User agrees to indemnify, defend, and hold PLEXE and/or PLEXE members harmless from any liability, loss, claim and
      expense, including attorney's fees related to a user's violation of these terms of use or the use of the services
      and information provided at this site.
    </p>
    <h3>Links:</h3>

    <p class="policy-text">Clicking on certain links within this Website or certain other Websites that are linked to
      this Website may take you to other Websites or may display information on your computer screen from other
      Websites, which may not be maintained by PLEXE. Such Websites may contain terms and conditions, privacy
      provisions, confidentiality provisions, or other provisions that differ from the terms and conditions applicable
      to this Website. Links to other Internet services and Websites are provided solely for the convenience of users. A
      link to any service or Website is not an endorsement of any kind of the service or Website, its content, or its
      sponsoring organization.
      <br /> <br />
      PLEXE and/or PLEXE members assume no responsibility or liability whatsoever for the content, accuracy,
      reliability, or opinions expressed in a website, to which this site is linked (a "linked site") and such linked
      sites are not monitored, investigated, or checked for accuracy or completeness by PLEXE. It is the responsibility
      of the user to evaluate the accuracy, reliability, timeliness, and completeness of any information available on a
      linked site. All products, services and content obtained from a linked site are provided "as is" without warranty
      of any kind, express or implied, including, but not limited to, implied warranties of merchantability, fitness for
      a particular purpose, title, non-infringement, security, or accuracy.
    </p>
    <h3>Children:</h3>

    <p class="policy-text">We do not knowingly solicit or collect personal information from children under the age 13,
      without parental consent.
    </p>
    <h3>Confidentiality of Information:</h3>

    <p class="policy-text">We have taken reasonable steps to ensure the confidentiality of information taken at this
      Website and transmitted via the Internet. However, unexpected changes in technology may be used by unauthorized
      third parties to intercept confidential information and we cannot be responsible should confidential information
      be intercepted and subsequently used by an unintended recipient.
    </p>
    <h3>Choice of Law:</h3>

    <p class="policy-text">All Website activity or use and these Terms and Conditions are governed by the laws of
      Australia and the applicable laws of New South Wales, without regard to conflict of law principles.
    </p>
    <h3>Website Content and Material:</h3>

    <p class="policy-text">PLEXE and its affiliates reserve the right to amend, supplement, modify or otherwise revise
      any information contained on this website at any time, and from time-to-time, without notification. Not all
      products and services are available in all geographic areas. Your eligibility for products and services is subject
      to final determination and acceptance by us.
    </p>
    <h3>Waiver and Severability:</h3>

    <p class="policy-text">Any waiver of any provision contained in these Terms and Conditions shall not be deemed to be
      a waiver of any other right, term or provision of these Terms and Conditions. If any provision in these Terms and
      Conditions shall be or become wholly or partially invalid, illegal, or unenforceable, such provision shall be
      enforced to the extent it is legal and valid and the validity, legality and enforceability of the remaining
      provisions shall in no way be affected or impaired thereby.
      <br /> <br />
      PLEXE may make changes to this Agreement from time to time. If we do this, the date at the top of this page
      indicates when the latest revisions were made. Your continued use of the Service or the Website will constitute
      acceptance of such revisions.
      <br /> <br />
      Effective (Rev 09/2021)
    </p>



  </div>
</section>

<section *ngIf="urlService.checkUrlCo()">
  <div class="container">
    <h1>Terms of Use and Conditions</h1>
    <h3>Please read these Terms of Use carefully and completely.</h3>

    <p class="policy-text">Plexe, LLC is a financial technology company, not a bank.</p>
    <p class="policy-text">The following terms and conditions govern the use of our website which is managed by Plexe,
      LLC (collectively referred to as "PLEXE") andis intended for use by individual business owners and legal entities
      organized and
      authorized to perform business in the U.S only.</p>

    <h3>Accepting the Terms</h3>

    <p class="policy-text">By using the information, tools, features and functionality located at plexe.co or subdomains
      or by submitting an application for credit to PLEXE, you signify that you have read, understand and agree to be
      bound by these terms and conditions (the “Agreement”) for any and all applications for credit to PLEXE. You
      further agree all information being provided by your company as the borrower and or by you (either as a guarantor
      or as an authorized representative of your company) as part of the application process is true and complete. </p>
    <p class="policy-text">You may not use the Service and you may not accept this Agreement if you are under 18 years
      of age. You represent that you have the capacity to bind the company or entity applying for credit, on behalf of
      such company or entity. You understand that (a) we will require you to sign a personal guarantee for the credit
      obtained by your company from PLEXE, and (b) we will take a general lien on your business’s assets. 
    </p>
    <p class="policy-text">In addition to this Agreement you and your company may enter into other agreements that will
      govern the terms of your company’s loan or line of credit and use of the Service. If there is a conflict between
      this Agreement and your Platform Agreement and Terms and Conditions, the other agreement shall govern with respect
      to those specific aspects.
    </p>
     
    <h3>Credit and Background Check Authorization</h3>

    <p class="policy-text">You understand and agree that PLEXE, agents and assignees are authorized to contact third
      parties to conduct background checks and other investigative reports, or obtain any other document or data that we
      reasonably believe may be necessary to determine your eligibility for credit, including but not limited make
      credit report inquiries (including requesting personal credit bureau reports about you and any other owner(s) of
      the business who will be providing a personal guarantee, and business credit bureaus about your company, in either
      case, from credit reporting agencies and other sources) or for any other lawful purpose. PLEXE has such authority
      with any extension of credit to the company or any other time during which credit has been obtained by your
      company from PLEXE
      on whose behalf you are applying, conducting loan file updates,
      conducting credit report inquiry updates, ongoing loan reviews, renewal of financing, or referral of your business
      to third party lenders. You understand
      and agree that PLEXE is making no commitment or guarantee that any product, evaluation, or guidance provided by
      PLEXE will result in an approval of credit.</p>

    <h3>Primary uses:</h3>
    <p class="policy-text">
      The Site is only intended for those who access it from within United States. Because of this we cannot guarantee
      that the Site or the information thereon complies with law or regulation of other countries, or is appropriate for
      use, in other places.
    </p>
    <p class="policy-text">
      You are wholly responsible for use of the Site by any person using your computer and you must ensure that any such
      person complies with these Terms.
    </p>
    <p class="policy-text">
      The information provided on this Site is not intended for distribution to, or use by, any person in any
      jurisdiction where such distribution or use would be contrary to law or regulation. This Site should not be
      considered as communicating any invitation or inducement to engage in banking or investment activity or any offer
      to buy or sell any securities or other instruments outside of the United States.
    </p>
    <h3>Copyright and Other Intellectual Property:</h3>
    <p class="policy-text">
      PLEXE, maintains this website and certain other websites that are linked to this site, and is the owner or the
      authorized user of all text, images, graphics, photos, animation, music, sounds, and other materials contained
      within these websites. The materials contained within these websites, including, without limitation, any
      copyrights, trademarks, service marks, and all other proprietary materials, are protected by the U.S. and
      international copyright laws and treaty provisions, trademarks laws, and other proprietary rights laws. PLEXE
      and/or PLEXE members also own(s) a copyright in the selection, coordination and arrangement of the material
      contained within these websites.
    </p>
    <p class="policy-text">
      The material contained within these websites is provided by PLEXE and/or PLEXE members only for lawful uses by
      customers, PLEXE employees, and members of the general public. the material may not be copied, republished,
      incorporated into another website, or reproduced (whether by linking, framing, or any other method), transmitted,
      distributed, uploaded, posted, used to create a derivative work, or exploited in any other way without the express
      written consent of PLEXE.
    </p>
    <h3>Limited Liability:</h3>
    <p class="policy-text">Neither PLEXE and/or PLEXE members nor any other party involved in the creation, production
      or delivery of the information at this site, nor the officers, directors, employees or representatives of any of
      the foregoing, are liable in any way for any indirect, special, punitive, consequential, or indirect damages
      (including without limitation lost profits, cost of procuring substitute service or lost opportunity) arising out
      of or in connection with this site or the use of this site or a linked site or with the delay or inability to use
      this site or a linked site, whether or not PLEXE is made aware of the possibility of such damages. This limitation
      includes, but is not limited to, the transmission of any viruses, data or harmful code that may affect a user's
      equipment, any incompatibility between this site's files and the user's browser or other site accessing program or
      any failure of any electronic or telephone equipment, communication or connection lines, unauthorized access,
      theft, operator errors, or any force majeure. PLEXE does not guarantee continuous, uninterrupted, or secure access
      to this site or a linked site. The content, accuracy, opinions expressed, and other links provided by the linked
      sites are not necessarily investigated, verified, monitored, or endorsed by PLEXE. The information, software,
      products, and description of services published on the site, or a linked site may include inaccuracies or
      typographical errors, and PLEXE and/or PLEXE members specifically disclaim any liability for such inaccuracies or
      errors. Changes are periodically made to the information on the site and linked sites. PLEXE may make improvements
      or changes to the site at any time.</p>

    <h3>NO WARRANTIES:</h3>
    <p class="policy-text">
      All products, services and content on this site are provided "as is" without warranty of any kind, express or
      implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose,
      title, non-infringement, security, or accuracy. Neither PLEXE nor any PLEXE members endorses, and none are
      responsible for the accuracy or reliability of any information on this site. It is the responsibility of the user
      to evaluate the accuracy, reliability, timeliness, and completeness of any information available on this site.
      PLEXE specifically disclaims any duty to update the information on the site.
    </p>
    <p class="policy-text">
      User agrees to indemnify, defend, and hold PLEXE and/or PLEXE members harmless from any liability, loss, claim and
      expense, including attorney's fees related to a user's violation of these terms of use or the use of the services
      and information provided at this site.
    </p>
    <h3>Links:</h3>
    <p class="policy-text">
      Clicking on certain links within this Website or certain other Websites that are linked to this Website may take
      you to other Websites or may display information on your computer screen from other Websites, which may not be
      maintained by PLEXE. Such Websites may contain terms and conditions, privacy provisions, confidentiality
      provisions, or other provisions that differ from the terms and conditions applicable to this Website. Links to
      other Internet services and Websites are provided solely for the convenience of users. A link to any service or
      Website is not an endorsement of any kind of the service or Website, its content, or its sponsoring organization.
    </p>
    <p class="policy-text">
      PLEXE and/or PLEXE members assume no responsibility or liability whatsoever for the content, accuracy,
      reliability, or opinions expressed in a website, to which this site is linked (a "linked site") and such linked
      sites are not monitored, investigated, or checked for accuracy or completeness by PLEXE. It is the responsibility
      of the user to evaluate the accuracy, reliability, timeliness, and completeness of any information available on a
      linked site. All products, services and content obtained from a linked site are provided "as is" without warranty
      of any kind, express or implied, including, but not limited to, implied warranties of merchantability, fitness for
      a particular purpose, title, non-infringement, security, or accuracy.
    </p>
    <h3>Children:</h3>
    <p class="policy-text">
      We do not knowingly solicit or collect personal information from children under the age 13, without parental
      consent.
    </p>
    <h3>Confidentiality of Information:</h3>
    <p class="policy-text">
      We have taken reasonable steps to ensure the confidentiality of information taken at this Website and transmitted
      via the Internet. However, unexpected changes in technology may be used by unauthorized third parties to intercept
      confidential information and we cannot be responsible should confidential information be intercepted and
      subsequently used by an unintended recipient.
    </p>
    <h3>Choice of Law:</h3>
    <p class="policy-text">
      All Website activity or use and these Terms and Conditions are governed by the laws of the United States of
      America and the applicable laws of the State of Delaware, without regard to conflict of law principles.
    </p>
    <h3>Website Content and Material:</h3>
    <p class="policy-text">
      PLEXE and its affiliates reserve the right to amend, supplement, modify or otherwise revise any information
      contained on this website at any time, and from time-to-time, without notification. Not all products and services
      are available in all geographic areas. Your eligibility for products and services is subject to final
      determination and acceptance by us.
    </p>
    <h3>Waiver and Severability:</h3>
    <p class="policy-text">
      Any waiver of any provision contained in these Terms and Conditions shall not be deemed to be a waiver of any
      other right, term or provision of these Terms and Conditions. If any provision in these Terms and Conditions shall
      be or become wholly or partially invalid, illegal, or unenforceable, such provision shall be enforced to the
      extent it is legal and valid and the validity, legality and enforceability of the remaining provisions shall in no
      way be affected or impaired thereby.
    </p>


    <p class="policy-text">PLEXE may make changes to this Agreement from time to time. If we do this, the date at the
      top of this page
      indicates when the latest revisions were made. Your continued use of the Service or the Website will constitute
      acceptance of such revisions.</p>


    <p class="policy-text">Effective (Rev 09/2021)</p>
  </div>
</section>