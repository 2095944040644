import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {



  faqs: any[] = [
    {
      header: 'About Us',
      ref: 'about-us',
      question: 'Who we are?',
      answer: 'We are an innovative lender supporting the growth of small to medium sized businesses across the country. We recognised that traditional lending products have not evolved to support the diversity of modern-day businesses, so we engineered a uniquely versatile line of credit to be the perfect fit for them all.'
    },
    {
      header: 'Application and Account Setup',
      ref: 'application',
      question: 'The ideal customer and business?',
      answer: 'We believe all small businesses should get the funding they deserve, so we developed advanced data analysis to get a deeper understanding of where each business is at, as well as where it is going. This deeper understanding enables us to provide funding in a multitude of different ways, all through one line of credit. So, whether your business is large or small, old or young, with assets or without, we can help.'
    },
    {
      question: 'What we finance ',
      ref: 'what-we-finance',
      answer: 'Future Cash Flow <br><br> Access funding even when your business has no assets.We assess your earnings capacity to ensure that any funding provided can be comfortably repaid out of future cash flow.<br><br>Receivables and Preceivables®<br><br>Access funding by leveraging your invoices and now as an industry first with Plexe, you can access partial funding before you invoice by leveraging any drafts, quotes or orders.<br><br>Quotes, Orders and Inventory <br><br> Access partial funding by leveraging your current inventory. To access funding for the purchase of new inventory either locally or abroad, provide us with a valid purchase order and we can help there too.'
    },
    {
      question: 'How is my credit limit determined?',
      ref: 'limit-determinated',
      answer: 'We take a holistic view on all the elements of value within your business in order to determine your credit limit. So, whether it is determined by your receivables, preceivables®, inventory or future cashflows, or a combination of everything, rest assured you’ll get the optimal credit limit. Best of all, it will naturally grow with your business.'
    },
    {
      question: 'Funding range?',
      ref: 'funding-range',
      answer: 'Initial limits of $10,000 to $250,000. Your credit limit adapts to your changing needs, adjusting as your business grows removing the need to reapply.'
    },
    {
      question: 'How does PLEXE assess my application?',
      ref: 'plexe',
      answer: 'By analysing your business bank or accounting data in conjunction with the credit history of your business.'
    },
    {
      question: 'Why two different ways of connecting?',
      ref: 'two-ways',
      answer: 'We simply added the option to provide banking data for businesses that don’t use online accounting software.'
    },
    {
      question: 'Plexe supported platforms?',
      ref: 'plexe-supported-platforms',
      answer: 'For accounting data, we integrate seamlessly with Xero, MYOB and QuickBooks with more coming soon. For banking data, we can accept data from almost all the major banks and credit unions.'
    },
    {
      question: 'How does the application and approval process work?',
      ref: 'how-approval-process-work',
      answer: '1. Register an account and connect your accounting and or bank data to see what funding we can provide. There is no obligation at this point. <br><br> 2. Once your account has been approved, you may activate your account and withdraw funds whenever you like.'
    },
    {
      header: 'Data Security',
      ref: 'data-security',
      question: 'Does PLEXE make contact with my customers?',
      answer: 'No disclosure of our relationship is made to any of your customers. Our service is 100% confidential.'
    },
    {
      question: 'Is PLEXE secure?',
      ref: 'is-plexe-secure',
      answer: 'Security is our number one priority. All communication with us and third-party systems is done using industry standard 256-bit encryption technology. We do not store any username or passwords for your accounting software or bank accounts in our system. Our data centres are all based in Australia and we undertake periodic reviews and penetration testing to ensure our systems are secure.'
    },
    {
      header: 'Product Details',
      ref: 'product-details',
      question: 'What is the product?',
      answer: 'An innovative line of credit designed to provide you with flexibility and simplicity. You select the terms that suit your business, including choosing fixed repayments or pay as you earn (a percentage of your income). Your limits automatically increase as your business grows so you don’t need to reapply. And what’s nice is you receive 21 days no payments and no interest or fees on every withdrawal.'
    },
    {
      question: 'What does it cost to use?',
      ref: 'is-plexe-secure',
      answer: 'We charge one simple Repayment percentage on each repayment you make, and this is disclosed upfront to you. That way, you‘ll always know exactly what it will cost. We have no hidden fees, no management fees, no line fees or even interest!'
    },
    {
      question: 'How is the Advance Fee calculated?',
      ref: 'how-advance-fee-calculated',
      answer: 'To keep our pricing fair and competitive, the fee is based on our holistic analysis of the data for each individual business.'
    },
    {
      question: 'When can I expect to receive the funds into my bank account?',
      ref: 'receive-bank',
      answer: 'Once your account is approved, if you submit a request for a same day transfer before 2pm, the funds will arrive the same day.'
    },
    {
      question: 'How do repayments work?',
      ref: 'how-repayments-work',
      answer: 'We offer options so that repayments can be aligned with individual business cash flows. Choose from fixed weekly direct debits (also tailor the repayment term), pay as your earn - variable weekly direct debits (Repayments are made by nominating a percentage of your incoming cashflow you put towards your repayments, meaning repayments rise and fall in line with your cash flow providing flexibility during slower periods, subject to minimum repayments) or  invoice payments only (we set up a bank account in the name of your business for your customers to make payment contributions).'
    },
    {
      header: 'Transacting',
      ref: 'transacting',
      question: 'How do I access funds once my account is approved?',
      answer: 'Simply log into your dashboard and click Withdraw Funds. Choose how you’d like to repay them and then enter the amount. Hit Proceed and we will do the rest.'
    },
    {
      question: 'Can my limit ever change?',
      ref: 'limit-ever-change',
      answer: 'Yes, your limit is designed to grow in line with your business so you’ll never need to reapply for funding.'
    },
    {
      question: 'When do I have to use the line of credit?',
      ref: 'line-of-credit',
      answer: 'Unlike some other facilities, our line of credit has been designed so that you can withdraw as little or as much as you’d like, as frequently or infrequently as you like. This way you can lower your borrowing cost by only withdrawing the exact amount you need, when you need it.'
    },

  ];

  constructor() { }

  ngOnInit(): void {
  }

  toggleAccordion(event, index) {
    const element = event.target;
    element.classList.toggle('active');
    if (this.faqs[index].isActive) {
      this.faqs[index].isActive = false;
    } else {
      this.faqs[index].isActive = true;
    }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + 'px';
    }
  }
}
