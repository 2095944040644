import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { PartnerComponent } from "./components/partner/partner.component";
import { AccreditationComponent } from "./components/accreditation/accreditation.component";
import { AboutUsComponent } from "./components/about-us/about-us.component";
import { FaqComponent } from "./components/faq/faq.component";
import { PrivacyComponent } from "./components/privacy/privacy.component";
import { TermsComponent } from "./components/terms/terms.component";
import { OurStoryComponent } from "./components/our-story/our-story.component";
import { HowItWorksComponent } from "./components/how-it-works/how-it-works.component";
import { PartnerReferralComponent } from "./components/partner-referral/partner-referral.component";
import { QuickbooksComponent } from "./components/quickbooks/quickbooks.component";
import { QuickBooksDisconnectedComponent } from "./components/quick-books-disconnected/quick-books-disconnected.component";
import { QuickBooksLearnMoreComponent } from "./components/quick-books-learn-more/quick-books-learn-more.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "how-it-works", component: HowItWorksComponent },
  {
    path: "partner", children: [
      { path: "", component: PartnerComponent },
      { path: ":urlReferral", component: PartnerReferralComponent }
    ]
  },
  { path: "our-story", component: OurStoryComponent },
  { path: "about-us", component: AboutUsComponent },
  { path: "faq", component: FaqComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "terms", component: TermsComponent },
  { path: "accreditation", component: AccreditationComponent },
  { path: 'quickbooks', component: QuickbooksComponent },
  { path: "quick-books-disconnected", component: QuickBooksDisconnectedComponent },
  { path: "quick-books-learn-more", component: QuickBooksLearnMoreComponent },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
