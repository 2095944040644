import { Injectable } from '@angular/core';
import { TokenCredentials } from '@azure/ms-rest-js';

export class MyToken extends TokenCredentials {
  constructor(token: string) {
    super(token);
  }

  setToken(t: string) {
    this.token = t;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TokenHolderService {
  private token: MyToken = new MyToken('default');

  constructor() {}

  getToken() {
    return this.token;
  }

  setToken(newToken: string) {
    this.token.setToken(newToken);
  }
}
