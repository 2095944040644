/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const alertId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'alertId'],
  mapper: {
    serializedName: 'alertId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicationId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'applicationId'],
  mapper: {
    serializedName: 'applicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicationId1: msRest.OperationQueryParameter = {
  parameterPath: 'applicationId',
  mapper: {
    required: true,
    serializedName: 'applicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const dateParameter: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'dateParameter'],
  mapper: {
    serializedName: 'date',
    type: {
      name: 'DateTime'
    }
  }
};
export const dateTime0: msRest.OperationQueryParameter = {
  parameterPath: 'dateTime',
  mapper: {
    required: true,
    serializedName: 'dateTime',
    type: {
      name: 'DateTime'
    }
  }
};
export const dateTime1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'dateTime'],
  mapper: {
    serializedName: 'dateTime',
    type: {
      name: 'DateTime'
    }
  }
};
export const end: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'end'],
  mapper: {
    serializedName: 'end',
    type: {
      name: 'DateTime'
    }
  }
};
export const endDate0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'endDate'],
  mapper: {
    serializedName: 'endDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const endDate1: msRest.OperationQueryParameter = {
  parameterPath: 'endDate',
  mapper: {
    required: true,
    serializedName: 'endDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const filterColumn: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'filterColumn'],
  mapper: {
    serializedName: 'filterColumn',
    type: {
      name: 'String'
    }
  }
};
export const filterValue: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'filterValue'],
  mapper: {
    serializedName: 'filterValue',
    type: {
      name: 'String'
    }
  }
};
export const id: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'id'],
  mapper: {
    serializedName: 'id',
    type: {
      name: 'Uuid'
    }
  }
};
export const include: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'include'],
  mapper: {
    serializedName: 'include',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const includePaymentArrangement: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'includePaymentArrangement'],
  mapper: {
    serializedName: 'includePaymentArrangement',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const loan: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loan'],
  mapper: {
    serializedName: 'loan',
    type: {
      name: 'Uuid'
    }
  }
};
export const loanId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loanId'],
  mapper: {
    serializedName: 'loanId',
    type: {
      name: 'Uuid'
    }
  }
};
export const loanId1: msRest.OperationQueryParameter = {
  parameterPath: 'loanId',
  mapper: {
    required: true,
    serializedName: 'loanId',
    type: {
      name: 'Uuid'
    }
  }
};
export const loanId2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loanId'],
  mapper: {
    serializedName: 'LoanId',
    type: {
      name: 'Uuid'
    }
  }
};
export const message: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'message'],
  mapper: {
    serializedName: 'message',
    type: {
      name: 'String'
    }
  }
};
export const metricName: msRest.OperationQueryParameter = {
  parameterPath: 'metricName',
  mapper: {
    required: true,
    serializedName: 'metricName',
    type: {
      name: 'String'
    }
  }
};
export const metricType: msRest.OperationQueryParameter = {
  parameterPath: 'metricType',
  mapper: {
    required: true,
    serializedName: 'metricType',
    type: {
      name: 'String'
    }
  }
};
export const net: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'net'],
  mapper: {
    serializedName: 'net',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const offerId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'offerId'],
  mapper: {
    serializedName: 'offerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const organisationId0: msRest.OperationQueryParameter = {
  parameterPath: 'organisationId',
  mapper: {
    required: true,
    serializedName: 'organisationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const organisationId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'organisationId'],
  mapper: {
    serializedName: 'organisationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const product: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'product'],
  mapper: {
    serializedName: 'product',
    type: {
      name: 'String'
    }
  }
};
export const quarterly: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'quarterly'],
  mapper: {
    serializedName: 'quarterly',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const repaymentType: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'repaymentType'],
  mapper: {
    serializedName: 'repaymentType',
    defaultValue: 0,
    type: {
      name: 'Number'
    }
  }
};
export const showClosed0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'showClosed'],
  mapper: {
    serializedName: 'showClosed',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const showClosed1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'showClosed'],
  mapper: {
    serializedName: 'showClosed',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const skip0: msRest.OperationQueryParameter = {
  parameterPath: 'skip',
  mapper: {
    required: true,
    serializedName: 'skip',
    type: {
      name: 'Number'
    }
  }
};
export const skip1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'skip'],
  mapper: {
    serializedName: 'skip',
    defaultValue: 0,
    type: {
      name: 'Number'
    }
  }
};
export const start: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'start'],
  mapper: {
    serializedName: 'start',
    type: {
      name: 'DateTime'
    }
  }
};
export const startDate0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'startDate'],
  mapper: {
    serializedName: 'startDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const startDate1: msRest.OperationQueryParameter = {
  parameterPath: 'startDate',
  mapper: {
    required: true,
    serializedName: 'startDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const take0: msRest.OperationQueryParameter = {
  parameterPath: 'take',
  mapper: {
    required: true,
    serializedName: 'take',
    type: {
      name: 'Number'
    }
  }
};
export const take1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'take'],
  mapper: {
    serializedName: 'take',
    defaultValue: 2147483647,
    type: {
      name: 'Number'
    }
  }
};
export const value: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'value'],
  mapper: {
    serializedName: 'value',
    type: {
      name: 'String'
    }
  }
};
export const weekly: msRest.OperationQueryParameter = {
  parameterPath: 'weekly',
  mapper: {
    required: true,
    serializedName: 'weekly',
    type: {
      name: 'Boolean'
    }
  }
};
