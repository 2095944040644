/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const LoginModel: msRest.CompositeMapper = {
  serializedName: 'LoginModel',
  type: {
    name: 'Composite',
    className: 'LoginModel',
    modelProperties: {
      username: {
        required: true,
        serializedName: 'username',
        type: {
          name: 'String'
        }
      },
      password: {
        required: true,
        serializedName: 'password',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const FullApplicationUser: msRest.CompositeMapper = {
  serializedName: 'FullApplicationUser',
  type: {
    name: 'Composite',
    className: 'FullApplicationUser',
    modelProperties: {
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      username: {
        serializedName: 'username',
        type: {
          name: 'String'
        }
      },
      userType: {
        serializedName: 'userType',
        type: {
          name: 'String'
        }
      },
      token: {
        serializedName: 'token',
        type: {
          name: 'String'
        }
      },
      expiresUTC: {
        serializedName: 'expiresUTC',
        type: {
          name: 'DateTime'
        }
      },
      requiresTwoFactor: {
        serializedName: 'requiresTwoFactor',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const TwoFactorModel: msRest.CompositeMapper = {
  serializedName: 'TwoFactorModel',
  type: {
    name: 'Composite',
    className: 'TwoFactorModel',
    modelProperties: {
      username: {
        required: true,
        serializedName: 'username',
        type: {
          name: 'String'
        }
      },
      code: {
        required: true,
        serializedName: 'code',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const UserModel: msRest.CompositeMapper = {
  serializedName: 'UserModel',
  type: {
    name: 'Composite',
    className: 'UserModel',
    modelProperties: {
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      customerType: {
        serializedName: 'customerType',
        type: {
          name: 'String'
        }
      },
      lockoutEnd: {
        serializedName: 'lockoutEnd',
        type: {
          name: 'DateTime'
        }
      },
      twoFactorEnabled: {
        serializedName: 'twoFactorEnabled',
        type: {
          name: 'Boolean'
        }
      },
      phoneNumberConfirmed: {
        serializedName: 'phoneNumberConfirmed',
        type: {
          name: 'Boolean'
        }
      },
      phoneNumber: {
        serializedName: 'phoneNumber',
        type: {
          name: 'String'
        }
      },
      emailConfirmed: {
        serializedName: 'emailConfirmed',
        type: {
          name: 'Boolean'
        }
      },
      normalizedEmail: {
        serializedName: 'normalizedEmail',
        type: {
          name: 'String'
        }
      },
      email: {
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      normalizedUserName: {
        serializedName: 'normalizedUserName',
        type: {
          name: 'String'
        }
      },
      userName: {
        serializedName: 'userName',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      lockoutEnabled: {
        serializedName: 'lockoutEnabled',
        type: {
          name: 'Boolean'
        }
      },
      accessFailedCount: {
        serializedName: 'accessFailedCount',
        type: {
          name: 'Number'
        }
      },
      lastSignInUtc: {
        serializedName: 'lastSignInUtc',
        type: {
          name: 'DateTime'
        }
      },
      lastLogOutUtc: {
        serializedName: 'lastLogOutUtc',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const HistoricalLoginModel: msRest.CompositeMapper = {
  serializedName: 'HistoricalLoginModel',
  type: {
    name: 'Composite',
    className: 'HistoricalLoginModel',
    modelProperties: {
      userName: {
        serializedName: 'userName',
        type: {
          name: 'String'
        }
      },
      logInDateUtc: {
        serializedName: 'logInDateUtc',
        type: {
          name: 'DateTime'
        }
      },
      logOutdateUtc: {
        serializedName: 'logOutdateUtc',
        type: {
          name: 'DateTime'
        }
      },
      timeLoggedIn: {
        serializedName: 'timeLoggedIn',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PagedListHistoricalLoginModel: msRest.CompositeMapper = {
  serializedName: 'PagedList_HistoricalLoginModel_',
  type: {
    name: 'Composite',
    className: 'PagedListHistoricalLoginModel',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'HistoricalLoginModel'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const RegisterModel: msRest.CompositeMapper = {
  serializedName: 'RegisterModel',
  type: {
    name: 'Composite',
    className: 'RegisterModel',
    modelProperties: {
      id: {
        required: true,
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MaxLength: 250,
          MinLength: 0
        },
        type: {
          name: 'String'
        }
      },
      email: {
        required: true,
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      passwordConfirmation: {
        required: true,
        serializedName: 'passwordConfirmation',
        type: {
          name: 'String'
        }
      },
      customerType: {
        required: true,
        serializedName: 'customerType',
        type: {
          name: 'String'
        }
      },
      username: {
        required: true,
        serializedName: 'username',
        type: {
          name: 'String'
        }
      },
      password: {
        required: true,
        serializedName: 'password',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const UpdateModel: msRest.CompositeMapper = {
  serializedName: 'UpdateModel',
  type: {
    name: 'Composite',
    className: 'UpdateModel',
    modelProperties: {
      firstName: {
        required: true,
        serializedName: 'firstName',
        constraints: {
          MaxLength: 200,
          MinLength: 0
        },
        type: {
          name: 'String'
        }
      },
      lastName: {
        required: true,
        serializedName: 'lastName',
        constraints: {
          MaxLength: 250,
          MinLength: 0
        },
        type: {
          name: 'String'
        }
      },
      email: {
        required: true,
        serializedName: 'email',
        type: {
          name: 'String'
        }
      },
      customerType: {
        required: true,
        serializedName: 'customerType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PasswordResetModel: msRest.CompositeMapper = {
  serializedName: 'PasswordResetModel',
  type: {
    name: 'Composite',
    className: 'PasswordResetModel',
    modelProperties: {
      password: {
        required: true,
        serializedName: 'password',
        type: {
          name: 'String'
        }
      },
      passwordConfirmation: {
        required: true,
        serializedName: 'passwordConfirmation',
        type: {
          name: 'String'
        }
      }
    }
  }
};
