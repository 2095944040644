import { Component, OnInit } from '@angular/core';
import { REGION } from '../../Constants';
import { Meta } from '@angular/platform-browser';
import {UrlService} from '../../shared/services/url.service';


@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  region = window['appConfig']['region'];
  isUSRegion = this.region == REGION.US;

  constructor(
    private meta: Meta, 
    public urlService: UrlService) { }
  
  ngOnInit(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

}
