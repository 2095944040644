import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UrlService {
  domainNamePlexeCo = "plexe.co";
  domainNamePlexeWwwCo = "www.plexe.co";
  domainNamePlexeCom = "plexe.com.au";
  domainNamePlexeWwwCom = "www.plexe.com.au";

  constructor() {}

  checkUrlCo() {
    //return true;
    var overrideAU = window["appConfig"]["overrideAU"];
    var overrideUS = window["appConfig"]["overrideUS"];

    if (overrideUS == "true" && overrideAU == "false") {
      return true;
    }

    return (
      window.location.hostname === this.domainNamePlexeCo ||
      window.location.hostname === this.domainNamePlexeWwwCo
    );
  }

  checkUrlCom() {
    var overrideAU = window["appConfig"]["overrideAU"];
    var overrideUS = window["appConfig"]["overrideUS"];

    if (overrideAU == "true" && overrideUS == "false") {
      return true;
    }

    return (
      window.location.hostname === this.domainNamePlexeCom ||
      window.location.hostname === this.domainNamePlexeWwwCom
    );
  }
}
