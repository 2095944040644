/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const SecondaryApplicantRequested: msRest.CompositeMapper = {
  serializedName: "SecondaryApplicantRequested",
  type: {
    name: "Composite",
    className: "SecondaryApplicantRequested",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DrivingLicense: msRest.CompositeMapper = {
  serializedName: "DrivingLicense",
  type: {
    name: "Composite",
    className: "DrivingLicense",
    modelProperties: {
      issuingState: {
        serializedName: "issuingState",
        type: {
          name: "String"
        }
      },
      cardNumber: {
        serializedName: "cardNumber",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "DateTime"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      image: {
        serializedName: "image",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const PrimaryApplicant: msRest.CompositeMapper = {
  serializedName: "PrimaryApplicant",
  type: {
    name: "Composite",
    className: "PrimaryApplicant",
    modelProperties: {
      isManuallyAdded: {
        serializedName: "isManuallyAdded",
        type: {
          name: "Boolean"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      driversLicense: {
        serializedName: "driversLicense",
        type: {
          name: "Composite",
          className: "DrivingLicense"
        }
      },
      isPrimary: {
        serializedName: "isPrimary",
        type: {
          name: "Boolean"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "String"
        }
      },
      miscellaneousData: {
        serializedName: "miscellaneousData",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const PrimaryApplicantAndSecondaryApplicantRequested: msRest.CompositeMapper = {
  serializedName: "PrimaryApplicantAndSecondaryApplicantRequested",
  type: {
    name: "Composite",
    className: "PrimaryApplicantAndSecondaryApplicantRequested",
    modelProperties: {
      secondaryApplicantRequested: {
        serializedName: "secondaryApplicantRequested",
        type: {
          name: "Composite",
          className: "SecondaryApplicantRequested"
        }
      },
      primaryApplicant: {
        serializedName: "primaryApplicant",
        type: {
          name: "Composite",
          className: "PrimaryApplicant"
        }
      }
    }
  }
};

export const SecondaryApplicant: msRest.CompositeMapper = {
  serializedName: "SecondaryApplicant",
  type: {
    name: "Composite",
    className: "SecondaryApplicant",
    modelProperties: {
      isManuallyAdded: {
        serializedName: "isManuallyAdded",
        type: {
          name: "Boolean"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      driversLicense: {
        serializedName: "driversLicense",
        type: {
          name: "Composite",
          className: "DrivingLicense"
        }
      },
      isPrimary: {
        serializedName: "isPrimary",
        type: {
          name: "Boolean"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "String"
        }
      },
      miscellaneousData: {
        serializedName: "miscellaneousData",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const Applicant: msRest.CompositeMapper = {
  serializedName: "Applicant",
  type: {
    name: "Composite",
    className: "Applicant",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      driversLicense: {
        serializedName: "driversLicense",
        type: {
          name: "Composite",
          className: "DrivingLicense"
        }
      },
      isPrimary: {
        serializedName: "isPrimary",
        type: {
          name: "Boolean"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "String"
        }
      },
      miscellaneousData: {
        serializedName: "miscellaneousData",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const PlexeOrganisationServiceBankFeedAccount: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceBankFeedAccount",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceBankFeedAccount",
    modelProperties: {
      selected: {
        serializedName: "selected",
        type: {
          name: "Boolean"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      accountNumber: {
        serializedName: "accountNumber",
        type: {
          name: "String"
        }
      },
      bsb: {
        serializedName: "bsb",
        type: {
          name: "String"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "String"
        }
      },
      available: {
        serializedName: "available",
        type: {
          name: "String"
        }
      },
      accountHolder: {
        serializedName: "accountHolder",
        type: {
          name: "String"
        }
      },
      accountType: {
        serializedName: "accountType",
        type: {
          name: "String"
        }
      },
      slug: {
        serializedName: "slug",
        type: {
          name: "String"
        }
      },
      enabled: {
        serializedName: "enabled",
        type: {
          name: "Boolean"
        }
      },
      accountId: {
        serializedName: "accountId",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Acknowledgement: msRest.CompositeMapper = {
  serializedName: "Acknowledgement",
  type: {
    name: "Composite",
    className: "Acknowledgement",
    modelProperties: {
      acknowledged: {
        serializedName: "acknowledged",
        type: {
          name: "Boolean"
        }
      },
      acknowledgementCode: {
        serializedName: "acknowledgementCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const StartApplicationRequest: msRest.CompositeMapper = {
  serializedName: "StartApplicationRequest",
  type: {
    name: "Composite",
    className: "StartApplicationRequest",
    modelProperties: {
      abn: {
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      customerId: {
        required: true,
        serializedName: "customerId",
        type: {
          name: "Uuid"
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      mobile: {
        required: true,
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      acnType: {
        serializedName: "acnType",
        type: {
          name: "String"
        }
      },
      acnNumber: {
        serializedName: "acnNumber",
        type: {
          name: "String"
        }
      },
      acknowledgements: {
        required: true,
        serializedName: "acknowledgements",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Acknowledgement"
            }
          }
        }
      },
      extraInformation: {
        serializedName: "extraInformation",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const Customer: msRest.CompositeMapper = {
  serializedName: "Customer",
  type: {
    name: "Composite",
    className: "Customer",
    modelProperties: {
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      mobileNumber: {
        required: true,
        serializedName: "mobileNumber",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      parentId: {
        serializedName: "parentId",
        type: {
          name: "Uuid"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      hasLoggedIn: {
        serializedName: "hasLoggedIn",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CreateApplicationAndCustomerRequest: msRest.CompositeMapper = {
  serializedName: "CreateApplicationAndCustomerRequest",
  type: {
    name: "Composite",
    className: "CreateApplicationAndCustomerRequest",
    modelProperties: {
      application: {
        serializedName: "application",
        type: {
          name: "Composite",
          className: "StartApplicationRequest"
        }
      },
      customer: {
        serializedName: "customer",
        type: {
          name: "Composite",
          className: "Customer"
        }
      }
    }
  }
};

export const ApplicationAndCustomerResponse: msRest.CompositeMapper = {
  serializedName: "ApplicationAndCustomerResponse",
  type: {
    name: "Composite",
    className: "ApplicationAndCustomerResponse",
    modelProperties: {
      customerId: {
        serializedName: "customerId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const UpdateApplicationRequest: msRest.CompositeMapper = {
  serializedName: "UpdateApplicationRequest",
  type: {
    name: "Composite",
    className: "UpdateApplicationRequest",
    modelProperties: {
      applicationId: {
        required: true,
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      abn: {
        required: true,
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      businessName: {
        required: true,
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      mobile: {
        required: true,
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      offerId: {
        serializedName: "offerId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Communication: msRest.CompositeMapper = {
  serializedName: "Communication",
  type: {
    name: "Composite",
    className: "Communication",
    modelProperties: {
      createdDateUtc: {
        serializedName: "createdDateUtc",
        type: {
          name: "DateTime"
        }
      },
      message: {
        required: true,
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      user: {
        required: true,
        serializedName: "user",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ValidationError: msRest.CompositeMapper = {
  serializedName: "ValidationError",
  type: {
    name: "Composite",
    className: "ValidationError",
    modelProperties: {
      errorCode: {
        serializedName: "errorCode",
        type: {
          name: "String"
        }
      },
      errorMessage: {
        serializedName: "errorMessage",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ApiResponseGuid: msRest.CompositeMapper = {
  serializedName: "ApiResponse_Guid_",
  type: {
    name: "Composite",
    className: "ApiResponseGuid",
    modelProperties: {
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationError"
            }
          }
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "Uuid"
        }
      },
      success: {
        serializedName: "success",
        type: {
          name: "Boolean"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const BankingData: msRest.CompositeMapper = {
  serializedName: "BankingData",
  type: {
    name: "Composite",
    className: "BankingData",
    modelProperties: {
      bankName: {
        serializedName: "bankName",
        type: {
          name: "String"
        }
      },
      customerId: {
        serializedName: "customerId",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      extraDetails: {
        serializedName: "extraDetails",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      yodlee: {
        serializedName: "yodlee",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const AccountingData: msRest.CompositeMapper = {
  serializedName: "AccountingData",
  type: {
    name: "Composite",
    className: "AccountingData",
    modelProperties: {
      provider: {
        serializedName: "provider",
        type: {
          name: "String"
        }
      },
      tokens: {
        serializedName: "tokens",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsBankAccount: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsBankAccount",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsBankAccount",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      accountNumber: {
        serializedName: "accountNumber",
        type: {
          name: "String"
        }
      },
      bsb: {
        serializedName: "bsb",
        type: {
          name: "String"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "String"
        }
      },
      available: {
        serializedName: "available",
        type: {
          name: "String"
        }
      },
      accountHolder: {
        serializedName: "accountHolder",
        type: {
          name: "String"
        }
      },
      accountType: {
        serializedName: "accountType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Trust: msRest.CompositeMapper = {
  serializedName: "Trust",
  type: {
    name: "Composite",
    className: "Trust",
    modelProperties: {
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        type: {
          name: "String"
        }
      },
      isCompanyTrust: {
        serializedName: "isCompanyTrust",
        type: {
          name: "Boolean"
        }
      },
      acn: {
        serializedName: "acn",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CompleteContractRequest: msRest.CompositeMapper = {
  serializedName: "CompleteContractRequest",
  type: {
    name: "Composite",
    className: "CompleteContractRequest",
    modelProperties: {
      ipAddress: {
        serializedName: "ipAddress",
        type: {
          name: "String"
        }
      },
      signature: {
        serializedName: "signature",
        type: {
          name: "ByteArray"
        }
      },
      signature2: {
        serializedName: "signature2",
        type: {
          name: "ByteArray"
        }
      },
      mimeType: {
        serializedName: "mimeType",
        type: {
          name: "String"
        }
      },
      secondaryApplicant: {
        serializedName: "secondaryApplicant",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Commission: msRest.CompositeMapper = {
  serializedName: "Commission",
  type: {
    name: "Composite",
    className: "Commission",
    modelProperties: {
      upfrontFee: {
        serializedName: "upfrontFee",
        type: {
          name: "Number"
        }
      },
      trailing: {
        serializedName: "trailing",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TransactionDataFile: msRest.CompositeMapper = {
  serializedName: "TransactionDataFile",
  type: {
    name: "Composite",
    className: "TransactionDataFile",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileType: {
        serializedName: "fileType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CardConfiguration: msRest.CompositeMapper = {
  serializedName: "CardConfiguration",
  type: {
    name: "Composite",
    className: "CardConfiguration",
    modelProperties: {
      limit: {
        serializedName: "limit",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CardHolderDetails: msRest.CompositeMapper = {
  serializedName: "CardHolderDetails",
  type: {
    name: "Composite",
    className: "CardHolderDetails",
    modelProperties: {
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "Number"
        }
      },
      limit: {
        serializedName: "limit",
        type: {
          name: "Number"
        }
      },
      suspended: {
        serializedName: "suspended",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CorprateCardHolder: msRest.CompositeMapper = {
  serializedName: "CorprateCardHolder",
  type: {
    name: "Composite",
    className: "CorprateCardHolder",
    modelProperties: {
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      middleName: {
        serializedName: "middleName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      cardId: {
        serializedName: "cardId",
        type: {
          name: "Uuid"
        }
      },
      providerType: {
        serializedName: "providerType",
        type: {
          name: "Number"
        }
      },
      aggregateId: {
        serializedName: "aggregateId",
        type: {
          name: "Uuid"
        }
      },
      createdOnUtc: {
        serializedName: "createdOnUtc",
        type: {
          name: "DateTime"
        }
      },
      eventId: {
        serializedName: "eventId",
        type: {
          name: "Uuid"
        }
      },
      jwtToken: {
        serializedName: "jwtToken",
        type: {
          name: "String"
        }
      },
      activityId: {
        serializedName: "activityId",
        type: {
          name: "Uuid"
        }
      },
      eventType: {
        readOnly: true,
        serializedName: "eventType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegisterCustomerRequest: msRest.CompositeMapper = {
  serializedName: "RegisterCustomerRequest",
  type: {
    name: "Composite",
    className: "RegisterCustomerRequest",
    modelProperties: {
      customer: {
        serializedName: "customer",
        type: {
          name: "Composite",
          className: "Customer"
        }
      }
    }
  }
};

export const IFormFile: msRest.CompositeMapper = {
  serializedName: "IFormFile",
  type: {
    name: "Composite",
    className: "IFormFile",
    modelProperties: {
      contentType: {
        readOnly: true,
        serializedName: "contentType",
        type: {
          name: "String"
        }
      },
      contentDisposition: {
        readOnly: true,
        serializedName: "contentDisposition",
        type: {
          name: "String"
        }
      },
      headers: {
        readOnly: true,
        serializedName: "headers",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Sequence",
              element: {
                type: {
                  name: "String"
                }
              }
            }
          }
        }
      },
      length: {
        readOnly: true,
        serializedName: "length",
        type: {
          name: "Number"
        }
      },
      name: {
        readOnly: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      fileName: {
        readOnly: true,
        serializedName: "fileName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Amount: msRest.CompositeMapper = {
  serializedName: "Amount",
  type: {
    name: "Composite",
    className: "Amount",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      currencyCode: {
        serializedName: "currencyCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TransactionProjection: msRest.CompositeMapper = {
  serializedName: "TransactionProjection",
  type: {
    name: "Composite",
    className: "TransactionProjection",
    modelProperties: {
      overrideId: {
        serializedName: "overrideId",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      advanceId: {
        serializedName: "advanceId",
        type: {
          name: "String"
        }
      },
      advanceName: {
        serializedName: "advanceName",
        type: {
          name: "String"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      reconciled: {
        serializedName: "reconciled",
        type: {
          name: "Boolean"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      isCredit: {
        serializedName: "isCredit",
        type: {
          name: "Boolean"
        }
      },
      bankReceipt: {
        serializedName: "bankReceipt",
        type: {
          name: "String"
        }
      },
      force: {
        serializedName: "force",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ApiResponseListGuid: msRest.CompositeMapper = {
  serializedName: "ApiResponse_List_Guid__",
  type: {
    name: "Composite",
    className: "ApiResponseListGuid",
    modelProperties: {
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationError"
            }
          }
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      success: {
        serializedName: "success",
        type: {
          name: "Boolean"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ApiResponseString: msRest.CompositeMapper = {
  serializedName: "ApiResponse_String_",
  type: {
    name: "Composite",
    className: "ApiResponseString",
    modelProperties: {
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationError"
            }
          }
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "String"
        }
      },
      success: {
        serializedName: "success",
        type: {
          name: "Boolean"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AdvanceProjection: msRest.CompositeMapper = {
  serializedName: "AdvanceProjection",
  type: {
    name: "Composite",
    className: "AdvanceProjection",
    modelProperties: {
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      remaining: {
        serializedName: "remaining",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      nextRepaymentValue: {
        serializedName: "nextRepaymentValue",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      nextRepaymentDateUTC: {
        serializedName: "nextRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      actualNextRepaymentDateUTC: {
        readOnly: true,
        serializedName: "actualNextRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      actualNextRepaymentDay: {
        readOnly: true,
        serializedName: "actualNextRepaymentDay",
        type: {
          name: "String"
        }
      },
      nextRepaymentDay: {
        readOnly: true,
        serializedName: "nextRepaymentDay",
        type: {
          name: "String"
        }
      },
      lastRepaymentDay: {
        readOnly: true,
        serializedName: "lastRepaymentDay",
        type: {
          name: "String"
        }
      },
      lastRepaymentDateUTC: {
        readOnly: true,
        serializedName: "lastRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      totalRepaymentAmount: {
        serializedName: "totalRepaymentAmount",
        type: {
          name: "Number"
        }
      },
      weeklyPayment: {
        serializedName: "weeklyPayment",
        type: {
          name: "Number"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      terms: {
        serializedName: "terms",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Boolean"
        }
      },
      percentage: {
        serializedName: "percentage",
        type: {
          name: "Number"
        }
      },
      weeks: {
        serializedName: "weeks",
        type: {
          name: "Number"
        }
      },
      name: {
        readOnly: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      earlyRepaid: {
        serializedName: "earlyRepaid",
        type: {
          name: "Boolean"
        }
      },
      earlyRepaidDate: {
        serializedName: "earlyRepaidDate",
        type: {
          name: "DateTime"
        }
      },
      overrideLastRepaymentDateUTC: {
        serializedName: "overrideLastRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      overrideLastRepayment: {
        serializedName: "overrideLastRepayment",
        type: {
          name: "Boolean"
        }
      },
      legacy: {
        serializedName: "legacy",
        type: {
          name: "Boolean"
        }
      },
      monthlyFee: {
        serializedName: "monthlyFee",
        type: {
          name: "Boolean"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      fixed: {
        serializedName: "fixed",
        type: {
          name: "Boolean"
        }
      },
      fixedRepayment: {
        serializedName: "fixedRepayment",
        type: {
          name: "Number"
        }
      },
      fixedRepaymentFee: {
        serializedName: "fixedRepaymentFee",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ApiResponseListAdvanceProjection: msRest.CompositeMapper = {
  serializedName: "ApiResponse_List_AdvanceProjection__",
  type: {
    name: "Composite",
    className: "ApiResponseListAdvanceProjection",
    modelProperties: {
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationError"
            }
          }
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AdvanceProjection"
            }
          }
        }
      },
      success: {
        serializedName: "success",
        type: {
          name: "Boolean"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CommissionRequest: msRest.CompositeMapper = {
  serializedName: "CommissionRequest",
  type: {
    name: "Composite",
    className: "CommissionRequest",
    modelProperties: {
      upfrontFee: {
        serializedName: "upfrontFee",
        type: {
          name: "Number"
        }
      },
      trailing: {
        serializedName: "trailing",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WithdrawPercentageRequest: msRest.CompositeMapper = {
  serializedName: "WithdrawPercentageRequest",
  type: {
    name: "Composite",
    className: "WithdrawPercentageRequest",
    modelProperties: {
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      percentage: {
        serializedName: "percentage",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Boolean"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      nextRepaymentDate: {
        serializedName: "nextRepaymentDate",
        type: {
          name: "DateTime"
        }
      },
      weeks: {
        serializedName: "weeks",
        type: {
          name: "Number"
        }
      },
      totalRepaymentAmount: {
        serializedName: "totalRepaymentAmount",
        type: {
          name: "Number"
        }
      },
      otp: {
        serializedName: "otp",
        type: {
          name: "String"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "CommissionRequest"
        }
      }
    }
  }
};

export const ApiResponseAdvanceProjection: msRest.CompositeMapper = {
  serializedName: "ApiResponse_AdvanceProjection_",
  type: {
    name: "Composite",
    className: "ApiResponseAdvanceProjection",
    modelProperties: {
      errors: {
        serializedName: "errors",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ValidationError"
            }
          }
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "Composite",
          className: "AdvanceProjection"
        }
      },
      success: {
        serializedName: "success",
        type: {
          name: "Boolean"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const WithdrawInvoiceRequest: msRest.CompositeMapper = {
  serializedName: "WithdrawInvoiceRequest",
  type: {
    name: "Composite",
    className: "WithdrawInvoiceRequest",
    modelProperties: {
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Boolean"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      nextRepaymentDate: {
        serializedName: "nextRepaymentDate",
        type: {
          name: "DateTime"
        }
      },
      otp: {
        serializedName: "otp",
        type: {
          name: "String"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "CommissionRequest"
        }
      }
    }
  }
};

export const WithdrawFixedRequest: msRest.CompositeMapper = {
  serializedName: "WithdrawFixedRequest",
  type: {
    name: "Composite",
    className: "WithdrawFixedRequest",
    modelProperties: {
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      term: {
        serializedName: "term",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Boolean"
        }
      },
      weeklyPayment: {
        serializedName: "weeklyPayment",
        type: {
          name: "Number"
        }
      },
      totalRepaymentAmount: {
        serializedName: "totalRepaymentAmount",
        type: {
          name: "Number"
        }
      },
      nextRepaymentDate: {
        serializedName: "nextRepaymentDate",
        type: {
          name: "DateTime"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      otp: {
        serializedName: "otp",
        type: {
          name: "String"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "CommissionRequest"
        }
      }
    }
  }
};

export const WithdrawLineOfCreditRequest: msRest.CompositeMapper = {
  serializedName: "WithdrawLineOfCreditRequest",
  type: {
    name: "Composite",
    className: "WithdrawLineOfCreditRequest",
    modelProperties: {
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Boolean"
        }
      },
      percentageOfIncome: {
        serializedName: "percentageOfIncome",
        type: {
          name: "Number"
        }
      },
      weeklyPayment: {
        serializedName: "weeklyPayment",
        type: {
          name: "Number"
        }
      },
      totalRepaymentAmount: {
        serializedName: "totalRepaymentAmount",
        type: {
          name: "Number"
        }
      },
      nextRepaymentDate: {
        serializedName: "nextRepaymentDate",
        type: {
          name: "DateTime"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      otp: {
        serializedName: "otp",
        type: {
          name: "String"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "CommissionRequest"
        }
      },
      fixed: {
        serializedName: "fixed",
        type: {
          name: "Boolean"
        }
      },
      terms: {
        serializedName: "terms",
        type: {
          name: "Number"
        }
      },
      fixedRepaymentFee: {
        serializedName: "fixedRepaymentFee",
        type: {
          name: "Number"
        }
      },
      fixedRepayment: {
        serializedName: "fixedRepayment",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WithdrawCreditCardRequest: msRest.CompositeMapper = {
  serializedName: "WithdrawCreditCardRequest",
  type: {
    name: "Composite",
    className: "WithdrawCreditCardRequest",
    modelProperties: {
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Boolean"
        }
      },
      percentageOfIncome: {
        serializedName: "percentageOfIncome",
        type: {
          name: "Number"
        }
      },
      weeklyPayment: {
        serializedName: "weeklyPayment",
        type: {
          name: "Number"
        }
      },
      totalRepaymentAmount: {
        serializedName: "totalRepaymentAmount",
        type: {
          name: "Number"
        }
      },
      nextRepaymentDate: {
        serializedName: "nextRepaymentDate",
        type: {
          name: "DateTime"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      otp: {
        serializedName: "otp",
        type: {
          name: "String"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "CommissionRequest"
        }
      },
      fixed: {
        serializedName: "fixed",
        type: {
          name: "Boolean"
        }
      },
      terms: {
        serializedName: "terms",
        type: {
          name: "Number"
        }
      },
      fixedRepaymentFee: {
        serializedName: "fixedRepaymentFee",
        type: {
          name: "Number"
        }
      },
      fixedRepayment: {
        serializedName: "fixedRepayment",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const RepaymentAdjustmentProjection: msRest.CompositeMapper = {
  serializedName: "RepaymentAdjustmentProjection",
  type: {
    name: "Composite",
    className: "RepaymentAdjustmentProjection",
    modelProperties: {
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      advanceId: {
        serializedName: "advanceId",
        type: {
          name: "Uuid"
        }
      },
      adjustmentType: {
        serializedName: "adjustmentType",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      transactionDateUTC: {
        serializedName: "transactionDateUTC",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const CommissionSet: msRest.CompositeMapper = {
  serializedName: "CommissionSet",
  type: {
    name: "Composite",
    className: "CommissionSet",
    modelProperties: {
      nonSecured: {
        serializedName: "nonSecured",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      lineOfCredit: {
        serializedName: "lineOfCredit",
        type: {
          name: "Composite",
          className: "Commission"
        }
      }
    }
  }
};

export const BankDetails: msRest.CompositeMapper = {
  serializedName: "BankDetails",
  type: {
    name: "Composite",
    className: "BankDetails",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      bsb: {
        serializedName: "bsb",
        type: {
          name: "String"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Notification: msRest.CompositeMapper = {
  serializedName: "Notification",
  type: {
    name: "Composite",
    className: "Notification",
    modelProperties: {
      createdOnUtc: {
        serializedName: "createdOnUtc",
        type: {
          name: "DateTime"
        }
      },
      message: {
        required: true,
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      read: {
        serializedName: "read",
        type: {
          name: "Boolean"
        }
      },
      readOnUtc: {
        serializedName: "readOnUtc",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PaymentDetails: msRest.CompositeMapper = {
  serializedName: "PaymentDetails",
  type: {
    name: "Composite",
    className: "PaymentDetails",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      paymentId: {
        serializedName: "paymentId",
        type: {
          name: "Uuid"
        }
      },
      syntheticPaymentId: {
        serializedName: "syntheticPaymentId",
        type: {
          name: "Uuid"
        }
      },
      paymentQueryId: {
        serializedName: "paymentQueryId",
        type: {
          name: "Uuid"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      transactionId: {
        serializedName: "transactionId",
        type: {
          name: "Uuid"
        }
      },
      paymentDate: {
        serializedName: "paymentDate",
        type: {
          name: "DateTime"
        }
      },
      paymentAmount: {
        serializedName: "paymentAmount",
        type: {
          name: "Number"
        }
      },
      paymentReference: {
        serializedName: "paymentReference",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ReconciliationDetails: msRest.CompositeMapper = {
  serializedName: "ReconciliationDetails",
  type: {
    name: "Composite",
    className: "ReconciliationDetails",
    modelProperties: {
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      transactionId: {
        serializedName: "transactionId",
        type: {
          name: "Uuid"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      account: {
        serializedName: "account",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PartnerCommission: msRest.CompositeMapper = {
  serializedName: "PartnerCommission",
  type: {
    name: "Composite",
    className: "PartnerCommission",
    modelProperties: {
      unsecuredTrail: {
        serializedName: "unsecuredTrail",
        type: {
          name: "Number"
        }
      },
      securedTrail: {
        serializedName: "securedTrail",
        type: {
          name: "Number"
        }
      },
      locTrail: {
        serializedName: "locTrail",
        type: {
          name: "Number"
        }
      },
      upFrontFee: {
        serializedName: "upFrontFee",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Partner: msRest.CompositeMapper = {
  serializedName: "Partner",
  type: {
    name: "Composite",
    className: "Partner",
    modelProperties: {
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      mobilePhoneNumber: {
        serializedName: "mobilePhoneNumber",
        type: {
          name: "String"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "PartnerCommission"
        }
      },
      urlReferral: {
        serializedName: "urlReferral",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RegisterPartnerRequest: msRest.CompositeMapper = {
  serializedName: "RegisterPartnerRequest",
  type: {
    name: "Composite",
    className: "RegisterPartnerRequest",
    modelProperties: {
      partner: {
        serializedName: "partner",
        type: {
          name: "Composite",
          className: "Partner"
        }
      }
    }
  }
};

export const PartnerSendLinkRequest: msRest.CompositeMapper = {
  serializedName: "PartnerSendLinkRequest",
  type: {
    name: "Composite",
    className: "PartnerSendLinkRequest",
    modelProperties: {
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      ein: {
        serializedName: "ein",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PartnerOwnerDetails: msRest.CompositeMapper = {
  serializedName: "PartnerOwnerDetails",
  type: {
    name: "Composite",
    className: "PartnerOwnerDetails",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      preferredName: {
        serializedName: "preferredName",
        type: {
          name: "String"
        }
      },
      lastname: {
        serializedName: "lastname",
        type: {
          name: "String"
        }
      },
      cellNumber: {
        serializedName: "cellNumber",
        type: {
          name: "String"
        }
      },
      officeNumber: {
        serializedName: "officeNumber",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      driversLicenceNumber: {
        serializedName: "driversLicenceNumber",
        type: {
          name: "String"
        }
      },
      stateIssued: {
        serializedName: "stateIssued",
        type: {
          name: "String"
        }
      },
      ssn: {
        serializedName: "ssn",
        type: {
          name: "String"
        }
      },
      dob: {
        serializedName: "dob",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PartnerBusinessDetails: msRest.CompositeMapper = {
  serializedName: "PartnerBusinessDetails",
  type: {
    name: "Composite",
    className: "PartnerBusinessDetails",
    modelProperties: {
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      companyAddress: {
        serializedName: "companyAddress",
        type: {
          name: "String"
        }
      },
      officeNumber: {
        serializedName: "officeNumber",
        type: {
          name: "String"
        }
      },
      webAddress: {
        serializedName: "webAddress",
        type: {
          name: "String"
        }
      },
      accountPayableEmail: {
        serializedName: "accountPayableEmail",
        type: {
          name: "String"
        }
      },
      optionFirst: {
        serializedName: "optionFirst",
        type: {
          name: "Boolean"
        }
      },
      optionSecond: {
        serializedName: "optionSecond",
        type: {
          name: "Boolean"
        }
      },
      optionThird: {
        serializedName: "optionThird",
        type: {
          name: "Boolean"
        }
      },
      optionFourth: {
        serializedName: "optionFourth",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PartnerAchDetail: msRest.CompositeMapper = {
  serializedName: "PartnerAchDetail",
  type: {
    name: "Composite",
    className: "PartnerAchDetail",
    modelProperties: {
      bankName: {
        serializedName: "bankName",
        type: {
          name: "String"
        }
      },
      accountType: {
        serializedName: "accountType",
        type: {
          name: "String"
        }
      },
      routingNumber: {
        serializedName: "routingNumber",
        type: {
          name: "String"
        }
      },
      accountNumber: {
        serializedName: "accountNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PartnerCompanyDetail: msRest.CompositeMapper = {
  serializedName: "PartnerCompanyDetail",
  type: {
    name: "Composite",
    className: "PartnerCompanyDetail",
    modelProperties: {
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      },
      signature: {
        serializedName: "signature",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PartnerAccreditationRequest: msRest.CompositeMapper = {
  serializedName: "PartnerAccreditationRequest",
  type: {
    name: "Composite",
    className: "PartnerAccreditationRequest",
    modelProperties: {
      ownerDetails: {
        serializedName: "ownerDetails",
        type: {
          name: "Composite",
          className: "PartnerOwnerDetails"
        }
      },
      businessDetails: {
        serializedName: "businessDetails",
        type: {
          name: "Composite",
          className: "PartnerBusinessDetails"
        }
      },
      achDetail: {
        serializedName: "achDetail",
        type: {
          name: "Composite",
          className: "PartnerAchDetail"
        }
      },
      companyDetail: {
        serializedName: "companyDetail",
        type: {
          name: "Composite",
          className: "PartnerCompanyDetail"
        }
      }
    }
  }
};

export const SiteDetails: msRest.CompositeMapper = {
  serializedName: "SiteDetails",
  type: {
    name: "Composite",
    className: "SiteDetails",
    modelProperties: {
      items: {
        serializedName: "items",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      urls: {
        serializedName: "urls",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
