<div class="au-section" *ngIf="urlService.checkUrlCom()">
  <section class="our-story">
    <div class="container our-story-container d-flex">
      <div>
        <h1 class="our-story__header white mb-60">Our story</h1>
        <h4>
          We’re revolutionising funding solutions for small to medium sized
          businesses
        </h4>
        <p>
          With our proprietary technology, businesses no longer require a range
          of complicated finance products to maximise their performance.
          Designed with simplicity in mind, Plexe replaces your credit cards,
          overdrafts, terms loans as well as invoice and trade finance
          facilities into one line of credit.
        </p>
        <p>
          Our goal is to provide small to medium sized businesses with access to
          the future of finance.
        </p>
      </div>
    </div>
  </section>
</div>

<div class="us-section" *ngIf="urlService.checkUrlCo()">
  <section class="our-story">
    <div class="container our-story-container d-flex">
      <div>
        <h1 class="our-story__header white mb-20">Our story</h1>
        <span class="header-divider"></span>
        <h4>
          We are changing the world for businesses by providing critical tools
          for success.
        </h4>
        <p>
          We have developed real time tools to enable businesses to compare
          their performance with actual companies and assist in improving their
          overall health.
        </p>
        <p>
          Our goal is to provide small to medium sized businesses with access to
          the future of finance.
        </p>
      </div>
    </div>
  </section>
</div>

<div id="Our_story" *ngIf="false" class="our-story-us">
  <svg class="quote_2">
    <rect
      id="quote_2"
      rx="0"
      ry="0"
      x="0"
      y="0"
      width="1920"
      height="995"
    ></rect>
  </svg>
  <svg class="quote_2_k">
    <rect
      id="quote_2_k"
      rx="0"
      ry="0"
      x="0"
      y="0"
      width="1920"
      height="995"
    ></rect>
  </svg>
  <img
    id="quote_2_l"
    src="assets/us-site/quote_2_l.png"
    srcset="assets/us-site/quote_2_l.png 1x, assets/us-site/quote_2_l@2x.png 2x"
  />

  <div id="With_are_a_technology_business">
    <span
      >With are a technology businesses that applies algorithms to modernized
      finance tools. Designed with simplicity in mind, all products and features
      reduce bookkeeping and administration to provide more time for businesses
      to focus on generating revenue. <br /><br />We have developed real time
      tools to enable businesses to compare their performance with actual
      companies and assist in improving their overall health. <br /><br />Our
      goal is to provide small to medium sized businesses with access to the
      future of finance.</span
    >
  </div>
  <div id="n_Our_story">
    <span><br />Our story</span>
  </div>
  <div id="We_are_changing_the_world_for_">
    <span
      >We are changing the world for businesses by providing critical tools for
      success.
    </span>
  </div>
  <svg class="Line_2" viewBox="0 0 200 10">
    <path id="Line_2" d="M 0 0 L 200 0"></path>
  </svg>
</div>
