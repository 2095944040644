/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';
import * as Models from './models';
import * as Mappers from './models/mappers';
import * as Parameters from './models/parameters';
import { ReportingServiceAPIContext } from './reportingServiceAPIContext';

class ReportingServiceAPI extends ReportingServiceAPIContext {
  /**
   * Initializes a new instance of the ReportingServiceAPI class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.ReportingServiceAPIOptions) {
    super(credentials, options);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActivitySummaryResponse>
   */
  getActivitySummary(
    options?: Models.ReportingServiceAPIGetActivitySummaryOptionalParams
  ): Promise<Models.GetActivitySummaryResponse>;
  /**
   * @param callback The callback
   */
  getActivitySummary(callback: msRest.ServiceCallback<Models.ActivitySummary[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getActivitySummary(
    options: Models.ReportingServiceAPIGetActivitySummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.ActivitySummary[]>
  ): void;
  getActivitySummary(
    options?:
      | Models.ReportingServiceAPIGetActivitySummaryOptionalParams
      | msRest.ServiceCallback<Models.ActivitySummary[]>,
    callback?: msRest.ServiceCallback<Models.ActivitySummary[]>
  ): Promise<Models.GetActivitySummaryResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getActivitySummaryOperationSpec,
      callback
    ) as Promise<Models.GetActivitySummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetActivitySummaryConsolidatedResponse>
   */
  getActivitySummaryConsolidated(
    options?: Models.ReportingServiceAPIGetActivitySummaryConsolidatedOptionalParams
  ): Promise<Models.GetActivitySummaryConsolidatedResponse>;
  /**
   * @param callback The callback
   */
  getActivitySummaryConsolidated(callback: msRest.ServiceCallback<Models.ActivitySummary>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getActivitySummaryConsolidated(
    options: Models.ReportingServiceAPIGetActivitySummaryConsolidatedOptionalParams,
    callback: msRest.ServiceCallback<Models.ActivitySummary>
  ): void;
  getActivitySummaryConsolidated(
    options?:
      | Models.ReportingServiceAPIGetActivitySummaryConsolidatedOptionalParams
      | msRest.ServiceCallback<Models.ActivitySummary>,
    callback?: msRest.ServiceCallback<Models.ActivitySummary>
  ): Promise<Models.GetActivitySummaryConsolidatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getActivitySummaryConsolidatedOperationSpec,
      callback
    ) as Promise<Models.GetActivitySummaryConsolidatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPricingReportResponse>
   */
  getPricingReport(options?: msRest.RequestOptionsBase): Promise<Models.GetPricingReportResponse>;
  /**
   * @param callback The callback
   */
  getPricingReport(callback: msRest.ServiceCallback<Models.PricingData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPricingReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PricingData[]>): void;
  getPricingReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PricingData[]>,
    callback?: msRest.ServiceCallback<Models.PricingData[]>
  ): Promise<Models.GetPricingReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPricingReportOperationSpec,
      callback
    ) as Promise<Models.GetPricingReportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPricingReportCSVResponse>
   */
  getPricingReportCSV(options?: msRest.RequestOptionsBase): Promise<Models.GetPricingReportCSVResponse>;
  /**
   * @param callback The callback
   */
  getPricingReportCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPricingReportCSV(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getPricingReportCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetPricingReportCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPricingReportCSVOperationSpec,
      callback
    ) as Promise<Models.GetPricingReportCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAutomatedDecisionReportResponse>
   */
  getAutomatedDecisionReport(
    options?: Models.ReportingServiceAPIGetAutomatedDecisionReportOptionalParams
  ): Promise<Models.GetAutomatedDecisionReportResponse>;
  /**
   * @param callback The callback
   */
  getAutomatedDecisionReport(callback: msRest.ServiceCallback<Models.AutomatedDecisionData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAutomatedDecisionReport(
    options: Models.ReportingServiceAPIGetAutomatedDecisionReportOptionalParams,
    callback: msRest.ServiceCallback<Models.AutomatedDecisionData[]>
  ): void;
  getAutomatedDecisionReport(
    options?:
      | Models.ReportingServiceAPIGetAutomatedDecisionReportOptionalParams
      | msRest.ServiceCallback<Models.AutomatedDecisionData[]>,
    callback?: msRest.ServiceCallback<Models.AutomatedDecisionData[]>
  ): Promise<Models.GetAutomatedDecisionReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAutomatedDecisionReportOperationSpec,
      callback
    ) as Promise<Models.GetAutomatedDecisionReportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAutomatedDecisionStatisticsReportResponse>
   */
  getAutomatedDecisionStatisticsReport(
    options?: Models.ReportingServiceAPIGetAutomatedDecisionStatisticsReportOptionalParams
  ): Promise<Models.GetAutomatedDecisionStatisticsReportResponse>;
  /**
   * @param callback The callback
   */
  getAutomatedDecisionStatisticsReport(callback: msRest.ServiceCallback<Models.AutomatedDecisionStatistics>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAutomatedDecisionStatisticsReport(
    options: Models.ReportingServiceAPIGetAutomatedDecisionStatisticsReportOptionalParams,
    callback: msRest.ServiceCallback<Models.AutomatedDecisionStatistics>
  ): void;
  getAutomatedDecisionStatisticsReport(
    options?:
      | Models.ReportingServiceAPIGetAutomatedDecisionStatisticsReportOptionalParams
      | msRest.ServiceCallback<Models.AutomatedDecisionStatistics>,
    callback?: msRest.ServiceCallback<Models.AutomatedDecisionStatistics>
  ): Promise<Models.GetAutomatedDecisionStatisticsReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAutomatedDecisionStatisticsReportOperationSpec,
      callback
    ) as Promise<Models.GetAutomatedDecisionStatisticsReportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomerReportResponse>
   */
  getCustomerReport(
    options?: Models.ReportingServiceAPIGetCustomerReportOptionalParams
  ): Promise<Models.GetCustomerReportResponse>;
  /**
   * @param callback The callback
   */
  getCustomerReport(callback: msRest.ServiceCallback<Models.CustomerData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomerReport(
    options: Models.ReportingServiceAPIGetCustomerReportOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerData[]>
  ): void;
  getCustomerReport(
    options?: Models.ReportingServiceAPIGetCustomerReportOptionalParams | msRest.ServiceCallback<Models.CustomerData[]>,
    callback?: msRest.ServiceCallback<Models.CustomerData[]>
  ): Promise<Models.GetCustomerReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCustomerReportOperationSpec,
      callback
    ) as Promise<Models.GetCustomerReportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetArrearsReportResponse>
   */
  getArrearsReport(
    options?: Models.ReportingServiceAPIGetArrearsReportOptionalParams
  ): Promise<Models.GetArrearsReportResponse>;
  /**
   * @param callback The callback
   */
  getArrearsReport(callback: msRest.ServiceCallback<Models.ArrearsReportData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getArrearsReport(
    options: Models.ReportingServiceAPIGetArrearsReportOptionalParams,
    callback: msRest.ServiceCallback<Models.ArrearsReportData[]>
  ): void;
  getArrearsReport(
    options?:
      | Models.ReportingServiceAPIGetArrearsReportOptionalParams
      | msRest.ServiceCallback<Models.ArrearsReportData[]>,
    callback?: msRest.ServiceCallback<Models.ArrearsReportData[]>
  ): Promise<Models.GetArrearsReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getArrearsReportOperationSpec,
      callback
    ) as Promise<Models.GetArrearsReportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetArrearsPercentageResponse>
   */
  getArrearsPercentage(
    options?: Models.ReportingServiceAPIGetArrearsPercentageOptionalParams
  ): Promise<Models.GetArrearsPercentageResponse>;
  /**
   * @param callback The callback
   */
  getArrearsPercentage(callback: msRest.ServiceCallback<number>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getArrearsPercentage(
    options: Models.ReportingServiceAPIGetArrearsPercentageOptionalParams,
    callback: msRest.ServiceCallback<number>
  ): void;
  getArrearsPercentage(
    options?: Models.ReportingServiceAPIGetArrearsPercentageOptionalParams | msRest.ServiceCallback<number>,
    callback?: msRest.ServiceCallback<number>
  ): Promise<Models.GetArrearsPercentageResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getArrearsPercentageOperationSpec,
      callback
    ) as Promise<Models.GetArrearsPercentageResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetArrearsReportCSVResponse>
   */
  getArrearsReportCSV(
    options?: Models.ReportingServiceAPIGetArrearsReportCSVOptionalParams
  ): Promise<Models.GetArrearsReportCSVResponse>;
  /**
   * @param callback The callback
   */
  getArrearsReportCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getArrearsReportCSV(
    options: Models.ReportingServiceAPIGetArrearsReportCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getArrearsReportCSV(
    options?:
      | Models.ReportingServiceAPIGetArrearsReportCSVOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetArrearsReportCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getArrearsReportCSVOperationSpec,
      callback
    ) as Promise<Models.GetArrearsReportCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getArrearsReportCSVLocal(
    options?: Models.ReportingServiceAPIGetArrearsReportCSVLocalOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  getArrearsReportCSVLocal(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getArrearsReportCSVLocal(
    options: Models.ReportingServiceAPIGetArrearsReportCSVLocalOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  getArrearsReportCSVLocal(
    options?: Models.ReportingServiceAPIGetArrearsReportCSVLocalOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getArrearsReportCSVLocalOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getArrearsReportCSVLocalQuarterly(
    options?: Models.ReportingServiceAPIGetArrearsReportCSVLocalQuarterlyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  getArrearsReportCSVLocalQuarterly(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getArrearsReportCSVLocalQuarterly(
    options: Models.ReportingServiceAPIGetArrearsReportCSVLocalQuarterlyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  getArrearsReportCSVLocalQuarterly(
    options?: Models.ReportingServiceAPIGetArrearsReportCSVLocalQuarterlyOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getArrearsReportCSVLocalQuarterlyOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllInvoicesCSVResponse>
   */
  getAllInvoicesCSV(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAllInvoicesCSVResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllInvoicesCSV(loanId: string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllInvoicesCSV(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getAllInvoicesCSV(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetAllInvoicesCSVResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllInvoicesCSVOperationSpec,
      callback
    ) as Promise<Models.GetAllInvoicesCSVResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProcessedInvoicesCSVResponse>
   */
  getProcessedInvoicesCSV(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetProcessedInvoicesCSVResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getProcessedInvoicesCSV(loanId: string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getProcessedInvoicesCSV(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getProcessedInvoicesCSV(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetProcessedInvoicesCSVResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getProcessedInvoicesCSVOperationSpec,
      callback
    ) as Promise<Models.GetProcessedInvoicesCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllClosingBalancesResponse>
   */
  getAllClosingBalances(options?: msRest.RequestOptionsBase): Promise<Models.GetAllClosingBalancesResponse>;
  /**
   * @param callback The callback
   */
  getAllClosingBalances(callback: msRest.ServiceCallback<Models.ClosingBalance[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllClosingBalances(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ClosingBalance[]>
  ): void;
  getAllClosingBalances(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ClosingBalance[]>,
    callback?: msRest.ServiceCallback<Models.ClosingBalance[]>
  ): Promise<Models.GetAllClosingBalancesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllClosingBalancesOperationSpec,
      callback
    ) as Promise<Models.GetAllClosingBalancesResponse>;
  }

  /**
   * @param metricType
   * @param [options] The optional parameters
   * @returns Promise<Models.GetMetricsResponse>
   */
  getMetrics(metricType: string, options?: msRest.RequestOptionsBase): Promise<Models.GetMetricsResponse>;
  /**
   * @param metricType
   * @param callback The callback
   */
  getMetrics(metricType: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param metricType
   * @param options The optional parameters
   * @param callback The callback
   */
  getMetrics(metricType: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getMetrics(
    metricType: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetMetricsResponse> {
    return this.sendOperationRequest(
      {
        metricType,
        options
      },
      getMetricsOperationSpec,
      callback
    ) as Promise<Models.GetMetricsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDilutionsResponse>
   */
  getDilutions(organisationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetDilutionsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getDilutions(organisationId: string, callback: msRest.ServiceCallback<Models.Dilution[]>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getDilutions(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.Dilution[]>
  ): void;
  getDilutions(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Dilution[]>,
    callback?: msRest.ServiceCallback<Models.Dilution[]>
  ): Promise<Models.GetDilutionsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getDilutionsOperationSpec,
      callback
    ) as Promise<Models.GetDilutionsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllInvoiceLoansResponse>
   */
  getAllInvoiceLoans(options?: msRest.RequestOptionsBase): Promise<Models.GetAllInvoiceLoansResponse>;
  /**
   * @param callback The callback
   */
  getAllInvoiceLoans(callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllInvoiceLoans(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): void;
  getAllInvoiceLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: string }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): Promise<Models.GetAllInvoiceLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllInvoiceLoansOperationSpec,
      callback
    ) as Promise<Models.GetAllInvoiceLoansResponse>;
  }

  /**
   * @param loanId
   * @param startDate
   * @param endDate
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDriftMetricsResponse>
   */
  getDriftMetrics(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetDriftMetricsResponse>;
  /**
   * @param loanId
   * @param startDate
   * @param endDate
   * @param callback The callback
   */
  getDriftMetrics(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    callback: msRest.ServiceCallback<Models.DriftMetricList>
  ): void;
  /**
   * @param loanId
   * @param startDate
   * @param endDate
   * @param options The optional parameters
   * @param callback The callback
   */
  getDriftMetrics(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.DriftMetricList>
  ): void;
  getDriftMetrics(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DriftMetricList>,
    callback?: msRest.ServiceCallback<Models.DriftMetricList>
  ): Promise<Models.GetDriftMetricsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        startDate,
        endDate,
        options
      },
      getDriftMetricsOperationSpec,
      callback
    ) as Promise<Models.GetDriftMetricsResponse>;
  }

  /**
   * @param startDate
   * @param endDate
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllDriftMetricsResponse>
   */
  getAllDriftMetrics(
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllDriftMetricsResponse>;
  /**
   * @param startDate
   * @param endDate
   * @param callback The callback
   */
  getAllDriftMetrics(
    startDate: Date | string,
    endDate: Date | string,
    callback: msRest.ServiceCallback<Models.DriftMetricList>
  ): void;
  /**
   * @param startDate
   * @param endDate
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllDriftMetrics(
    startDate: Date | string,
    endDate: Date | string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.DriftMetricList>
  ): void;
  getAllDriftMetrics(
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DriftMetricList>,
    callback?: msRest.ServiceCallback<Models.DriftMetricList>
  ): Promise<Models.GetAllDriftMetricsResponse> {
    return this.sendOperationRequest(
      {
        startDate,
        endDate,
        options
      },
      getAllDriftMetricsOperationSpec,
      callback
    ) as Promise<Models.GetAllDriftMetricsResponse>;
  }

  /**
   * @param loanId
   * @param startDate
   * @param endDate
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDriftMetricsCSVResponse>
   */
  getDriftMetricsCSV(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetDriftMetricsCSVResponse>;
  /**
   * @param loanId
   * @param startDate
   * @param endDate
   * @param callback The callback
   */
  getDriftMetricsCSV(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  /**
   * @param loanId
   * @param startDate
   * @param endDate
   * @param options The optional parameters
   * @param callback The callback
   */
  getDriftMetricsCSV(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getDriftMetricsCSV(
    loanId: string,
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetDriftMetricsCSVResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        startDate,
        endDate,
        options
      },
      getDriftMetricsCSVOperationSpec,
      callback
    ) as Promise<Models.GetDriftMetricsCSVResponse>;
  }

  /**
   * @param startDate
   * @param endDate
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllDriftMetricsCSVResponse>
   */
  getAllDriftMetricsCSV(
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllDriftMetricsCSVResponse>;
  /**
   * @param startDate
   * @param endDate
   * @param callback The callback
   */
  getAllDriftMetricsCSV(
    startDate: Date | string,
    endDate: Date | string,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  /**
   * @param startDate
   * @param endDate
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllDriftMetricsCSV(
    startDate: Date | string,
    endDate: Date | string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getAllDriftMetricsCSV(
    startDate: Date | string,
    endDate: Date | string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetAllDriftMetricsCSVResponse> {
    return this.sendOperationRequest(
      {
        startDate,
        endDate,
        options
      },
      getAllDriftMetricsCSVOperationSpec,
      callback
    ) as Promise<Models.GetAllDriftMetricsCSVResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllDebtorDaysResponse>
   */
  getAllDebtorDays(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllDebtorDaysResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getAllDebtorDays(organisationId: string, callback: msRest.ServiceCallback<Models.DebtorDayValue[]>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllDebtorDays(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.DebtorDayValue[]>
  ): void;
  getAllDebtorDays(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.DebtorDayValue[]>,
    callback?: msRest.ServiceCallback<Models.DebtorDayValue[]>
  ): Promise<Models.GetAllDebtorDaysResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getAllDebtorDaysOperationSpec,
      callback
    ) as Promise<Models.GetAllDebtorDaysResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllDebtorsResponse>
   */
  getAllDebtors(options?: msRest.RequestOptionsBase): Promise<Models.GetAllDebtorsResponse>;
  /**
   * @param callback The callback
   */
  getAllDebtors(callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllDebtors(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): void;
  getAllDebtors(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: string }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): Promise<Models.GetAllDebtorsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllDebtorsOperationSpec,
      callback
    ) as Promise<Models.GetAllDebtorsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  displayMessage(options?: Models.ReportingServiceAPIDisplayMessageOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  displayMessage(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  displayMessage(
    options: Models.ReportingServiceAPIDisplayMessageOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  displayMessage(
    options?: Models.ReportingServiceAPIDisplayMessageOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      displayMessageOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  newAlert(options?: Models.ReportingServiceAPINewAlertOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  newAlert(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  newAlert(options: Models.ReportingServiceAPINewAlertOptionalParams, callback: msRest.ServiceCallback<void>): void;
  newAlert(
    options?: Models.ReportingServiceAPINewAlertOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      newAlertOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  offerUpdated(options?: Models.ReportingServiceAPIOfferUpdatedOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  offerUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  offerUpdated(
    options: Models.ReportingServiceAPIOfferUpdatedOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  offerUpdated(
    options?: Models.ReportingServiceAPIOfferUpdatedOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      offerUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  portfolioUpdated(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  portfolioUpdated(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  portfolioUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  portfolioUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      portfolioUpdatedOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetConnectionReportResponse>
   */
  getConnectionReport(options?: msRest.RequestOptionsBase): Promise<Models.GetConnectionReportResponse>;
  /**
   * @param callback The callback
   */
  getConnectionReport(callback: msRest.ServiceCallback<Models.ConnectionStatus[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getConnectionReport(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ConnectionStatus[]>
  ): void;
  getConnectionReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ConnectionStatus[]>,
    callback?: msRest.ServiceCallback<Models.ConnectionStatus[]>
  ): Promise<Models.GetConnectionReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getConnectionReportOperationSpec,
      callback
    ) as Promise<Models.GetConnectionReportResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAverageDailyARCSVResponse>
   */
  getAverageDailyARCSV(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAverageDailyARCSVResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAverageDailyARCSV(loanId: string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAverageDailyARCSV(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getAverageDailyARCSV(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetAverageDailyARCSVResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAverageDailyARCSVOperationSpec,
      callback
    ) as Promise<Models.GetAverageDailyARCSVResponse>;
  }

  /**
   * @param loanId
   * @param dateTime
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOpenARCSVResponse>
   */
  getOpenARCSV(
    loanId: string,
    dateTime: Date | string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetOpenARCSVResponse>;
  /**
   * @param loanId
   * @param dateTime
   * @param callback The callback
   */
  getOpenARCSV(loanId: string, dateTime: Date | string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param loanId
   * @param dateTime
   * @param options The optional parameters
   * @param callback The callback
   */
  getOpenARCSV(
    loanId: string,
    dateTime: Date | string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getOpenARCSV(
    loanId: string,
    dateTime: Date | string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetOpenARCSVResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        dateTime,
        options
      },
      getOpenARCSVOperationSpec,
      callback
    ) as Promise<Models.GetOpenARCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetProvisoReportResponse>
   */
  getProvisoReport(options?: msRest.RequestOptionsBase): Promise<Models.GetProvisoReportResponse>;
  /**
   * @param callback The callback
   */
  getProvisoReport(callback: msRest.ServiceCallback<Models.ProvisoData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getProvisoReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ProvisoData[]>): void;
  getProvisoReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProvisoData[]>,
    callback?: msRest.ServiceCallback<Models.ProvisoData[]>
  ): Promise<Models.GetProvisoReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getProvisoReportOperationSpec,
      callback
    ) as Promise<Models.GetProvisoReportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBalancesReportResponse>
   */
  getBalancesReport(options?: msRest.RequestOptionsBase): Promise<Models.GetBalancesReportResponse>;
  /**
   * @param callback The callback
   */
  getBalancesReport(callback: msRest.ServiceCallback<{ [propertyName: string]: Models.LoanBalances }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBalancesReport(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: Models.LoanBalances }>
  ): void;
  getBalancesReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: Models.LoanBalances }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: Models.LoanBalances }>
  ): Promise<Models.GetBalancesReportResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBalancesReportOperationSpec,
      callback
    ) as Promise<Models.GetBalancesReportResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBalancesReportCSVResponse>
   */
  getBalancesReportCSV(options?: msRest.RequestOptionsBase): Promise<Models.GetBalancesReportCSVResponse>;
  /**
   * @param callback The callback
   */
  getBalancesReportCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBalancesReportCSV(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getBalancesReportCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetBalancesReportCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBalancesReportCSVOperationSpec,
      callback
    ) as Promise<Models.GetBalancesReportCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllApplicationsResponse>
   */
  getAllApplications(options?: msRest.RequestOptionsBase): Promise<Models.GetAllApplicationsResponse>;
  /**
   * @param callback The callback
   */
  getAllApplications(callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllApplications(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): void;
  getAllApplications(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: string }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): Promise<Models.GetAllApplicationsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllApplicationsOperationSpec,
      callback
    ) as Promise<Models.GetAllApplicationsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationEventsResponse>
   */
  getApplicationEvents(
    applicationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetApplicationEventsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicationEvents(applicationId: string, callback: msRest.ServiceCallback<Models.ApplicationEvent[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationEvents(
    applicationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationEvent[]>
  ): void;
  getApplicationEvents(
    applicationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationEvent[]>,
    callback?: msRest.ServiceCallback<Models.ApplicationEvent[]>
  ): Promise<Models.GetApplicationEventsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationEventsOperationSpec,
      callback
    ) as Promise<Models.GetApplicationEventsResponse>;
  }

  /**
   * @param loanId
   * @param skip
   * @param take
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanEventsResponse>
   */
  getLoanEvents(
    loanId: string,
    skip: number,
    take: number,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetLoanEventsResponse>;
  /**
   * @param loanId
   * @param skip
   * @param take
   * @param callback The callback
   */
  getLoanEvents(loanId: string, skip: number, take: number, callback: msRest.ServiceCallback<Models.LoanEvent[]>): void;
  /**
   * @param loanId
   * @param skip
   * @param take
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanEvents(
    loanId: string,
    skip: number,
    take: number,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.LoanEvent[]>
  ): void;
  getLoanEvents(
    loanId: string,
    skip: number,
    take: number,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LoanEvent[]>,
    callback?: msRest.ServiceCallback<Models.LoanEvent[]>
  ): Promise<Models.GetLoanEventsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        skip,
        take,
        options
      },
      getLoanEventsOperationSpec,
      callback
    ) as Promise<Models.GetLoanEventsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanEventsCSVResponse>
   */
  getLoanEventsCSV(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanEventsCSVResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanEventsCSV(loanId: string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanEventsCSV(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getLoanEventsCSV(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetLoanEventsCSVResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanEventsCSVOperationSpec,
      callback
    ) as Promise<Models.GetLoanEventsCSVResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getLoanEventsCSVLocal(
    loanId: string,
    options?: Models.ReportingServiceAPIGetLoanEventsCSVLocalOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanEventsCSVLocal(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanEventsCSVLocal(
    loanId: string,
    options: Models.ReportingServiceAPIGetLoanEventsCSVLocalOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  getLoanEventsCSVLocal(
    loanId: string,
    options?: Models.ReportingServiceAPIGetLoanEventsCSVLocalOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanEventsCSVLocalOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLastApplicationsActivitiesResponse>
   */
  getLastApplicationsActivities(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetLastApplicationsActivitiesResponse>;
  /**
   * @param callback The callback
   */
  getLastApplicationsActivities(callback: msRest.ServiceCallback<Models.ApplicationAcitvityState[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLastApplicationsActivities(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.ApplicationAcitvityState[]>
  ): void;
  getLastApplicationsActivities(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationAcitvityState[]>,
    callback?: msRest.ServiceCallback<Models.ApplicationAcitvityState[]>
  ): Promise<Models.GetLastApplicationsActivitiesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLastApplicationsActivitiesOperationSpec,
      callback
    ) as Promise<Models.GetLastApplicationsActivitiesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllAuditNotesResponse>
   */
  getAllAuditNotes(options?: msRest.RequestOptionsBase): Promise<Models.GetAllAuditNotesResponse>;
  /**
   * @param callback The callback
   */
  getAllAuditNotes(callback: msRest.ServiceCallback<Models.AuditData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllAuditNotes(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AuditData[]>): void;
  getAllAuditNotes(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AuditData[]>,
    callback?: msRest.ServiceCallback<Models.AuditData[]>
  ): Promise<Models.GetAllAuditNotesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllAuditNotesOperationSpec,
      callback
    ) as Promise<Models.GetAllAuditNotesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllTransactionsResponse>
   */
  getAllTransactions(
    options?: Models.ReportingServiceAPIGetAllTransactionsOptionalParams
  ): Promise<Models.GetAllTransactionsResponse>;
  /**
   * @param callback The callback
   */
  getAllTransactions(callback: msRest.ServiceCallback<Models.TransactionDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllTransactions(
    options: Models.ReportingServiceAPIGetAllTransactionsOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDetails[]>
  ): void;
  getAllTransactions(
    options?:
      | Models.ReportingServiceAPIGetAllTransactionsOptionalParams
      | msRest.ServiceCallback<Models.TransactionDetails[]>,
    callback?: msRest.ServiceCallback<Models.TransactionDetails[]>
  ): Promise<Models.GetAllTransactionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllTransactionsOperationSpec,
      callback
    ) as Promise<Models.GetAllTransactionsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllTransactionsCSVResponse>
   */
  getAllTransactionsCSV(
    options?: Models.ReportingServiceAPIGetAllTransactionsCSVOptionalParams
  ): Promise<Models.GetAllTransactionsCSVResponse>;
  /**
   * @param callback The callback
   */
  getAllTransactionsCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllTransactionsCSV(
    options: Models.ReportingServiceAPIGetAllTransactionsCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getAllTransactionsCSV(
    options?:
      | Models.ReportingServiceAPIGetAllTransactionsCSVOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetAllTransactionsCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllTransactionsCSVOperationSpec,
      callback
    ) as Promise<Models.GetAllTransactionsCSVResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllDebtorDaysCSVResponse>
   */
  getAllDebtorDaysCSV(
    organisationId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetAllDebtorDaysCSVResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getAllDebtorDaysCSV(organisationId: string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllDebtorDaysCSV(
    organisationId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getAllDebtorDaysCSV(
    organisationId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetAllDebtorDaysCSVResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getAllDebtorDaysCSVOperationSpec,
      callback
    ) as Promise<Models.GetAllDebtorDaysCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLastLoansActivitiesResponse>
   */
  getLastLoansActivities(options?: msRest.RequestOptionsBase): Promise<Models.GetLastLoansActivitiesResponse>;
  /**
   * @param callback The callback
   */
  getLastLoansActivities(callback: msRest.ServiceCallback<Models.LoanAcitvityState[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLastLoansActivities(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.LoanAcitvityState[]>
  ): void;
  getLastLoansActivities(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LoanAcitvityState[]>,
    callback?: msRest.ServiceCallback<Models.LoanAcitvityState[]>
  ): Promise<Models.GetLastLoansActivitiesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLastLoansActivitiesOperationSpec,
      callback
    ) as Promise<Models.GetLastLoansActivitiesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBalanceDataResponse>
   */
  getBalanceData(options?: msRest.RequestOptionsBase): Promise<Models.GetBalanceDataResponse>;
  /**
   * @param callback The callback
   */
  getBalanceData(callback: msRest.ServiceCallback<{ [propertyName: string]: Models.BalanceData }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBalanceData(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: Models.BalanceData }>
  ): void;
  getBalanceData(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: Models.BalanceData }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: Models.BalanceData }>
  ): Promise<Models.GetBalanceDataResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBalanceDataOperationSpec,
      callback
    ) as Promise<Models.GetBalanceDataResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoansResponse>
   */
  getAllLoans(options?: msRest.RequestOptionsBase): Promise<Models.GetAllLoansResponse>;
  /**
   * @param callback The callback
   */
  getAllLoans(callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoans(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): void;
  getAllLoans(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: string }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>
  ): Promise<Models.GetAllLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllLoansOperationSpec,
      callback
    ) as Promise<Models.GetAllLoansResponse>;
  }

  /**
   * @param metricName
   * @param weekly
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPortfolioMetricResponse>
   */
  getPortfolioMetric(
    metricName: string,
    weekly: boolean,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPortfolioMetricResponse>;
  /**
   * @param metricName
   * @param weekly
   * @param callback The callback
   */
  getPortfolioMetric(
    metricName: string,
    weekly: boolean,
    callback: msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>
  ): void;
  /**
   * @param metricName
   * @param weekly
   * @param options The optional parameters
   * @param callback The callback
   */
  getPortfolioMetric(
    metricName: string,
    weekly: boolean,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>
  ): void;
  getPortfolioMetric(
    metricName: string,
    weekly: boolean,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>,
    callback?: msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>
  ): Promise<Models.GetPortfolioMetricResponse> {
    return this.sendOperationRequest(
      {
        metricName,
        weekly,
        options
      },
      getPortfolioMetricOperationSpec,
      callback
    ) as Promise<Models.GetPortfolioMetricResponse>;
  }

  /**
   * @param metricName
   * @param weekly
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPortfolioMetricMovingAverageResponse>
   */
  getPortfolioMetricMovingAverage(
    metricName: string,
    weekly: boolean,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPortfolioMetricMovingAverageResponse>;
  /**
   * @param metricName
   * @param weekly
   * @param callback The callback
   */
  getPortfolioMetricMovingAverage(
    metricName: string,
    weekly: boolean,
    callback: msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>
  ): void;
  /**
   * @param metricName
   * @param weekly
   * @param options The optional parameters
   * @param callback The callback
   */
  getPortfolioMetricMovingAverage(
    metricName: string,
    weekly: boolean,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>
  ): void;
  getPortfolioMetricMovingAverage(
    metricName: string,
    weekly: boolean,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>,
    callback?: msRest.ServiceCallback<Models.PortfolioMetricPerDate[]>
  ): Promise<Models.GetPortfolioMetricMovingAverageResponse> {
    return this.sendOperationRequest(
      {
        metricName,
        weekly,
        options
      },
      getPortfolioMetricMovingAverageOperationSpec,
      callback
    ) as Promise<Models.GetPortfolioMetricMovingAverageResponse>;
  }

  /**
   * @param metricName
   * @param weekly
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPortfolioMetricCSVResponse>
   */
  getPortfolioMetricCSV(
    metricName: string,
    weekly: boolean,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetPortfolioMetricCSVResponse>;
  /**
   * @param metricName
   * @param weekly
   * @param callback The callback
   */
  getPortfolioMetricCSV(
    metricName: string,
    weekly: boolean,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  /**
   * @param metricName
   * @param weekly
   * @param options The optional parameters
   * @param callback The callback
   */
  getPortfolioMetricCSV(
    metricName: string,
    weekly: boolean,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getPortfolioMetricCSV(
    metricName: string,
    weekly: boolean,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetPortfolioMetricCSVResponse> {
    return this.sendOperationRequest(
      {
        metricName,
        weekly,
        options
      },
      getPortfolioMetricCSVOperationSpec,
      callback
    ) as Promise<Models.GetPortfolioMetricCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookResponse>
   */
  getLoanBook(options?: Models.ReportingServiceAPIGetLoanBookOptionalParams): Promise<Models.GetLoanBookResponse>;
  /**
   * @param callback The callback
   */
  getLoanBook(callback: msRest.ServiceCallback<Models.LoanBook[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBook(
    options: Models.ReportingServiceAPIGetLoanBookOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanBook[]>
  ): void;
  getLoanBook(
    options?: Models.ReportingServiceAPIGetLoanBookOptionalParams | msRest.ServiceCallback<Models.LoanBook[]>,
    callback?: msRest.ServiceCallback<Models.LoanBook[]>
  ): Promise<Models.GetLoanBookResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanLimitsResponse>
   */
  getLoanLimits(options?: msRest.RequestOptionsBase): Promise<Models.GetLoanLimitsResponse>;
  /**
   * @param callback The callback
   */
  getLoanLimits(callback: msRest.ServiceCallback<Models.OverrideValues[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanLimits(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.OverrideValues[]>): void;
  getLoanLimits(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.OverrideValues[]>,
    callback?: msRest.ServiceCallback<Models.OverrideValues[]>
  ): Promise<Models.GetLoanLimitsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanLimitsOperationSpec,
      callback
    ) as Promise<Models.GetLoanLimitsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanLimitsCSVResponse>
   */
  getLoanLimitsCSV(options?: msRest.RequestOptionsBase): Promise<Models.GetLoanLimitsCSVResponse>;
  /**
   * @param callback The callback
   */
  getLoanLimitsCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanLimitsCSV(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  getLoanLimitsCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetLoanLimitsCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanLimitsCSVOperationSpec,
      callback
    ) as Promise<Models.GetLoanLimitsCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getLoanLimitsCSVLocal(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  getLoanLimitsCSVLocal(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanLimitsCSVLocal(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  getLoanLimitsCSVLocal(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanLimitsCSVLocalOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookSummaryResponse>
   */
  getLoanBookSummary(
    options?: Models.ReportingServiceAPIGetLoanBookSummaryOptionalParams
  ): Promise<Models.GetLoanBookSummaryResponse>;
  /**
   * @param callback The callback
   */
  getLoanBookSummary(callback: msRest.ServiceCallback<Models.LoanBookSummary[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBookSummary(
    options: Models.ReportingServiceAPIGetLoanBookSummaryOptionalParams,
    callback: msRest.ServiceCallback<Models.LoanBookSummary[]>
  ): void;
  getLoanBookSummary(
    options?:
      | Models.ReportingServiceAPIGetLoanBookSummaryOptionalParams
      | msRest.ServiceCallback<Models.LoanBookSummary[]>,
    callback?: msRest.ServiceCallback<Models.LoanBookSummary[]>
  ): Promise<Models.GetLoanBookSummaryResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookSummaryOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookSummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRepaymentByProductResponse>
   */
  getRepaymentByProduct(
    options?: Models.ReportingServiceAPIGetRepaymentByProductOptionalParams
  ): Promise<Models.GetRepaymentByProductResponse>;
  /**
   * @param callback The callback
   */
  getRepaymentByProduct(callback: msRest.ServiceCallback<Models.Repayment[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRepaymentByProduct(
    options: Models.ReportingServiceAPIGetRepaymentByProductOptionalParams,
    callback: msRest.ServiceCallback<Models.Repayment[]>
  ): void;
  getRepaymentByProduct(
    options?:
      | Models.ReportingServiceAPIGetRepaymentByProductOptionalParams
      | msRest.ServiceCallback<Models.Repayment[]>,
    callback?: msRest.ServiceCallback<Models.Repayment[]>
  ): Promise<Models.GetRepaymentByProductResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRepaymentByProductOperationSpec,
      callback
    ) as Promise<Models.GetRepaymentByProductResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRepaymentResponse>
   */
  getRepayment(options?: Models.ReportingServiceAPIGetRepaymentOptionalParams): Promise<Models.GetRepaymentResponse>;
  /**
   * @param callback The callback
   */
  getRepayment(callback: msRest.ServiceCallback<Models.Repayment[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRepayment(
    options: Models.ReportingServiceAPIGetRepaymentOptionalParams,
    callback: msRest.ServiceCallback<Models.Repayment[]>
  ): void;
  getRepayment(
    options?: Models.ReportingServiceAPIGetRepaymentOptionalParams | msRest.ServiceCallback<Models.Repayment[]>,
    callback?: msRest.ServiceCallback<Models.Repayment[]>
  ): Promise<Models.GetRepaymentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRepaymentOperationSpec,
      callback
    ) as Promise<Models.GetRepaymentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRepaymentCSVResponse>
   */
  getRepaymentCSV(
    options?: Models.ReportingServiceAPIGetRepaymentCSVOptionalParams
  ): Promise<Models.GetRepaymentCSVResponse>;
  /**
   * @param callback The callback
   */
  getRepaymentCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRepaymentCSV(
    options: Models.ReportingServiceAPIGetRepaymentCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getRepaymentCSV(
    options?: Models.ReportingServiceAPIGetRepaymentCSVOptionalParams | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetRepaymentCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRepaymentCSVOperationSpec,
      callback
    ) as Promise<Models.GetRepaymentCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanBook(options?: Models.ReportingServiceAPIUpdateLoanBookOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateLoanBook(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanBook(
    options: Models.ReportingServiceAPIUpdateLoanBookOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateLoanBook(
    options?: Models.ReportingServiceAPIUpdateLoanBookOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateLoanBookOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookCSVResponse>
   */
  getLoanBookCSV(
    options?: Models.ReportingServiceAPIGetLoanBookCSVOptionalParams
  ): Promise<Models.GetLoanBookCSVResponse>;
  /**
   * @param callback The callback
   */
  getLoanBookCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBookCSV(
    options: Models.ReportingServiceAPIGetLoanBookCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getLoanBookCSV(
    options?: Models.ReportingServiceAPIGetLoanBookCSVOptionalParams | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetLoanBookCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookCSVOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookSummaryCSVResponse>
   */
  getLoanBookSummaryCSV(
    options?: Models.ReportingServiceAPIGetLoanBookSummaryCSVOptionalParams
  ): Promise<Models.GetLoanBookSummaryCSVResponse>;
  /**
   * @param callback The callback
   */
  getLoanBookSummaryCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBookSummaryCSV(
    options: Models.ReportingServiceAPIGetLoanBookSummaryCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getLoanBookSummaryCSV(
    options?:
      | Models.ReportingServiceAPIGetLoanBookSummaryCSVOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetLoanBookSummaryCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookSummaryCSVOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookSummaryCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookExcelResponse>
   */
  getLoanBookExcel(
    options?: Models.ReportingServiceAPIGetLoanBookExcelOptionalParams
  ): Promise<Models.GetLoanBookExcelResponse>;
  /**
   * @param callback The callback
   */
  getLoanBookExcel(callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBookExcel(
    options: Models.ReportingServiceAPIGetLoanBookExcelOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  getLoanBookExcel(
    options?:
      | Models.ReportingServiceAPIGetLoanBookExcelOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.GetLoanBookExcelResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookExcelOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookExcelResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  saveLoanBookExcel(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  saveLoanBookExcel(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  saveLoanBookExcel(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  saveLoanBookExcel(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      saveLoanBookExcelOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookSummaryExcelResponse>
   */
  getLoanBookSummaryExcel(
    options?: Models.ReportingServiceAPIGetLoanBookSummaryExcelOptionalParams
  ): Promise<Models.GetLoanBookSummaryExcelResponse>;
  /**
   * @param callback The callback
   */
  getLoanBookSummaryExcel(callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBookSummaryExcel(
    options: Models.ReportingServiceAPIGetLoanBookSummaryExcelOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  getLoanBookSummaryExcel(
    options?:
      | Models.ReportingServiceAPIGetLoanBookSummaryExcelOptionalParams
      | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.GetLoanBookSummaryExcelResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookSummaryExcelOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookSummaryExcelResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateBalances(options?: Models.ReportingServiceAPIUpdateBalancesOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateBalances(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateBalances(
    options: Models.ReportingServiceAPIUpdateBalancesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateBalances(
    options?: Models.ReportingServiceAPIUpdateBalancesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateBalancesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCreditLimitsResponse>
   */
  getCreditLimits(options?: msRest.RequestOptionsBase): Promise<Models.GetCreditLimitsResponse>;
  /**
   * @param callback The callback
   */
  getCreditLimits(callback: msRest.ServiceCallback<Models.CreditLimit[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCreditLimits(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CreditLimit[]>): void;
  getCreditLimits(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CreditLimit[]>,
    callback?: msRest.ServiceCallback<Models.CreditLimit[]>
  ): Promise<Models.GetCreditLimitsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCreditLimitsOperationSpec,
      callback
    ) as Promise<Models.GetCreditLimitsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAdvancesResponse>
   */
  getAdvances(options?: Models.ReportingServiceAPIGetAdvancesOptionalParams): Promise<Models.GetAdvancesResponse>;
  /**
   * @param callback The callback
   */
  getAdvances(callback: msRest.ServiceCallback<Models.AdvanceDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAdvances(
    options: Models.ReportingServiceAPIGetAdvancesOptionalParams,
    callback: msRest.ServiceCallback<Models.AdvanceDetails[]>
  ): void;
  getAdvances(
    options?: Models.ReportingServiceAPIGetAdvancesOptionalParams | msRest.ServiceCallback<Models.AdvanceDetails[]>,
    callback?: msRest.ServiceCallback<Models.AdvanceDetails[]>
  ): Promise<Models.GetAdvancesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAdvancesOperationSpec,
      callback
    ) as Promise<Models.GetAdvancesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAdvancesCSVResponse>
   */
  getAdvancesCSV(
    options?: Models.ReportingServiceAPIGetAdvancesCSVOptionalParams
  ): Promise<Models.GetAdvancesCSVResponse>;
  /**
   * @param callback The callback
   */
  getAdvancesCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAdvancesCSV(
    options: Models.ReportingServiceAPIGetAdvancesCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getAdvancesCSV(
    options?: Models.ReportingServiceAPIGetAdvancesCSVOptionalParams | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetAdvancesCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAdvancesCSVOperationSpec,
      callback
    ) as Promise<Models.GetAdvancesCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInterestAndPrincipalCSVResponse>
   */
  getInterestAndPrincipalCSV(
    options?: Models.ReportingServiceAPIGetInterestAndPrincipalCSVOptionalParams
  ): Promise<Models.GetInterestAndPrincipalCSVResponse>;
  /**
   * @param callback The callback
   */
  getInterestAndPrincipalCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInterestAndPrincipalCSV(
    options: Models.ReportingServiceAPIGetInterestAndPrincipalCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getInterestAndPrincipalCSV(
    options?:
      | Models.ReportingServiceAPIGetInterestAndPrincipalCSVOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetInterestAndPrincipalCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInterestAndPrincipalCSVOperationSpec,
      callback
    ) as Promise<Models.GetInterestAndPrincipalCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInterestAndPrincipalResponse>
   */
  getInterestAndPrincipal(
    options?: Models.ReportingServiceAPIGetInterestAndPrincipalOptionalParams
  ): Promise<Models.GetInterestAndPrincipalResponse>;
  /**
   * @param callback The callback
   */
  getInterestAndPrincipal(callback: msRest.ServiceCallback<Models.InterestPrincipal[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInterestAndPrincipal(
    options: Models.ReportingServiceAPIGetInterestAndPrincipalOptionalParams,
    callback: msRest.ServiceCallback<Models.InterestPrincipal[]>
  ): void;
  getInterestAndPrincipal(
    options?:
      | Models.ReportingServiceAPIGetInterestAndPrincipalOptionalParams
      | msRest.ServiceCallback<Models.InterestPrincipal[]>,
    callback?: msRest.ServiceCallback<Models.InterestPrincipal[]>
  ): Promise<Models.GetInterestAndPrincipalResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInterestAndPrincipalOperationSpec,
      callback
    ) as Promise<Models.GetInterestAndPrincipalResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  clearAllDriftAverageData(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  clearAllDriftAverageData(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  clearAllDriftAverageData(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  clearAllDriftAverageData(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      clearAllDriftAverageDataOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateDriftAverageData(
    options?: Models.ReportingServiceAPIUpdateDriftAverageDataOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateDriftAverageData(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateDriftAverageData(
    options: Models.ReportingServiceAPIUpdateDriftAverageDataOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateDriftAverageData(
    options?: Models.ReportingServiceAPIUpdateDriftAverageDataOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateDriftAverageDataOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateWeeklyDriftAverageDataFromDate(
    options?: Models.ReportingServiceAPIUpdateWeeklyDriftAverageDataFromDateOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  updateWeeklyDriftAverageDataFromDate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  updateWeeklyDriftAverageDataFromDate(
    options: Models.ReportingServiceAPIUpdateWeeklyDriftAverageDataFromDateOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateWeeklyDriftAverageDataFromDate(
    options?:
      | Models.ReportingServiceAPIUpdateWeeklyDriftAverageDataFromDateOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      updateWeeklyDriftAverageDataFromDateOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDriftReportDataCSVResponse>
   */
  getDriftReportDataCSV(options?: msRest.RequestOptionsBase): Promise<Models.GetDriftReportDataCSVResponse>;
  /**
   * @param callback The callback
   */
  getDriftReportDataCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDriftReportDataCSV(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getDriftReportDataCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetDriftReportDataCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDriftReportDataCSVOperationSpec,
      callback
    ) as Promise<Models.GetDriftReportDataCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetHistoricalPortfolioMetricsReportCSVResponse>
   */
  getHistoricalPortfolioMetricsReportCSV(
    options?: Models.ReportingServiceAPIGetHistoricalPortfolioMetricsReportCSVOptionalParams
  ): Promise<Models.GetHistoricalPortfolioMetricsReportCSVResponse>;
  /**
   * @param callback The callback
   */
  getHistoricalPortfolioMetricsReportCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getHistoricalPortfolioMetricsReportCSV(
    options: Models.ReportingServiceAPIGetHistoricalPortfolioMetricsReportCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getHistoricalPortfolioMetricsReportCSV(
    options?:
      | Models.ReportingServiceAPIGetHistoricalPortfolioMetricsReportCSVOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetHistoricalPortfolioMetricsReportCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getHistoricalPortfolioMetricsReportCSVOperationSpec,
      callback
    ) as Promise<Models.GetHistoricalPortfolioMetricsReportCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  clearAllHistoricalPortfolioMetricsReportData(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  clearAllHistoricalPortfolioMetricsReportData(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  clearAllHistoricalPortfolioMetricsReportData(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  clearAllHistoricalPortfolioMetricsReportData(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      clearAllHistoricalPortfolioMetricsReportDataOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanActivityCSVResponse>
   */
  getLoanActivityCSV(options?: msRest.RequestOptionsBase): Promise<Models.GetLoanActivityCSVResponse>;
  /**
   * @param callback The callback
   */
  getLoanActivityCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanActivityCSV(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getLoanActivityCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetLoanActivityCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanActivityCSVOperationSpec,
      callback
    ) as Promise<Models.GetLoanActivityCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanActivitySummaryCSVResponse>
   */
  getLoanActivitySummaryCSV(
    options?: Models.ReportingServiceAPIGetLoanActivitySummaryCSVOptionalParams
  ): Promise<Models.GetLoanActivitySummaryCSVResponse>;
  /**
   * @param callback The callback
   */
  getLoanActivitySummaryCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanActivitySummaryCSV(
    options: Models.ReportingServiceAPIGetLoanActivitySummaryCSVOptionalParams,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  getLoanActivitySummaryCSV(
    options?:
      | Models.ReportingServiceAPIGetLoanActivitySummaryCSVOptionalParams
      | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.GetLoanActivitySummaryCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanActivitySummaryCSVOperationSpec,
      callback
    ) as Promise<Models.GetLoanActivitySummaryCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomerAuditsResponse>
   */
  getCustomerAudits(
    options?: Models.ReportingServiceAPIGetCustomerAuditsOptionalParams
  ): Promise<Models.GetCustomerAuditsResponse>;
  /**
   * @param callback The callback
   */
  getCustomerAudits(callback: msRest.ServiceCallback<Models.CustomerAudit[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomerAudits(
    options: Models.ReportingServiceAPIGetCustomerAuditsOptionalParams,
    callback: msRest.ServiceCallback<Models.CustomerAudit[]>
  ): void;
  getCustomerAudits(
    options?:
      | Models.ReportingServiceAPIGetCustomerAuditsOptionalParams
      | msRest.ServiceCallback<Models.CustomerAudit[]>,
    callback?: msRest.ServiceCallback<Models.CustomerAudit[]>
  ): Promise<Models.GetCustomerAuditsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCustomerAuditsOperationSpec,
      callback
    ) as Promise<Models.GetCustomerAuditsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllCustomerAuditsResponse>
   */
  getAllCustomerAudits(
    organisationId: string,
    options?: Models.ReportingServiceAPIGetAllCustomerAuditsOptionalParams
  ): Promise<Models.GetAllCustomerAuditsResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getAllCustomerAudits(organisationId: string, callback: msRest.ServiceCallback<Models.PagedListCustomerAudit>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllCustomerAudits(
    organisationId: string,
    options: Models.ReportingServiceAPIGetAllCustomerAuditsOptionalParams,
    callback: msRest.ServiceCallback<Models.PagedListCustomerAudit>
  ): void;
  getAllCustomerAudits(
    organisationId: string,
    options?:
      | Models.ReportingServiceAPIGetAllCustomerAuditsOptionalParams
      | msRest.ServiceCallback<Models.PagedListCustomerAudit>,
    callback?: msRest.ServiceCallback<Models.PagedListCustomerAudit>
  ): Promise<Models.GetAllCustomerAuditsResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getAllCustomerAuditsOperationSpec,
      callback
    ) as Promise<Models.GetAllCustomerAuditsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsAuditReportEnabledResponse>
   */
  isAuditReportEnabled(options?: msRest.RequestOptionsBase): Promise<Models.IsAuditReportEnabledResponse>;
  /**
   * @param callback The callback
   */
  isAuditReportEnabled(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isAuditReportEnabled(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isAuditReportEnabled(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsAuditReportEnabledResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isAuditReportEnabledOperationSpec,
      callback
    ) as Promise<Models.IsAuditReportEnabledResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllAutomatedDecisionReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllAutomatedDecisionReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllAutomatedDecisionReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllAutomatedDecisionReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllAutomatedDecisionReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllPricingReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllPricingReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllPricingReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllPricingReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllPricingReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildPricingReport(
    options?: Models.ReportingServiceAPIBuildPricingReportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildPricingReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildPricingReport(
    options: Models.ReportingServiceAPIBuildPricingReportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildPricingReport(
    options?: Models.ReportingServiceAPIBuildPricingReportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildPricingReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAutomatedDecisionReport(
    options?: Models.ReportingServiceAPIBuildAutomatedDecisionReportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAutomatedDecisionReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAutomatedDecisionReport(
    options: Models.ReportingServiceAPIBuildAutomatedDecisionReportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildAutomatedDecisionReport(
    options?: Models.ReportingServiceAPIBuildAutomatedDecisionReportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAutomatedDecisionReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildTotalNowArrearsReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildTotalNowArrearsReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildTotalNowArrearsReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildTotalNowArrearsReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildTotalNowArrearsReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildTotalArrearsReportOnDate(
    options?: Models.ReportingServiceAPIBuildTotalArrearsReportOnDateOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildTotalArrearsReportOnDate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildTotalArrearsReportOnDate(
    options: Models.ReportingServiceAPIBuildTotalArrearsReportOnDateOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildTotalArrearsReportOnDate(
    options?: Models.ReportingServiceAPIBuildTotalArrearsReportOnDateOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildTotalArrearsReportOnDateOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildArrearsReport(
    options?: Models.ReportingServiceAPIBuildArrearsReportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildArrearsReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildArrearsReport(
    options: Models.ReportingServiceAPIBuildArrearsReportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildArrearsReport(
    options?: Models.ReportingServiceAPIBuildArrearsReportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildArrearsReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildArrearsReportForLoanOnDate(
    options?: Models.ReportingServiceAPIBuildArrearsReportForLoanOnDateOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildArrearsReportForLoanOnDate(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildArrearsReportForLoanOnDate(
    options: Models.ReportingServiceAPIBuildArrearsReportForLoanOnDateOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildArrearsReportForLoanOnDate(
    options?: Models.ReportingServiceAPIBuildArrearsReportForLoanOnDateOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildArrearsReportForLoanOnDateOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildHistoricalArrearsReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildHistoricalArrearsReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildHistoricalArrearsReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildHistoricalArrearsReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildHistoricalArrearsReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildHistoricalArrearsReportQuick(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildHistoricalArrearsReportQuick(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildHistoricalArrearsReportQuick(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildHistoricalArrearsReportQuick(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildHistoricalArrearsReportQuickOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildHistoricalArrearsReportOnLoan(
    options?: Models.ReportingServiceAPIBuildHistoricalArrearsReportOnLoanOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildHistoricalArrearsReportOnLoan(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildHistoricalArrearsReportOnLoan(
    options: Models.ReportingServiceAPIBuildHistoricalArrearsReportOnLoanOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildHistoricalArrearsReportOnLoan(
    options?: Models.ReportingServiceAPIBuildHistoricalArrearsReportOnLoanOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildHistoricalArrearsReportOnLoanOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildHistoricalMonthlyArrearsReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildHistoricalMonthlyArrearsReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildHistoricalMonthlyArrearsReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildHistoricalMonthlyArrearsReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildHistoricalMonthlyArrearsReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildHistoricalArrearsReportOnLoan1(
    options?: Models.ReportingServiceAPIBuildHistoricalArrearsReportOnLoan1OptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildHistoricalArrearsReportOnLoan1(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildHistoricalArrearsReportOnLoan1(
    options: Models.ReportingServiceAPIBuildHistoricalArrearsReportOnLoan1OptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildHistoricalArrearsReportOnLoan1(
    options?:
      | Models.ReportingServiceAPIBuildHistoricalArrearsReportOnLoan1OptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildHistoricalArrearsReportOnLoan1OperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.BuildReceivableTotalsResponse>
   */
  buildReceivableTotals(
    loanId: string,
    options?: msRest.RequestOptionsBase
  ): Promise<Models.BuildReceivableTotalsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  buildReceivableTotals(
    loanId: string,
    callback: msRest.ServiceCallback<{ [propertyName: string]: Models.ReceivableTotal }>
  ): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildReceivableTotals(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<{ [propertyName: string]: Models.ReceivableTotal }>
  ): void;
  buildReceivableTotals(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: Models.ReceivableTotal }>,
    callback?: msRest.ServiceCallback<{ [propertyName: string]: Models.ReceivableTotal }>
  ): Promise<Models.BuildReceivableTotalsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      buildReceivableTotalsOperationSpec,
      callback
    ) as Promise<Models.BuildReceivableTotalsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildTotalReceivableCSV(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  buildTotalReceivableCSV(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildTotalReceivableCSV(
    loanId: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildTotalReceivableCSV(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      buildTotalReceivableCSVOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllTotalReceivableCSV(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllTotalReceivableCSV(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllTotalReceivableCSV(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllTotalReceivableCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllTotalReceivableCSVOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllClosingBalancesReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllClosingBalancesReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllClosingBalancesReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllClosingBalancesReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllClosingBalancesReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildClosingBalancesReport(
    options?: Models.ReportingServiceAPIBuildClosingBalancesReportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildClosingBalancesReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildClosingBalancesReport(
    options: Models.ReportingServiceAPIBuildClosingBalancesReportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildClosingBalancesReport(
    options?: Models.ReportingServiceAPIBuildClosingBalancesReportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildClosingBalancesReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllCreditLimitsReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllCreditLimitsReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllCreditLimitsReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllCreditLimitsReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllCreditLimitsReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildCreditLimitsReport(
    options?: Models.ReportingServiceAPIBuildCreditLimitsReportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildCreditLimitsReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildCreditLimitsReport(
    options: Models.ReportingServiceAPIBuildCreditLimitsReportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildCreditLimitsReport(
    options?: Models.ReportingServiceAPIBuildCreditLimitsReportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildCreditLimitsReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildCreditLimitsReportDirect(
    options?: Models.ReportingServiceAPIBuildCreditLimitsReportDirectOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildCreditLimitsReportDirect(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildCreditLimitsReportDirect(
    options: Models.ReportingServiceAPIBuildCreditLimitsReportDirectOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildCreditLimitsReportDirect(
    options?: Models.ReportingServiceAPIBuildCreditLimitsReportDirectOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildCreditLimitsReportDirectOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllInvoiceReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllInvoiceReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllInvoiceReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllInvoiceReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllInvoiceReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildInvoiceReport(
    options?: Models.ReportingServiceAPIBuildInvoiceReportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildInvoiceReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildInvoiceReport(
    options: Models.ReportingServiceAPIBuildInvoiceReportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildInvoiceReport(
    options?: Models.ReportingServiceAPIBuildInvoiceReportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildInvoiceReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildBalancesReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildBalancesReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildBalancesReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildBalancesReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildBalancesReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllDebtorDaysReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllDebtorDaysReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllDebtorDaysReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllDebtorDaysReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllDebtorDaysReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildDebtorDaysReport(
    options?: Models.ReportingServiceAPIBuildDebtorDaysReportOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildDebtorDaysReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildDebtorDaysReport(
    options: Models.ReportingServiceAPIBuildDebtorDaysReportOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildDebtorDaysReport(
    options?: Models.ReportingServiceAPIBuildDebtorDaysReportOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildDebtorDaysReportOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildLoanBook(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  buildLoanBook(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildLoanBook(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildLoanBook(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      buildLoanBookOperationSpec,
      callback
    );
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildProviso(
    organisationId: string,
    options?: Models.ReportingServiceAPIBuildProvisoOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  buildProviso(organisationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildProviso(
    organisationId: string,
    options: Models.ReportingServiceAPIBuildProvisoOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildProviso(
    organisationId: string,
    options?: Models.ReportingServiceAPIBuildProvisoOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      buildProvisoOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildLoanBook1(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildLoanBook1(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildLoanBook1(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildLoanBook1(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildLoanBook1OperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.BuildAllLoanBookInMemoryCSVResponse>
   */
  buildAllLoanBookInMemoryCSV(options?: msRest.RequestOptionsBase): Promise<Models.BuildAllLoanBookInMemoryCSVResponse>;
  /**
   * @param callback The callback
   */
  buildAllLoanBookInMemoryCSV(callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllLoanBookInMemoryCSV(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionFile>
  ): void;
  buildAllLoanBookInMemoryCSV(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionFile>,
    callback?: msRest.ServiceCallback<Models.TransactionFile>
  ): Promise<Models.BuildAllLoanBookInMemoryCSVResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllLoanBookInMemoryCSVOperationSpec,
      callback
    ) as Promise<Models.BuildAllLoanBookInMemoryCSVResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.BuildLoanBookExcelResponse>
   */
  buildLoanBookExcel(options?: msRest.RequestOptionsBase): Promise<Models.BuildLoanBookExcelResponse>;
  /**
   * @param callback The callback
   */
  buildLoanBookExcel(callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildLoanBookExcel(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<Models.TransactionDataFile>
  ): void;
  buildLoanBookExcel(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>,
    callback?: msRest.ServiceCallback<Models.TransactionDataFile>
  ): Promise<Models.BuildLoanBookExcelResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildLoanBookExcelOperationSpec,
      callback
    ) as Promise<Models.BuildLoanBookExcelResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllLoanLimits(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllLoanLimits(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllLoanLimits(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllLoanLimits(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllLoanLimitsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildLoanLimits(options?: Models.ReportingServiceAPIBuildLoanLimitsOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildLoanLimits(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildLoanLimits(
    options: Models.ReportingServiceAPIBuildLoanLimitsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildLoanLimits(
    options?: Models.ReportingServiceAPIBuildLoanLimitsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildLoanLimitsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildLoanBookSummary(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildLoanBookSummary(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildLoanBookSummary(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildLoanBookSummary(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildLoanBookSummaryOperationSpec,
      callback
    );
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildRepayment(loanId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  buildRepayment(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  buildRepayment(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildRepayment(
    loanId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      buildRepaymentOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllRepayment(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllRepayment(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllRepayment(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllRepayment(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllRepaymentOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildConnectionReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildConnectionReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildConnectionReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildConnectionReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildConnectionReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildBalanceData(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildBalanceData(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildBalanceData(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildBalanceData(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildBalanceDataOperationSpec,
      callback
    );
  }

  /**
   * @param weekly
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildPortfolioMetricsForToday(weekly: boolean, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param weekly
   * @param callback The callback
   */
  buildPortfolioMetricsForToday(weekly: boolean, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param weekly
   * @param options The optional parameters
   * @param callback The callback
   */
  buildPortfolioMetricsForToday(
    weekly: boolean,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildPortfolioMetricsForToday(
    weekly: boolean,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        weekly,
        options
      },
      buildPortfolioMetricsForTodayOperationSpec,
      callback
    );
  }

  /**
   * @param weekly
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllPortfolioMetrics(weekly: boolean, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param weekly
   * @param callback The callback
   */
  buildAllPortfolioMetrics(weekly: boolean, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param weekly
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllPortfolioMetrics(
    weekly: boolean,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildAllPortfolioMetrics(
    weekly: boolean,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        weekly,
        options
      },
      buildAllPortfolioMetricsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildInterestAndPrincipal(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildInterestAndPrincipal(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildInterestAndPrincipal(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildInterestAndPrincipal(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildInterestAndPrincipalOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllAdvances(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllAdvances(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllAdvances(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllAdvances(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllAdvancesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllLoanPortfolioMetricsPerClientDaily(
    options?: Models.ReportingServiceAPIBuildAllLoanPortfolioMetricsPerClientDailyOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllLoanPortfolioMetricsPerClientDaily(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllLoanPortfolioMetricsPerClientDaily(
    options: Models.ReportingServiceAPIBuildAllLoanPortfolioMetricsPerClientDailyOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildAllLoanPortfolioMetricsPerClientDaily(
    options?:
      | Models.ReportingServiceAPIBuildAllLoanPortfolioMetricsPerClientDailyOptionalParams
      | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllLoanPortfolioMetricsPerClientDailyOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllLoanPortfolioMetrics(
    options?: Models.ReportingServiceAPIBuildAllLoanPortfolioMetricsOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllLoanPortfolioMetrics(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllLoanPortfolioMetrics(
    options: Models.ReportingServiceAPIBuildAllLoanPortfolioMetricsOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildAllLoanPortfolioMetrics(
    options?: Models.ReportingServiceAPIBuildAllLoanPortfolioMetricsOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllLoanPortfolioMetricsOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllLoanPortfolioMetricsForToday(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllLoanPortfolioMetricsForToday(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllLoanPortfolioMetricsForToday(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildAllLoanPortfolioMetricsForToday(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllLoanPortfolioMetricsForTodayOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPortfolioPerformanceResponse>
   */
  getPortfolioPerformance(
    options?: Models.ReportingServiceAPIGetPortfolioPerformanceOptionalParams
  ): Promise<Models.GetPortfolioPerformanceResponse>;
  /**
   * @param callback The callback
   */
  getPortfolioPerformance(callback: msRest.ServiceCallback<Models.PortfolioPerformance[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPortfolioPerformance(
    options: Models.ReportingServiceAPIGetPortfolioPerformanceOptionalParams,
    callback: msRest.ServiceCallback<Models.PortfolioPerformance[]>
  ): void;
  getPortfolioPerformance(
    options?:
      | Models.ReportingServiceAPIGetPortfolioPerformanceOptionalParams
      | msRest.ServiceCallback<Models.PortfolioPerformance[]>,
    callback?: msRest.ServiceCallback<Models.PortfolioPerformance[]>
  ): Promise<Models.GetPortfolioPerformanceResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPortfolioPerformanceOperationSpec,
      callback
    ) as Promise<Models.GetPortfolioPerformanceResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPortfolioPerformanceDetailResponse>
   */
  getPortfolioPerformanceDetail(
    options?: Models.ReportingServiceAPIGetPortfolioPerformanceDetailOptionalParams
  ): Promise<Models.GetPortfolioPerformanceDetailResponse>;
  /**
   * @param callback The callback
   */
  getPortfolioPerformanceDetail(callback: msRest.ServiceCallback<Models.PortfolioPerformanceCompact[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPortfolioPerformanceDetail(
    options: Models.ReportingServiceAPIGetPortfolioPerformanceDetailOptionalParams,
    callback: msRest.ServiceCallback<Models.PortfolioPerformanceCompact[]>
  ): void;
  getPortfolioPerformanceDetail(
    options?:
      | Models.ReportingServiceAPIGetPortfolioPerformanceDetailOptionalParams
      | msRest.ServiceCallback<Models.PortfolioPerformanceCompact[]>,
    callback?: msRest.ServiceCallback<Models.PortfolioPerformanceCompact[]>
  ): Promise<Models.GetPortfolioPerformanceDetailResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPortfolioPerformanceDetailOperationSpec,
      callback
    ) as Promise<Models.GetPortfolioPerformanceDetailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildAllPerformancePortfolioReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildAllPerformancePortfolioReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildAllPerformancePortfolioReport(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  buildAllPerformancePortfolioReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildAllPerformancePortfolioReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildTodayPerformancePortfolioReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildTodayPerformancePortfolioReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildTodayPerformancePortfolioReport(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildTodayPerformancePortfolioReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildTodayPerformancePortfolioReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildQuarterlyPerformancePortfolioReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildQuarterlyPerformancePortfolioReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildQuarterlyPerformancePortfolioReport(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildQuarterlyPerformancePortfolioReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildQuarterlyPerformancePortfolioReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  buildBiannuallyPerformancePortfolioReport(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  buildBiannuallyPerformancePortfolioReport(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  buildBiannuallyPerformancePortfolioReport(
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  buildBiannuallyPerformancePortfolioReport(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      buildBiannuallyPerformancePortfolioReportOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  getPortfolioPerformanceCSV(
    options?: Models.ReportingServiceAPIGetPortfolioPerformanceCSVOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  getPortfolioPerformanceCSV(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPortfolioPerformanceCSV(
    options: Models.ReportingServiceAPIGetPortfolioPerformanceCSVOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  getPortfolioPerformanceCSV(
    options?: Models.ReportingServiceAPIGetPortfolioPerformanceCSVOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPortfolioPerformanceCSVOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetConnectionReportLastUpdatedResponse>
   */
  getConnectionReportLastUpdated(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetConnectionReportLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getConnectionReportLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getConnectionReportLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getConnectionReportLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetConnectionReportLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getConnectionReportLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetConnectionReportLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAdvancesLastUpdatedResponse>
   */
  getAdvancesLastUpdated(options?: msRest.RequestOptionsBase): Promise<Models.GetAdvancesLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getAdvancesLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAdvancesLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getAdvancesLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetAdvancesLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAdvancesLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetAdvancesLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBalancesLastUpdatedResponse>
   */
  getBalancesLastUpdated(options?: msRest.RequestOptionsBase): Promise<Models.GetBalancesLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getBalancesLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBalancesLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getBalancesLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetBalancesLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBalancesLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetBalancesLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPortfolioLastUpdatedResponse>
   */
  getPortfolioLastUpdated(options?: msRest.RequestOptionsBase): Promise<Models.GetPortfolioLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getPortfolioLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPortfolioLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getPortfolioLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetPortfolioLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPortfolioLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetPortfolioLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookLastUpdatedResponse>
   */
  getLoanBookLastUpdated(options?: msRest.RequestOptionsBase): Promise<Models.GetLoanBookLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getLoanBookLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBookLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getLoanBookLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetLoanBookLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBookSummaryLastUpdatedResponse>
   */
  getLoanBookSummaryLastUpdated(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetLoanBookSummaryLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getLoanBookSummaryLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBookSummaryLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getLoanBookSummaryLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetLoanBookSummaryLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoanBookSummaryLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetLoanBookSummaryLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRepaymentLastUpdatedResponse>
   */
  getRepaymentLastUpdated(options?: msRest.RequestOptionsBase): Promise<Models.GetRepaymentLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getRepaymentLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRepaymentLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getRepaymentLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetRepaymentLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRepaymentLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetRepaymentLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetInterestPrincipalLastUpdatedResponse>
   */
  getInterestPrincipalLastUpdated(
    options?: msRest.RequestOptionsBase
  ): Promise<Models.GetInterestPrincipalLastUpdatedResponse>;
  /**
   * @param callback The callback
   */
  getInterestPrincipalLastUpdated(callback: msRest.ServiceCallback<Date>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getInterestPrincipalLastUpdated(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Date>): void;
  getInterestPrincipalLastUpdated(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Date>,
    callback?: msRest.ServiceCallback<Date>
  ): Promise<Models.GetInterestPrincipalLastUpdatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getInterestPrincipalLastUpdatedOperationSpec,
      callback
    ) as Promise<Models.GetInterestPrincipalLastUpdatedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  validateAllCustomers(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  validateAllCustomers(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  validateAllCustomers(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  validateAllCustomers(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      validateAllCustomersOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  validateAllPartners(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  validateAllPartners(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  validateAllPartners(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  validateAllPartners(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      validateAllPartnersOperationSpec,
      callback
    );
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const getActivitySummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-activity-summary',
  queryParameters: [Parameters.loanId0, Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ActivitySummary'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getActivitySummaryConsolidatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-activity-summary-consolidated',
  queryParameters: [Parameters.loanId0, Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: Mappers.ActivitySummary
    },
    default: {}
  },
  serializer
};

const getPricingReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-pricing-report',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PricingData'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPricingReportCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-pricing-report-csv',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getAutomatedDecisionReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-automated-decision-report',
  queryParameters: [Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AutomatedDecisionData'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAutomatedDecisionStatisticsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-automated-decision-statistics-report',
  queryParameters: [Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: Mappers.AutomatedDecisionStatistics
    },
    default: {}
  },
  serializer
};

const getCustomerReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-customer-report',
  queryParameters: [Parameters.applicationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerData'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getArrearsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-arrears-report',
  queryParameters: [Parameters.dateParameter, Parameters.includePaymentArrangement],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ArrearsReportData'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getArrearsPercentageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-arrears-percentage',
  queryParameters: [Parameters.dateParameter, Parameters.includePaymentArrangement],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Number'
        }
      }
    },
    default: {}
  },
  serializer
};

const getArrearsReportCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-arrears-report-csv',
  queryParameters: [Parameters.dateParameter, Parameters.includePaymentArrangement],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getArrearsReportCSVLocalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-arrears-report-csv-local',
  queryParameters: [Parameters.dateParameter, Parameters.includePaymentArrangement],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getArrearsReportCSVLocalQuarterlyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-arrears-report-csv-quarterly',
  queryParameters: [Parameters.includePaymentArrangement],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getAllInvoicesCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-invoices-csv',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getProcessedInvoicesCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-processed-invoices-csv',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getAllClosingBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-closing-balances',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ClosingBalance'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getMetricsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-metrics',
  queryParameters: [Parameters.metricType],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getDilutionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-dilution-invoice-list',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Dilution'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAllInvoiceLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-invoice-loans',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getDriftMetricsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-drift-metrics',
  queryParameters: [Parameters.loanId1, Parameters.startDate1, Parameters.endDate1],
  responses: {
    200: {
      bodyMapper: Mappers.DriftMetricList
    },
    default: {}
  },
  serializer
};

const getAllDriftMetricsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-drift-metrics',
  queryParameters: [Parameters.startDate1, Parameters.endDate1],
  responses: {
    200: {
      bodyMapper: Mappers.DriftMetricList
    },
    default: {}
  },
  serializer
};

const getDriftMetricsCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-drift-metrics-csv',
  queryParameters: [Parameters.loanId1, Parameters.startDate1, Parameters.endDate1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getAllDriftMetricsCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-drift-metrics-csv',
  queryParameters: [Parameters.startDate1, Parameters.endDate1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getAllDebtorDaysOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-debtor-days',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DebtorDayValue'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAllDebtorsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-debtor',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const displayMessageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/display-message',
  queryParameters: [Parameters.value],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const newAlertOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/new-alert',
  queryParameters: [Parameters.alertId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const offerUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/offer-updated',
  queryParameters: [Parameters.offerId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const portfolioUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/portfolio-updated',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getConnectionReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-connection-status',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ConnectionStatus'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAverageDailyARCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-average-daily-ar-csv',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getOpenARCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-open-ar-csv',
  queryParameters: [Parameters.loanId1, Parameters.dateTime0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getProvisoReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-proviso-report',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ProvisoData'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getBalancesReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-loan-balances',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Composite',
              className: 'LoanBalances'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getBalancesReportCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-loan-balances-csv',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getAllApplicationsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-applications',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getApplicationEventsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-application-events',
  queryParameters: [Parameters.applicationId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationEvent'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getLoanEventsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-events',
  queryParameters: [Parameters.loanId1, Parameters.skip0, Parameters.take0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanEvent'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getLoanEventsCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-events-csv',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getLoanEventsCSVLocalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-events-csv-local',
  queryParameters: [Parameters.loanId1, Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getLastApplicationsActivitiesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-last-applications-activities',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'ApplicationAcitvityState'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAllAuditNotesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-audit-notes',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AuditData'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAllTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-transactions',
  queryParameters: [Parameters.start, Parameters.end],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'TransactionDetails'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAllTransactionsCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-transactions-as-csv',
  queryParameters: [Parameters.start, Parameters.end],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getAllDebtorDaysCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-debtor-days-csv',
  queryParameters: [Parameters.organisationId0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getLastLoansActivitiesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-last-loans-activities',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanAcitvityState'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getBalanceDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-balance-data',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Composite',
              className: 'BalanceData'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAllLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-all-loans',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPortfolioMetricOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-portfolio-metrics',
  queryParameters: [Parameters.metricName, Parameters.weekly],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PortfolioMetricPerDate'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPortfolioMetricMovingAverageOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-portfolio-metrics-moving-average',
  queryParameters: [Parameters.metricName, Parameters.weekly],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PortfolioMetricPerDate'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPortfolioMetricCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-portfolio-metrics-csv',
  queryParameters: [Parameters.metricName, Parameters.weekly],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getLoanBookOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-book',
  queryParameters: [Parameters.dateParameter, Parameters.showClosed0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanBook'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getLoanLimitsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-limits',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'OverrideValues'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getLoanLimitsCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-limits-csv',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getLoanLimitsCSVLocalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-limits-csv-local',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getLoanBookSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-book-summary',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'LoanBookSummary'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getRepaymentByProductOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-repayment-by-product',
  queryParameters: [Parameters.startDate0, Parameters.endDate0, Parameters.product, Parameters.loan],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Repayment'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-repayment',
  queryParameters: [Parameters.startDate0, Parameters.endDate0, Parameters.loan],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Repayment'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getRepaymentCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-repayment-csv',
  queryParameters: [Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const updateLoanBookOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Reporting/update-loan-book',
  queryParameters: [Parameters.loanId2],
  requestBody: {
    parameterPath: ['options', 'updateValues'],
    mapper: Mappers.LoanBook
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getLoanBookCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-book-csv',
  queryParameters: [Parameters.dateParameter, Parameters.showClosed1],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getLoanBookSummaryCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-book-summary-csv',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getLoanBookExcelOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-book-excel',
  queryParameters: [Parameters.dateParameter, Parameters.showClosed0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    default: {}
  },
  serializer
};

const saveLoanBookExcelOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/save-loan-book-excel',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getLoanBookSummaryExcelOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-book-summary-excel',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    default: {}
  },
  serializer
};

const updateBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/update-loan-balance',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getCreditLimitsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-credit-limits',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CreditLimit'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAdvancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-advances',
  queryParameters: [Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'AdvanceDetails'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAdvancesCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-advances-csv',
  queryParameters: [Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getInterestAndPrincipalCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-interest-principal-csv',
  queryParameters: [Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getInterestAndPrincipalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-interest-principal',
  queryParameters: [Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'InterestPrincipal'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const clearAllDriftAverageDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Reporting/clear-all-drift-average-data',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateDriftAverageDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Reporting/update-drift-average-data',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateWeeklyDriftAverageDataFromDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Reporting/update-drift-average-data-weekly',
  queryParameters: [Parameters.startDate0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getDriftReportDataCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-drift-average-data-report-csv',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getHistoricalPortfolioMetricsReportCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Reporting/get-historical-portfolio-metrics-report-csv',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const clearAllHistoricalPortfolioMetricsReportDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Reporting/clear-all-historical-portfolio-metrics-report-data',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getLoanActivityCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-activity-csv',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getLoanActivitySummaryCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-loan-activity-summary-csv',
  queryParameters: [Parameters.loanId0, Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const getCustomerAuditsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/get-customer-audits',
  queryParameters: [Parameters.organisationId1, Parameters.startDate0, Parameters.endDate0],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerAudit'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getAllCustomerAuditsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/{organisationId}/customer-audits',
  urlParameters: [Parameters.organisationId0],
  queryParameters: [
    Parameters.startDate0,
    Parameters.endDate0,
    Parameters.skip1,
    Parameters.take1,
    Parameters.filterColumn,
    Parameters.filterValue
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerAudit
    },
    default: {}
  },
  serializer
};

const isAuditReportEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Reporting/is-audit-report-enabled',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const buildAllAutomatedDecisionReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-automated-decisions',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllPricingReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-pricing-report',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildPricingReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-pricing-report',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAutomatedDecisionReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-automated-decisions',
  queryParameters: [Parameters.id],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildTotalNowArrearsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-now-arrears',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildTotalArrearsReportOnDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-arrears-on-date',
  queryParameters: [Parameters.dateTime1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildArrearsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-arrears',
  queryParameters: [Parameters.id],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildArrearsReportForLoanOnDateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-arrears-for-loan-on-date',
  queryParameters: [Parameters.id, Parameters.dateParameter],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildHistoricalArrearsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-arrears',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildHistoricalArrearsReportQuickOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-arrears-quick',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildHistoricalArrearsReportOnLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-arrears-on-loan',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildHistoricalMonthlyArrearsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-arrears-monthly',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildHistoricalArrearsReportOnLoan1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-arrears-loan',
  queryParameters: [Parameters.id, Parameters.dateParameter],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildReceivableTotalsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-total-receivables',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Composite',
              className: 'ReceivableTotal'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const buildTotalReceivableCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-total-receivables-csv',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllTotalReceivableCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-total-receivables',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllClosingBalancesReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-closing-balances-report',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildClosingBalancesReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-closing-balances-report',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllCreditLimitsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-credit-limits-report',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildCreditLimitsReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-credit-limits-report',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildCreditLimitsReportDirectOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-credit-limits-report-direct',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllInvoiceReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-invoice-report',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildInvoiceReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-invoice-report',
  queryParameters: [Parameters.loanId0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildBalancesReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-loan-balances',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllDebtorDaysReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-debtor-days',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildDebtorDaysReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-debtor-days',
  queryParameters: [Parameters.organisationId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildLoanBookOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-loan-book',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildProvisoOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-proviso',
  queryParameters: [Parameters.organisationId0, Parameters.message],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildLoanBook1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-loan-book',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllLoanBookInMemoryCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-loan-book-in-memory-csv',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    default: {}
  },
  serializer
};

const buildLoanBookExcelOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-loan-book-excel',
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    default: {}
  },
  serializer
};

const buildAllLoanLimitsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-loan-limits',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildLoanLimitsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-loan-limits',
  queryParameters: [Parameters.id],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildLoanBookSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-loan-book-summary',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-repayment',
  queryParameters: [Parameters.loanId1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-repayment',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildConnectionReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-connection-report',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildBalanceDataOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-balance-data',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildPortfolioMetricsForTodayOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-portfolio-metrics-for-today',
  queryParameters: [Parameters.weekly],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllPortfolioMetricsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-portfolio-metrics',
  queryParameters: [Parameters.weekly],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildInterestAndPrincipalOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-interest-principal',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllAdvancesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-all-advances',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllLoanPortfolioMetricsPerClientDailyOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-portfolio-metrics-daily',
  queryParameters: [Parameters.startDate0],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllLoanPortfolioMetricsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-portfolio-metrics',
  queryParameters: [Parameters.dateParameter],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildAllLoanPortfolioMetricsForTodayOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingBuilder/build-historical-portfolio-metrics-for-today',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getPortfolioPerformanceOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingPerformancePortfolio/get-portfolio-performance',
  queryParameters: [Parameters.quarterly],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PortfolioPerformance'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const getPortfolioPerformanceDetailOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingPerformancePortfolio/get-portfolio-performance-detail',
  queryParameters: [Parameters.quarterly, Parameters.net, Parameters.include, Parameters.repaymentType],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PortfolioPerformanceCompact'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const buildAllPerformancePortfolioReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingPerformancePortfolio/build-performance-portfolio-all',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildTodayPerformancePortfolioReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingPerformancePortfolio/build-performance-portfolio-today',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildQuarterlyPerformancePortfolioReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingPerformancePortfolio/build-performance-portfolio-quarterly',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const buildBiannuallyPerformancePortfolioReportOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingPerformancePortfolio/build-performance-portfolio-biannually',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getPortfolioPerformanceCSVOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingPerformancePortfolio/get-performance-portfolio-quarterly-csv-local',
  queryParameters: [Parameters.quarterly],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getConnectionReportLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-connection-status-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const getAdvancesLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-advances-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const getBalancesLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-balances-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const getPortfolioLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-portfolio-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const getLoanBookLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-loanbook-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const getLoanBookSummaryLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-loanbook-summary-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const getRepaymentLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-repayment-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const getInterestPrincipalLastUpdatedOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingUpdated/get-interest-principal-last-updated',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'DateTime'
        }
      }
    },
    default: {}
  },
  serializer
};

const validateAllCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingValidation/validate-all-customers',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const validateAllPartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/ReportingValidation/validate-all-partners',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

export {
  ReportingServiceAPI,
  ReportingServiceAPIContext,
  Models as ReportingServiceAPIModels,
  Mappers as ReportingServiceAPIMappers
};
