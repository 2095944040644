import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/shared/services/api.service";
import { UrlService } from "../../shared/services/url.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-quick-learn-more",
  templateUrl: "./quick-books-learn-more.component.html",
  styleUrls: ["./quick-books-learn-more.component.scss"],
})
export class QuickBooksLearnMoreComponent implements OnInit {
  pendingPartner = false;
  pendingPartnerDetailForm: FormGroup;

  constructor(
    private toastr: ToastrService,
    public urlService: UrlService,
    private route: ActivatedRoute,
    private apiService: ApiService
  ) {}

  submitPendingPartnerDetail() {
    this.pendingPartner = true;
    if (this.pendingPartnerDetailForm.valid) {
      this.apiService
        .sendMessage("LearnMoreAsPartner", this.pendingPartnerDetailForm.value)
        .pipe(finalize(() => (this.pendingPartner = false)))
        .subscribe(
          (data) => {
            this.pendingPartnerDetailForm.reset();
            this.toastr.success("Your details has been sent");
          },
          (error) => {
            this.toastr.error("Server Error. Try again later");
          }
        );
    } else {
      if (this.pendingPartnerDetailForm.controls["firstname"].invalid) {
        this.toastr.error("First name is required");
      }
      if (this.pendingPartnerDetailForm.controls["lastname"].invalid) {
        this.toastr.error("Last name is required");
      }
      if (this.pendingPartnerDetailForm.controls["phone"].invalid) {
        this.toastr.error("Phone is invalid");
      }
      if (this.pendingPartnerDetailForm.controls["company"].invalid) {
        this.toastr.error("Company is required");
      }
      if (this.pendingPartnerDetailForm.controls["email"].invalid) {
        this.toastr.error("Email address is invalid");
      }
      if (this.pendingPartnerDetailForm.controls["customertype"].invalid) {
        this.toastr.error("Customer type is invalid");
      }
      this.pendingPartner = false;
    }
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(JSON.stringify(params));
      if (params.partnerid) {
        localStorage.setItem('partnerid', params.partnerid);
      } else if (params.partnerId) {
        localStorage.setItem('partnerid', params.partnerId);
      } else {
        localStorage.removeItem('partnerid');
      }
    });
  }
}
