import { Component, OnInit } from '@angular/core';
import { UrlService } from '../shared/services/url.service';
import { REGION } from '../Constants';
import { Router } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isHidden = false;
  hideAccreditation = true;
  defaultRegion = window['appConfig']['region'];
  isUsRegion = this.defaultRegion == REGION.US;

  constructor(
    public urlService: UrlService,
    public router: Router
  ) { }

  ngOnInit(): void {

  }


  showNav() {
    this.isHidden = true;
  }

  isPartner() {
    return this.router.url.toLowerCase() === '/partner';
  }

}
