import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

function bootstrapFailed(val: any) {
  console.error('bootstrap-fail', val);
}

fetch('./environments/environment.json')
  .then(response => response.json())
  .then(config => {
    if (!config) {
      bootstrapFailed(config);
      return;
    }

    if (environment.production) {
      enableProdMode();
    }

    window['appConfig'] = config;

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(bootstrapFailed);
  });
