<div id="quickbooks">
  <div class="container text-white py-5">
    <div class="d-flex align-items-center justify-content-center">
      <img src="assets/images/quickbooks-logo.png" alt="QB" class="qb-logo">
      <span class="plus">+</span>
      <img src="assets/images/plexe-logo.png" alt="Plexe" class="plexe-logo">
    </div>
    <div class="text-center mt-3 mb-5">
      <a href="/#getstarted" fragment="getstarted" class="btn btn-lightblue">Get started</a>
    </div>
    <h1>QuickBooks Integration</h1>
    <div class="divider"></div>
    <div class="row">
      <div class="col-12 col-lg-8">
        <p class="description">Track your work on client services and effortlessly connect accounting with QuickBooks</p>
      </div>
    </div>
  </div>
</div>
