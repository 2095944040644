import { Injectable } from '@angular/core';
import { OriginationReadApi } from '../../core/proxy/Read/originationReadApi';

@Injectable()
export class SystemService {
  constructor(private readApi: OriginationReadApi) {}
}

export interface BankAccount {
  name?: string;
  accountNumber?: string;
  id?: number;
  bsb?: string;
  balance?: string;
  available?: string;
  accountHolder?: string;
  accountType?: string;
  isNominated?: boolean;
}
