/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const BalanceSummary: msRest.CompositeMapper = {
  serializedName: 'BalanceSummary',
  type: {
    name: 'Composite',
    className: 'BalanceSummary',
    modelProperties: {
      advances: {
        serializedName: 'advances',
        type: {
          name: 'Number'
        }
      },
      invoicePayments: {
        serializedName: 'invoicePayments',
        type: {
          name: 'Number'
        }
      },
      fees: {
        serializedName: 'fees',
        type: {
          name: 'Number'
        }
      },
      sweeps: {
        serializedName: 'sweeps',
        type: {
          name: 'Number'
        }
      },
      repayments: {
        serializedName: 'repayments',
        type: {
          name: 'Number'
        }
      },
      rest: {
        serializedName: 'rest',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const InvoicesSummary: msRest.CompositeMapper = {
  serializedName: 'InvoicesSummary',
  type: {
    name: 'Composite',
    className: 'InvoicesSummary',
    modelProperties: {
      invoiceRaised: {
        serializedName: 'invoiceRaised',
        type: {
          name: 'Number'
        }
      },
      invoiceClosed: {
        serializedName: 'invoiceClosed',
        type: {
          name: 'Number'
        }
      },
      modificationUp: {
        serializedName: 'modificationUp',
        type: {
          name: 'Number'
        }
      },
      modificationDown: {
        serializedName: 'modificationDown',
        type: {
          name: 'Number'
        }
      },
      invoiceVoided: {
        serializedName: 'invoiceVoided',
        type: {
          name: 'Number'
        }
      },
      paymentsApplied: {
        serializedName: 'paymentsApplied',
        type: {
          name: 'Number'
        }
      },
      paymentsUnapplied: {
        serializedName: 'paymentsUnapplied',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PaymentsSummary: msRest.CompositeMapper = {
  serializedName: 'PaymentsSummary',
  type: {
    name: 'Composite',
    className: 'PaymentsSummary',
    modelProperties: {
      syntheticsOpened: {
        serializedName: 'syntheticsOpened',
        type: {
          name: 'Number'
        }
      },
      syntheticsClosed: {
        serializedName: 'syntheticsClosed',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AgedSummary: msRest.CompositeMapper = {
  serializedName: 'AgedSummary',
  type: {
    name: 'Composite',
    className: 'AgedSummary',
    modelProperties: {
      invoiceAged: {
        serializedName: 'invoiceAged',
        type: {
          name: 'Number'
        }
      },
      invoiceClosed: {
        serializedName: 'invoiceClosed',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CreditNotesSummary: msRest.CompositeMapper = {
  serializedName: 'CreditNotesSummary',
  type: {
    name: 'Composite',
    className: 'CreditNotesSummary',
    modelProperties: {
      noteOpened: {
        serializedName: 'noteOpened',
        type: {
          name: 'Number'
        }
      },
      noteApplied: {
        serializedName: 'noteApplied',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ContraSummary: msRest.CompositeMapper = {
  serializedName: 'ContraSummary',
  type: {
    name: 'Composite',
    className: 'ContraSummary',
    modelProperties: {
      billOpened: {
        serializedName: 'billOpened',
        type: {
          name: 'Number'
        }
      },
      billClosed: {
        serializedName: 'billClosed',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const UnderReviewSummary: msRest.CompositeMapper = {
  serializedName: 'UnderReviewSummary',
  type: {
    name: 'Composite',
    className: 'UnderReviewSummary',
    modelProperties: {
      intoUnderReview: {
        serializedName: 'intoUnderReview',
        type: {
          name: 'Number'
        }
      },
      outOfUnderReview: {
        serializedName: 'outOfUnderReview',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DisapprovedSummary: msRest.CompositeMapper = {
  serializedName: 'DisapprovedSummary',
  type: {
    name: 'Composite',
    className: 'DisapprovedSummary',
    modelProperties: {
      intoDisapproved: {
        serializedName: 'intoDisapproved',
        type: {
          name: 'Number'
        }
      },
      outOfDisapproved: {
        serializedName: 'outOfDisapproved',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const InternalApprovedSummary: msRest.CompositeMapper = {
  serializedName: 'InternalApprovedSummary',
  type: {
    name: 'Composite',
    className: 'InternalApprovedSummary',
    modelProperties: {
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ConcentrationSummary: msRest.CompositeMapper = {
  serializedName: 'ConcentrationSummary',
  type: {
    name: 'Composite',
    className: 'ConcentrationSummary',
    modelProperties: {
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ApprovedSummary: msRest.CompositeMapper = {
  serializedName: 'ApprovedSummary',
  type: {
    name: 'Composite',
    className: 'ApprovedSummary',
    modelProperties: {
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const FundingRateSummary: msRest.CompositeMapper = {
  serializedName: 'FundingRateSummary',
  type: {
    name: 'Composite',
    className: 'FundingRateSummary',
    modelProperties: {
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const LimitSummary: msRest.CompositeMapper = {
  serializedName: 'LimitSummary',
  type: {
    name: 'Composite',
    className: 'LimitSummary',
    modelProperties: {
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AvailabilitySummary: msRest.CompositeMapper = {
  serializedName: 'AvailabilitySummary',
  type: {
    name: 'Composite',
    className: 'AvailabilitySummary',
    modelProperties: {
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      net: {
        serializedName: 'net',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ActivitySummary: msRest.CompositeMapper = {
  serializedName: 'ActivitySummary',
  type: {
    name: 'Composite',
    className: 'ActivitySummary',
    modelProperties: {
      activityId: {
        serializedName: 'activityId',
        type: {
          name: 'Uuid'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      eventName: {
        serializedName: 'eventName',
        type: {
          name: 'String'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Composite',
          className: 'BalanceSummary'
        }
      },
      invoices: {
        serializedName: 'invoices',
        type: {
          name: 'Composite',
          className: 'InvoicesSummary'
        }
      },
      payments: {
        serializedName: 'payments',
        type: {
          name: 'Composite',
          className: 'PaymentsSummary'
        }
      },
      aged: {
        serializedName: 'aged',
        type: {
          name: 'Composite',
          className: 'AgedSummary'
        }
      },
      creditNotes: {
        serializedName: 'creditNotes',
        type: {
          name: 'Composite',
          className: 'CreditNotesSummary'
        }
      },
      contra: {
        serializedName: 'contra',
        type: {
          name: 'Composite',
          className: 'ContraSummary'
        }
      },
      underReview: {
        serializedName: 'underReview',
        type: {
          name: 'Composite',
          className: 'UnderReviewSummary'
        }
      },
      disapproved: {
        serializedName: 'disapproved',
        type: {
          name: 'Composite',
          className: 'DisapprovedSummary'
        }
      },
      internalApproved: {
        serializedName: 'internalApproved',
        type: {
          name: 'Composite',
          className: 'InternalApprovedSummary'
        }
      },
      concentration: {
        serializedName: 'concentration',
        type: {
          name: 'Composite',
          className: 'ConcentrationSummary'
        }
      },
      approved: {
        serializedName: 'approved',
        type: {
          name: 'Composite',
          className: 'ApprovedSummary'
        }
      },
      fundingRate: {
        serializedName: 'fundingRate',
        type: {
          name: 'Composite',
          className: 'FundingRateSummary'
        }
      },
      limit: {
        serializedName: 'limit',
        type: {
          name: 'Composite',
          className: 'LimitSummary'
        }
      },
      availability: {
        serializedName: 'availability',
        type: {
          name: 'Composite',
          className: 'AvailabilitySummary'
        }
      }
    }
  }
};

export const PricingData: msRest.CompositeMapper = {
  serializedName: 'PricingData',
  type: {
    name: 'Composite',
    className: 'PricingData',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      repaymentOption: {
        serializedName: 'repaymentOption',
        type: {
          name: 'String'
        }
      },
      systemIrr: {
        serializedName: 'systemIrr',
        type: {
          name: 'Number'
        }
      },
      actualIrr: {
        serializedName: 'actualIrr',
        type: {
          name: 'Number'
        }
      },
      varianceIrr: {
        readOnly: true,
        serializedName: 'varianceIrr',
        type: {
          name: 'Number'
        }
      },
      varianceAdvanceRate: {
        readOnly: true,
        serializedName: 'varianceAdvanceRate',
        type: {
          name: 'Number'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      systemAdvanceRate: {
        serializedName: 'systemAdvanceRate',
        type: {
          name: 'Number'
        }
      },
      actualAdvanceRate: {
        serializedName: 'actualAdvanceRate',
        type: {
          name: 'Number'
        }
      },
      probabilityOfWin: {
        serializedName: 'probabilityOfWin',
        type: {
          name: 'Number'
        }
      },
      percentageLoss: {
        serializedName: 'percentageLoss',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const TransactionFile: msRest.CompositeMapper = {
  serializedName: 'TransactionFile',
  type: {
    name: 'Composite',
    className: 'TransactionFile',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'String'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const AutomatedDecisionData: msRest.CompositeMapper = {
  serializedName: 'AutomatedDecisionData',
  type: {
    name: 'Composite',
    className: 'AutomatedDecisionData',
    modelProperties: {
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      cancelReason: {
        serializedName: 'cancelReason',
        type: {
          name: 'String'
        }
      },
      automatedDecision: {
        serializedName: 'automatedDecision',
        type: {
          name: 'String'
        }
      },
      automatedDecisionProduct: {
        serializedName: 'automatedDecisionProduct',
        type: {
          name: 'String'
        }
      },
      automatedDecisionLimit: {
        serializedName: 'automatedDecisionLimit',
        type: {
          name: 'Number'
        }
      },
      automatedDecisionNotes: {
        serializedName: 'automatedDecisionNotes',
        type: {
          name: 'String'
        }
      },
      currentDecision: {
        serializedName: 'currentDecision',
        type: {
          name: 'String'
        }
      },
      currentDecisionProduct: {
        serializedName: 'currentDecisionProduct',
        type: {
          name: 'String'
        }
      },
      currentDecisionLimit: {
        serializedName: 'currentDecisionLimit',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const AutomatedDecisionStatistics: msRest.CompositeMapper = {
  serializedName: 'AutomatedDecisionStatistics',
  type: {
    name: 'Composite',
    className: 'AutomatedDecisionStatistics',
    modelProperties: {
      acceptedOrDeclined: {
        serializedName: 'acceptedOrDeclined',
        type: {
          name: 'Number'
        }
      },
      totalOfApplication: {
        serializedName: 'totalOfApplication',
        type: {
          name: 'Number'
        }
      },
      autoDecisionRate: {
        readOnly: true,
        serializedName: 'autoDecisionRate',
        type: {
          name: 'Number'
        }
      },
      countSystemAcceptedAndPlexeDeclined: {
        serializedName: 'countSystemAcceptedAndPlexeDeclined',
        type: {
          name: 'Number'
        }
      },
      countSystemDeclineAndPlexeAccepted: {
        serializedName: 'countSystemDeclineAndPlexeAccepted',
        type: {
          name: 'Number'
        }
      },
      totalIncorrectDecision: {
        readOnly: true,
        serializedName: 'totalIncorrectDecision',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CustomerData: msRest.CompositeMapper = {
  serializedName: 'CustomerData',
  type: {
    name: 'Composite',
    className: 'CustomerData',
    modelProperties: {
      message: {
        serializedName: 'message',
        type: {
          name: 'String'
        }
      },
      adminUser: {
        serializedName: 'adminUser',
        type: {
          name: 'String'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      applicationId: {
        serializedName: 'applicationId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const ArrearsReportData: msRest.CompositeMapper = {
  serializedName: 'ArrearsReportData',
  type: {
    name: 'Composite',
    className: 'ArrearsReportData',
    modelProperties: {
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      loadId: {
        serializedName: 'loadId',
        type: {
          name: 'Uuid'
        }
      },
      principalBalance: {
        serializedName: 'principalBalance',
        type: {
          name: 'Number'
        }
      },
      totalArrears: {
        serializedName: 'totalArrears',
        type: {
          name: 'Number'
        }
      },
      days: {
        serializedName: 'days',
        type: {
          name: 'Number'
        }
      },
      action: {
        serializedName: 'action',
        type: {
          name: 'Number'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      nextReminder: {
        serializedName: 'nextReminder',
        type: {
          name: 'DateTime'
        }
      },
      actionDisplay: {
        readOnly: true,
        serializedName: 'actionDisplay',
        type: {
          name: 'String'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      advanceName: {
        serializedName: 'advanceName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ClosingBalance: msRest.CompositeMapper = {
  serializedName: 'ClosingBalance',
  type: {
    name: 'Composite',
    className: 'ClosingBalance',
    modelProperties: {
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Dilution: msRest.CompositeMapper = {
  serializedName: 'Dilution',
  type: {
    name: 'Composite',
    className: 'Dilution',
    modelProperties: {
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      count: {
        serializedName: 'count',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DriftMetric: msRest.CompositeMapper = {
  serializedName: 'DriftMetric',
  type: {
    name: 'Composite',
    className: 'DriftMetric',
    modelProperties: {
      metric: {
        readOnly: true,
        serializedName: 'metric',
        type: {
          name: 'String'
        }
      },
      weight: {
        readOnly: true,
        serializedName: 'weight',
        type: {
          name: 'Number'
        }
      },
      direction: {
        readOnly: true,
        serializedName: 'direction',
        type: {
          name: 'Number'
        }
      },
      startDateValue: {
        readOnly: true,
        serializedName: 'startDateValue',
        type: {
          name: 'Number'
        }
      },
      endDateValue: {
        readOnly: true,
        serializedName: 'endDateValue',
        type: {
          name: 'Number'
        }
      },
      change: {
        readOnly: true,
        serializedName: 'change',
        type: {
          name: 'Number'
        }
      },
      weightedChange: {
        readOnly: true,
        serializedName: 'weightedChange',
        type: {
          name: 'Number'
        }
      },
      adjustedWeightedChange: {
        readOnly: true,
        serializedName: 'adjustedWeightedChange',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DriftMetricList: msRest.CompositeMapper = {
  serializedName: 'DriftMetricList',
  type: {
    name: 'Composite',
    className: 'DriftMetricList',
    modelProperties: {
      metricList: {
        readOnly: true,
        serializedName: 'metricList',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'DriftMetric'
            }
          }
        }
      },
      totalAdjustedWeightedChange: {
        readOnly: true,
        serializedName: 'totalAdjustedWeightedChange',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DebtorDayDetail: msRest.CompositeMapper = {
  serializedName: 'DebtorDayDetail',
  type: {
    name: 'Composite',
    className: 'DebtorDayDetail',
    modelProperties: {
      invoiceCount: {
        serializedName: 'invoiceCount',
        type: {
          name: 'Number'
        }
      },
      totalDays: {
        serializedName: 'totalDays',
        type: {
          name: 'Number'
        }
      },
      totalAmount: {
        serializedName: 'totalAmount',
        type: {
          name: 'Number'
        }
      },
      averageDays: {
        readOnly: true,
        serializedName: 'averageDays',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const DebtorDayDetails: msRest.CompositeMapper = {
  serializedName: 'DebtorDayDetails',
  type: {
    name: 'Composite',
    className: 'DebtorDayDetails',
    modelProperties: {
      total: {
        serializedName: 'total',
        type: {
          name: 'Composite',
          className: 'DebtorDayDetail'
        }
      },
      q1: {
        serializedName: 'q1',
        type: {
          name: 'Composite',
          className: 'DebtorDayDetail'
        }
      },
      q2: {
        serializedName: 'q2',
        type: {
          name: 'Composite',
          className: 'DebtorDayDetail'
        }
      },
      q3: {
        serializedName: 'q3',
        type: {
          name: 'Composite',
          className: 'DebtorDayDetail'
        }
      },
      q4: {
        serializedName: 'q4',
        type: {
          name: 'Composite',
          className: 'DebtorDayDetail'
        }
      }
    }
  }
};

export const DebtorDayValue: msRest.CompositeMapper = {
  serializedName: 'DebtorDayValue',
  type: {
    name: 'Composite',
    className: 'DebtorDayValue',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      nominated: {
        serializedName: 'nominated',
        type: {
          name: 'Boolean'
        }
      },
      closed: {
        serializedName: 'closed',
        type: {
          name: 'Composite',
          className: 'DebtorDayDetails'
        }
      },
      open: {
        serializedName: 'open',
        type: {
          name: 'Composite',
          className: 'DebtorDayDetails'
        }
      }
    }
  }
};

export const ConnectionStatus: msRest.CompositeMapper = {
  serializedName: 'ConnectionStatus',
  type: {
    name: 'Composite',
    className: 'ConnectionStatus',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      provider: {
        serializedName: 'provider',
        type: {
          name: 'String'
        }
      },
      bankConnected: {
        serializedName: 'bankConnected',
        type: {
          name: 'Boolean'
        }
      },
      accountingConnected: {
        serializedName: 'accountingConnected',
        type: {
          name: 'Boolean'
        }
      },
      accountingLastUpdated: {
        serializedName: 'accountingLastUpdated',
        type: {
          name: 'DateTime'
        }
      },
      bankLastUpdated: {
        serializedName: 'bankLastUpdated',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const ProvisoData: msRest.CompositeMapper = {
  serializedName: 'ProvisoData',
  type: {
    name: 'Composite',
    className: 'ProvisoData',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      eventDate: {
        serializedName: 'eventDate',
        type: {
          name: 'DateTime'
        }
      },
      eventName: {
        serializedName: 'eventName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const LoanBalances: msRest.CompositeMapper = {
  serializedName: 'LoanBalances',
  type: {
    name: 'Composite',
    className: 'LoanBalances',
    modelProperties: {
      availableFunds: {
        readOnly: true,
        serializedName: 'availableFunds',
        type: {
          name: 'Number'
        }
      },
      systemPricing: {
        serializedName: 'systemPricing',
        type: {
          name: 'Number'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      totalLimit: {
        readOnly: true,
        serializedName: 'totalLimit',
        type: {
          name: 'Number'
        }
      },
      limits: {
        serializedName: 'limits',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Number'
            }
          }
        }
      },
      rawTotal: {
        serializedName: 'rawTotal',
        type: {
          name: 'Number'
        }
      },
      adjustment: {
        serializedName: 'adjustment',
        type: {
          name: 'Number'
        }
      },
      totalDebtors: {
        serializedName: 'totalDebtors',
        type: {
          name: 'Number'
        }
      },
      totalFundingRate: {
        serializedName: 'totalFundingRate',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const ApplicationEvent: msRest.CompositeMapper = {
  serializedName: 'ApplicationEvent',
  type: {
    name: 'Composite',
    className: 'ApplicationEvent',
    modelProperties: {
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      organisation: {
        serializedName: 'organisation',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'String'
        }
      },
      partner: {
        serializedName: 'partner',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const LoanEvent: msRest.CompositeMapper = {
  serializedName: 'LoanEvent',
  type: {
    name: 'Composite',
    className: 'LoanEvent',
    modelProperties: {
      available: {
        serializedName: 'available',
        type: {
          name: 'Number'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      repaymentType: {
        serializedName: 'repaymentType',
        type: {
          name: 'String'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      organisation: {
        serializedName: 'organisation',
        type: {
          name: 'String'
        }
      },
      receivableLimit: {
        serializedName: 'receivableLimit',
        type: {
          name: 'Number'
        }
      },
      cashflowLimit: {
        serializedName: 'cashflowLimit',
        type: {
          name: 'Number'
        }
      },
      preceivableLimit: {
        serializedName: 'preceivableLimit',
        type: {
          name: 'Number'
        }
      },
      inventoryLimit: {
        serializedName: 'inventoryLimit',
        type: {
          name: 'Number'
        }
      },
      receivableBalance: {
        serializedName: 'receivableBalance',
        type: {
          name: 'Number'
        }
      },
      receivableAvailable: {
        serializedName: 'receivableAvailable',
        type: {
          name: 'Number'
        }
      },
      percentageBalance: {
        serializedName: 'percentageBalance',
        type: {
          name: 'Number'
        }
      },
      percentageAvailable: {
        serializedName: 'percentageAvailable',
        type: {
          name: 'Number'
        }
      },
      cashflowBalance: {
        serializedName: 'cashflowBalance',
        type: {
          name: 'Number'
        }
      },
      cashflowAvailable: {
        serializedName: 'cashflowAvailable',
        type: {
          name: 'Number'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      totalLimit: {
        serializedName: 'totalLimit',
        type: {
          name: 'Number'
        }
      },
      override: {
        serializedName: 'override',
        type: {
          name: 'Number'
        }
      },
      receivablesApproved: {
        serializedName: 'receivablesApproved',
        type: {
          name: 'Number'
        }
      },
      receivablesTotal: {
        serializedName: 'receivablesTotal',
        type: {
          name: 'Number'
        }
      },
      overAdvance: {
        serializedName: 'overAdvance',
        type: {
          name: 'Number'
        }
      },
      receivablesPayments: {
        serializedName: 'receivablesPayments',
        type: {
          name: 'Number'
        }
      },
      receivablesDisapproved: {
        serializedName: 'receivablesDisapproved',
        type: {
          name: 'Number'
        }
      },
      receivablesReceivablesAged: {
        serializedName: 'receivablesReceivablesAged',
        type: {
          name: 'Number'
        }
      },
      receivablesInternallyApproved: {
        serializedName: 'receivablesInternallyApproved',
        type: {
          name: 'Number'
        }
      },
      receivablesContra: {
        serializedName: 'receivablesContra',
        type: {
          name: 'Number'
        }
      },
      receivablesCreditsNotes: {
        serializedName: 'receivablesCreditsNotes',
        type: {
          name: 'Number'
        }
      },
      receivablesConcentration: {
        serializedName: 'receivablesConcentration',
        type: {
          name: 'Number'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'String'
        }
      },
      partner: {
        serializedName: 'partner',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const ApplicationAcitvityState: msRest.CompositeMapper = {
  serializedName: 'ApplicationAcitvityState',
  type: {
    name: 'Composite',
    className: 'ApplicationAcitvityState',
    modelProperties: {
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'String'
        }
      },
      partner: {
        serializedName: 'partner',
        type: {
          name: 'String'
        }
      },
      daysLastActivity: {
        serializedName: 'daysLastActivity',
        type: {
          name: 'Number'
        }
      },
      dateLastAcivity: {
        serializedName: 'dateLastAcivity',
        type: {
          name: 'DateTime'
        }
      },
      lastActivity: {
        serializedName: 'lastActivity',
        type: {
          name: 'String'
        }
      },
      organsation: {
        serializedName: 'organsation',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const AuditData: msRest.CompositeMapper = {
  serializedName: 'AuditData',
  type: {
    name: 'Composite',
    className: 'AuditData',
    modelProperties: {
      setting: {
        serializedName: 'setting',
        type: {
          name: 'String'
        }
      },
      existingValue: {
        serializedName: 'existingValue',
        type: {
          name: 'String'
        }
      },
      newValue: {
        serializedName: 'newValue',
        type: {
          name: 'String'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      }
    }
  }
};

export const TransactionDetails: msRest.CompositeMapper = {
  serializedName: 'TransactionDetails',
  type: {
    name: 'Composite',
    className: 'TransactionDetails',
    modelProperties: {
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      advanceName: {
        serializedName: 'advanceName',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      reconciled: {
        serializedName: 'reconciled',
        type: {
          name: 'String'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const LoanAcitvityState: msRest.CompositeMapper = {
  serializedName: 'LoanAcitvityState',
  type: {
    name: 'Composite',
    className: 'LoanAcitvityState',
    modelProperties: {
      available: {
        serializedName: 'available',
        type: {
          name: 'Number'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      repaymentType: {
        serializedName: 'repaymentType',
        type: {
          name: 'String'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      title: {
        serializedName: 'title',
        type: {
          name: 'String'
        }
      },
      customer: {
        serializedName: 'customer',
        type: {
          name: 'String'
        }
      },
      partner: {
        serializedName: 'partner',
        type: {
          name: 'String'
        }
      },
      daysLastActivity: {
        serializedName: 'daysLastActivity',
        type: {
          name: 'Number'
        }
      },
      dateLastAcivity: {
        serializedName: 'dateLastAcivity',
        type: {
          name: 'DateTime'
        }
      },
      lastActivity: {
        serializedName: 'lastActivity',
        type: {
          name: 'String'
        }
      },
      organsation: {
        serializedName: 'organsation',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const BalanceData: msRest.CompositeMapper = {
  serializedName: 'BalanceData',
  type: {
    name: 'Composite',
    className: 'BalanceData',
    modelProperties: {
      closing: {
        serializedName: 'closing',
        type: {
          name: 'Number'
        }
      },
      outstanding: {
        serializedName: 'outstanding',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PortfolioMetricPerDate: msRest.CompositeMapper = {
  serializedName: 'PortfolioMetricPerDate',
  type: {
    name: 'Composite',
    className: 'PortfolioMetricPerDate',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      dates: {
        serializedName: 'dates',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Number'
            }
          }
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      },
      metric: {
        serializedName: 'metric',
        type: {
          name: 'String'
        }
      },
      enabled: {
        serializedName: 'enabled',
        type: {
          name: 'Boolean'
        }
      }
    }
  }
};

export const LoanBook: msRest.CompositeMapper = {
  serializedName: 'LoanBook',
  type: {
    name: 'Composite',
    className: 'LoanBook',
    modelProperties: {
      interestRate: {
        serializedName: 'interestRate',
        type: {
          name: 'Number'
        }
      },
      interestRateAfterCommission: {
        serializedName: 'interestRateAfterCommission',
        type: {
          name: 'Number'
        }
      },
      customerName: {
        serializedName: 'customerName',
        type: {
          name: 'String'
        }
      },
      customerMobile: {
        serializedName: 'customerMobile',
        type: {
          name: 'String'
        }
      },
      customerEmail: {
        serializedName: 'customerEmail',
        type: {
          name: 'String'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      establishmentFee: {
        serializedName: 'establishmentFee',
        type: {
          name: 'Number'
        }
      },
      commission: {
        serializedName: 'commission',
        type: {
          name: 'Number'
        }
      },
      commissionExcludingEstablishmentFee: {
        serializedName: 'commissionExcludingEstablishmentFee',
        type: {
          name: 'Number'
        }
      },
      totalCommission: {
        serializedName: 'totalCommission',
        type: {
          name: 'Number'
        }
      },
      remainingTerm: {
        serializedName: 'remainingTerm',
        type: {
          name: 'Number'
        }
      },
      firstDirectDebit: {
        serializedName: 'firstDirectDebit',
        type: {
          name: 'DateTime'
        }
      },
      lastDirectDebit: {
        serializedName: 'lastDirectDebit',
        type: {
          name: 'DateTime'
        }
      },
      weeklyRepayment: {
        serializedName: 'weeklyRepayment',
        type: {
          name: 'Number'
        }
      },
      weightedAvergageRate: {
        serializedName: 'weightedAvergageRate',
        type: {
          name: 'Number'
        }
      },
      weightedAvergageRateNow: {
        serializedName: 'weightedAvergageRateNow',
        type: {
          name: 'Number'
        }
      },
      totalRepayment: {
        serializedName: 'totalRepayment',
        type: {
          name: 'Number'
        }
      },
      establishmentAmount: {
        serializedName: 'establishmentAmount',
        type: {
          name: 'Number'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      advanceAmount: {
        serializedName: 'advanceAmount',
        type: {
          name: 'Number'
        }
      },
      term: {
        serializedName: 'term',
        type: {
          name: 'Number'
        }
      },
      repaymentOption: {
        serializedName: 'repaymentOption',
        type: {
          name: 'String'
        }
      },
      accountingDataSource: {
        serializedName: 'accountingDataSource',
        type: {
          name: 'String'
        }
      },
      entity: {
        serializedName: 'entity',
        type: {
          name: 'String'
        }
      },
      loanDisbursementDate: {
        serializedName: 'loanDisbursementDate',
        type: {
          name: 'DateTime'
        }
      },
      monthlyFee: {
        serializedName: 'monthlyFee',
        type: {
          name: 'Number'
        }
      },
      totalInterest: {
        serializedName: 'totalInterest',
        type: {
          name: 'Number'
        }
      },
      totalPrincipal: {
        serializedName: 'totalPrincipal',
        type: {
          name: 'Number'
        }
      },
      totalRepayments: {
        serializedName: 'totalRepayments',
        type: {
          name: 'Number'
        }
      },
      collectiveAdvanceAmount: {
        serializedName: 'collectiveAdvanceAmount',
        type: {
          name: 'Number'
        }
      },
      totalRepayment2: {
        serializedName: 'totalRepayment2',
        type: {
          name: 'Number'
        }
      },
      totalRepaymentToDate: {
        serializedName: 'totalRepaymentToDate',
        type: {
          name: 'Number'
        }
      },
      totalRepaymentToASpecificedDate: {
        serializedName: 'totalRepaymentToASpecificedDate',
        type: {
          name: 'Number'
        }
      },
      daysArrear: {
        serializedName: 'daysArrear',
        type: {
          name: 'Number'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      settlementDate: {
        serializedName: 'settlementDate',
        type: {
          name: 'DateTime'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      state: {
        serializedName: 'state',
        type: {
          name: 'String'
        }
      },
      industry: {
        serializedName: 'industry',
        type: {
          name: 'String'
        }
      },
      outstandingBalance: {
        serializedName: 'outstandingBalance',
        type: {
          name: 'Number'
        }
      },
      yearsInBusiness: {
        serializedName: 'yearsInBusiness',
        type: {
          name: 'String'
        }
      },
      annualTurnOver: {
        serializedName: 'annualTurnOver',
        type: {
          name: 'Number'
        }
      },
      partnerName: {
        serializedName: 'partnerName',
        type: {
          name: 'String'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      probOfWin: {
        serializedName: 'probOfWin',
        type: {
          name: 'Number'
        }
      },
      expectedValue: {
        serializedName: 'expectedValue',
        type: {
          name: 'Number'
        }
      },
      totalOutstandingBalance: {
        serializedName: 'totalOutstandingBalance',
        type: {
          name: 'Number'
        }
      },
      vedaScore: {
        serializedName: 'vedaScore',
        type: {
          name: 'Number'
        }
      },
      facilityLimit: {
        serializedName: 'facilityLimit',
        type: {
          name: 'Number'
        }
      },
      creditLimit: {
        serializedName: 'creditLimit',
        type: {
          name: 'Number'
        }
      },
      availableCredit: {
        serializedName: 'availableCredit',
        type: {
          name: 'Number'
        }
      },
      arrearsGreatThan7Days: {
        serializedName: 'arrearsGreatThan7Days',
        type: {
          name: 'Number'
        }
      },
      arrearsGreatThan14Days: {
        serializedName: 'arrearsGreatThan14Days',
        type: {
          name: 'Number'
        }
      },
      arrearsGreatThan21Days: {
        serializedName: 'arrearsGreatThan21Days',
        type: {
          name: 'Number'
        }
      },
      partnerBusinessName: {
        serializedName: 'partnerBusinessName',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      loanCreationDate: {
        serializedName: 'loanCreationDate',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const OverrideValues: msRest.CompositeMapper = {
  serializedName: 'OverrideValues',
  type: {
    name: 'Composite',
    className: 'OverrideValues',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      overrideCashflow: {
        serializedName: 'overrideCashflow',
        type: {
          name: 'Number'
        }
      },
      actualCashflow: {
        serializedName: 'actualCashflow',
        type: {
          name: 'Number'
        }
      },
      cashflowDifference: {
        readOnly: true,
        serializedName: 'cashflowDifference',
        type: {
          name: 'Number'
        }
      },
      overrideInventory: {
        serializedName: 'overrideInventory',
        type: {
          name: 'Number'
        }
      },
      actualInventory: {
        serializedName: 'actualInventory',
        type: {
          name: 'Number'
        }
      },
      inventoryDifference: {
        readOnly: true,
        serializedName: 'inventoryDifference',
        type: {
          name: 'Number'
        }
      },
      overridePreceivables: {
        serializedName: 'overridePreceivables',
        type: {
          name: 'Number'
        }
      },
      actualPreceivables: {
        serializedName: 'actualPreceivables',
        type: {
          name: 'Number'
        }
      },
      preceivablesDifference: {
        readOnly: true,
        serializedName: 'preceivablesDifference',
        type: {
          name: 'Number'
        }
      },
      overrideReceivables: {
        serializedName: 'overrideReceivables',
        type: {
          name: 'Number'
        }
      },
      actualReceivables: {
        serializedName: 'actualReceivables',
        type: {
          name: 'Number'
        }
      },
      receivablesDifference: {
        readOnly: true,
        serializedName: 'receivablesDifference',
        type: {
          name: 'Number'
        }
      },
      loanType: {
        serializedName: 'loanType',
        type: {
          name: 'String'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      cappedCashFlow: {
        serializedName: 'cappedCashFlow',
        type: {
          name: 'Number'
        }
      },
      amortisedCashFlow: {
        serializedName: 'amortisedCashFlow',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const LoanBookSummary: msRest.CompositeMapper = {
  serializedName: 'LoanBookSummary',
  type: {
    name: 'Composite',
    className: 'LoanBookSummary',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      customerId: {
        serializedName: 'customerId',
        type: {
          name: 'Uuid'
        }
      },
      settlementDate: {
        serializedName: 'settlementDate',
        type: {
          name: 'DateTime'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      state: {
        serializedName: 'state',
        type: {
          name: 'String'
        }
      },
      industry: {
        serializedName: 'industry',
        type: {
          name: 'String'
        }
      },
      outstandingBalance: {
        serializedName: 'outstandingBalance',
        type: {
          name: 'Number'
        }
      },
      yearsInBusiness: {
        serializedName: 'yearsInBusiness',
        type: {
          name: 'String'
        }
      },
      annualTurnOver: {
        serializedName: 'annualTurnOver',
        type: {
          name: 'Number'
        }
      },
      partnerName: {
        serializedName: 'partnerName',
        type: {
          name: 'String'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Number'
        }
      },
      probOfWin: {
        serializedName: 'probOfWin',
        type: {
          name: 'Number'
        }
      },
      expectedValue: {
        serializedName: 'expectedValue',
        type: {
          name: 'Number'
        }
      },
      totalOutstandingBalance: {
        serializedName: 'totalOutstandingBalance',
        type: {
          name: 'Number'
        }
      },
      vedaScore: {
        serializedName: 'vedaScore',
        type: {
          name: 'Number'
        }
      },
      facilityLimit: {
        serializedName: 'facilityLimit',
        type: {
          name: 'Number'
        }
      },
      creditLimit: {
        serializedName: 'creditLimit',
        type: {
          name: 'Number'
        }
      },
      availableCredit: {
        serializedName: 'availableCredit',
        type: {
          name: 'Number'
        }
      },
      arrearsGreatThan7Days: {
        serializedName: 'arrearsGreatThan7Days',
        type: {
          name: 'Number'
        }
      },
      arrearsGreatThan14Days: {
        serializedName: 'arrearsGreatThan14Days',
        type: {
          name: 'Number'
        }
      },
      arrearsGreatThan21Days: {
        serializedName: 'arrearsGreatThan21Days',
        type: {
          name: 'Number'
        }
      },
      partnerBusinessName: {
        serializedName: 'partnerBusinessName',
        type: {
          name: 'String'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'String'
        }
      },
      loanCreationDate: {
        serializedName: 'loanCreationDate',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Repayment: msRest.CompositeMapper = {
  serializedName: 'Repayment',
  type: {
    name: 'Composite',
    className: 'Repayment',
    modelProperties: {
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      bankName: {
        serializedName: 'bankName',
        type: {
          name: 'String'
        }
      },
      bankBSB: {
        serializedName: 'bankBSB',
        type: {
          name: 'String'
        }
      },
      bankAccountNumber: {
        serializedName: 'bankAccountNumber',
        type: {
          name: 'String'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      advanceName: {
        serializedName: 'advanceName',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      details: {
        serializedName: 'details',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const TransactionDataFile: msRest.CompositeMapper = {
  serializedName: 'TransactionDataFile',
  type: {
    name: 'Composite',
    className: 'TransactionDataFile',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'ByteArray'
        }
      },
      fileName: {
        serializedName: 'fileName',
        type: {
          name: 'String'
        }
      },
      fileType: {
        serializedName: 'fileType',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const Limits: msRest.CompositeMapper = {
  serializedName: 'Limits',
  type: {
    name: 'Composite',
    className: 'Limits',
    modelProperties: {
      calculated: {
        serializedName: 'calculated',
        type: {
          name: 'Number'
        }
      },
      override: {
        serializedName: 'override',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const CreditLimit: msRest.CompositeMapper = {
  serializedName: 'CreditLimit',
  type: {
    name: 'Composite',
    className: 'CreditLimit',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      name: {
        serializedName: 'name',
        type: {
          name: 'String'
        }
      },
      cashFlow: {
        serializedName: 'cashFlow',
        type: {
          name: 'Composite',
          className: 'Limits'
        }
      },
      receivables: {
        serializedName: 'receivables',
        type: {
          name: 'Composite',
          className: 'Limits'
        }
      },
      preceivables: {
        serializedName: 'preceivables',
        type: {
          name: 'Composite',
          className: 'Limits'
        }
      },
      inventory: {
        serializedName: 'inventory',
        type: {
          name: 'Composite',
          className: 'Limits'
        }
      }
    }
  }
};

export const AdvanceDetails: msRest.CompositeMapper = {
  serializedName: 'AdvanceDetails',
  type: {
    name: 'Composite',
    className: 'AdvanceDetails',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      customerName: {
        serializedName: 'customerName',
        type: {
          name: 'String'
        }
      },
      advanceId: {
        serializedName: 'advanceId',
        type: {
          name: 'Uuid'
        }
      },
      percent: {
        serializedName: 'percent',
        type: {
          name: 'Number'
        }
      },
      priority: {
        serializedName: 'priority',
        type: {
          name: 'Boolean'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      businessName: {
        serializedName: 'businessName',
        type: {
          name: 'String'
        }
      },
      type: {
        serializedName: 'type',
        type: {
          name: 'String'
        }
      },
      term: {
        serializedName: 'term',
        type: {
          name: 'Number'
        }
      },
      firstDirectDebit: {
        serializedName: 'firstDirectDebit',
        type: {
          name: 'DateTime'
        }
      },
      lastDirectDebit: {
        serializedName: 'lastDirectDebit',
        type: {
          name: 'DateTime'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      advanceRate: {
        serializedName: 'advanceRate',
        type: {
          name: 'Number'
        }
      },
      day: {
        serializedName: 'day',
        type: {
          name: 'String'
        }
      },
      weeklyAmount: {
        serializedName: 'weeklyAmount',
        type: {
          name: 'Number'
        }
      },
      shortCode: {
        serializedName: 'shortCode',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const InterestPrincipal: msRest.CompositeMapper = {
  serializedName: 'InterestPrincipal',
  type: {
    name: 'Composite',
    className: 'InterestPrincipal',
    modelProperties: {
      contactName: {
        serializedName: 'contactName',
        type: {
          name: 'String'
        }
      },
      emailAddress: {
        serializedName: 'emailAddress',
        type: {
          name: 'String'
        }
      },
      poAddressLine1: {
        readOnly: true,
        serializedName: 'poAddressLine1',
        type: {
          name: 'String'
        }
      },
      poAddressLine2: {
        readOnly: true,
        serializedName: 'poAddressLine2',
        type: {
          name: 'String'
        }
      },
      poAddressLine3: {
        readOnly: true,
        serializedName: 'poAddressLine3',
        type: {
          name: 'String'
        }
      },
      poAddressLine4: {
        readOnly: true,
        serializedName: 'poAddressLine4',
        type: {
          name: 'String'
        }
      },
      poCity: {
        readOnly: true,
        serializedName: 'poCity',
        type: {
          name: 'String'
        }
      },
      poPostalCode: {
        readOnly: true,
        serializedName: 'poPostalCode',
        type: {
          name: 'String'
        }
      },
      poCountry: {
        readOnly: true,
        serializedName: 'poCountry',
        type: {
          name: 'String'
        }
      },
      invoiceNumber: {
        serializedName: 'invoiceNumber',
        type: {
          name: 'String'
        }
      },
      reference: {
        readOnly: true,
        serializedName: 'reference',
        type: {
          name: 'String'
        }
      },
      invoiceDate: {
        readOnly: true,
        serializedName: 'invoiceDate',
        type: {
          name: 'DateTime'
        }
      },
      dueDate: {
        readOnly: true,
        serializedName: 'dueDate',
        type: {
          name: 'DateTime'
        }
      },
      inventoryItemCode: {
        readOnly: true,
        serializedName: 'inventoryItemCode',
        type: {
          name: 'String'
        }
      },
      description: {
        serializedName: 'description',
        type: {
          name: 'String'
        }
      },
      qty: {
        readOnly: true,
        serializedName: 'qty',
        type: {
          name: 'Number'
        }
      },
      unitAmount: {
        readOnly: true,
        serializedName: 'unitAmount',
        type: {
          name: 'Number'
        }
      },
      discount: {
        readOnly: true,
        serializedName: 'discount',
        type: {
          name: 'String'
        }
      },
      accountCode: {
        serializedName: 'accountCode',
        type: {
          name: 'String'
        }
      },
      taxType: {
        readOnly: true,
        serializedName: 'taxType',
        type: {
          name: 'String'
        }
      },
      trackingName1: {
        readOnly: true,
        serializedName: 'trackingName1',
        type: {
          name: 'String'
        }
      },
      trackingOption1: {
        readOnly: true,
        serializedName: 'trackingOption1',
        type: {
          name: 'String'
        }
      },
      trackingName2: {
        readOnly: true,
        serializedName: 'trackingName2',
        type: {
          name: 'String'
        }
      },
      trackingOption2: {
        readOnly: true,
        serializedName: 'trackingOption2',
        type: {
          name: 'String'
        }
      },
      currency: {
        readOnly: true,
        serializedName: 'currency',
        type: {
          name: 'String'
        }
      },
      brandingTheme: {
        readOnly: true,
        serializedName: 'brandingTheme',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      }
    }
  }
};

export const CustomerAudit: msRest.CompositeMapper = {
  serializedName: 'CustomerAudit',
  type: {
    name: 'Composite',
    className: 'CustomerAudit',
    modelProperties: {
      user: {
        serializedName: 'user',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      eventName: {
        serializedName: 'eventName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PagedListCustomerAudit: msRest.CompositeMapper = {
  serializedName: 'PagedList_CustomerAudit_',
  type: {
    name: 'Composite',
    className: 'PagedListCustomerAudit',
    modelProperties: {
      data: {
        serializedName: 'data',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'CustomerAudit'
            }
          }
        }
      },
      totalCount: {
        serializedName: 'totalCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsPaymentInvoice: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsPaymentInvoice',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsPaymentInvoice',
    modelProperties: {
      amountApplied: {
        serializedName: 'amountApplied',
        type: {
          name: 'Number'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      number: {
        serializedName: 'number',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsPayment: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsPayment',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsPayment',
    modelProperties: {
      type: {
        serializedName: 'type',
        type: {
          name: 'Number'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Number'
        }
      },
      currencyRate: {
        serializedName: 'currencyRate',
        type: {
          name: 'Number'
        }
      },
      bankAmount: {
        serializedName: 'bankAmount',
        type: {
          name: 'Number'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      reference: {
        serializedName: 'reference',
        type: {
          name: 'String'
        }
      },
      isReconciled: {
        serializedName: 'isReconciled',
        type: {
          name: 'Boolean'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      account: {
        serializedName: 'account',
        type: {
          name: 'String'
        }
      },
      updatedDate: {
        serializedName: 'updatedDate',
        type: {
          name: 'DateTime'
        }
      },
      retrievedDate: {
        serializedName: 'retrievedDate',
        type: {
          name: 'DateTime'
        }
      },
      invoices: {
        serializedName: 'invoices',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeAccountingProxyModelsPaymentInvoice'
            }
          }
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsLineItem: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsLineItem',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsLineItem',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      description: {
        serializedName: 'description',
        type: {
          name: 'String'
        }
      },
      discountRate: {
        serializedName: 'discountRate',
        type: {
          name: 'Number'
        }
      },
      itemCode: {
        serializedName: 'itemCode',
        type: {
          name: 'String'
        }
      },
      lineAmount: {
        serializedName: 'lineAmount',
        type: {
          name: 'Number'
        }
      },
      quantity: {
        serializedName: 'quantity',
        type: {
          name: 'Number'
        }
      },
      taxAmount: {
        serializedName: 'taxAmount',
        type: {
          name: 'Number'
        }
      },
      unitAmount: {
        serializedName: 'unitAmount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsBalance: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsBalance',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsBalance',
    modelProperties: {
      outstanding: {
        serializedName: 'outstanding',
        type: {
          name: 'Number'
        }
      },
      overdue: {
        serializedName: 'overdue',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsBalances: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsBalances',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsBalances',
    modelProperties: {
      accountsReceivable: {
        serializedName: 'accountsReceivable',
        type: {
          name: 'Composite',
          className: 'PlexeAccountingProxyModelsBalance'
        }
      },
      accountsPayable: {
        serializedName: 'accountsPayable',
        type: {
          name: 'Composite',
          className: 'PlexeAccountingProxyModelsBalance'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsAddress: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsAddress',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsAddress',
    modelProperties: {
      addressType: {
        serializedName: 'addressType',
        type: {
          name: 'String'
        }
      },
      addressLine1: {
        serializedName: 'addressLine1',
        type: {
          name: 'String'
        }
      },
      addressLine2: {
        serializedName: 'addressLine2',
        type: {
          name: 'String'
        }
      },
      addressLine3: {
        serializedName: 'addressLine3',
        type: {
          name: 'String'
        }
      },
      addressLine4: {
        serializedName: 'addressLine4',
        type: {
          name: 'String'
        }
      },
      city: {
        serializedName: 'city',
        type: {
          name: 'String'
        }
      },
      state: {
        serializedName: 'state',
        type: {
          name: 'String'
        }
      },
      region: {
        serializedName: 'region',
        type: {
          name: 'String'
        }
      },
      postalCode: {
        serializedName: 'postalCode',
        type: {
          name: 'String'
        }
      },
      country: {
        serializedName: 'country',
        type: {
          name: 'String'
        }
      },
      attentionTo: {
        serializedName: 'attentionTo',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsPhone: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsPhone',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsPhone',
    modelProperties: {
      phoneType: {
        serializedName: 'phoneType',
        type: {
          name: 'Number'
        }
      },
      phoneNumber: {
        serializedName: 'phoneNumber',
        type: {
          name: 'String'
        }
      },
      phoneAreaCode: {
        serializedName: 'phoneAreaCode',
        type: {
          name: 'String'
        }
      },
      phoneCountryCode: {
        serializedName: 'phoneCountryCode',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsContact: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsContact',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsContact',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      firstName: {
        serializedName: 'firstName',
        type: {
          name: 'String'
        }
      },
      lastName: {
        serializedName: 'lastName',
        type: {
          name: 'String'
        }
      },
      abn: {
        serializedName: 'abn',
        type: {
          name: 'String'
        }
      },
      acn: {
        serializedName: 'acn',
        type: {
          name: 'String'
        }
      },
      companyName: {
        serializedName: 'companyName',
        type: {
          name: 'String'
        }
      },
      notes: {
        serializedName: 'notes',
        type: {
          name: 'String'
        }
      },
      balance: {
        serializedName: 'balance',
        type: {
          name: 'Composite',
          className: 'PlexeAccountingProxyModelsBalances'
        }
      },
      addresses: {
        serializedName: 'addresses',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeAccountingProxyModelsAddress'
            }
          }
        }
      },
      phones: {
        serializedName: 'phones',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeAccountingProxyModelsPhone'
            }
          }
        }
      },
      fullName: {
        serializedName: 'fullName',
        type: {
          name: 'String'
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsCreditNote: msRest.CompositeMapper = {
  serializedName: 'PlexeAccountingProxyModelsCreditNote',
  type: {
    name: 'Composite',
    className: 'PlexeAccountingProxyModelsCreditNote',
    modelProperties: {
      id: {
        serializedName: 'id',
        type: {
          name: 'String'
        }
      },
      date: {
        serializedName: 'date',
        type: {
          name: 'DateTime'
        }
      },
      dueDate: {
        serializedName: 'dueDate',
        type: {
          name: 'DateTime'
        }
      },
      status: {
        serializedName: 'status',
        type: {
          name: 'Number'
        }
      },
      total: {
        serializedName: 'total',
        type: {
          name: 'Number'
        }
      },
      creditNoteNumber: {
        serializedName: 'creditNoteNumber',
        type: {
          name: 'String'
        }
      },
      lineItems: {
        serializedName: 'lineItems',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeAccountingProxyModelsLineItem'
            }
          }
        }
      },
      payments: {
        serializedName: 'payments',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeAccountingProxyModelsPayment'
            }
          }
        }
      },
      contact: {
        serializedName: 'contact',
        type: {
          name: 'Composite',
          className: 'PlexeAccountingProxyModelsContact'
        }
      }
    }
  }
};

export const InvoiceValues: msRest.CompositeMapper = {
  serializedName: 'InvoiceValues',
  type: {
    name: 'Composite',
    className: 'InvoiceValues',
    modelProperties: {
      loanId: {
        serializedName: 'loanId',
        type: {
          name: 'Uuid'
        }
      },
      debtor: {
        serializedName: 'debtor',
        type: {
          name: 'String'
        }
      },
      invoiceNumber: {
        serializedName: 'invoiceNumber',
        type: {
          name: 'String'
        }
      },
      amount: {
        serializedName: 'amount',
        type: {
          name: 'Number'
        }
      },
      raisedDate: {
        serializedName: 'raisedDate',
        type: {
          name: 'DateTime'
        }
      },
      lastPaymentDate: {
        serializedName: 'lastPaymentDate',
        type: {
          name: 'DateTime'
        }
      },
      daysOpen: {
        readOnly: true,
        serializedName: 'daysOpen',
        type: {
          name: 'Number'
        }
      },
      closed: {
        readOnly: true,
        serializedName: 'closed',
        type: {
          name: 'Boolean'
        }
      },
      openDays: {
        serializedName: 'openDays',
        type: {
          name: 'Dictionary',
          value: {
            type: {
              name: 'Number'
            }
          }
        }
      },
      nominated: {
        serializedName: 'nominated',
        type: {
          name: 'Boolean'
        }
      },
      amountPaid: {
        serializedName: 'amountPaid',
        type: {
          name: 'Number'
        }
      },
      payments: {
        serializedName: 'payments',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeAccountingProxyModelsPayment'
            }
          }
        }
      },
      credits: {
        serializedName: 'credits',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PlexeAccountingProxyModelsCreditNote'
            }
          }
        }
      }
    }
  }
};

export const ReceivableTotal: msRest.CompositeMapper = {
  serializedName: 'ReceivableTotal',
  type: {
    name: 'Composite',
    className: 'ReceivableTotal',
    modelProperties: {
      totalOpenInvoices: {
        serializedName: 'totalOpenInvoices',
        type: {
          name: 'Number'
        }
      },
      totalRaisedInvoices: {
        serializedName: 'totalRaisedInvoices',
        type: {
          name: 'Number'
        }
      },
      count: {
        serializedName: 'count',
        type: {
          name: 'Number'
        }
      },
      openInvoices: {
        serializedName: 'openInvoices',
        type: {
          name: 'Number'
        }
      },
      openInvoiceList: {
        serializedName: 'openInvoiceList',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'InvoiceValues'
            }
          }
        }
      },
      openInvoicesCount: {
        serializedName: 'openInvoicesCount',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const Performance: msRest.CompositeMapper = {
  serializedName: 'Performance',
  type: {
    name: 'Composite',
    className: 'Performance',
    modelProperties: {
      month: {
        serializedName: 'month',
        type: {
          name: 'String'
        }
      },
      ratioAllNetInclude: {
        serializedName: 'ratioAllNetInclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedNetInclude: {
        serializedName: 'ratioFixedNetInclude',
        type: {
          name: 'Number'
        }
      },
      ratioLineOfCreditNetInclude: {
        serializedName: 'ratioLineOfCreditNetInclude',
        type: {
          name: 'Number'
        }
      },
      ratioInvoiceNetInclude: {
        serializedName: 'ratioInvoiceNetInclude',
        type: {
          name: 'Number'
        }
      },
      ratioAllNetExclude: {
        serializedName: 'ratioAllNetExclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedNetExclude: {
        serializedName: 'ratioFixedNetExclude',
        type: {
          name: 'Number'
        }
      },
      ratioLineOfCreditNetExclude: {
        serializedName: 'ratioLineOfCreditNetExclude',
        type: {
          name: 'Number'
        }
      },
      ratioInvoiceNetExclude: {
        serializedName: 'ratioInvoiceNetExclude',
        type: {
          name: 'Number'
        }
      },
      ratioAllGrossInclude: {
        serializedName: 'ratioAllGrossInclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedGrossInclude: {
        serializedName: 'ratioFixedGrossInclude',
        type: {
          name: 'Number'
        }
      },
      ratioLineOfCreditGrossInclude: {
        serializedName: 'ratioLineOfCreditGrossInclude',
        type: {
          name: 'Number'
        }
      },
      ratioInvoiceGrossInclude: {
        serializedName: 'ratioInvoiceGrossInclude',
        type: {
          name: 'Number'
        }
      },
      ratioAllGrossExclude: {
        serializedName: 'ratioAllGrossExclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedGrossExclude: {
        serializedName: 'ratioFixedGrossExclude',
        type: {
          name: 'Number'
        }
      },
      ratioLineOfCreditGrossExclude: {
        serializedName: 'ratioLineOfCreditGrossExclude',
        type: {
          name: 'Number'
        }
      },
      ratioInvoiceGrossExclude: {
        serializedName: 'ratioInvoiceGrossExclude',
        type: {
          name: 'Number'
        }
      },
      totalAdvance: {
        serializedName: 'totalAdvance',
        type: {
          name: 'Number'
        }
      },
      advanceStartDate: {
        serializedName: 'advanceStartDate',
        type: {
          name: 'DateTime'
        }
      },
      advanceEndDate: {
        serializedName: 'advanceEndDate',
        type: {
          name: 'DateTime'
        }
      },
      currentDate: {
        serializedName: 'currentDate',
        type: {
          name: 'DateTime'
        }
      },
      grossAmountExclude: {
        serializedName: 'grossAmountExclude',
        type: {
          name: 'Number'
        }
      },
      grossAmountInclude: {
        serializedName: 'grossAmountInclude',
        type: {
          name: 'Number'
        }
      },
      netAmountExclude: {
        serializedName: 'netAmountExclude',
        type: {
          name: 'Number'
        }
      },
      netAmountInclude: {
        serializedName: 'netAmountInclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedAndLocGrossExclude: {
        serializedName: 'ratioFixedAndLocGrossExclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedAndLocGrossInclude: {
        serializedName: 'ratioFixedAndLocGrossInclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedAndLocNetExclude: {
        serializedName: 'ratioFixedAndLocNetExclude',
        type: {
          name: 'Number'
        }
      },
      ratioFixedAndLocNetInclude: {
        serializedName: 'ratioFixedAndLocNetInclude',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PortfolioPerformance: msRest.CompositeMapper = {
  serializedName: 'PortfolioPerformance',
  type: {
    name: 'Composite',
    className: 'PortfolioPerformance',
    modelProperties: {
      label: {
        serializedName: 'label',
        type: {
          name: 'String'
        }
      },
      totalAdvance: {
        serializedName: 'totalAdvance',
        type: {
          name: 'Number'
        }
      },
      performances: {
        serializedName: 'performances',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'Performance'
            }
          }
        }
      },
      totalAdvanceSecured: {
        serializedName: 'totalAdvanceSecured',
        type: {
          name: 'Number'
        }
      },
      totalAdvanceLineOfCredit: {
        serializedName: 'totalAdvanceLineOfCredit',
        type: {
          name: 'Number'
        }
      },
      totalAdvanceFixed: {
        serializedName: 'totalAdvanceFixed',
        type: {
          name: 'Number'
        }
      },
      totalAdvanceFixedAndLineOfCredit: {
        serializedName: 'totalAdvanceFixedAndLineOfCredit',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PerformanceDetail: msRest.CompositeMapper = {
  serializedName: 'PerformanceDetail',
  type: {
    name: 'Composite',
    className: 'PerformanceDetail',
    modelProperties: {
      month: {
        serializedName: 'month',
        type: {
          name: 'String'
        }
      },
      ratio: {
        serializedName: 'ratio',
        type: {
          name: 'Number'
        }
      }
    }
  }
};

export const PortfolioPerformanceCompact: msRest.CompositeMapper = {
  serializedName: 'PortfolioPerformanceCompact',
  type: {
    name: 'Composite',
    className: 'PortfolioPerformanceCompact',
    modelProperties: {
      label: {
        serializedName: 'label',
        type: {
          name: 'String'
        }
      },
      totalAdvance: {
        serializedName: 'totalAdvance',
        type: {
          name: 'Number'
        }
      },
      performances: {
        serializedName: 'performances',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'Composite',
              className: 'PerformanceDetail'
            }
          }
        }
      }
    }
  }
};
