import { Component, HostListener, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ApiService } from "../../shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import { OriginationWriteApi } from "../../core/proxy/Write/originationWriteApi";
import { AU_STATES, REGION, US_STATES } from "../../Constants";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateCustomParserFormatter } from "../../shared/providers/dateformat";
import { ContractDocument } from "../../core/proxy/Read/models";
import { OriginationReadApi } from "../../core/proxy/Read/originationReadApi";
import { saveAs } from "file-saver";
import { Guid } from "guid-typescript";
import { debug } from "console";
import * as moment from "moment";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-accreditation",
  templateUrl: "./accreditation.component.html",
  styleUrls: ["./accreditation.component.scss"],
  // providers: [
  //   { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  // ],
})
export class AccreditationComponent implements OnInit {
  public id: Guid;
  accreditationForm: FormGroup;
  formSubmitted = false;
  pendingAccreditation = false;
  formattedDate = "";
  formattedDob = "";
  colorClassesNames = ["color-dark-blue", "color-white-gray"];
  defaultRegion = window["appConfig"]["region"];
  isUSRegion = this.defaultRegion == REGION.US;
  states = [];
  downloadingW9SampleFile = false;

  upploadFileImg = faFileUpload;
  showMore = false;
  checkBoxColor = "red";

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    const panels = document.getElementsByClassName("panel");
    const scroll = window.scrollY + window.innerHeight / 1.5;

    for (let i = 0; i < panels.length; i++) {
      const panel = panels[i] as HTMLElement;

      if (
        panel.offsetTop <= scroll &&
        panel.offsetTop + panel.offsetHeight > scroll
      ) {
        for (let j = 0; j < panels.length; j++) {
          this.colorClassesNames.map((className) =>
            panels[j].classList.remove(className)
          );
          panels[j].classList.add(this.colorClassesNames[i]);
        }
      }
    }
  }
  selectedBankStatements;
  selectedBlankInvoices;
  selectedW9Form;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private readApi: OriginationReadApi,
    private writeApi: OriginationWriteApi,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initAccreditationForm();
    this.loadStates();
  }

  learnMoreAsAccreditation(
    emailAddress: string,
    firstname: string,
    lastName: string,
    role: string,
    state: string,
    companyName: string,
    phone: string
  ) {
    //make restfull call to send message
    /* this.writeApi.sendMessage({
     messageType: "LearnMoreAsAccreditation",
     message: {
       "firstname": firstname,
       "lastname": lastName,
       "email": emailAddress,
       "companyName": companyName,
       "phone": phone,
       "role": role,
       "state": state
     }
   });
   //.then(_=> /*show its been sent );*/
  }
  formatDobString() {
    let date = this.accreditationForm.value.dob;
    let formattedDob = "";

    if (!date) {
      return;
    }

    if (typeof date === "string") {
      date = date ? date.replace(/\//g, "") : "";

      if (date.length) {
        for (let i = 0; i < date.length; i++) {
          formattedDob += date[i];
          if (i == 1 || i == 3) {
            formattedDob += "/";
          }
        }
      }
    } else {
      if (this.isUSRegion) {
        formattedDob =
          (date.month < 10 ? "0" + date.month : date.month) +
          "/" +
          (date.day < 10 ? "0" + date.day : date.day) +
          "/" +
          date.year;
      } else {
        formattedDob =
          (date.day < 10 ? "0" + date.day : date.day) +
          "/" +
          (date.month < 10 ? "0" + date.month : date.month) +
          "/" +
          date.year;
      }
    }

    this.formattedDob = formattedDob;
  }

  onBankStatementFileChange(event: any): void {
    this.selectedBankStatements = event.target.files;
  }

  onBlankInvoiceFileChange(event: any): void {
    this.selectedBlankInvoices = event.target.files;
  }
  onW9FormFileChange(event: any): void {
    this.selectedW9Form = event.target.files;
  }
  formatDateString() {
    let date = this.accreditationForm.value.dob;
    let formattedDate = "";

    if (!date) {
      return;
    }

    if (typeof date === "string") {
      date = date ? date.replace(/\//g, "") : "";

      if (date.length) {
        for (let i = 0; i < date.length; i++) {
          formattedDate += date[i];
          if (i == 1 || i == 3) {
            formattedDate += "/";
          }
        }
      }
    } else {
      if (this.isUSRegion) {
        formattedDate =
          (date.month < 10 ? "0" + date.month : date.month) +
          "/" +
          (date.day < 10 ? "0" + date.day : date.day) +
          "/" +
          date.year;
      } else {
        formattedDate =
          (date.day < 10 ? "0" + date.day : date.day) +
          "/" +
          (date.month < 10 ? "0" + date.month : date.month) +
          "/" +
          date.year;
      }
    }

    this.formattedDate = formattedDate;
  }
  private initAccreditationForm() {
    this.accreditationForm = this.formBuilder.group({
      ownerDetails: this.formBuilder.group({
        title: [null, Validators.required],
        firstName: [null, Validators.required],
        preferredName: [null, Validators.required],
        lastname: [null, Validators.required],
        cellNumber: [null, Validators.required],
        officeNumber: [null, Validators.required],
        email: [null, [Validators.required, Validators.email]],
        driversLicenceNumber: [null, Validators.required],
        stateIssued: [null, Validators.required],
        ssn: [null, Validators.required],
        dob: [null, Validators.required],
      }),
      businessDetails: this.formBuilder.group({
        companyName: [null, Validators.required],
        companyAddress: [null, Validators.required],
        officeNumber: [null, Validators.required],
        webAddress: [null, Validators.required],
        accountPayableEmail: [null, [Validators.required, Validators.email]],
        optionFirst: [null, Validators.required],
        optionSecond: [null, Validators.required],
        optionThird: [null, Validators.required],
        optionFourth: [null, Validators.required],
      }),
      achDetail: this.formBuilder.group({
        bankName: [null, Validators.required],
        accountType: [null, Validators.required],
        routingNumber: [null, Validators.required],
        accountNumber: [null, Validators.required],
      }),
      documentDetail: this.formBuilder.group({
        bankStatementAdded: [null, Validators.required],
        blankInvoiceAdded: [null, Validators.required],
        w9FormAdded: [null, Validators.required],
        w9FormFile: [null, Validators.required],
        bankStatementFiles: [null, Validators.required],
        blankInvoiceFiles: [null, Validators.required],
      }),
      companyDetail: this.formBuilder.group({
        companyName: [null, Validators.required],
        name: [null, Validators.required],
        title: [null, Validators.required],
        date: [null, Validators.required],
        signature: [null, Validators.required],
      }),
    });
  }

  submit() {
    if (this.accreditationForm.valid) {
      this.id = Guid.create();
      this.pendingAccreditation = true;
      var data = this.accreditationForm.value;
      if (this.accreditationForm.value.ownerDetails.dob) {
        if (this.isUSRegion) {
          this.formattedDob =
            (this.accreditationForm.value.ownerDetails.dob.month < 10
              ? "0" + this.accreditationForm.value.ownerDetails.dob.month
              : this.accreditationForm.value.ownerDetails.dob.month) +
            "/" +
            (this.accreditationForm.value.ownerDetails.dob.day < 10
              ? "0" + this.accreditationForm.value.ownerDetails.dob.day
              : this.accreditationForm.value.ownerDetails.dob.day) +
            "/" +
            this.accreditationForm.value.ownerDetails.dob.year;
        } else {
          this.formattedDob =
            (this.accreditationForm.value.ownerDetails.dob.day < 10
              ? "0" + this.accreditationForm.value.ownerDetails.dob.day
              : this.accreditationForm.value.ownerDetails.dob.day) +
            "/" +
            (this.accreditationForm.value.ownerDetails.dob.month < 10
              ? "0" + this.accreditationForm.value.ownerDetails.dob.month
              : this.accreditationForm.value.ownerDetails.dob.month) +
            "/" +
            this.accreditationForm.value.ownerDetails.dob.year;
        }
      }
      if (this.accreditationForm.value.companyDetail.date) {
        if (this.isUSRegion) {
          this.formattedDate =
            (this.accreditationForm.value.companyDetail.date.month < 10
              ? "0" + this.accreditationForm.value.companyDetail.date.month
              : this.accreditationForm.value.companyDetail.date.month) +
            "/" +
            (this.accreditationForm.value.companyDetail.date.day < 10
              ? "0" + this.accreditationForm.value.companyDetail.date.day
              : this.accreditationForm.value.companyDetail.date.day) +
            "/" +
            this.accreditationForm.value.companyDetail.date.year;
        } else {
          this.formattedDate =
            (this.accreditationForm.value.companyDetail.date.day < 10
              ? "0" + this.accreditationForm.value.companyDetail.date.day
              : this.accreditationForm.value.companyDetail.date.day) +
            "/" +
            (this.accreditationForm.value.companyDetail.date.month < 10
              ? "0" + this.accreditationForm.value.companyDetail.date.month
              : this.accreditationForm.value.companyDetail.date.month) +
            "/" +
            this.accreditationForm.value.companyDetail.date.year;
        }
      }
      data.ownerDetails.dob = this.formattedDob;
      data.companyDetail.date = this.formattedDate;
      var partnerAccreditationDetail = {
        ownerDetails: data.ownerDetails,
        businessDetails: data.businessDetails,
        achDetail: data.achDetail,
        companyDetail: data.companyDetail,
      };
      Promise.all([
        this.uploadW9Form(this.id.toString(), this.selectedW9Form[0]),
        this.uploadBankStatement(
          this.id.toString(),
          this.selectedBankStatements
        ),
        this.uploadBlankInvoice(this.id.toString(), this.selectedBlankInvoices),
        this.submitAccreditationDetail(
          this.id.toString(),
          partnerAccreditationDetail
        ),
      ]).then((res) => {
        console.log();
        this.formSubmitted = true;
        this.formattedDob = "";
      });
    } else {
      this.accreditationForm.markAllAsTouched();
    }
  }

  uploadW9Form(id: string, file: File) {
    return this.writeApi.uploadW9Form(id, {
      uploadedFile: file,
    });
  }

  uploadBankStatement(id: string, files: File[]) {
    var uploadedFiles = [];
    for (var i = 0; i < files.length; i++) {
      uploadedFiles.push(files[i]);
    }
    return this.writeApi.uploadBankStatement(id, {
      uploadedFiles: uploadedFiles,
    });
  }

  uploadBlankInvoice(id: string, files: File[]) {
    var uploadedFiles = [];
    for (var i = 0; i < files.length; i++) {
      uploadedFiles.push(files[i]);
    }
    return this.writeApi.uploadBlankInvoice(id, {
      uploadedFiles: uploadedFiles,
    });
  }

  submitAccreditationDetail(id: string, partnerAccreditationDetail: any) {
    this.writeApi
      .addAccreditation(id, {
        partnerAccreditationDetail: partnerAccreditationDetail,
      })
      .then((_) => {
        this.toastr.success("Saved Successfully");
        this.accreditationForm.reset();
        this.accreditationForm.markAsPristine();
        this.accreditationForm.markAsUntouched();
      })
      .catch((err) => {
        this.toastr.error("Failed to save");
        this.accreditationForm.reset();
        this.accreditationForm.markAsPristine();
        this.accreditationForm.markAsUntouched();
      })
      .then((_) => (this.pendingAccreditation = false));
  }

  private loadStates() {
    if (this.isUSRegion) {
      this.states = US_STATES;
    } else {
      this.states = AU_STATES;
    }
  }

  export() {
    if (!this.downloadingW9SampleFile) {
      this.downloadContractFile();
    }
  }

  downloadContractFile() {
    this.downloadingW9SampleFile = true;
    this.readApi
      .getW9FormSampleDocument()
      .then((res) => {
        this.downloadFile(res.data, res.fileName, res.fileType);
      })
      .catch((err) => {
        this.toastr.error("Failed to download file");
      })
      .then((_) => (this.downloadingW9SampleFile = false));
  }

  downloadFile(data: any, filename: string, filetype: string) {
    const blob = new Blob([data], { type: filetype });
    saveAs(blob, filename);
  }
}
