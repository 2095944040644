/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";
import * as Models from "./models";
import * as Mappers from "./models/mappers";
import * as Parameters from "./models/parameters";
import { OriginationReadApiContext } from "./originationReadApiContext";

class OriginationReadApi extends OriginationReadApiContext {
  /**
   * Initializes a new instance of the OriginationReadApi class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.OriginationReadApiOptions) {
    super(credentials, options);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loadApplicantCache(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  loadApplicantCache(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  loadApplicantCache(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  loadApplicantCache(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      loadApplicantCacheOperationSpec,
      callback);
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantNamesResponse>
   */
  getApplicantNames(organisationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicantNamesResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getApplicantNames(organisationId: string, callback: msRest.ServiceCallback<Models.Name[]>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicantNames(organisationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Name[]>): void;
  getApplicantNames(organisationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Name[]>, callback?: msRest.ServiceCallback<Models.Name[]>): Promise<Models.GetApplicantNamesResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getApplicantNamesOperationSpec,
      callback) as Promise<Models.GetApplicantNamesResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantsResponse>
   */
  getApplicants(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicantsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicants(applicationId: string, callback: msRest.ServiceCallback<Models.Applicant[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicants(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Applicant[]>): void;
  getApplicants(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Applicant[]>, callback?: msRest.ServiceCallback<Models.Applicant[]>): Promise<Models.GetApplicantsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicantsOperationSpec,
      callback) as Promise<Models.GetApplicantsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPrimaryApplicantResponse>
   */
  getPrimaryApplicant(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPrimaryApplicantResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getPrimaryApplicant(applicationId: string, callback: msRest.ServiceCallback<Models.PrimaryApplicant>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPrimaryApplicant(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PrimaryApplicant>): void;
  getPrimaryApplicant(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PrimaryApplicant>, callback?: msRest.ServiceCallback<Models.PrimaryApplicant>): Promise<Models.GetPrimaryApplicantResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getPrimaryApplicantOperationSpec,
      callback) as Promise<Models.GetPrimaryApplicantResponse>;
  }

  /**
   * @param applicantId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantDriverLicenceUrlResponse>
   */
  getApplicantDriverLicenceUrl(applicantId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicantDriverLicenceUrlResponse>;
  /**
   * @param applicantId
   * @param callback The callback
   */
  getApplicantDriverLicenceUrl(applicantId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicantId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicantDriverLicenceUrl(applicantId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getApplicantDriverLicenceUrl(applicantId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetApplicantDriverLicenceUrlResponse> {
    return this.sendOperationRequest(
      {
        applicantId,
        options
      },
      getApplicantDriverLicenceUrlOperationSpec,
      callback) as Promise<Models.GetApplicantDriverLicenceUrlResponse>;
  }

  /**
   * @param applicantId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantResponse>
   */
  getApplicant(applicantId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicantResponse>;
  /**
   * @param applicantId
   * @param callback The callback
   */
  getApplicant(applicantId: string, callback: msRest.ServiceCallback<Models.Applicant>): void;
  /**
   * @param applicantId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicant(applicantId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Applicant>): void;
  getApplicant(applicantId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Applicant>, callback?: msRest.ServiceCallback<Models.Applicant>): Promise<Models.GetApplicantResponse> {
    return this.sendOperationRequest(
      {
        applicantId,
        options
      },
      getApplicantOperationSpec,
      callback) as Promise<Models.GetApplicantResponse>;
  }

  /**
   * @param applicantId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicantDriverLicenceImageResponse>
   */
  getApplicantDriverLicenceImage(applicantId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicantDriverLicenceImageResponse>;
  /**
   * @param applicantId
   * @param callback The callback
   */
  getApplicantDriverLicenceImage(applicantId: string, callback: msRest.ServiceCallback<Uint8Array>): void;
  /**
   * @param applicantId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicantDriverLicenceImage(applicantId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Uint8Array>): void;
  getApplicantDriverLicenceImage(applicantId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Uint8Array>, callback?: msRest.ServiceCallback<Uint8Array>): Promise<Models.GetApplicantDriverLicenceImageResponse> {
    return this.sendOperationRequest(
      {
        applicantId,
        options
      },
      getApplicantDriverLicenceImageOperationSpec,
      callback) as Promise<Models.GetApplicantDriverLicenceImageResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSecondaryApplicantResponse>
   */
  getSecondaryApplicant(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetSecondaryApplicantResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSecondaryApplicant(applicationId: string, callback: msRest.ServiceCallback<Models.SecondaryApplicant>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSecondaryApplicant(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SecondaryApplicant>): void;
  getSecondaryApplicant(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SecondaryApplicant>, callback?: msRest.ServiceCallback<Models.SecondaryApplicant>): Promise<Models.GetSecondaryApplicantResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSecondaryApplicantOperationSpec,
      callback) as Promise<Models.GetSecondaryApplicantResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOrganisationExtraDetailsResponse>
   */
  getOrganisationExtraDetails(options?: Models.OriginationReadApiGetOrganisationExtraDetailsOptionalParams): Promise<Models.GetOrganisationExtraDetailsResponse>;
  /**
   * @param callback The callback
   */
  getOrganisationExtraDetails(callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getOrganisationExtraDetails(options: Models.OriginationReadApiGetOrganisationExtraDetailsOptionalParams, callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  getOrganisationExtraDetails(options?: Models.OriginationReadApiGetOrganisationExtraDetailsOptionalParams | msRest.ServiceCallback<{ [propertyName: string]: string }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>): Promise<Models.GetOrganisationExtraDetailsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getOrganisationExtraDetailsOperationSpec,
      callback) as Promise<Models.GetOrganisationExtraDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loadApplicationCache(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  loadApplicationCache(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  loadApplicationCache(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  loadApplicationCache(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      loadApplicationCacheOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.OverviewResponse>
   */
  overview(options?: Models.OriginationReadApiOverviewOptionalParams): Promise<Models.OverviewResponse>;
  /**
   * @param callback The callback
   */
  overview(callback: msRest.ServiceCallback<Models.ApplicationsOverview>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  overview(options: Models.OriginationReadApiOverviewOptionalParams, callback: msRest.ServiceCallback<Models.ApplicationsOverview>): void;
  overview(options?: Models.OriginationReadApiOverviewOptionalParams | msRest.ServiceCallback<Models.ApplicationsOverview>, callback?: msRest.ServiceCallback<Models.ApplicationsOverview>): Promise<Models.OverviewResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      overviewOperationSpec,
      callback) as Promise<Models.OverviewResponse>;
  }

  /**
   * @param otp
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateOtpResponse>
   */
  validateOtp(otp: string, options?: msRest.RequestOptionsBase): Promise<Models.ValidateOtpResponse>;
  /**
   * @param otp
   * @param callback The callback
   */
  validateOtp(otp: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param otp
   * @param options The optional parameters
   * @param callback The callback
   */
  validateOtp(otp: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  validateOtp(otp: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>, callback?: msRest.ServiceCallback<Models.BoolWrapper>): Promise<Models.ValidateOtpResponse> {
    return this.sendOperationRequest(
      {
        otp,
        options
      },
      validateOtpOperationSpec,
      callback) as Promise<Models.ValidateOtpResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.ApplicationStatusResponse>
   */
  applicationStatus(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.ApplicationStatusResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationStatus(applicationId: string, callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationStatus(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>): void;
  applicationStatus(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: boolean }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: boolean }>): Promise<Models.ApplicationStatusResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationStatusOperationSpec,
      callback) as Promise<Models.ApplicationStatusResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.ApplicationConnectionStatusResponse>
   */
  applicationConnectionStatus(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.ApplicationConnectionStatusResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  applicationConnectionStatus(applicationId: string, callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationConnectionStatus(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<{ [propertyName: string]: boolean }>): void;
  applicationConnectionStatus(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: boolean }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: boolean }>): Promise<Models.ApplicationConnectionStatusResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      applicationConnectionStatusOperationSpec,
      callback) as Promise<Models.ApplicationConnectionStatusResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.HasOrganisationNameBeenFinalisedResponse>
   */
  hasOrganisationNameBeenFinalised(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.HasOrganisationNameBeenFinalisedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  hasOrganisationNameBeenFinalised(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  hasOrganisationNameBeenFinalised(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  hasOrganisationNameBeenFinalised(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.HasOrganisationNameBeenFinalisedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      hasOrganisationNameBeenFinalisedOperationSpec,
      callback) as Promise<Models.HasOrganisationNameBeenFinalisedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsOfferReadyResponse>
   */
  isOfferReady(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsOfferReadyResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isOfferReady(applicationId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isOfferReady(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  isOfferReady(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>, callback?: msRest.ServiceCallback<Models.BoolWrapper>): Promise<Models.IsOfferReadyResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isOfferReadyOperationSpec,
      callback) as Promise<Models.IsOfferReadyResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.HasLoggedInResponse>
   */
  hasLoggedIn(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.HasLoggedInResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  hasLoggedIn(applicationId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  hasLoggedIn(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  hasLoggedIn(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>, callback?: msRest.ServiceCallback<Models.BoolWrapper>): Promise<Models.HasLoggedInResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      hasLoggedInOperationSpec,
      callback) as Promise<Models.HasLoggedInResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadContractFileResponse>
   */
  downloadContractFile(applicationId: string, options?: Models.OriginationReadApiDownloadContractFileOptionalParams): Promise<Models.DownloadContractFileResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  downloadContractFile(applicationId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadContractFile(applicationId: string, options: Models.OriginationReadApiDownloadContractFileOptionalParams, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  downloadContractFile(applicationId: string, options?: Models.OriginationReadApiDownloadContractFileOptionalParams | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.DownloadContractFileResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      downloadContractFileOperationSpec,
      callback) as Promise<Models.DownloadContractFileResponse>;
  }

  /**
   * @param objectType
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDataResponse>
   */
  getData(objectType: string, id: string, options?: msRest.RequestOptionsBase): Promise<Models.GetDataResponse>;
  /**
   * @param objectType
   * @param id
   * @param callback The callback
   */
  getData(objectType: string, id: string, callback: msRest.ServiceCallback<Models.Summary>): void;
  /**
   * @param objectType
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  getData(objectType: string, id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Summary>): void;
  getData(objectType: string, id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Summary>, callback?: msRest.ServiceCallback<Models.Summary>): Promise<Models.GetDataResponse> {
    return this.sendOperationRequest(
      {
        objectType,
        id,
        options
      },
      getDataOperationSpec,
      callback) as Promise<Models.GetDataResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommissionDetailsResponse>
   */
  getCommissionDetails(applicationId: string, options?: Models.OriginationReadApiGetCommissionDetailsOptionalParams): Promise<Models.GetCommissionDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getCommissionDetails(applicationId: string, callback: msRest.ServiceCallback<Models.CommissionDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommissionDetails(applicationId: string, options: Models.OriginationReadApiGetCommissionDetailsOptionalParams, callback: msRest.ServiceCallback<Models.CommissionDetails>): void;
  getCommissionDetails(applicationId: string, options?: Models.OriginationReadApiGetCommissionDetailsOptionalParams | msRest.ServiceCallback<Models.CommissionDetails>, callback?: msRest.ServiceCallback<Models.CommissionDetails>): Promise<Models.GetCommissionDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getCommissionDetailsOperationSpec,
      callback) as Promise<Models.GetCommissionDetailsResponse>;
  }

  /**
   * @param shortCode
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationIdOnShortCodeResponse>
   */
  getApplicationIdOnShortCode(shortCode: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationIdOnShortCodeResponse>;
  /**
   * @param shortCode
   * @param callback The callback
   */
  getApplicationIdOnShortCode(shortCode: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param shortCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationIdOnShortCode(shortCode: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getApplicationIdOnShortCode(shortCode: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetApplicationIdOnShortCodeResponse> {
    return this.sendOperationRequest(
      {
        shortCode,
        options
      },
      getApplicationIdOnShortCodeOperationSpec,
      callback) as Promise<Models.GetApplicationIdOnShortCodeResponse>;
  }

  /**
   * @param shortCode
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTokenResponse>
   */
  getToken(shortCode: string, options?: msRest.RequestOptionsBase): Promise<Models.GetTokenResponse>;
  /**
   * @param shortCode
   * @param callback The callback
   */
  getToken(shortCode: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param shortCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getToken(shortCode: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getToken(shortCode: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetTokenResponse> {
    return this.sendOperationRequest(
      {
        shortCode,
        options
      },
      getTokenOperationSpec,
      callback) as Promise<Models.GetTokenResponse>;
  }

  /**
   * @param shortCode
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOnShortCodeResponse>
   */
  getApplicationOnShortCode(shortCode: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationOnShortCodeResponse>;
  /**
   * @param shortCode
   * @param callback The callback
   */
  getApplicationOnShortCode(shortCode: string, callback: msRest.ServiceCallback<Models.Application>): void;
  /**
   * @param shortCode
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOnShortCode(shortCode: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Application>): void;
  getApplicationOnShortCode(shortCode: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Application>, callback?: msRest.ServiceCallback<Models.Application>): Promise<Models.GetApplicationOnShortCodeResponse> {
    return this.sendOperationRequest(
      {
        shortCode,
        options
      },
      getApplicationOnShortCodeOperationSpec,
      callback) as Promise<Models.GetApplicationOnShortCodeResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetShortCodeOnApplicationIdResponse>
   */
  getShortCodeOnApplicationId(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetShortCodeOnApplicationIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getShortCodeOnApplicationId(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getShortCodeOnApplicationId(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getShortCodeOnApplicationId(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetShortCodeOnApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getShortCodeOnApplicationIdOperationSpec,
      callback) as Promise<Models.GetShortCodeOnApplicationIdResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetQuickUrlResponse>
   */
  getQuickUrl(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetQuickUrlResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getQuickUrl(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getQuickUrl(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getQuickUrl(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetQuickUrlResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getQuickUrlOperationSpec,
      callback) as Promise<Models.GetQuickUrlResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSecondApplicantUrlResponse>
   */
  getSecondApplicantUrl(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetSecondApplicantUrlResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSecondApplicantUrl(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSecondApplicantUrl(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getSecondApplicantUrl(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetSecondApplicantUrlResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSecondApplicantUrlOperationSpec,
      callback) as Promise<Models.GetSecondApplicantUrlResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBaseUrlResponse>
   */
  getBaseUrl(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBaseUrlResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBaseUrl(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBaseUrl(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getBaseUrl(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetBaseUrlResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBaseUrlOperationSpec,
      callback) as Promise<Models.GetBaseUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ApplicationListResponse>
   */
  applicationList(options?: Models.OriginationReadApiApplicationListOptionalParams): Promise<Models.ApplicationListResponse>;
  /**
   * @param callback The callback
   */
  applicationList(callback: msRest.ServiceCallback<Models.PagedListApplicationListProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  applicationList(options: Models.OriginationReadApiApplicationListOptionalParams, callback: msRest.ServiceCallback<Models.PagedListApplicationListProjection>): void;
  applicationList(options?: Models.OriginationReadApiApplicationListOptionalParams | msRest.ServiceCallback<Models.PagedListApplicationListProjection>, callback?: msRest.ServiceCallback<Models.PagedListApplicationListProjection>): Promise<Models.ApplicationListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      applicationListOperationSpec,
      callback) as Promise<Models.ApplicationListResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBusinessNameFromApplicationResponse>
   */
  getBusinessNameFromApplication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBusinessNameFromApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBusinessNameFromApplication(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBusinessNameFromApplication(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getBusinessNameFromApplication(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetBusinessNameFromApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBusinessNameFromApplicationOperationSpec,
      callback) as Promise<Models.GetBusinessNameFromApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankAccountsResponse>
   */
  getBankAccounts(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBankAccountsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankAccounts(applicationId: string, callback: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankAccounts(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>): void;
  getBankAccounts(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>, callback?: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>): Promise<Models.GetBankAccountsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankAccountsOperationSpec,
      callback) as Promise<Models.GetBankAccountsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedAccountsResponse>
   */
  getBankfeedAccounts(applicationId: string, options?: Models.OriginationReadApiGetBankfeedAccountsOptionalParams): Promise<Models.GetBankfeedAccountsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedAccounts(applicationId: string, callback: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedAccounts(applicationId: string, options: Models.OriginationReadApiGetBankfeedAccountsOptionalParams, callback: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>): void;
  getBankfeedAccounts(applicationId: string, options?: Models.OriginationReadApiGetBankfeedAccountsOptionalParams | msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>, callback?: msRest.ServiceCallback<Models.PlexeOrganisationServiceModelsBankAccount[]>): Promise<Models.GetBankfeedAccountsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedAccountsOperationSpec,
      callback) as Promise<Models.GetBankfeedAccountsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedMFADetailsResponse>
   */
  getBankfeedMFADetails(applicationId: string, options?: Models.OriginationReadApiGetBankfeedMFADetailsOptionalParams): Promise<Models.GetBankfeedMFADetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedMFADetails(applicationId: string, callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedMFADetails(applicationId: string, options: Models.OriginationReadApiGetBankfeedMFADetailsOptionalParams, callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>): void;
  getBankfeedMFADetails(applicationId: string, options?: Models.OriginationReadApiGetBankfeedMFADetailsOptionalParams | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>, callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsMFADetails>): Promise<Models.GetBankfeedMFADetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedMFADetailsOperationSpec,
      callback) as Promise<Models.GetBankfeedMFADetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedFaskLinkResponse>
   */
  getBankfeedFaskLink(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBankfeedFaskLinkResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedFaskLink(applicationId: string, callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedFaskLink(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>): void;
  getBankfeedFaskLink(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>, callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>): Promise<Models.GetBankfeedFaskLinkResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedFaskLinkOperationSpec,
      callback) as Promise<Models.GetBankfeedFaskLinkResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankfeedPlaidLinkResponse>
   */
  getBankfeedPlaidLink(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBankfeedPlaidLinkResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getBankfeedPlaidLink(applicationId: string, callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankfeedPlaidLink(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>): void;
  getBankfeedPlaidLink(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>, callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsFastLinkToken>): Promise<Models.GetBankfeedPlaidLinkResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getBankfeedPlaidLinkOperationSpec,
      callback) as Promise<Models.GetBankfeedPlaidLinkResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsProvisoApplicationResponse>
   */
  isProvisoApplication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsProvisoApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isProvisoApplication(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isProvisoApplication(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isProvisoApplication(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsProvisoApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isProvisoApplicationOperationSpec,
      callback) as Promise<Models.IsProvisoApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param offerId
   * @param [options] The optional parameters
   * @returns Promise<Models.OfferResponse>
   */
  offer(applicationId: string, offerId: string, options?: msRest.RequestOptionsBase): Promise<Models.OfferResponse>;
  /**
   * @param applicationId
   * @param offerId
   * @param callback The callback
   */
  offer(applicationId: string, offerId: string, callback: msRest.ServiceCallback<Models.OfferAmount>): void;
  /**
   * @param applicationId
   * @param offerId
   * @param options The optional parameters
   * @param callback The callback
   */
  offer(applicationId: string, offerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.OfferAmount>): void;
  offer(applicationId: string, offerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.OfferAmount>, callback?: msRest.ServiceCallback<Models.OfferAmount>): Promise<Models.OfferResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        offerId,
        options
      },
      offerOperationSpec,
      callback) as Promise<Models.OfferResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationSettingsResponse>
   */
  getApplicationSettings(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationSettingsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplicationSettings(applicationId: string, callback: msRest.ServiceCallback<Models.ApplicationSettings>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationSettings(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ApplicationSettings>): void;
  getApplicationSettings(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationSettings>, callback?: msRest.ServiceCallback<Models.ApplicationSettings>): Promise<Models.GetApplicationSettingsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationSettingsOperationSpec,
      callback) as Promise<Models.GetApplicationSettingsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommunicationResponse>
   */
  getCommunication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCommunicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getCommunication(applicationId: string, callback: msRest.ServiceCallback<Models.CommunicationProjection[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommunication(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CommunicationProjection[]>): void;
  getCommunication(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CommunicationProjection[]>, callback?: msRest.ServiceCallback<Models.CommunicationProjection[]>): Promise<Models.GetCommunicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getCommunicationOperationSpec,
      callback) as Promise<Models.GetCommunicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CommissionMethodResponse>
   */
  commissionMethod(applicationId: string, options?: Models.OriginationReadApiCommissionMethodOptionalParams): Promise<Models.CommissionMethodResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  commissionMethod(applicationId: string, callback: msRest.ServiceCallback<Models.Commission>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  commissionMethod(applicationId: string, options: Models.OriginationReadApiCommissionMethodOptionalParams, callback: msRest.ServiceCallback<Models.Commission>): void;
  commissionMethod(applicationId: string, options?: Models.OriginationReadApiCommissionMethodOptionalParams | msRest.ServiceCallback<Models.Commission>, callback?: msRest.ServiceCallback<Models.Commission>): Promise<Models.CommissionMethodResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      commissionMethodOperationSpec,
      callback) as Promise<Models.CommissionMethodResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationResponse>
   */
  getApplication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getApplication(applicationId: string, callback: msRest.ServiceCallback<Models.Application>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplication(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Application>): void;
  getApplication(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Application>, callback?: msRest.ServiceCallback<Models.Application>): Promise<Models.GetApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getApplicationOperationSpec,
      callback) as Promise<Models.GetApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DebtorSummaryOnApplicationIdResponse>
   */
  debtorSummaryOnApplicationId(applicationId: string, options?: Models.OriginationReadApiDebtorSummaryOnApplicationIdOptionalParams): Promise<Models.DebtorSummaryOnApplicationIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  debtorSummaryOnApplicationId(applicationId: string, callback: msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  debtorSummaryOnApplicationId(applicationId: string, options: Models.OriginationReadApiDebtorSummaryOnApplicationIdOptionalParams, callback: msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>): void;
  debtorSummaryOnApplicationId(applicationId: string, options?: Models.OriginationReadApiDebtorSummaryOnApplicationIdOptionalParams | msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>, callback?: msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>): Promise<Models.DebtorSummaryOnApplicationIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      debtorSummaryOnApplicationIdOperationSpec,
      callback) as Promise<Models.DebtorSummaryOnApplicationIdResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeFixedRepaymentResponse>
   */
  calculateIndicativeFixedRepayment(applicationId: string, options?: Models.OriginationReadApiCalculateIndicativeFixedRepaymentOptionalParams): Promise<Models.CalculateIndicativeFixedRepaymentResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  calculateIndicativeFixedRepayment(applicationId: string, callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeFixedRepayment(applicationId: string, options: Models.OriginationReadApiCalculateIndicativeFixedRepaymentOptionalParams, callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>): void;
  calculateIndicativeFixedRepayment(applicationId: string, options?: Models.OriginationReadApiCalculateIndicativeFixedRepaymentOptionalParams | msRest.ServiceCallback<Models.FixedRepaymentCalculation>, callback?: msRest.ServiceCallback<Models.FixedRepaymentCalculation>): Promise<Models.CalculateIndicativeFixedRepaymentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      calculateIndicativeFixedRepaymentOperationSpec,
      callback) as Promise<Models.CalculateIndicativeFixedRepaymentResponse>;
  }

  /**
   * @param applicationId
   * @param amount
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeLineOfCreditSliderResponse>
   */
  calculateIndicativeLineOfCreditSlider(applicationId: string, amount: number, options?: msRest.RequestOptionsBase): Promise<Models.CalculateIndicativeLineOfCreditSliderResponse>;
  /**
   * @param applicationId
   * @param amount
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditSlider(applicationId: string, amount: number, callback: msRest.ServiceCallback<{ [propertyName: string]: number }>): void;
  /**
   * @param applicationId
   * @param amount
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditSlider(applicationId: string, amount: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<{ [propertyName: string]: number }>): void;
  calculateIndicativeLineOfCreditSlider(applicationId: string, amount: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: number }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: number }>): Promise<Models.CalculateIndicativeLineOfCreditSliderResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        amount,
        options
      },
      calculateIndicativeLineOfCreditSliderOperationSpec,
      callback) as Promise<Models.CalculateIndicativeLineOfCreditSliderResponse>;
  }

  /**
   * @param applicationId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse>
   */
  calculateIndicativeLineOfCreditRepayment(applicationId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, options?: msRest.RequestOptionsBase): Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse>;
  /**
   * @param applicationId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditRepayment(applicationId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>): void;
  /**
   * @param applicationId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeLineOfCreditRepayment(applicationId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>): void;
  calculateIndicativeLineOfCreditRepayment(applicationId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>, callback?: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>): Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        amount,
        percentageOfIncome,
        fixedRepaymentCalculation,
        term,
        options
      },
      calculateIndicativeLineOfCreditRepaymentOperationSpec,
      callback) as Promise<Models.CalculateIndicativeLineOfCreditRepaymentResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativePercentOfIncomeResponse>
   */
  calculateIndicativePercentOfIncome(applicationId: string, options?: Models.OriginationReadApiCalculateIndicativePercentOfIncomeOptionalParams): Promise<Models.CalculateIndicativePercentOfIncomeResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  calculateIndicativePercentOfIncome(applicationId: string, callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativePercentOfIncome(applicationId: string, options: Models.OriginationReadApiCalculateIndicativePercentOfIncomeOptionalParams, callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>): void;
  calculateIndicativePercentOfIncome(applicationId: string, options?: Models.OriginationReadApiCalculateIndicativePercentOfIncomeOptionalParams | msRest.ServiceCallback<Models.PercentOfIncomeCalculation>, callback?: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>): Promise<Models.CalculateIndicativePercentOfIncomeResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      calculateIndicativePercentOfIncomeOperationSpec,
      callback) as Promise<Models.CalculateIndicativePercentOfIncomeResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateIndicativeInvoicePaymentResponse>
   */
  calculateIndicativeInvoicePayment(applicationId: string, options?: Models.OriginationReadApiCalculateIndicativeInvoicePaymentOptionalParams): Promise<Models.CalculateIndicativeInvoicePaymentResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  calculateIndicativeInvoicePayment(applicationId: string, callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateIndicativeInvoicePayment(applicationId: string, options: Models.OriginationReadApiCalculateIndicativeInvoicePaymentOptionalParams, callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>): void;
  calculateIndicativeInvoicePayment(applicationId: string, options?: Models.OriginationReadApiCalculateIndicativeInvoicePaymentOptionalParams | msRest.ServiceCallback<Models.InvoicePaymentCalculation>, callback?: msRest.ServiceCallback<Models.InvoicePaymentCalculation>): Promise<Models.CalculateIndicativeInvoicePaymentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      calculateIndicativeInvoicePaymentOperationSpec,
      callback) as Promise<Models.CalculateIndicativeInvoicePaymentResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IncomeDetailsForApplicationResponse>
   */
  incomeDetailsForApplication(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IncomeDetailsForApplicationResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  incomeDetailsForApplication(applicationId: string, callback: msRest.ServiceCallback<Models.IncomeDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  incomeDetailsForApplication(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.IncomeDetails>): void;
  incomeDetailsForApplication(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.IncomeDetails>, callback?: msRest.ServiceCallback<Models.IncomeDetails>): Promise<Models.IncomeDetailsForApplicationResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      incomeDetailsForApplicationOperationSpec,
      callback) as Promise<Models.IncomeDetailsForApplicationResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApplicationCompleteResponse>
   */
  isApplicationComplete(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsApplicationCompleteResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isApplicationComplete(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isApplicationComplete(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isApplicationComplete(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsApplicationCompleteResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isApplicationCompleteOperationSpec,
      callback) as Promise<Models.IsApplicationCompleteResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOfferDetailsResponse>
   */
  getOfferDetails(applicationId: string, options?: Models.OriginationReadApiGetOfferDetailsOptionalParams): Promise<Models.GetOfferDetailsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getOfferDetails(applicationId: string, callback: msRest.ServiceCallback<Models.OfferDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getOfferDetails(applicationId: string, options: Models.OriginationReadApiGetOfferDetailsOptionalParams, callback: msRest.ServiceCallback<Models.OfferDetails>): void;
  getOfferDetails(applicationId: string, options?: Models.OriginationReadApiGetOfferDetailsOptionalParams | msRest.ServiceCallback<Models.OfferDetails>, callback?: msRest.ServiceCallback<Models.OfferDetails>): Promise<Models.GetOfferDetailsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getOfferDetailsOperationSpec,
      callback) as Promise<Models.GetOfferDetailsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOrganisationIdResponse>
   */
  getOrganisationId(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetOrganisationIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getOrganisationId(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getOrganisationId(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getOrganisationId(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getOrganisationIdOperationSpec,
      callback) as Promise<Models.GetOrganisationIdResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetOfferIdResponse>
   */
  getOfferId(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetOfferIdResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getOfferId(applicationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getOfferId(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getOfferId(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetOfferIdResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getOfferIdOperationSpec,
      callback) as Promise<Models.GetOfferIdResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOnCustomerIdResponse>
   */
  getApplicationOnCustomerId(customerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getApplicationOnCustomerId(customerId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOnCustomerId(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getApplicationOnCustomerId(customerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetApplicationOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getApplicationOnCustomerIdOperationSpec,
      callback) as Promise<Models.GetApplicationOnCustomerIdResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationDetailsOnCustomerIdResponse>
   */
  getApplicationDetailsOnCustomerId(customerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationDetailsOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getApplicationDetailsOnCustomerId(customerId: string, callback: msRest.ServiceCallback<Models.Application>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationDetailsOnCustomerId(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Application>): void;
  getApplicationDetailsOnCustomerId(customerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Application>, callback?: msRest.ServiceCallback<Models.Application>): Promise<Models.GetApplicationDetailsOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getApplicationDetailsOnCustomerIdOperationSpec,
      callback) as Promise<Models.GetApplicationDetailsOnCustomerIdResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOnOrganisationIdResponse>
   */
  getApplicationOnOrganisationId(organisationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationOnOrganisationIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getApplicationOnOrganisationId(organisationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOnOrganisationId(organisationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getApplicationOnOrganisationId(organisationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetApplicationOnOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getApplicationOnOrganisationIdOperationSpec,
      callback) as Promise<Models.GetApplicationOnOrganisationIdResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationDetailOnOrganisationIdResponse>
   */
  getApplicationDetailOnOrganisationId(organisationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationDetailOnOrganisationIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getApplicationDetailOnOrganisationId(organisationId: string, callback: msRest.ServiceCallback<Models.Application>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationDetailOnOrganisationId(organisationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Application>): void;
  getApplicationDetailOnOrganisationId(organisationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Application>, callback?: msRest.ServiceCallback<Models.Application>): Promise<Models.GetApplicationDetailOnOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getApplicationDetailOnOrganisationIdOperationSpec,
      callback) as Promise<Models.GetApplicationDetailOnOrganisationIdResponse>;
  }

  /**
   * @param organisation
   * @param [options] The optional parameters
   * @returns Promise<Models.GetApplicationOnOrganisationNameResponse>
   */
  getApplicationOnOrganisationName(organisation: string, options?: msRest.RequestOptionsBase): Promise<Models.GetApplicationOnOrganisationNameResponse>;
  /**
   * @param organisation
   * @param callback The callback
   */
  getApplicationOnOrganisationName(organisation: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param organisation
   * @param options The optional parameters
   * @param callback The callback
   */
  getApplicationOnOrganisationName(organisation: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getApplicationOnOrganisationName(organisation: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetApplicationOnOrganisationNameResponse> {
    return this.sendOperationRequest(
      {
        organisation,
        options
      },
      getApplicationOnOrganisationNameOperationSpec,
      callback) as Promise<Models.GetApplicationOnOrganisationNameResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateApplicationCache(applicationId: string, options?: Models.OriginationReadApiUpdateApplicationCacheOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateApplicationCache(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateApplicationCache(applicationId: string, options: Models.OriginationReadApiUpdateApplicationCacheOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateApplicationCache(applicationId: string, options?: Models.OriginationReadApiUpdateApplicationCacheOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateApplicationCacheOperationSpec,
      callback);
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateCommunicationCache(applicationId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  updateCommunicationCache(applicationId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateCommunicationCache(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  updateCommunicationCache(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      updateCommunicationCacheOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApprovedResponse>
   */
  isApproved(options?: Models.OriginationReadApiIsApprovedOptionalParams): Promise<Models.IsApprovedResponse>;
  /**
   * @param callback The callback
   */
  isApproved(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isApproved(options: Models.OriginationReadApiIsApprovedOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  isApproved(options?: Models.OriginationReadApiIsApprovedOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsApprovedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isApprovedOperationSpec,
      callback) as Promise<Models.IsApprovedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApplicantApprovedResponse>
   */
  isApplicantApproved(options?: Models.OriginationReadApiIsApplicantApprovedOptionalParams): Promise<Models.IsApplicantApprovedResponse>;
  /**
   * @param callback The callback
   */
  isApplicantApproved(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isApplicantApproved(options: Models.OriginationReadApiIsApplicantApprovedOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  isApplicantApproved(options?: Models.OriginationReadApiIsApplicantApprovedOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsApplicantApprovedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isApplicantApprovedOperationSpec,
      callback) as Promise<Models.IsApplicantApprovedResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsApplicantEvaluatedResponse>
   */
  isApplicantEvaluated(options?: Models.OriginationReadApiIsApplicantEvaluatedOptionalParams): Promise<Models.IsApplicantEvaluatedResponse>;
  /**
   * @param callback The callback
   */
  isApplicantEvaluated(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isApplicantEvaluated(options: Models.OriginationReadApiIsApplicantEvaluatedOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  isApplicantEvaluated(options?: Models.OriginationReadApiIsApplicantEvaluatedOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsApplicantEvaluatedResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isApplicantEvaluatedOperationSpec,
      callback) as Promise<Models.IsApplicantEvaluatedResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSingleApplicationResponse>
   */
  getSingleApplication(customerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetSingleApplicationResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getSingleApplication(customerId: string, callback: msRest.ServiceCallback<Models.ApplicationListProjection>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSingleApplication(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ApplicationListProjection>): void;
  getSingleApplication(customerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ApplicationListProjection>, callback?: msRest.ServiceCallback<Models.ApplicationListProjection>): Promise<Models.GetSingleApplicationResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getSingleApplicationOperationSpec,
      callback) as Promise<Models.GetSingleApplicationResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetByPartnerResponse>
   */
  getByPartner(options?: Models.OriginationReadApiGetByPartnerOptionalParams): Promise<Models.GetByPartnerResponse>;
  /**
   * @param callback The callback
   */
  getByPartner(callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getByPartner(options: Models.OriginationReadApiGetByPartnerOptionalParams, callback: msRest.ServiceCallback<Models.ApplicationListProjection[]>): void;
  getByPartner(options?: Models.OriginationReadApiGetByPartnerOptionalParams | msRest.ServiceCallback<Models.ApplicationListProjection[]>, callback?: msRest.ServiceCallback<Models.ApplicationListProjection[]>): Promise<Models.GetByPartnerResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getByPartnerOperationSpec,
      callback) as Promise<Models.GetByPartnerResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsAuditRequiredResponse>
   */
  isAuditRequired(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsAuditRequiredResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isAuditRequired(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isAuditRequired(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isAuditRequired(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsAuditRequiredResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isAuditRequiredOperationSpec,
      callback) as Promise<Models.IsAuditRequiredResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuditDetailsResponse>
   */
  getAuditDetails(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAuditDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAuditDetails(loanId: string, callback: msRest.ServiceCallback<Models.AuditDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuditDetails(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AuditDetails>): void;
  getAuditDetails(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AuditDetails>, callback?: msRest.ServiceCallback<Models.AuditDetails>): Promise<Models.GetAuditDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAuditDetailsOperationSpec,
      callback) as Promise<Models.GetAuditDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCurrentAuditReportResponse>
   */
  getCurrentAuditReport(loanId: string, options?: Models.OriginationReadApiGetCurrentAuditReportOptionalParams): Promise<Models.GetCurrentAuditReportResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getCurrentAuditReport(loanId: string, callback: msRest.ServiceCallback<Models.AuditInvoiceDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCurrentAuditReport(loanId: string, options: Models.OriginationReadApiGetCurrentAuditReportOptionalParams, callback: msRest.ServiceCallback<Models.AuditInvoiceDetails>): void;
  getCurrentAuditReport(loanId: string, options?: Models.OriginationReadApiGetCurrentAuditReportOptionalParams | msRest.ServiceCallback<Models.AuditInvoiceDetails>, callback?: msRest.ServiceCallback<Models.AuditInvoiceDetails>): Promise<Models.GetCurrentAuditReportResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getCurrentAuditReportOperationSpec,
      callback) as Promise<Models.GetCurrentAuditReportResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRecentAuditReportsResponse>
   */
  getRecentAuditReports(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetRecentAuditReportsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getRecentAuditReports(loanId: string, callback: msRest.ServiceCallback<Models.AuditInvoiceDetails[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getRecentAuditReports(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AuditInvoiceDetails[]>): void;
  getRecentAuditReports(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AuditInvoiceDetails[]>, callback?: msRest.ServiceCallback<Models.AuditInvoiceDetails[]>): Promise<Models.GetRecentAuditReportsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getRecentAuditReportsOperationSpec,
      callback) as Promise<Models.GetRecentAuditReportsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCurrentAuditDocumentsResponse>
   */
  getCurrentAuditDocuments(loanId: string, options?: Models.OriginationReadApiGetCurrentAuditDocumentsOptionalParams): Promise<Models.GetCurrentAuditDocumentsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getCurrentAuditDocuments(loanId: string, callback: msRest.ServiceCallback<Models.PagedListAuditDocument>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCurrentAuditDocuments(loanId: string, options: Models.OriginationReadApiGetCurrentAuditDocumentsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListAuditDocument>): void;
  getCurrentAuditDocuments(loanId: string, options?: Models.OriginationReadApiGetCurrentAuditDocumentsOptionalParams | msRest.ServiceCallback<Models.PagedListAuditDocument>, callback?: msRest.ServiceCallback<Models.PagedListAuditDocument>): Promise<Models.GetCurrentAuditDocumentsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getCurrentAuditDocumentsOperationSpec,
      callback) as Promise<Models.GetCurrentAuditDocumentsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRecentAuditDocumentsResponse>
   */
  getRecentAuditDocuments(loanId: string, options?: Models.OriginationReadApiGetRecentAuditDocumentsOptionalParams): Promise<Models.GetRecentAuditDocumentsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getRecentAuditDocuments(loanId: string, callback: msRest.ServiceCallback<Models.PagedListAuditDocument>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getRecentAuditDocuments(loanId: string, options: Models.OriginationReadApiGetRecentAuditDocumentsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListAuditDocument>): void;
  getRecentAuditDocuments(loanId: string, options?: Models.OriginationReadApiGetRecentAuditDocumentsOptionalParams | msRest.ServiceCallback<Models.PagedListAuditDocument>, callback?: msRest.ServiceCallback<Models.PagedListAuditDocument>): Promise<Models.GetRecentAuditDocumentsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getRecentAuditDocumentsOperationSpec,
      callback) as Promise<Models.GetRecentAuditDocumentsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuditSettingsResponse>
   */
  getAuditSettings(options?: msRest.RequestOptionsBase): Promise<Models.GetAuditSettingsResponse>;
  /**
   * @param callback The callback
   */
  getAuditSettings(callback: msRest.ServiceCallback<Models.AuditSettings>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuditSettings(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AuditSettings>): void;
  getAuditSettings(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AuditSettings>, callback?: msRest.ServiceCallback<Models.AuditSettings>): Promise<Models.GetAuditSettingsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAuditSettingsOperationSpec,
      callback) as Promise<Models.GetAuditSettingsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsCorprateResponse>
   */
  isCorprate(options?: Models.OriginationReadApiIsCorprateOptionalParams): Promise<Models.IsCorprateResponse>;
  /**
   * @param callback The callback
   */
  isCorprate(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isCorprate(options: Models.OriginationReadApiIsCorprateOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  isCorprate(options?: Models.OriginationReadApiIsCorprateOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsCorprateResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isCorprateOperationSpec,
      callback) as Promise<Models.IsCorprateResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.HasCorprateLoggedInResponse>
   */
  hasCorprateLoggedIn(options?: Models.OriginationReadApiHasCorprateLoggedInOptionalParams): Promise<Models.HasCorprateLoggedInResponse>;
  /**
   * @param callback The callback
   */
  hasCorprateLoggedIn(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  hasCorprateLoggedIn(options: Models.OriginationReadApiHasCorprateLoggedInOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  hasCorprateLoggedIn(options?: Models.OriginationReadApiHasCorprateLoggedInOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.HasCorprateLoggedInResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      hasCorprateLoggedInOperationSpec,
      callback) as Promise<Models.HasCorprateLoggedInResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsCardServiceEnabledResponse>
   */
  isCardServiceEnabled(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsCardServiceEnabledResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isCardServiceEnabled(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isCardServiceEnabled(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isCardServiceEnabled(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsCardServiceEnabledResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isCardServiceEnabledOperationSpec,
      callback) as Promise<Models.IsCardServiceEnabledResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCardConfigurationResponse>
   */
  getCardConfiguration(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCardConfigurationResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getCardConfiguration(loanId: string, callback: msRest.ServiceCallback<Models.CardConfiguration>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCardConfiguration(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CardConfiguration>): void;
  getCardConfiguration(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CardConfiguration>, callback?: msRest.ServiceCallback<Models.CardConfiguration>): Promise<Models.GetCardConfigurationResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getCardConfigurationOperationSpec,
      callback) as Promise<Models.GetCardConfigurationResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllCardHolderCardsResponse>
   */
  getAllCardHolderCards(loanId: string, options?: Models.OriginationReadApiGetAllCardHolderCardsOptionalParams): Promise<Models.GetAllCardHolderCardsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllCardHolderCards(loanId: string, callback: msRest.ServiceCallback<Models.PagedListCardHolderDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllCardHolderCards(loanId: string, options: Models.OriginationReadApiGetAllCardHolderCardsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCardHolderDetails>): void;
  getAllCardHolderCards(loanId: string, options?: Models.OriginationReadApiGetAllCardHolderCardsOptionalParams | msRest.ServiceCallback<Models.PagedListCardHolderDetails>, callback?: msRest.ServiceCallback<Models.PagedListCardHolderDetails>): Promise<Models.GetAllCardHolderCardsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllCardHolderCardsOperationSpec,
      callback) as Promise<Models.GetAllCardHolderCardsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCardResponse>
   */
  getCard(loanId: string, options?: Models.OriginationReadApiGetCardOptionalParams): Promise<Models.GetCardResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getCard(loanId: string, callback: msRest.ServiceCallback<Models.CardHolderDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCard(loanId: string, options: Models.OriginationReadApiGetCardOptionalParams, callback: msRest.ServiceCallback<Models.CardHolderDetails>): void;
  getCard(loanId: string, options?: Models.OriginationReadApiGetCardOptionalParams | msRest.ServiceCallback<Models.CardHolderDetails>, callback?: msRest.ServiceCallback<Models.CardHolderDetails>): Promise<Models.GetCardResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getCardOperationSpec,
      callback) as Promise<Models.GetCardResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTransactionsPerCardResponse>
   */
  getTransactionsPerCard(loanId: string, options?: Models.OriginationReadApiGetTransactionsPerCardOptionalParams): Promise<Models.GetTransactionsPerCardResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getTransactionsPerCard(loanId: string, callback: msRest.ServiceCallback<Models.PagedListCardTransaction>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getTransactionsPerCard(loanId: string, options: Models.OriginationReadApiGetTransactionsPerCardOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCardTransaction>): void;
  getTransactionsPerCard(loanId: string, options?: Models.OriginationReadApiGetTransactionsPerCardOptionalParams | msRest.ServiceCallback<Models.PagedListCardTransaction>, callback?: msRest.ServiceCallback<Models.PagedListCardTransaction>): Promise<Models.GetTransactionsPerCardResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getTransactionsPerCardOperationSpec,
      callback) as Promise<Models.GetTransactionsPerCardResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetTransactionsResponse>
   */
  getTransactions(loanId: string, options?: Models.OriginationReadApiGetTransactionsOptionalParams): Promise<Models.GetTransactionsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getTransactions(loanId: string, callback: msRest.ServiceCallback<Models.PagedListCardTransaction>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getTransactions(loanId: string, options: Models.OriginationReadApiGetTransactionsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCardTransaction>): void;
  getTransactions(loanId: string, options?: Models.OriginationReadApiGetTransactionsOptionalParams | msRest.ServiceCallback<Models.PagedListCardTransaction>, callback?: msRest.ServiceCallback<Models.PagedListCardTransaction>): Promise<Models.GetTransactionsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getTransactionsOperationSpec,
      callback) as Promise<Models.GetTransactionsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWebComponentTokenResponse>
   */
  getWebComponentToken(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetWebComponentTokenResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getWebComponentToken(loanId: string, callback: msRest.ServiceCallback<Models.HydrogenAppTokenResponse>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getWebComponentToken(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.HydrogenAppTokenResponse>): void;
  getWebComponentToken(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.HydrogenAppTokenResponse>, callback?: msRest.ServiceCallback<Models.HydrogenAppTokenResponse>): Promise<Models.GetWebComponentTokenResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getWebComponentTokenOperationSpec,
      callback) as Promise<Models.GetWebComponentTokenResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCorpratesResponse>
   */
  getCorprates(loanId: string, options?: Models.OriginationReadApiGetCorpratesOptionalParams): Promise<Models.GetCorpratesResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getCorprates(loanId: string, callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCorprates(loanId: string, options: Models.OriginationReadApiGetCorpratesOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  getCorprates(loanId: string, options?: Models.OriginationReadApiGetCorpratesOptionalParams | msRest.ServiceCallback<Models.PagedListCustomerProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerProjection>): Promise<Models.GetCorpratesResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getCorpratesOperationSpec,
      callback) as Promise<Models.GetCorpratesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loadCustomerCache(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  loadCustomerCache(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  loadCustomerCache(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  loadCustomerCache(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      loadCustomerCacheOperationSpec,
      callback);
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomerResponse>
   */
  getCustomer(customerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCustomerResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getCustomer(customerId: string, callback: msRest.ServiceCallback<Models.Customer>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomer(customerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Customer>): void;
  getCustomer(customerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Customer>, callback?: msRest.ServiceCallback<Models.Customer>): Promise<Models.GetCustomerResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getCustomerOperationSpec,
      callback) as Promise<Models.GetCustomerResponse>;
  }

  /**
   * @param email
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomerOnEmailResponse>
   */
  getCustomerOnEmail(email: string, options?: msRest.RequestOptionsBase): Promise<Models.GetCustomerOnEmailResponse>;
  /**
   * @param email
   * @param callback The callback
   */
  getCustomerOnEmail(email: string, callback: msRest.ServiceCallback<Models.Customer>): void;
  /**
   * @param email
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomerOnEmail(email: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Customer>): void;
  getCustomerOnEmail(email: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Customer>, callback?: msRest.ServiceCallback<Models.Customer>): Promise<Models.GetCustomerOnEmailResponse> {
    return this.sendOperationRequest(
      {
        email,
        options
      },
      getCustomerOnEmailOperationSpec,
      callback) as Promise<Models.GetCustomerOnEmailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCustomersResponse>
   */
  getCustomers(options?: Models.OriginationReadApiGetCustomersOptionalParams): Promise<Models.GetCustomersResponse>;
  /**
   * @param callback The callback
   */
  getCustomers(callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getCustomers(options: Models.OriginationReadApiGetCustomersOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  getCustomers(options?: Models.OriginationReadApiGetCustomersOptionalParams | msRest.ServiceCallback<Models.PagedListCustomerProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerProjection>): Promise<Models.GetCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getCustomersOperationSpec,
      callback) as Promise<Models.GetCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchCustomersResponse>
   */
  searchCustomers(options?: Models.OriginationReadApiSearchCustomersOptionalParams): Promise<Models.SearchCustomersResponse>;
  /**
   * @param callback The callback
   */
  searchCustomers(callback: msRest.ServiceCallback<Models.CustomerProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchCustomers(options: Models.OriginationReadApiSearchCustomersOptionalParams, callback: msRest.ServiceCallback<Models.CustomerProjection[]>): void;
  searchCustomers(options?: Models.OriginationReadApiSearchCustomersOptionalParams | msRest.ServiceCallback<Models.CustomerProjection[]>, callback?: msRest.ServiceCallback<Models.CustomerProjection[]>): Promise<Models.SearchCustomersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      searchCustomersOperationSpec,
      callback) as Promise<Models.SearchCustomersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuthorizationTokenResponse>
   */
  getAuthorizationToken(options?: Models.OriginationReadApiGetAuthorizationTokenOptionalParams): Promise<Models.GetAuthorizationTokenResponse>;
  /**
   * @param callback The callback
   */
  getAuthorizationToken(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuthorizationToken(options: Models.OriginationReadApiGetAuthorizationTokenOptionalParams, callback: msRest.ServiceCallback<string>): void;
  getAuthorizationToken(options?: Models.OriginationReadApiGetAuthorizationTokenOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetAuthorizationTokenResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAuthorizationTokenOperationSpec,
      callback) as Promise<Models.GetAuthorizationTokenResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDocumentsPagedResponse>
   */
  getDocumentsPaged(applicationId: string, options?: Models.OriginationReadApiGetDocumentsPagedOptionalParams): Promise<Models.GetDocumentsPagedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getDocumentsPaged(applicationId: string, callback: msRest.ServiceCallback<Models.PagedListTransactionDataFile>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getDocumentsPaged(applicationId: string, options: Models.OriginationReadApiGetDocumentsPagedOptionalParams, callback: msRest.ServiceCallback<Models.PagedListTransactionDataFile>): void;
  getDocumentsPaged(applicationId: string, options?: Models.OriginationReadApiGetDocumentsPagedOptionalParams | msRest.ServiceCallback<Models.PagedListTransactionDataFile>, callback?: msRest.ServiceCallback<Models.PagedListTransactionDataFile>): Promise<Models.GetDocumentsPagedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getDocumentsPagedOperationSpec,
      callback) as Promise<Models.GetDocumentsPagedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadDocumentResponse>
   */
  downloadDocument(applicationId: string, options?: Models.OriginationReadApiDownloadDocumentOptionalParams): Promise<Models.DownloadDocumentResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  downloadDocument(applicationId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadDocument(applicationId: string, options: Models.OriginationReadApiDownloadDocumentOptionalParams, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  downloadDocument(applicationId: string, options?: Models.OriginationReadApiDownloadDocumentOptionalParams | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.DownloadDocumentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      downloadDocumentOperationSpec,
      callback) as Promise<Models.DownloadDocumentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsEmployeeResponse>
   */
  isEmployee(options?: Models.OriginationReadApiIsEmployeeOptionalParams): Promise<Models.IsEmployeeResponse>;
  /**
   * @param callback The callback
   */
  isEmployee(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isEmployee(options: Models.OriginationReadApiIsEmployeeOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  isEmployee(options?: Models.OriginationReadApiIsEmployeeOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsEmployeeResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isEmployeeOperationSpec,
      callback) as Promise<Models.IsEmployeeResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.HasLoggedIn1Response>
   */
  hasLoggedIn1(options?: Models.OriginationReadApiHasLoggedIn1OptionalParams): Promise<Models.HasLoggedIn1Response>;
  /**
   * @param callback The callback
   */
  hasLoggedIn1(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  hasLoggedIn1(options: Models.OriginationReadApiHasLoggedIn1OptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  hasLoggedIn1(options?: Models.OriginationReadApiHasLoggedIn1OptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.HasLoggedIn1Response> {
    return this.sendOperationRequest(
      {
        options
      },
      hasLoggedIn1OperationSpec,
      callback) as Promise<Models.HasLoggedIn1Response>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsPayrollSystemConnectedResponse>
   */
  isPayrollSystemConnected(loanId: string, options?: Models.OriginationReadApiIsPayrollSystemConnectedOptionalParams): Promise<Models.IsPayrollSystemConnectedResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isPayrollSystemConnected(loanId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isPayrollSystemConnected(loanId: string, options: Models.OriginationReadApiIsPayrollSystemConnectedOptionalParams, callback: msRest.ServiceCallback<boolean>): void;
  isPayrollSystemConnected(loanId: string, options?: Models.OriginationReadApiIsPayrollSystemConnectedOptionalParams | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsPayrollSystemConnectedResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isPayrollSystemConnectedOperationSpec,
      callback) as Promise<Models.IsPayrollSystemConnectedResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPayrollDetailsResponse>
   */
  getPayrollDetails(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPayrollDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPayrollDetails(loanId: string, callback: msRest.ServiceCallback<Models.ProviderDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPayrollDetails(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ProviderDetails>): void;
  getPayrollDetails(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ProviderDetails>, callback?: msRest.ServiceCallback<Models.ProviderDetails>): Promise<Models.GetPayrollDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPayrollDetailsOperationSpec,
      callback) as Promise<Models.GetPayrollDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEmployeePageDetailsResponse>
   */
  getEmployeePageDetails(loanId: string, options?: Models.OriginationReadApiGetEmployeePageDetailsOptionalParams): Promise<Models.GetEmployeePageDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getEmployeePageDetails(loanId: string, callback: msRest.ServiceCallback<Models.PagedListEmployeeDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getEmployeePageDetails(loanId: string, options: Models.OriginationReadApiGetEmployeePageDetailsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListEmployeeDetails>): void;
  getEmployeePageDetails(loanId: string, options?: Models.OriginationReadApiGetEmployeePageDetailsOptionalParams | msRest.ServiceCallback<Models.PagedListEmployeeDetails>, callback?: msRest.ServiceCallback<Models.PagedListEmployeeDetails>): Promise<Models.GetEmployeePageDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getEmployeePageDetailsOperationSpec,
      callback) as Promise<Models.GetEmployeePageDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEmployeeDetailResponse>
   */
  getEmployeeDetail(loanId: string, options?: Models.OriginationReadApiGetEmployeeDetailOptionalParams): Promise<Models.GetEmployeeDetailResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getEmployeeDetail(loanId: string, callback: msRest.ServiceCallback<Models.EmployeeDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getEmployeeDetail(loanId: string, options: Models.OriginationReadApiGetEmployeeDetailOptionalParams, callback: msRest.ServiceCallback<Models.EmployeeDetails>): void;
  getEmployeeDetail(loanId: string, options?: Models.OriginationReadApiGetEmployeeDetailOptionalParams | msRest.ServiceCallback<Models.EmployeeDetails>, callback?: msRest.ServiceCallback<Models.EmployeeDetails>): Promise<Models.GetEmployeeDetailResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getEmployeeDetailOperationSpec,
      callback) as Promise<Models.GetEmployeeDetailResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEmployeeWithdrawDetailsResponse>
   */
  getEmployeeWithdrawDetails(loanId: string, options?: Models.OriginationReadApiGetEmployeeWithdrawDetailsOptionalParams): Promise<Models.GetEmployeeWithdrawDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getEmployeeWithdrawDetails(loanId: string, callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getEmployeeWithdrawDetails(loanId: string, options: Models.OriginationReadApiGetEmployeeWithdrawDetailsOptionalParams, callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  getEmployeeWithdrawDetails(loanId: string, options?: Models.OriginationReadApiGetEmployeeWithdrawDetailsOptionalParams | msRest.ServiceCallback<{ [propertyName: string]: string }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>): Promise<Models.GetEmployeeWithdrawDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getEmployeeWithdrawDetailsOperationSpec,
      callback) as Promise<Models.GetEmployeeWithdrawDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateWithdrawRequestResponse>
   */
  calculateWithdrawRequest(loanId: string, options?: Models.OriginationReadApiCalculateWithdrawRequestOptionalParams): Promise<Models.CalculateWithdrawRequestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculateWithdrawRequest(loanId: string, callback: msRest.ServiceCallback<Models.WithdrawRequest>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateWithdrawRequest(loanId: string, options: Models.OriginationReadApiCalculateWithdrawRequestOptionalParams, callback: msRest.ServiceCallback<Models.WithdrawRequest>): void;
  calculateWithdrawRequest(loanId: string, options?: Models.OriginationReadApiCalculateWithdrawRequestOptionalParams | msRest.ServiceCallback<Models.WithdrawRequest>, callback?: msRest.ServiceCallback<Models.WithdrawRequest>): Promise<Models.CalculateWithdrawRequestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculateWithdrawRequestOperationSpec,
      callback) as Promise<Models.CalculateWithdrawRequestResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllWithdrawRequestsResponse>
   */
  getAllWithdrawRequests(loanId: string, options?: Models.OriginationReadApiGetAllWithdrawRequestsOptionalParams): Promise<Models.GetAllWithdrawRequestsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllWithdrawRequests(loanId: string, callback: msRest.ServiceCallback<Models.WithdrawRequest[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllWithdrawRequests(loanId: string, options: Models.OriginationReadApiGetAllWithdrawRequestsOptionalParams, callback: msRest.ServiceCallback<Models.WithdrawRequest[]>): void;
  getAllWithdrawRequests(loanId: string, options?: Models.OriginationReadApiGetAllWithdrawRequestsOptionalParams | msRest.ServiceCallback<Models.WithdrawRequest[]>, callback?: msRest.ServiceCallback<Models.WithdrawRequest[]>): Promise<Models.GetAllWithdrawRequestsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllWithdrawRequestsOperationSpec,
      callback) as Promise<Models.GetAllWithdrawRequestsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetWithdrawRequestResponse>
   */
  getWithdrawRequest(loanId: string, options?: Models.OriginationReadApiGetWithdrawRequestOptionalParams): Promise<Models.GetWithdrawRequestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getWithdrawRequest(loanId: string, callback: msRest.ServiceCallback<Models.WithdrawRequest>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getWithdrawRequest(loanId: string, options: Models.OriginationReadApiGetWithdrawRequestOptionalParams, callback: msRest.ServiceCallback<Models.WithdrawRequest>): void;
  getWithdrawRequest(loanId: string, options?: Models.OriginationReadApiGetWithdrawRequestOptionalParams | msRest.ServiceCallback<Models.WithdrawRequest>, callback?: msRest.ServiceCallback<Models.WithdrawRequest>): Promise<Models.GetWithdrawRequestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getWithdrawRequestOperationSpec,
      callback) as Promise<Models.GetWithdrawRequestResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetFinchRedirectUrlResponse>
   */
  getFinchRedirectUrl(options?: msRest.RequestOptionsBase): Promise<Models.GetFinchRedirectUrlResponse>;
  /**
   * @param callback The callback
   */
  getFinchRedirectUrl(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFinchRedirectUrl(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getFinchRedirectUrl(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetFinchRedirectUrlResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getFinchRedirectUrlOperationSpec,
      callback) as Promise<Models.GetFinchRedirectUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetEmployeesResponse>
   */
  getEmployees(options?: Models.OriginationReadApiGetEmployeesOptionalParams): Promise<Models.GetEmployeesResponse>;
  /**
   * @param callback The callback
   */
  getEmployees(callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getEmployees(options: Models.OriginationReadApiGetEmployeesOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  getEmployees(options?: Models.OriginationReadApiGetEmployeesOptionalParams | msRest.ServiceCallback<Models.PagedListCustomerProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerProjection>): Promise<Models.GetEmployeesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getEmployeesOperationSpec,
      callback) as Promise<Models.GetEmployeesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetFilterEmployeesResponse>
   */
  getFilterEmployees(options?: Models.OriginationReadApiGetFilterEmployeesOptionalParams): Promise<Models.GetFilterEmployeesResponse>;
  /**
   * @param callback The callback
   */
  getFilterEmployees(callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getFilterEmployees(options: Models.OriginationReadApiGetFilterEmployeesOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCustomerProjection>): void;
  getFilterEmployees(options?: Models.OriginationReadApiGetFilterEmployeesOptionalParams | msRest.ServiceCallback<Models.PagedListCustomerProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerProjection>): Promise<Models.GetFilterEmployeesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getFilterEmployeesOperationSpec,
      callback) as Promise<Models.GetFilterEmployeesResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllContractDocumentsResponse>
   */
  getAllContractDocuments(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetAllContractDocumentsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAllContractDocuments(applicationId: string, callback: msRest.ServiceCallback<Models.ContractDocument[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllContractDocuments(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ContractDocument[]>): void;
  getAllContractDocuments(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ContractDocument[]>, callback?: msRest.ServiceCallback<Models.ContractDocument[]>): Promise<Models.GetAllContractDocumentsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAllContractDocumentsOperationSpec,
      callback) as Promise<Models.GetAllContractDocumentsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllContractDocumentsPagedResponse>
   */
  getAllContractDocumentsPaged(applicationId: string, options?: Models.OriginationReadApiGetAllContractDocumentsPagedOptionalParams): Promise<Models.GetAllContractDocumentsPagedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getAllContractDocumentsPaged(applicationId: string, callback: msRest.ServiceCallback<Models.PagedListContractDocument>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllContractDocumentsPaged(applicationId: string, options: Models.OriginationReadApiGetAllContractDocumentsPagedOptionalParams, callback: msRest.ServiceCallback<Models.PagedListContractDocument>): void;
  getAllContractDocumentsPaged(applicationId: string, options?: Models.OriginationReadApiGetAllContractDocumentsPagedOptionalParams | msRest.ServiceCallback<Models.PagedListContractDocument>, callback?: msRest.ServiceCallback<Models.PagedListContractDocument>): Promise<Models.GetAllContractDocumentsPagedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getAllContractDocumentsPagedOperationSpec,
      callback) as Promise<Models.GetAllContractDocumentsPagedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.DeletePastContractResponse>
   */
  deletePastContract(applicationId: string, options?: Models.OriginationReadApiDeletePastContractOptionalParams): Promise<Models.DeletePastContractResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  deletePastContract(applicationId: string, callback: msRest.ServiceCallback<Models.PagedListContractDocument>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  deletePastContract(applicationId: string, options: Models.OriginationReadApiDeletePastContractOptionalParams, callback: msRest.ServiceCallback<Models.PagedListContractDocument>): void;
  deletePastContract(applicationId: string, options?: Models.OriginationReadApiDeletePastContractOptionalParams | msRest.ServiceCallback<Models.PagedListContractDocument>, callback?: msRest.ServiceCallback<Models.PagedListContractDocument>): Promise<Models.DeletePastContractResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      deletePastContractOperationSpec,
      callback) as Promise<Models.DeletePastContractResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBaseSupportDocumentResponse>
   */
  getBaseSupportDocument(options?: msRest.RequestOptionsBase): Promise<Models.GetBaseSupportDocumentResponse>;
  /**
   * @param callback The callback
   */
  getBaseSupportDocument(callback: msRest.ServiceCallback<Models.ContractDocument[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBaseSupportDocument(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.ContractDocument[]>): void;
  getBaseSupportDocument(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.ContractDocument[]>, callback?: msRest.ServiceCallback<Models.ContractDocument[]>): Promise<Models.GetBaseSupportDocumentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBaseSupportDocumentOperationSpec,
      callback) as Promise<Models.GetBaseSupportDocumentResponse>;
  }

  /**
   * @param documentId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadContractFileOnDocumentIdResponse>
   */
  downloadContractFileOnDocumentId(documentId: string, options?: Models.OriginationReadApiDownloadContractFileOnDocumentIdOptionalParams): Promise<Models.DownloadContractFileOnDocumentIdResponse>;
  /**
   * @param documentId
   * @param callback The callback
   */
  downloadContractFileOnDocumentId(documentId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param documentId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadContractFileOnDocumentId(documentId: string, options: Models.OriginationReadApiDownloadContractFileOnDocumentIdOptionalParams, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  downloadContractFileOnDocumentId(documentId: string, options?: Models.OriginationReadApiDownloadContractFileOnDocumentIdOptionalParams | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.DownloadContractFileOnDocumentIdResponse> {
    return this.sendOperationRequest(
      {
        documentId,
        options
      },
      downloadContractFileOnDocumentIdOperationSpec,
      callback) as Promise<Models.DownloadContractFileOnDocumentIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetW9FormSampleDocumentResponse>
   */
  getW9FormSampleDocument(options?: msRest.RequestOptionsBase): Promise<Models.GetW9FormSampleDocumentResponse>;
  /**
   * @param callback The callback
   */
  getW9FormSampleDocument(callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getW9FormSampleDocument(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  getW9FormSampleDocument(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.GetW9FormSampleDocumentResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getW9FormSampleDocumentOperationSpec,
      callback) as Promise<Models.GetW9FormSampleDocumentResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loadLoanCache(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  loadLoanCache(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  loadLoanCache(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  loadLoanCache(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      loadLoanCacheOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CustomerLookupOfflineResponse>
   */
  customerLookupOffline(options?: msRest.RequestOptionsBase): Promise<Models.CustomerLookupOfflineResponse>;
  /**
   * @param callback The callback
   */
  customerLookupOffline(callback: msRest.ServiceCallback<Models.CustomerLookupData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  customerLookupOffline(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CustomerLookupData[]>): void;
  customerLookupOffline(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CustomerLookupData[]>, callback?: msRest.ServiceCallback<Models.CustomerLookupData[]>): Promise<Models.CustomerLookupOfflineResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      customerLookupOfflineOperationSpec,
      callback) as Promise<Models.CustomerLookupOfflineResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CustomerLookupResponse>
   */
  customerLookup(options?: Models.OriginationReadApiCustomerLookupOptionalParams): Promise<Models.CustomerLookupResponse>;
  /**
   * @param callback The callback
   */
  customerLookup(callback: msRest.ServiceCallback<Models.CustomerLookupData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  customerLookup(options: Models.OriginationReadApiCustomerLookupOptionalParams, callback: msRest.ServiceCallback<Models.CustomerLookupData[]>): void;
  customerLookup(options?: Models.OriginationReadApiCustomerLookupOptionalParams | msRest.ServiceCallback<Models.CustomerLookupData[]>, callback?: msRest.ServiceCallback<Models.CustomerLookupData[]>): Promise<Models.CustomerLookupResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      customerLookupOperationSpec,
      callback) as Promise<Models.CustomerLookupResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansOnCustomerIdResponse>
   */
  getLoansOnCustomerId(customerId: string, options?: Models.OriginationReadApiGetLoansOnCustomerIdOptionalParams): Promise<Models.GetLoansOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getLoansOnCustomerId(customerId: string, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoansOnCustomerId(customerId: string, options: Models.OriginationReadApiGetLoansOnCustomerIdOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  getLoansOnCustomerId(customerId: string, options?: Models.OriginationReadApiGetLoansOnCustomerIdOptionalParams | msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): Promise<Models.GetLoansOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getLoansOnCustomerIdOperationSpec,
      callback) as Promise<Models.GetLoansOnCustomerIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentQueriesResponse>
   */
  getPaymentQueries(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPaymentQueriesResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentQueries(loanId: string, callback: msRest.ServiceCallback<Models.PaymentQuery[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentQueries(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PaymentQuery[]>): void;
  getPaymentQueries(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PaymentQuery[]>, callback?: msRest.ServiceCallback<Models.PaymentQuery[]>): Promise<Models.GetPaymentQueriesResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentQueriesOperationSpec,
      callback) as Promise<Models.GetPaymentQueriesResponse>;
  }

  /**
   * @param customerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse>
   */
  getLoanReadyApplicationsOnCustomerId(customerId: string, options?: Models.OriginationReadApiGetLoanReadyApplicationsOnCustomerIdOptionalParams): Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse>;
  /**
   * @param customerId
   * @param callback The callback
   */
  getLoanReadyApplicationsOnCustomerId(customerId: string, callback: msRest.ServiceCallback<Models.PagedListLoanReadyApplication>): void;
  /**
   * @param customerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanReadyApplicationsOnCustomerId(customerId: string, options: Models.OriginationReadApiGetLoanReadyApplicationsOnCustomerIdOptionalParams, callback: msRest.ServiceCallback<Models.PagedListLoanReadyApplication>): void;
  getLoanReadyApplicationsOnCustomerId(customerId: string, options?: Models.OriginationReadApiGetLoanReadyApplicationsOnCustomerIdOptionalParams | msRest.ServiceCallback<Models.PagedListLoanReadyApplication>, callback?: msRest.ServiceCallback<Models.PagedListLoanReadyApplication>): Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse> {
    return this.sendOperationRequest(
      {
        customerId,
        options
      },
      getLoanReadyApplicationsOnCustomerIdOperationSpec,
      callback) as Promise<Models.GetLoanReadyApplicationsOnCustomerIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetCommissionOnLoanResponse>
   */
  getCommissionOnLoan(loanId: string, options?: Models.OriginationReadApiGetCommissionOnLoanOptionalParams): Promise<Models.GetCommissionOnLoanResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getCommissionOnLoan(loanId: string, callback: msRest.ServiceCallback<Models.Commission>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getCommissionOnLoan(loanId: string, options: Models.OriginationReadApiGetCommissionOnLoanOptionalParams, callback: msRest.ServiceCallback<Models.Commission>): void;
  getCommissionOnLoan(loanId: string, options?: Models.OriginationReadApiGetCommissionOnLoanOptionalParams | msRest.ServiceCallback<Models.Commission>, callback?: msRest.ServiceCallback<Models.Commission>): Promise<Models.GetCommissionOnLoanResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getCommissionOnLoanOperationSpec,
      callback) as Promise<Models.GetCommissionOnLoanResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentArrangementsResponse>
   */
  getPaymentArrangements(loanId: string, options?: Models.OriginationReadApiGetPaymentArrangementsOptionalParams): Promise<Models.GetPaymentArrangementsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentArrangements(loanId: string, callback: msRest.ServiceCallback<Models.PaymentArrangement[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentArrangements(loanId: string, options: Models.OriginationReadApiGetPaymentArrangementsOptionalParams, callback: msRest.ServiceCallback<Models.PaymentArrangement[]>): void;
  getPaymentArrangements(loanId: string, options?: Models.OriginationReadApiGetPaymentArrangementsOptionalParams | msRest.ServiceCallback<Models.PaymentArrangement[]>, callback?: msRest.ServiceCallback<Models.PaymentArrangement[]>): Promise<Models.GetPaymentArrangementsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentArrangementsOperationSpec,
      callback) as Promise<Models.GetPaymentArrangementsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanNotificationsResponse>
   */
  getLoanNotifications(loanId: string, options?: Models.OriginationReadApiGetLoanNotificationsOptionalParams): Promise<Models.GetLoanNotificationsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanNotifications(loanId: string, callback: msRest.ServiceCallback<Models.PagedListLoanNotificationProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanNotifications(loanId: string, options: Models.OriginationReadApiGetLoanNotificationsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListLoanNotificationProjection>): void;
  getLoanNotifications(loanId: string, options?: Models.OriginationReadApiGetLoanNotificationsOptionalParams | msRest.ServiceCallback<Models.PagedListLoanNotificationProjection>, callback?: msRest.ServiceCallback<Models.PagedListLoanNotificationProjection>): Promise<Models.GetLoanNotificationsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanNotificationsOperationSpec,
      callback) as Promise<Models.GetLoanNotificationsResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanByOrganisationIdResponse>
   */
  getLoanByOrganisationId(organisationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanByOrganisationIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getLoanByOrganisationId(organisationId: string, callback: msRest.ServiceCallback<Models.Loan>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanByOrganisationId(organisationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Loan>): void;
  getLoanByOrganisationId(organisationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Loan>, callback?: msRest.ServiceCallback<Models.Loan>): Promise<Models.GetLoanByOrganisationIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getLoanByOrganisationIdOperationSpec,
      callback) as Promise<Models.GetLoanByOrganisationIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanResponse>
   */
  getLoan(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoan(loanId: string, callback: msRest.ServiceCallback<Models.Loan>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoan(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Loan>): void;
  getLoan(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Loan>, callback?: msRest.ServiceCallback<Models.Loan>): Promise<Models.GetLoanResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanOperationSpec,
      callback) as Promise<Models.GetLoanResponse>;
  }

  /**
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanDataByOrganistionIdResponse>
   */
  getLoanDataByOrganistionId(organisationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanDataByOrganistionIdResponse>;
  /**
   * @param organisationId
   * @param callback The callback
   */
  getLoanDataByOrganistionId(organisationId: string, callback: msRest.ServiceCallback<Models.LoanData>): void;
  /**
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanDataByOrganistionId(organisationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.LoanData>): void;
  getLoanDataByOrganistionId(organisationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LoanData>, callback?: msRest.ServiceCallback<Models.LoanData>): Promise<Models.GetLoanDataByOrganistionIdResponse> {
    return this.sendOperationRequest(
      {
        organisationId,
        options
      },
      getLoanDataByOrganistionIdOperationSpec,
      callback) as Promise<Models.GetLoanDataByOrganistionIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanDataByLoanIdResponse>
   */
  getLoanDataByLoanId(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanDataByLoanIdResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanDataByLoanId(loanId: string, callback: msRest.ServiceCallback<Models.LoanData>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanDataByLoanId(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.LoanData>): void;
  getLoanDataByLoanId(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LoanData>, callback?: msRest.ServiceCallback<Models.LoanData>): Promise<Models.GetLoanDataByLoanIdResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanDataByLoanIdOperationSpec,
      callback) as Promise<Models.GetLoanDataByLoanIdResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanBalancesResponse>
   */
  getLoanBalances(loanId: string, options?: Models.OriginationReadApiGetLoanBalancesOptionalParams): Promise<Models.GetLoanBalancesResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanBalances(loanId: string, callback: msRest.ServiceCallback<Models.LoanBalances>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanBalances(loanId: string, options: Models.OriginationReadApiGetLoanBalancesOptionalParams, callback: msRest.ServiceCallback<Models.LoanBalances>): void;
  getLoanBalances(loanId: string, options?: Models.OriginationReadApiGetLoanBalancesOptionalParams | msRest.ServiceCallback<Models.LoanBalances>, callback?: msRest.ServiceCallback<Models.LoanBalances>): Promise<Models.GetLoanBalancesResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanBalancesOperationSpec,
      callback) as Promise<Models.GetLoanBalancesResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoanBalancesResponse>
   */
  getAllLoanBalances(loanId: string, options?: Models.OriginationReadApiGetAllLoanBalancesOptionalParams): Promise<Models.GetAllLoanBalancesResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getAllLoanBalances(loanId: string, callback: msRest.ServiceCallback<Models.AllLoanBalances>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoanBalances(loanId: string, options: Models.OriginationReadApiGetAllLoanBalancesOptionalParams, callback: msRest.ServiceCallback<Models.AllLoanBalances>): void;
  getAllLoanBalances(loanId: string, options?: Models.OriginationReadApiGetAllLoanBalancesOptionalParams | msRest.ServiceCallback<Models.AllLoanBalances>, callback?: msRest.ServiceCallback<Models.AllLoanBalances>): Promise<Models.GetAllLoanBalancesResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getAllLoanBalancesOperationSpec,
      callback) as Promise<Models.GetAllLoanBalancesResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanSettingsResponse>
   */
  getLoanSettings(loanId: string, options?: Models.OriginationReadApiGetLoanSettingsOptionalParams): Promise<Models.GetLoanSettingsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanSettings(loanId: string, callback: msRest.ServiceCallback<Models.LoanSettings>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanSettings(loanId: string, options: Models.OriginationReadApiGetLoanSettingsOptionalParams, callback: msRest.ServiceCallback<Models.LoanSettings>): void;
  getLoanSettings(loanId: string, options?: Models.OriginationReadApiGetLoanSettingsOptionalParams | msRest.ServiceCallback<Models.LoanSettings>, callback?: msRest.ServiceCallback<Models.LoanSettings>): Promise<Models.GetLoanSettingsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanSettingsOperationSpec,
      callback) as Promise<Models.GetLoanSettingsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllLoanDataResponse>
   */
  getAllLoanData(options?: msRest.RequestOptionsBase): Promise<Models.GetAllLoanDataResponse>;
  /**
   * @param callback The callback
   */
  getAllLoanData(callback: msRest.ServiceCallback<Models.LoanData[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllLoanData(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.LoanData[]>): void;
  getAllLoanData(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LoanData[]>, callback?: msRest.ServiceCallback<Models.LoanData[]>): Promise<Models.GetAllLoanDataResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllLoanDataOperationSpec,
      callback) as Promise<Models.GetAllLoanDataResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansResponse>
   */
  getLoans(options?: Models.OriginationReadApiGetLoansOptionalParams): Promise<Models.GetLoansResponse>;
  /**
   * @param callback The callback
   */
  getLoans(callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoans(options: Models.OriginationReadApiGetLoansOptionalParams, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  getLoans(options?: Models.OriginationReadApiGetLoansOptionalParams | msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): Promise<Models.GetLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getLoansOperationSpec,
      callback) as Promise<Models.GetLoansResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetClosedLoansResponse>
   */
  getClosedLoans(options?: msRest.RequestOptionsBase): Promise<Models.GetClosedLoansResponse>;
  /**
   * @param callback The callback
   */
  getClosedLoans(callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getClosedLoans(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  getClosedLoans(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): Promise<Models.GetClosedLoansResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getClosedLoansOperationSpec,
      callback) as Promise<Models.GetClosedLoansResponse>;
  }

  /**
   * @param typeName
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansByTypeResponse>
   */
  getLoansByType(typeName: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoansByTypeResponse>;
  /**
   * @param typeName
   * @param callback The callback
   */
  getLoansByType(typeName: string, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  /**
   * @param typeName
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoansByType(typeName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  getLoansByType(typeName: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): Promise<Models.GetLoansByTypeResponse> {
    return this.sendOperationRequest(
      {
        typeName,
        options
      },
      getLoansByTypeOperationSpec,
      callback) as Promise<Models.GetLoansByTypeResponse>;
  }

  /**
   * @param criteria
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoansByHealthResponse>
   */
  getLoansByHealth(criteria: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoansByHealthResponse>;
  /**
   * @param criteria
   * @param callback The callback
   */
  getLoansByHealth(criteria: string, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  /**
   * @param criteria
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoansByHealth(criteria: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): void;
  getLoansByHealth(criteria: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>, callback?: msRest.ServiceCallback<Models.PagedListCustomerLoanProjection>): Promise<Models.GetLoansByHealthResponse> {
    return this.sendOperationRequest(
      {
        criteria,
        options
      },
      getLoansByHealthOperationSpec,
      callback) as Promise<Models.GetLoansByHealthResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaidCommissionsResponse>
   */
  getPaidCommissions(loanId: string, options?: Models.OriginationReadApiGetPaidCommissionsOptionalParams): Promise<Models.GetPaidCommissionsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaidCommissions(loanId: string, callback: msRest.ServiceCallback<Models.PagedListPaidCommission>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaidCommissions(loanId: string, options: Models.OriginationReadApiGetPaidCommissionsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListPaidCommission>): void;
  getPaidCommissions(loanId: string, options?: Models.OriginationReadApiGetPaidCommissionsOptionalParams | msRest.ServiceCallback<Models.PagedListPaidCommission>, callback?: msRest.ServiceCallback<Models.PagedListPaidCommission>): Promise<Models.GetPaidCommissionsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaidCommissionsOperationSpec,
      callback) as Promise<Models.GetPaidCommissionsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DebtorSummaryResponse>
   */
  debtorSummary(loanId: string, options?: Models.OriginationReadApiDebtorSummaryOptionalParams): Promise<Models.DebtorSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  debtorSummary(loanId: string, callback: msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  debtorSummary(loanId: string, options: Models.OriginationReadApiDebtorSummaryOptionalParams, callback: msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>): void;
  debtorSummary(loanId: string, options?: Models.OriginationReadApiDebtorSummaryOptionalParams | msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>, callback?: msRest.ServiceCallback<Models.PagedListLoanDebtorProjection>): Promise<Models.DebtorSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      debtorSummaryOperationSpec,
      callback) as Promise<Models.DebtorSummaryResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.TransactionSummaryResponse>
   */
  transactionSummary(loanId: string, options?: Models.OriginationReadApiTransactionSummaryOptionalParams): Promise<Models.TransactionSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  transactionSummary(loanId: string, callback: msRest.ServiceCallback<Models.PagedListTransactionProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  transactionSummary(loanId: string, options: Models.OriginationReadApiTransactionSummaryOptionalParams, callback: msRest.ServiceCallback<Models.PagedListTransactionProjection>): void;
  transactionSummary(loanId: string, options?: Models.OriginationReadApiTransactionSummaryOptionalParams | msRest.ServiceCallback<Models.PagedListTransactionProjection>, callback?: msRest.ServiceCallback<Models.PagedListTransactionProjection>): Promise<Models.TransactionSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      transactionSummaryOperationSpec,
      callback) as Promise<Models.TransactionSummaryResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllTransactionsResponse>
   */
  getAllTransactions(options?: Models.OriginationReadApiGetAllTransactionsOptionalParams): Promise<Models.GetAllTransactionsResponse>;
  /**
   * @param callback The callback
   */
  getAllTransactions(callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllTransactions(options: Models.OriginationReadApiGetAllTransactionsOptionalParams, callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  getAllTransactions(options?: Models.OriginationReadApiGetAllTransactionsOptionalParams | msRest.ServiceCallback<Models.TransactionProjection[]>, callback?: msRest.ServiceCallback<Models.TransactionProjection[]>): Promise<Models.GetAllTransactionsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllTransactionsOperationSpec,
      callback) as Promise<Models.GetAllTransactionsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.TransactionSummaryOnIdsResponse>
   */
  transactionSummaryOnIds(loanId: string, options?: Models.OriginationReadApiTransactionSummaryOnIdsOptionalParams): Promise<Models.TransactionSummaryOnIdsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  transactionSummaryOnIds(loanId: string, callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  transactionSummaryOnIds(loanId: string, options: Models.OriginationReadApiTransactionSummaryOnIdsOptionalParams, callback: msRest.ServiceCallback<Models.TransactionProjection[]>): void;
  transactionSummaryOnIds(loanId: string, options?: Models.OriginationReadApiTransactionSummaryOnIdsOptionalParams | msRest.ServiceCallback<Models.TransactionProjection[]>, callback?: msRest.ServiceCallback<Models.TransactionProjection[]>): Promise<Models.TransactionSummaryOnIdsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      transactionSummaryOnIdsOperationSpec,
      callback) as Promise<Models.TransactionSummaryOnIdsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceSummaryResponse>
   */
  advanceSummary(loanId: string, options?: Models.OriginationReadApiAdvanceSummaryOptionalParams): Promise<Models.AdvanceSummaryResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  advanceSummary(loanId: string, callback: msRest.ServiceCallback<Models.PagedListAdvanceProjection>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceSummary(loanId: string, options: Models.OriginationReadApiAdvanceSummaryOptionalParams, callback: msRest.ServiceCallback<Models.PagedListAdvanceProjection>): void;
  advanceSummary(loanId: string, options?: Models.OriginationReadApiAdvanceSummaryOptionalParams | msRest.ServiceCallback<Models.PagedListAdvanceProjection>, callback?: msRest.ServiceCallback<Models.PagedListAdvanceProjection>): Promise<Models.AdvanceSummaryResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      advanceSummaryOperationSpec,
      callback) as Promise<Models.AdvanceSummaryResponse>;
  }

  /**
   * @param loanId
   * @param advanceId
   * @param [options] The optional parameters
   * @returns Promise<Models.AdvanceHistoryDetailsResponse>
   */
  advanceHistoryDetails(loanId: string, advanceId: string, options?: msRest.RequestOptionsBase): Promise<Models.AdvanceHistoryDetailsResponse>;
  /**
   * @param loanId
   * @param advanceId
   * @param callback The callback
   */
  advanceHistoryDetails(loanId: string, advanceId: string, callback: msRest.ServiceCallback<Models.AdvanceProjection>): void;
  /**
   * @param loanId
   * @param advanceId
   * @param options The optional parameters
   * @param callback The callback
   */
  advanceHistoryDetails(loanId: string, advanceId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.AdvanceProjection>): void;
  advanceHistoryDetails(loanId: string, advanceId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.AdvanceProjection>, callback?: msRest.ServiceCallback<Models.AdvanceProjection>): Promise<Models.AdvanceHistoryDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        advanceId,
        options
      },
      advanceHistoryDetailsOperationSpec,
      callback) as Promise<Models.AdvanceHistoryDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CashflowDetailsMethodResponse>
   */
  cashflowDetailsMethod(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.CashflowDetailsMethodResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  cashflowDetailsMethod(loanId: string, callback: msRest.ServiceCallback<Models.CashflowDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  cashflowDetailsMethod(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.CashflowDetails>): void;
  cashflowDetailsMethod(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.CashflowDetails>, callback?: msRest.ServiceCallback<Models.CashflowDetails>): Promise<Models.CashflowDetailsMethodResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      cashflowDetailsMethodOperationSpec,
      callback) as Promise<Models.CashflowDetailsMethodResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IncomeDetailsMethodResponse>
   */
  incomeDetailsMethod(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IncomeDetailsMethodResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  incomeDetailsMethod(loanId: string, callback: msRest.ServiceCallback<Models.IncomeDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  incomeDetailsMethod(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.IncomeDetails>): void;
  incomeDetailsMethod(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.IncomeDetails>, callback?: msRest.ServiceCallback<Models.IncomeDetails>): Promise<Models.IncomeDetailsMethodResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      incomeDetailsMethodOperationSpec,
      callback) as Promise<Models.IncomeDetailsMethodResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateFixedRepaymentResponse>
   */
  calculateFixedRepayment(loanId: string, options?: Models.OriginationReadApiCalculateFixedRepaymentOptionalParams): Promise<Models.CalculateFixedRepaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculateFixedRepayment(loanId: string, callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateFixedRepayment(loanId: string, options: Models.OriginationReadApiCalculateFixedRepaymentOptionalParams, callback: msRest.ServiceCallback<Models.FixedRepaymentCalculation>): void;
  calculateFixedRepayment(loanId: string, options?: Models.OriginationReadApiCalculateFixedRepaymentOptionalParams | msRest.ServiceCallback<Models.FixedRepaymentCalculation>, callback?: msRest.ServiceCallback<Models.FixedRepaymentCalculation>): Promise<Models.CalculateFixedRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculateFixedRepaymentOperationSpec,
      callback) as Promise<Models.CalculateFixedRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param amount
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateLineOfCreditSliderResponse>
   */
  calculateLineOfCreditSlider(loanId: string, amount: number, options?: msRest.RequestOptionsBase): Promise<Models.CalculateLineOfCreditSliderResponse>;
  /**
   * @param loanId
   * @param amount
   * @param callback The callback
   */
  calculateLineOfCreditSlider(loanId: string, amount: number, callback: msRest.ServiceCallback<{ [propertyName: string]: number }>): void;
  /**
   * @param loanId
   * @param amount
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateLineOfCreditSlider(loanId: string, amount: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<{ [propertyName: string]: number }>): void;
  calculateLineOfCreditSlider(loanId: string, amount: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: number }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: number }>): Promise<Models.CalculateLineOfCreditSliderResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        amount,
        options
      },
      calculateLineOfCreditSliderOperationSpec,
      callback) as Promise<Models.CalculateLineOfCreditSliderResponse>;
  }

  /**
   * @param loanId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateLineOfCreditRepaymentResponse>
   */
  calculateLineOfCreditRepayment(loanId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, options?: msRest.RequestOptionsBase): Promise<Models.CalculateLineOfCreditRepaymentResponse>;
  /**
   * @param loanId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param callback The callback
   */
  calculateLineOfCreditRepayment(loanId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>): void;
  /**
   * @param loanId
   * @param amount
   * @param percentageOfIncome
   * @param fixedRepaymentCalculation
   * @param term
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateLineOfCreditRepayment(loanId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>): void;
  calculateLineOfCreditRepayment(loanId: string, amount: number, percentageOfIncome: number, fixedRepaymentCalculation: boolean, term: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>, callback?: msRest.ServiceCallback<Models.LineOfCreditRepaymentCalculation>): Promise<Models.CalculateLineOfCreditRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        amount,
        percentageOfIncome,
        fixedRepaymentCalculation,
        term,
        options
      },
      calculateLineOfCreditRepaymentOperationSpec,
      callback) as Promise<Models.CalculateLineOfCreditRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculatePercentOfIncomeResponse>
   */
  calculatePercentOfIncome(loanId: string, options?: Models.OriginationReadApiCalculatePercentOfIncomeOptionalParams): Promise<Models.CalculatePercentOfIncomeResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculatePercentOfIncome(loanId: string, callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculatePercentOfIncome(loanId: string, options: Models.OriginationReadApiCalculatePercentOfIncomeOptionalParams, callback: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>): void;
  calculatePercentOfIncome(loanId: string, options?: Models.OriginationReadApiCalculatePercentOfIncomeOptionalParams | msRest.ServiceCallback<Models.PercentOfIncomeCalculation>, callback?: msRest.ServiceCallback<Models.PercentOfIncomeCalculation>): Promise<Models.CalculatePercentOfIncomeResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculatePercentOfIncomeOperationSpec,
      callback) as Promise<Models.CalculatePercentOfIncomeResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateInvoicePaymentResponse>
   */
  calculateInvoicePayment(loanId: string, options?: Models.OriginationReadApiCalculateInvoicePaymentOptionalParams): Promise<Models.CalculateInvoicePaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculateInvoicePayment(loanId: string, callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateInvoicePayment(loanId: string, options: Models.OriginationReadApiCalculateInvoicePaymentOptionalParams, callback: msRest.ServiceCallback<Models.InvoicePaymentCalculation>): void;
  calculateInvoicePayment(loanId: string, options?: Models.OriginationReadApiCalculateInvoicePaymentOptionalParams | msRest.ServiceCallback<Models.InvoicePaymentCalculation>, callback?: msRest.ServiceCallback<Models.InvoicePaymentCalculation>): Promise<Models.CalculateInvoicePaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculateInvoicePaymentOperationSpec,
      callback) as Promise<Models.CalculateInvoicePaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateEarlyRepaymentResponse>
   */
  calculateEarlyRepayment(loanId: string, options?: Models.OriginationReadApiCalculateEarlyRepaymentOptionalParams): Promise<Models.CalculateEarlyRepaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculateEarlyRepayment(loanId: string, callback: msRest.ServiceCallback<Models.EarlyRepaymentCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateEarlyRepayment(loanId: string, options: Models.OriginationReadApiCalculateEarlyRepaymentOptionalParams, callback: msRest.ServiceCallback<Models.EarlyRepaymentCalculation>): void;
  calculateEarlyRepayment(loanId: string, options?: Models.OriginationReadApiCalculateEarlyRepaymentOptionalParams | msRest.ServiceCallback<Models.EarlyRepaymentCalculation>, callback?: msRest.ServiceCallback<Models.EarlyRepaymentCalculation>): Promise<Models.CalculateEarlyRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculateEarlyRepaymentOperationSpec,
      callback) as Promise<Models.CalculateEarlyRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.CalculateSkipRepaymentResponse>
   */
  calculateSkipRepayment(loanId: string, options?: Models.OriginationReadApiCalculateSkipRepaymentOptionalParams): Promise<Models.CalculateSkipRepaymentResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  calculateSkipRepayment(loanId: string, callback: msRest.ServiceCallback<Models.SkipRepaymentCalculation>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  calculateSkipRepayment(loanId: string, options: Models.OriginationReadApiCalculateSkipRepaymentOptionalParams, callback: msRest.ServiceCallback<Models.SkipRepaymentCalculation>): void;
  calculateSkipRepayment(loanId: string, options?: Models.OriginationReadApiCalculateSkipRepaymentOptionalParams | msRest.ServiceCallback<Models.SkipRepaymentCalculation>, callback?: msRest.ServiceCallback<Models.SkipRepaymentCalculation>): Promise<Models.CalculateSkipRepaymentResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      calculateSkipRepaymentOperationSpec,
      callback) as Promise<Models.CalculateSkipRepaymentResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankDetailsResponse>
   */
  getBankDetails(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBankDetailsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getBankDetails(loanId: string, callback: msRest.ServiceCallback<Models.BankDetails>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankDetails(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.BankDetails>): void;
  getBankDetails(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BankDetails>, callback?: msRest.ServiceCallback<Models.BankDetails>): Promise<Models.GetBankDetailsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getBankDetailsOperationSpec,
      callback) as Promise<Models.GetBankDetailsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsBankSetupResponse>
   */
  isBankSetup(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsBankSetupResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isBankSetup(loanId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isBankSetup(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  isBankSetup(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>, callback?: msRest.ServiceCallback<Models.BoolWrapper>): Promise<Models.IsBankSetupResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isBankSetupOperationSpec,
      callback) as Promise<Models.IsBankSetupResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsChangeOfFacilityRequiredResponse>
   */
  isChangeOfFacilityRequired(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsChangeOfFacilityRequiredResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isChangeOfFacilityRequired(loanId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isChangeOfFacilityRequired(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  isChangeOfFacilityRequired(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>, callback?: msRest.ServiceCallback<Models.BoolWrapper>): Promise<Models.IsChangeOfFacilityRequiredResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isChangeOfFacilityRequiredOperationSpec,
      callback) as Promise<Models.IsChangeOfFacilityRequiredResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetNewFacilityLimitResponse>
   */
  getNewFacilityLimit(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetNewFacilityLimitResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getNewFacilityLimit(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getNewFacilityLimit(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<number>): void;
  getNewFacilityLimit(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<number>, callback?: msRest.ServiceCallback<number>): Promise<Models.GetNewFacilityLimitResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getNewFacilityLimitOperationSpec,
      callback) as Promise<Models.GetNewFacilityLimitResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetHighestFacilityLimitResponse>
   */
  getHighestFacilityLimit(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetHighestFacilityLimitResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getHighestFacilityLimit(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getHighestFacilityLimit(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<number>): void;
  getHighestFacilityLimit(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<number>, callback?: msRest.ServiceCallback<number>): Promise<Models.GetHighestFacilityLimitResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getHighestFacilityLimitOperationSpec,
      callback) as Promise<Models.GetHighestFacilityLimitResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetLoanApplicationResponse>
   */
  getLoanApplication(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetLoanApplicationResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getLoanApplication(loanId: string, callback: msRest.ServiceCallback<Models.Application>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getLoanApplication(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Application>): void;
  getLoanApplication(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Application>, callback?: msRest.ServiceCallback<Models.Application>): Promise<Models.GetLoanApplicationResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getLoanApplicationOperationSpec,
      callback) as Promise<Models.GetLoanApplicationResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsConnectedResponse>
   */
  isConnected(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsConnectedResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isConnected(loanId: string, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isConnected(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.BoolWrapper>): void;
  isConnected(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.BoolWrapper>, callback?: msRest.ServiceCallback<Models.BoolWrapper>): Promise<Models.IsConnectedResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isConnectedOperationSpec,
      callback) as Promise<Models.IsConnectedResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsAccountingSystemConnectedResponse>
   */
  isAccountingSystemConnected(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsAccountingSystemConnectedResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  isAccountingSystemConnected(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  isAccountingSystemConnected(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  isAccountingSystemConnected(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.IsAccountingSystemConnectedResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      isAccountingSystemConnectedOperationSpec,
      callback) as Promise<Models.IsAccountingSystemConnectedResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadTransactionFileResponse>
   */
  downloadTransactionFile(loanId: string, options?: Models.OriginationReadApiDownloadTransactionFileOptionalParams): Promise<Models.DownloadTransactionFileResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  downloadTransactionFile(loanId: string, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadTransactionFile(loanId: string, options: Models.OriginationReadApiDownloadTransactionFileOptionalParams, callback: msRest.ServiceCallback<Models.TransactionFile>): void;
  downloadTransactionFile(loanId: string, options?: Models.OriginationReadApiDownloadTransactionFileOptionalParams | msRest.ServiceCallback<Models.TransactionFile>, callback?: msRest.ServiceCallback<Models.TransactionFile>): Promise<Models.DownloadTransactionFileResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      downloadTransactionFileOperationSpec,
      callback) as Promise<Models.DownloadTransactionFileResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadTransactionStatementResponse>
   */
  downloadTransactionStatement(loanId: string, options?: Models.OriginationReadApiDownloadTransactionStatementOptionalParams): Promise<Models.DownloadTransactionStatementResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  downloadTransactionStatement(loanId: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadTransactionStatement(loanId: string, options: Models.OriginationReadApiDownloadTransactionStatementOptionalParams, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  downloadTransactionStatement(loanId: string, options?: Models.OriginationReadApiDownloadTransactionStatementOptionalParams | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.DownloadTransactionStatementResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      downloadTransactionStatementOperationSpec,
      callback) as Promise<Models.DownloadTransactionStatementResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.LookupDebtorsResponse>
   */
  lookupDebtors(loanId: string, options?: Models.OriginationReadApiLookupDebtorsOptionalParams): Promise<Models.LookupDebtorsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  lookupDebtors(loanId: string, callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  lookupDebtors(loanId: string, options: Models.OriginationReadApiLookupDebtorsOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  lookupDebtors(loanId: string, options?: Models.OriginationReadApiLookupDebtorsOptionalParams | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.LookupDebtorsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      lookupDebtorsOperationSpec,
      callback) as Promise<Models.LookupDebtorsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanCache(loanId: string, options?: Models.OriginationReadApiUpdateLoanCacheOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanCache(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanCache(loanId: string, options: Models.OriginationReadApiUpdateLoanCacheOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateLoanCache(loanId: string, options?: Models.OriginationReadApiUpdateLoanCacheOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanCacheOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanBalanceCache(loanId: string, options?: Models.OriginationReadApiUpdateLoanBalanceCacheOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanBalanceCache(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanBalanceCache(loanId: string, options: Models.OriginationReadApiUpdateLoanBalanceCacheOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateLoanBalanceCache(loanId: string, options?: Models.OriginationReadApiUpdateLoanBalanceCacheOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanBalanceCacheOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateLoanSettingCache(loanId: string, options?: Models.OriginationReadApiUpdateLoanSettingCacheOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  updateLoanSettingCache(loanId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateLoanSettingCache(loanId: string, options: Models.OriginationReadApiUpdateLoanSettingCacheOptionalParams, callback: msRest.ServiceCallback<void>): void;
  updateLoanSettingCache(loanId: string, options?: Models.OriginationReadApiUpdateLoanSettingCacheOptionalParams | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      updateLoanSettingCacheOperationSpec,
      callback);
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentQueries1Response>
   */
  getPaymentQueries1(loanId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPaymentQueries1Response>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentQueries1(loanId: string, callback: msRest.ServiceCallback<Models.PaymentQuery[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentQueries1(loanId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.PaymentQuery[]>): void;
  getPaymentQueries1(loanId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.PaymentQuery[]>, callback?: msRest.ServiceCallback<Models.PaymentQuery[]>): Promise<Models.GetPaymentQueries1Response> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentQueries1OperationSpec,
      callback) as Promise<Models.GetPaymentQueries1Response>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetReconciledPaymentsResponse>
   */
  getReconciledPayments(loanId: string, options?: Models.OriginationReadApiGetReconciledPaymentsOptionalParams): Promise<Models.GetReconciledPaymentsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getReconciledPayments(loanId: string, callback: msRest.ServiceCallback<Models.PagedListReconciledPayment>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getReconciledPayments(loanId: string, options: Models.OriginationReadApiGetReconciledPaymentsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListReconciledPayment>): void;
  getReconciledPayments(loanId: string, options?: Models.OriginationReadApiGetReconciledPaymentsOptionalParams | msRest.ServiceCallback<Models.PagedListReconciledPayment>, callback?: msRest.ServiceCallback<Models.PagedListReconciledPayment>): Promise<Models.GetReconciledPaymentsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getReconciledPaymentsOperationSpec,
      callback) as Promise<Models.GetReconciledPaymentsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPaymentReconciliationTransactionResponse>
   */
  getPaymentReconciliationTransaction(loanId: string, options?: Models.OriginationReadApiGetPaymentReconciliationTransactionOptionalParams): Promise<Models.GetPaymentReconciliationTransactionResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getPaymentReconciliationTransaction(loanId: string, callback: msRest.ServiceCallback<Models.PaymentAndTransactions[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPaymentReconciliationTransaction(loanId: string, options: Models.OriginationReadApiGetPaymentReconciliationTransactionOptionalParams, callback: msRest.ServiceCallback<Models.PaymentAndTransactions[]>): void;
  getPaymentReconciliationTransaction(loanId: string, options?: Models.OriginationReadApiGetPaymentReconciliationTransactionOptionalParams | msRest.ServiceCallback<Models.PaymentAndTransactions[]>, callback?: msRest.ServiceCallback<Models.PaymentAndTransactions[]>): Promise<Models.GetPaymentReconciliationTransactionResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getPaymentReconciliationTransactionOperationSpec,
      callback) as Promise<Models.GetPaymentReconciliationTransactionResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSyntheticTransactionsResponse>
   */
  getSyntheticTransactions(loanId: string, options?: Models.OriginationReadApiGetSyntheticTransactionsOptionalParams): Promise<Models.GetSyntheticTransactionsResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getSyntheticTransactions(loanId: string, callback: msRest.ServiceCallback<Models.SyntheticPayment[]>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSyntheticTransactions(loanId: string, options: Models.OriginationReadApiGetSyntheticTransactionsOptionalParams, callback: msRest.ServiceCallback<Models.SyntheticPayment[]>): void;
  getSyntheticTransactions(loanId: string, options?: Models.OriginationReadApiGetSyntheticTransactionsOptionalParams | msRest.ServiceCallback<Models.SyntheticPayment[]>, callback?: msRest.ServiceCallback<Models.SyntheticPayment[]>): Promise<Models.GetSyntheticTransactionsResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getSyntheticTransactionsOperationSpec,
      callback) as Promise<Models.GetSyntheticTransactionsResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSyntheticTransactionsTotalResponse>
   */
  getSyntheticTransactionsTotal(loanId: string, options?: Models.OriginationReadApiGetSyntheticTransactionsTotalOptionalParams): Promise<Models.GetSyntheticTransactionsTotalResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getSyntheticTransactionsTotal(loanId: string, callback: msRest.ServiceCallback<number>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSyntheticTransactionsTotal(loanId: string, options: Models.OriginationReadApiGetSyntheticTransactionsTotalOptionalParams, callback: msRest.ServiceCallback<number>): void;
  getSyntheticTransactionsTotal(loanId: string, options?: Models.OriginationReadApiGetSyntheticTransactionsTotalOptionalParams | msRest.ServiceCallback<number>, callback?: msRest.ServiceCallback<number>): Promise<Models.GetSyntheticTransactionsTotalResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getSyntheticTransactionsTotalOperationSpec,
      callback) as Promise<Models.GetSyntheticTransactionsTotalResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loadAllOurCustomers(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  loadAllOurCustomers(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  loadAllOurCustomers(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  loadAllOurCustomers(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      loadAllOurCustomersOperationSpec,
      callback);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankListResponse>
   */
  getBankList(options?: Models.OriginationReadApiGetBankListOptionalParams): Promise<Models.GetBankListResponse>;
  /**
   * @param callback The callback
   */
  getBankList(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankList(options: Models.OriginationReadApiGetBankListOptionalParams, callback: msRest.ServiceCallback<string[]>): void;
  getBankList(options?: Models.OriginationReadApiGetBankListOptionalParams | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.GetBankListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBankListOperationSpec,
      callback) as Promise<Models.GetBankListResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBankListDetailsResponse>
   */
  getBankListDetails(options?: Models.OriginationReadApiGetBankListDetailsOptionalParams): Promise<Models.GetBankListDetailsResponse>;
  /**
   * @param callback The callback
   */
  getBankListDetails(callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getBankListDetails(options: Models.OriginationReadApiGetBankListDetailsOptionalParams, callback: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>): void;
  getBankListDetails(options?: Models.OriginationReadApiGetBankListDetailsOptionalParams | msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>, callback?: msRest.ServiceCallback<Models.PlexeBankStatementsProxyModelsInstitution[]>): Promise<Models.GetBankListDetailsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getBankListDetailsOperationSpec,
      callback) as Promise<Models.GetBankListDetailsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportedAccountingSoftwareResponse>
   */
  getSupportedAccountingSoftware(options?: msRest.RequestOptionsBase): Promise<Models.GetSupportedAccountingSoftwareResponse>;
  /**
   * @param callback The callback
   */
  getSupportedAccountingSoftware(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportedAccountingSoftware(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getSupportedAccountingSoftware(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.GetSupportedAccountingSoftwareResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getSupportedAccountingSoftwareOperationSpec,
      callback) as Promise<Models.GetSupportedAccountingSoftwareResponse>;
  }

  /**
   * @param abn
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBusinessNameResponse>
   */
  getBusinessName(abn: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBusinessNameResponse>;
  /**
   * @param abn
   * @param callback The callback
   */
  getBusinessName(abn: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param abn
   * @param options The optional parameters
   * @param callback The callback
   */
  getBusinessName(abn: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  getBusinessName(abn: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetBusinessNameResponse> {
    return this.sendOperationRequest(
      {
        abn,
        options
      },
      getBusinessNameOperationSpec,
      callback) as Promise<Models.GetBusinessNameResponse>;
  }

  /**
   * @param zipCode
   * @param [options] The optional parameters
   * @returns Promise<Models.IsZipCodeValidResponse>
   */
  isZipCodeValid(zipCode: number, options?: msRest.RequestOptionsBase): Promise<Models.IsZipCodeValidResponse>;
  /**
   * @param zipCode
   * @param callback The callback
   */
  isZipCodeValid(zipCode: number, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param zipCode
   * @param options The optional parameters
   * @param callback The callback
   */
  isZipCodeValid(zipCode: number, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isZipCodeValid(zipCode: number, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsZipCodeValidResponse> {
    return this.sendOperationRequest(
      {
        zipCode,
        options
      },
      isZipCodeValidOperationSpec,
      callback) as Promise<Models.IsZipCodeValidResponse>;
  }

  /**
   * @param abn
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBusinessNameOnAcnResponse>
   */
  getBusinessNameOnAcn(abn: string, options?: Models.OriginationReadApiGetBusinessNameOnAcnOptionalParams): Promise<Models.GetBusinessNameOnAcnResponse>;
  /**
   * @param abn
   * @param callback The callback
   */
  getBusinessNameOnAcn(abn: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param abn
   * @param options The optional parameters
   * @param callback The callback
   */
  getBusinessNameOnAcn(abn: string, options: Models.OriginationReadApiGetBusinessNameOnAcnOptionalParams, callback: msRest.ServiceCallback<string>): void;
  getBusinessNameOnAcn(abn: string, options?: Models.OriginationReadApiGetBusinessNameOnAcnOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetBusinessNameOnAcnResponse> {
    return this.sendOperationRequest(
      {
        abn,
        options
      },
      getBusinessNameOnAcnOperationSpec,
      callback) as Promise<Models.GetBusinessNameOnAcnResponse>;
  }

  /**
   * @param abn
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBusinessNameDetailsResponse>
   */
  getBusinessNameDetails(abn: string, options?: msRest.RequestOptionsBase): Promise<Models.GetBusinessNameDetailsResponse>;
  /**
   * @param abn
   * @param callback The callback
   */
  getBusinessNameDetails(abn: string, callback: msRest.ServiceCallback<Models.OrganisationDetails>): void;
  /**
   * @param abn
   * @param options The optional parameters
   * @param callback The callback
   */
  getBusinessNameDetails(abn: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.OrganisationDetails>): void;
  getBusinessNameDetails(abn: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.OrganisationDetails>, callback?: msRest.ServiceCallback<Models.OrganisationDetails>): Promise<Models.GetBusinessNameDetailsResponse> {
    return this.sendOperationRequest(
      {
        abn,
        options
      },
      getBusinessNameDetailsOperationSpec,
      callback) as Promise<Models.GetBusinessNameDetailsResponse>;
  }

  /**
   * @param abn
   * @param [options] The optional parameters
   * @returns Promise<Models.GetBusinessNameDetailsOnAcnResponse>
   */
  getBusinessNameDetailsOnAcn(abn: string, options?: Models.OriginationReadApiGetBusinessNameDetailsOnAcnOptionalParams): Promise<Models.GetBusinessNameDetailsOnAcnResponse>;
  /**
   * @param abn
   * @param callback The callback
   */
  getBusinessNameDetailsOnAcn(abn: string, callback: msRest.ServiceCallback<Models.OrganisationDetails>): void;
  /**
   * @param abn
   * @param options The optional parameters
   * @param callback The callback
   */
  getBusinessNameDetailsOnAcn(abn: string, options: Models.OriginationReadApiGetBusinessNameDetailsOnAcnOptionalParams, callback: msRest.ServiceCallback<Models.OrganisationDetails>): void;
  getBusinessNameDetailsOnAcn(abn: string, options?: Models.OriginationReadApiGetBusinessNameDetailsOnAcnOptionalParams | msRest.ServiceCallback<Models.OrganisationDetails>, callback?: msRest.ServiceCallback<Models.OrganisationDetails>): Promise<Models.GetBusinessNameDetailsOnAcnResponse> {
    return this.sendOperationRequest(
      {
        abn,
        options
      },
      getBusinessNameDetailsOnAcnOperationSpec,
      callback) as Promise<Models.GetBusinessNameDetailsOnAcnResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDetailsResponse>
   */
  getDetails(options?: Models.OriginationReadApiGetDetailsOptionalParams): Promise<Models.GetDetailsResponse>;
  /**
   * @param callback The callback
   */
  getDetails(callback: msRest.ServiceCallback<Models.LookupResult>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDetails(options: Models.OriginationReadApiGetDetailsOptionalParams, callback: msRest.ServiceCallback<Models.LookupResult>): void;
  getDetails(options?: Models.OriginationReadApiGetDetailsOptionalParams | msRest.ServiceCallback<Models.LookupResult>, callback?: msRest.ServiceCallback<Models.LookupResult>): Promise<Models.GetDetailsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDetailsOperationSpec,
      callback) as Promise<Models.GetDetailsResponse>;
  }

  /**
   * @param accountingPackage
   * @param organisationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAccountingAuthorizeUrlResponse>
   */
  getAccountingAuthorizeUrl(accountingPackage: string, organisationId: string, options?: Models.OriginationReadApiGetAccountingAuthorizeUrlOptionalParams): Promise<Models.GetAccountingAuthorizeUrlResponse>;
  /**
   * @param accountingPackage
   * @param organisationId
   * @param callback The callback
   */
  getAccountingAuthorizeUrl(accountingPackage: string, organisationId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param accountingPackage
   * @param organisationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getAccountingAuthorizeUrl(accountingPackage: string, organisationId: string, options: Models.OriginationReadApiGetAccountingAuthorizeUrlOptionalParams, callback: msRest.ServiceCallback<string>): void;
  getAccountingAuthorizeUrl(accountingPackage: string, organisationId: string, options?: Models.OriginationReadApiGetAccountingAuthorizeUrlOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetAccountingAuthorizeUrlResponse> {
    return this.sendOperationRequest(
      {
        accountingPackage,
        organisationId,
        options
      },
      getAccountingAuthorizeUrlOperationSpec,
      callback) as Promise<Models.GetAccountingAuthorizeUrlResponse>;
  }

  /**
   * @param loanId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetReconnectAccountingAuthorizeUrlResponse>
   */
  getReconnectAccountingAuthorizeUrl(loanId: string, options?: Models.OriginationReadApiGetReconnectAccountingAuthorizeUrlOptionalParams): Promise<Models.GetReconnectAccountingAuthorizeUrlResponse>;
  /**
   * @param loanId
   * @param callback The callback
   */
  getReconnectAccountingAuthorizeUrl(loanId: string, callback: msRest.ServiceCallback<string>): void;
  /**
   * @param loanId
   * @param options The optional parameters
   * @param callback The callback
   */
  getReconnectAccountingAuthorizeUrl(loanId: string, options: Models.OriginationReadApiGetReconnectAccountingAuthorizeUrlOptionalParams, callback: msRest.ServiceCallback<string>): void;
  getReconnectAccountingAuthorizeUrl(loanId: string, options?: Models.OriginationReadApiGetReconnectAccountingAuthorizeUrlOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetReconnectAccountingAuthorizeUrlResponse> {
    return this.sendOperationRequest(
      {
        loanId,
        options
      },
      getReconnectAccountingAuthorizeUrlOperationSpec,
      callback) as Promise<Models.GetReconnectAccountingAuthorizeUrlResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  loadPartnerCache(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  loadPartnerCache(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  loadPartnerCache(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  loadPartnerCache(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>, callback?: msRest.ServiceCallback<void>): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      loadPartnerCacheOperationSpec,
      callback);
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerResponse>
   */
  getPartner(partnerId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPartnerResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartner(partnerId: string, callback: msRest.ServiceCallback<Models.Partner>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartner(partnerId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Partner>): void;
  getPartner(partnerId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Partner>, callback?: msRest.ServiceCallback<Models.Partner>): Promise<Models.GetPartnerResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerOperationSpec,
      callback) as Promise<Models.GetPartnerResponse>;
  }

  /**
   * @param partnerId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerSentLinkRequestResponse>
   */
  getPartnerSentLinkRequest(partnerId: string, options?: Models.OriginationReadApiGetPartnerSentLinkRequestOptionalParams): Promise<Models.GetPartnerSentLinkRequestResponse>;
  /**
   * @param partnerId
   * @param callback The callback
   */
  getPartnerSentLinkRequest(partnerId: string, callback: msRest.ServiceCallback<Models.PartnerSendLinkRequest>): void;
  /**
   * @param partnerId
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerSentLinkRequest(partnerId: string, options: Models.OriginationReadApiGetPartnerSentLinkRequestOptionalParams, callback: msRest.ServiceCallback<Models.PartnerSendLinkRequest>): void;
  getPartnerSentLinkRequest(partnerId: string, options?: Models.OriginationReadApiGetPartnerSentLinkRequestOptionalParams | msRest.ServiceCallback<Models.PartnerSendLinkRequest>, callback?: msRest.ServiceCallback<Models.PartnerSendLinkRequest>): Promise<Models.GetPartnerSentLinkRequestResponse> {
    return this.sendOperationRequest(
      {
        partnerId,
        options
      },
      getPartnerSentLinkRequestOperationSpec,
      callback) as Promise<Models.GetPartnerSentLinkRequestResponse>;
  }

  /**
   * @param email
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerOnEmailResponse>
   */
  getPartnerOnEmail(email: string, options?: msRest.RequestOptionsBase): Promise<Models.GetPartnerOnEmailResponse>;
  /**
   * @param email
   * @param callback The callback
   */
  getPartnerOnEmail(email: string, callback: msRest.ServiceCallback<Models.Partner>): void;
  /**
   * @param email
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerOnEmail(email: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.Partner>): void;
  getPartnerOnEmail(email: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.Partner>, callback?: msRest.ServiceCallback<Models.Partner>): Promise<Models.GetPartnerOnEmailResponse> {
    return this.sendOperationRequest(
      {
        email,
        options
      },
      getPartnerOnEmailOperationSpec,
      callback) as Promise<Models.GetPartnerOnEmailResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnersResponse>
   */
  getPartners(options?: Models.OriginationReadApiGetPartnersOptionalParams): Promise<Models.GetPartnersResponse>;
  /**
   * @param callback The callback
   */
  getPartners(callback: msRest.ServiceCallback<Models.PagedListPartnerProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartners(options: Models.OriginationReadApiGetPartnersOptionalParams, callback: msRest.ServiceCallback<Models.PagedListPartnerProjection>): void;
  getPartners(options?: Models.OriginationReadApiGetPartnersOptionalParams | msRest.ServiceCallback<Models.PagedListPartnerProjection>, callback?: msRest.ServiceCallback<Models.PagedListPartnerProjection>): Promise<Models.GetPartnersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnersOperationSpec,
      callback) as Promise<Models.GetPartnersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.SearchPartnersResponse>
   */
  searchPartners(options?: Models.OriginationReadApiSearchPartnersOptionalParams): Promise<Models.SearchPartnersResponse>;
  /**
   * @param callback The callback
   */
  searchPartners(callback: msRest.ServiceCallback<Models.PartnerProjection[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  searchPartners(options: Models.OriginationReadApiSearchPartnersOptionalParams, callback: msRest.ServiceCallback<Models.PartnerProjection[]>): void;
  searchPartners(options?: Models.OriginationReadApiSearchPartnersOptionalParams | msRest.ServiceCallback<Models.PartnerProjection[]>, callback?: msRest.ServiceCallback<Models.PartnerProjection[]>): Promise<Models.SearchPartnersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      searchPartnersOperationSpec,
      callback) as Promise<Models.SearchPartnersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAuthorizationToken1Response>
   */
  getAuthorizationToken1(options?: Models.OriginationReadApiGetAuthorizationToken1OptionalParams): Promise<Models.GetAuthorizationToken1Response>;
  /**
   * @param callback The callback
   */
  getAuthorizationToken1(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAuthorizationToken1(options: Models.OriginationReadApiGetAuthorizationToken1OptionalParams, callback: msRest.ServiceCallback<string>): void;
  getAuthorizationToken1(options?: Models.OriginationReadApiGetAuthorizationToken1OptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetAuthorizationToken1Response> {
    return this.sendOperationRequest(
      {
        options
      },
      getAuthorizationToken1OperationSpec,
      callback) as Promise<Models.GetAuthorizationToken1Response>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAccreditationResponse>
   */
  getAccreditation(options?: Models.OriginationReadApiGetAccreditationOptionalParams): Promise<Models.GetAccreditationResponse>;
  /**
   * @param callback The callback
   */
  getAccreditation(callback: msRest.ServiceCallback<Models.PartnerAccreditationRequest>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAccreditation(options: Models.OriginationReadApiGetAccreditationOptionalParams, callback: msRest.ServiceCallback<Models.PartnerAccreditationRequest>): void;
  getAccreditation(options?: Models.OriginationReadApiGetAccreditationOptionalParams | msRest.ServiceCallback<Models.PartnerAccreditationRequest>, callback?: msRest.ServiceCallback<Models.PartnerAccreditationRequest>): Promise<Models.GetAccreditationResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAccreditationOperationSpec,
      callback) as Promise<Models.GetAccreditationResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadBankStatementsResponse>
   */
  downloadBankStatements(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadBankStatementsResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  downloadBankStatements(id: string, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadBankStatements(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  downloadBankStatements(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile[]>, callback?: msRest.ServiceCallback<Models.TransactionDataFile[]>): Promise<Models.DownloadBankStatementsResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      downloadBankStatementsOperationSpec,
      callback) as Promise<Models.DownloadBankStatementsResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadBlankInvoicesResponse>
   */
  downloadBlankInvoices(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadBlankInvoicesResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  downloadBlankInvoices(id: string, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadBlankInvoices(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TransactionDataFile[]>): void;
  downloadBlankInvoices(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile[]>, callback?: msRest.ServiceCallback<Models.TransactionDataFile[]>): Promise<Models.DownloadBlankInvoicesResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      downloadBlankInvoicesOperationSpec,
      callback) as Promise<Models.DownloadBlankInvoicesResponse>;
  }

  /**
   * @param id
   * @param [options] The optional parameters
   * @returns Promise<Models.DownloadW9FormResponse>
   */
  downloadW9Form(id: string, options?: msRest.RequestOptionsBase): Promise<Models.DownloadW9FormResponse>;
  /**
   * @param id
   * @param callback The callback
   */
  downloadW9Form(id: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param id
   * @param options The optional parameters
   * @param callback The callback
   */
  downloadW9Form(id: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  downloadW9Form(id: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.DownloadW9FormResponse> {
    return this.sendOperationRequest(
      {
        id,
        options
      },
      downloadW9FormOperationSpec,
      callback) as Promise<Models.DownloadW9FormResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerDailyReportsResponse>
   */
  getPartnerDailyReports(options?: Models.OriginationReadApiGetPartnerDailyReportsOptionalParams): Promise<Models.GetPartnerDailyReportsResponse>;
  /**
   * @param callback The callback
   */
  getPartnerDailyReports(callback: msRest.ServiceCallback<Models.PagedListPartnerProjection>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerDailyReports(options: Models.OriginationReadApiGetPartnerDailyReportsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListPartnerProjection>): void;
  getPartnerDailyReports(options?: Models.OriginationReadApiGetPartnerDailyReportsOptionalParams | msRest.ServiceCallback<Models.PagedListPartnerProjection>, callback?: msRest.ServiceCallback<Models.PagedListPartnerProjection>): Promise<Models.GetPartnerDailyReportsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerDailyReportsOperationSpec,
      callback) as Promise<Models.GetPartnerDailyReportsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetPartnerIdByUrlReferralResponse>
   */
  getPartnerIdByUrlReferral(options?: Models.OriginationReadApiGetPartnerIdByUrlReferralOptionalParams): Promise<Models.GetPartnerIdByUrlReferralResponse>;
  /**
   * @param callback The callback
   */
  getPartnerIdByUrlReferral(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getPartnerIdByUrlReferral(options: Models.OriginationReadApiGetPartnerIdByUrlReferralOptionalParams, callback: msRest.ServiceCallback<string>): void;
  getPartnerIdByUrlReferral(options?: Models.OriginationReadApiGetPartnerIdByUrlReferralOptionalParams | msRest.ServiceCallback<string>, callback?: msRest.ServiceCallback<string>): Promise<Models.GetPartnerIdByUrlReferralResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getPartnerIdByUrlReferralOperationSpec,
      callback) as Promise<Models.GetPartnerIdByUrlReferralResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportingDocumentRequestResponse>
   */
  getSupportingDocumentRequest(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.GetSupportingDocumentRequestResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSupportingDocumentRequest(applicationId: string, callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportingDocumentRequest(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SupportDocumentDetails[]>): void;
  getSupportingDocumentRequest(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SupportDocumentDetails[]>, callback?: msRest.ServiceCallback<Models.SupportDocumentDetails[]>): Promise<Models.GetSupportingDocumentRequestResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSupportingDocumentRequestOperationSpec,
      callback) as Promise<Models.GetSupportingDocumentRequestResponse>;
  }

  /**
   * @param applicationId
   * @param location
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportingDocumentResponse>
   */
  getSupportingDocument(applicationId: string, location: string, options?: msRest.RequestOptionsBase): Promise<Models.GetSupportingDocumentResponse>;
  /**
   * @param applicationId
   * @param location
   * @param callback The callback
   */
  getSupportingDocument(applicationId: string, location: string, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  /**
   * @param applicationId
   * @param location
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportingDocument(applicationId: string, location: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.TransactionDataFile>): void;
  getSupportingDocument(applicationId: string, location: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.TransactionDataFile>, callback?: msRest.ServiceCallback<Models.TransactionDataFile>): Promise<Models.GetSupportingDocumentResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        location,
        options
      },
      getSupportingDocumentOperationSpec,
      callback) as Promise<Models.GetSupportingDocumentResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.GetSupportingDocumentsResponse>
   */
  getSupportingDocuments(applicationId: string, options?: Models.OriginationReadApiGetSupportingDocumentsOptionalParams): Promise<Models.GetSupportingDocumentsResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  getSupportingDocuments(applicationId: string, callback: msRest.ServiceCallback<Models.PagedListSupportDocumentDetails>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  getSupportingDocuments(applicationId: string, options: Models.OriginationReadApiGetSupportingDocumentsOptionalParams, callback: msRest.ServiceCallback<Models.PagedListSupportDocumentDetails>): void;
  getSupportingDocuments(applicationId: string, options?: Models.OriginationReadApiGetSupportingDocumentsOptionalParams | msRest.ServiceCallback<Models.PagedListSupportDocumentDetails>, callback?: msRest.ServiceCallback<Models.PagedListSupportDocumentDetails>): Promise<Models.GetSupportingDocumentsResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      getSupportingDocumentsOperationSpec,
      callback) as Promise<Models.GetSupportingDocumentsResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsSupportedDocumentCompletedResponse>
   */
  isSupportedDocumentCompleted(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsSupportedDocumentCompletedResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isSupportedDocumentCompleted(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isSupportedDocumentCompleted(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isSupportedDocumentCompleted(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsSupportedDocumentCompletedResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isSupportedDocumentCompletedOperationSpec,
      callback) as Promise<Models.IsSupportedDocumentCompletedResponse>;
  }

  /**
   * @param applicationId
   * @param [options] The optional parameters
   * @returns Promise<Models.IsSupportedEnabledResponse>
   */
  isSupportedEnabled(applicationId: string, options?: msRest.RequestOptionsBase): Promise<Models.IsSupportedEnabledResponse>;
  /**
   * @param applicationId
   * @param callback The callback
   */
  isSupportedEnabled(applicationId: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param applicationId
   * @param options The optional parameters
   * @param callback The callback
   */
  isSupportedEnabled(applicationId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isSupportedEnabled(applicationId: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>, callback?: msRest.ServiceCallback<boolean>): Promise<Models.IsSupportedEnabledResponse> {
    return this.sendOperationRequest(
      {
        applicationId,
        options
      },
      isSupportedEnabledOperationSpec,
      callback) as Promise<Models.IsSupportedEnabledResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRequestSupportingDocumentsResponse>
   */
  getRequestSupportingDocuments(options?: msRest.RequestOptionsBase): Promise<Models.GetRequestSupportingDocumentsResponse>;
  /**
   * @param callback The callback
   */
  getRequestSupportingDocuments(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRequestSupportingDocuments(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getRequestSupportingDocuments(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.GetRequestSupportingDocumentsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRequestSupportingDocumentsOperationSpec,
      callback) as Promise<Models.GetRequestSupportingDocumentsResponse>;
  }

  /**
   * @param url
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDesignElementsOnSiteResponse>
   */
  getDesignElementsOnSite(url: string, options?: msRest.RequestOptionsBase): Promise<Models.GetDesignElementsOnSiteResponse>;
  /**
   * @param url
   * @param callback The callback
   */
  getDesignElementsOnSite(url: string, callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  /**
   * @param url
   * @param options The optional parameters
   * @param callback The callback
   */
  getDesignElementsOnSite(url: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<{ [propertyName: string]: string }>): void;
  getDesignElementsOnSite(url: string, options?: msRest.RequestOptionsBase | msRest.ServiceCallback<{ [propertyName: string]: string }>, callback?: msRest.ServiceCallback<{ [propertyName: string]: string }>): Promise<Models.GetDesignElementsOnSiteResponse> {
    return this.sendOperationRequest(
      {
        url,
        options
      },
      getDesignElementsOnSiteOperationSpec,
      callback) as Promise<Models.GetDesignElementsOnSiteResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllSitesResponse>
   */
  getAllSites(options?: msRest.RequestOptionsBase): Promise<Models.GetAllSitesResponse>;
  /**
   * @param callback The callback
   */
  getAllSites(callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllSites(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<Models.SiteDetails[]>): void;
  getAllSites(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<Models.SiteDetails[]>, callback?: msRest.ServiceCallback<Models.SiteDetails[]>): Promise<Models.GetAllSitesResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllSitesOperationSpec,
      callback) as Promise<Models.GetAllSitesResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetDesignElementListResponse>
   */
  getDesignElementList(options?: msRest.RequestOptionsBase): Promise<Models.GetDesignElementListResponse>;
  /**
   * @param callback The callback
   */
  getDesignElementList(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getDesignElementList(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getDesignElementList(options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>, callback?: msRest.ServiceCallback<string[]>): Promise<Models.GetDesignElementListResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getDesignElementListOperationSpec,
      callback) as Promise<Models.GetDesignElementListResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const loadApplicantCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/load-applicant-cache",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getApplicantNamesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/{organisationId}/applicant-names",
  urlParameters: [
    Parameters.organisationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Name"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/{applicationId}/applicants",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Applicant"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPrimaryApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/{applicationId}/primary-applicants",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PrimaryApplicant
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantDriverLicenceUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/{applicantId}/get-driver-licence-url",
  urlParameters: [
    Parameters.applicantId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/{applicantId}/get-applicant",
  urlParameters: [
    Parameters.applicantId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Applicant
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicantDriverLicenceImageOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/{applicantId}/get-driver-licence-image",
  urlParameters: [
    Parameters.applicantId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "ByteArray"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSecondaryApplicantOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/{applicationId}/secondary-applicants",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SecondaryApplicant
    },
    500: {},
    default: {}
  },
  serializer
};

const getOrganisationExtraDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Applicant/get-organisation-extra-details",
  queryParameters: [
    Parameters.organisationId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const loadApplicationCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/load-application-cache",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const overviewOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/summary",
  queryParameters: [
    Parameters.partnerId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationsOverview
    },
    500: {},
    default: {}
  },
  serializer
};

const validateOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/valid-otp",
  queryParameters: [
    Parameters.otp
  ],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const applicationStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/get-status",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Boolean"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const applicationConnectionStatusOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/get-connection-status",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Boolean"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const hasOrganisationNameBeenFinalisedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/has-organisation-name-been-finalised",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isOfferReadyOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/is-offer-ready",
  queryParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const hasLoggedInOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/has-logged-in",
  queryParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadContractFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/download-contract",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getDataOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/get-data",
  queryParameters: [
    Parameters.objectType,
    Parameters.id0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Summary
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommissionDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/get-commission-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.secured,
    Parameters.establishmentFeeRate,
    Parameters.commissionRate
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CommissionDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationIdOnShortCodeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{shortCode}/short-code-on-application-id",
  urlParameters: [
    Parameters.shortCode0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{shortCode}/get-token",
  urlParameters: [
    Parameters.shortCode0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Uuid"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnShortCodeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{shortCode}/application",
  urlParameters: [
    Parameters.shortCode0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Application
    },
    500: {},
    default: {}
  },
  serializer
};

const getShortCodeOnApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/short-code",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getQuickUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/quick-url",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSecondApplicantUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/second-applicant-url",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBaseUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/base-url",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const applicationListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/list",
  queryParameters: [
    Parameters.partnerId0,
    Parameters.orderBy0,
    Parameters.skip0,
    Parameters.take0,
    Parameters.status0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getBusinessNameFromApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/business-name",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/bank-accounts",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeOrganisationServiceModelsBankAccount"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedAccountsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/bankfeed-accounts",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.excludeCreditCards
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeOrganisationServiceModelsBankAccount"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedMFADetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/bankfeed-mfa",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.yodlee
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PlexeBankStatementsProxyModelsMFADetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedFaskLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/bankfeed-fastlink",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PlexeBankStatementsProxyModelsFastLinkToken
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankfeedPlaidLinkOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/bankfeed-plaidlink",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PlexeBankStatementsProxyModelsFastLinkToken
    },
    500: {},
    default: {}
  },
  serializer
};

const isProvisoApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/is-proviso-application",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const offerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/offer/{offerId}",
  urlParameters: [
    Parameters.applicationId0,
    Parameters.offerId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OfferAmount
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/get-application-settings",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationSettings
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommunicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/get-communication",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CommunicationProjection"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const commissionMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/commission",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.loanType
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Commission
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Application
    },
    500: {},
    default: {}
  },
  serializer
};

const debtorSummaryOnApplicationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/debtor-summary-on-application-id",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListLoanDebtorProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeFixedRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/calculate-indicative-fixed-repayment",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.amount0,
    Parameters.terms
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FixedRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeLineOfCreditSliderOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/calculate-indicative-line-of-credit-slider",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.amount1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Number"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeLineOfCreditRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/calculate-indicative-line-of-credit-repayment",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.amount1,
    Parameters.percentageOfIncome,
    Parameters.fixedRepaymentCalculation,
    Parameters.term
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LineOfCreditRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativePercentOfIncomeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/calculate-indicative-percent-of-income",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.amount0,
    Parameters.percent
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PercentOfIncomeCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateIndicativeInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/calculate-indicative-invoice-payment",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.amount0,
    Parameters.overrideCalculation
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InvoicePaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const incomeDetailsForApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/income-details",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.IncomeDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const isApplicationCompleteOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/is-application-complete",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getOfferDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/offerdetails",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.force
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OfferDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/organisation-id",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getOfferIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/offer-id",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{customerId}/get-application-id",
  urlParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationDetailsOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{customerId}/get-application-on-customer",
  urlParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Application
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{organisationId}/get-application-id-organisation",
  urlParameters: [
    Parameters.organisationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationDetailOnOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{organisationId}/get-application-on-organisation",
  urlParameters: [
    Parameters.organisationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Application
    },
    500: {},
    default: {}
  },
  serializer
};

const getApplicationOnOrganisationNameOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{organisation}/get-application-id-organisation-name",
  urlParameters: [
    Parameters.organisation
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateApplicationCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/update-application-cache",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.shortCode1,
    Parameters.customerId1,
    Parameters.organisationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateCommunicationCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{applicationId}/update-communication-cache",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const isApprovedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/application-is-approved",
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isApplicantApprovedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/applicant-is-approved",
  queryParameters: [
    Parameters.applicantId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isApplicantEvaluatedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/applicant-is-evaluated",
  queryParameters: [
    Parameters.applicantId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSingleApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/{customerId}/get-single-application",
  urlParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ApplicationListProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getByPartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Application/get-by-partner",
  queryParameters: [
    Parameters.partnerId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ApplicationListProjection"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isAuditRequiredOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Audit/{loanId}/is-audit-required",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAuditDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Audit/{loanId}/get-all-audit-details",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AuditDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getCurrentAuditReportOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Audit/{loanId}/get-current-audit-report",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.status1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AuditInvoiceDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getRecentAuditReportsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Audit/{loanId}/get-recent-audit-reports",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuditInvoiceDetails"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCurrentAuditDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Audit/{loanId}/get-current-audit-documents",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListAuditDocument
    },
    500: {},
    default: {}
  },
  serializer
};

const getRecentAuditDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Audit/{loanId}/get-recent-audit-documents",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.invoiceId,
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListAuditDocument
    },
    500: {},
    default: {}
  },
  serializer
};

const getAuditSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Audit/get-audit-settings",
  responses: {
    200: {
      bodyMapper: Mappers.AuditSettings
    },
    500: {},
    default: {}
  },
  serializer
};

const isCorprateOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/is-corprate",
  queryParameters: [
    Parameters.username
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const hasCorprateLoggedInOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/has-corprate-logged-in",
  queryParameters: [
    Parameters.username
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isCardServiceEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/is-card-service-enabled",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getCardConfigurationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/get-card-configuration",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CardConfiguration
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllCardHolderCardsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/get-all-card-holder-cards",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCardHolderDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/get-card",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.providerType,
    Parameters.cardId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CardHolderDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getTransactionsPerCardOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/get-transactions-per-card",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.cardId,
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCardTransaction
    },
    500: {},
    default: {}
  },
  serializer
};

const getTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/get-transactions",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCardTransaction
    },
    500: {},
    default: {}
  },
  serializer
};

const getWebComponentTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/get-web-component-token",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.HydrogenAppTokenResponse
    },
    500: {},
    default: {}
  },
  serializer
};

const getCorpratesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Card/{loanId}/get-corprates",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.orderBy0,
    Parameters.skip1,
    Parameters.take1,
    Parameters.includeDisabled
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const loadCustomerCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Customer/load-customer-cache",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getCustomerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Customer/{customerId}",
  urlParameters: [
    Parameters.customerId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Customer
    },
    500: {},
    default: {}
  },
  serializer
};

const getCustomerOnEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Customer/{email}/email",
  urlParameters: [
    Parameters.email
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Customer
    },
    500: {},
    default: {}
  },
  serializer
};

const getCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Customer/get-customers",
  queryParameters: [
    Parameters.searchText,
    Parameters.skip1,
    Parameters.take1,
    Parameters.includeDisabled
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const searchCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Customer/search-customers",
  queryParameters: [
    Parameters.charList
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomerProjection"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAuthorizationTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Customer/get-authorization-token",
  queryParameters: [
    Parameters.customerId1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDocumentsPagedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/DocumentSupport/{applicationId}/get-documents-paged",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListTransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/DocumentSupport/{applicationId}/get-document",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.fileName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const isEmployeeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/is-employee",
  queryParameters: [
    Parameters.username
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const hasLoggedIn1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/has-logged-in",
  queryParameters: [
    Parameters.username
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isPayrollSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/is-payroll-connected",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.providerType
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getPayrollDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/get-payroll-details",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.ProviderDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getEmployeePageDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/get-employee-page-details",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListEmployeeDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getEmployeeDetailOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/get-employee-detail",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.employeeId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.EmployeeDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getEmployeeWithdrawDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/get-employee-withdraw-details",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.employeeId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateWithdrawRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/calculate-withdraw-request",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.employeeId,
    Parameters.amount0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.WithdrawRequest
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllWithdrawRequestsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/get-all-withdraw-requests",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.employeeId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "WithdrawRequest"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getWithdrawRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/{loanId}/get-withdraw-requests",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.employeeId,
    Parameters.requestId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.WithdrawRequest
    },
    500: {},
    default: {}
  },
  serializer
};

const getFinchRedirectUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/get-finch-redirect-url",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getEmployeesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/EarlyAccess/get-employees",
  queryParameters: [
    Parameters.orderBy0,
    Parameters.skip1,
    Parameters.take1,
    Parameters.includeDisabled
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getFilterEmployeesOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/EarlyAccess/get-filter-employees",
  queryParameters: [
    Parameters.search,
    Parameters.skip1,
    Parameters.take1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllContractDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Files/{applicationId}/get-all-contract-documents",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContractDocument"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllContractDocumentsPagedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Files/{applicationId}/get-all-contract-documents-paged",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListContractDocument
    },
    500: {},
    default: {}
  },
  serializer
};

const deletePastContractOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Files/{applicationId}/delete-past-contract",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.documentId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListContractDocument
    },
    500: {},
    default: {}
  },
  serializer
};

const getBaseSupportDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Files/get-base-support-documents",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContractDocument"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadContractFileOnDocumentIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Files/{documentId}/get-contract-document-on-documentid",
  urlParameters: [
    Parameters.documentId1
  ],
  queryParameters: [
    Parameters.applicationId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getW9FormSampleDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Files/get-w9form-sample-document",
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const loadLoanCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/load-loan-cache",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const customerLookupOfflineOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/customer-lookup-offline",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomerLookupData"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const customerLookupOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/customer-lookup",
  queryParameters: [
    Parameters.search,
    Parameters.includeCancelled
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomerLookupData"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{customerId}/get-loans-id",
  urlParameters: [
    Parameters.customerId0
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy2,
    Parameters.includedClosed
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentQueriesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-payment-queries",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PaymentQuery"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanReadyApplicationsOnCustomerIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{customerId}/get-loan-ready-applications",
  urlParameters: [
    Parameters.customerId0
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy2
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListLoanReadyApplication
    },
    500: {},
    default: {}
  },
  serializer
};

const getCommissionOnLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/commission",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.secured
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Commission
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentArrangementsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/payment-arrangements",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.advanceId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PaymentArrangement"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanNotificationsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-loan-notifications",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.onlyUnread,
    Parameters.skip1,
    Parameters.take1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListLoanNotificationProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanByOrganisationIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{organisationId}/get-loan-on-organisation-id",
  urlParameters: [
    Parameters.organisationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Loan
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-loan",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Loan
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanDataByOrganistionIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{organisationId}/get-loan-data-by-organisation",
  urlParameters: [
    Parameters.organisationId0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LoanData
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanDataByLoanIdOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-loan-data-by-loan",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LoanData
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-loan-balances",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.force
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LoanBalances
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllLoanBalancesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-all-loan-balances",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.force
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AllLoanBalances
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanSettingsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-loan-settings",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.force
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LoanSettings
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllLoanDataOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/get-loan-data",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LoanData"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/list",
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy2,
    Parameters.includedClosed
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getClosedLoansOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/closed",
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansByTypeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/by-type",
  queryParameters: [
    Parameters.typeName
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoansByHealthOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/by-criteria",
  queryParameters: [
    Parameters.criteria
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListCustomerLoanProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaidCommissionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/commission-summary",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListPaidCommission
    },
    500: {},
    default: {}
  },
  serializer
};

const debtorSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/debtor-summary",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListLoanDebtorProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const transactionSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/transaction-summary",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy0,
    Parameters.orderbyDescending,
    Parameters.filterColumn,
    Parameters.filterValue,
    Parameters.viewType
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListTransactionProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/get-all-transactions",
  queryParameters: [
    Parameters.startDate,
    Parameters.endDate
  ],
  requestBody: {
    parameterPath: [
      "options",
      "types"
    ],
    mapper: {
      serializedName: "types",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "String"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TransactionProjection"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const transactionSummaryOnIdsOperationSpec: msRest.OperationSpec = {
  httpMethod: "POST",
  path: "api/Loan/{loanId}/transaction-summary-on-ids",
  urlParameters: [
    Parameters.loanId
  ],
  requestBody: {
    parameterPath: [
      "options",
      "transactionids"
    ],
    mapper: {
      serializedName: "transactionids",
      type: {
        name: "Sequence",
        element: {
          type: {
            name: "Uuid"
          }
        }
      }
    }
  },
  contentType: "application/json-patch+json; charset=utf-8",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TransactionProjection"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceSummaryOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/advance-summary",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListAdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const advanceHistoryDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/advance-details/{advanceId}",
  urlParameters: [
    Parameters.loanId,
    Parameters.advanceId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.AdvanceProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const cashflowDetailsMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/cashflow-details",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.CashflowDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const incomeDetailsMethodOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/income-details",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.IncomeDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateFixedRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/calculate-fixed-repayment",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.amount0,
    Parameters.terms
  ],
  responses: {
    200: {
      bodyMapper: Mappers.FixedRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateLineOfCreditSliderOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/calculate-line-of-credit-slider",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.amount1
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Number"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateLineOfCreditRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/calculate-line-of-credit-repayment",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.amount1,
    Parameters.percentageOfIncome,
    Parameters.fixedRepaymentCalculation,
    Parameters.term
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LineOfCreditRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculatePercentOfIncomeOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/calculate-percent-of-income",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.amount0,
    Parameters.percent
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PercentOfIncomeCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateInvoicePaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/calculate-invoice-payment",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.amount0,
    Parameters.overrideCalculation
  ],
  responses: {
    200: {
      bodyMapper: Mappers.InvoicePaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateEarlyRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/calculate-early-repayment",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.advanceId2
  ],
  responses: {
    200: {
      bodyMapper: Mappers.EarlyRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const calculateSkipRepaymentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/calculate-skip-repayment",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.advanceId2
  ],
  responses: {
    200: {
      bodyMapper: Mappers.SkipRepaymentCalculation
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/bank-details",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.BankDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const isBankSetupOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/is-bank-setup",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const isChangeOfFacilityRequiredOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/is-change-facility-required",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const getNewFacilityLimitOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-new-facility-limit",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Number"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getHighestFacilityLimitOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/get-highest-facility-limit",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Number"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getLoanApplicationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/application",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Application
    },
    500: {},
    default: {}
  },
  serializer
};

const isConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/is-connected",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.BoolWrapper
    },
    500: {},
    default: {}
  },
  serializer
};

const isAccountingSystemConnectedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/is-accounting-system-connected",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadTransactionFileOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/download-transactions",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.startDate,
    Parameters.endDate,
    Parameters.fileType
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionFile
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadTransactionStatementOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/download-transactions-statement",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.startDate,
    Parameters.endDate
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const lookupDebtorsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/lookup-debtors",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.debtor
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const updateLoanCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/update-loan-cache",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.organisationId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanBalanceCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/update-loan-balance",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.offerId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const updateLoanSettingCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Loan/{loanId}/update-loan-setting",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.offerId1
  ],
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getPaymentQueries1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/LoanReconciliation/{loanId}/get-payment-queries",
  urlParameters: [
    Parameters.loanId
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PaymentQuery"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getReconciledPaymentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/LoanReconciliation/{loanId}/get-reconciled-payments",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0,
    Parameters.orderBy0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListReconciledPayment
    },
    500: {},
    default: {}
  },
  serializer
};

const getPaymentReconciliationTransactionOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/LoanReconciliation/{loanId}/get-payment-reconciliation-transactions",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.startDate,
    Parameters.endDate,
    Parameters.showAll,
    Parameters.onlyKnownDebtors
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PaymentAndTransactions"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSyntheticTransactionsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/LoanReconciliation/{loanId}/get-synthetic-transactions",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.debtor
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SyntheticPayment"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSyntheticTransactionsTotalOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/LoanReconciliation/{loanId}/get-synthetic-transactions-total",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.debtor
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Number"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const loadAllOurCustomersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/load-all-our-customers",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getBankListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/get-bank-list",
  queryParameters: [
    Parameters.reset
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBankListDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/get-bank-list-details",
  queryParameters: [
    Parameters.reset
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeBankStatementsProxyModelsInstitution"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportedAccountingSoftwareOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/get-supported-accounting-software",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBusinessNameOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/{abn}/business-name",
  urlParameters: [
    Parameters.abn
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isZipCodeValidOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/{zipCode}/is-zipcode-valid",
  urlParameters: [
    Parameters.zipCode
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBusinessNameOnAcnOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/{abn}/business-name-on-acn",
  urlParameters: [
    Parameters.abn
  ],
  queryParameters: [
    Parameters.acn
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getBusinessNameDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/{abn}/business-name-details",
  urlParameters: [
    Parameters.abn
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrganisationDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getBusinessNameDetailsOnAcnOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/{abn}/business-name-details-on-acn",
  urlParameters: [
    Parameters.abn
  ],
  queryParameters: [
    Parameters.acn
  ],
  responses: {
    200: {
      bodyMapper: Mappers.OrganisationDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const getDetailsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/get-details",
  queryParameters: [
    Parameters.searchString
  ],
  responses: {
    200: {
      bodyMapper: Mappers.LookupResult
    },
    500: {},
    default: {}
  },
  serializer
};

const getAccountingAuthorizeUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/{organisationId}/accounting-authorize-url/{accountingPackage}",
  urlParameters: [
    Parameters.accountingPackage,
    Parameters.organisationId0
  ],
  queryParameters: [
    Parameters.host
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getReconnectAccountingAuthorizeUrlOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Lookup/{loanId}/reconnect-accounting-authorize-url",
  urlParameters: [
    Parameters.loanId
  ],
  queryParameters: [
    Parameters.host
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const loadPartnerCacheOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/load-partner-cache",
  responses: {
    200: {},
    500: {},
    default: {}
  },
  serializer
};

const getPartnerOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/{partnerId}",
  urlParameters: [
    Parameters.partnerId1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Partner
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerSentLinkRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/{partnerId}/get-sent-link-request",
  urlParameters: [
    Parameters.partnerId1
  ],
  queryParameters: [
    Parameters.requestId
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerSendLinkRequest
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerOnEmailOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/{email}/email",
  urlParameters: [
    Parameters.email
  ],
  responses: {
    200: {
      bodyMapper: Mappers.Partner
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/get-partners",
  queryParameters: [
    Parameters.searchText,
    Parameters.skip0,
    Parameters.take0,
    Parameters.includeDisabled
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListPartnerProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const searchPartnersOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/search-partners",
  queryParameters: [
    Parameters.charList
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PartnerProjection"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAuthorizationToken1OperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/get-authorization-token",
  queryParameters: [
    Parameters.userName,
    Parameters.password
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAccreditationOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/get-accreditation",
  queryParameters: [
    Parameters.id1
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PartnerAccreditationRequest
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadBankStatementsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/{Id}/download-bank-statements",
  urlParameters: [
    Parameters.id2
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TransactionDataFile"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadBlankInvoicesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/{Id}/download-blank-invoices",
  urlParameters: [
    Parameters.id2
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TransactionDataFile"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const downloadW9FormOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/{Id}/download-w9form",
  urlParameters: [
    Parameters.id2
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerDailyReportsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/get-partner-daily-reports",
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListPartnerProjection
    },
    500: {},
    default: {}
  },
  serializer
};

const getPartnerIdByUrlReferralOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/Partner/get-partnerid-by-url-referral",
  queryParameters: [
    Parameters.urlReferral
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "String"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportingDocumentRequestOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/SupportingDocument/{applicationId}/get-supporting-document-request",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SupportDocumentDetails"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportingDocumentOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/SupportingDocument/{applicationId}/get-supporting-document/{location}",
  urlParameters: [
    Parameters.applicationId0,
    Parameters.location
  ],
  responses: {
    200: {
      bodyMapper: Mappers.TransactionDataFile
    },
    500: {},
    default: {}
  },
  serializer
};

const getSupportingDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/SupportingDocument/{applicationId}/get-supporting-document",
  urlParameters: [
    Parameters.applicationId0
  ],
  queryParameters: [
    Parameters.skip0,
    Parameters.take0
  ],
  responses: {
    200: {
      bodyMapper: Mappers.PagedListSupportDocumentDetails
    },
    500: {},
    default: {}
  },
  serializer
};

const isSupportedDocumentCompletedOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/SupportingDocument/{applicationId}/is-supported-document-completed",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const isSupportedEnabledOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/SupportingDocument/{applicationId}/is-supported-enabled",
  urlParameters: [
    Parameters.applicationId0
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Boolean"
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getRequestSupportingDocumentsOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/SupportingDocument/get-request-supporting-documents",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDesignElementsOnSiteOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/WhiteLabel/get-css-details-on-site",
  queryParameters: [
    Parameters.url
  ],
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getAllSitesOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/WhiteLabel/get-all-sites",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SiteDetails"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

const getDesignElementListOperationSpec: msRest.OperationSpec = {
  httpMethod: "GET",
  path: "api/WhiteLabel/get-design-element-list",
  responses: {
    200: {
      bodyMapper: {
        serializedName: "parsedResponse",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    },
    500: {},
    default: {}
  },
  serializer
};

export {
  OriginationReadApi,
  OriginationReadApiContext,
  Models as OriginationReadApiModels,
  Mappers as OriginationReadApiMappers
};
