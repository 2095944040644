/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from '@azure/ms-rest-js';
import * as msRest from '@azure/ms-rest-js';

/**
 * An interface representing BalanceSummary.
 */
export interface BalanceSummary {
  advances?: number;
  invoicePayments?: number;
  fees?: number;
  sweeps?: number;
  repayments?: number;
  rest?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing InvoicesSummary.
 */
export interface InvoicesSummary {
  invoiceRaised?: number;
  invoiceClosed?: number;
  modificationUp?: number;
  modificationDown?: number;
  invoiceVoided?: number;
  paymentsApplied?: number;
  paymentsUnapplied?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing PaymentsSummary.
 */
export interface PaymentsSummary {
  syntheticsOpened?: number;
  syntheticsClosed?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing AgedSummary.
 */
export interface AgedSummary {
  invoiceAged?: number;
  invoiceClosed?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing CreditNotesSummary.
 */
export interface CreditNotesSummary {
  noteOpened?: number;
  noteApplied?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing ContraSummary.
 */
export interface ContraSummary {
  billOpened?: number;
  billClosed?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing UnderReviewSummary.
 */
export interface UnderReviewSummary {
  intoUnderReview?: number;
  outOfUnderReview?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing DisapprovedSummary.
 */
export interface DisapprovedSummary {
  intoDisapproved?: number;
  outOfDisapproved?: number;
  total?: number;
  net?: number;
}

/**
 * An interface representing InternalApprovedSummary.
 */
export interface InternalApprovedSummary {
  total?: number;
  net?: number;
}

/**
 * An interface representing ConcentrationSummary.
 */
export interface ConcentrationSummary {
  total?: number;
  net?: number;
}

/**
 * An interface representing ApprovedSummary.
 */
export interface ApprovedSummary {
  total?: number;
  net?: number;
}

/**
 * An interface representing FundingRateSummary.
 */
export interface FundingRateSummary {
  total?: number;
  net?: number;
}

/**
 * An interface representing LimitSummary.
 */
export interface LimitSummary {
  total?: number;
  net?: number;
}

/**
 * An interface representing AvailabilitySummary.
 */
export interface AvailabilitySummary {
  total?: number;
  net?: number;
}

/**
 * An interface representing ActivitySummary.
 */
export interface ActivitySummary {
  activityId?: string;
  loanId?: string;
  date?: Date;
  eventName?: string;
  balance?: BalanceSummary;
  invoices?: InvoicesSummary;
  payments?: PaymentsSummary;
  aged?: AgedSummary;
  creditNotes?: CreditNotesSummary;
  contra?: ContraSummary;
  underReview?: UnderReviewSummary;
  disapproved?: DisapprovedSummary;
  internalApproved?: InternalApprovedSummary;
  concentration?: ConcentrationSummary;
  approved?: ApprovedSummary;
  fundingRate?: FundingRateSummary;
  limit?: LimitSummary;
  availability?: AvailabilitySummary;
}

/**
 * An interface representing PricingData.
 */
export interface PricingData {
  name?: string;
  repaymentOption?: string;
  systemIrr?: number;
  actualIrr?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly varianceIrr?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly varianceAdvanceRate?: number;
  type?: string;
  status?: string;
  id?: string;
  systemAdvanceRate?: number;
  actualAdvanceRate?: number;
  probabilityOfWin?: number;
  percentageLoss?: number;
}

/**
 * An interface representing TransactionFile.
 */
export interface TransactionFile {
  data?: string;
  fileName?: string;
  fileType?: string;
}

/**
 * An interface representing AutomatedDecisionData.
 */
export interface AutomatedDecisionData {
  date?: Date;
  applicationId?: string;
  name?: string;
  status?: string;
  cancelReason?: string;
  automatedDecision?: string;
  automatedDecisionProduct?: string;
  automatedDecisionLimit?: number;
  automatedDecisionNotes?: string;
  currentDecision?: string;
  currentDecisionProduct?: string;
  currentDecisionLimit?: number;
}

/**
 * An interface representing AutomatedDecisionStatistics.
 */
export interface AutomatedDecisionStatistics {
  acceptedOrDeclined?: number;
  totalOfApplication?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly autoDecisionRate?: number;
  countSystemAcceptedAndPlexeDeclined?: number;
  countSystemDeclineAndPlexeAccepted?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalIncorrectDecision?: number;
}

/**
 * An interface representing CustomerData.
 */
export interface CustomerData {
  message?: string;
  adminUser?: string;
  customer?: string;
  date?: Date;
  applicationId?: string;
}

/**
 * An interface representing ArrearsReportData.
 */
export interface ArrearsReportData {
  shortCode?: string;
  name?: string;
  loadId?: string;
  principalBalance?: number;
  totalArrears?: number;
  days?: number;
  action?: number;
  notes?: string;
  nextReminder?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly actionDisplay?: string;
  advanceId?: string;
  advanceName?: string;
}

/**
 * An interface representing ClosingBalance.
 */
export interface ClosingBalance {
  businessName?: string;
  balance?: number;
}

/**
 * An interface representing Dilution.
 */
export interface Dilution {
  status?: string;
  total?: number;
  details?: string;
  count?: number;
}

/**
 * An interface representing DriftMetric.
 */
export interface DriftMetric {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly metric?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly weight?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly direction?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly startDateValue?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly endDateValue?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly change?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly weightedChange?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly adjustedWeightedChange?: number;
}

/**
 * An interface representing DriftMetricList.
 */
export interface DriftMetricList {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly metricList?: DriftMetric[];
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalAdjustedWeightedChange?: number;
}

/**
 * An interface representing DebtorDayDetail.
 */
export interface DebtorDayDetail {
  invoiceCount?: number;
  totalDays?: number;
  totalAmount?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly averageDays?: number;
}

/**
 * An interface representing DebtorDayDetails.
 */
export interface DebtorDayDetails {
  total?: DebtorDayDetail;
  q1?: DebtorDayDetail;
  q2?: DebtorDayDetail;
  q3?: DebtorDayDetail;
  q4?: DebtorDayDetail;
}

/**
 * An interface representing DebtorDayValue.
 */
export interface DebtorDayValue {
  name?: string;
  nominated?: boolean;
  closed?: DebtorDayDetails;
  open?: DebtorDayDetails;
}

/**
 * An interface representing ConnectionStatus.
 */
export interface ConnectionStatus {
  id?: string;
  name?: string;
  provider?: string;
  bankConnected?: boolean;
  accountingConnected?: boolean;
  accountingLastUpdated?: Date;
  bankLastUpdated?: Date;
}

/**
 * An interface representing ProvisoData.
 */
export interface ProvisoData {
  name?: string;
  eventDate?: Date;
  eventName?: string;
}

/**
 * An interface representing LoanBalances.
 */
export interface LoanBalances {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly availableFunds?: number;
  systemPricing?: number;
  balance?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly totalLimit?: number;
  limits?: { [propertyName: string]: number };
  rawTotal?: number;
  adjustment?: number;
  totalDebtors?: number;
  totalFundingRate?: number;
}

/**
 * An interface representing ApplicationEvent.
 */
export interface ApplicationEvent {
  status?: string;
  organisation?: string;
  date?: Date;
  title?: string;
  customer?: string;
  partner?: string;
}

/**
 * An interface representing LoanEvent.
 */
export interface LoanEvent {
  available?: number;
  balance?: number;
  repaymentType?: string;
  loanType?: string;
  organisation?: string;
  receivableLimit?: number;
  cashflowLimit?: number;
  preceivableLimit?: number;
  inventoryLimit?: number;
  receivableBalance?: number;
  receivableAvailable?: number;
  percentageBalance?: number;
  percentageAvailable?: number;
  cashflowBalance?: number;
  cashflowAvailable?: number;
  notes?: string;
  totalLimit?: number;
  override?: number;
  receivablesApproved?: number;
  receivablesTotal?: number;
  overAdvance?: number;
  receivablesPayments?: number;
  receivablesDisapproved?: number;
  receivablesReceivablesAged?: number;
  receivablesInternallyApproved?: number;
  receivablesContra?: number;
  receivablesCreditsNotes?: number;
  receivablesConcentration?: number;
  date?: Date;
  title?: string;
  customer?: string;
  partner?: string;
}

/**
 * An interface representing ApplicationAcitvityState.
 */
export interface ApplicationAcitvityState {
  status?: string;
  title?: string;
  customer?: string;
  partner?: string;
  daysLastActivity?: number;
  dateLastAcivity?: Date;
  lastActivity?: string;
  organsation?: string;
}

/**
 * An interface representing AuditData.
 */
export interface AuditData {
  setting?: string;
  existingValue?: string;
  newValue?: string;
  notes?: string;
  date?: Date;
}

/**
 * An interface representing TransactionDetails.
 */
export interface TransactionDetails {
  date?: Date;
  shortCode?: string;
  businessName?: string;
  advanceName?: string;
  type?: string;
  details?: string;
  amount?: number;
  reconciled?: string;
  debtor?: string;
}

/**
 * An interface representing LoanAcitvityState.
 */
export interface LoanAcitvityState {
  available?: number;
  balance?: number;
  repaymentType?: string;
  loanType?: string;
  title?: string;
  customer?: string;
  partner?: string;
  daysLastActivity?: number;
  dateLastAcivity?: Date;
  lastActivity?: string;
  organsation?: string;
}

/**
 * An interface representing BalanceData.
 */
export interface BalanceData {
  closing?: number;
  outstanding?: number;
}

/**
 * An interface representing PortfolioMetricPerDate.
 */
export interface PortfolioMetricPerDate {
  name?: string;
  dates?: { [propertyName: string]: number };
  type?: string;
  id?: string;
  metric?: string;
  enabled?: boolean;
}

/**
 * An interface representing LoanBook.
 */
export interface LoanBook {
  interestRate?: number;
  interestRateAfterCommission?: number;
  customerName?: string;
  customerMobile?: string;
  customerEmail?: string;
  advanceRate?: number;
  establishmentFee?: number;
  commission?: number;
  commissionExcludingEstablishmentFee?: number;
  totalCommission?: number;
  remainingTerm?: number;
  firstDirectDebit?: Date;
  lastDirectDebit?: Date;
  weeklyRepayment?: number;
  weightedAvergageRate?: number;
  weightedAvergageRateNow?: number;
  totalRepayment?: number;
  establishmentAmount?: number;
  advanceId?: string;
  advanceAmount?: number;
  term?: number;
  repaymentOption?: string;
  accountingDataSource?: string;
  entity?: string;
  loanDisbursementDate?: Date;
  monthlyFee?: number;
  totalInterest?: number;
  totalPrincipal?: number;
  totalRepayments?: number;
  collectiveAdvanceAmount?: number;
  totalRepayment2?: number;
  totalRepaymentToDate?: number;
  totalRepaymentToASpecificedDate?: number;
  daysArrear?: number;
  loanId?: string;
  customerId?: string;
  settlementDate?: Date;
  businessName?: string;
  state?: string;
  industry?: string;
  outstandingBalance?: number;
  yearsInBusiness?: string;
  annualTurnOver?: number;
  partnerName?: string;
  balance?: number;
  probOfWin?: number;
  expectedValue?: number;
  totalOutstandingBalance?: number;
  vedaScore?: number;
  facilityLimit?: number;
  creditLimit?: number;
  availableCredit?: number;
  arrearsGreatThan7Days?: number;
  arrearsGreatThan14Days?: number;
  arrearsGreatThan21Days?: number;
  partnerBusinessName?: string;
  shortCode?: string;
  status?: string;
  loanCreationDate?: string;
}

/**
 * An interface representing OverrideValues.
 */
export interface OverrideValues {
  name?: string;
  date?: Date;
  overrideCashflow?: number;
  actualCashflow?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly cashflowDifference?: number;
  overrideInventory?: number;
  actualInventory?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly inventoryDifference?: number;
  overridePreceivables?: number;
  actualPreceivables?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly preceivablesDifference?: number;
  overrideReceivables?: number;
  actualReceivables?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly receivablesDifference?: number;
  loanType?: string;
  loanId?: string;
  cappedCashFlow?: number;
  amortisedCashFlow?: number;
}

/**
 * An interface representing LoanBookSummary.
 */
export interface LoanBookSummary {
  loanId?: string;
  customerId?: string;
  settlementDate?: Date;
  businessName?: string;
  state?: string;
  industry?: string;
  outstandingBalance?: number;
  yearsInBusiness?: string;
  annualTurnOver?: number;
  partnerName?: string;
  balance?: number;
  probOfWin?: number;
  expectedValue?: number;
  totalOutstandingBalance?: number;
  vedaScore?: number;
  facilityLimit?: number;
  creditLimit?: number;
  availableCredit?: number;
  arrearsGreatThan7Days?: number;
  arrearsGreatThan14Days?: number;
  arrearsGreatThan21Days?: number;
  partnerBusinessName?: string;
  shortCode?: string;
  status?: string;
  loanCreationDate?: string;
}

/**
 * An interface representing Repayment.
 */
export interface Repayment {
  name?: string;
  bankName?: string;
  bankBSB?: string;
  bankAccountNumber?: string;
  loanId?: string;
  date?: Date;
  advanceName?: string;
  amount?: number;
  advanceId?: string;
  details?: string;
  type?: string;
  id?: string;
}

/**
 * An interface representing TransactionDataFile.
 */
export interface TransactionDataFile {
  data?: Uint8Array;
  fileName?: string;
  fileType?: string;
}

/**
 * An interface representing Limits.
 */
export interface Limits {
  calculated?: number;
  override?: number;
}

/**
 * An interface representing CreditLimit.
 */
export interface CreditLimit {
  loanId?: string;
  name?: string;
  cashFlow?: Limits;
  receivables?: Limits;
  preceivables?: Limits;
  inventory?: Limits;
}

/**
 * An interface representing AdvanceDetails.
 */
export interface AdvanceDetails {
  loanId?: string;
  customerName?: string;
  advanceId?: string;
  percent?: number;
  priority?: boolean;
  date?: Date;
  businessName?: string;
  type?: string;
  term?: number;
  firstDirectDebit?: Date;
  lastDirectDebit?: Date;
  amount?: number;
  advanceRate?: number;
  day?: string;
  weeklyAmount?: number;
  shortCode?: string;
}

/**
 * An interface representing InterestPrincipal.
 */
export interface InterestPrincipal {
  contactName?: string;
  emailAddress?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly poAddressLine1?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly poAddressLine2?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly poAddressLine3?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly poAddressLine4?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly poCity?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly poPostalCode?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly poCountry?: string;
  invoiceNumber?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly reference?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly invoiceDate?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly dueDate?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly inventoryItemCode?: string;
  description?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly qty?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly unitAmount?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly discount?: string;
  accountCode?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly taxType?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly trackingName1?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly trackingOption1?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly trackingName2?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly trackingOption2?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly currency?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly brandingTheme?: string;
  date?: Date;
  amount?: number;
  loanId?: string;
}

/**
 * An interface representing CustomerAudit.
 */
export interface CustomerAudit {
  user?: string;
  date?: Date;
  eventName?: string;
}

/**
 * An interface representing PagedListCustomerAudit.
 */
export interface PagedListCustomerAudit {
  data?: CustomerAudit[];
  totalCount?: number;
}

/**
 * An interface representing PlexeAccountingProxyModelsPaymentInvoice.
 */
export interface PlexeAccountingProxyModelsPaymentInvoice {
  amountApplied?: number;
  id?: string;
  number?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsPayment.
 */
export interface PlexeAccountingProxyModelsPayment {
  type?: number;
  status?: number;
  currencyRate?: number;
  bankAmount?: number;
  amount?: number;
  reference?: string;
  isReconciled?: boolean;
  date?: Date;
  account?: string;
  updatedDate?: Date;
  retrievedDate?: Date;
  invoices?: PlexeAccountingProxyModelsPaymentInvoice[];
  debtor?: string;
  id?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsLineItem.
 */
export interface PlexeAccountingProxyModelsLineItem {
  id?: string;
  description?: string;
  discountRate?: number;
  itemCode?: string;
  lineAmount?: number;
  quantity?: number;
  taxAmount?: number;
  unitAmount?: number;
}

/**
 * An interface representing PlexeAccountingProxyModelsBalance.
 */
export interface PlexeAccountingProxyModelsBalance {
  outstanding?: number;
  overdue?: number;
}

/**
 * An interface representing PlexeAccountingProxyModelsBalances.
 */
export interface PlexeAccountingProxyModelsBalances {
  accountsReceivable?: PlexeAccountingProxyModelsBalance;
  accountsPayable?: PlexeAccountingProxyModelsBalance;
}

/**
 * An interface representing PlexeAccountingProxyModelsAddress.
 */
export interface PlexeAccountingProxyModelsAddress {
  addressType?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  city?: string;
  state?: string;
  region?: string;
  postalCode?: string;
  country?: string;
  attentionTo?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsPhone.
 */
export interface PlexeAccountingProxyModelsPhone {
  phoneType?: number;
  phoneNumber?: string;
  phoneAreaCode?: string;
  phoneCountryCode?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsContact.
 */
export interface PlexeAccountingProxyModelsContact {
  id?: string;
  firstName?: string;
  lastName?: string;
  abn?: string;
  acn?: string;
  companyName?: string;
  notes?: string;
  balance?: PlexeAccountingProxyModelsBalances;
  addresses?: PlexeAccountingProxyModelsAddress[];
  phones?: PlexeAccountingProxyModelsPhone[];
  fullName?: string;
}

/**
 * An interface representing PlexeAccountingProxyModelsCreditNote.
 */
export interface PlexeAccountingProxyModelsCreditNote {
  id?: string;
  date?: Date;
  dueDate?: Date;
  status?: number;
  total?: number;
  creditNoteNumber?: string;
  lineItems?: PlexeAccountingProxyModelsLineItem[];
  payments?: PlexeAccountingProxyModelsPayment[];
  contact?: PlexeAccountingProxyModelsContact;
}

/**
 * An interface representing InvoiceValues.
 */
export interface InvoiceValues {
  loanId?: string;
  debtor?: string;
  invoiceNumber?: string;
  amount?: number;
  raisedDate?: Date;
  lastPaymentDate?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly daysOpen?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly closed?: boolean;
  openDays?: { [propertyName: string]: number };
  nominated?: boolean;
  amountPaid?: number;
  payments?: PlexeAccountingProxyModelsPayment[];
  credits?: PlexeAccountingProxyModelsCreditNote[];
}

/**
 * An interface representing ReceivableTotal.
 */
export interface ReceivableTotal {
  totalOpenInvoices?: number;
  totalRaisedInvoices?: number;
  count?: number;
  openInvoices?: number;
  openInvoiceList?: InvoiceValues[];
  openInvoicesCount?: number;
}

/**
 * An interface representing Performance.
 */
export interface Performance {
  month?: string;
  ratioAllNetInclude?: number;
  ratioFixedNetInclude?: number;
  ratioLineOfCreditNetInclude?: number;
  ratioInvoiceNetInclude?: number;
  ratioAllNetExclude?: number;
  ratioFixedNetExclude?: number;
  ratioLineOfCreditNetExclude?: number;
  ratioInvoiceNetExclude?: number;
  ratioAllGrossInclude?: number;
  ratioFixedGrossInclude?: number;
  ratioLineOfCreditGrossInclude?: number;
  ratioInvoiceGrossInclude?: number;
  ratioAllGrossExclude?: number;
  ratioFixedGrossExclude?: number;
  ratioLineOfCreditGrossExclude?: number;
  ratioInvoiceGrossExclude?: number;
  totalAdvance?: number;
  advanceStartDate?: Date;
  advanceEndDate?: Date;
  currentDate?: Date;
  grossAmountExclude?: number;
  grossAmountInclude?: number;
  netAmountExclude?: number;
  netAmountInclude?: number;
  ratioFixedAndLocGrossExclude?: number;
  ratioFixedAndLocGrossInclude?: number;
  ratioFixedAndLocNetExclude?: number;
  ratioFixedAndLocNetInclude?: number;
}

/**
 * An interface representing PortfolioPerformance.
 */
export interface PortfolioPerformance {
  label?: string;
  totalAdvance?: number;
  performances?: Performance[];
  totalAdvanceSecured?: number;
  totalAdvanceLineOfCredit?: number;
  totalAdvanceFixed?: number;
  totalAdvanceFixedAndLineOfCredit?: number;
}

/**
 * An interface representing PerformanceDetail.
 */
export interface PerformanceDetail {
  month?: string;
  ratio?: number;
}

/**
 * An interface representing PortfolioPerformanceCompact.
 */
export interface PortfolioPerformanceCompact {
  label?: string;
  totalAdvance?: number;
  performances?: PerformanceDetail[];
}

/**
 * An interface representing ReportingServiceAPIOptions.
 */
export interface ReportingServiceAPIOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetActivitySummaryOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetActivitySummaryConsolidatedOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetAutomatedDecisionReportOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetAutomatedDecisionStatisticsReportOptionalParams
  extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetCustomerReportOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetArrearsReportOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
  /**
   * Default value: false.
   */
  includePaymentArrangement?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetArrearsPercentageOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
  /**
   * Default value: false.
   */
  includePaymentArrangement?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetArrearsReportCSVOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
  /**
   * Default value: false.
   */
  includePaymentArrangement?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetArrearsReportCSVLocalOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
  /**
   * Default value: false.
   */
  includePaymentArrangement?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetArrearsReportCSVLocalQuarterlyOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  includePaymentArrangement?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIDisplayMessageOptionalParams extends msRest.RequestOptionsBase {
  value?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPINewAlertOptionalParams extends msRest.RequestOptionsBase {
  alertId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIOfferUpdatedOptionalParams extends msRest.RequestOptionsBase {
  offerId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanEventsCSVLocalOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetAllTransactionsOptionalParams extends msRest.RequestOptionsBase {
  start?: Date;
  end?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetAllTransactionsCSVOptionalParams extends msRest.RequestOptionsBase {
  start?: Date;
  end?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanBookOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
  /**
   * Default value: true.
   */
  showClosed?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanBookSummaryOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetRepaymentByProductOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
  product?: string;
  loan?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetRepaymentOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
  loan?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetRepaymentCSVOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIUpdateLoanBookOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
  updateValues?: LoanBook;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanBookCSVOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
  /**
   * Default value: false.
   */
  showClosed?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanBookSummaryCSVOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanBookExcelOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
  /**
   * Default value: true.
   */
  showClosed?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanBookSummaryExcelOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIUpdateBalancesOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetAdvancesOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetAdvancesCSVOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetInterestAndPrincipalCSVOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetInterestAndPrincipalOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIUpdateDriftAverageDataOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIUpdateWeeklyDriftAverageDataFromDateOptionalParams
  extends msRest.RequestOptionsBase {
  startDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetHistoricalPortfolioMetricsReportCSVOptionalParams
  extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetLoanActivitySummaryCSVOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetCustomerAuditsOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
  startDate?: Date;
  endDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetAllCustomerAuditsOptionalParams extends msRest.RequestOptionsBase {
  startDate?: Date;
  endDate?: Date;
  /**
   * Default value: 0.
   */
  skip?: number;
  /**
   * Default value: 2147483647.
   */
  take?: number;
  filterColumn?: string;
  filterValue?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildPricingReportOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildAutomatedDecisionReportOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildTotalArrearsReportOnDateOptionalParams extends msRest.RequestOptionsBase {
  dateTime?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildArrearsReportOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildArrearsReportForLoanOnDateOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildHistoricalArrearsReportOnLoanOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildHistoricalArrearsReportOnLoan1OptionalParams
  extends msRest.RequestOptionsBase {
  id?: string;
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildClosingBalancesReportOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildCreditLimitsReportOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildCreditLimitsReportDirectOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildInvoiceReportOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildDebtorDaysReportOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildProvisoOptionalParams extends msRest.RequestOptionsBase {
  message?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildLoanLimitsOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildAllLoanPortfolioMetricsPerClientDailyOptionalParams
  extends msRest.RequestOptionsBase {
  startDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIBuildAllLoanPortfolioMetricsOptionalParams extends msRest.RequestOptionsBase {
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetPortfolioPerformanceOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: true.
   */
  quarterly?: boolean;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetPortfolioPerformanceDetailOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: true.
   */
  quarterly?: boolean;
  /**
   * Default value: false.
   */
  net?: boolean;
  /**
   * Default value: true.
   */
  include?: boolean;
  /**
   * Default value: 0.
   */
  repaymentType?: number;
}

/**
 * Optional Parameters.
 */
export interface ReportingServiceAPIGetPortfolioPerformanceCSVOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: true.
   */
  quarterly?: boolean;
}

/**
 * Contains response data for the getActivitySummary operation.
 */
export type GetActivitySummaryResponse = Array<ActivitySummary> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ActivitySummary[];
  };
};

/**
 * Contains response data for the getActivitySummaryConsolidated operation.
 */
export type GetActivitySummaryConsolidatedResponse = ActivitySummary & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ActivitySummary;
  };
};

/**
 * Contains response data for the getPricingReport operation.
 */
export type GetPricingReportResponse = Array<PricingData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: PricingData[];
  };
};

/**
 * Contains response data for the getPricingReportCSV operation.
 */
export type GetPricingReportCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getAutomatedDecisionReport operation.
 */
export type GetAutomatedDecisionReportResponse = Array<AutomatedDecisionData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: AutomatedDecisionData[];
  };
};

/**
 * Contains response data for the getAutomatedDecisionStatisticsReport operation.
 */
export type GetAutomatedDecisionStatisticsReportResponse = AutomatedDecisionStatistics & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: AutomatedDecisionStatistics;
  };
};

/**
 * Contains response data for the getCustomerReport operation.
 */
export type GetCustomerReportResponse = Array<CustomerData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: CustomerData[];
  };
};

/**
 * Contains response data for the getArrearsReport operation.
 */
export type GetArrearsReportResponse = Array<ArrearsReportData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ArrearsReportData[];
  };
};

/**
 * Contains response data for the getArrearsPercentage operation.
 */
export type GetArrearsPercentageResponse = {
  /**
   * The parsed response body.
   */
  body: number;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: number;
  };
};

/**
 * Contains response data for the getArrearsReportCSV operation.
 */
export type GetArrearsReportCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getAllInvoicesCSV operation.
 */
export type GetAllInvoicesCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getProcessedInvoicesCSV operation.
 */
export type GetProcessedInvoicesCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getAllClosingBalances operation.
 */
export type GetAllClosingBalancesResponse = Array<ClosingBalance> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ClosingBalance[];
  };
};

/**
 * Contains response data for the getMetrics operation.
 */
export type GetMetricsResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: string[];
  };
};

/**
 * Contains response data for the getDilutions operation.
 */
export type GetDilutionsResponse = Array<Dilution> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Dilution[];
  };
};

/**
 * Contains response data for the getAllInvoiceLoans operation.
 */
export type GetAllInvoiceLoansResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: string;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: { [propertyName: string]: string };
  };
};

/**
 * Contains response data for the getDriftMetrics operation.
 */
export type GetDriftMetricsResponse = DriftMetricList & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: DriftMetricList;
  };
};

/**
 * Contains response data for the getAllDriftMetrics operation.
 */
export type GetAllDriftMetricsResponse = DriftMetricList & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: DriftMetricList;
  };
};

/**
 * Contains response data for the getDriftMetricsCSV operation.
 */
export type GetDriftMetricsCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getAllDriftMetricsCSV operation.
 */
export type GetAllDriftMetricsCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getAllDebtorDays operation.
 */
export type GetAllDebtorDaysResponse = Array<DebtorDayValue> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: DebtorDayValue[];
  };
};

/**
 * Contains response data for the getAllDebtors operation.
 */
export type GetAllDebtorsResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: string;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: { [propertyName: string]: string };
  };
};

/**
 * Contains response data for the getConnectionReport operation.
 */
export type GetConnectionReportResponse = Array<ConnectionStatus> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ConnectionStatus[];
  };
};

/**
 * Contains response data for the getAverageDailyARCSV operation.
 */
export type GetAverageDailyARCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getOpenARCSV operation.
 */
export type GetOpenARCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getProvisoReport operation.
 */
export type GetProvisoReportResponse = Array<ProvisoData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ProvisoData[];
  };
};

/**
 * Contains response data for the getBalancesReport operation.
 */
export type GetBalancesReportResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: LoanBalances;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: { [propertyName: string]: LoanBalances };
  };
};

/**
 * Contains response data for the getBalancesReportCSV operation.
 */
export type GetBalancesReportCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getAllApplications operation.
 */
export type GetAllApplicationsResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: string;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: { [propertyName: string]: string };
  };
};

/**
 * Contains response data for the getApplicationEvents operation.
 */
export type GetApplicationEventsResponse = Array<ApplicationEvent> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ApplicationEvent[];
  };
};

/**
 * Contains response data for the getLoanEvents operation.
 */
export type GetLoanEventsResponse = Array<LoanEvent> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: LoanEvent[];
  };
};

/**
 * Contains response data for the getLoanEventsCSV operation.
 */
export type GetLoanEventsCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLastApplicationsActivities operation.
 */
export type GetLastApplicationsActivitiesResponse = Array<ApplicationAcitvityState> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: ApplicationAcitvityState[];
  };
};

/**
 * Contains response data for the getAllAuditNotes operation.
 */
export type GetAllAuditNotesResponse = Array<AuditData> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: AuditData[];
  };
};

/**
 * Contains response data for the getAllTransactions operation.
 */
export type GetAllTransactionsResponse = Array<TransactionDetails> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionDetails[];
  };
};

/**
 * Contains response data for the getAllTransactionsCSV operation.
 */
export type GetAllTransactionsCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getAllDebtorDaysCSV operation.
 */
export type GetAllDebtorDaysCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLastLoansActivities operation.
 */
export type GetLastLoansActivitiesResponse = Array<LoanAcitvityState> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: LoanAcitvityState[];
  };
};

/**
 * Contains response data for the getBalanceData operation.
 */
export type GetBalanceDataResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: BalanceData;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: { [propertyName: string]: BalanceData };
  };
};

/**
 * Contains response data for the getAllLoans operation.
 */
export type GetAllLoansResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: string;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: { [propertyName: string]: string };
  };
};

/**
 * Contains response data for the getPortfolioMetric operation.
 */
export type GetPortfolioMetricResponse = Array<PortfolioMetricPerDate> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: PortfolioMetricPerDate[];
  };
};

/**
 * Contains response data for the getPortfolioMetricMovingAverage operation.
 */
export type GetPortfolioMetricMovingAverageResponse = Array<PortfolioMetricPerDate> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: PortfolioMetricPerDate[];
  };
};

/**
 * Contains response data for the getPortfolioMetricCSV operation.
 */
export type GetPortfolioMetricCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLoanBook operation.
 */
export type GetLoanBookResponse = Array<LoanBook> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: LoanBook[];
  };
};

/**
 * Contains response data for the getLoanLimits operation.
 */
export type GetLoanLimitsResponse = Array<OverrideValues> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: OverrideValues[];
  };
};

/**
 * Contains response data for the getLoanLimitsCSV operation.
 */
export type GetLoanLimitsCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLoanBookSummary operation.
 */
export type GetLoanBookSummaryResponse = Array<LoanBookSummary> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: LoanBookSummary[];
  };
};

/**
 * Contains response data for the getRepaymentByProduct operation.
 */
export type GetRepaymentByProductResponse = Array<Repayment> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Repayment[];
  };
};

/**
 * Contains response data for the getRepayment operation.
 */
export type GetRepaymentResponse = Array<Repayment> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Repayment[];
  };
};

/**
 * Contains response data for the getRepaymentCSV operation.
 */
export type GetRepaymentCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLoanBookCSV operation.
 */
export type GetLoanBookCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLoanBookSummaryCSV operation.
 */
export type GetLoanBookSummaryCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLoanBookExcel operation.
 */
export type GetLoanBookExcelResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionDataFile;
  };
};

/**
 * Contains response data for the getLoanBookSummaryExcel operation.
 */
export type GetLoanBookSummaryExcelResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionDataFile;
  };
};

/**
 * Contains response data for the getCreditLimits operation.
 */
export type GetCreditLimitsResponse = Array<CreditLimit> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: CreditLimit[];
  };
};

/**
 * Contains response data for the getAdvances operation.
 */
export type GetAdvancesResponse = Array<AdvanceDetails> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: AdvanceDetails[];
  };
};

/**
 * Contains response data for the getAdvancesCSV operation.
 */
export type GetAdvancesCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getInterestAndPrincipalCSV operation.
 */
export type GetInterestAndPrincipalCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getInterestAndPrincipal operation.
 */
export type GetInterestAndPrincipalResponse = Array<InterestPrincipal> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: InterestPrincipal[];
  };
};

/**
 * Contains response data for the getDriftReportDataCSV operation.
 */
export type GetDriftReportDataCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getHistoricalPortfolioMetricsReportCSV operation.
 */
export type GetHistoricalPortfolioMetricsReportCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLoanActivityCSV operation.
 */
export type GetLoanActivityCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getLoanActivitySummaryCSV operation.
 */
export type GetLoanActivitySummaryCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the getCustomerAudits operation.
 */
export type GetCustomerAuditsResponse = Array<CustomerAudit> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: CustomerAudit[];
  };
};

/**
 * Contains response data for the getAllCustomerAudits operation.
 */
export type GetAllCustomerAuditsResponse = PagedListCustomerAudit & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: PagedListCustomerAudit;
  };
};

/**
 * Contains response data for the isAuditReportEnabled operation.
 */
export type IsAuditReportEnabledResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: boolean;
  };
};

/**
 * Contains response data for the buildReceivableTotals operation.
 */
export type BuildReceivableTotalsResponse = {
  /**
   * The response body properties.
   */
  [propertyName: string]: ReceivableTotal;
} & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: { [propertyName: string]: ReceivableTotal };
  };
};

/**
 * Contains response data for the buildAllLoanBookInMemoryCSV operation.
 */
export type BuildAllLoanBookInMemoryCSVResponse = TransactionFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionFile;
  };
};

/**
 * Contains response data for the buildLoanBookExcel operation.
 */
export type BuildLoanBookExcelResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: TransactionDataFile;
  };
};

/**
 * Contains response data for the getPortfolioPerformance operation.
 */
export type GetPortfolioPerformanceResponse = Array<PortfolioPerformance> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: PortfolioPerformance[];
  };
};

/**
 * Contains response data for the getPortfolioPerformanceDetail operation.
 */
export type GetPortfolioPerformanceDetailResponse = Array<PortfolioPerformanceCompact> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: PortfolioPerformanceCompact[];
  };
};

/**
 * Contains response data for the getConnectionReportLastUpdated operation.
 */
export type GetConnectionReportLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};

/**
 * Contains response data for the getAdvancesLastUpdated operation.
 */
export type GetAdvancesLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};

/**
 * Contains response data for the getBalancesLastUpdated operation.
 */
export type GetBalancesLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};

/**
 * Contains response data for the getPortfolioLastUpdated operation.
 */
export type GetPortfolioLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};

/**
 * Contains response data for the getLoanBookLastUpdated operation.
 */
export type GetLoanBookLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};

/**
 * Contains response data for the getLoanBookSummaryLastUpdated operation.
 */
export type GetLoanBookSummaryLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};

/**
 * Contains response data for the getRepaymentLastUpdated operation.
 */
export type GetRepaymentLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};

/**
 * Contains response data for the getInterestPrincipalLastUpdated operation.
 */
export type GetInterestPrincipalLastUpdatedResponse = {
  /**
   * The parsed response body.
   */
  body: Date;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: Date;
  };
};
