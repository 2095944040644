import { Injectable } from "@angular/core";
import { ErrorHandler } from "@angular/core";
import { LoggingService } from "./services/logging.service";

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private monitoringService: LoggingService) {
    super();
  }

  handleError(error: Error) {
    this.monitoringService.logException(error); // Manually log exception
  }
}
