<div class="au-quick-books-disconnected-section">
  <section class="quick-books-disconnected">
    <div class="quick-books-disconnected-container">
      <h1 class="quick-books-disconnected__header white">QuickBooks Disconnected</h1>
      <span class="header-divider"></span>
      <!--<h4 class="quick-books-disconnected__subtitle mb-60">A fast and simple process</h4>-->
      <p class="quick-books-disconnected__text mb-30">
        Your account has been disconnected. To see full financial information of your business and maximize your funding options, you can reconnect below. 
      </p>
      <p class="quick-books-disconnected__text mb-30">
        If you would like to re-connect Plexe and your QuickBooks account, login to your portal and click the "Connect QuickBooks" button.
      </p>
   <!--   <p><img style="cursor: pointer;" height="40" src="assets/images/{{ qbConnectButton }}.png" /></p>-->
      <div class="quick-books-disconnected__find-more">
        <a class="find-more-btn" *ngIf="urlService.checkUrlCom()" href="https://portal.plexe.com.au/login">Log In</a>
        <a class="find-more-btn" *ngIf="urlService.checkUrlCo()" href="https://portal.plexe.co/login">Log In</a>
        <i class="far fa-arrow-alt-circle-down find-out-more"></i>
      </div>
    </div>
  </section>
  <section class="detail">
    <div class="detail-container">
      <h3 class="detail__title">
        Your guide to <span class="detail__span-text">our line of credit</span>
      </h3>
      <span class="detail__divider"></span>
      <p class="detail__subtitle">How could your business benefit</p>
      <div class="detail__details-list">
        <div class="detail-list__item">
          Most working capital loans require you to withdraw 100% of available
          funds once you’re approved and reapply each time you need additional
          funds for your business. This leaves you with little funds available
          when unexpected opportunities arise.
        </div>
        <div class="detail-list__item">
          With Plexe your Line of Credit provides you with a limit, which is the
          maximum amount you can withdraw. You can use it as much or little, and
          as often as you like for business purchases such as inventory,
          supplies, or operating expenses.
        </div>
        <div class="detail-list__item">
          Each time you withdraw you set the terms you like, which consolidates
          all your past withdrawals. This makes it easy for you to manage only
          one schedule and budget accordingly.
        </div>
        <div class="detail-list__item">
          We also recognise that business can face situations that affect cash
          flow. This can make it particularly difficult when you have fixed loan
          repayments. That is why we let you choose between fixed or percentage
          of your weekly income repayments. Over the time you can even switch
          between the two to perfectly match your needs.
        </div>
      </div>
    </div>
  </section>
</div>
