<section *ngIf="urlService.checkUrlCom()">
  <div class="container">
    <h1>Privacy and Credit Policy</h1>

    <h3>1. Who are we?</h3>
    <p class="policy-text">Plexe Pty Ltd (Plexe), is a financial services provider, providing line of credit facilities
      for small to medium sized enterprises for business purposes only.</p>

    <h3>2. What is this Privacy statement about?</h3>
    <p class="policy-text">This statement reflects Plexe’s commitment regarding the protection of your privacy. The
      Privacy statement may from time to time be updated by Plex. Any updates will be reflected on our website and we
      encourage you to view this regularly. You have our full commitment to protecting your personal data that we
      collect, receive and disclose and that it’s in accordance with the Privacy Act, 1988 Commonwealth (Privacy Act)
    </p>

    <h3>3. What does this Privacy statement apply to?</h3>
    <p class="policy-text">To personal information as defined in the Privacy Act. Generally, personal data is
      information or an opinion in any form, about an individual (not a company or trust) whose identity is likely or
      can be ascertained from the information at hand. Our obligation relating to the data we collate as part of a
      commercial agreement is directed by the confidential information undertaking in the commercial financial services
      arrangement. By executing the agreement with us, you agree to the collection, use and disclosure of personal data
      reflected in this Privacy Statement. You are under no obligation to disclose any personal data to us; however,
      this may result in us not being able to process your application.</p>

    <h3>4. Our broad undertaking to you</h3>
    <p class="policy-text">We undertake to collect, store and use personal data which is compliant with the Privacy Act.
      We won’t use or disclose personal data other than for a purpose: (1) as disclosed in this Privacy Statement; (2)
      in which the data was provided; (3) authorized by law or required; (4) you authorised or is disclosed to you; and
      (5) to give effect to your directions. Plexe may collect, store and or disclose data about you to third parties
      for the following reasons: (1) complying with law; (2) to obtain a credit record on you, (3) to allow the credit
      reporting bodies build or manage a credit data record containing information about you. (4) to provide you with
      marketing material about financial services and products we may provide; (5) to manage and administer the
      agreement with you; (6) to protect our legal interests; (7) to follow your instructions; and (8) any discussions
      with a third party regarding am assignment of the loans or partial or full acquisition of the business.</p>

    <h3>5. The purpose for which we collect, disclose personal data</h3>
    <p class="policy-text">Plexe collects and discloses personal data as it deems necessary to effectively process your
      application and to fulfil your requests. The data we may collect or disclose includes, when permitted by law: (1)
      identification data; (2) that you have made an application for credit, the dollar amount and credit type; (3) if
      repayments are greater than 60 days overdue; (4) that defaults are remedied or overdue payments are no longer
      overdue; (5) data as to whether you have committed a serious infringement of credit; (6) data about cheques
      dishonoured; (7) your repayment history; (8) data about court judgments made against you; (9) data which is
      publicly available in the public domain and relevant to your credit worthiness; (10) insolvency information from
      the National Personal Insolvency Index. Third Party Service Provider Subject to the Privacy Act, in the process of
      providing you with the services, we may share personal information with related bodies corporate and with third
      parties with whom we have dealings in the usual course of carrying on our business. These third-party service
      providers may include agents, portfolio managers, mail house operators, collection agencies, research providers,
      dispute resolution schemes, financiers and accounting, legal and other advisers.</p>

    <h3>6. Why is my personal information being collected and disclosed?</h3>
    <p class="policy-text">The collection and disclosure of personal information is required in order to process your
      finance application as per your instructions. You are not required to provide us with any information requested,
      however this may restrict us from providing you with access to any of our services.</p>

    <h3>7. Your personal information may also assist us in:</h3>
    <ul>
      <li>
        reviewing your finance application;
      </li>
      <li>
        provide you with updates on any products, services, direct marketing and any promotional offers that may be of
        interest to you subject to any opt out options you have requested. If you wish to opt-out, please send requests
        to team@plexe.com.au
      </li>
      <li>
        provide you with updates on any products, services, direct marketing and any promotional offers that may be of
        interest to you subject to any opt out options you have requested. If you wish to opt-out, please send requests
        to team@plexe.com.au
      </li>
      <li>
        making contact in any method deemed efficient including but not limited to electronic or written communication
      </li>
      <li>
        making any modifications to our website portal and finance application process
      </li>
      <li>
        dispute resolution and replying to any enquiries you may have
      </li>
    </ul>
    <h3>8. Website cookies and usage of data</h3>
    <p class="policy-text">When visiting our website, software and cookies may be used to collect some data about pages
      you have visited, time you have visited the pages, web portals visited prior to visiting our website etc. You can
      choose to decline cookies if your browser permits, however this may limit you from gaining the full benefits of
      our website and services. None of this data has any association with an identifiable person and therefore the
      collection of such data isn’t personal information. This policy applies to our website only and not to any other
      websites. Plexe is not responsible for the privacy practises of other such websites and we therefore encourage any
      users to be aware, when they leave our website, to read the privacy statements of each and every website that
      collects personal identifiable data.</p>

    <h3>9. Can I remain nameless with Plexe</h3>
    <p class="policy-text">For general information regarding our services only, you may speak to us without providing
      your identity, however for any finance applications we will require your personal information to confirm your
      identity for Anti-Money Laundering and Counter-Terrorism Financing Rules and to assist in processing your finance
      application.</p>

    <h3>10. Accessing and updating your personal data</h3>
    <p class="policy-text">Subject to a proper identification process, you may access, or update any personal
      information we have about you in writing from our privacy officer at E-mail: team@plexe.com.au. There is no charge
      to make a request however we may charge an administration fee for providing access or updating your personal
      details we have on you if the information is incorrect. If for any reason this is unavailable, we will notify you
      in writing with reasons. All requests will be handled within a reasonable time period and we will follow due
      process to correct information we believe is incorrect.</p>

    <h3>11. Security of your personal information</h3>
    <p class="policy-text">We maintain strict protocols to ensure all reasonable care is taken to protect and avoid any
      unauthorised access, changes, disclosure, harm or loss of your data and in the unlikely event this happens, we
      will immediately inform the effected parties with any data they request with reference to the breach of pivacy.
    </p>

    <h3>12. Credit reporting bodies</h3>
    <p class="policy-text">We may disclose credit information to credit reporting bodies that we use such as; Illion
      Australia Pty Ltd (www.checkyourcredit.com.au; PO Box 7405 St Kilda Road MELBOURNE VIC 3004 Equifax Pty Ltd
      (www.equifax.com.au PO Box 964 NORTH SYDNEY NSW 2059)</p>

    <h3>13. Complaints</h3>
    <p class="policy-text">If you believe we haven’t acted in accordance with our obligations under the Privacy Act or
      the registered Credit Reporting Code or as a credit provider, we encourage you to contact our privacy officer at
      team@plexe.com.au. We will endeavour to resolve any complaints. Please contact us on team@plexe.com.au, for any
      enquires, problems or complaints you may have regarding this privacy statement or the way we manage any of your
      personal information</p>

    <h3>14. Disclosing data to overseas recipients</h3>
    <p class="policy-text">Your personal information is unlikely to be disclosed to overseas recipients in other
      countries. In this event we will ensure that they protect the privacy of your data as we are committed under this
      statement.</p>

    <h3>15. Notification of any breaches in data</h3>
    <p class="policy-text">We will notify any individuals effected as well as the Australian Information Commissioner,
      of “eligible data breaches” as defined for the purpose of Part IIIC of the Privacy Act 1988 for any data breaches
      and we will provide the notification when we are required to do so in terms of that Act.</p>

    <h3>16. Any further information</h3>
    <p class="policy-text">Please contact us at team@plexe.com.au should you require any further information relating to
      this privacy statement or any other further information requests.</p>
  </div>
</section>

<section *ngIf="urlService.checkUrlCo()">
  <div class="container">
    <h1>Privacy and Credit Policy</h1>

    <h3>Online Privacy Notice</h3>
    <p class="policy-text">
      Your privacy is important to us. We conduct regular assessment reviews to ensure information we collect, use and share is protected. This Plexe, LLC Online Privacy Notice ("Notice") describes how PLEXE, LLC and any of our affiliates manage personal information about you when you interact with us online through our websites, mobile applications, and social sites ("Sites and Mobile Apps").
    </p>

    <h3>This notice explains</h3>
    <p class="policy-text">
      •	How we collect personal information when you visit, use, or interact with us online, and through our ads displayed through online services operated by us or non-affiliated third parties
    </p>
    <p class="policy-text">
      •	How we may use or share personal information collected to deliver products and services to you and for advertising purposes
    </p>
    <p class="policy-text">
      The term “PLEXE” or "we", "us" or "our" in this Notice refers to Plexe, LLC affiliates or subsidiaries of Plexe, LLC that link to or reference this Notice.
    </p>
    <p class="policy-text">
      By using the Sites and Mobile Apps, you agree to the terms and conditions of this Notice. PLEXE provides other online interfaces. If you visit or access your accounts from one of these sites or mobile apps, please review the online privacy practices of that site or mobile app to understand how your online personal information may be collected, used, and shared.
    </p>

    <h3>Our online privacy practices</h3>
    <p class="policy-text">
      We are committed to transparency about your personal information. We ask for your consent when required, otherwise by using our Site and Mobile Apps, you consent to the collection, use and sharing of your personal information subject to and consistent with applicable laws and other notices you may have received based on your relationship with us.
    </p>
    <h3> Linking to other sites</h3>
    <p class="policy-text">
      We may provide links to non-affiliated third-party sites, such as credit bureaus, service providers or merchants. If you follow links to sites not affiliated with or controlled by PLEXE, you should review their privacy and security policies and other terms and conditions, as they may be different from those of our Sites and Mobile Apps. PLEXE does not guarantee and is not responsible for the privacy or security of these sites, including the accuracy, completeness, or reliability of their information.
    </p>

    <h3>Protecting your personal information</h3>
    <p class="policy-text">
      To protect personal information from unauthorized access and use, we use security measures that comply with applicable federal and state laws. These measures may include device safeguards and secured files and buildings as well as oversight of our third-party service providers to ensure personal information remains confidential and secure. In the event of a data breach, we provide timely notification, in accordance with applicable laws.
    </p>
    <p class="policy-text">
      We also recognize the importance of protecting privacy where children are involved. Our Sites and Mobile Apps are not directed to individuals under the age of 13, and we request that these individuals do not provide personal information through our Sites and Mobile Apps. We do not knowingly collect personal information from children under 13.
    </p>

    <h3>Making sure personal information is accurate</h3>
    <p class="policy-text">
      Keeping your personal information accurate and up to date is very important. If your personal information is incomplete, inaccurate or not current, please use the Contact Us option on our Sites.
    </p>

    <h3>How do we collect personal information online?</h3>
    <p class="policy-text">
      We may collect personal information about you through your computer, smartphone, tablet or other mobile device by the use of cookies and similar tracking technologies as well as personal information you provide when you visit or use our Sites and Mobile Apps.
    </p>
    <p class="policy-text">
      We may use vendors and service providers to process personal information for business purposes on our behalf. Service providers and vendors are contractually obligated to comply with our policies to protect information we share with them, or they collect on our behalf.
    </p>
    <p class="policy-text">
      The personal information we collect is limited to what is required to provide our products or services and to support legal and risk requirements. 
    </p>


    <h3>Types of personal information we collect</h3>
    <p class="policy-text">
      The type of personal information we collect from and about you online will depend on how you interact with us and may include but not limited to:
    </p>
    <ul>
      <li>
        Contact Information, Account Application information, Identifiers, Access Authorization, Information from your computer, smartphone, tablet, or other mobile device. 
      </li>
    </ul>



    <h3>How do we use your personal information?</h3>
    <p class="policy-text">
      Collected from and about you online described in this Notice may be used for several purposes including but not limited to delivering products & services to you and providing advertising.
    </p>
    <p class="policy-text">
      We retain personal information for a period as required by laws and regulations and the necessary business purpose. We securely delete personal information as soon as legally permitted.
    </p>

    <h3>With whom do we share your personal information?</h3>
    <p class="policy-text">
      We may share the personal information we collect from and about you online described in this Notice (and subject to other legal restrictions and notices you may have received depending on your relationship with us with:
    </p>
    <ul>
      <li>Affiliates of PLEXE; Service Providers, Vendors and Third-Party Providers who have contracts with PLEXE.</li>
      <li>Government Agencies as required by laws and regulations.</li>
    </ul>

    <h3>Aggregated/De-identified information</h3>
    <p class="policy-text">
      Collected personal information may be aggregated and/or de-identified (we remove any details that identify you personally). We may share this aggregated and/or de-identified information with service providers, vendors and third-party providers to help deliver products, services, and content that are better tailored to the users of our online services and for our own business purposes where permissible under applicable laws and regulations.
    </p>
    <h3>How to limit sharing</h3>
    <p class="policy-text">
      You have choices regarding the sharing of some personal information. Where appropriate, we will limit sharing of your personal information based on your privacy choices. 
    </p>
    <h3>Reminder</h3>
    <p class="policy-text">
      Please note that if you opt out of advertising, you may still receive untailored advertising from PLEXE:
    </p>
    <ul>
      <li>
        When accessing online account servicing areas (i.e., after sign-in), such as your online accounts, you may receive tailored content and advertising based on your account relationships.
      </li>
      <li>
        For online behavioural advertising opt outs from our Sites and Mobile Apps and on other sites to work on your device, your browser must be set to accept cookies.
      </li>
      <li>
        If you delete cookies, buy a new device, access our Sites and Mobile Apps or other sites from a different device, login under a different screen name, or change web browsers, you will need to opt out again.
      </li>
      <li>
        If your browser has scripting disabled, you do not need to opt out, as online behavioural advertising technology does not work when scripting is disabled.
      </li>
    </ul>

    <h3>Aggregation Services</h3>

    <p>Some companies may offer aggregation websites and services that allow you to consolidate your account information from different sources (such as your accounts with  other financial institutions) so that you can view it in one location or perform actions related to your accounts using their services (a "Provider"). To do this, a Provider may request you to provide your information-sharing consent directly to PLEXE.</p>
    <ul>
      <li>
        The Provider may access, on your behalf, information about yourself.
      </li>
      <li>
        You should use caution and ensure that the Provider has appropriate policies and practices to protect the privacy and security of any personal information you provide or to which they are gaining access.
      </li>
      <li>
      	Use of your information by the Provider is governed by your agreement with them, not by PLEXE.
      </li>
      <li>
        We are not responsible for the use or disclosure of any personal information accessed by any company or person to whom you provide your site username and passcode.
      </li>
      <li>
        If you share your PLEXE username, passcode, or other information about your accounts with others, we will consider that you have authorized any transaction or action initiated by using the access information you provide
      </li>
      <li>
        If you decide to revoke the authority you have given to a Provider, we strongly recommend that you change your PLEXE passcode to ensure that the Provider cannot continue to access your account
      </li>
      <li>
        You may revoke your consent for certain Providers through PLEXE Online portal.
      </li>
    </ul>

    <h3>Social Media</h3>

    <p> PLEXE engages with customers on social media platforms such as Facebook™, Twitter™, YouTube™ and LinkedIn™.</p>

    <ul>
      <li>
        Any content you post on official PLEXE managed social media pages, such as pictures, information, opinions, or any personal information that you make available to other participants on these social platforms, is subject to the Terms of Use and Privacy Policies of those respective platforms.
      </li>
      <li>
        When interacting with official PLEXE social media pages, PLEXE's privacy notices may apply.
      </li>
      <li>
        Please review the privacy policy for the specific social media service you are using to better understand your rights and obligations regarding such content.
      </li>
    </ul>
      <p>
        We may allow certain non-affiliated third-party widgets (for example social share buttons) on our sites that enable users to easily share information on another platform, such as a social media platform. The non-affiliated third parties that own these widgets may have access to information about your browsing on pages of our Sites and Mobile Apps where these widgets are placed.
      </p>
  
    <h3>Effective (Rev 09/2021)</h3>

  </div>
</section>