/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from '@azure/ms-rest-js';
import * as msRest from '@azure/ms-rest-js';

/**
 * An interface representing LoginModel.
 */
export interface LoginModel {
  username: string;
  password: string;
}

/**
 * An interface representing FullApplicationUser.
 */
export interface FullApplicationUser {
  firstName?: string;
  lastName?: string;
  id?: string;
  email?: string;
  username?: string;
  userType?: string;
  token?: string;
  expiresUTC?: Date;
  requiresTwoFactor?: boolean;
}

/**
 * An interface representing TwoFactorModel.
 */
export interface TwoFactorModel {
  username: string;
  code: string;
}

/**
 * An interface representing UserModel.
 */
export interface UserModel {
  firstName?: string;
  lastName?: string;
  customerType?: string;
  lockoutEnd?: Date;
  twoFactorEnabled?: boolean;
  phoneNumberConfirmed?: boolean;
  phoneNumber?: string;
  emailConfirmed?: boolean;
  normalizedEmail?: string;
  email?: string;
  normalizedUserName?: string;
  userName?: string;
  id?: string;
  lockoutEnabled?: boolean;
  accessFailedCount?: number;
  lastSignInUtc?: Date;
  lastLogOutUtc?: Date;
}

/**
 * An interface representing HistoricalLoginModel.
 */
export interface HistoricalLoginModel {
  userName?: string;
  logInDateUtc?: Date;
  logOutdateUtc?: Date;
  timeLoggedIn?: number;
}

/**
 * An interface representing PagedListHistoricalLoginModel.
 */
export interface PagedListHistoricalLoginModel {
  data?: HistoricalLoginModel[];
  totalCount?: number;
}

/**
 * An interface representing RegisterModel.
 */
export interface RegisterModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  passwordConfirmation: string;
  customerType: string;
  username: string;
  password: string;
}

/**
 * An interface representing UpdateModel.
 */
export interface UpdateModel {
  firstName: string;
  lastName: string;
  email: string;
  customerType: string;
}

/**
 * An interface representing PasswordResetModel.
 */
export interface PasswordResetModel {
  password: string;
  passwordConfirmation: string;
}

/**
 * An interface representing AuthorizationAPIOptions.
 */
export interface AuthorizationAPIOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPICreateTokenOptionalParams extends msRest.RequestOptionsBase {
  loginModel?: LoginModel;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIValidateTwoFactorLoginOptionalParams extends msRest.RequestOptionsBase {
  twoFactorModel?: TwoFactorModel;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIResendTwoFactorOtpOptionalParams extends msRest.RequestOptionsBase {
  userName?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIGetUserOptionalParams extends msRest.RequestOptionsBase {
  username?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIGetUserByIdOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIDeleteUserOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIGetHistoricalLoginsOptionalParams extends msRest.RequestOptionsBase {
  fromDateTimeUtc?: Date;
  toDateTimeUtc?: Date;
  skip?: number;
  take?: number;
  orderBy?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIGetAllInternalUsersOptionalParams extends msRest.RequestOptionsBase {
  orderBy?: string;
  skip?: number;
  /**
   * Default value: 30.
   */
  take?: number;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIRegisterOptionalParams extends msRest.RequestOptionsBase {
  registerModel?: RegisterModel;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIUpdateOptionalParams extends msRest.RequestOptionsBase {
  updateModel?: UpdateModel;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIUpdateByIdOptionalParams extends msRest.RequestOptionsBase {
  updateModel?: UpdateModel;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIResetOptionalParams extends msRest.RequestOptionsBase {
  updateModel?: PasswordResetModel;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIPasswordResetConfirmationOptionalParams extends msRest.RequestOptionsBase {
  userEmail?: string;
  updateModel?: PasswordResetModel;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIIndexOptionalParams extends msRest.RequestOptionsBase {
  passwordResetToken?: string;
  email?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIPasswordResetConfirmation1OptionalParams extends msRest.RequestOptionsBase {
  passwordResetToken?: string;
  email?: string;
}

/**
 * Optional Parameters.
 */
export interface AuthorizationAPIGetVariableOtpLengthOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: 6.
   */
  length?: number;
}

/**
 * Contains response data for the createToken operation.
 */
export type CreateTokenResponse = FullApplicationUser & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: FullApplicationUser;
  };
};

/**
 * Contains response data for the validateTwoFactorLogin operation.
 */
export type ValidateTwoFactorLoginResponse = FullApplicationUser & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: FullApplicationUser;
  };
};

/**
 * Contains response data for the resendTwoFactorOtp operation.
 */
export type ResendTwoFactorOtpResponse = FullApplicationUser & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: FullApplicationUser;
  };
};

/**
 * Contains response data for the getUser operation.
 */
export type GetUserResponse = UserModel & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: UserModel;
  };
};

/**
 * Contains response data for the getUserById operation.
 */
export type GetUserByIdResponse = UserModel & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: UserModel;
  };
};

/**
 * Contains response data for the deleteUser operation.
 */
export type DeleteUserResponse = UserModel & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: UserModel;
  };
};

/**
 * Contains response data for the getHistoricalLogins operation.
 */
export type GetHistoricalLoginsResponse = PagedListHistoricalLoginModel & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: PagedListHistoricalLoginModel;
  };
};

/**
 * Contains response data for the getAllInternalUsers operation.
 */
export type GetAllInternalUsersResponse = Array<UserModel> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: UserModel[];
  };
};

/**
 * Contains response data for the refreshToken operation.
 */
export type RefreshTokenResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: string;
  };
};

/**
 * Contains response data for the sendLogEvent operation.
 */
export type SendLogEventResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: string;
  };
};

/**
 * Contains response data for the get operation.
 */
export type GetResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: string;
  };
};

/**
 * Contains response data for the getVariableOtpLength operation.
 */
export type GetVariableOtpLengthResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: string;
  };
};

/**
 * Contains response data for the validateOtp operation.
 */
export type ValidateOtpResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
    /**
     * The response body as text (string format)
     */
    bodyAsText: string;

    /**
     * The response body as parsed JSON or XML
     */
    parsedBody: boolean;
  };
};
