import { NgModule, Optional, SkipSelf } from '@angular/core';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { RouteReusableStrategy } from '../core/route-reusable-strategy';
import { SystemService } from '../core/services/system.service';
import { HomePageRedirectGuard } from '../core/guards/home-page-redirect.guard';

@NgModule({
  imports: [RouterModule],
  declarations: [],
  providers: [
    SystemService,
    HomePageRedirectGuard,
    {
      provide: RouteReuseStrategy,
      useClass: RouteReusableStrategy
    }
  ],
  exports: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
