/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const accounting: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "accounting"
  ],
  mapper: {
    serializedName: "accounting",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const advanceId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "advanceId"
  ],
  mapper: {
    serializedName: "advanceId",
    type: {
      name: "Uuid"
    }
  }
};
export const advanceId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "advanceId"
  ],
  mapper: {
    serializedName: "AdvanceId",
    type: {
      name: "Uuid"
    }
  }
};
export const advanceId2: msRest.OperationURLParameter = {
  parameterPath: "advanceId",
  mapper: {
    required: true,
    serializedName: "advanceId",
    type: {
      name: "Uuid"
    }
  }
};
export const amount: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "amount"
  ],
  mapper: {
    serializedName: "amount",
    type: {
      name: "Number"
    }
  }
};
export const applicantId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "applicantId"
  ],
  mapper: {
    serializedName: "applicantId",
    type: {
      name: "Uuid"
    }
  }
};
export const applicationId0: msRest.OperationURLParameter = {
  parameterPath: "applicationId",
  mapper: {
    required: true,
    serializedName: "applicationId",
    type: {
      name: "Uuid"
    }
  }
};
export const applicationId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "applicationId"
  ],
  mapper: {
    serializedName: "applicationId",
    type: {
      name: "Uuid"
    }
  }
};
export const archive: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "archive"
  ],
  mapper: {
    serializedName: "archive",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const auditType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "auditType"
  ],
  mapper: {
    serializedName: "auditType",
    type: {
      name: "String"
    }
  }
};
export const balanceOverride: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "balanceOverride"
  ],
  mapper: {
    serializedName: "balanceOverride",
    type: {
      name: "Boolean"
    }
  }
};
export const bankId: msRest.OperationQueryParameter = {
  parameterPath: "bankId",
  mapper: {
    required: true,
    serializedName: "bankId",
    type: {
      name: "String"
    }
  }
};
export const banking: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "banking"
  ],
  mapper: {
    serializedName: "banking",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const businessName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "businessName"
  ],
  mapper: {
    serializedName: "businessName",
    type: {
      name: "String"
    }
  }
};
export const cardId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "cardId"
  ],
  mapper: {
    serializedName: "cardId",
    type: {
      name: "Uuid"
    }
  }
};
export const code: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "code"
  ],
  mapper: {
    serializedName: "code",
    type: {
      name: "String"
    }
  }
};
export const comment: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "comment"
  ],
  mapper: {
    serializedName: "comment",
    type: {
      name: "String"
    }
  }
};
export const communicationId: msRest.OperationURLParameter = {
  parameterPath: "communicationId",
  mapper: {
    required: true,
    serializedName: "communicationId",
    type: {
      name: "Uuid"
    }
  }
};
export const companyUri: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "companyUri"
  ],
  mapper: {
    serializedName: "companyUri",
    type: {
      name: "String"
    }
  }
};
export const connectionId: msRest.OperationURLParameter = {
  parameterPath: "connectionId",
  mapper: {
    required: true,
    serializedName: "connectionId",
    type: {
      name: "String"
    }
  }
};
export const count: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "count"
  ],
  mapper: {
    serializedName: "count",
    type: {
      name: "Number"
    }
  }
};
export const createdOnUtc: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "createdOnUtc"
  ],
  mapper: {
    serializedName: "createdOnUtc",
    type: {
      name: "DateTime"
    }
  }
};
export const customerId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "customerId"
  ],
  mapper: {
    serializedName: "customerId",
    type: {
      name: "Uuid"
    }
  }
};
export const customerId1: msRest.OperationURLParameter = {
  parameterPath: "customerId",
  mapper: {
    required: true,
    serializedName: "customerId",
    type: {
      name: "Uuid"
    }
  }
};
export const dateOfBirth: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateOfBirth"
  ],
  mapper: {
    serializedName: "dateOfBirth",
    type: {
      name: "DateTime"
    }
  }
};
export const dateParameter: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "dateParameter"
  ],
  mapper: {
    serializedName: "date",
    type: {
      name: "DateTime"
    }
  }
};
export const debtor: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "debtor"
  ],
  mapper: {
    serializedName: "debtor",
    type: {
      name: "String"
    }
  }
};
export const debtorId: msRest.OperationQueryParameter = {
  parameterPath: "debtorId",
  mapper: {
    required: true,
    serializedName: "debtorId",
    type: {
      name: "String"
    }
  }
};
export const debtors: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "debtors"
  ],
  mapper: {
    serializedName: "debtors",
    type: {
      name: "String"
    }
  }
};
export const defaulted: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "defaulted"
  ],
  mapper: {
    serializedName: "defaulted",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const details: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "details"
  ],
  mapper: {
    serializedName: "details",
    type: {
      name: "String"
    }
  }
};
export const doCheck: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "doCheck"
  ],
  mapper: {
    serializedName: "doCheck",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const documentId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "documentId"
  ],
  mapper: {
    serializedName: "documentId",
    type: {
      name: "String"
    }
  }
};
export const email0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "email"
  ],
  mapper: {
    serializedName: "email",
    type: {
      name: "Boolean"
    }
  }
};
export const email1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "email"
  ],
  mapper: {
    serializedName: "email",
    type: {
      name: "String"
    }
  }
};
export const employeeId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "employeeId"
  ],
  mapper: {
    serializedName: "employeeId",
    type: {
      name: "Uuid"
    }
  }
};
export const employeeId1: msRest.OperationURLParameter = {
  parameterPath: "employeeId",
  mapper: {
    required: true,
    serializedName: "employeeId",
    type: {
      name: "Uuid"
    }
  }
};
export const enable: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "enable"
  ],
  mapper: {
    serializedName: "enable",
    type: {
      name: "Boolean"
    }
  }
};
export const enabled0: msRest.OperationQueryParameter = {
  parameterPath: "enabled",
  mapper: {
    required: true,
    serializedName: "enabled",
    type: {
      name: "Boolean"
    }
  }
};
export const enabled1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "enabled"
  ],
  mapper: {
    serializedName: "enabled",
    type: {
      name: "Boolean"
    }
  }
};
export const endDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "endDate"
  ],
  mapper: {
    serializedName: "endDate",
    type: {
      name: "DateTime"
    }
  }
};
export const fileData: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "fileData"
  ],
  mapper: {
    serializedName: "fileData",
    type: {
      name: "Stream"
    }
  }
};
export const filename: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filename"
  ],
  mapper: {
    serializedName: "filename",
    type: {
      name: "String"
    }
  }
};
export const fileName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileName"
  ],
  mapper: {
    serializedName: "fileName",
    type: {
      name: "String"
    }
  }
};
export const fileType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileType"
  ],
  mapper: {
    serializedName: "fileType",
    type: {
      name: "String"
    }
  }
};
export const firstName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "firstName"
  ],
  mapper: {
    serializedName: "firstName",
    type: {
      name: "String"
    }
  }
};
export const funder0: msRest.OperationQueryParameter = {
  parameterPath: "funder",
  mapper: {
    required: true,
    serializedName: "funder",
    type: {
      name: "String"
    }
  }
};
export const funder1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "funder"
  ],
  mapper: {
    serializedName: "funder",
    type: {
      name: "String"
    }
  }
};
export const health: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "health"
  ],
  mapper: {
    serializedName: "health",
    type: {
      name: "String"
    }
  }
};
export const highDay: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "highDay"
  ],
  mapper: {
    serializedName: "highDay",
    type: {
      name: "Number"
    }
  }
};
export const host: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "host"
  ],
  mapper: {
    serializedName: "host",
    type: {
      name: "String"
    }
  }
};
export const id0: msRest.OperationQueryParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "Id",
    type: {
      name: "Uuid"
    }
  }
};
export const id1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "id"
  ],
  mapper: {
    serializedName: "Id",
    type: {
      name: "Uuid"
    }
  }
};
export const lastName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "lastName"
  ],
  mapper: {
    serializedName: "lastName",
    type: {
      name: "String"
    }
  }
};
export const lastRepayment: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "lastRepayment"
  ],
  mapper: {
    serializedName: "lastRepayment",
    type: {
      name: "DateTime"
    }
  }
};
export const legacy: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "legacy"
  ],
  mapper: {
    serializedName: "legacy",
    type: {
      name: "Boolean"
    }
  }
};
export const loanId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "loanId"
  ],
  mapper: {
    serializedName: "loanId",
    type: {
      name: "Uuid"
    }
  }
};
export const loanId1: msRest.OperationURLParameter = {
  parameterPath: "loanId",
  mapper: {
    required: true,
    serializedName: "loanId",
    type: {
      name: "Uuid"
    }
  }
};
export const loanId2: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "loanId"
  ],
  mapper: {
    serializedName: "loanId",
    defaultValue: '',
    type: {
      name: "String"
    }
  }
};
export const loanType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "loanType"
  ],
  mapper: {
    serializedName: "loanType",
    type: {
      name: "String"
    }
  }
};
export const local: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "local"
  ],
  mapper: {
    serializedName: "local",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const location: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "location"
  ],
  mapper: {
    serializedName: "location",
    type: {
      name: "String"
    }
  }
};
export const locTrailing: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "locTrailing"
  ],
  mapper: {
    serializedName: "locTrailing",
    type: {
      name: "Number"
    }
  }
};
export const lowDay: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "lowDay"
  ],
  mapper: {
    serializedName: "lowDay",
    type: {
      name: "Number"
    }
  }
};
export const message0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "message"
  ],
  mapper: {
    serializedName: "message",
    type: {
      name: "String"
    }
  }
};
export const message1: msRest.OperationQueryParameter = {
  parameterPath: "message",
  mapper: {
    required: true,
    serializedName: "message",
    type: {
      name: "String"
    }
  }
};
export const messageType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "messageType"
  ],
  mapper: {
    serializedName: "messageType",
    type: {
      name: "String"
    }
  }
};
export const mobile: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "mobile"
  ],
  mapper: {
    serializedName: "mobile",
    type: {
      name: "String"
    }
  }
};
export const name: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "name"
  ],
  mapper: {
    serializedName: "name",
    type: {
      name: "String"
    }
  }
};
export const newAmount: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "newAmount"
  ],
  mapper: {
    serializedName: "newAmount",
    type: {
      name: "Number"
    }
  }
};
export const newCustomerId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "newCustomerId"
  ],
  mapper: {
    serializedName: "newCustomerId",
    type: {
      name: "Uuid"
    }
  }
};
export const newdate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "newdate"
  ],
  mapper: {
    serializedName: "newdate",
    type: {
      name: "DateTime"
    }
  }
};
export const notes: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "notes"
  ],
  mapper: {
    serializedName: "notes",
    type: {
      name: "String"
    }
  }
};
export const notificationId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "notificationId"
  ],
  mapper: {
    serializedName: "notificationId",
    type: {
      name: "Uuid"
    }
  }
};
export const notificationId1: msRest.OperationURLParameter = {
  parameterPath: "notificationId",
  mapper: {
    required: true,
    serializedName: "notificationId",
    type: {
      name: "Uuid"
    }
  }
};
export const number: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "number"
  ],
  mapper: {
    serializedName: "number",
    type: {
      name: "String"
    }
  }
};
export const offerId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "offerId"
  ],
  mapper: {
    serializedName: "offerId",
    type: {
      name: "Uuid"
    }
  }
};
export const offerId1: msRest.OperationURLParameter = {
  parameterPath: "offerId",
  mapper: {
    required: true,
    serializedName: "offerId",
    type: {
      name: "Uuid"
    }
  }
};
export const oldCustomerId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "oldCustomerId"
  ],
  mapper: {
    serializedName: "oldCustomerId",
    type: {
      name: "Uuid"
    }
  }
};
export const onOffAuditParameter: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "onOffAuditParameter"
  ],
  mapper: {
    serializedName: "onOffAudit",
    type: {
      name: "Boolean"
    }
  }
};
export const organisationId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "organisationId"
  ],
  mapper: {
    serializedName: "organisationId",
    type: {
      name: "Uuid"
    }
  }
};
export const partnerId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "partnerId"
  ],
  mapper: {
    serializedName: "partnerId",
    type: {
      name: "Uuid"
    }
  }
};
export const partnerId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "partnerId"
  ],
  mapper: {
    serializedName: "PartnerId",
    type: {
      name: "Uuid"
    }
  }
};
export const partnerId2: msRest.OperationURLParameter = {
  parameterPath: "partnerId",
  mapper: {
    required: true,
    serializedName: "partnerId",
    type: {
      name: "Uuid"
    }
  }
};
export const password: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "password"
  ],
  mapper: {
    serializedName: "password",
    type: {
      name: "String"
    }
  }
};
export const paymentAmount: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "paymentAmount"
  ],
  mapper: {
    serializedName: "paymentAmount",
    type: {
      name: "Number"
    }
  }
};
export const paymentArrangementId: msRest.OperationQueryParameter = {
  parameterPath: "paymentArrangementId",
  mapper: {
    required: true,
    serializedName: "paymentArrangementId",
    type: {
      name: "Uuid"
    }
  }
};
export const paymentDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "paymentDate"
  ],
  mapper: {
    serializedName: "paymentDate",
    type: {
      name: "DateTime"
    }
  }
};
export const paymentId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "paymentId"
  ],
  mapper: {
    serializedName: "paymentId",
    type: {
      name: "Uuid"
    }
  }
};
export const paymentQueryId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "paymentQueryId"
  ],
  mapper: {
    serializedName: "paymentQueryId",
    type: {
      name: "Uuid"
    }
  }
};
export const paymentQueryId1: msRest.OperationURLParameter = {
  parameterPath: "paymentQueryId",
  mapper: {
    required: true,
    serializedName: "paymentQueryId",
    type: {
      name: "Uuid"
    }
  }
};
export const phone: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "phone"
  ],
  mapper: {
    serializedName: "phone",
    type: {
      name: "Boolean"
    }
  }
};
export const provider: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "provider"
  ],
  mapper: {
    serializedName: "provider",
    type: {
      name: "String"
    }
  }
};
export const providerType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "providerType"
  ],
  mapper: {
    serializedName: "providerType",
    type: {
      name: "Number"
    }
  }
};
export const publicToken: msRest.OperationQueryParameter = {
  parameterPath: "publicToken",
  mapper: {
    required: true,
    serializedName: "publicToken",
    type: {
      name: "String"
    }
  }
};
export const reason: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "reason"
  ],
  mapper: {
    serializedName: "reason",
    type: {
      name: "String"
    }
  }
};
export const reconciled: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "reconciled"
  ],
  mapper: {
    serializedName: "reconciled",
    type: {
      name: "Boolean"
    }
  }
};
export const repaymentId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "repaymentId"
  ],
  mapper: {
    serializedName: "repaymentId",
    type: {
      name: "Uuid"
    }
  }
};
export const requestId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "requestId"
  ],
  mapper: {
    serializedName: "requestId",
    type: {
      name: "Uuid"
    }
  }
};
export const revolving: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "revolving"
  ],
  mapper: {
    serializedName: "revolving",
    type: {
      name: "Boolean"
    }
  }
};
export const secured0: msRest.OperationQueryParameter = {
  parameterPath: "secured",
  mapper: {
    required: true,
    serializedName: "secured",
    type: {
      name: "Number"
    }
  }
};
export const secured1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "secured"
  ],
  mapper: {
    serializedName: "secured",
    type: {
      name: "Number"
    }
  }
};
export const securedTrailing: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "securedTrailing"
  ],
  mapper: {
    serializedName: "securedTrailing",
    type: {
      name: "Number"
    }
  }
};
export const sms: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sms"
  ],
  mapper: {
    serializedName: "sms",
    type: {
      name: "Boolean"
    }
  }
};
export const startDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "startDate"
  ],
  mapper: {
    serializedName: "startDate",
    type: {
      name: "DateTime"
    }
  }
};
export const status0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "status"
  ],
  mapper: {
    serializedName: "status",
    type: {
      name: "String"
    }
  }
};
export const status1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "status"
  ],
  mapper: {
    serializedName: "status",
    type: {
      name: "Boolean"
    }
  }
};
export const status2: msRest.OperationQueryParameter = {
  parameterPath: "status",
  mapper: {
    required: true,
    serializedName: "status",
    type: {
      name: "Boolean"
    }
  }
};
export const supportingDocumentId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "supportingDocumentId"
  ],
  mapper: {
    serializedName: "supportingDocumentId",
    type: {
      name: "Uuid"
    }
  }
};
export const syntheticPaymentId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "syntheticPaymentId"
  ],
  mapper: {
    serializedName: "syntheticPaymentId",
    type: {
      name: "Uuid"
    }
  }
};
export const sythenticTransactionId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "sythenticTransactionId"
  ],
  mapper: {
    serializedName: "sythenticTransactionId",
    type: {
      name: "Uuid"
    }
  }
};
export const title: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "title"
  ],
  mapper: {
    serializedName: "title",
    type: {
      name: "String"
    }
  }
};
export const trailing: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "trailing"
  ],
  mapper: {
    serializedName: "trailing",
    type: {
      name: "Number"
    }
  }
};
export const transactionId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "transactionId"
  ],
  mapper: {
    serializedName: "transactionId",
    type: {
      name: "Uuid"
    }
  }
};
export const unsecuredTrailing: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "unsecuredTrailing"
  ],
  mapper: {
    serializedName: "unsecuredTrailing",
    type: {
      name: "Number"
    }
  }
};
export const updateReconcilationStatus: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "updateReconcilationStatus"
  ],
  mapper: {
    serializedName: "updateReconcilationStatus",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const upfront: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "upfront"
  ],
  mapper: {
    serializedName: "upfront",
    type: {
      name: "Number"
    }
  }
};
export const upfrontFee: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "upfrontFee"
  ],
  mapper: {
    serializedName: "upfrontFee",
    type: {
      name: "Number"
    }
  }
};
export const uploadedFile: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "uploadedFile"
  ],
  mapper: {
    serializedName: "uploadedFile",
    type: {
      name: "Stream"
    }
  }
};
export const uploadedFiles: msRest.OperationParameter = {
  parameterPath: [
    "options",
    "uploadedFiles"
  ],
  mapper: {
    serializedName: "uploadedFiles",
    type: {
      name: "Sequence",
      element: {
        type: {
          name: "Object"
        }
      }
    }
  }
};
export const uri: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "uri"
  ],
  mapper: {
    serializedName: "uri",
    type: {
      name: "String"
    }
  }
};
export const url: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "url"
  ],
  mapper: {
    serializedName: "url",
    type: {
      name: "String"
    }
  }
};
export const user: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "user"
  ],
  mapper: {
    serializedName: "user",
    type: {
      name: "String"
    }
  }
};
export const username: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "username"
  ],
  mapper: {
    serializedName: "username",
    type: {
      name: "String"
    }
  }
};
export const weeklyrepayment: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "weeklyrepayment"
  ],
  mapper: {
    serializedName: "weeklyrepayment",
    type: {
      name: "Number"
    }
  }
};
export const yodlee: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "yodlee"
  ],
  mapper: {
    serializedName: "yodlee",
    type: {
      name: "Boolean"
    }
  }
};
