<div class="au-partner-section" *ngIf="urlService.checkUrlCom()">
  <section class="partner panel" (scroll)="onWindowScroll($event)">
    <div class="container d-flex">
      <div>
        <h1 class="partner__header white mb-60">Our Partners</h1>
        <h4 class="white">
          We understand good businesses can miss out on accessing the right
          finance solutions they needed to manage their cash flow effectively.
        </h4>
        <p class="white">
          You can now forge more powerful relationships with your clients,
          providing them with access to a finance solution engineered to replace
          complicated working capital products such as business loans,
          overdrafts, debtor and invoice finance and much more.
        </p>
      </div>
    </div>
  </section>

  <section class="partner-form panel" id="getstarted">
    <div class="container">
      <div>
        <h1 class="partner__header">Want to learn more?</h1>
        <h4 class="home__subheader partner__subheader">
          Leave your details below and we will get in touch to help you find a
          solution that matches your needs
        </h4>
        <form class="form" [formGroup]="partnerForm" (submit)="submit()">
          <div class="form-group">
            <input
              type="text"
              formControlName="firstname"
              class="form-input mt-10"
              placeholder="First name"
            />
            <input
              type="text"
              formControlName="lastname"
              class="form-input mt-10"
              placeholder="Last name"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              class="form-input mt-10"
              placeholder="Area code"
            />
            <input
              type="text"
              formControlName="phone"
              inputmode="tel"
              class="form-input mt-10"
              placeholder="Phone number"
            />
          </div>
          <div class="form-group">
            <input
              type="text"
              formControlName="businessName"
              class="form-input mt-10"
              placeholder="Business name"
            />
            <input
              type="text"
              formControlName="state"
              class="form-input mt-10"
              placeholder="State"
            />
          </div>
          <div class="form-group">
            <input
              type="email"
              formControlName="email"
              class="form-input mt-10"
              placeholder="Email address"
            />
            <input
              type="text"
              formControlName="role"
              class="form-input mt-10"
              placeholder="Role"
              required
            />

            <select formControlName="customertype"
            class="form-input mt-10"
            placeholder="Customer Type"
            required>
      <option disabled selected value="">Customer Type</option>
      <option value="New Customer">New Customer</option>
      <option value="Existing Customer">Existing Customer</option>
      <option value="Partner Enquiry">Partner Enquiry</option>
    </select>
          </div>

          <button
            type="submit"
            [disabled]="pendingPartner"
            class="btn btn-orange mt-40"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  </section>
</div>

<div class="us-partner-section" *ngIf="urlService.checkUrlCo()">
  <section class="hero panel" (scroll)="onWindowScroll($event)">
    <div class="container container-hero d-flex">
      <div>
        <h1
          id="hero-header-wrapper"
          class="hero__header hero__header_height mb-20"
          style="max-width: 750px"
        >
          <span
            id="hero-header"
            class="typing-element animate__animated animate__fadeIn"
          >
            Let’s disrupt
            <span>innovation together</span>
          </span>
        </h1>
        <span class="header-divider"></span>
        <p
          id="hero-text-2"
          class="hero__text animate__animated animate__fadeIn"
          style="font-size: 24px; margin-bottom: 80px"
        >
          Interested in working with Plexe as a direct referral partner or
          integrating a bespoke solution into your platform to assist your
          business clients
        </p>
        <div class="find-more" (click)="scrollToElement(target)">
          <a
            href="https://portal.plexe.com.au/public"
            style="font-size: 24px; color: white"
            *ngIf="urlService.checkUrlCom(); else domainIsCo"
            >Find out more</a
          >
          <ng-template #domainIsCo>
            <a style="font-size: 24px; color: white">Find out more</a>
          </ng-template>
          <i
            class="far fa-arrow-alt-circle-down find-out-more"
            style="font-size: 40px"
          ></i>
        </div>
      </div>
    </div>
  </section>

  <section
    class="details-1 panel"
    style="
      margin-top: 0;
      flex-direction: unset;
      min-height: auto;
      padding-bottom: 100px;
      padding-left: 130px;
      padding-right: 130px;
    "
  >
    <div class="d-flex">
      <div class="row">
        <div class="section-1 col-md-6">
          <p style="font-size: 18px">
            We are a financial technology partner providing our partners with
            access to an intelligent AI driven financial Super App they can now
            offer their small-medium business clients.
          </p>
          <p style="font-size: 18px">
            Our platform is modular based with adaptive API’s which are fully
            customisable by our partners and we can assist to manage and service
            any modules of your preference, from administration to credit risk
            and funding.
          </p>
        </div>
        <div class="section-2 col-md-6">
          <p style="font-size: 18px">
            Our proprietary data scraping and underwriting technology is
            designed to automate the entire lending process, enabling customers
            to be credit assessed and verified, documents signed, and clients
            onboarded within minutes.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="started-faster detail-3 panel" style="min-height: 500px">
    <div
      class="container started-faster-container d-flex"
      style="margin-top: 50px; margin-bottom: 50px"
    >
      <div style="color: white; max-width: 900px; margin: 0 auto">
        <p class="" style="text-align: center">
          <img src="../../../assets/svgs/settings.svg" alt="settings" />
        </p>
        <h1
          style="
            color: white;
            text-align: center;
            font-size: 36px;
            font-weight: bold;
            margin: 20px 0px 20px 0px;
          "
        >
          Get started faster
        </h1>
        <span class="detail-3-divider" style="margin: 30px auto"></span>
        <div class="d-flex">
          <div style="flex: 1">
            <p class="subtitle">Direct referral partners</p>
            <p style="color: white; margin-top: 0px; line-height: 20px">
              We offer an amazing referral program for our direct referral
              partner channels that have business clients in need of modern
              financial solutions.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="why-us detail-4 panel" #target>
    <div class="container why-us-container d-flex" id="getstarted">
      <div style="color: white; margin: 0 auto">
        <p class="" style="text-align: center; padding: 0">
          <img src="../../../assets/svgs/mail.svg" alt="mail" />
        </p>
        <h1 class="why-us__subtitle">Want to learn more?</h1>
        <span
          class="detail-4-divider"
          style="margin: 50px auto 0; color: rgba(1, 11, 50, 1)"
        ></span>
        <p
          id="hero-text-2"
          class="animate__animated animate__fadeIn mb-30 why-us__text"
        >
          Leave your details and we will get in touch to help you find a
          solution that meets your needs.
        </p>
        <div class="d-flex">
          <form
            class="form"
            style="margin: 0 auto"
            [formGroup]="pendingPartnerDetailForm"
            (submit)="submitPendingPartnerDetail()"
          >
            <div class="form-group" style="margin-bottom: 0">
              <input
                type="text"
                formControlName="firstname"
                class="form-input mt-10"
                placeholder="First name"
                required
              />
              <input
                type="text"
                formControlName="lastname"
                class="form-input mt-10"
                placeholder="Last name"
                required
              />
            </div>

            <input
              type="email"
              formControlName="email"
              class="form-input mt-10"
              placeholder="Email address"
              email="true"
              required
            />

            <input
              type="tel"
              formControlName="phone"
              inputmode="tel"
              class="form-input mt-10"
              placeholder="(___) ___-____"
              maxlength="14"
              pattern="[0-9]+"
              required
            />

            <input
              type="text"
              formControlName="company"
              class="form-input mt-10"
              placeholder="Company"
              required
            />

            <select formControlName="customertype"
                    class="form-input mt-10"
                    placeholder="Customer Type"
                    required>
              <option disabled selected value="">Customer Type</option>
              <option value="New Customer">New Customer</option>
              <option value="Existing Customer">Existing Customer</option>
              <option value="Partner Enquiry">Partner Enquiry</option>
            </select>

            <button
              type="submit"
              [disabled]="pendingPartner"
              class="btn btn-orange mt-40"
              style="margin: 0 auto; margin-top: 30px"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</div>
