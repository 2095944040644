/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import { ServiceClientOptions } from "@azure/ms-rest-js";
import * as msRest from "@azure/ms-rest-js";

/**
 * An interface representing SecondaryApplicantRequested.
 */
export interface SecondaryApplicantRequested {
  email?: string;
  mobile?: string;
  name?: string;
}

/**
 * An interface representing DrivingLicense.
 */
export interface DrivingLicense {
  issuingState?: string;
  cardNumber?: string;
  expiryDate?: Date;
  name?: string;
  address?: string;
  dateOfBirth?: Date;
  image?: Uint8Array;
}

/**
 * An interface representing PrimaryApplicant.
 */
export interface PrimaryApplicant {
  isManuallyAdded?: boolean;
  name?: string;
  driversLicense?: DrivingLicense;
  isPrimary?: boolean;
  email?: string;
  mobile?: string;
  applicationId?: string;
  miscellaneousData?: { [propertyName: string]: string };
}

/**
 * An interface representing PrimaryApplicantAndSecondaryApplicantRequested.
 */
export interface PrimaryApplicantAndSecondaryApplicantRequested {
  secondaryApplicantRequested?: SecondaryApplicantRequested;
  primaryApplicant?: PrimaryApplicant;
}

/**
 * An interface representing SecondaryApplicant.
 */
export interface SecondaryApplicant {
  isManuallyAdded?: boolean;
  name?: string;
  driversLicense?: DrivingLicense;
  isPrimary?: boolean;
  email?: string;
  mobile?: string;
  applicationId?: string;
  miscellaneousData?: { [propertyName: string]: string };
}

/**
 * An interface representing Applicant.
 */
export interface Applicant {
  name?: string;
  driversLicense?: DrivingLicense;
  isPrimary?: boolean;
  email?: string;
  mobile?: string;
  applicationId?: string;
  miscellaneousData?: { [propertyName: string]: string };
}

/**
 * An interface representing PlexeOrganisationServiceBankFeedAccount.
 */
export interface PlexeOrganisationServiceBankFeedAccount {
  selected?: boolean;
  name?: string;
  accountNumber?: string;
  bsb?: string;
  balance?: string;
  available?: string;
  accountHolder?: string;
  accountType?: string;
  slug?: string;
  enabled?: boolean;
  accountId?: string;
}

/**
 * An interface representing Acknowledgement.
 */
export interface Acknowledgement {
  acknowledged?: boolean;
  acknowledgementCode?: string;
}

/**
 * An interface representing StartApplicationRequest.
 */
export interface StartApplicationRequest {
  abn?: string;
  businessName?: string;
  tradingName?: string;
  applicationId?: string;
  customerId: string;
  partnerId?: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  acnType?: string;
  acnNumber?: string;
  acknowledgements: Acknowledgement[];
  extraInformation?: { [propertyName: string]: string };
}

/**
 * An interface representing Customer.
 */
export interface Customer {
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  businessName?: string;
  tradingName?: string;
  password?: string;
  type?: string;
  parentId?: string;
  dateOfBirth?: Date;
  hasLoggedIn?: boolean;
}

/**
 * An interface representing CreateApplicationAndCustomerRequest.
 */
export interface CreateApplicationAndCustomerRequest {
  application?: StartApplicationRequest;
  customer?: Customer;
}

/**
 * An interface representing ApplicationAndCustomerResponse.
 */
export interface ApplicationAndCustomerResponse {
  customerId?: string;
  applicationId?: string;
}

/**
 * An interface representing UpdateApplicationRequest.
 */
export interface UpdateApplicationRequest {
  applicationId: string;
  abn: string;
  businessName: string;
  tradingName?: string;
  firstName: string;
  lastName: string;
  mobile: string;
  email: string;
  offerId?: string;
}

/**
 * An interface representing Communication.
 */
export interface Communication {
  createdDateUtc?: Date;
  message: string;
  user: string;
}

/**
 * An interface representing ValidationError.
 */
export interface ValidationError {
  errorCode?: string;
  errorMessage?: string;
}

/**
 * An interface representing ApiResponseGuid.
 */
export interface ApiResponseGuid {
  errors?: ValidationError[];
  data?: string;
  success?: boolean;
  message?: string;
}

/**
 * An interface representing BankingData.
 */
export interface BankingData {
  bankName?: string;
  customerId?: string;
  password?: string;
  extraDetails?: { [propertyName: string]: string };
  yodlee?: boolean;
}

/**
 * An interface representing AccountingData.
 */
export interface AccountingData {
  provider?: string;
  tokens?: { [propertyName: string]: string };
}

/**
 * An interface representing PlexeOrganisationServiceModelsBankAccount.
 */
export interface PlexeOrganisationServiceModelsBankAccount {
  name?: string;
  accountNumber?: string;
  bsb?: string;
  balance?: string;
  available?: string;
  accountHolder?: string;
  accountType?: string;
}

/**
 * An interface representing Trust.
 */
export interface Trust {
  firstName?: string;
  surname?: string;
  isCompanyTrust?: boolean;
  acn?: string;
  companyName?: string;
}

/**
 * An interface representing CompleteContractRequest.
 */
export interface CompleteContractRequest {
  ipAddress?: string;
  signature?: Uint8Array;
  signature2?: Uint8Array;
  mimeType?: string;
  secondaryApplicant?: boolean;
}

/**
 * An interface representing Commission.
 */
export interface Commission {
  upfrontFee?: number;
  trailing?: number;
}

/**
 * An interface representing TransactionDataFile.
 */
export interface TransactionDataFile {
  data?: Uint8Array;
  fileName?: string;
  fileType?: string;
}

/**
 * An interface representing CardConfiguration.
 */
export interface CardConfiguration {
  limit?: number;
}

/**
 * An interface representing CardHolderDetails.
 */
export interface CardHolderDetails {
  firstName?: string;
  lastName?: string;
  email?: string;
  number?: number;
  limit?: number;
  suspended?: boolean;
}

/**
 * An interface representing CorprateCardHolder.
 */
export interface CorprateCardHolder {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  dateOfBirth?: Date;
  loanId?: string;
  cardId?: string;
  providerType?: number;
  aggregateId?: string;
  createdOnUtc?: Date;
  eventId?: string;
  jwtToken?: string;
  activityId?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly eventType?: string;
}

/**
 * An interface representing RegisterCustomerRequest.
 */
export interface RegisterCustomerRequest {
  customer?: Customer;
}

/**
 * An interface representing IFormFile.
 */
export interface IFormFile {
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly contentType?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly contentDisposition?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly headers?: { [propertyName: string]: string[] };
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly length?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly name?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly fileName?: string;
}

/**
 * An interface representing Amount.
 */
export interface Amount {
  value?: number;
  currencyCode?: string;
}

/**
 * An interface representing TransactionProjection.
 */
export interface TransactionProjection {
  overrideId?: string;
  loanId?: string;
  advanceId?: string;
  advanceName?: string;
  dateUTC?: Date;
  type?: string;
  details?: string;
  amount?: Amount;
  balance?: Amount;
  reconciled?: boolean;
  debtor?: string;
  isCredit?: boolean;
  bankReceipt?: string;
  force?: boolean;
}

/**
 * An interface representing ApiResponseListGuid.
 */
export interface ApiResponseListGuid {
  errors?: ValidationError[];
  data?: string[];
  success?: boolean;
  message?: string;
}

/**
 * An interface representing ApiResponseString.
 */
export interface ApiResponseString {
  errors?: ValidationError[];
  data?: string;
  success?: boolean;
  message?: string;
}

/**
 * An interface representing AdvanceProjection.
 */
export interface AdvanceProjection {
  loanId?: string;
  dateUTC?: Date;
  type?: string;
  details?: string;
  amount?: Amount;
  remaining?: Amount;
  nextRepaymentValue?: Amount;
  nextRepaymentDateUTC?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly actualNextRepaymentDateUTC?: Date;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly actualNextRepaymentDay?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly nextRepaymentDay?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRepaymentDay?: string;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly lastRepaymentDateUTC?: Date;
  totalRepaymentAmount?: number;
  weeklyPayment?: number;
  advanceRate?: number;
  terms?: number;
  priority?: boolean;
  percentage?: number;
  weeks?: number;
  /**
   * **NOTE: This property will not be serialized. It can only be populated by the server.**
   */
  readonly name?: string;
  earlyRepaid?: boolean;
  earlyRepaidDate?: Date;
  overrideLastRepaymentDateUTC?: Date;
  overrideLastRepayment?: boolean;
  legacy?: boolean;
  monthlyFee?: boolean;
  commission?: Commission;
  fixed?: boolean;
  fixedRepayment?: number;
  fixedRepaymentFee?: number;
}

/**
 * An interface representing ApiResponseListAdvanceProjection.
 */
export interface ApiResponseListAdvanceProjection {
  errors?: ValidationError[];
  data?: AdvanceProjection[];
  success?: boolean;
  message?: string;
}

/**
 * An interface representing CommissionRequest.
 */
export interface CommissionRequest {
  upfrontFee?: number;
  trailing?: number;
}

/**
 * An interface representing WithdrawPercentageRequest.
 */
export interface WithdrawPercentageRequest {
  amount?: number;
  percentage?: number;
  priority?: boolean;
  advanceRate?: number;
  nextRepaymentDate?: Date;
  weeks?: number;
  totalRepaymentAmount?: number;
  otp?: string;
  dateUTC?: Date;
  commission?: CommissionRequest;
}

/**
 * An interface representing ApiResponseAdvanceProjection.
 */
export interface ApiResponseAdvanceProjection {
  errors?: ValidationError[];
  data?: AdvanceProjection;
  success?: boolean;
  message?: string;
}

/**
 * An interface representing WithdrawInvoiceRequest.
 */
export interface WithdrawInvoiceRequest {
  dateUTC?: Date;
  amount?: number;
  priority?: boolean;
  advanceRate?: number;
  nextRepaymentDate?: Date;
  otp?: string;
  commission?: CommissionRequest;
}

/**
 * An interface representing WithdrawFixedRequest.
 */
export interface WithdrawFixedRequest {
  amount?: number;
  term?: number;
  priority?: boolean;
  weeklyPayment?: number;
  totalRepaymentAmount?: number;
  nextRepaymentDate?: Date;
  advanceRate?: number;
  otp?: string;
  dateUTC?: Date;
  commission?: CommissionRequest;
}

/**
 * An interface representing WithdrawLineOfCreditRequest.
 */
export interface WithdrawLineOfCreditRequest {
  amount?: number;
  priority?: boolean;
  percentageOfIncome?: number;
  weeklyPayment?: number;
  totalRepaymentAmount?: number;
  nextRepaymentDate?: Date;
  advanceRate?: number;
  otp?: string;
  dateUTC?: Date;
  commission?: CommissionRequest;
  fixed?: boolean;
  terms?: number;
  fixedRepaymentFee?: number;
  fixedRepayment?: number;
}

/**
 * An interface representing WithdrawCreditCardRequest.
 */
export interface WithdrawCreditCardRequest {
  amount?: number;
  priority?: boolean;
  percentageOfIncome?: number;
  weeklyPayment?: number;
  totalRepaymentAmount?: number;
  nextRepaymentDate?: Date;
  advanceRate?: number;
  otp?: string;
  dateUTC?: Date;
  commission?: CommissionRequest;
  fixed?: boolean;
  terms?: number;
  fixedRepaymentFee?: number;
  fixedRepayment?: number;
}

/**
 * An interface representing RepaymentAdjustmentProjection.
 */
export interface RepaymentAdjustmentProjection {
  loanId?: string;
  advanceId?: string;
  adjustmentType?: string;
  details?: string;
  amount?: number;
  transactionDateUTC?: Date;
}

/**
 * An interface representing CommissionSet.
 */
export interface CommissionSet {
  nonSecured?: Commission;
  secured?: Commission;
  lineOfCredit?: Commission;
}

/**
 * An interface representing BankDetails.
 */
export interface BankDetails {
  name?: string;
  bsb?: string;
  number?: string;
}

/**
 * An interface representing Notification.
 */
export interface Notification {
  createdOnUtc?: Date;
  message: string;
  read?: boolean;
  readOnUtc?: Date;
}

/**
 * An interface representing PaymentDetails.
 */
export interface PaymentDetails {
  type?: string;
  paymentId?: string;
  syntheticPaymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  transactionId?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * An interface representing ReconciliationDetails.
 */
export interface ReconciliationDetails {
  amount?: number;
  transactionId?: string;
  date?: Date;
  debtor?: string;
  details?: string;
  account?: string;
  type?: string;
}

/**
 * An interface representing PartnerCommission.
 */
export interface PartnerCommission {
  unsecuredTrail?: number;
  securedTrail?: number;
  locTrail?: number;
  upFrontFee?: number;
}

/**
 * An interface representing Partner.
 */
export interface Partner {
  email: string;
  firstName: string;
  lastName: string;
  businessName?: string;
  mobilePhoneNumber?: string;
  commission?: PartnerCommission;
  urlReferral?: string;
}

/**
 * An interface representing RegisterPartnerRequest.
 */
export interface RegisterPartnerRequest {
  partner?: Partner;
}

/**
 * An interface representing PartnerSendLinkRequest.
 */
export interface PartnerSendLinkRequest {
  firstName?: string;
  lastName?: string;
  email?: string;
  ein?: string;
  mobile?: string;
  createdDate?: Date;
}

/**
 * An interface representing PartnerOwnerDetails.
 */
export interface PartnerOwnerDetails {
  title?: string;
  firstName?: string;
  preferredName?: string;
  lastname?: string;
  cellNumber?: string;
  officeNumber?: string;
  email?: string;
  driversLicenceNumber?: string;
  stateIssued?: string;
  ssn?: string;
  dob?: Date;
}

/**
 * An interface representing PartnerBusinessDetails.
 */
export interface PartnerBusinessDetails {
  companyName?: string;
  companyAddress?: string;
  officeNumber?: string;
  webAddress?: string;
  accountPayableEmail?: string;
  optionFirst?: boolean;
  optionSecond?: boolean;
  optionThird?: boolean;
  optionFourth?: boolean;
}

/**
 * An interface representing PartnerAchDetail.
 */
export interface PartnerAchDetail {
  bankName?: string;
  accountType?: string;
  routingNumber?: string;
  accountNumber?: string;
}

/**
 * An interface representing PartnerCompanyDetail.
 */
export interface PartnerCompanyDetail {
  companyName?: string;
  name?: string;
  title?: string;
  date?: string;
  signature?: string;
}

/**
 * An interface representing PartnerAccreditationRequest.
 */
export interface PartnerAccreditationRequest {
  ownerDetails?: PartnerOwnerDetails;
  businessDetails?: PartnerBusinessDetails;
  achDetail?: PartnerAchDetail;
  companyDetail?: PartnerCompanyDetail;
}

/**
 * An interface representing SiteDetails.
 */
export interface SiteDetails {
  items?: { [propertyName: string]: string };
  name?: string;
  urls?: string[];
  partnerId?: string;
}

/**
 * An interface representing OriginationWriteApiOptions.
 */
export interface OriginationWriteApiOptions extends ServiceClientOptions {
  baseUri?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddPrimaryApplicantDetailsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: true.
   */
  doCheck?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddSecondaryApplicantDetailsOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: true.
   */
  doCheck?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSendSecondaryApplicantNoticeOptionalParams extends msRest.RequestOptionsBase {
  email?: boolean;
  phone?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateApplicantDetailsOptionalParams extends msRest.RequestOptionsBase {
  applicantId?: string;
  applicants?: Applicant;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatePartnerListOptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetPrimaryAccountOptionalParams extends msRest.RequestOptionsBase {
  account?: PlexeOrganisationServiceBankFeedAccount;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSendMessageOptionalParams extends msRest.RequestOptionsBase {
  messageType?: string;
  message?: { [propertyName: string]: string };
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSendSimpleMessageOptionalParams extends msRest.RequestOptionsBase {
  messageType?: string;
  message?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCreateApplicationOptionalParams extends msRest.RequestOptionsBase {
  startApplicationRequest?: StartApplicationRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCreateApplicationAndCustomerOptionalParams extends msRest.RequestOptionsBase {
  applicationAndCustomer?: CreateApplicationAndCustomerRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateCustomerOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  customerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiChangeStatusOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  status?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatePartnerOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateBusinessNameOptionalParams extends msRest.RequestOptionsBase {
  businessName?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateBusinessDetailsOptionalParams extends msRest.RequestOptionsBase {
  details?: { [propertyName: string]: string };
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateApplicationOptionalParams extends msRest.RequestOptionsBase {
  updateApplicationRequest?: UpdateApplicationRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateApplicationStatusOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  status?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateOfferOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  offerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateLoginStatusOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  status?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterCommunicationOptionalParams extends msRest.RequestOptionsBase {
  notification?: Communication;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCommunicationAddedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  notificationId?: string;
  message?: string;
  user?: string;
  createdOnUtc?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCancelApplicationOptionalParams extends msRest.RequestOptionsBase {
  reason?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSubmittedApplicationOptionalParams extends msRest.RequestOptionsBase {
  sms?: boolean;
  email?: boolean;
  comment?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiLinkBankingDataOptionalParams extends msRest.RequestOptionsBase {
  bankingData?: BankingData;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadBankStatementsDocumentIdOptionalParams extends msRest.RequestOptionsBase {
  documentId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiLinkAccoutingDataOptionalParams extends msRest.RequestOptionsBase {
  accountingData?: AccountingData;
  host?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCompletedOptionalParams extends msRest.RequestOptionsBase {
  sms?: boolean;
  email?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReferOptionalParams extends msRest.RequestOptionsBase {
  sms?: boolean;
  email?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConfirmBankAccountsOptionalParams extends msRest.RequestOptionsBase {
  bankAccountIds?: number[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConnectBankfeedAccountOptionalParams extends msRest.RequestOptionsBase {
  bankingData?: BankingData;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConfirmBankfeedAccountOptionalParams extends msRest.RequestOptionsBase {
  bankAccount?: PlexeOrganisationServiceModelsBankAccount;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConfirmBankfeedAccountsOptionalParams extends msRest.RequestOptionsBase {
  bankAccount?: PlexeOrganisationServiceModelsBankAccount[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSendApplicationOtpOptionalParams extends msRest.RequestOptionsBase {
  number?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSendLoginOtpOptionalParams extends msRest.RequestOptionsBase {
  email?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConfirmBankfeedMFADetailsOptionalParams extends msRest.RequestOptionsBase {
  fields?: { [propertyName: string]: string };
  uri?: string;
  yodlee?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddTrustDetailsOptionalParams extends msRest.RequestOptionsBase {
  trust?: Trust;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiContractCompleteOptionalParams extends msRest.RequestOptionsBase {
  request?: CompleteContractRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatedContractCompletedOptionalParams extends msRest.RequestOptionsBase {
  request?: CompleteContractRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiGenerateOfferOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: true.
   */
  accounting?: boolean;
  /**
   * Default value: true.
   */
  banking?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCommissionMethodOptionalParams extends msRest.RequestOptionsBase {
  commission?: Commission;
  secured?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConnectAccountingCompanyOptionalParams extends msRest.RequestOptionsBase {
  companyUri?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConnectAccountingCompanyWithDetailsOptionalParams extends msRest.RequestOptionsBase {
  reponseDetails?: { [propertyName: string]: string };
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiOfferCompletedOptionalParams extends msRest.RequestOptionsBase {
  offerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiOfferFailedOptionalParams extends msRest.RequestOptionsBase {
  offerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiBankingSystemConnectedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiBankfeedSystemConnectedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiBankingSystemFailedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiBankfeedSystemFailedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  message?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingSystemConnectedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingCompanySystemConnectedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  companies?: { [propertyName: string]: string };
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingCompanySystemConnectedWithDetailsOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  response?: { [propertyName: string]: string };
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingSystemFailedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiBankingSystemAccountsReadyOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiBankfeedSystemAccountsReadyOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiBankfeedSystemMFAReadyOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiOrganisationCreatedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiOrganisationFailedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiApplicationCanNotBeCompletedOptionalParams extends msRest.RequestOptionsBase {
  message?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiLoanCreatedOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingProcessedOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
  message?: string;
  count?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingProcessedErrorOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
  message?: string;
  url?: string;
  provider?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingQueueStartedOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAccountingQueueWaitOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiStatementProcessedOptionalParams extends msRest.RequestOptionsBase {
  organisationId?: string;
  message?: string;
  count?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiApplicationStatusChangedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  customerId?: string;
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiContractSignedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRefreshEquifaxOptionalParams extends msRest.RequestOptionsBase {
  applicantId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRefreshEquifaxFailedOptionalParams extends msRest.RequestOptionsBase {
  applicantId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRefreshIllionOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRefreshIllionFailedOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiApplicantIsReadyForEvaluationOptionalParams extends msRest.RequestOptionsBase {
  applicantId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateApplicationBusinessOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  message?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateIncomeOnReconnectOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddLoanCustomerOptionalParams extends msRest.RequestOptionsBase {
  customerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateLoanCustomerOptionalParams extends msRest.RequestOptionsBase {
  oldCustomerId?: string;
  newCustomerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRemoveLoanCustomerOptionalParams extends msRest.RequestOptionsBase {
  customerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRemovePartnerExternalLinkOptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateAuditDetailsOptionalParams extends msRest.RequestOptionsBase {
  auditType?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadLoanDocumentOptionalParams extends msRest.RequestOptionsBase {
  uploadedFile?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadLoanDocumentsAsByteArrayOptionalParams extends msRest.RequestOptionsBase {
  filename?: string;
  uploadedFile?: Uint8Array;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAuditCompleteOptionalParams extends msRest.RequestOptionsBase {
  notes?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiOnOffAuditOptionalParams extends msRest.RequestOptionsBase {
  onOffAuditParameter?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateAuditSettingsOptionalParams extends msRest.RequestOptionsBase {
  lowDay?: number;
  highDay?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAuditDocumentOptionalParams extends msRest.RequestOptionsBase {
  location?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetCorpratePasswordOptionalParams extends msRest.RequestOptionsBase {
  username?: string;
  password?: string;
  dateOfBirth?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetCardConfigurationOptionalParams extends msRest.RequestOptionsBase {
  configuration?: CardConfiguration;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddCardOptionalParams extends msRest.RequestOptionsBase {
  details?: CardHolderDetails;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateCardOptionalParams extends msRest.RequestOptionsBase {
  details?: CardHolderDetails;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRemoveCardOptionalParams extends msRest.RequestOptionsBase {
  cardId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSuspendCardOptionalParams extends msRest.RequestOptionsBase {
  cardId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnSuspendCardOptionalParams extends msRest.RequestOptionsBase {
  cardId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateCardConfigurationOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCreateCorprateOptionalParams extends msRest.RequestOptionsBase {
  details?: CorprateCardHolder;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiTestCustomerConnectionOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddCustomersOptionalParams extends msRest.RequestOptionsBase {
  customers?: RegisterCustomerRequest[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddCustomerOptionalParams extends msRest.RequestOptionsBase {
  customers?: RegisterCustomerRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiResetPassswordOptionalParams extends msRest.RequestOptionsBase {
  emailAddress?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiNotifyPasswordResetCompletedOptionalParams extends msRest.RequestOptionsBase {
  brokerEmail?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateCustomer1OptionalParams extends msRest.RequestOptionsBase {
  firstName?: string;
  lastName?: string;
  mobile?: string;
  email?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiDisableCustomerOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetUserRolesOptionalParams extends msRest.RequestOptionsBase {
  roles?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRemoveUserRolesOptionalParams extends msRest.RequestOptionsBase {
  roles?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCustomerRolesUpdatedOptionalParams extends msRest.RequestOptionsBase {
  customerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadDocumentOptionalParams extends msRest.RequestOptionsBase {
  filename?: string;
  uploadedFile?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadDocumentsOptionalParams extends msRest.RequestOptionsBase {
  uploadedFiles?: any[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetEmployeePasswordOptionalParams extends msRest.RequestOptionsBase {
  username?: string;
  password?: string;
  dateOfBirth?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiEnableEmployeeEarlyAccessOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiDisableEmployeeEarlyAccessOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiConnectOptionalParams extends msRest.RequestOptionsBase {
  providerType?: number;
  connectionData?: { [propertyName: string]: string };
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiDisconnectedOptionalParams extends msRest.RequestOptionsBase {
  providerType?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetEmployeeWithdrawDetailsOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
  details?: { [propertyName: string]: string };
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiMakeWithdrawRequestOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
  amount?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiEarlyAccessConnectionUpdatedOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiEmployeeStatusUpdatedOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiProcessFinchOptionalParams extends msRest.RequestOptionsBase {
  code?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateEmployeeDateOfBirthOptionalParams extends msRest.RequestOptionsBase {
  employeeId?: string;
  dateOfBirth?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetEmployeeRolesOptionalParams extends msRest.RequestOptionsBase {
  roles?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRemoveEmployeeRolesOptionalParams extends msRest.RequestOptionsBase {
  roles?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadBaseSupportDocumentOptionalParams extends msRest.RequestOptionsBase {
  name?: string;
  filename?: string;
  uploadedFile?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateBaseSupportDocumentForApplicationOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiOverrideContractOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  uploadedFile?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadW9SampleDocumentOptionalParams extends msRest.RequestOptionsBase {
  name?: string;
  uploadedFile?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateTransactionDateOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
  newdate?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateTransactionDebtorOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
  debtor?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterTransactionsSummaryOptionalParams extends msRest.RequestOptionsBase {
  request?: TransactionProjection[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnregisterTransactionSummaryOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterSyntheticPaymentSummaryOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterSyntheticPaymentFullOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
  debtor?: string;
  details?: string;
  amount?: number;
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconcileTransactionOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
  reconciled?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnregisterSyntheticPaymentOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
  debtor?: string;
  details?: string;
  amount?: number;
  dateParameter?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiDeleteSyntheticPaymentOptionalParams extends msRest.RequestOptionsBase {
  sythenticTransactionId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterAdvancesSummaryOptionalParams extends msRest.RequestOptionsBase {
  request?: AdvanceProjection[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnregisterAdvancesSummaryOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawStartDateOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  startDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateAdvanceDateOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  startDate?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawWeeklyRepaymentOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  weeklyrepayment?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawTrailingCommissionOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  trailing?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawLegacyOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  legacy?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawUpfrontFeeCommissionOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  upfrontFee?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateAdvanceLastRepaymentDateOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  lastRepayment?: Date;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawAsPercentageOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawPercentageRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawAsPercentageOverrideOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawPercentageRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawAsPercentageOverrideOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  request?: WithdrawPercentageRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawAsInvoicePaymentOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawInvoiceRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawAsInvoicePaymentOverrideOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawInvoiceRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawAsInvoicePaymentOverrideOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  request?: WithdrawInvoiceRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawFixedOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawFixedRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawFixedOverrideOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawFixedRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawFixedOverrideOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  request?: WithdrawFixedRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawLineOfCreditOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawLineOfCreditRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawLineOfCreditOverrideOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawLineOfCreditRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawLineOfCreditOverrideOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  request?: WithdrawLineOfCreditRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawCreditCardOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawCreditCardRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiWithdrawCreditCardOverrideOptionalParams extends msRest.RequestOptionsBase {
  request?: WithdrawCreditCardRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateWithdrawCreditCardOverrideOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  request?: WithdrawCreditCardRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterPaymentArrangementOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  startDate?: Date;
  endDate?: Date;
  amount?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiEarlyRepayAdvanceOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterRepaymentDetailsOptionalParams extends msRest.RequestOptionsBase {
  request?: RepaymentAdjustmentProjection[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterEarlyRepaymentDetailsOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterSkipRepaymentDetailsOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnregisterRepaymentDetailsOptionalParams extends msRest.RequestOptionsBase {
  repaymentId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddCommissionToAdvanceOptionalParams extends msRest.RequestOptionsBase {
  advanceId?: string;
  amount?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCommissionMethod1OptionalParams extends msRest.RequestOptionsBase {
  commissionSet?: CommissionSet;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiEnableOptionalParams extends msRest.RequestOptionsBase {
  enabled?: boolean;
  reason?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateLoanCustomerDetailsOptionalParams extends msRest.RequestOptionsBase {
  customerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateBusinessNameDetailsOptionalParams extends msRest.RequestOptionsBase {
  businessName?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateBalanceOverrideOptionalParams extends msRest.RequestOptionsBase {
  balanceOverride?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateLoanStatusOptionalParams extends msRest.RequestOptionsBase {
  health?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconnectAccountingDataOptionalParams extends msRest.RequestOptionsBase {
  accountingData?: AccountingData;
  host?: string;
  /**
   * Default value: ''.
   */
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReprocessAccountingDataCommandOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCloseOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: false.
   */
  defaulted?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSwitchLoanTypeOptionalParams extends msRest.RequestOptionsBase {
  loanType?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiArchivedOptionalParams extends msRest.RequestOptionsBase {
  /**
   * Default value: true.
   */
  archive?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateLoanPartnerDetailsOptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddChangeToFacilityOptionalParams extends msRest.RequestOptionsBase {
  newAmount?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCreateLoanOnApplicationIdOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
  funder?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterBankAccountOptionalParams extends msRest.RequestOptionsBase {
  bankDetails?: BankDetails;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdateRevolvingFacilityOptionalParams extends msRest.RequestOptionsBase {
  revolving?: boolean;
  /**
   * Default value: false.
   */
  local?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRegisterLoanNotificationOptionalParams extends msRest.RequestOptionsBase {
  notification?: Notification;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiEnableLoanSignalOptionalParams extends msRest.RequestOptionsBase {
  enable?: boolean;
  reason?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAuditInvoiceOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiDailyUpdateOnLoanOptionalParams extends msRest.RequestOptionsBase {
  loanId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconciliationAllPaymentsOptionalParams extends msRest.RequestOptionsBase {
  paymentDetails?: PaymentDetails[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconciliationPaymentOptionalParams extends msRest.RequestOptionsBase {
  paymentId?: string;
  syntheticPaymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconciliationRepaymentOptionalParams extends msRest.RequestOptionsBase {
  syntheticPaymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
  transactionId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconciliationTransfersOptionalParams extends msRest.RequestOptionsBase {
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconciliationExternalCreditOptionalParams extends msRest.RequestOptionsBase {
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiReconciliationInvoicePaymentOptionalParams extends msRest.RequestOptionsBase {
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
  transactionId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnreconciliationAllPaymentOptionalParams extends msRest.RequestOptionsBase {
  payments?: PaymentDetails[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnreconciliationPaymentOptionalParams extends msRest.RequestOptionsBase {
  paymentId?: string;
  syntheticPaymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnreconciliationRepaymentOptionalParams extends msRest.RequestOptionsBase {
  paymentId?: string;
  syntheticPaymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnreconciliationInvoicePaymentOptionalParams extends msRest.RequestOptionsBase {
  paymentId?: string;
  syntheticPaymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnreconciliationTransfersOptionalParams extends msRest.RequestOptionsBase {
  paymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUnreconciliationExternalCreditOptionalParams extends msRest.RequestOptionsBase {
  paymentId?: string;
  paymentQueryId?: string;
  amount?: number;
  debtor?: string;
  paymentDate?: Date;
  paymentAmount?: number;
  paymentReference?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCreateReconcilationRequestOptionalParams extends msRest.RequestOptionsBase {
  details?: ReconciliationDetails;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiCreateReconcilationMisdirectionOptionalParams extends msRest.RequestOptionsBase {
  details?: ReconciliationDetails;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiPaymentQueryStatusChangeOptionalParams extends msRest.RequestOptionsBase {
  status?: string;
  notes?: string;
  /**
   * Default value: true.
   */
  updateReconcilationStatus?: boolean;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAssignMisdirectionOptionalParams extends msRest.RequestOptionsBase {
  paymentQueryIds?: string[];
  transactionId?: string;
  dateParameter?: Date;
  details?: string;
  debtors?: string;
  amount?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAssignRequestOptionalParams extends msRest.RequestOptionsBase {
  transactionId?: string;
  dateParameter?: Date;
  details?: string;
  debtors?: string;
  amount?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatePartnersOptionalParams extends msRest.RequestOptionsBase {
  partners?: RegisterPartnerRequest[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddPartnersOptionalParams extends msRest.RequestOptionsBase {
  partners?: RegisterPartnerRequest[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatePartner1OptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
  partner?: RegisterPartnerRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatePartnerCommissionOptionalParams extends msRest.RequestOptionsBase {
  locTrailing?: number;
  unsecuredTrailing?: number;
  securedTrailing?: number;
  upfront?: number;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiResetPasswordOptionalParams extends msRest.RequestOptionsBase {
  emailAddress?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSendLinkOptionalParams extends msRest.RequestOptionsBase {
  partnerDetail?: PartnerSendLinkRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiResendSendLinkOptionalParams extends msRest.RequestOptionsBase {
  requestId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSendLinkForExistingOptionalParams extends msRest.RequestOptionsBase {
  applicationId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiDisablePartnerOptionalParams extends msRest.RequestOptionsBase {
  id?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiNotifyPasswordResetCompleted1OptionalParams extends msRest.RequestOptionsBase {
  brokerEmail?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddAccreditationOptionalParams extends msRest.RequestOptionsBase {
  partnerAccreditationDetail?: PartnerAccreditationRequest;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadW9FormOptionalParams extends msRest.RequestOptionsBase {
  uploadedFile?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadBankStatementOptionalParams extends msRest.RequestOptionsBase {
  uploadedFiles?: any[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUploadBlankInvoiceOptionalParams extends msRest.RequestOptionsBase {
  uploadedFiles?: any[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetPartnerRolesOptionalParams extends msRest.RequestOptionsBase {
  roles?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRemovePartnerRolesOptionalParams extends msRest.RequestOptionsBase {
  roles?: string[];
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatePartnerDailyReportEmailOptionalParams extends msRest.RequestOptionsBase {
  email?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiUpdatePartnerList1OptionalParams extends msRest.RequestOptionsBase {
  partnerId?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddSupportingDocumentOptionalParams extends msRest.RequestOptionsBase {
  supportingDocumentId?: string;
  fileData?: Uint8Array;
  fileType?: string;
  fileName?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddSupportingDocumentDirectlyOptionalParams extends msRest.RequestOptionsBase {
  fileData?: msRest.HttpRequestBody;
  fileType?: string;
  fileName?: string;
  details?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddSupportingDocumentDirectOptionalParams extends msRest.RequestOptionsBase {
  supportingDocumentId?: string;
  uploadedFile?: msRest.HttpRequestBody;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiAddRequestSupportingDocumentOptionalParams extends msRest.RequestOptionsBase {
  title?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiRemoveRequestSupportingDocumentOptionalParams extends msRest.RequestOptionsBase {
  title?: string;
}

/**
 * Optional Parameters.
 */
export interface OriginationWriteApiSetSiteOptionalParams extends msRest.RequestOptionsBase {
  details?: SiteDetails;
}

/**
 * Contains response data for the addPrimaryApplicantDetails operation.
 */
export type AddPrimaryApplicantDetailsResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the addSecondaryApplicantDetails operation.
 */
export type AddSecondaryApplicantDetailsResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the completeApplication operation.
 */
export type CompleteApplicationResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the createApplication operation.
 */
export type CreateApplicationResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the createApplicationAndCustomer operation.
 */
export type CreateApplicationAndCustomerResponse = ApplicationAndCustomerResponse & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApplicationAndCustomerResponse;
    };
};

/**
 * Contains response data for the updateCustomer operation.
 */
export type UpdateCustomerResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the changeStatus operation.
 */
export type ChangeStatusResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the updatePartner operation.
 */
export type UpdatePartnerResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateBusinessName operation.
 */
export type UpdateBusinessNameResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateBusinessDetails operation.
 */
export type UpdateBusinessDetailsResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateApplication operation.
 */
export type UpdateApplicationResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateApplicationStatus operation.
 */
export type UpdateApplicationStatusResponse = {
  /**
   * The parsed response body.
   */
  body: boolean;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: boolean;
    };
};

/**
 * Contains response data for the updateOffer operation.
 */
export type UpdateOfferResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateLoginStatus operation.
 */
export type UpdateLoginStatusResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the registerCommunication operation.
 */
export type RegisterCommunicationResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the sendApplicationOtp operation.
 */
export type SendApplicationOtpResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the generateOffer operation.
 */
export type GenerateOfferResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the addLoanCustomer operation.
 */
export type AddLoanCustomerResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateLoanCustomer operation.
 */
export type UpdateLoanCustomerResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the removeLoanCustomer operation.
 */
export type RemoveLoanCustomerResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the auditDocument operation.
 */
export type AuditDocumentResponse = TransactionDataFile & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: TransactionDataFile;
    };
};

/**
 * Contains response data for the testCustomerConnection operation.
 */
export type TestCustomerConnectionResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the addCustomers operation.
 */
export type AddCustomersResponse = Array<string> & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string[];
    };
};

/**
 * Contains response data for the addCustomer operation.
 */
export type AddCustomerResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the sendOtp operation.
 */
export type SendOtpResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateTransactionDate operation.
 */
export type UpdateTransactionDateResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateTransactionDebtor operation.
 */
export type UpdateTransactionDebtorResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerTransactionsSummary operation.
 */
export type RegisterTransactionsSummaryResponse = ApiResponseListGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseListGuid;
    };
};

/**
 * Contains response data for the unregisterTransactionSummary operation.
 */
export type UnregisterTransactionSummaryResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerSyntheticPaymentSummary operation.
 */
export type RegisterSyntheticPaymentSummaryResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerSyntheticPaymentFull operation.
 */
export type RegisterSyntheticPaymentFullResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the reconcileTransaction operation.
 */
export type ReconcileTransactionResponse = ApiResponseString & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseString;
    };
};

/**
 * Contains response data for the unregisterSyntheticPayment operation.
 */
export type UnregisterSyntheticPaymentResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the deleteSyntheticPayment operation.
 */
export type DeleteSyntheticPaymentResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerAdvancesSummary operation.
 */
export type RegisterAdvancesSummaryResponse = ApiResponseListAdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseListAdvanceProjection;
    };
};

/**
 * Contains response data for the unregisterAdvancesSummary operation.
 */
export type UnregisterAdvancesSummaryResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateWithdrawStartDate operation.
 */
export type UpdateWithdrawStartDateResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateAdvanceDate operation.
 */
export type UpdateAdvanceDateResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateWithdrawWeeklyRepayment operation.
 */
export type UpdateWithdrawWeeklyRepaymentResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateWithdrawTrailingCommission operation.
 */
export type UpdateWithdrawTrailingCommissionResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateWithdrawLegacy operation.
 */
export type UpdateWithdrawLegacyResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateWithdrawUpfrontFeeCommission operation.
 */
export type UpdateWithdrawUpfrontFeeCommissionResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateAdvanceLastRepaymentDate operation.
 */
export type UpdateAdvanceLastRepaymentDateResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawAsPercentage operation.
 */
export type WithdrawAsPercentageResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawAsPercentageOverride operation.
 */
export type WithdrawAsPercentageOverrideResponse = ApiResponseAdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseAdvanceProjection;
    };
};

/**
 * Contains response data for the updateWithdrawAsPercentageOverride operation.
 */
export type UpdateWithdrawAsPercentageOverrideResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawAsInvoicePayment operation.
 */
export type WithdrawAsInvoicePaymentResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawAsInvoicePaymentOverride operation.
 */
export type WithdrawAsInvoicePaymentOverrideResponse = ApiResponseAdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseAdvanceProjection;
    };
};

/**
 * Contains response data for the updateWithdrawAsInvoicePaymentOverride operation.
 */
export type UpdateWithdrawAsInvoicePaymentOverrideResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawFixed operation.
 */
export type WithdrawFixedResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawFixedOverride operation.
 */
export type WithdrawFixedOverrideResponse = ApiResponseAdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseAdvanceProjection;
    };
};

/**
 * Contains response data for the updateWithdrawFixedOverride operation.
 */
export type UpdateWithdrawFixedOverrideResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawLineOfCredit operation.
 */
export type WithdrawLineOfCreditResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawLineOfCreditOverride operation.
 */
export type WithdrawLineOfCreditOverrideResponse = ApiResponseAdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseAdvanceProjection;
    };
};

/**
 * Contains response data for the updateWithdrawLineOfCreditOverride operation.
 */
export type UpdateWithdrawLineOfCreditOverrideResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawCreditCard operation.
 */
export type WithdrawCreditCardResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the withdrawCreditCardOverride operation.
 */
export type WithdrawCreditCardOverrideResponse = ApiResponseAdvanceProjection & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseAdvanceProjection;
    };
};

/**
 * Contains response data for the updateWithdrawCreditCardOverride operation.
 */
export type UpdateWithdrawCreditCardOverrideResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerPaymentArrangement operation.
 */
export type RegisterPaymentArrangementResponse = ApiResponseString & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseString;
    };
};

/**
 * Contains response data for the unregisterPaymentArrangement operation.
 */
export type UnregisterPaymentArrangementResponse = ApiResponseString & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseString;
    };
};

/**
 * Contains response data for the earlyRepayAdvance operation.
 */
export type EarlyRepayAdvanceResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerRepaymentDetails operation.
 */
export type RegisterRepaymentDetailsResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerEarlyRepaymentDetails operation.
 */
export type RegisterEarlyRepaymentDetailsResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the registerSkipRepaymentDetails operation.
 */
export type RegisterSkipRepaymentDetailsResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the unregisterRepaymentDetails operation.
 */
export type UnregisterRepaymentDetailsResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the updateBusinessNameDetails operation.
 */
export type UpdateBusinessNameDetailsResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the updateBalanceOverride operation.
 */
export type UpdateBalanceOverrideResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the createLoanOnApplicationId operation.
 */
export type CreateLoanOnApplicationIdResponse = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the sendOtp1 operation.
 */
export type SendOtp1Response = {
  /**
   * The parsed response body.
   */
  body: string;

  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: string;
    };
};

/**
 * Contains response data for the registerLoanNotification operation.
 */
export type RegisterLoanNotificationResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the readLoanNotification operation.
 */
export type ReadLoanNotificationResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the createReconcilationRequest operation.
 */
export type CreateReconcilationRequestResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the createReconcilationMisdirection operation.
 */
export type CreateReconcilationMisdirectionResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the paymentQueryStatusChange operation.
 */
export type PaymentQueryStatusChangeResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the assignMisdirection operation.
 */
export type AssignMisdirectionResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};

/**
 * Contains response data for the assignRequest operation.
 */
export type AssignRequestResponse = ApiResponseGuid & {
  /**
   * The underlying HTTP response.
   */
  _response: msRest.HttpResponse & {
      /**
       * The response body as text (string format)
       */
      bodyAsText: string;

      /**
       * The response body as parsed JSON or XML
       */
      parsedBody: ApiResponseGuid;
    };
};
