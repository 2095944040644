/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from "@azure/ms-rest-js";

export const abn: msRest.OperationURLParameter = {
  parameterPath: "abn",
  mapper: {
    required: true,
    serializedName: "abn",
    type: {
      name: "String"
    }
  }
};
export const accountingPackage: msRest.OperationURLParameter = {
  parameterPath: "accountingPackage",
  mapper: {
    required: true,
    serializedName: "accountingPackage",
    type: {
      name: "String"
    }
  }
};
export const acn: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "acn"
  ],
  mapper: {
    serializedName: "acn",
    type: {
      name: "String"
    }
  }
};
export const advanceId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "advanceId"
  ],
  mapper: {
    serializedName: "AdvanceId",
    type: {
      name: "Uuid"
    }
  }
};
export const advanceId1: msRest.OperationURLParameter = {
  parameterPath: "advanceId",
  mapper: {
    required: true,
    serializedName: "advanceId",
    type: {
      name: "Uuid"
    }
  }
};
export const advanceId2: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "advanceId"
  ],
  mapper: {
    serializedName: "advanceId",
    type: {
      name: "Uuid"
    }
  }
};
export const amount0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "amount"
  ],
  mapper: {
    serializedName: "amount",
    type: {
      name: "Number"
    }
  }
};
export const amount1: msRest.OperationQueryParameter = {
  parameterPath: "amount",
  mapper: {
    required: true,
    serializedName: "amount",
    type: {
      name: "Number"
    }
  }
};
export const applicantId0: msRest.OperationURLParameter = {
  parameterPath: "applicantId",
  mapper: {
    required: true,
    serializedName: "applicantId",
    type: {
      name: "Uuid"
    }
  }
};
export const applicantId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "applicantId"
  ],
  mapper: {
    serializedName: "applicantId",
    type: {
      name: "Uuid"
    }
  }
};
export const applicationId0: msRest.OperationURLParameter = {
  parameterPath: "applicationId",
  mapper: {
    required: true,
    serializedName: "applicationId",
    type: {
      name: "Uuid"
    }
  }
};
export const applicationId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "applicationId"
  ],
  mapper: {
    serializedName: "applicationId",
    type: {
      name: "Uuid"
    }
  }
};
export const cardId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "cardId"
  ],
  mapper: {
    serializedName: "cardId",
    type: {
      name: "Uuid"
    }
  }
};
export const charList: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "charList"
  ],
  mapper: {
    serializedName: "charList",
    type: {
      name: "String"
    }
  }
};
export const commissionRate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "commissionRate"
  ],
  mapper: {
    serializedName: "commissionRate",
    type: {
      name: "Number"
    }
  }
};
export const criteria: msRest.OperationQueryParameter = {
  parameterPath: "criteria",
  mapper: {
    required: true,
    serializedName: "criteria",
    type: {
      name: "String"
    }
  }
};
export const customerId0: msRest.OperationURLParameter = {
  parameterPath: "customerId",
  mapper: {
    required: true,
    serializedName: "customerId",
    type: {
      name: "Uuid"
    }
  }
};
export const customerId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "customerId"
  ],
  mapper: {
    serializedName: "customerId",
    type: {
      name: "Uuid"
    }
  }
};
export const debtor: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "debtor"
  ],
  mapper: {
    serializedName: "debtor",
    type: {
      name: "String"
    }
  }
};
export const documentId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "documentId"
  ],
  mapper: {
    serializedName: "documentId",
    type: {
      name: "String"
    }
  }
};
export const documentId1: msRest.OperationURLParameter = {
  parameterPath: "documentId",
  mapper: {
    required: true,
    serializedName: "documentId",
    type: {
      name: "Uuid"
    }
  }
};
export const email: msRest.OperationURLParameter = {
  parameterPath: "email",
  mapper: {
    required: true,
    serializedName: "email",
    type: {
      name: "String"
    }
  }
};
export const employeeId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "employeeId"
  ],
  mapper: {
    serializedName: "employeeId",
    type: {
      name: "Uuid"
    }
  }
};
export const endDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "endDate"
  ],
  mapper: {
    serializedName: "endDate",
    type: {
      name: "DateTime"
    }
  }
};
export const establishmentFeeRate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "establishmentFeeRate"
  ],
  mapper: {
    serializedName: "establishmentFeeRate",
    type: {
      name: "Number"
    }
  }
};
export const excludeCreditCards: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "excludeCreditCards"
  ],
  mapper: {
    serializedName: "excludeCreditCards",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const fileName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileName"
  ],
  mapper: {
    serializedName: "fileName",
    type: {
      name: "String"
    }
  }
};
export const fileType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "fileType"
  ],
  mapper: {
    serializedName: "fileType",
    type: {
      name: "String"
    }
  }
};
export const filterColumn: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filterColumn"
  ],
  mapper: {
    serializedName: "filterColumn",
    type: {
      name: "String"
    }
  }
};
export const filterValue: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "filterValue"
  ],
  mapper: {
    serializedName: "filterValue",
    type: {
      name: "String"
    }
  }
};
export const fixedRepaymentCalculation: msRest.OperationQueryParameter = {
  parameterPath: "fixedRepaymentCalculation",
  mapper: {
    required: true,
    serializedName: "fixedRepaymentCalculation",
    type: {
      name: "Boolean"
    }
  }
};
export const force: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "force"
  ],
  mapper: {
    serializedName: "force",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const host: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "host"
  ],
  mapper: {
    serializedName: "host",
    type: {
      name: "String"
    }
  }
};
export const id0: msRest.OperationQueryParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "id",
    type: {
      name: "Uuid"
    }
  }
};
export const id1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "id"
  ],
  mapper: {
    serializedName: "Id",
    type: {
      name: "Uuid"
    }
  }
};
export const id2: msRest.OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    required: true,
    serializedName: "Id",
    type: {
      name: "Uuid"
    }
  }
};
export const includeCancelled: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeCancelled"
  ],
  mapper: {
    serializedName: "includeCancelled",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const includedClosed: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includedClosed"
  ],
  mapper: {
    serializedName: "includedClosed",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const includeDisabled: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "includeDisabled"
  ],
  mapper: {
    serializedName: "includeDisabled",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const invoiceId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "invoiceId"
  ],
  mapper: {
    serializedName: "invoiceId",
    type: {
      name: "Uuid"
    }
  }
};
export const loanId: msRest.OperationURLParameter = {
  parameterPath: "loanId",
  mapper: {
    required: true,
    serializedName: "loanId",
    type: {
      name: "Uuid"
    }
  }
};
export const loanType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "loanType"
  ],
  mapper: {
    serializedName: "loanType",
    type: {
      name: "Number"
    }
  }
};
export const location: msRest.OperationURLParameter = {
  parameterPath: "location",
  mapper: {
    required: true,
    serializedName: "location",
    type: {
      name: "String"
    }
  }
};
export const objectType: msRest.OperationQueryParameter = {
  parameterPath: "objectType",
  mapper: {
    required: true,
    serializedName: "objectType",
    type: {
      name: "String"
    }
  }
};
export const offerId0: msRest.OperationURLParameter = {
  parameterPath: "offerId",
  mapper: {
    required: true,
    serializedName: "offerId",
    type: {
      name: "Uuid"
    }
  }
};
export const offerId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "offerId"
  ],
  mapper: {
    serializedName: "offerId",
    type: {
      name: "Uuid"
    }
  }
};
export const onlyKnownDebtors: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "onlyKnownDebtors"
  ],
  mapper: {
    serializedName: "onlyKnownDebtors",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const onlyUnread: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "onlyUnread"
  ],
  mapper: {
    serializedName: "onlyUnread",
    type: {
      name: "Boolean"
    }
  }
};
export const orderBy0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderBy"
  ],
  mapper: {
    serializedName: "orderBy",
    type: {
      name: "String"
    }
  }
};
export const orderBy1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderBy"
  ],
  mapper: {
    serializedName: "orderBy",
    defaultValue: '',
    type: {
      name: "String"
    }
  }
};
export const orderBy2: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderBy"
  ],
  mapper: {
    serializedName: "orderBy",
    defaultValue: 'BusinessName',
    type: {
      name: "String"
    }
  }
};
export const orderbyDescending: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "orderbyDescending"
  ],
  mapper: {
    serializedName: "orderbyDescending",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const organisation: msRest.OperationURLParameter = {
  parameterPath: "organisation",
  mapper: {
    required: true,
    serializedName: "organisation",
    type: {
      name: "String"
    }
  }
};
export const organisationId0: msRest.OperationURLParameter = {
  parameterPath: "organisationId",
  mapper: {
    required: true,
    serializedName: "organisationId",
    type: {
      name: "Uuid"
    }
  }
};
export const organisationId1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "organisationId"
  ],
  mapper: {
    serializedName: "organisationId",
    type: {
      name: "Uuid"
    }
  }
};
export const otp: msRest.OperationQueryParameter = {
  parameterPath: "otp",
  mapper: {
    required: true,
    serializedName: "otp",
    type: {
      name: "String"
    }
  }
};
export const overrideCalculation: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "overrideCalculation"
  ],
  mapper: {
    serializedName: "overrideCalculation",
    defaultValue: false,
    type: {
      name: "Boolean"
    }
  }
};
export const partnerId0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "partnerId"
  ],
  mapper: {
    serializedName: "partnerId",
    type: {
      name: "Uuid"
    }
  }
};
export const partnerId1: msRest.OperationURLParameter = {
  parameterPath: "partnerId",
  mapper: {
    required: true,
    serializedName: "partnerId",
    type: {
      name: "Uuid"
    }
  }
};
export const password: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "password"
  ],
  mapper: {
    serializedName: "password",
    type: {
      name: "String"
    }
  }
};
export const percent: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "percent"
  ],
  mapper: {
    serializedName: "percent",
    type: {
      name: "Number"
    }
  }
};
export const percentageOfIncome: msRest.OperationQueryParameter = {
  parameterPath: "percentageOfIncome",
  mapper: {
    required: true,
    serializedName: "percentageOfIncome",
    type: {
      name: "Number"
    }
  }
};
export const providerType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "providerType"
  ],
  mapper: {
    serializedName: "providerType",
    type: {
      name: "Number"
    }
  }
};
export const requestId: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "requestId"
  ],
  mapper: {
    serializedName: "requestId",
    type: {
      name: "Uuid"
    }
  }
};
export const reset: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "reset"
  ],
  mapper: {
    serializedName: "reset",
    type: {
      name: "Boolean"
    }
  }
};
export const search: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "search"
  ],
  mapper: {
    serializedName: "search",
    type: {
      name: "String"
    }
  }
};
export const searchString: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "searchString"
  ],
  mapper: {
    serializedName: "searchString",
    type: {
      name: "String"
    }
  }
};
export const searchText: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "searchText"
  ],
  mapper: {
    serializedName: "searchText",
    type: {
      name: "String"
    }
  }
};
export const secured: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "secured"
  ],
  mapper: {
    serializedName: "secured",
    type: {
      name: "Number"
    }
  }
};
export const shortCode0: msRest.OperationURLParameter = {
  parameterPath: "shortCode",
  mapper: {
    required: true,
    serializedName: "shortCode",
    type: {
      name: "String"
    }
  }
};
export const shortCode1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "shortCode"
  ],
  mapper: {
    serializedName: "shortCode",
    type: {
      name: "String"
    }
  }
};
export const showAll: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "showAll"
  ],
  mapper: {
    serializedName: "showAll",
    defaultValue: true,
    type: {
      name: "Boolean"
    }
  }
};
export const skip0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "skip"
  ],
  mapper: {
    serializedName: "skip",
    defaultValue: 0,
    type: {
      name: "Number"
    }
  }
};
export const skip1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "skip"
  ],
  mapper: {
    serializedName: "skip",
    type: {
      name: "Number"
    }
  }
};
export const startDate: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "startDate"
  ],
  mapper: {
    serializedName: "startDate",
    type: {
      name: "DateTime"
    }
  }
};
export const status0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "status"
  ],
  mapper: {
    serializedName: "status",
    type: {
      name: "String"
    }
  }
};
export const status1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "status"
  ],
  mapper: {
    serializedName: "status",
    type: {
      name: "Number"
    }
  }
};
export const take0: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "take"
  ],
  mapper: {
    serializedName: "take",
    defaultValue: 2147483647,
    type: {
      name: "Number"
    }
  }
};
export const take1: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "take"
  ],
  mapper: {
    serializedName: "take",
    type: {
      name: "Number"
    }
  }
};
export const term: msRest.OperationQueryParameter = {
  parameterPath: "term",
  mapper: {
    required: true,
    serializedName: "term",
    type: {
      name: "Number"
    }
  }
};
export const terms: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "terms"
  ],
  mapper: {
    serializedName: "terms",
    type: {
      name: "Number"
    }
  }
};
export const typeName: msRest.OperationQueryParameter = {
  parameterPath: "typeName",
  mapper: {
    required: true,
    serializedName: "typeName",
    type: {
      name: "String"
    }
  }
};
export const url: msRest.OperationQueryParameter = {
  parameterPath: "url",
  mapper: {
    required: true,
    serializedName: "url",
    type: {
      name: "String"
    }
  }
};
export const urlReferral: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "urlReferral"
  ],
  mapper: {
    serializedName: "urlReferral",
    type: {
      name: "String"
    }
  }
};
export const username: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "username"
  ],
  mapper: {
    serializedName: "username",
    type: {
      name: "String"
    }
  }
};
export const userName: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "userName"
  ],
  mapper: {
    serializedName: "userName",
    type: {
      name: "String"
    }
  }
};
export const viewType: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "viewType"
  ],
  mapper: {
    serializedName: "viewType",
    defaultValue: 'All',
    type: {
      name: "String"
    }
  }
};
export const yodlee: msRest.OperationQueryParameter = {
  parameterPath: [
    "options",
    "yodlee"
  ],
  mapper: {
    serializedName: "yodlee",
    type: {
      name: "Boolean"
    }
  }
};
export const zipCode: msRest.OperationURLParameter = {
  parameterPath: "zipCode",
  mapper: {
    required: true,
    serializedName: "zipCode",
    type: {
      name: "Number"
    }
  }
};
