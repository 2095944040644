import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { finalize } from "rxjs/operators";
import { ApiService } from "../shared/services/api.service";
import { ToastrService } from "ngx-toastr";
import {UrlService} from '../shared/services/url.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  subscribeForm: FormGroup;

  pendingSubscribe = false;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private toastr: ToastrService,
    public urlService: UrlService
  ) { }

  ngOnInit(): void {
    this.initSubscribeForm();

  }

  private initSubscribeForm() {
    this.subscribeForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  subscribe() {
    console.log(this.subscribeForm.value);
    this.pendingSubscribe = true;
    if (this.subscribeForm.valid) {
      this.apiService.sendMessage('Subscribe', this.subscribeForm.value)
        .pipe(
          finalize(() => this.pendingSubscribe = false)
        )
        .subscribe(
          data => {
            this.toastr.success('Successfully subscribed');
          },
          error => {
            this.toastr.error('Server Error. Try again later');
          }
        )
    } else {
      this.toastr.error('Email is incorrect');
      this.pendingSubscribe = false;
    }
  }
}
