<section class="faq-hero">
  <div class="container faq-hero-container d-flex">
    <div>
      <h1 class="faq-hero__header white mb-60">
        Frequently Asked Questions
      </h1>
      <h4 >
        How can we help?
      </h4>
      <p>
        If you still have a question, there’s a good chance we’ve provided the answer below. Please get in touch if there are any we missed.
      </p>
    </div>
  </div>
</section>
<div class="faq">
  <div class="faq-sidebar">
    <nav class="faq-sidebar-navigation">
      <h3 *ngFor="let faq of faqs;"><a  class="faq__header"  [href]="'faq#' + faq.ref">{{faq.header}}</a></h3>
    </nav>
    <div class="faq-sidebar-contact">
      <h3 class="faq-sidebar-contact__title white">Have more questions?</h3>
      <p class="faq-sidebar-contact__text white">We are ready to answer all your enquiries, all you need to do is to contact us.</p>
      <a class="faq-sidebar-contact__phone d-block" href="tel:1300375057">1300 375 057</a>
      <a class="faq-sidebar-contact__email d-block mb-30" href="mail:team@plexe.com.au">team@plexe.com.au</a>
      <button class="btn btn-orange float-right">Contact us</button>
    </div>

  </div>
  <div class="faq-container">
    <div class="faq-wrapper" *ngFor="let faq of faqs; let i = index" >
     <a name="{{faq.ref}}">&nbsp;</a>
      <h3 class="faq__header">{{faq.header}}</h3>
      <div class="faq__card">
        <b class="faq__button" (click)="toggleAccordion($event, i)">{{faq.question}}</b>

        <div class="panel">
          <p [innerHTML]="faq.answer" class="faq__text mb-0" hide="!faq.isActive"></p>
          <p  class="spacing-last"></p>
        </div>
      </div>
    </div>
  </div>
</div>
