<footer class="footer" *ngIf="urlService.checkUrlCom()">
  <div class="footer-top">
    <div class="container footer-container">
      <div class="logo logo_white">
        <svg xmlns="http://www.w3.org/2000/svg" width="113" height="46">
          <path
            fill="#FFF"
            d="M15.1 9.3c-3.9 0-6.9 1.8-8 3.8V9.9H.2V46h7.1V33.5c1.3 1.8 3.9 3.2 7.6 3.2 7.4 0 12.4-5.9 12.4-13.8-.1-7.7-4.5-13.6-12.2-13.6zm-1.4 21.1c-3.6 0-6.6-2.8-6.6-7.4 0-4.6 2.9-7.3 6.6-7.3s6.5 2.7 6.5 7.3c0 4.7-2.8 7.4-6.5 7.4zM29.6 0h7.1v36.1h-7.1zM58.6 28.1c-1.2 1.3-2.9 2.7-6 2.7-3.5 0-6.4-2.5-6.6-5.3h18.7c0-.8 1.7-16.4-12.8-16.4-6.7 0-12.9 5.4-12.9 13.8 0 8.8 6.3 14 13.5 14 5 0 8.6-2.3 10.6-4.8l-4.5-4zm-6.5-13.2c4 0 5.8 2.6 5.9 5.6H46.2c.2-2.9 2.2-5.6 5.9-5.6zM99.9 9.1C93.2 9.1 87 14.5 87 22.9c0 8.8 6.3 14 13.5 14 5 0 8.6-2.3 10.6-4.8l-4.6-3.9c-1.2 1.3-2.9 2.7-6 2.7-3.5 0-6.4-2.5-6.6-5.3h18.7c.1-.8 1.8-16.5-12.7-16.5zm-5.8 11.3c.2-2.8 2.2-5.6 5.9-5.6 4 0 5.8 2.6 5.9 5.6H94.1z"
          />
          <path fill="#FFF" d="M66.5 9.9h-1.2L74.6 23l-9.3 13.1h1.2L75.8 23z" />
          <path fill="#FFF" d="M68.9 9.9h-1.2L77 23l-9.3 13.1h1.1L78.2 23z" />
          <path fill="#FFF" d="M64.1 9.9h-1.2L72.3 23l-9.4 13.1h1.2L73.4 23z" />
          <path
            fill="#FFF"
            d="M89.9 9.9h-8.3l-5.2 7.3-5.2-7.3H70L79.4 23 70 36.1h1.2l5.2-7.3 5.2 7.3h8.3L80.6 23z"
          />
        </svg>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container footer-container">
      <div class="footer-bottom-form" [formGroup]="subscribeForm">
        <h4>Subscribe to our news</h4>
        <p class="mt-2 mb-2">
          Be in the know and get industry news first. We promise we won't bother
          you too often.
        </p>
        <input
          type="email"
          formControlName="email"
          class="form-input"
          placeholder="email"
        />
        <button
          type="submit"
          (click)="subscribe()"
          [disabled]="pendingSubscribe"
          class="btn btn-orange mt-2"
        >
          Subscribe
        </button>
      </div>
      <div class="footer-bottom-info">
        <div class="footer-bottom-info_top">
          <div
            class="
              footer-bottom-info_top-item footer-bottom-info_top-item_first
            "
          >
            <h4>About Us</h4>
            <ul>
              <li>
                <a routerLink="our-story">About Us</a>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>

          <div class="footer-bottom-info_top-item">
            <h4>Legal</h4>
            <ul>
              <li>
                <a routerLink="terms">Terms of Use</a>
              </li>
              <li>
                <a routerLink="privacy">Privacy and Credit</a>
              </li>
            </ul>
          </div>
          <div
            class="footer-bottom-info_top-item footer-bottom-info_top-item_last"
          >
            <h4>Follow Us</h4>
            <ul>
              <li>
                <!--  <a href="" class="d-flex align-items-center"><i class="icon"><svg aria-hidden="true" focusable="false"
                      data-prefix="fab" data-icon="linkedin" role="img" xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512" class="svg-inline--fa fa-linkedin fa-w-14 fa-3x">
                      <path
                        d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z">
                      </path>
                    </svg></i>LinkedIn</a>-->
              </li>
              <li>
                <a
                href="https://www.linkedin.com/company/plexe/"
                class="d-flex align-items-center"
                style="font-size: 14px"
              >
                <img
                  src="../../assets/svgs/linkedin.svg"
                  alt="linkedin"
                  style="margin-right: 34px;  width: 18px"
                />
                LinkedIn
              </a>
              </li>
              <li>
              <!--     <a
                  href="https://www.instagram.com/_plexe_"
                  class="d-flex align-items-center"
                  ><i class="icon"
                    ><svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="instagram-square"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      class="svg-inline--fa fa-instagram-square fa-w-14 fa-3x"
                    >
                      <path
                        d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"
                        class=""
                      ></path></svg></i
                  >Instagram</a
                >-->
              </li>
            </ul>
          </div>
        </div>
        <div class="footer-bottom-info_bottom">
          <h4>
            Copyright © 2022 Plexe<ng-container
              *ngIf="urlService.checkUrlCom()"
            >
              | ABN: 628 916 245</ng-container
            >
          </h4>
          <p style="font-weight: 300">
            Full Terms and Conditions available on application. Loan amount and
            approval time may vary. Plexe® is a Registered Trademark. All rights
            reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</footer>

<div class="footer-us footer" *ngIf="urlService.checkUrlCo()">
  <div class="footer-content row">
    <div
      class="footer-section section-first col-lg-5 col-12 pl-0 mt-10 mt-sm-0"
    >
      <h4 style="font-weight: bold">
        Copyright © 2022 Plexe, LLC<ng-container
          *ngIf="urlService.checkUrlCom()"
        >
          | ABN: 628 916 245</ng-container
        >
      </h4>
      <p style="font-size: 12px; font-weight: 300">
        Full Terms and Conditions available on application. Loan amount and
        approval time may vary. Plexe® is a Registered Trademark. All rights
        reserved.
      </p>
    </div>
    <div
      class="footer-section section-second col-lg-2 col-12 pl-0 mt-10 mt-sm-0"
    >
      <ul style="line-height: 27px">
        <li>
          <a routerLink="our-story" style="font-size: 14px">About Us</a>
        </li>
        <li>
          <a routerLink="terms" style="font-size: 14px">Terms of Use</a>
        </li>
        <li>
          <a routerLink="privacy" style="font-size: 14px">Privacy and Credit</a>
        </li>
       
      </ul>
    </div>
    <div
      class="footer-section section-third col-lg-2 col-12 pl-0 mt-10 mt-sm-0"
      style="margin-bottom: 5px"
    >
      <ul>
        <li>
          <a routerLink="our-story"></a>
        </li>
        <li>
          <a href="https://app.termly.io/notify/364e98dc-0710-4e4c-8480-ab11fca629a8" style="font-size: 14px" id="termly-consent-preferences">DSAR form</a>
        </li>
        <li>
          <a href="#" style="font-size: 14px" onclick="window.displayPreferenceModal();return false;" id="termly-consent-preferences">Consent Preferences</a>
        </li>
        <li style="margin-top: 15px">
           <!--<a
            href="https://www.instagram.com/_plexe_"
            class="d-flex align-items-center"
            style="font-size: 14px"
          >
            <img
              src="../../assets/svgs/instagram.svg"
              alt="instagram"
              style="margin-right: 25px"
            />
            Instagram
          </a>-->
        </li>
      </ul>
    </div>
    <div
      class="footer-section section-fourth col-lg-3 col-12 pl-0 mt-10 mt-sm-0"
      style="margin-bottom: 15px"
    > <!-- 
      <h4 class="span-text">Subscribe to our news</h4>
      <form>
        <div class="email-form form-row">
         <div
            class="col-8 mr-0 email-input"
            [formGroup]="subscribeForm"
            style="padding: 0"
          >
            <input
              type="email"
              formControlName="email"
              class="form-input form-control"
              placeholder="email"
            />
            <button
              type="submit"
              (click)="subscribe()"
              [disabled]="pendingSubscribe"
              class="btn-orange submit-btn"
            >
              OK
            </button>
          </div>
        </div>
      </form>-->
      <p class="info-text">
        Customer support: (855) 230-7755 General support: team@plexe.co
      </p>
    </div>
  </div>
</div>
