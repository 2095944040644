<section class="accreditation panel" (scroll)="onWindowScroll($event)">
  <div class="container d-flex">
    <div>
      <h1 class="accreditation__header white mb-60">Accreditation</h1>
      <span class="header-divider"></span>
      <p class="accreditation__text">
        We understand good businesses can miss out on accessing the right
        finance solutions they needed to manage their cash flow effectively.
      </p>
      <p class="accreditation__text">
        You can now forge more powerful relationships with your clients,
        providing them with access to a finance solution engineered to replace
        complicated working capital products such as business loans, overdrafts,
        debtor and invoice finance and much more.
      </p>
    </div>
  </div>
</section>
<section class="accreditation-form panel">
  <div class="container">
    <form
      class="form"
      [formGroup]="accreditationForm"
      (submit)="submit()"
      novalidate
    >
      <div class="accreditation-form-section" formGroupName="ownerDetails">
        <h4 class="home__subheader accreditation__subheader">Owner Details</h4>
        <span class="section-divider"></span>
        <p class="subtitle">Fill owner details below.</p>
        <div class="form-input-container">
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                formControlName="title"
                class="form-input"
                placeholder="Title"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls['title']
                    .valid
                "
              />
              <span hidden translate>Title</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls['title']
                    .valid ||
                  accreditationForm?.controls['ownerDetails'].controls['title']
                    .untouched
                "
                class="text-danger"
                translate
              >
                Title is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                formControlName="firstName"
                class="form-input"
                placeholder="First Name"
              />

              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'firstName'
                  ].valid
                "
              />
              <span hidden translate>FirstName</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'firstName'
                  ].valid ||
                  accreditationForm?.controls['ownerDetails'].controls[
                    'firstName'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                First Name is required
              </small>
            </div>
          </div>
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                formControlName="preferredName"
                class="form-input"
                placeholder="Preferred Name"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'preferredName'
                  ].valid
                "
              />
              <span hidden translate>Preferred Name</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'preferredName'
                  ].valid ||
                  accreditationForm?.controls['ownerDetails'].controls[
                    'preferredName'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Preferred Name is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                formControlName="lastname"
                class="form-input"
                placeholder="Last Name"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'lastname'
                  ].valid
                "
              />
              <span hidden translate>Last Name</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'lastname'
                  ].valid ||
                  accreditationForm?.controls['ownerDetails'].controls[
                    'lastname'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Last Name is required
              </small>
            </div>
          </div>
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                formControlName="cellNumber"
                class="form-input"
                numeric
                numericType="number"
                maxlength="14"
                placeholder="Cell Number"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'cellNumber'
                  ].valid
                "
              />
              <span hidden translate>Cell Number</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'cellNumber'
                  ].valid ||
                  accreditationForm?.controls['ownerDetails'].controls[
                    'cellNumber'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Cell Number is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                numeric
                numericType="number"
                maxlength="14"
                formControlName="officeNumber"
                inputmode="tel"
                class="form-input"
                placeholder="Office Number"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'officeNumber'
                  ].valid
                "
              />
              <span hidden translate>Office Number</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'officeNumber'
                  ].valid ||
                  accreditationForm?.controls['ownerDetails'].controls[
                    'officeNumber'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Office Number is required
              </small>
            </div>
          </div>
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                formControlName="email"
                class="form-input"
                pattern="^[\w\.=-]+@[\w\.-]+\.[\w]{2,3}$"
                placeholder="Email Address"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls['email']
                    .valid
                "
              />
              <span hidden translate>Email Address</span>
              <div
                class=""
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls['email']
                    .valid ||
                  accreditationForm?.controls['ownerDetails'].controls['email']
                    .untouched
                "
              >
                <small
                  *ngIf="
                    accreditationForm?.controls['ownerDetails'].controls[
                      'email'
                    ].errors &&
                    accreditationForm?.controls['ownerDetails'].controls[
                      'email'
                    ].errors.required
                  "
                  class="text-danger"
                  translate
                >
                  Email Address is required
                </small>
                <small
                  *ngIf="
                    accreditationForm?.controls['ownerDetails'].controls[
                      'email'
                    ].errors &&
                    accreditationForm?.controls['ownerDetails'].controls[
                      'email'
                    ].errors.pattern
                  "
                  class="text-danger"
                  translate
                >
                  Email Address is invalid
                </small>
              </div>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                numeric
                numericType="number"
                maxlength="9"
                minlength="6"
                formControlName="driversLicenceNumber"
                class="form-input"
                placeholder="Drivers Licence Number"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'driversLicenceNumber'
                  ].valid
                "
              />
              <span hidden translate>Drivers Licence Number</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'driversLicenceNumber'
                  ].valid ||
                  accreditationForm?.controls['ownerDetails'].controls[
                    'driversLicenceNumber'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Drivers Licence Number is required
              </small>
            </div>
          </div>
          <div class="form-input-row">
            <div class="form-group-field">
              <select class="form-input" formControlName="stateIssued">
                <option [ngValue]="null" disabled selected>State Issued</option>
                <option *ngFor="let state of states" [ngValue]="state.Value">
                  {{ state.Text }}
                </option>
              </select>
              <!--<input type="text"
             formControlName="stateIssued"
             class="form-input "
             placeholder="State Issued">-->
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                style="right: 17px"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'stateIssued'
                  ].valid
                "
              />
              <span hidden translate>State Issued</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls[
                    'stateIssued'
                  ].valid ||
                  accreditationForm?.controls['ownerDetails'].controls[
                    'stateIssued'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                State Issued is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                numeric
                numericType="number"
                formControlName="ssn"
                maxlength="9"
                class="form-input"
                placeholder="Social Security Number"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['ownerDetails'].controls['ssn']
                    .valid
                "
              />
              <span hidden translate>Social Security Number</span>
              <small
                [hidden]="
                  accreditationForm?.controls['ownerDetails'].controls['ssn']
                    .valid ||
                  accreditationForm?.controls['ownerDetails'].controls['ssn']
                    .untouched
                "
                class="text-danger"
                translate
              >
                Social Security Number is required
              </small>
            </div>
          </div>
          <div class="form-group-field">
            {{ formattedDob }}
            <input
              type="text"
              class="form-input"
              formControlName="dob"
              ngbDatepicker
              #dob="ngbDatepicker"
              (click)="dob.open()"
              (input)="formatDobString()"
              [value]="formattedDob"
              [autoClose]="true"
              [minDate]="{ year: 1920, month: 1, day: 1 }"
              [maxDate]="{ year: 2002, month: 12, day: 31 }"
              [placeholder]="isUSRegion ? 'mm/dd/yyyy' : 'dd/mm/yyyy'"
            />
            <!--<input type="text"
    formControlName="dob"
    class="form-input"
    placeholder="Date Of Birth">-->
            <img
              class="check-valid position-absolute"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="
                accreditationForm?.controls['ownerDetails'].controls['dob']
                  .valid
              "
            />
            <span hidden translate>Date Of Birth</span>
            <small
              [hidden]="
                accreditationForm?.controls['ownerDetails'].controls['dob']
                  .valid ||
                accreditationForm?.controls['ownerDetails'].controls['dob']
                  .untouched
              "
              class="text-danger"
              translate
            >
              Date Of Birth is required
            </small>
          </div>
        </div>
      </div>

      <div class="accreditation-form-section" formGroupName="businessDetails">
        <h4 class="home__subheader accreditation__subheader">
          Business Details
        </h4>
        <span class="section-divider"></span>
        <p class="subtitle">
          (B2B Partners or Independent Sales Organizations)
        </p>
        <div class="form-input-container">
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                formControlName="companyName"
                class="form-input"
                placeholder="Company Name"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['businessDetails'].controls[
                    'companyName'
                  ].valid
                "
              />
              <span hidden translate>Company Name</span>
              <small
                [hidden]="
                  accreditationForm?.controls['businessDetails'].controls[
                    'companyName'
                  ].valid ||
                  accreditationForm?.controls['businessDetails'].controls[
                    'companyName'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Company Name is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                formControlName="companyAddress"
                class="form-input"
                placeholder="Company Address"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['businessDetails'].controls[
                    'companyAddress'
                  ].valid
                "
              />
              <span hidden translate>Company Address</span>
              <small
                [hidden]="
                  accreditationForm?.controls['businessDetails'].controls[
                    'companyAddress'
                  ].valid ||
                  accreditationForm?.controls['businessDetails'].controls[
                    'companyAddress'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Company Address is required
              </small>
            </div>
          </div>
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                numeric
                numericType="number"
                maxlength="14"
                formControlName="officeNumber"
                class="form-input"
                placeholder="Office Number"
              />

              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['businessDetails'].controls[
                    'officeNumber'
                  ].valid
                "
              />
              <span hidden translate>Office Number</span>
              <small
                [hidden]="
                  accreditationForm?.controls['businessDetails'].controls[
                    'officeNumber'
                  ].valid ||
                  accreditationForm?.controls['businessDetails'].controls[
                    'officeNumber'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Office Number is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                formControlName="webAddress"
                class="form-input"
                placeholder="Web Address"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['businessDetails'].controls[
                    'webAddress'
                  ].valid
                "
              />
              <span hidden translate>Web Address</span>
              <small
                [hidden]="
                  accreditationForm?.controls['businessDetails'].controls[
                    'webAddress'
                  ].valid ||
                  accreditationForm?.controls['businessDetails'].controls[
                    'webAddress'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Web Address is required
              </small>
            </div>
          </div>
          <div class="form-group-field">
            <input
              type="text"
              formControlName="accountPayableEmail"
              pattern="^[\w\.=-]+@[\w\.-]+\.[\w]{2,3}$"
              inputmode="email"
              class="form-input"
              placeholder="Account Payable Email"
            />
            <img
              class="check-valid position-absolute"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="
                accreditationForm?.controls['businessDetails'].controls[
                  'accountPayableEmail'
                ].valid
              "
            />
            <span hidden translate>Account Payable Email</span>
            <div
              class=""
              [hidden]="
                accreditationForm?.controls['businessDetails'].controls[
                  'accountPayableEmail'
                ].valid ||
                accreditationForm?.controls['businessDetails'].controls[
                  'accountPayableEmail'
                ].untouched
              "
            >
              <small
                *ngIf="
                  accreditationForm?.controls['businessDetails'].controls[
                    'accountPayableEmail'
                  ].errors &&
                  accreditationForm?.controls['businessDetails'].controls[
                    'accountPayableEmail'
                  ].errors.required
                "
                class="text-danger"
                translate
              >
                Account Payable Email Address is required
              </small>
              <small
                *ngIf="
                  accreditationForm?.controls['businessDetails'].controls[
                    'accountPayableEmail'
                  ].errors &&
                  accreditationForm?.controls['businessDetails'].controls[
                    'accountPayableEmail'
                  ].errors.pattern
                "
                class="text-danger"
                translate
              >
                Account Payable Email Address is invalid
              </small>
            </div>
          </div>
          <div class="form-checkbox-container">
            <div class="form-group-field">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="optionFirst"
                  name="optionFirst"
                  id="optionFirst"
                />
                <label
                  class="form-check-label accreditation-label"
                  for="optionFirst"
                >
                  Have you, the Company (its affiliates, parent or predecessor
                  company) or any Senior Executives or Owners ever been formally
                  charged with, arrested for, indicted, convicted of, or
                  confessed to a crime (other than traffic violations)? If yes,
                  please provide details of the crime(s) and the disposition.
                </label>
              </div>
            </div>
            <div class="form-group-field">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="optionSecond"
                  name="optionSecond"
                  id="optionSecond"
                />
                <label
                  class="form-check-label accreditation-label"
                  for="optionSecond"
                  >Has the Company (its affiliates, parent or predecessor
                  company) been investigated by any industry regulatory body;
                  including but not limited to State Attorney General, State
                  Banking or Consumer Body, the FTC or the CFPB? If yes, please
                  provide details, including the disposition and any sanctions
                  or regulatory actions</label
                >
              </div>
            </div>
            <div class="form-group-field">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="optionThird"
                  name="optionThird"
                  id="optionThird"
                />
                <label
                  class="form-check-label accreditation-label"
                  for="optionThird"
                  >Have you, the Company (its affiliates, parent or predecessor
                  company) or any Senior Executives or Owners ever held any
                  professional licenses that were revoked or suspended, such as
                  a series 6, 7, CPA, mortgage brokerage licenses, etc?</label
                >
              </div>
            </div>
            <div class="form-group-field">
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="checkbox"
                  formControlName="optionFourth"
                  name="optionFourth"
                  id="optionFourth"
                />
                <label
                  class="form-check-label accreditation-label"
                  for="optionFourth"
                  >Has the Company (its affiliates, parent or predecessor
                  company) incurred any civil judgments or adverse findings or
                  settlements for any forms of fraud, misappropriation of funds,
                  tax evasion or similar civil matters involving the integrity
                  of the Company, its affiliates, parent or predecessor? If yes,
                  please provide details</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="accreditation-form-section" formGroupName="achDetail">
        <h4 class="home__subheader accreditation__subheader">ACH Form</h4>
        <span class="section-divider"></span>
        <p class="subtitle">
          To set you up on our direct pay program. Please list this information
          and enclose documents below.
        </p>
        <div class="form-input-container">
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                formControlName="bankName"
                class="form-input"
                placeholder="Bank Name"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['achDetail'].controls['bankName']
                    .valid
                "
              />
              <span hidden translate>Bank Name</span>
              <small
                [hidden]="
                  accreditationForm?.controls['achDetail'].controls['bankName']
                    .valid ||
                  accreditationForm?.controls['achDetail'].controls['bankName']
                    .untouched
                "
                class="text-danger"
                translate
              >
                Bank Name is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                formControlName="accountType"
                class="form-input"
                placeholder="Account Type"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['achDetail'].controls[
                    'accountType'
                  ].valid
                "
              />
              <span hidden translate>Account Type</span>
              <small
                [hidden]="
                  accreditationForm?.controls['achDetail'].controls[
                    'accountType'
                  ].valid ||
                  accreditationForm?.controls['achDetail'].controls[
                    'accountType'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Account Type is required
              </small>
            </div>
          </div>
          <div class="form-input-row">
            <div class="form-group-field">
              <input
                type="text"
                numeric
                numericType="number"
                formControlName="routingNumber"
                class="form-input"
                placeholder="Routing Number"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['achDetail'].controls[
                    'routingNumber'
                  ].valid
                "
              />
              <span hidden translate>Routing Number</span>
              <small
                [hidden]="
                  accreditationForm?.controls['achDetail'].controls[
                    'routingNumber'
                  ].valid ||
                  accreditationForm?.controls['achDetail'].controls[
                    'routingNumber'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Routing Number is required
              </small>
            </div>
            <div class="form-group-field">
              <input
                type="text"
                numeric
                numericType="number"
                formControlName="accountNumber"
                class="form-input"
                placeholder="Account Number"
              />
              <img
                class="check-valid position-absolute"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="
                  accreditationForm?.controls['achDetail'].controls[
                    'accountNumber'
                  ].valid
                "
              />
              <span hidden translate>Account Number</span>
              <small
                [hidden]="
                  accreditationForm?.controls['achDetail'].controls[
                    'accountNumber'
                  ].valid ||
                  accreditationForm?.controls['achDetail'].controls[
                    'accountNumber'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Account Number is required
              </small>
            </div>
          </div>
        </div>
      </div>

      <div class="accreditation-form-section" formGroupName="documentDetail">
        <h4 class="home__subheader accreditation__subheader">
          Upload Documents
        </h4>
        <span class="section-divider"></span>
        <p class="subtitle">(Upload bank statements, invoices and w9form)</p>
        <div class="form-group-container">
          <div class="form-group-field">
            <div class="upload-file-input-container">
              <label for="bankStatementFile" class="col-form-label"
                ><span class="label-text">Bank Statement:</span>
              </label>
              <div class="icon-input-container">
                <fa-icon [icon]="upploadFileImg" class="uppload-icon"></fa-icon>
                <input
                  formControlName="bankStatementFiles"
                  id="bankStatementFile"
                  type="file"
                  class="upload-file"
                  (change)="onBankStatementFileChange($event)"
                />
              </div>
            </div>
            <small
              [hidden]="
                accreditationForm?.controls['documentDetail'].controls[
                  'bankStatementFiles'
                ].valid ||
                accreditationForm?.controls['documentDetail'].controls[
                  'bankStatementFiles'
                ].untouched
              "
              class="text-danger"
              translate
            >
              Bank Statement is required
            </small>
          </div>
          <div class="form-group-field">
            <div class="upload-file-input-container">
              <label for="blankInvoiceFile" class="col-form-label">
                <span class="label-text">Blank Invoice:</span>
              </label>
              <div class="icon-input-container">
                <fa-icon [icon]="upploadFileImg" class="uppload-icon"></fa-icon>
                <input
                  formControlName="blankInvoiceFiles"
                  id="blankInvoiceFile"
                  type="file"
                  class="upload-file"
                  (change)="onBlankInvoiceFileChange($event)"
                />
              </div>
            </div>
            <small
              [hidden]="
                accreditationForm?.controls['documentDetail'].controls[
                  'blankInvoiceFiles'
                ].valid ||
                accreditationForm?.controls['documentDetail'].controls[
                  'blankInvoiceFiles'
                ].untouched
              "
              class="text-danger"
              translate
            >
              Blank Invoice is required
            </small>
          </div>
          <div class="form-group-field">
            <div class="upload-file-input-container">
              <label for="w9FormFile" class="col-form-label">
                <span class="label-text">W9Form:</span>
              </label>
              <div class="icon-input-container">
                <fa-icon [icon]="upploadFileImg" class="uppload-icon"></fa-icon>
                <input
                  formControlName="w9FormFile"
                  id="w9FormFile"
                  type="file"
                  (change)="onW9FormFileChange($event)"
                />
              </div>
            </div>
            <small
              [hidden]="
                accreditationForm?.controls['documentDetail'].controls[
                  'w9FormFile'
                ].valid ||
                accreditationForm?.controls['documentDetail'].controls[
                  'w9FormFile'
                ].untouched
              "
              class="text-danger"
              translate
            >
              W9Form File is required
            </small>
          </div>
        </div>

        <h4
          class="home__subheader accreditation__subheader"
          style="margin-top: 25px"
        >
          I enclose the following documents:
        </h4>
        <span class="section-divider"></span>
        <div class="form-group-field">
          <div class="form-group row">
            <label for="inputPassword3" class="col-sm-4 col-form-label">
              Direct pay program
            </label>
            <div class="col-sm-8">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="bankStatementAdded"
                  formControlName="bankStatementAdded"
                />
                <label class="form-check-label" for="bankStatementAdded">
                  Bank statement/voided check/bank stamped deposit slip
                </label>
              </div>
              <small
                [hidden]="
                  accreditationForm?.controls['documentDetail'].controls[
                    'bankStatementAdded'
                  ].valid ||
                  accreditationForm?.controls['documentDetail'].controls[
                    'bankStatementAdded'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Selection is required
              </small>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword3" class="col-sm-4 col-form-label"
              >Incentive confirmation</label
            >
            <div class="col-sm-8">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="blankInvoiceAdded"
                  formControlName="blankInvoiceAdded"
                />
                <label class="form-check-label" for="blankInvoiceAdded">
                  Blank Invoice (bank and entity details to match the above)
                </label>
              </div>
              <small
                [hidden]="
                  accreditationForm?.controls['documentDetail'].controls[
                    'blankInvoiceAdded'
                  ].valid ||
                  accreditationForm?.controls['documentDetail'].controls[
                    'blankInvoiceAdded'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Selection is required
              </small>
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword3" class="col-sm-4 col-form-label"
              ><strong>W9 Form</strong></label
            >
            <div class="col-sm-8">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="w9FormAdded"
                  formControlName="w9FormAdded"
                />
                <label class="form-check-label" for="w9FormAdded">
                  Please fill out W9 Form
                  <a
                    href="javascript:void(0);"
                    (click)="export()"
                    style="cursor: pointer"
                    >(attached)
                    <i
                      class="fa fa-spinner fa-spin"
                      style="font-size: 18px"
                      *ngIf="downloadingW9SampleFile"
                    ></i>
                  </a>
                </label>
              </div>
              <small
                [hidden]="
                  accreditationForm?.controls['documentDetail'].controls[
                    'w9FormAdded'
                  ].valid ||
                  accreditationForm?.controls['documentDetail'].controls[
                    'w9FormAdded'
                  ].untouched
                "
                class="text-danger"
                translate
              >
                Selection is required
              </small>
            </div>
          </div>
        </div>
        <div class="form-group-field">
          <p class="info-text">
       
            <span class="info-text__more-text">
              I, an officer of the Company with authority to execute
              documents on behalf of the Company, authorize Plexe and its
              designated agents to order and conduct a comprehensive review of
              my background and the Company’s background and the background of
              the Company’s Owners, including ordering background reports and
              investigative reports at Plexe’s discretion. Appendix I must be
              completed for each person or entity owning 25% or more of the
              Company. I further authorize any individual, company, firm,
              corporation, or public agency to divulge any and all information,
              verbal or written, pertaining to me, the Company, and its Owners
              to Plexe or its agents and authorize the complete release of any
              records or data pertaining thereto which the individual, company,
              firm, corporation, or public agency may have, including
              information or data received from other sources. Plexe and its
              subsidiaries, affiliates and agents may rely upon this
              authorization to conduct additional background checks on me, the
              Company, or the Company’s Owners, during the Company’s
              participation in Plexe’s Preferrred Partner Program without asking
              for an additional, subsequent authorization. I certify that all
              information on this form is true and correct, that the list and/or
              background information provided for the Owners, Senior Executives
              and Client-facing Employees is complete, that the information
              provided for each is, to the best of my knowledge, true and
              correct, I have all necessary authority to provide the information
              on this form, and dishonesty and/or incomplete information will
              disqualify the Company from consideration for participation in the
              Plexe Preferred Partner Program. The Company shall promptly notify
              Plexe in writing of any changes, additions, or deletions to the
              information included in this form, including additional and/or
              changes to the Company’s Owners.
            </span>
            
          </p>
        </div>
      </div>
      <div class="accreditation-form-section" formGroupName="companyDetail">
        <div class="form-input-row">
          <div class="form-group-field">
            <input
              type="text"
              formControlName="companyName"
              class="form-input"
              placeholder="Name of the company"
            />
            <img
              class="check-valid position-absolute"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="
                accreditationForm?.controls['companyDetail'].controls[
                  'companyName'
                ].valid
              "
            />
            <span hidden translate>Name of the company</span>
            <small
              [hidden]="
                accreditationForm?.controls['companyDetail'].controls[
                  'companyName'
                ].valid ||
                accreditationForm?.controls['companyDetail'].controls[
                  'companyName'
                ].untouched
              "
              class="text-danger"
              translate
            >
              Name of the company is required
            </small>
          </div>
          <div class="form-group-field">
            <input
              type="text"
              formControlName="name"
              class="form-input"
              placeholder="Name"
            />
            <img
              class="check-valid position-absolute"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="
                accreditationForm?.controls['companyDetail'].controls['name']
                  .valid
              "
            />
            <span hidden translate>Name</span>
            <small
              [hidden]="
                accreditationForm?.controls['companyDetail'].controls['name']
                  .valid ||
                accreditationForm?.controls['companyDetail'].controls['name']
                  .untouched
              "
              class="text-danger"
              translate
            >
              Name is required
            </small>
          </div>
        </div>
        <div class="form-input-row">
          <div class="form-group-field">
            <input
              type="text"
              formControlName="title"
              class="form-input"
              placeholder="Title"
            />
            <img
              class="check-valid position-absolute"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="
                accreditationForm?.controls['companyDetail'].controls['title']
                  .valid
              "
            />
            <span hidden translate>Title</span>
            <small
              [hidden]="
                accreditationForm?.controls['companyDetail'].controls['title']
                  .valid ||
                accreditationForm?.controls['companyDetail'].controls['title']
                  .untouched
              "
              class="text-danger"
              translate
            >
              Title is required
            </small>
          </div>
          <div class="form-group-field">
            <input
              type="text"
              class="form-input"
              formControlName="date"
              ngbDatepicker
              #date="ngbDatepicker"
              (click)="date.open()"
              (input)="formatDateString()"
              [value]="formattedDate"
              [autoClose]="true"
              [minDate]="{ year: 1920, month: 1, day: 1 }"
              [maxDate]="{ year: 2002, month: 12, day: 31 }"
              [placeholder]="isUSRegion ? 'mm/dd/yyyy' : 'dd/mm/yyyy'"
            />
            <!--<input type="text"
           formControlName="date"
           class="form-input"
           placeholder="Date">-->
            <img
              class="check-valid position-absolute"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="
                accreditationForm?.controls['companyDetail'].controls['date']
                  .valid
              "
            />
            <span hidden translate>Date</span>
            <small
              [hidden]="
                accreditationForm?.controls['companyDetail'].controls['date']
                  .valid ||
                accreditationForm?.controls['companyDetail'].controls['date']
                  .untouched
              "
              class="text-danger"
              translate
            >
              Date is required
            </small>
          </div>
        </div>
        <div class="form-group-field">
          <input
            type="text"
            formControlName="signature"
            class="form-input"
            placeholder="Signature"
          />
          <img
            class="check-valid position-absolute"
            src="assets/svgs/broker/check_yellow.svg"
            *ngIf="
              accreditationForm?.controls['companyDetail'].controls['signature']
                .valid
            "
          />
          <span hidden translate>Signature</span>
          <small
            [hidden]="
              accreditationForm?.controls['companyDetail'].controls['signature']
                .valid ||
              accreditationForm?.controls['companyDetail'].controls['signature']
                .untouched
            "
            class="text-danger"
            translate
          >
            Signature is required
          </small>
        </div>
      </div>
      <p *ngIf="formSubmitted">Data has been uploaded!</p>
      <div class="accreditation-form-section button-section">
        <!--<button type="submit"
                [disabled]="accreditationForm.invalid || pendingAccreditation"
                class="btn btn-orange">
          <i class="fas fa-cog fa-spin" [hidden]="!pendingAccreditation"></i>
          Submit
        </button>-->

        <button type="submit" class="btn btn-orange">
          <i class="fas fa-cog fa-spin" [hidden]="!pendingAccreditation"></i>
          Submit
        </button>
      </div>
    </form>
  </div>
</section>
