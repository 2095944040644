/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is regenerated.
 */

import * as msRest from "@azure/ms-rest-js";


export const Name: msRest.CompositeMapper = {
  serializedName: "Name",
  type: {
    name: "Composite",
    className: "Name",
    modelProperties: {
      first: {
        serializedName: "first",
        type: {
          name: "String"
        }
      },
      last: {
        serializedName: "last",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DrivingLicense: msRest.CompositeMapper = {
  serializedName: "DrivingLicense",
  type: {
    name: "Composite",
    className: "DrivingLicense",
    modelProperties: {
      issuingState: {
        serializedName: "issuingState",
        type: {
          name: "String"
        }
      },
      cardNumber: {
        serializedName: "cardNumber",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "DateTime"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "String"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      image: {
        serializedName: "image",
        type: {
          name: "ByteArray"
        }
      }
    }
  }
};

export const Applicant: msRest.CompositeMapper = {
  serializedName: "Applicant",
  type: {
    name: "Composite",
    className: "Applicant",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      driversLicense: {
        serializedName: "driversLicense",
        type: {
          name: "Composite",
          className: "DrivingLicense"
        }
      },
      isPrimary: {
        serializedName: "isPrimary",
        type: {
          name: "Boolean"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "String"
        }
      },
      miscellaneousData: {
        serializedName: "miscellaneousData",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const PrimaryApplicant: msRest.CompositeMapper = {
  serializedName: "PrimaryApplicant",
  type: {
    name: "Composite",
    className: "PrimaryApplicant",
    modelProperties: {
      isManuallyAdded: {
        serializedName: "isManuallyAdded",
        type: {
          name: "Boolean"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      driversLicense: {
        serializedName: "driversLicense",
        type: {
          name: "Composite",
          className: "DrivingLicense"
        }
      },
      isPrimary: {
        serializedName: "isPrimary",
        type: {
          name: "Boolean"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "String"
        }
      },
      miscellaneousData: {
        serializedName: "miscellaneousData",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const SecondaryApplicant: msRest.CompositeMapper = {
  serializedName: "SecondaryApplicant",
  type: {
    name: "Composite",
    className: "SecondaryApplicant",
    modelProperties: {
      isManuallyAdded: {
        serializedName: "isManuallyAdded",
        type: {
          name: "Boolean"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      driversLicense: {
        serializedName: "driversLicense",
        type: {
          name: "Composite",
          className: "DrivingLicense"
        }
      },
      isPrimary: {
        serializedName: "isPrimary",
        type: {
          name: "Boolean"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "String"
        }
      },
      miscellaneousData: {
        serializedName: "miscellaneousData",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ApplicationsOverview: msRest.CompositeMapper = {
  serializedName: "ApplicationsOverview",
  type: {
    name: "Composite",
    className: "ApplicationsOverview",
    modelProperties: {
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      },
      totalAmountApproved: {
        serializedName: "totalAmountApproved",
        type: {
          name: "Number"
        }
      },
      totalAplicationsApproved: {
        serializedName: "totalAplicationsApproved",
        type: {
          name: "Number"
        }
      },
      totalAmountPending: {
        serializedName: "totalAmountPending",
        type: {
          name: "Number"
        }
      },
      totalApplicationsPending: {
        serializedName: "totalApplicationsPending",
        type: {
          name: "Number"
        }
      },
      totalCommissionsPaid: {
        serializedName: "totalCommissionsPaid",
        type: {
          name: "Number"
        }
      },
      totalCommissionsDeals: {
        serializedName: "totalCommissionsDeals",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const BoolWrapper: msRest.CompositeMapper = {
  serializedName: "BoolWrapper",
  type: {
    name: "Composite",
    className: "BoolWrapper",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const TransactionDataFile: msRest.CompositeMapper = {
  serializedName: "TransactionDataFile",
  type: {
    name: "Composite",
    className: "TransactionDataFile",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileType: {
        serializedName: "fileType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Commission: msRest.CompositeMapper = {
  serializedName: "Commission",
  type: {
    name: "Composite",
    className: "Commission",
    modelProperties: {
      upfrontFee: {
        serializedName: "upfrontFee",
        type: {
          name: "Number"
        }
      },
      trailing: {
        serializedName: "trailing",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Loan: msRest.CompositeMapper = {
  serializedName: "Loan",
  type: {
    name: "Composite",
    className: "Loan",
    modelProperties: {
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      offerId: {
        serializedName: "offerId",
        type: {
          name: "Uuid"
        }
      },
      loanType: {
        serializedName: "loanType",
        type: {
          name: "Number"
        }
      },
      enabled: {
        serializedName: "enabled",
        type: {
          name: "Boolean"
        }
      },
      closed: {
        serializedName: "closed",
        type: {
          name: "Boolean"
        }
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean"
        }
      },
      revolving: {
        serializedName: "revolving",
        type: {
          name: "Boolean"
        }
      },
      enabledDebtors: {
        serializedName: "enabledDebtors",
        type: {
          name: "Boolean"
        }
      },
      showAllDebtors: {
        serializedName: "showAllDebtors",
        type: {
          name: "Boolean"
        }
      },
      bankId: {
        serializedName: "bankId",
        type: {
          name: "String"
        }
      },
      funder: {
        serializedName: "funder",
        type: {
          name: "String"
        }
      },
      health: {
        serializedName: "health",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      customerId: {
        serializedName: "customerId",
        type: {
          name: "Uuid"
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      },
      organisationId: {
        serializedName: "organisationId",
        type: {
          name: "Uuid"
        }
      },
      customerFullName: {
        serializedName: "customerFullName",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      securedCommission: {
        serializedName: "securedCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      lineOfCommission: {
        serializedName: "lineOfCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      salesPerson: {
        serializedName: "salesPerson",
        type: {
          name: "String"
        }
      },
      lastUpdated: {
        serializedName: "lastUpdated",
        type: {
          name: "DateTime"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Boolean"
        }
      },
      loanTypeName: {
        serializedName: "loanTypeName",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      fullBusinessName: {
        serializedName: "fullBusinessName",
        type: {
          name: "String"
        }
      },
      nextAuditDate: {
        serializedName: "nextAuditDate",
        type: {
          name: "DateTime"
        }
      },
      auditHighRange: {
        serializedName: "auditHighRange",
        type: {
          name: "Number"
        }
      },
      auditLowRange: {
        serializedName: "auditLowRange",
        type: {
          name: "Number"
        }
      },
      auditType: {
        serializedName: "auditType",
        type: {
          name: "String"
        }
      },
      auditTypeList: {
        serializedName: "auditTypeList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      closedDate: {
        serializedName: "closedDate",
        type: {
          name: "DateTime"
        }
      },
      balanceOverride: {
        serializedName: "balanceOverride",
        type: {
          name: "Boolean"
        }
      },
      defaulted: {
        serializedName: "defaulted",
        type: {
          name: "Boolean"
        }
      },
      defaultStatus: {
        serializedName: "defaultStatus",
        type: {
          name: "String"
        }
      },
      customerIds: {
        serializedName: "customerIds",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Uuid"
            }
          }
        }
      },
      customerIdStr: {
        serializedName: "customerIdStr",
        type: {
          name: "String"
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Application: msRest.CompositeMapper = {
  serializedName: "Application",
  type: {
    name: "Composite",
    className: "Application",
    modelProperties: {
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      },
      customerId: {
        serializedName: "customerId",
        type: {
          name: "Uuid"
        }
      },
      abn: {
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      shortCode: {
        serializedName: "shortCode",
        type: {
          name: "String"
        }
      },
      statusName: {
        serializedName: "statusName",
        type: {
          name: "String"
        }
      },
      securedCommission: {
        serializedName: "securedCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      lineOfCreditCommission: {
        serializedName: "lineOfCreditCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      organisationId: {
        serializedName: "organisationId",
        type: {
          name: "Uuid"
        }
      },
      offerId: {
        serializedName: "offerId",
        type: {
          name: "Uuid"
        }
      },
      isUnitTrust: {
        serializedName: "isUnitTrust",
        type: {
          name: "Boolean"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Boolean"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      fullBusinessName: {
        serializedName: "fullBusinessName",
        type: {
          name: "String"
        }
      },
      offerAccepted: {
        serializedName: "offerAccepted",
        type: {
          name: "Boolean"
        }
      },
      loanName: {
        serializedName: "loanName",
        type: {
          name: "Number"
        }
      },
      acn: {
        serializedName: "acn",
        type: {
          name: "String"
        }
      },
      acnType: {
        serializedName: "acnType",
        type: {
          name: "String"
        }
      },
      contactSms: {
        serializedName: "contactSms",
        type: {
          name: "Boolean"
        }
      },
      contactEmail: {
        serializedName: "contactEmail",
        type: {
          name: "Boolean"
        }
      },
      hasLoggedIn: {
        serializedName: "hasLoggedIn",
        type: {
          name: "Boolean"
        }
      },
      contractIPAddress: {
        serializedName: "contractIPAddress",
        type: {
          name: "String"
        }
      },
      contractSignatureLocation: {
        serializedName: "contractSignatureLocation",
        type: {
          name: "String"
        }
      },
      contractSignature2Location: {
        serializedName: "contractSignature2Location",
        type: {
          name: "String"
        }
      },
      contractIPAddressSecondary: {
        serializedName: "contractIPAddressSecondary",
        type: {
          name: "String"
        }
      },
      contractSignatureLocationSecondary: {
        serializedName: "contractSignatureLocationSecondary",
        type: {
          name: "String"
        }
      },
      contractSignature2LocationSecondary: {
        serializedName: "contractSignature2LocationSecondary",
        type: {
          name: "String"
        }
      },
      cancelledReason: {
        serializedName: "cancelledReason",
        type: {
          name: "String"
        }
      },
      secondaryApplicantRequested: {
        serializedName: "secondaryApplicantRequested",
        type: {
          name: "Boolean"
        }
      },
      primaryApplicantAdded: {
        serializedName: "primaryApplicantAdded",
        type: {
          name: "Boolean"
        }
      },
      secondaryApplicantAdded: {
        serializedName: "secondaryApplicantAdded",
        type: {
          name: "Boolean"
        }
      },
      contractSignatureDate: {
        serializedName: "contractSignatureDate",
        type: {
          name: "String"
        }
      },
      contractSignature2Date: {
        serializedName: "contractSignature2Date",
        type: {
          name: "String"
        }
      },
      contractSignatureSecondaryDate: {
        serializedName: "contractSignatureSecondaryDate",
        type: {
          name: "String"
        }
      },
      contractSignature2SecondaryDate: {
        serializedName: "contractSignature2SecondaryDate",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const Customer: msRest.CompositeMapper = {
  serializedName: "Customer",
  type: {
    name: "Composite",
    className: "Customer",
    modelProperties: {
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      mobileNumber: {
        required: true,
        serializedName: "mobileNumber",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      password: {
        serializedName: "password",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      parentId: {
        serializedName: "parentId",
        type: {
          name: "Uuid"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      hasLoggedIn: {
        serializedName: "hasLoggedIn",
        type: {
          name: "Boolean"
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const PartnerCommission: msRest.CompositeMapper = {
  serializedName: "PartnerCommission",
  type: {
    name: "Composite",
    className: "PartnerCommission",
    modelProperties: {
      unsecuredTrail: {
        serializedName: "unsecuredTrail",
        type: {
          name: "Number"
        }
      },
      securedTrail: {
        serializedName: "securedTrail",
        type: {
          name: "Number"
        }
      },
      locTrail: {
        serializedName: "locTrail",
        type: {
          name: "Number"
        }
      },
      upFrontFee: {
        serializedName: "upFrontFee",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Partner: msRest.CompositeMapper = {
  serializedName: "Partner",
  type: {
    name: "Composite",
    className: "Partner",
    modelProperties: {
      email: {
        required: true,
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        required: true,
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        required: true,
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      mobilePhoneNumber: {
        serializedName: "mobilePhoneNumber",
        type: {
          name: "String"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "PartnerCommission"
        }
      },
      urlReferral: {
        serializedName: "urlReferral",
        type: {
          name: "String"
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsOrganisationAddress: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsOrganisationAddress",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsOrganisationAddress",
    modelProperties: {
      addressLine1: {
        serializedName: "addressLine1",
        type: {
          name: "String"
        }
      },
      addressLine2: {
        serializedName: "addressLine2",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      region: {
        serializedName: "region",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsOrganisationPhone: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsOrganisationPhone",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsOrganisationPhone",
    modelProperties: {
      phoneType: {
        serializedName: "phoneType",
        type: {
          name: "String"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      phoneAreaCode: {
        serializedName: "phoneAreaCode",
        type: {
          name: "String"
        }
      },
      phoneCountryCode: {
        serializedName: "phoneCountryCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsApplicant: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsApplicant",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsApplicant",
    modelProperties: {
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      surname: {
        serializedName: "surname",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      middleName: {
        serializedName: "middleName",
        type: {
          name: "String"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      ownershipType: {
        serializedName: "ownershipType",
        type: {
          name: "String"
        }
      },
      citizenshipStaus: {
        serializedName: "citizenshipStaus",
        type: {
          name: "String"
        }
      },
      unitNo: {
        serializedName: "unitNo",
        type: {
          name: "String"
        }
      },
      streetNo: {
        serializedName: "streetNo",
        type: {
          name: "String"
        }
      },
      street: {
        serializedName: "street",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      postcode: {
        serializedName: "postcode",
        type: {
          name: "String"
        }
      },
      timeAtAddress: {
        serializedName: "timeAtAddress",
        type: {
          name: "String"
        }
      },
      driverLicenceState: {
        serializedName: "driverLicenceState",
        type: {
          name: "String"
        }
      },
      driverLicenceExpiryDate: {
        serializedName: "driverLicenceExpiryDate",
        type: {
          name: "String"
        }
      },
      medicareNumber: {
        serializedName: "medicareNumber",
        type: {
          name: "String"
        }
      },
      medicareExpiryDate: {
        serializedName: "medicareExpiryDate",
        type: {
          name: "String"
        }
      },
      medicarePosition: {
        serializedName: "medicarePosition",
        type: {
          name: "String"
        }
      },
      medicareNameOnCard: {
        serializedName: "medicareNameOnCard",
        type: {
          name: "String"
        }
      },
      driverLicenceNumber: {
        serializedName: "driverLicenceNumber",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsNews: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsNews",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsNews",
    modelProperties: {
      datetime: {
        serializedName: "datetime",
        type: {
          name: "DateTime"
        }
      },
      source: {
        serializedName: "source",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsNote: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsNote",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsNote",
    modelProperties: {
      datetime: {
        serializedName: "datetime",
        type: {
          name: "DateTime"
        }
      },
      author: {
        serializedName: "author",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeLookupServiceProxyModelsOrganisationDetails: msRest.CompositeMapper = {
  serializedName: "PlexeLookupServiceProxyModelsOrganisationDetails",
  type: {
    name: "Composite",
    className: "PlexeLookupServiceProxyModelsOrganisationDetails",
    modelProperties: {
      entityType: {
        serializedName: "entityType",
        type: {
          name: "String"
        }
      },
      entityTypeCode: {
        serializedName: "entityTypeCode",
        type: {
          name: "String"
        }
      },
      fromDate: {
        serializedName: "fromDate",
        type: {
          name: "DateTime"
        }
      },
      toDate: {
        serializedName: "toDate",
        type: {
          name: "DateTime"
        }
      },
      postcode: {
        serializedName: "postcode",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      familyName: {
        serializedName: "familyName",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      otherName: {
        serializedName: "otherName",
        type: {
          name: "String"
        }
      },
      mainName: {
        serializedName: "mainName",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsPaymentTerm: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsPaymentTerm",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsPaymentTerm",
    modelProperties: {
      day: {
        serializedName: "day",
        type: {
          name: "Number"
        }
      },
      termType: {
        serializedName: "termType",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsAddress: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsAddress",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsAddress",
    modelProperties: {
      addressType: {
        serializedName: "addressType",
        type: {
          name: "String"
        }
      },
      addressLine1: {
        serializedName: "addressLine1",
        type: {
          name: "String"
        }
      },
      addressLine2: {
        serializedName: "addressLine2",
        type: {
          name: "String"
        }
      },
      addressLine3: {
        serializedName: "addressLine3",
        type: {
          name: "String"
        }
      },
      addressLine4: {
        serializedName: "addressLine4",
        type: {
          name: "String"
        }
      },
      city: {
        serializedName: "city",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      region: {
        serializedName: "region",
        type: {
          name: "String"
        }
      },
      postalCode: {
        serializedName: "postalCode",
        type: {
          name: "String"
        }
      },
      country: {
        serializedName: "country",
        type: {
          name: "String"
        }
      },
      attentionTo: {
        serializedName: "attentionTo",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsPhone: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsPhone",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsPhone",
    modelProperties: {
      phoneType: {
        serializedName: "phoneType",
        type: {
          name: "Number"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      phoneAreaCode: {
        serializedName: "phoneAreaCode",
        type: {
          name: "String"
        }
      },
      phoneCountryCode: {
        serializedName: "phoneCountryCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsOrganisation: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsOrganisation",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsOrganisation",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      abn: {
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      acn: {
        serializedName: "acn",
        type: {
          name: "String"
        }
      },
      legalName: {
        serializedName: "legalName",
        type: {
          name: "String"
        }
      },
      shortCode: {
        serializedName: "shortCode",
        type: {
          name: "String"
        }
      },
      paysTax: {
        serializedName: "paysTax",
        type: {
          name: "Boolean"
        }
      },
      version: {
        serializedName: "version",
        type: {
          name: "Number"
        }
      },
      organizationType: {
        serializedName: "organizationType",
        type: {
          name: "Number"
        }
      },
      isDemoCompany: {
        serializedName: "isDemoCompany",
        type: {
          name: "Boolean"
        }
      },
      apiKey: {
        serializedName: "apiKey",
        type: {
          name: "String"
        }
      },
      countryCode: {
        serializedName: "countryCode",
        type: {
          name: "String"
        }
      },
      taxNumber: {
        serializedName: "taxNumber",
        type: {
          name: "String"
        }
      },
      financialYearEndDay: {
        serializedName: "financialYearEndDay",
        type: {
          name: "Number"
        }
      },
      financialYearEndMonth: {
        serializedName: "financialYearEndMonth",
        type: {
          name: "Number"
        }
      },
      periodLockDate: {
        serializedName: "periodLockDate",
        type: {
          name: "DateTime"
        }
      },
      endOfYearLockDate: {
        serializedName: "endOfYearLockDate",
        type: {
          name: "DateTime"
        }
      },
      createdDateUtc: {
        serializedName: "createdDateUtc",
        type: {
          name: "DateTime"
        }
      },
      timezone: {
        serializedName: "timezone",
        type: {
          name: "String"
        }
      },
      lineOfBusiness: {
        serializedName: "lineOfBusiness",
        type: {
          name: "String"
        }
      },
      registrationNumber: {
        serializedName: "registrationNumber",
        type: {
          name: "String"
        }
      },
      billsPaymentTerm: {
        serializedName: "billsPaymentTerm",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsPaymentTerm"
        }
      },
      salesPaymentTerm: {
        serializedName: "salesPaymentTerm",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsPaymentTerm"
        }
      },
      salesTaxBasisType: {
        serializedName: "salesTaxBasisType",
        type: {
          name: "Number"
        }
      },
      salesTaxPeriodType: {
        serializedName: "salesTaxPeriodType",
        type: {
          name: "Number"
        }
      },
      baseCurrency: {
        serializedName: "baseCurrency",
        type: {
          name: "String"
        }
      },
      addresses: {
        serializedName: "addresses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsAddress"
            }
          }
        }
      },
      phones: {
        serializedName: "phones",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsPhone"
            }
          }
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsOrganisation: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsOrganisation",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsOrganisation",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      abn: {
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "String"
        }
      },
      bankStatement: {
        serializedName: "bankStatement",
        type: {
          name: "Boolean"
        }
      },
      bankFeed: {
        serializedName: "bankFeed",
        type: {
          name: "Boolean"
        }
      },
      accounting: {
        serializedName: "accounting",
        type: {
          name: "Boolean"
        }
      },
      archive: {
        serializedName: "archive",
        type: {
          name: "Boolean"
        }
      },
      address: {
        serializedName: "address",
        type: {
          name: "Composite",
          className: "PlexeOrganisationServiceModelsOrganisationAddress"
        }
      },
      numbers: {
        serializedName: "numbers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeOrganisationServiceModelsOrganisationPhone"
            }
          }
        }
      },
      applicants: {
        serializedName: "applicants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeOrganisationServiceModelsApplicant"
            }
          }
        }
      },
      news: {
        serializedName: "news",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeOrganisationServiceModelsNews"
            }
          }
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeOrganisationServiceModelsNote"
            }
          }
        }
      },
      asicDetails: {
        serializedName: "asicDetails",
        type: {
          name: "Composite",
          className: "PlexeLookupServiceProxyModelsOrganisationDetails"
        }
      },
      accountingSystem: {
        serializedName: "accountingSystem",
        type: {
          name: "String"
        }
      },
      accountingSystemDetails: {
        serializedName: "accountingSystemDetails",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsOrganisation"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      bankStatementType: {
        serializedName: "bankStatementType",
        type: {
          name: "String"
        }
      },
      bankFeedConnectionType: {
        serializedName: "bankFeedConnectionType",
        type: {
          name: "String"
        }
      },
      plexeBsb: {
        serializedName: "plexeBsb",
        type: {
          name: "String"
        }
      },
      plexeAccountNumber: {
        serializedName: "plexeAccountNumber",
        type: {
          name: "String"
        }
      },
      bankingProviderCustomerId: {
        serializedName: "bankingProviderCustomerId",
        type: {
          name: "String"
        }
      },
      automaticDirectDebit: {
        serializedName: "automaticDirectDebit",
        type: {
          name: "Boolean"
        }
      },
      invoiceExclusion: {
        serializedName: "invoiceExclusion",
        type: {
          name: "Boolean"
        }
      },
      accountingWriteBack: {
        serializedName: "accountingWriteBack",
        type: {
          name: "Boolean"
        }
      },
      automaticAccountingImport: {
        serializedName: "automaticAccountingImport",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Summary: msRest.CompositeMapper = {
  serializedName: "Summary",
  type: {
    name: "Composite",
    className: "Summary",
    modelProperties: {
      loan: {
        serializedName: "loan",
        type: {
          name: "Composite",
          className: "Loan"
        }
      },
      application: {
        serializedName: "application",
        type: {
          name: "Composite",
          className: "Application"
        }
      },
      quickUrl: {
        serializedName: "quickUrl",
        type: {
          name: "String"
        }
      },
      customer: {
        serializedName: "customer",
        type: {
          name: "Composite",
          className: "Customer"
        }
      },
      customers: {
        serializedName: "customers",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Customer"
            }
          }
        }
      },
      partner: {
        serializedName: "partner",
        type: {
          name: "Composite",
          className: "Partner"
        }
      },
      organisation: {
        serializedName: "organisation",
        type: {
          name: "Composite",
          className: "PlexeOrganisationServiceModelsOrganisation"
        }
      },
      applicants: {
        serializedName: "applicants",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Applicant"
            }
          }
        }
      }
    }
  }
};

export const CommissionDetails: msRest.CompositeMapper = {
  serializedName: "CommissionDetails",
  type: {
    name: "Composite",
    className: "CommissionDetails",
    modelProperties: {
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      upFrontBrokerEstablishmentFee: {
        serializedName: "upFrontBrokerEstablishmentFee",
        type: {
          name: "Number"
        }
      },
      firstAdvanceCommissionFee: {
        serializedName: "firstAdvanceCommissionFee",
        type: {
          name: "Number"
        }
      },
      ongoingAdvanceCommissionFee: {
        serializedName: "ongoingAdvanceCommissionFee",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const Amount: msRest.CompositeMapper = {
  serializedName: "Amount",
  type: {
    name: "Composite",
    className: "Amount",
    modelProperties: {
      value: {
        serializedName: "value",
        type: {
          name: "Number"
        }
      },
      currencyCode: {
        serializedName: "currencyCode",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const ApplicationListProjection: msRest.CompositeMapper = {
  serializedName: "ApplicationListProjection",
  type: {
    name: "Composite",
    className: "ApplicationListProjection",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "String"
        }
      },
      customerId: {
        serializedName: "customerId",
        type: {
          name: "String"
        }
      },
      customerFullName: {
        serializedName: "customerFullName",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      shortCode: {
        serializedName: "shortCode",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      cancelledReason: {
        serializedName: "cancelledReason",
        type: {
          name: "String"
        }
      },
      totalAmount: {
        serializedName: "totalAmount",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      availableCredit: {
        serializedName: "availableCredit",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      securedCommission: {
        serializedName: "securedCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      lineOfCreditCommission: {
        serializedName: "lineOfCreditCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      creditType: {
        serializedName: "creditType",
        type: {
          name: "String"
        }
      },
      salesPerson: {
        serializedName: "salesPerson",
        type: {
          name: "String"
        }
      },
      abn: {
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      lastUpdated: {
        serializedName: "lastUpdated",
        type: {
          name: "DateTime"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      isUnitTrust: {
        serializedName: "isUnitTrust",
        type: {
          name: "Boolean"
        }
      },
      organisationId: {
        serializedName: "organisationId",
        type: {
          name: "Uuid"
        }
      },
      offerId: {
        serializedName: "offerId",
        type: {
          name: "Uuid"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      hasLoggedIn: {
        serializedName: "hasLoggedIn",
        type: {
          name: "Boolean"
        }
      },
      fullBusinessName: {
        serializedName: "fullBusinessName",
        type: {
          name: "String"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Boolean"
        }
      },
      offerAccepted: {
        serializedName: "offerAccepted",
        type: {
          name: "Boolean"
        }
      },
      loanType: {
        serializedName: "loanType",
        type: {
          name: "String"
        }
      },
      acn: {
        serializedName: "acn",
        type: {
          name: "String"
        }
      },
      acnType: {
        serializedName: "acnType",
        type: {
          name: "String"
        }
      },
      accountingConnected: {
        serializedName: "accountingConnected",
        type: {
          name: "Boolean"
        }
      },
      accountingSkipped: {
        serializedName: "accountingSkipped",
        type: {
          name: "Boolean"
        }
      },
      bankingConnected: {
        serializedName: "bankingConnected",
        type: {
          name: "Boolean"
        }
      },
      bankingSkipped: {
        serializedName: "bankingSkipped",
        type: {
          name: "Boolean"
        }
      },
      contactEmail: {
        serializedName: "contactEmail",
        type: {
          name: "Boolean"
        }
      },
      contactSms: {
        serializedName: "contactSms",
        type: {
          name: "Boolean"
        }
      },
      contractIPAddress: {
        serializedName: "contractIPAddress",
        type: {
          name: "String"
        }
      },
      contractSignatureLocation: {
        serializedName: "contractSignatureLocation",
        type: {
          name: "String"
        }
      },
      contractSignature2Location: {
        serializedName: "contractSignature2Location",
        type: {
          name: "String"
        }
      },
      contractSignatureLocationSecondary: {
        serializedName: "contractSignatureLocationSecondary",
        type: {
          name: "String"
        }
      },
      contractSignature2LocationSecondary: {
        serializedName: "contractSignature2LocationSecondary",
        type: {
          name: "String"
        }
      },
      contractSignatureDate: {
        serializedName: "contractSignatureDate",
        type: {
          name: "String"
        }
      },
      contractSignature2Date: {
        serializedName: "contractSignature2Date",
        type: {
          name: "String"
        }
      },
      contractSignatureSecondaryDate: {
        serializedName: "contractSignatureSecondaryDate",
        type: {
          name: "String"
        }
      },
      contractSignature2SecondaryDate: {
        serializedName: "contractSignature2SecondaryDate",
        type: {
          name: "String"
        }
      },
      contractIPAddressSecondary: {
        serializedName: "contractIPAddressSecondary",
        type: {
          name: "String"
        }
      },
      secondaryApplicantRequested: {
        serializedName: "secondaryApplicantRequested",
        type: {
          name: "Boolean"
        }
      },
      primaryApplicantAdded: {
        serializedName: "primaryApplicantAdded",
        type: {
          name: "Boolean"
        }
      },
      secondaryApplicantAdded: {
        serializedName: "secondaryApplicantAdded",
        type: {
          name: "Boolean"
        }
      },
      customerIds: {
        serializedName: "customerIds",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PagedListApplicationListProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_ApplicationListProjection_",
  type: {
    name: "Composite",
    className: "PagedListApplicationListProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ApplicationListProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PlexeOrganisationServiceModelsBankAccount: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceModelsBankAccount",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceModelsBankAccount",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      accountNumber: {
        serializedName: "accountNumber",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      bsb: {
        serializedName: "bsb",
        type: {
          name: "String"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "String"
        }
      },
      available: {
        serializedName: "available",
        type: {
          name: "String"
        }
      },
      accountHolder: {
        serializedName: "accountHolder",
        type: {
          name: "String"
        }
      },
      accountType: {
        serializedName: "accountType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsField: msRest.CompositeMapper = {
  serializedName: "PlexeBankStatementsProxyModelsField",
  type: {
    name: "Composite",
    className: "PlexeBankStatementsProxyModelsField",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      text: {
        serializedName: "text",
        type: {
          name: "String"
        }
      },
      value: {
        serializedName: "value",
        type: {
          name: "String"
        }
      },
      values: {
        serializedName: "values",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      fieldID: {
        serializedName: "fieldID",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsMFADetails: msRest.CompositeMapper = {
  serializedName: "PlexeBankStatementsProxyModelsMFADetails",
  type: {
    name: "Composite",
    className: "PlexeBankStatementsProxyModelsMFADetails",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      fields: {
        serializedName: "fields",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeBankStatementsProxyModelsField"
            }
          }
        }
      },
      submitTo: {
        serializedName: "submitTo",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsToken: msRest.CompositeMapper = {
  serializedName: "PlexeBankStatementsProxyModelsToken",
  type: {
    name: "Composite",
    className: "PlexeBankStatementsProxyModelsToken",
    modelProperties: {
      accessToken: {
        serializedName: "accessToken",
        type: {
          name: "String"
        }
      },
      issuedAt: {
        serializedName: "issuedAt",
        type: {
          name: "DateTime"
        }
      },
      expiresIn: {
        serializedName: "expiresIn",
        type: {
          name: "Number"
        }
      },
      isValid: {
        serializedName: "isValid",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsFastLinkToken: msRest.CompositeMapper = {
  serializedName: "PlexeBankStatementsProxyModelsFastLinkToken",
  type: {
    name: "Composite",
    className: "PlexeBankStatementsProxyModelsFastLinkToken",
    modelProperties: {
      fastLinkUri: {
        serializedName: "fastLinkUri",
        type: {
          name: "String"
        }
      },
      token: {
        serializedName: "token",
        type: {
          name: "Composite",
          className: "PlexeBankStatementsProxyModelsToken"
        }
      }
    }
  }
};

export const OfferType: msRest.CompositeMapper = {
  serializedName: "OfferType",
  type: {
    name: "Composite",
    className: "OfferType",
    modelProperties: {
      creditLimit: {
        serializedName: "creditLimit",
        type: {
          name: "Number"
        }
      },
      facilityLimit: {
        serializedName: "facilityLimit",
        type: {
          name: "Number"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      establishmentFee: {
        serializedName: "establishmentFee",
        type: {
          name: "Number"
        }
      },
      weeks: {
        serializedName: "weeks",
        type: {
          name: "Number"
        }
      },
      decision: {
        serializedName: "decision",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const OfferAmount: msRest.CompositeMapper = {
  serializedName: "OfferAmount",
  type: {
    name: "Composite",
    className: "OfferAmount",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      unsecured: {
        serializedName: "unsecured",
        type: {
          name: "Composite",
          className: "OfferType"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Composite",
          className: "OfferType"
        }
      },
      lineOfCredit: {
        serializedName: "lineOfCredit",
        type: {
          name: "Composite",
          className: "OfferType"
        }
      },
      creditCard: {
        serializedName: "creditCard",
        type: {
          name: "Composite",
          className: "OfferType"
        }
      }
    }
  }
};

export const ApplicationSettings: msRest.CompositeMapper = {
  serializedName: "ApplicationSettings",
  type: {
    name: "Composite",
    className: "ApplicationSettings",
    modelProperties: {
      fixedRepayment: {
        serializedName: "fixedRepayment",
        type: {
          name: "Boolean"
        }
      },
      percentOfIncome: {
        serializedName: "percentOfIncome",
        type: {
          name: "Boolean"
        }
      },
      invoicePayment: {
        serializedName: "invoicePayment",
        type: {
          name: "Boolean"
        }
      },
      lineOfCreditPayment: {
        serializedName: "lineOfCreditPayment",
        type: {
          name: "Boolean"
        }
      },
      creditCardPayment: {
        serializedName: "creditCardPayment",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const CommunicationProjection: msRest.CompositeMapper = {
  serializedName: "CommunicationProjection",
  type: {
    name: "Composite",
    className: "CommunicationProjection",
    modelProperties: {
      notificationId: {
        serializedName: "notificationId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      createdDateUtc: {
        serializedName: "createdDateUtc",
        type: {
          name: "DateTime"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      user: {
        serializedName: "user",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const DebtorDetails: msRest.CompositeMapper = {
  serializedName: "DebtorDetails",
  type: {
    name: "Composite",
    className: "DebtorDetails",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      invoiceTypes: {
        serializedName: "invoiceTypes",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Number"
            }
          }
        }
      }
    }
  }
};

export const LoanDebtorProjection: msRest.CompositeMapper = {
  serializedName: "LoanDebtorProjection",
  type: {
    name: "Composite",
    className: "LoanDebtorProjection",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "Composite",
          className: "DebtorDetails"
        }
      },
      approved: {
        serializedName: "approved",
        type: {
          name: "Number"
        }
      },
      fundingRate: {
        serializedName: "fundingRate",
        type: {
          name: "Number"
        }
      },
      available: {
        serializedName: "available",
        type: {
          name: "Number"
        }
      },
      selected: {
        serializedName: "selected",
        type: {
          name: "Boolean"
        }
      },
      requested: {
        serializedName: "requested",
        type: {
          name: "Boolean"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      adminSelected: {
        serializedName: "adminSelected",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PagedListLoanDebtorProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_LoanDebtorProjection_",
  type: {
    name: "Composite",
    className: "PagedListLoanDebtorProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LoanDebtorProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const FixedRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: "FixedRepaymentCalculation",
  type: {
    name: "Composite",
    className: "FixedRepaymentCalculation",
    modelProperties: {
      terms: {
        serializedName: "terms",
        type: {
          name: "Number"
        }
      },
      weeklyRepayment: {
        serializedName: "weeklyRepayment",
        type: {
          name: "Number"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      withdrawAmount: {
        serializedName: "withdrawAmount",
        type: {
          name: "Number"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      totalRepayment: {
        serializedName: "totalRepayment",
        type: {
          name: "Number"
        }
      },
      firstRepayment: {
        serializedName: "firstRepayment",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const LineOfCreditRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: "LineOfCreditRepaymentCalculation",
  type: {
    name: "Composite",
    className: "LineOfCreditRepaymentCalculation",
    modelProperties: {
      percentageOfIncome: {
        serializedName: "percentageOfIncome",
        type: {
          name: "Number"
        }
      },
      weeklyRepayment: {
        serializedName: "weeklyRepayment",
        type: {
          name: "Number"
        }
      },
      weeks: {
        serializedName: "weeks",
        type: {
          name: "Number"
        }
      },
      weeklyForecastIncome: {
        serializedName: "weeklyForecastIncome",
        type: {
          name: "Number"
        }
      },
      fixed: {
        serializedName: "fixed",
        type: {
          name: "Boolean"
        }
      },
      isFirstAdavnce: {
        serializedName: "isFirstAdavnce",
        type: {
          name: "Boolean"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      withdrawAmount: {
        serializedName: "withdrawAmount",
        type: {
          name: "Number"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      totalRepayment: {
        serializedName: "totalRepayment",
        type: {
          name: "Number"
        }
      },
      firstRepayment: {
        serializedName: "firstRepayment",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PercentOfIncomeCalculation: msRest.CompositeMapper = {
  serializedName: "PercentOfIncomeCalculation",
  type: {
    name: "Composite",
    className: "PercentOfIncomeCalculation",
    modelProperties: {
      rate: {
        serializedName: "rate",
        type: {
          name: "Number"
        }
      },
      weeks: {
        serializedName: "weeks",
        type: {
          name: "Number"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      withdrawAmount: {
        serializedName: "withdrawAmount",
        type: {
          name: "Number"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      totalRepayment: {
        serializedName: "totalRepayment",
        type: {
          name: "Number"
        }
      },
      firstRepayment: {
        serializedName: "firstRepayment",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const InvoicePaymentCalculation: msRest.CompositeMapper = {
  serializedName: "InvoicePaymentCalculation",
  type: {
    name: "Composite",
    className: "InvoicePaymentCalculation",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      withdrawAmount: {
        serializedName: "withdrawAmount",
        type: {
          name: "Number"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      totalRepayment: {
        serializedName: "totalRepayment",
        type: {
          name: "Number"
        }
      },
      firstRepayment: {
        serializedName: "firstRepayment",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const IncomeSummary: msRest.CompositeMapper = {
  serializedName: "IncomeSummary",
  type: {
    name: "Composite",
    className: "IncomeSummary",
    modelProperties: {
      income: {
        serializedName: "income",
        type: {
          name: "Number"
        }
      },
      repayment: {
        serializedName: "repayment",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const IncomeDetails: msRest.CompositeMapper = {
  serializedName: "IncomeDetails",
  type: {
    name: "Composite",
    className: "IncomeDetails",
    modelProperties: {
      currentMonthIncomeChangePercent: {
        serializedName: "currentMonthIncomeChangePercent",
        type: {
          name: "Number"
        }
      },
      currentMonthIncomeChangeUp: {
        serializedName: "currentMonthIncomeChangeUp",
        type: {
          name: "Boolean"
        }
      },
      currentWeek: {
        serializedName: "currentWeek",
        type: {
          name: "Composite",
          className: "IncomeSummary"
        }
      },
      estimatedWeek: {
        serializedName: "estimatedWeek",
        type: {
          name: "Composite",
          className: "IncomeSummary"
        }
      },
      historicalWeeks: {
        serializedName: "historicalWeeks",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Composite",
              className: "IncomeSummary"
            }
          }
        }
      },
      nextRepaymentDate: {
        serializedName: "nextRepaymentDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const OfferDetails: msRest.CompositeMapper = {
  serializedName: "OfferDetails",
  type: {
    name: "Composite",
    className: "OfferDetails",
    modelProperties: {
      offerId: {
        serializedName: "offerId",
        type: {
          name: "Uuid"
        }
      },
      facilityLimit: {
        serializedName: "facilityLimit",
        type: {
          name: "Number"
        }
      },
      creditLimit: {
        serializedName: "creditLimit",
        type: {
          name: "Number"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Boolean"
        }
      },
      decision: {
        serializedName: "decision",
        type: {
          name: "Number"
        }
      },
      loanType: {
        serializedName: "loanType",
        type: {
          name: "Number"
        }
      },
      cashFlowLimit: {
        serializedName: "cashFlowLimit",
        type: {
          name: "Number"
        }
      },
      receivableLimit: {
        serializedName: "receivableLimit",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AuditDetails: msRest.CompositeMapper = {
  serializedName: "AuditDetails",
  type: {
    name: "Composite",
    className: "AuditDetails",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      nextDate: {
        serializedName: "nextDate",
        type: {
          name: "DateTime"
        }
      },
      low: {
        serializedName: "low",
        type: {
          name: "Number"
        }
      },
      high: {
        serializedName: "high",
        type: {
          name: "Number"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      organisationId: {
        serializedName: "organisationId",
        type: {
          name: "Uuid"
        }
      },
      onOffAudit: {
        serializedName: "onOffAudit",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsLineItem: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsLineItem",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsLineItem",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      discountRate: {
        serializedName: "discountRate",
        type: {
          name: "Number"
        }
      },
      itemCode: {
        serializedName: "itemCode",
        type: {
          name: "String"
        }
      },
      lineAmount: {
        serializedName: "lineAmount",
        type: {
          name: "Number"
        }
      },
      quantity: {
        serializedName: "quantity",
        type: {
          name: "Number"
        }
      },
      taxAmount: {
        serializedName: "taxAmount",
        type: {
          name: "Number"
        }
      },
      unitAmount: {
        serializedName: "unitAmount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsPaymentInvoice: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsPaymentInvoice",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsPaymentInvoice",
    modelProperties: {
      amountApplied: {
        serializedName: "amountApplied",
        type: {
          name: "Number"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsPayment: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsPayment",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsPayment",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      currencyRate: {
        serializedName: "currencyRate",
        type: {
          name: "Number"
        }
      },
      bankAmount: {
        serializedName: "bankAmount",
        type: {
          name: "Number"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      reference: {
        serializedName: "reference",
        type: {
          name: "String"
        }
      },
      isReconciled: {
        serializedName: "isReconciled",
        type: {
          name: "Boolean"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      account: {
        serializedName: "account",
        type: {
          name: "String"
        }
      },
      updatedDate: {
        serializedName: "updatedDate",
        type: {
          name: "DateTime"
        }
      },
      retrievedDate: {
        serializedName: "retrievedDate",
        type: {
          name: "DateTime"
        }
      },
      invoices: {
        serializedName: "invoices",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsPaymentInvoice"
            }
          }
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsBalance: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsBalance",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsBalance",
    modelProperties: {
      outstanding: {
        serializedName: "outstanding",
        type: {
          name: "Number"
        }
      },
      overdue: {
        serializedName: "overdue",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsBalances: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsBalances",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsBalances",
    modelProperties: {
      accountsReceivable: {
        serializedName: "accountsReceivable",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsBalance"
        }
      },
      accountsPayable: {
        serializedName: "accountsPayable",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsBalance"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsContact: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsContact",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsContact",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      abn: {
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      acn: {
        serializedName: "acn",
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsBalances"
        }
      },
      addresses: {
        serializedName: "addresses",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsAddress"
            }
          }
        }
      },
      phones: {
        serializedName: "phones",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsPhone"
            }
          }
        }
      },
      fullName: {
        serializedName: "fullName",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsCreditNote: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsCreditNote",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsCreditNote",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      dueDate: {
        serializedName: "dueDate",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      creditNoteNumber: {
        serializedName: "creditNoteNumber",
        type: {
          name: "String"
        }
      },
      lineItems: {
        serializedName: "lineItems",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsLineItem"
            }
          }
        }
      },
      payments: {
        serializedName: "payments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsPayment"
            }
          }
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsContact"
        }
      }
    }
  }
};

export const PlexeAccountingProxyModelsInvoice: msRest.CompositeMapper = {
  serializedName: "PlexeAccountingProxyModelsInvoice",
  type: {
    name: "Composite",
    className: "PlexeAccountingProxyModelsInvoice",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      dueDate: {
        serializedName: "dueDate",
        type: {
          name: "DateTime"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      total: {
        serializedName: "total",
        type: {
          name: "Number"
        }
      },
      amountDue: {
        serializedName: "amountDue",
        type: {
          name: "Number"
        }
      },
      amountPaid: {
        serializedName: "amountPaid",
        type: {
          name: "Number"
        }
      },
      paymentDate: {
        serializedName: "paymentDate",
        type: {
          name: "DateTime"
        }
      },
      invoiceNumber: {
        serializedName: "invoiceNumber",
        type: {
          name: "String"
        }
      },
      lineItems: {
        serializedName: "lineItems",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsLineItem"
            }
          }
        }
      },
      payments: {
        serializedName: "payments",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsPayment"
            }
          }
        }
      },
      contact: {
        serializedName: "contact",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsContact"
        }
      },
      currencyRate: {
        serializedName: "currencyRate",
        type: {
          name: "Number"
        }
      },
      currencyCode: {
        serializedName: "currencyCode",
        type: {
          name: "String"
        }
      },
      creditNotes: {
        serializedName: "creditNotes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsCreditNote"
            }
          }
        }
      },
      updatedDateUtc: {
        serializedName: "updatedDateUtc",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const AuditDocument: msRest.CompositeMapper = {
  serializedName: "AuditDocument",
  type: {
    name: "Composite",
    className: "AuditDocument",
    modelProperties: {
      dateTime: {
        serializedName: "dateTime",
        type: {
          name: "DateTime"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      location: {
        serializedName: "location",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AuditInvoiceDetails: msRest.CompositeMapper = {
  serializedName: "AuditInvoiceDetails",
  type: {
    name: "Composite",
    className: "AuditInvoiceDetails",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      remainingDays: {
        serializedName: "remainingDays",
        type: {
          name: "Number"
        }
      },
      invoice: {
        serializedName: "invoice",
        type: {
          name: "Composite",
          className: "PlexeAccountingProxyModelsInvoice"
        }
      },
      auditType: {
        serializedName: "auditType",
        type: {
          name: "String"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "String"
        }
      },
      documents: {
        serializedName: "documents",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuditDocument"
            }
          }
        }
      },
      completedDate: {
        serializedName: "completedDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PagedListAuditDocument: msRest.CompositeMapper = {
  serializedName: "PagedList_AuditDocument_",
  type: {
    name: "Composite",
    className: "PagedListAuditDocument",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AuditDocument"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AuditSettings: msRest.CompositeMapper = {
  serializedName: "AuditSettings",
  type: {
    name: "Composite",
    className: "AuditSettings",
    modelProperties: {
      high: {
        serializedName: "high",
        type: {
          name: "Number"
        }
      },
      low: {
        serializedName: "low",
        type: {
          name: "Number"
        }
      },
      nextDate: {
        serializedName: "nextDate",
        type: {
          name: "DateTime"
        }
      },
      auditTypes: {
        serializedName: "auditTypes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const CardConfiguration: msRest.CompositeMapper = {
  serializedName: "CardConfiguration",
  type: {
    name: "Composite",
    className: "CardConfiguration",
    modelProperties: {
      limit: {
        serializedName: "limit",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CardHolderDetails: msRest.CompositeMapper = {
  serializedName: "CardHolderDetails",
  type: {
    name: "Composite",
    className: "CardHolderDetails",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      phoneNumber: {
        serializedName: "phoneNumber",
        type: {
          name: "String"
        }
      },
      income: {
        serializedName: "income",
        type: {
          name: "Number"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      cardName: {
        serializedName: "cardName",
        type: {
          name: "String"
        }
      },
      expiryDate: {
        serializedName: "expiryDate",
        type: {
          name: "DateTime"
        }
      },
      cardType: {
        serializedName: "cardType",
        type: {
          name: "String"
        }
      },
      creditLimit: {
        serializedName: "creditLimit",
        type: {
          name: "Number"
        }
      },
      limit: {
        serializedName: "limit",
        type: {
          name: "Number"
        }
      },
      suspended: {
        serializedName: "suspended",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PagedListCardHolderDetails: msRest.CompositeMapper = {
  serializedName: "PagedList_CardHolderDetails_",
  type: {
    name: "Composite",
    className: "PagedListCardHolderDetails",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CardHolderDetails"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CardTransaction: msRest.CompositeMapper = {
  serializedName: "CardTransaction",
  type: {
    name: "Composite",
    className: "CardTransaction",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      transactionType: {
        serializedName: "transactionType",
        type: {
          name: "String"
        }
      },
      cardHolderId: {
        serializedName: "cardHolderId",
        type: {
          name: "Uuid"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PagedListCardTransaction: msRest.CompositeMapper = {
  serializedName: "PagedList_CardTransaction_",
  type: {
    name: "Composite",
    className: "PagedListCardTransaction",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CardTransaction"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const HydrogenAppTokenResponse: msRest.CompositeMapper = {
  serializedName: "HydrogenAppTokenResponse",
  type: {
    name: "Composite",
    className: "HydrogenAppTokenResponse",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      tenantName: {
        serializedName: "tenant_name",
        type: {
          name: "String"
        }
      },
      username: {
        serializedName: "username",
        type: {
          name: "Object"
        }
      },
      isValid: {
        serializedName: "is_valid",
        type: {
          name: "Boolean"
        }
      },
      createDate: {
        serializedName: "create_date",
        type: {
          name: "DateTime"
        }
      },
      updateDate: {
        serializedName: "update_date",
        type: {
          name: "DateTime"
        }
      },
      appName: {
        serializedName: "app_name",
        type: {
          name: "String"
        }
      },
      appToken: {
        serializedName: "app_token",
        type: {
          name: "String"
        }
      },
      publicKey: {
        serializedName: "public_key",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomerProjection: msRest.CompositeMapper = {
  serializedName: "CustomerProjection",
  type: {
    name: "Composite",
    className: "CustomerProjection",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      mobileNumber: {
        serializedName: "mobileNumber",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      parentId: {
        serializedName: "parentId",
        type: {
          name: "String"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      },
      hasLoggedIn: {
        serializedName: "hasLoggedIn",
        type: {
          name: "Boolean"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PagedListCustomerProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_CustomerProjection_",
  type: {
    name: "Composite",
    className: "PagedListCustomerProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomerProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PagedListTransactionDataFile: msRest.CompositeMapper = {
  serializedName: "PagedList_TransactionDataFile_",
  type: {
    name: "Composite",
    className: "PagedListTransactionDataFile",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TransactionDataFile"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ProviderDetails: msRest.CompositeMapper = {
  serializedName: "ProviderDetails",
  type: {
    name: "Composite",
    className: "ProviderDetails",
    modelProperties: {
      connected: {
        serializedName: "connected",
        type: {
          name: "Boolean"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Number"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const PaySlip: msRest.CompositeMapper = {
  serializedName: "PaySlip",
  type: {
    name: "Composite",
    className: "PaySlip",
    modelProperties: {
      start: {
        serializedName: "start",
        type: {
          name: "DateTime"
        }
      },
      end: {
        serializedName: "end",
        type: {
          name: "DateTime"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const EmployeeDetails: msRest.CompositeMapper = {
  serializedName: "EmployeeDetails",
  type: {
    name: "Composite",
    className: "EmployeeDetails",
    modelProperties: {
      provider: {
        serializedName: "provider",
        type: {
          name: "String"
        }
      },
      employmentType: {
        serializedName: "employmentType",
        type: {
          name: "String"
        }
      },
      employmentStartDate: {
        serializedName: "employmentStartDate",
        type: {
          name: "DateTime"
        }
      },
      annual: {
        serializedName: "annual",
        type: {
          name: "Number"
        }
      },
      yearly: {
        serializedName: "yearly",
        type: {
          name: "Number"
        }
      },
      employeeId: {
        serializedName: "employeeId",
        type: {
          name: "Uuid"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Boolean"
        }
      },
      paySlips: {
        serializedName: "paySlips",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PaySlip"
            }
          }
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      phone: {
        serializedName: "phone",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      isActive: {
        serializedName: "isActive",
        type: {
          name: "Boolean"
        }
      },
      isEnable: {
        serializedName: "isEnable",
        type: {
          name: "Boolean"
        }
      },
      dateOfBirth: {
        serializedName: "dateOfBirth",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PagedListEmployeeDetails: msRest.CompositeMapper = {
  serializedName: "PagedList_EmployeeDetails_",
  type: {
    name: "Composite",
    className: "PagedListEmployeeDetails",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "EmployeeDetails"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const WithdrawRequest: msRest.CompositeMapper = {
  serializedName: "WithdrawRequest",
  type: {
    name: "Composite",
    className: "WithdrawRequest",
    modelProperties: {
      dateTime: {
        serializedName: "dateTime",
        type: {
          name: "DateTime"
        }
      },
      requestId: {
        serializedName: "requestId",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      employeeId: {
        serializedName: "employeeId",
        type: {
          name: "Uuid"
        }
      },
      requestAmount: {
        serializedName: "requestAmount",
        type: {
          name: "Number"
        }
      },
      responseAmount: {
        serializedName: "responseAmount",
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      reclaimDate: {
        serializedName: "reclaimDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const ContractDocument: msRest.CompositeMapper = {
  serializedName: "ContractDocument",
  type: {
    name: "Composite",
    className: "ContractDocument",
    modelProperties: {
      displayName: {
        serializedName: "displayName",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      documentId: {
        serializedName: "documentId",
        type: {
          name: "Uuid"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "ByteArray"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PagedListContractDocument: msRest.CompositeMapper = {
  serializedName: "PagedList_ContractDocument_",
  type: {
    name: "Composite",
    className: "PagedListContractDocument",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ContractDocument"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CustomerLookupData: msRest.CompositeMapper = {
  serializedName: "CustomerLookupData",
  type: {
    name: "Composite",
    className: "CustomerLookupData",
    modelProperties: {
      fullName: {
        serializedName: "fullName",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Number"
        }
      },
      abn: {
        serializedName: "abn",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      display: {
        readOnly: true,
        serializedName: "display",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const CustomerLoanProjection: msRest.CompositeMapper = {
  serializedName: "CustomerLoanProjection",
  type: {
    name: "Composite",
    className: "CustomerLoanProjection",
    modelProperties: {
      customerId: {
        serializedName: "customerId",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      },
      customerFullName: {
        serializedName: "customerFullName",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      health: {
        serializedName: "health",
        type: {
          name: "String"
        }
      },
      shortCode: {
        serializedName: "shortCode",
        type: {
          name: "String"
        }
      },
      totalAmount: {
        serializedName: "totalAmount",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      availableCredit: {
        serializedName: "availableCredit",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      securedCommission: {
        serializedName: "securedCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      lineOfCreditCommission: {
        serializedName: "lineOfCreditCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      salesPerson: {
        serializedName: "salesPerson",
        type: {
          name: "String"
        }
      },
      lastUpdated: {
        serializedName: "lastUpdated",
        type: {
          name: "DateTime"
        }
      },
      organisationId: {
        serializedName: "organisationId",
        type: {
          name: "Uuid"
        }
      },
      offerId: {
        serializedName: "offerId",
        type: {
          name: "Uuid"
        }
      },
      closed: {
        serializedName: "closed",
        type: {
          name: "Boolean"
        }
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean"
        }
      },
      enabledDebtors: {
        serializedName: "enabledDebtors",
        type: {
          name: "Boolean"
        }
      },
      showAllDebtors: {
        serializedName: "showAllDebtors",
        type: {
          name: "Boolean"
        }
      },
      bankId: {
        serializedName: "bankId",
        type: {
          name: "String"
        }
      },
      funder: {
        serializedName: "funder",
        type: {
          name: "String"
        }
      },
      enabled: {
        serializedName: "enabled",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      fullBusinessName: {
        serializedName: "fullBusinessName",
        type: {
          name: "String"
        }
      },
      revolving: {
        serializedName: "revolving",
        type: {
          name: "Boolean"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Boolean"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      nextAuditDate: {
        serializedName: "nextAuditDate",
        type: {
          name: "DateTime"
        }
      },
      auditLowRange: {
        serializedName: "auditLowRange",
        type: {
          name: "Number"
        }
      },
      auditHighRange: {
        serializedName: "auditHighRange",
        type: {
          name: "Number"
        }
      },
      auditType: {
        serializedName: "auditType",
        type: {
          name: "String"
        }
      },
      loanType: {
        serializedName: "loanType",
        type: {
          name: "String"
        }
      },
      closedDate: {
        serializedName: "closedDate",
        type: {
          name: "DateTime"
        }
      },
      onOffAudit: {
        serializedName: "onOffAudit",
        type: {
          name: "Boolean"
        }
      },
      auditId: {
        serializedName: "auditId",
        type: {
          name: "Uuid"
        }
      },
      balanceOverride: {
        serializedName: "balanceOverride",
        type: {
          name: "Boolean"
        }
      },
      defaulted: {
        serializedName: "defaulted",
        type: {
          name: "Boolean"
        }
      },
      defaultStatus: {
        serializedName: "defaultStatus",
        type: {
          name: "String"
        }
      },
      associatedCustomerIdStr: {
        serializedName: "associatedCustomerIdStr",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PagedListCustomerLoanProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_CustomerLoanProjection_",
  type: {
    name: "Composite",
    className: "PagedListCustomerLoanProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "CustomerLoanProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PaymentQueryNote: msRest.CompositeMapper = {
  serializedName: "PaymentQueryNote",
  type: {
    name: "Composite",
    className: "PaymentQueryNote",
    modelProperties: {
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      note: {
        serializedName: "note",
        type: {
          name: "String"
        }
      },
      output: {
        readOnly: true,
        serializedName: "output",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PaymentQuery: msRest.CompositeMapper = {
  serializedName: "PaymentQuery",
  type: {
    name: "Composite",
    className: "PaymentQuery",
    modelProperties: {
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      paymentQueryType: {
        serializedName: "paymentQueryType",
        type: {
          name: "String"
        }
      },
      reconciled: {
        serializedName: "reconciled",
        type: {
          name: "Boolean"
        }
      },
      time: {
        readOnly: true,
        serializedName: "time",
        type: {
          name: "Number"
        }
      },
      notes: {
        serializedName: "notes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PaymentQueryNote"
            }
          }
        }
      },
      paymentQueryId: {
        serializedName: "paymentQueryId",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      output: {
        readOnly: true,
        serializedName: "output",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      transactionId: {
        serializedName: "transactionId",
        type: {
          name: "Uuid"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      account: {
        serializedName: "account",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LoanReadyApplication: msRest.CompositeMapper = {
  serializedName: "LoanReadyApplication",
  type: {
    name: "Composite",
    className: "LoanReadyApplication",
    modelProperties: {
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      availableCredit: {
        serializedName: "availableCredit",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      shortCode: {
        serializedName: "shortCode",
        type: {
          name: "String"
        }
      },
      totalAmount: {
        serializedName: "totalAmount",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PagedListLoanReadyApplication: msRest.CompositeMapper = {
  serializedName: "PagedList_LoanReadyApplication_",
  type: {
    name: "Composite",
    className: "PagedListLoanReadyApplication",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LoanReadyApplication"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PaymentArrangement: msRest.CompositeMapper = {
  serializedName: "PaymentArrangement",
  type: {
    name: "Composite",
    className: "PaymentArrangement",
    modelProperties: {
      paymentArrangementId: {
        serializedName: "paymentArrangementId",
        type: {
          name: "Uuid"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      advanceId: {
        serializedName: "advanceId",
        type: {
          name: "Uuid"
        }
      },
      startDate: {
        serializedName: "startDate",
        type: {
          name: "DateTime"
        }
      },
      endDate: {
        serializedName: "endDate",
        type: {
          name: "DateTime"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LoanNotificationProjection: msRest.CompositeMapper = {
  serializedName: "LoanNotificationProjection",
  type: {
    name: "Composite",
    className: "LoanNotificationProjection",
    modelProperties: {
      notificationId: {
        serializedName: "notificationId",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      createdOnUtc: {
        serializedName: "createdOnUtc",
        type: {
          name: "DateTime"
        }
      },
      message: {
        serializedName: "message",
        type: {
          name: "String"
        }
      },
      read: {
        serializedName: "read",
        type: {
          name: "Boolean"
        }
      },
      readOnUtc: {
        serializedName: "readOnUtc",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PagedListLoanNotificationProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_LoanNotificationProjection_",
  type: {
    name: "Composite",
    className: "PagedListLoanNotificationProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "LoanNotificationProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const LoanData: msRest.CompositeMapper = {
  serializedName: "LoanData",
  type: {
    name: "Composite",
    className: "LoanData",
    modelProperties: {
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      applicationId: {
        serializedName: "applicationId",
        type: {
          name: "Uuid"
        }
      },
      organisationId: {
        serializedName: "organisationId",
        type: {
          name: "Uuid"
        }
      },
      customerId: {
        serializedName: "customerId",
        type: {
          name: "Uuid"
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      },
      offerId: {
        serializedName: "offerId",
        type: {
          name: "Uuid"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      tradingName: {
        serializedName: "tradingName",
        type: {
          name: "String"
        }
      },
      fullBusinessName: {
        serializedName: "fullBusinessName",
        type: {
          name: "String"
        }
      },
      closed: {
        serializedName: "closed",
        type: {
          name: "Boolean"
        }
      },
      revolving: {
        serializedName: "revolving",
        type: {
          name: "Boolean"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      },
      archived: {
        serializedName: "archived",
        type: {
          name: "Boolean"
        }
      },
      loanType: {
        serializedName: "loanType",
        type: {
          name: "Number"
        }
      },
      enabledDebtors: {
        serializedName: "enabledDebtors",
        type: {
          name: "Boolean"
        }
      },
      bankId: {
        serializedName: "bankId",
        type: {
          name: "String"
        }
      },
      funder: {
        serializedName: "funder",
        type: {
          name: "String"
        }
      },
      showAllDebtors: {
        serializedName: "showAllDebtors",
        type: {
          name: "Boolean"
        }
      },
      health: {
        serializedName: "health",
        type: {
          name: "String"
        }
      },
      enabled: {
        serializedName: "enabled",
        type: {
          name: "Boolean"
        }
      },
      customerFullName: {
        serializedName: "customerFullName",
        type: {
          name: "String"
        }
      },
      securedCommission: {
        serializedName: "securedCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      locCommission: {
        serializedName: "locCommission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      salesPerson: {
        serializedName: "salesPerson",
        type: {
          name: "String"
        }
      },
      lastUpdated: {
        serializedName: "lastUpdated",
        type: {
          name: "DateTime"
        }
      },
      secured: {
        serializedName: "secured",
        type: {
          name: "Boolean"
        }
      },
      nextAuditDate: {
        serializedName: "nextAuditDate",
        type: {
          name: "DateTime"
        }
      },
      auditHighRange: {
        serializedName: "auditHighRange",
        type: {
          name: "Number"
        }
      },
      auditLowRange: {
        serializedName: "auditLowRange",
        type: {
          name: "Number"
        }
      },
      auditType: {
        serializedName: "auditType",
        type: {
          name: "String"
        }
      },
      auditTypeList: {
        serializedName: "auditTypeList",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      loanTypeName: {
        serializedName: "loanTypeName",
        type: {
          name: "String"
        }
      },
      defaulted: {
        serializedName: "defaulted",
        type: {
          name: "Boolean"
        }
      },
      defaultStatus: {
        serializedName: "defaultStatus",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LoanBalances: msRest.CompositeMapper = {
  serializedName: "LoanBalances",
  type: {
    name: "Composite",
    className: "LoanBalances",
    modelProperties: {
      availableFunds: {
        readOnly: true,
        serializedName: "availableFunds",
        type: {
          name: "Number"
        }
      },
      systemPricing: {
        serializedName: "systemPricing",
        type: {
          name: "Number"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "Number"
        }
      },
      totalLimit: {
        readOnly: true,
        serializedName: "totalLimit",
        type: {
          name: "Number"
        }
      },
      limits: {
        serializedName: "limits",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Number"
            }
          }
        }
      },
      rawTotal: {
        serializedName: "rawTotal",
        type: {
          name: "Number"
        }
      },
      adjustment: {
        serializedName: "adjustment",
        type: {
          name: "Number"
        }
      },
      totalDebtors: {
        serializedName: "totalDebtors",
        type: {
          name: "Number"
        }
      },
      totalFundingRate: {
        serializedName: "totalFundingRate",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AllLoanBalances: msRest.CompositeMapper = {
  serializedName: "AllLoanBalances",
  type: {
    name: "Composite",
    className: "AllLoanBalances",
    modelProperties: {
      all: {
        serializedName: "all",
        type: {
          name: "Composite",
          className: "LoanBalances"
        }
      },
      fixed: {
        serializedName: "fixed",
        type: {
          name: "Composite",
          className: "LoanBalances"
        }
      },
      invoice: {
        serializedName: "invoice",
        type: {
          name: "Composite",
          className: "LoanBalances"
        }
      },
      percentage: {
        serializedName: "percentage",
        type: {
          name: "Composite",
          className: "LoanBalances"
        }
      },
      lineOfCredit: {
        serializedName: "lineOfCredit",
        type: {
          name: "Composite",
          className: "LoanBalances"
        }
      },
      creditCard: {
        serializedName: "creditCard",
        type: {
          name: "Composite",
          className: "LoanBalances"
        }
      }
    }
  }
};

export const LoanSettings: msRest.CompositeMapper = {
  serializedName: "LoanSettings",
  type: {
    name: "Composite",
    className: "LoanSettings",
    modelProperties: {
      fixedRepayment: {
        serializedName: "fixedRepayment",
        type: {
          name: "Boolean"
        }
      },
      percentOfIncome: {
        serializedName: "percentOfIncome",
        type: {
          name: "Boolean"
        }
      },
      invoicePayment: {
        serializedName: "invoicePayment",
        type: {
          name: "Boolean"
        }
      },
      lineOfCreditPayment: {
        serializedName: "lineOfCreditPayment",
        type: {
          name: "Boolean"
        }
      },
      creditCardPayment: {
        serializedName: "creditCardPayment",
        type: {
          name: "Boolean"
        }
      },
      revolving: {
        serializedName: "revolving",
        type: {
          name: "Boolean"
        }
      },
      enabledDebtors: {
        serializedName: "enabledDebtors",
        type: {
          name: "Boolean"
        }
      },
      showAllDebtors: {
        serializedName: "showAllDebtors",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PaidCommission: msRest.CompositeMapper = {
  serializedName: "PaidCommission",
  type: {
    name: "Composite",
    className: "PaidCommission",
    modelProperties: {
      createdOnUtc: {
        serializedName: "createdOnUtc",
        type: {
          name: "DateTime"
        }
      },
      paid: {
        serializedName: "paid",
        type: {
          name: "Boolean"
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      },
      advanceId: {
        serializedName: "advanceId",
        type: {
          name: "Uuid"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      paidOn: {
        serializedName: "paidOn",
        type: {
          name: "DateTime"
        }
      },
      id: {
        required: true,
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const PagedListPaidCommission: msRest.CompositeMapper = {
  serializedName: "PagedList_PaidCommission_",
  type: {
    name: "Composite",
    className: "PagedListPaidCommission",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PaidCommission"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const TransactionProjection: msRest.CompositeMapper = {
  serializedName: "TransactionProjection",
  type: {
    name: "Composite",
    className: "TransactionProjection",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      overrideId: {
        serializedName: "overrideId",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      advanceId: {
        serializedName: "advanceId",
        type: {
          name: "String"
        }
      },
      advanceName: {
        serializedName: "advanceName",
        type: {
          name: "String"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      balance: {
        serializedName: "balance",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      reconciled: {
        serializedName: "reconciled",
        type: {
          name: "Boolean"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      isCredit: {
        serializedName: "isCredit",
        type: {
          name: "Boolean"
        }
      },
      bankReceipt: {
        serializedName: "bankReceipt",
        type: {
          name: "String"
        }
      },
      force: {
        serializedName: "force",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PagedListTransactionProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_TransactionProjection_",
  type: {
    name: "Composite",
    className: "PagedListTransactionProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TransactionProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const AdvanceProjection: msRest.CompositeMapper = {
  serializedName: "AdvanceProjection",
  type: {
    name: "Composite",
    className: "AdvanceProjection",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      loanId: {
        serializedName: "loanId",
        type: {
          name: "Uuid"
        }
      },
      dateUTC: {
        serializedName: "dateUTC",
        type: {
          name: "DateTime"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      remaining: {
        serializedName: "remaining",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      nextRepaymentValue: {
        serializedName: "nextRepaymentValue",
        type: {
          name: "Composite",
          className: "Amount"
        }
      },
      nextRepaymentDateUTC: {
        serializedName: "nextRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      actualNextRepaymentDateUTC: {
        readOnly: true,
        serializedName: "actualNextRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      actualNextRepaymentDay: {
        readOnly: true,
        serializedName: "actualNextRepaymentDay",
        type: {
          name: "String"
        }
      },
      nextRepaymentDay: {
        readOnly: true,
        serializedName: "nextRepaymentDay",
        type: {
          name: "String"
        }
      },
      lastRepaymentDay: {
        readOnly: true,
        serializedName: "lastRepaymentDay",
        type: {
          name: "String"
        }
      },
      lastRepaymentDateUTC: {
        readOnly: true,
        serializedName: "lastRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      totalRepaymentAmount: {
        serializedName: "totalRepaymentAmount",
        type: {
          name: "Number"
        }
      },
      weeklyPayment: {
        serializedName: "weeklyPayment",
        type: {
          name: "Number"
        }
      },
      advanceRate: {
        serializedName: "advanceRate",
        type: {
          name: "Number"
        }
      },
      terms: {
        serializedName: "terms",
        type: {
          name: "Number"
        }
      },
      priority: {
        serializedName: "priority",
        type: {
          name: "Boolean"
        }
      },
      percentage: {
        serializedName: "percentage",
        type: {
          name: "Number"
        }
      },
      weeks: {
        serializedName: "weeks",
        type: {
          name: "Number"
        }
      },
      name: {
        readOnly: true,
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      earlyRepaid: {
        serializedName: "earlyRepaid",
        type: {
          name: "Boolean"
        }
      },
      earlyRepaidDate: {
        serializedName: "earlyRepaidDate",
        type: {
          name: "DateTime"
        }
      },
      overrideLastRepaymentDateUTC: {
        serializedName: "overrideLastRepaymentDateUTC",
        type: {
          name: "DateTime"
        }
      },
      overrideLastRepayment: {
        serializedName: "overrideLastRepayment",
        type: {
          name: "Boolean"
        }
      },
      legacy: {
        serializedName: "legacy",
        type: {
          name: "Boolean"
        }
      },
      monthlyFee: {
        serializedName: "monthlyFee",
        type: {
          name: "Boolean"
        }
      },
      commission: {
        serializedName: "commission",
        type: {
          name: "Composite",
          className: "Commission"
        }
      },
      fixed: {
        serializedName: "fixed",
        type: {
          name: "Boolean"
        }
      },
      fixedRepayment: {
        serializedName: "fixedRepayment",
        type: {
          name: "Number"
        }
      },
      fixedRepaymentFee: {
        serializedName: "fixedRepaymentFee",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PagedListAdvanceProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_AdvanceProjection_",
  type: {
    name: "Composite",
    className: "PagedListAdvanceProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AdvanceProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const CashflowDetails: msRest.CompositeMapper = {
  serializedName: "CashflowDetails",
  type: {
    name: "Composite",
    className: "CashflowDetails",
    modelProperties: {
      lastQuarterProfits: {
        serializedName: "lastQuarterProfits",
        type: {
          name: "Number"
        }
      },
      agedPayables: {
        serializedName: "agedPayables",
        type: {
          name: "Number"
        }
      },
      invoices: {
        serializedName: "invoices",
        type: {
          name: "Number"
        }
      },
      bills: {
        serializedName: "bills",
        type: {
          name: "Number"
        }
      },
      cashFlow: {
        serializedName: "cashFlow",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "Number"
            }
          }
        }
      }
    }
  }
};

export const EarlyRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: "EarlyRepaymentCalculation",
  type: {
    name: "Composite",
    className: "EarlyRepaymentCalculation",
    modelProperties: {
      saving: {
        serializedName: "saving",
        type: {
          name: "Number"
        }
      },
      repayment: {
        serializedName: "repayment",
        type: {
          name: "Number"
        }
      },
      scheduleTransaction: {
        serializedName: "scheduleTransaction",
        type: {
          name: "DateTime"
        }
      },
      weeklyRepayment: {
        serializedName: "weeklyRepayment",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SkipSchedule: msRest.CompositeMapper = {
  serializedName: "SkipSchedule",
  type: {
    name: "Composite",
    className: "SkipSchedule",
    modelProperties: {
      repaymentDate: {
        serializedName: "repaymentDate",
        type: {
          name: "DateTime"
        }
      },
      nextRepaymentDate: {
        serializedName: "nextRepaymentDate",
        type: {
          name: "DateTime"
        }
      },
      repaymentAmount: {
        serializedName: "repaymentAmount",
        type: {
          name: "Number"
        }
      },
      nextRepaymentAmount: {
        serializedName: "nextRepaymentAmount",
        type: {
          name: "Number"
        }
      },
      lastDate: {
        serializedName: "lastDate",
        type: {
          name: "DateTime"
        }
      },
      skipFee: {
        serializedName: "skipFee",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SkipRepaymentCalculation: msRest.CompositeMapper = {
  serializedName: "SkipRepaymentCalculation",
  type: {
    name: "Composite",
    className: "SkipRepaymentCalculation",
    modelProperties: {
      current: {
        serializedName: "current",
        type: {
          name: "Composite",
          className: "SkipSchedule"
        }
      },
      next: {
        serializedName: "next",
        type: {
          name: "Composite",
          className: "SkipSchedule"
        }
      }
    }
  }
};

export const BankDetails: msRest.CompositeMapper = {
  serializedName: "BankDetails",
  type: {
    name: "Composite",
    className: "BankDetails",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      bsb: {
        serializedName: "bsb",
        type: {
          name: "String"
        }
      },
      number: {
        serializedName: "number",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TransactionFile: msRest.CompositeMapper = {
  serializedName: "TransactionFile",
  type: {
    name: "Composite",
    className: "TransactionFile",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "String"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      fileType: {
        serializedName: "fileType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeOrganisationServiceAccountingModelsProcessedPayment: msRest.CompositeMapper = {
  serializedName: "PlexeOrganisationServiceAccountingModelsProcessedPayment",
  type: {
    name: "Composite",
    className: "PlexeOrganisationServiceAccountingModelsProcessedPayment",
    modelProperties: {
      manualExclude: {
        serializedName: "manualExclude",
        type: {
          name: "Boolean"
        }
      },
      paymentKey: {
        serializedName: "paymentKey",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "Number"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "Number"
        }
      },
      currencyRate: {
        serializedName: "currencyRate",
        type: {
          name: "Number"
        }
      },
      bankAmount: {
        serializedName: "bankAmount",
        type: {
          name: "Number"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      reference: {
        serializedName: "reference",
        type: {
          name: "String"
        }
      },
      isReconciled: {
        serializedName: "isReconciled",
        type: {
          name: "Boolean"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      account: {
        serializedName: "account",
        type: {
          name: "String"
        }
      },
      updatedDate: {
        serializedName: "updatedDate",
        type: {
          name: "DateTime"
        }
      },
      retrievedDate: {
        serializedName: "retrievedDate",
        type: {
          name: "DateTime"
        }
      },
      invoices: {
        serializedName: "invoices",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeAccountingProxyModelsPaymentInvoice"
            }
          }
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SyntheticPayment: msRest.CompositeMapper = {
  serializedName: "SyntheticPayment",
  type: {
    name: "Composite",
    className: "SyntheticPayment",
    modelProperties: {
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      transactionId: {
        serializedName: "transactionId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const ReconciledPayment: msRest.CompositeMapper = {
  serializedName: "ReconciledPayment",
  type: {
    name: "Composite",
    className: "ReconciledPayment",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      },
      paymentQueryId: {
        serializedName: "paymentQueryId",
        type: {
          name: "Uuid"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      transactionId: {
        serializedName: "transactionId",
        type: {
          name: "Uuid"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      payment: {
        serializedName: "payment",
        type: {
          name: "Composite",
          className: "PlexeOrganisationServiceAccountingModelsProcessedPayment"
        }
      },
      syntheticPayment: {
        serializedName: "syntheticPayment",
        type: {
          name: "Composite",
          className: "SyntheticPayment"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      autoMatch: {
        serializedName: "autoMatch",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PagedListReconciledPayment: msRest.CompositeMapper = {
  serializedName: "PagedList_ReconciledPayment_",
  type: {
    name: "Composite",
    className: "PagedListReconciledPayment",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReconciledPayment"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const ReconciliationTransaction: msRest.CompositeMapper = {
  serializedName: "ReconciliationTransaction",
  type: {
    name: "Composite",
    className: "ReconciliationTransaction",
    modelProperties: {
      source: {
        serializedName: "source",
        type: {
          name: "String"
        }
      },
      reconciled: {
        serializedName: "reconciled",
        type: {
          name: "Boolean"
        }
      },
      request: {
        serializedName: "request",
        type: {
          name: "Boolean"
        }
      },
      requestList: {
        serializedName: "requestList",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      misdirectionList: {
        serializedName: "misdirectionList",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      enabledDebtor: {
        serializedName: "enabledDebtor",
        type: {
          name: "Boolean"
        }
      },
      systemReconciled: {
        serializedName: "systemReconciled",
        type: {
          name: "Boolean"
        }
      },
      updatedDate: {
        serializedName: "updatedDate",
        type: {
          name: "DateTime"
        }
      },
      retrievedDate: {
        serializedName: "retrievedDate",
        type: {
          name: "DateTime"
        }
      },
      potentialMisdirection: {
        serializedName: "potentialMisdirection",
        type: {
          name: "Boolean"
        }
      },
      potentialMisdirectionDetails: {
        serializedName: "potentialMisdirectionDetails",
        type: {
          name: "Composite",
          className: "PaymentQuery"
        }
      },
      misdirect: {
        serializedName: "misdirect",
        type: {
          name: "Boolean"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      metaDescription: {
        serializedName: "metaDescription",
        type: {
          name: "String"
        }
      },
      amount: {
        serializedName: "amount",
        type: {
          name: "Number"
        }
      },
      account: {
        serializedName: "account",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      debtor: {
        serializedName: "debtor",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PaymentAndTransactions: msRest.CompositeMapper = {
  serializedName: "PaymentAndTransactions",
  type: {
    name: "Composite",
    className: "PaymentAndTransactions",
    modelProperties: {
      date: {
        serializedName: "date",
        type: {
          name: "DateTime"
        }
      },
      list: {
        serializedName: "list",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ReconciliationTransaction"
            }
          }
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsCredential: msRest.CompositeMapper = {
  serializedName: "PlexeBankStatementsProxyModelsCredential",
  type: {
    name: "Composite",
    className: "PlexeBankStatementsProxyModelsCredential",
    modelProperties: {
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      fieldID: {
        serializedName: "fieldID",
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      values: {
        serializedName: "values",
        type: {
          name: "String"
        }
      },
      keyboardType: {
        serializedName: "keyboardType",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PlexeBankStatementsProxyModelsInstitution: msRest.CompositeMapper = {
  serializedName: "PlexeBankStatementsProxyModelsInstitution",
  type: {
    name: "Composite",
    className: "PlexeBankStatementsProxyModelsInstitution",
    modelProperties: {
      slug: {
        serializedName: "slug",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      credentials: {
        serializedName: "credentials",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PlexeBankStatementsProxyModelsCredential"
            }
          }
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      searchable: {
        serializedName: "searchable",
        type: {
          name: "String"
        }
      },
      display: {
        serializedName: "display",
        type: {
          name: "String"
        }
      },
      searchVal: {
        serializedName: "searchVal",
        type: {
          name: "String"
        }
      },
      region: {
        serializedName: "region",
        type: {
          name: "String"
        }
      },
      exportWithPassword: {
        serializedName: "export_with_password",
        type: {
          name: "Number"
        }
      },
      estatementsSupported: {
        serializedName: "estatements_supported",
        type: {
          name: "Number"
        }
      },
      transactionListingsSupported: {
        serializedName: "transaction_listings_supported",
        type: {
          name: "String"
        }
      },
      requiresPreload: {
        serializedName: "requires_preload",
        type: {
          name: "String"
        }
      },
      requiresMfa: {
        serializedName: "requires_mfa",
        type: {
          name: "String"
        }
      },
      updatedAt: {
        serializedName: "updated_at",
        type: {
          name: "String"
        }
      },
      maxDays: {
        serializedName: "max_days",
        type: {
          name: "String"
        }
      },
      continues: {
        serializedName: "continues",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const OrganisationDetails: msRest.CompositeMapper = {
  serializedName: "OrganisationDetails",
  type: {
    name: "Composite",
    className: "OrganisationDetails",
    modelProperties: {
      entityType: {
        serializedName: "entityType",
        type: {
          name: "String"
        }
      },
      entityTypeCode: {
        serializedName: "entityTypeCode",
        type: {
          name: "String"
        }
      },
      fromDate: {
        serializedName: "fromDate",
        type: {
          name: "DateTime"
        }
      },
      toDate: {
        serializedName: "toDate",
        type: {
          name: "DateTime"
        }
      },
      postcode: {
        serializedName: "postcode",
        type: {
          name: "String"
        }
      },
      state: {
        serializedName: "state",
        type: {
          name: "String"
        }
      },
      familyName: {
        serializedName: "familyName",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      otherName: {
        serializedName: "otherName",
        type: {
          name: "String"
        }
      },
      mainName: {
        serializedName: "mainName",
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const RequestDetail: msRest.CompositeMapper = {
  serializedName: "RequestDetail",
  type: {
    name: "Composite",
    className: "RequestDetail",
    modelProperties: {
      type: {
        serializedName: "type",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const LookupResult: msRest.CompositeMapper = {
  serializedName: "LookupResult",
  type: {
    name: "Composite",
    className: "LookupResult",
    modelProperties: {
      search: {
        serializedName: "search",
        type: {
          name: "String"
        }
      },
      elapsed: {
        serializedName: "elapsed",
        type: {
          name: "String"
        }
      },
      list: {
        serializedName: "list",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "RequestDetail"
            }
          }
        }
      }
    }
  }
};

export const PartnerSendLinkRequest: msRest.CompositeMapper = {
  serializedName: "PartnerSendLinkRequest",
  type: {
    name: "Composite",
    className: "PartnerSendLinkRequest",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      ein: {
        serializedName: "ein",
        type: {
          name: "String"
        }
      },
      mobile: {
        serializedName: "mobile",
        type: {
          name: "String"
        }
      },
      createdDate: {
        serializedName: "createdDate",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PartnerProjection: msRest.CompositeMapper = {
  serializedName: "PartnerProjection",
  type: {
    name: "Composite",
    className: "PartnerProjection",
    modelProperties: {
      id: {
        serializedName: "id",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      lastName: {
        serializedName: "lastName",
        type: {
          name: "String"
        }
      },
      businessName: {
        serializedName: "businessName",
        type: {
          name: "String"
        }
      },
      mobilePhoneNumber: {
        serializedName: "mobilePhoneNumber",
        type: {
          name: "String"
        }
      },
      unsecuredTrail: {
        serializedName: "unsecuredTrail",
        type: {
          name: "Number"
        }
      },
      securedTrail: {
        serializedName: "securedTrail",
        type: {
          name: "Number"
        }
      },
      locTrail: {
        serializedName: "locTrail",
        type: {
          name: "Number"
        }
      },
      upFrontFee: {
        serializedName: "upFrontFee",
        type: {
          name: "Number"
        }
      },
      role: {
        serializedName: "role",
        type: {
          name: "String"
        }
      },
      urlReferral: {
        serializedName: "urlReferral",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PagedListPartnerProjection: msRest.CompositeMapper = {
  serializedName: "PagedList_PartnerProjection_",
  type: {
    name: "Composite",
    className: "PagedListPartnerProjection",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "PartnerProjection"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PartnerOwnerDetails: msRest.CompositeMapper = {
  serializedName: "PartnerOwnerDetails",
  type: {
    name: "Composite",
    className: "PartnerOwnerDetails",
    modelProperties: {
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      firstName: {
        serializedName: "firstName",
        type: {
          name: "String"
        }
      },
      preferredName: {
        serializedName: "preferredName",
        type: {
          name: "String"
        }
      },
      lastname: {
        serializedName: "lastname",
        type: {
          name: "String"
        }
      },
      cellNumber: {
        serializedName: "cellNumber",
        type: {
          name: "String"
        }
      },
      officeNumber: {
        serializedName: "officeNumber",
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        type: {
          name: "String"
        }
      },
      driversLicenceNumber: {
        serializedName: "driversLicenceNumber",
        type: {
          name: "String"
        }
      },
      stateIssued: {
        serializedName: "stateIssued",
        type: {
          name: "String"
        }
      },
      ssn: {
        serializedName: "ssn",
        type: {
          name: "String"
        }
      },
      dob: {
        serializedName: "dob",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const PartnerBusinessDetails: msRest.CompositeMapper = {
  serializedName: "PartnerBusinessDetails",
  type: {
    name: "Composite",
    className: "PartnerBusinessDetails",
    modelProperties: {
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      companyAddress: {
        serializedName: "companyAddress",
        type: {
          name: "String"
        }
      },
      officeNumber: {
        serializedName: "officeNumber",
        type: {
          name: "String"
        }
      },
      webAddress: {
        serializedName: "webAddress",
        type: {
          name: "String"
        }
      },
      accountPayableEmail: {
        serializedName: "accountPayableEmail",
        type: {
          name: "String"
        }
      },
      optionFirst: {
        serializedName: "optionFirst",
        type: {
          name: "Boolean"
        }
      },
      optionSecond: {
        serializedName: "optionSecond",
        type: {
          name: "Boolean"
        }
      },
      optionThird: {
        serializedName: "optionThird",
        type: {
          name: "Boolean"
        }
      },
      optionFourth: {
        serializedName: "optionFourth",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PartnerAchDetail: msRest.CompositeMapper = {
  serializedName: "PartnerAchDetail",
  type: {
    name: "Composite",
    className: "PartnerAchDetail",
    modelProperties: {
      bankName: {
        serializedName: "bankName",
        type: {
          name: "String"
        }
      },
      accountType: {
        serializedName: "accountType",
        type: {
          name: "String"
        }
      },
      routingNumber: {
        serializedName: "routingNumber",
        type: {
          name: "String"
        }
      },
      accountNumber: {
        serializedName: "accountNumber",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PartnerCompanyDetail: msRest.CompositeMapper = {
  serializedName: "PartnerCompanyDetail",
  type: {
    name: "Composite",
    className: "PartnerCompanyDetail",
    modelProperties: {
      companyName: {
        serializedName: "companyName",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      title: {
        serializedName: "title",
        type: {
          name: "String"
        }
      },
      date: {
        serializedName: "date",
        type: {
          name: "String"
        }
      },
      signature: {
        serializedName: "signature",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PartnerAccreditationRequest: msRest.CompositeMapper = {
  serializedName: "PartnerAccreditationRequest",
  type: {
    name: "Composite",
    className: "PartnerAccreditationRequest",
    modelProperties: {
      ownerDetails: {
        serializedName: "ownerDetails",
        type: {
          name: "Composite",
          className: "PartnerOwnerDetails"
        }
      },
      businessDetails: {
        serializedName: "businessDetails",
        type: {
          name: "Composite",
          className: "PartnerBusinessDetails"
        }
      },
      achDetail: {
        serializedName: "achDetail",
        type: {
          name: "Composite",
          className: "PartnerAchDetail"
        }
      },
      companyDetail: {
        serializedName: "companyDetail",
        type: {
          name: "Composite",
          className: "PartnerCompanyDetail"
        }
      }
    }
  }
};

export const FileDetails: msRest.CompositeMapper = {
  serializedName: "FileDetails",
  type: {
    name: "Composite",
    className: "FileDetails",
    modelProperties: {
      location: {
        serializedName: "location",
        type: {
          name: "String"
        }
      },
      fileType: {
        serializedName: "fileType",
        type: {
          name: "String"
        }
      },
      fileName: {
        serializedName: "fileName",
        type: {
          name: "String"
        }
      },
      internalLocation: {
        serializedName: "internalLocation",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const SupportDocumentDetails: msRest.CompositeMapper = {
  serializedName: "SupportDocumentDetails",
  type: {
    name: "Composite",
    className: "SupportDocumentDetails",
    modelProperties: {
      label: {
        serializedName: "label",
        type: {
          name: "String"
        }
      },
      details: {
        serializedName: "details",
        type: {
          name: "String"
        }
      },
      submittedDate: {
        serializedName: "submittedDate",
        type: {
          name: "DateTime"
        }
      },
      submitted: {
        serializedName: "submitted",
        type: {
          name: "Boolean"
        }
      },
      locations: {
        serializedName: "locations",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "FileDetails"
            }
          }
        }
      },
      id: {
        serializedName: "id",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};

export const PagedListSupportDocumentDetails: msRest.CompositeMapper = {
  serializedName: "PagedList_SupportDocumentDetails_",
  type: {
    name: "Composite",
    className: "PagedListSupportDocumentDetails",
    modelProperties: {
      data: {
        serializedName: "data",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "SupportDocumentDetails"
            }
          }
        }
      },
      totalCount: {
        serializedName: "totalCount",
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const SiteDetails: msRest.CompositeMapper = {
  serializedName: "SiteDetails",
  type: {
    name: "Composite",
    className: "SiteDetails",
    modelProperties: {
      items: {
        serializedName: "items",
        type: {
          name: "Dictionary",
          value: {
            type: {
              name: "String"
            }
          }
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      },
      urls: {
        serializedName: "urls",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      },
      partnerId: {
        serializedName: "partnerId",
        type: {
          name: "Uuid"
        }
      }
    }
  }
};
